import { fetchBaseQuery, createApi } from '@reduxjs/toolkit/query/react';


const baseUrl = process.env.REACT_APP_base_URL;
const logoBaseUrl = 'https://bsdawebfiledata.s3.ca-central-1.amazonaws.com/solution/';

export const currentMeetingDetail  = createApi({
    reducerPath: 'currentMeetingDetail',
    baseQuery: fetchBaseQuery({
      baseUrl: baseUrl,
      prepareHeaders: (headers) => {
        // Add any headers you need here
        return headers;
      },
    }),
    endpoints: (builder) => ({
      fetchCurrentMeetingDetail: builder.query({
        query: (currentMeetingId) => ({
          url: `meetings/${currentMeetingId}`,
          method: 'GET',
        }),
        transformResponse: (response) => {
        return response;
        },
        refetchOnWindowFocus: true,
        // retry: RetryOnError,
        onError: (error) => {
          console.error('Fetch Meetings details error:', error);
          // Handle error cases as per your application's requirements
        },
      })
    })
})


export const { useFetchCurrentMeetingDetailQuery } = currentMeetingDetail;
export default currentMeetingDetail;
