import React, { useState } from 'react';
import {
  Box,
  Button,
  MenuItem,
  Stack,
  Typography,
  Menu,
  styled,
  InputBase,
  Radio,
  FormControlLabel,
  Select,
  FormControl,
  Grid,
  Card,
  CardContent,
  IconButton,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Badge,
} from '@mui/material';
import { useTheme } from '@emotion/react';
import SearchIcon from '@mui/icons-material/Search';
import { Calculate, KeyboardArrowDownOutlined } from '@mui/icons-material';
import NorthOutlinedIcon from '@mui/icons-material/NorthOutlined';
import SouthOutlinedIcon from '@mui/icons-material/SouthOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import { getImages } from '../../commonComponents/const';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import StarOutlinedIcon from '@mui/icons-material/StarOutlined';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import { Link } from 'react-router-dom';

const BsiCoLabSolutionEnquiries = () => {
  const theme = useTheme();
  const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#ECF6FF',
    '&:hover': {
      backgroundColor: '#ECF6FF',
    },
    marginLeft: 0,
    marginBottom: 0,
    width: '300px',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  }));
  const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    right: '0',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: '#106EED',
    width: '100%',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1.6, 0, 1.9, 1.5),
      paddingRight: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      fontSize: '12px',
      fontWeight: '500'
    },
  }));

  const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 24,
    height: 24,
    border: `2px solid ${theme.palette.background.paper}`,
  }));
  // Sort and Filter
  const [openSort, setOpenSort] = useState(false);
  const [selectedSortOption, setSelectedSortOption] = useState('');

  const [openFilter, setOpenFilter] = useState(false);
  const [anchorElSort, setAnchorElSort] = useState(null);
  const [anchorElFilter, setAnchorElFilter] = useState(null);
  const handleClickSort = (event) => {
    setAnchorElSort(event.currentTarget);
    setOpenSort(!openSort);
  };
  const handleCloseSort = (event) => {
    setAnchorElSort(null);
    setOpenSort(false);
    setSelectedSortOption(event.target.value);
  };
  const handleClickFilter = (event) => {
    setAnchorElFilter(event.currentTarget);
    setOpenFilter(!openFilter);
  };
  const handleCloseFilter = () => {
    setAnchorElFilter(null);
    setOpenFilter(false);
  };

  // Select 
  const [designation, setDesignation] = useState('');
  const handleChange = (event) => {
    setDesignation(event.target.value);
  };
  return (
    <>
      <Stack
        direction="row"
        sx={{
          justifyItems: 'center',
          justifyContent: 'space-between',
          mt: 0,
          [theme.breakpoints.down('md')]: {
            flexWrap: 'wrap'
          }
        }}
      >
        <Search
          sx={{
            width: '300px',
            backgroundColor: '#ECF6FF',
            mb: 2.5,
            mr: 2,
            [theme.breakpoints.down('md')]: {
              width: "100%",
              mr: 0,
              mb: 1.2,
            }
          }}
        >
          <SearchIconWrapper>
            <SearchIcon sx={{ color: "lightSecondary.main" }} />
          </SearchIconWrapper>
          <StyledInputBase
            id="searchBox"
            placeholder="Search solution"
            inputProps={{ 'aria-label': 'search' }}
          />
        </Search>
        <Stack
          direction="row"
          sx={{
            justifyItems: 'center',
            mt: 0,
            [theme.breakpoints.down('md')]: {
              width: "100%",
              mb: 2,
            }
          }}
        >
          <Box
            sx={{
              mr: 2,
              [theme.breakpoints.down('md')]: {
                width: "calc(50% - 0.5rem)",
                mr: '0.5rem',
              }
            }}
          >
            <Button
              id="basic-button"
              aria-controls={openSort ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={openSort ? 'true' : undefined}
              onClick={handleClickSort}
              sx={{
                background: "#fff",
                fontSize: "0.9rem",
                color: "lightSecondary.main",
                textTransform: "inherit",
                fontWeight: "600",
                p: "10px 20px",
                '&:hover': {
                  background: "#fff",
                },
                [theme.breakpoints.down('md')]: {
                  width: "100%"
                }
              }}
            >
              Sort
              <KeyboardArrowDownOutlined
                sx={{

                }}
              />
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorElSort}
              open={openSort}
              onClose={handleCloseSort}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              sx={{
                '& .MuiMenu-paper': {
                  borderRadius: "10px",
                  border: "solid 1px #106EED"
                }
              }}
            >
              <MenuItem
                sx={{
                  '& .MuiFormControlLabel-label': {
                    fontSize: "0.9rem",
                    color: "#2A589C",
                    fontWeight: "600",
                  }
                }}
                onClick={handleCloseSort}>
                <FormControlLabel
                  value="CreatedUp" control={<Radio sx={{ paddingY: 0, color: "#2A589C" }} checked={selectedSortOption === 'CreatedUp'} />} label="Created" />
                <NorthOutlinedIcon sx={{
                  fontSize: "1.1rem",
                  color: "#2A589C"
                }} />
              </MenuItem>
              <MenuItem
                sx={{
                  '& .MuiFormControlLabel-label': {
                    fontSize: "0.9rem",
                    color: "#2A589C",
                    fontWeight: "600",
                  }
                }}
                onClick={handleCloseSort}>
                <FormControlLabel
                  value="CreatedDown" control={<Radio sx={{ paddingY: 0, color: "#2A589C" }} checked={selectedSortOption === 'CreatedDown'} />} label="Created" />
                <SouthOutlinedIcon sx={{
                  fontSize: "1.1rem",
                  color: "#2A589C"
                }} />
              </MenuItem>
              <MenuItem
                sx={{
                  '& .MuiFormControlLabel-label': {
                    fontSize: "0.9rem",
                    color: "#2A589C",
                    fontWeight: "600",
                  }
                }}
                onClick={handleCloseSort}>
                <FormControlLabel
                  value="name-a-z" control={<Radio sx={{ paddingY: 0, color: "#2A589C" }} checked={selectedSortOption === 'name-a-z'} />} label="Name A-Z" />
              </MenuItem>
              <MenuItem
                sx={{
                  '& .MuiFormControlLabel-label': {
                    fontSize: "0.9rem",
                    color: "#2A589C",
                    fontWeight: "600",
                  }
                }}
                onClick={handleCloseSort}>
                <FormControlLabel
                  value="name-z-a" control={<Radio sx={{ paddingY: 0, color: "#2A589C" }} checked={selectedSortOption === 'name-z-a'} />} label="Name Z-A" />
              </MenuItem>
            </Menu>
          </Box>
          <Box
            sx={{
              [theme.breakpoints.down('md')]: {
                width: "50%"
              }
            }}
          >
            <Button
              id="basic-button"
              aria-controls={openFilter ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={openFilter ? 'true' : undefined}
              onClick={handleClickFilter}
              sx={{
                background: "#fff",
                fontSize: "0.9rem",
                color: "lightSecondary.main",
                textTransform: "inherit",
                fontWeight: "600",
                p: "10px 20px",
                '&:hover': {
                  background: "#fff",
                },
                [theme.breakpoints.down('md')]: {
                  width: "100%"
                }
              }}
            >
              Filter
              <KeyboardArrowDownOutlined
              />
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorElFilter}
              open={openFilter}
              onClose={handleCloseFilter}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              sx={{
                '& .MuiMenu-paper': {
                  borderRadius: "10px",
                  border: "solid 1px #106EED",
                  minWidth: "280px",
                  px: 2.3,
                  py: 1
                }
              }}
            >
              <Box sx={{
                mb: 1.5
              }}>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: '500',
                    color: 'ltheme.main',
                    lineHeight: '24px',
                  }}
                  variant="body2">
                  Select Industry
                </Typography>
                <FormControl fullWidth
                  sx={{
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: 'none'
                    }
                  }}
                >
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={designation}
                    onChange={handleChange}
                    displayEmpty
                    IconComponent={KeyboardArrowDownOutlinedIcon}
                    sx={{
                      background: "#ECF6FF",
                      '& .MuiSelect-select': {
                        minHeight: "20px",
                        padding: "11px 14px",
                        color: "lightSecondary.main",
                        fontWeight: "600",
                        fontSize: "0.9rem"
                      },
                      '& .MuiSelect-icon': {
                        color: 'lightSecondary.main'
                      },
                    }}
                  >
                    <MenuItem>Select Industry</MenuItem>
                    <MenuItem value={2}>Insurance</MenuItem>
                    <MenuItem value={3}>Citizen service</MenuItem>
                    <MenuItem value={4}>Healthcare</MenuItem>
                    <MenuItem value={5}>Supply chain</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: '500',
                    color: 'ltheme.main',
                    lineHeight: '24px',
                  }}
                  variant="body2">
                  Select Type
                </Typography>
                <FormControl fullWidth
                  sx={{
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: 'none'
                    }
                  }}
                >
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={designation}
                    onChange={handleChange}
                    displayEmpty
                    IconComponent={KeyboardArrowDownOutlinedIcon}
                    sx={{
                      background: "#ECF6FF",
                      '& .MuiSelect-select': {
                        minHeight: "20px",
                        padding: "11px 14px",
                        color: "lightSecondary.main",
                        fontWeight: "600",
                        fontSize: "0.9rem"
                      },
                      '& .MuiSelect-icon': {
                        color: 'lightSecondary.main'
                      },
                    }}
                  >
                    <MenuItem>Select Type</MenuItem>
                    <MenuItem value={12}>All</MenuItem>
                    <MenuItem value={13}>Starred</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box sx={{
                display: 'flex',
                gap: 2,
                mt: 2
              }}>
                <Box sx={{
                  width: '50%'
                }}>
                  <Button variant="outlined"
                    sx={{
                      width: "100%",
                      textTransform: 'inherit',
                      p: "7px 15px"
                    }}
                  >
                    Clear
                  </Button>
                </Box>
                <Box sx={{
                  width: '50%'
                }}>
                  <Button variant="contained"
                    sx={{
                      width: "100%",
                      textTransform: 'inherit',
                      p: "7px 15px",
                      boxShadow: 'none'
                    }}>Apply
                  </Button>
                </Box>
              </Box>
            </Menu>
          </Box>
        </Stack>
      </Stack>
      <Grid container mt={0} columnSpacing={2}>
        <Grid item xs={12} md={6} lg={6}
          sx={{
            mb: 2
          }}
        >
          <Link to="/kanban-solution-enquiries"
            style={{
              textDecoration: 'none'
            }}
          >
            <Card
              sx={{
                boxShadow: 'none',
                borderRadius: "10px"
              }}
            >
              <CardContent
                sx={{
                  p: 0,
                  [theme.breakpoints.down('sm')]: {
                    position: 'relative'
                  }
                }}
                style={{
                  paddingBottom: "0"
                }}
              >
                <Box
                  sx={{
                    px: 2,
                    py: 2,
                    [theme.breakpoints.down('md')]: {
                      py: 3,
                    }
                  }}
                >
                  <Stack
                    direction="row"
                    sx={{
                      alignItems: "center",
                      justifyContent: "space-between",
                      minHeight: '74px',
                      [theme.breakpoints.down('sm')]: {
                        display: 'block'
                      }
                    }}
                  >
                    <Stack
                      direction="row"
                      sx={{
                        alignItems: "center",
                        [theme.breakpoints.down('sm')]: {
                          display: 'block'
                        }
                      }}
                    >
                      <Box
                        sx={{
                          minWidth: '100px',
                          pr: 2,
                          '& img': {
                            [theme.breakpoints.down('sm')]: {
                              width: 'auto !important',
                              maxWidth: '100%',
                              maxHeight: '50px'
                            }
                          }
                        }}
                      >
                        <img style={{ width: '100%' }} src={getImages('image_172.png')} />
                      </Box>
                      <Box>
                        <Typography variant="h6" color="textSecondary"
                          sx={{
                            fontSize: "1.1rem",
                            fontWeight: "700",
                            color: "secondary.main",
                            mb: 0
                          }}
                        >
                          TRUSTDOCK Digital ID
                        </Typography>
                        <Typography variant="body2" color="textSecondary"
                          sx={{
                            fontSize: "0.9rem",
                            fontWeight: "500",
                            color: "secondary.main",
                            minHeight: '44px',
                            [theme.breakpoints.down('sm')]: {
                              minHeight: 'inherit'
                            }
                          }}
                        >
                          As an extension of our services, we offer TRUSTDOCK ID. TRUSTDOCK ID is a secure virtual identity wallet where users-...
                        </Typography>
                      </Box>
                    </Stack>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        alignSelf: 'start',
                        [theme.breakpoints.down('sm')]: {
                          position: 'absolute',
                          top: '15px',
                          right: '10px'
                        }
                      }}
                    >
                      <IconButton
                        sx={{
                          p: 0.5,
                        }}>
                        <OpenInNewOutlinedIcon sx={{ color: 'lightSecondary.main' }} />
                      </IconButton>
                      <IconButton
                        sx={{
                          p: 0.5,
                        }}>
                        <StarOutlinedIcon sx={{ color: 'lightSecondary.main' }} />
                      </IconButton>
                    </Box>
                  </Stack>

                  <Box
                    sx={{
                      mt: 3,
                      [theme.breakpoints.down('md')]: {
                        mt: 1
                      }
                    }}
                  >
                    <Typography
                      noWrap
                      variant="body2"
                      sx={{
                        fontSize: '0.9rem',
                        fontWeight: '600',
                        color: 'lightSecondary.main',
                        width: "100%"

                      }}
                    >Recent Invites :</Typography>
                    <Stack
                      direction='row'
                      sx={{
                        alignItems: "center",
                        mt: 0.7,
                        justifyContent: "space-between"
                      }}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <ListItemAvatar
                          sx={{
                            [theme.breakpoints.down('md')]: {
                              minWidth: '36px'
                            }
                          }}
                        >
                          <Avatar
                            sx={{
                              color: 'success.main',
                              bgcolor: 'success.lighter',
                              width: '48px',
                              height: '48px',
                              [theme.breakpoints.down('md')]: {
                                width: '34px',
                                height: '34px'
                              }
                            }}
                            src={getImages('avatar-1.png')}
                          />
                        </ListItemAvatar>
                        <ListItemAvatar
                          sx={{
                            [theme.breakpoints.down('md')]: {
                              minWidth: '36px'
                            }
                          }}
                        >
                          <Avatar
                            sx={{
                              color: 'success.main',
                              bgcolor: 'success.lighter',
                              width: '48px',
                              height: '48px',
                              [theme.breakpoints.down('md')]: {
                                width: '34px',
                                height: '34px'
                              }
                            }}
                            src={getImages('2.jpg')}
                          />
                        </ListItemAvatar>
                        <ListItemAvatar
                          sx={{
                            width: "48px",
                            [theme.breakpoints.down('md')]: {
                              minWidth: '36px',
                              width: '36px'
                            }
                          }}
                        >
                          <Badge
                            overlap="circular"
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                            badgeContent={
                              <SmallAvatar
                                sx={{
                                  width: "26px",
                                  height: "26px",
                                  [theme.breakpoints.down('md')]: {
                                    width: '22px',
                                    height: '22px'
                                  }
                                }}
                                alt="Remy Sharp" src={getImages('2.jpg')} />
                            }
                          >
                            <Avatar alt="Travis Howard"
                              sx={{
                                color: 'success.main',
                                bgcolor: 'success.lighter',
                                width: '48px',
                                height: '48px',
                                [theme.breakpoints.down('md')]: {
                                  width: '34px',
                                  height: '34px'
                                }
                              }}
                              src={getImages('4.jpg')}
                            />
                          </Badge>
                        </ListItemAvatar>
                        <ListItemAvatar
                          sx={{
                            [theme.breakpoints.down('md')]: {
                              minWidth: '36px'
                            }
                          }}
                        >
                          <Avatar
                            sx={{
                              color: 'success.main',
                              bgcolor: 'success.lighter',
                              width: '48px',
                              height: '48px',
                              [theme.breakpoints.down('md')]: {
                                width: '34px',
                                height: '34px'
                              }
                            }}
                            src={getImages('avatar-1.png')}
                          />
                        </ListItemAvatar>
                        <ListItemAvatar
                          sx={{
                            width: "18px"
                          }}
                        >
                          <Badge
                            overlap="circular">

                            <Badge
                              overlap="circular"
                              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                              badgeContent={
                                <SmallAvatar
                                  sx={{
                                    width: "26px",
                                    height: "26px",
                                    ml: 1.5,
                                    [theme.breakpoints.down('md')]: {
                                      width: '22px',
                                      height: '22px'
                                    }
                                  }}
                                  alt="Remy Sharp" src={getImages('2.jpg')} />
                              }
                            />
                            <Avatar alt="Travis Howard"
                              sx={{
                                color: 'success.main',
                                bgcolor: 'success.lighter',
                                width: '48px',
                                height: '48px',
                                [theme.breakpoints.down('md')]: {
                                  width: '34px',
                                  height: '34px'
                                }
                              }}
                              src={getImages('1.jpg')}
                            />
                            <Badge
                              overlap="circular"
                              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                              badgeContent={
                                <SmallAvatar
                                  sx={{
                                    mr: 1.5
                                  }}
                                  alt="Remy Sharp" src={getImages('2.jpg')} />
                              }
                            />
                          </Badge>
                        </ListItemAvatar>
                      </Box>
                      <Box>
                        <Typography variant="subtitle1"
                          sx={{
                            fontSize: "0.95rem",
                            color: "ltheme.main",
                            fontWeight: "600"
                          }}>
                          <Badge color="secondary" badgeContent=" " variant="dot"
                            sx={{
                              '& .MuiBadge-badge': {
                                backgroundColor: "red.main"
                              }
                            }}
                          >
                            <MessageOutlinedIcon
                              sx={{
                                verticalAlign: "middle",
                                fill: "ltheme.main",
                                width: "20px",
                                height: "20px"
                              }}
                            />
                          </Badge>
                          &nbsp;
                          13
                        </Typography>
                      </Box>
                    </Stack>
                  </Box>
                </Box>
                <Box
                  sx={{
                    pt: 2,
                    px: 2,
                    backgroundColor: "rgba(219, 235, 248, 0.2)",
                    display: "flex",
                    alignItems: "start",
                    justifyContent: "space-between",
                    borderTop: "solid 1px rgba(162, 194, 241, 1)",
                    minHeight: '102px',
                    [theme.breakpoints.down('md')]: {
                      flexWrap: 'wrap',
                      px: 2
                    }
                  }}
                >
                  <Box display={"flex"}
                    sx={{
                      width: '33.33%',
                      pr: 1,
                      [theme.breakpoints.down('md')]: {
                        width: '50%',
                      }
                    }}
                  >
                    <Box>
                      <img style={{ display: "block", marginTop: '7px' }} src={getImages('headquarter_icon.png')} />
                    </Box>
                    <ListItemText
                      sx={{
                        pl: '10px',
                        width: "60%"
                      }}
                    >
                      <Typography noWrap
                        sx={{
                          fontSize: "12px",
                          fontWeight: '700',
                          color: 'secondary.main',
                          lineHeight: '15px',
                        }}
                        variant="h6">Headquarters
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          fontSize: '12px',
                          fontWeight: '500',
                          color: 'lightSecondary.main',
                        }}
                      >London, UK</Typography>
                    </ListItemText>
                  </Box>
                  <Box display={"flex"}
                    sx={{
                      width: '33.33%',
                      pr: 1,
                      [theme.breakpoints.down('md')]: {
                        width: '50%',
                      }
                    }}
                  >
                    <Box>
                      <img style={{ display: "block", marginTop: '7px' }} src={getImages('industry_icon.png')} />
                    </Box>
                    <ListItemText
                      sx={{
                        pl: '10px',
                        width: "60%"
                      }}
                    >
                      <Typography noWrap
                        sx={{
                          fontSize: "12px",
                          fontWeight: '700',
                          color: 'secondary.main',
                          lineHeight: '15px',
                        }}
                        variant="h6">Industries
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          fontSize: '12px',
                          fontWeight: '500',
                          color: 'lightSecondary.main',
                        }}
                      >Finance, Retial</Typography>
                    </ListItemText>
                  </Box>
                  <Box display={"flex"}
                    sx={{
                      width: '33.33%',
                      pr: 1,
                      [theme.breakpoints.down('md')]: {
                        width: '50%',
                      }
                    }}
                  >
                    <Box>
                      <img style={{ display: "block", marginTop: '7px' }} src={getImages('usecase_icon.png')} />
                    </Box>
                    <ListItemText
                      sx={{
                        pl: '10px',
                        width: "60%"
                      }}
                    >
                      <Typography noWrap
                        sx={{
                          fontSize: "12px",
                          fontWeight: '700',
                          color: 'secondary.main',
                          lineHeight: '15px',
                        }}
                        variant="h6">Use Cases
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          fontSize: '12px',
                          fontWeight: '500',
                          color: 'lightSecondary.main',
                        }}
                      >Smart Contract, Fruad Dection FinTech</Typography>
                    </ListItemText>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Link>
        </Grid>
        <Grid item xs={12} md={6} lg={6}
          sx={{
            mb: 2
          }}
        >
          <Link to="/kanban-solution-enquiries"
            style={{
              textDecoration: 'none'
            }}
          >
            <Card
              sx={{
                boxShadow: 'none',
                borderRadius: "10px"
              }}
            >
              <CardContent
                sx={{
                  p: 0,
                  [theme.breakpoints.down('sm')]: {
                    position: 'relative'
                  }
                }}
                style={{
                  paddingBottom: "0"
                }}
              >
                <Box
                  sx={{
                    px: 2,
                    py: 2,
                    [theme.breakpoints.down('md')]: {
                      py: 3,
                    }
                  }}
                >
                  <Stack
                    direction="row"
                    sx={{
                      alignItems: "center",
                      justifyContent: "space-between",
                      minHeight: '74px',
                      [theme.breakpoints.down('sm')]: {
                        display: 'block'
                      }
                    }}
                  >
                    <Stack
                      direction="row"
                      sx={{
                        alignItems: "center",
                        [theme.breakpoints.down('sm')]: {
                          display: 'block'
                        }
                      }}
                    >
                      <Box
                        sx={{
                          minWidth: '100px',
                          pr: 2,
                          '& img': {
                            [theme.breakpoints.down('sm')]: {
                              width: 'auto !important',
                              maxWidth: '100%',
                              maxHeight: '50px'
                            }
                          }
                        }}
                      >
                        <img style={{ width: '100%' }} src={getImages('image_172.png')} />
                      </Box>
                      <Box>
                        <Typography variant="h6" color="textSecondary"
                          sx={{
                            fontSize: "1.1rem",
                            fontWeight: "700",
                            color: "secondary.main",
                            mb: 0
                          }}
                        >
                          KYC as a Service
                        </Typography>
                        <Typography variant="body2" color="textSecondary"
                          sx={{
                            fontSize: "0.9rem",
                            fontWeight: "500",
                            color: "secondary.main",
                            minHeight: '44px',
                            [theme.breakpoints.down('sm')]: {
                              minHeight: 'inherit'
                            }
                          }}
                        >
                          TRUSTDOCK: The e-KYC and Due Diligence You Need.
                        </Typography>
                      </Box>
                    </Stack>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        alignSelf: 'start',
                        [theme.breakpoints.down('sm')]: {
                          position: 'absolute',
                          top: '15px',
                          right: '10px'
                        }
                      }}
                    >
                      <IconButton
                        sx={{
                          p: 0.5,
                        }}>
                        <OpenInNewOutlinedIcon sx={{ color: 'lightSecondary.main' }} />
                      </IconButton>
                      <IconButton
                        sx={{
                          p: 0.5,
                        }}>
                        <StarOutlinedIcon sx={{ color: 'lightSecondary.main' }} />
                      </IconButton>
                    </Box>
                  </Stack>

                  <Box
                    sx={{
                      mt: 3,
                      [theme.breakpoints.down('md')]: {
                        mt: 1
                      }
                    }}
                  >
                    <Typography
                      noWrap
                      variant="body2"
                      sx={{
                        fontSize: '0.9rem',
                        fontWeight: '600',
                        color: 'lightSecondary.main',
                        width: "100%"

                      }}
                    >Recent Invites :</Typography>
                    <Stack
                      direction='row'
                      sx={{
                        alignItems: "center",
                        mt: 0.7,
                        justifyContent: "space-between"
                      }}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <ListItemAvatar
                          sx={{
                            [theme.breakpoints.down('md')]: {
                              minWidth: '36px'
                            }
                          }}
                        >
                          <Avatar
                            sx={{
                              color: 'success.main',
                              bgcolor: 'success.lighter',
                              width: '48px',
                              height: '48px',
                              [theme.breakpoints.down('md')]: {
                                width: '34px',
                                height: '34px'
                              }
                            }}
                            src={getImages('avatar-1.png')}
                          />
                        </ListItemAvatar>
                        <ListItemAvatar
                          sx={{
                            [theme.breakpoints.down('md')]: {
                              minWidth: '36px'
                            }
                          }}
                        >
                          <Avatar
                            sx={{
                              color: 'success.main',
                              bgcolor: 'success.lighter',
                              width: '48px',
                              height: '48px',
                              [theme.breakpoints.down('md')]: {
                                width: '34px',
                                height: '34px'
                              }
                            }}
                            src={getImages('2.jpg')}
                          />
                        </ListItemAvatar>
                        <ListItemAvatar
                          sx={{
                            width: "48px",
                            [theme.breakpoints.down('md')]: {
                              minWidth: '36px',
                              width: '36px'
                            }
                          }}
                        >
                          <Badge
                            overlap="circular"
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                            badgeContent={
                              <SmallAvatar
                                sx={{
                                  width: "26px",
                                  height: "26px",
                                  [theme.breakpoints.down('md')]: {
                                    width: '22px',
                                    height: '22px'
                                  }
                                }}
                                alt="Remy Sharp" src={getImages('2.jpg')} />
                            }
                          >
                            <Avatar alt="Travis Howard"
                              sx={{
                                color: 'success.main',
                                bgcolor: 'success.lighter',
                                width: '48px',
                                height: '48px',
                                [theme.breakpoints.down('md')]: {
                                  width: '34px',
                                  height: '34px'
                                }
                              }}
                              src={getImages('4.jpg')}
                            />
                          </Badge>
                        </ListItemAvatar>
                        <ListItemAvatar
                          sx={{
                            [theme.breakpoints.down('md')]: {
                              minWidth: '36px'
                            }
                          }}
                        >
                          <Avatar
                            sx={{
                              color: 'success.main',
                              bgcolor: 'success.lighter',
                              width: '48px',
                              height: '48px',
                              [theme.breakpoints.down('md')]: {
                                width: '34px',
                                height: '34px'
                              }
                            }}
                            src={getImages('avatar-1.png')}
                          />
                        </ListItemAvatar>
                        <ListItemAvatar
                          sx={{
                            width: "18px"
                          }}
                        >
                          <Badge
                            overlap="circular">

                            <Badge
                              overlap="circular"
                              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                              badgeContent={
                                <SmallAvatar
                                  sx={{
                                    width: "26px",
                                    height: "26px",
                                    ml: 1.5,
                                    [theme.breakpoints.down('md')]: {
                                      width: '22px',
                                      height: '22px'
                                    }
                                  }}
                                  alt="Remy Sharp" src={getImages('2.jpg')} />
                              }
                            />
                            <Avatar alt="Travis Howard"
                              sx={{
                                color: 'success.main',
                                bgcolor: 'success.lighter',
                                width: '48px',
                                height: '48px',
                                [theme.breakpoints.down('md')]: {
                                  width: '34px',
                                  height: '34px'
                                }
                              }}
                              src={getImages('1.jpg')}
                            />
                            <Badge
                              overlap="circular"
                              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                              badgeContent={
                                <SmallAvatar
                                  sx={{
                                    mr: 1.5
                                  }}
                                  alt="Remy Sharp" src={getImages('2.jpg')} />
                              }
                            />
                          </Badge>
                        </ListItemAvatar>
                      </Box>
                      <Box>
                        <Typography variant="subtitle1"
                          sx={{
                            fontSize: "0.95rem",
                            color: "ltheme.main",
                            fontWeight: "600"
                          }}>
                          <MessageOutlinedIcon
                            sx={{
                              verticalAlign: "middle",
                              fill: "ltheme.main",
                              width: "20px",
                              height: "20px"
                            }}
                          />
                          &nbsp;
                          02
                        </Typography>
                      </Box>
                    </Stack>
                  </Box>
                </Box>
                <Box
                  sx={{
                    pt: 2,
                    px: 2,
                    backgroundColor: "rgba(219, 235, 248, 0.2)",
                    display: "flex",
                    alignItems: "start",
                    justifyContent: "space-between",
                    borderTop: "solid 1px rgba(162, 194, 241, 1)",
                    minHeight: '102px',
                    [theme.breakpoints.down('md')]: {
                      flexWrap: 'wrap',
                      px: 2
                    }
                  }}
                >
                  <Box display={"flex"}
                    sx={{
                      width: '33.33%',
                      pr: 1,
                      [theme.breakpoints.down('md')]: {
                        width: '50%',
                      }
                    }}
                  >
                    <Box>
                      <img style={{ display: "block", marginTop: '7px' }} src={getImages('headquarter_icon.png')} />
                    </Box>
                    <ListItemText
                      sx={{
                        pl: '10px',
                        width: "60%"
                      }}
                    >
                      <Typography noWrap
                        sx={{
                          fontSize: "12px",
                          fontWeight: '700',
                          color: 'secondary.main',
                          lineHeight: '15px',
                        }}
                        variant="h6">Headquarters
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          fontSize: '12px',
                          fontWeight: '500',
                          color: 'lightSecondary.main',
                        }}
                      >Tokyo, Japan</Typography>
                    </ListItemText>
                  </Box>
                  <Box display={"flex"}
                    sx={{
                      width: '33.33%',
                      pr: 1,
                      [theme.breakpoints.down('md')]: {
                        width: '50%',
                      }
                    }}
                  >
                    <Box>
                      <img style={{ display: "block", marginTop: '7px' }} src={getImages('industry_icon.png')} />
                    </Box>
                    <ListItemText
                      sx={{
                        pl: '10px',
                        width: "60%"
                      }}
                    >
                      <Typography noWrap
                        sx={{
                          fontSize: "12px",
                          fontWeight: '700',
                          color: 'secondary.main',
                          lineHeight: '15px',
                        }}
                        variant="h6">Industries
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          fontSize: '12px',
                          fontWeight: '500',
                          color: 'lightSecondary.main',
                        }}
                      >Fintech , Insurance , Citizen Service</Typography>
                    </ListItemText>
                  </Box>
                  <Box display={"flex"}
                    sx={{
                      width: '33.33%',
                      pr: 1,
                      [theme.breakpoints.down('md')]: {
                        width: '50%',
                      }
                    }}
                  >
                    <Box>
                      <img style={{ display: "block", marginTop: '7px' }} src={getImages('usecase_icon.png')} />
                    </Box>
                    <ListItemText
                      sx={{
                        pl: '10px',
                        width: "60%"
                      }}
                    >
                      <Typography noWrap
                        sx={{
                          fontSize: "12px",
                          fontWeight: '700',
                          color: 'secondary.main',
                          lineHeight: '15px',
                        }}
                        variant="h6">Use Cases
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          fontSize: '12px',
                          fontWeight: '500',
                          color: 'lightSecondary.main',
                        }}
                      >Fraud Detection , Identity and Verification , Decentralized Storage</Typography>
                    </ListItemText>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Link>
        </Grid>
        <Grid item xs={12} md={6} lg={6}
          sx={{
            mb: 2
          }}
        >
          <Link to="/kanban-solution-enquiries"
            style={{
              textDecoration: 'none'
            }}
          >
            <Card
              sx={{
                boxShadow: 'none',
                borderRadius: "10px"
              }}
            >
              <CardContent
                sx={{
                  p: 0,
                  [theme.breakpoints.down('sm')]: {
                    position: 'relative'
                  }
                }}
                style={{
                  paddingBottom: "0"
                }}
              >
                <Box
                  sx={{
                    px: 2,
                    py: 2,
                    [theme.breakpoints.down('md')]: {
                      py: 3,
                    }
                  }}
                >
                  <Stack
                    direction="row"
                    sx={{
                      alignItems: "center",
                      justifyContent: "space-between",
                      minHeight: '74px',
                      [theme.breakpoints.down('sm')]: {
                        display: 'block'
                      }
                    }}
                  >

                    <Stack
                      direction="row"
                      sx={{
                        alignItems: "center",
                        [theme.breakpoints.down('sm')]: {
                          display: 'block'
                        }
                      }}
                    >
                      <Box
                        sx={{
                          minWidth: '100px',
                          pr: 2,
                          '& img': {
                            [theme.breakpoints.down('sm')]: {
                              width: 'auto !important',
                              maxWidth: '100%',
                              maxHeight: '50px'
                            }
                          }
                        }}
                      >
                        <img style={{ width: '100%' }} src={getImages('image_172.png')} />
                      </Box>
                      <Box>
                        <Typography variant="h6" color="textSecondary"
                          sx={{
                            fontSize: "1.1rem",
                            fontWeight: "700",
                            color: "secondary.main",
                            mb: 0
                          }}
                        >
                          Aalpha information systems
                        </Typography>
                        <Typography variant="body2" color="textSecondary"
                          sx={{
                            fontSize: "0.9rem",
                            fontWeight: "500",
                            color: "secondary.main",
                            minHeight: '44px',
                            [theme.breakpoints.down('sm')]: {
                              minHeight: 'inherit'
                            }
                          }}
                        >
                          Aalpha info is a global blockchain platform that offers payroll, asset management, settlement clearing, and securitization...
                        </Typography>
                      </Box>
                    </Stack>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        alignSelf: 'start',
                        [theme.breakpoints.down('sm')]: {
                          position: 'absolute',
                          top: '15px',
                          right: '10px'
                        }
                      }}
                    >
                      <IconButton
                        sx={{
                          p: 0.5,
                        }}>
                        <OpenInNewOutlinedIcon sx={{ color: 'lightSecondary.main' }} />
                      </IconButton>
                      <IconButton
                        sx={{
                          p: 0.5,
                        }}>
                        <StarOutlinedIcon sx={{ color: 'lightSecondary.main' }} />
                      </IconButton>
                    </Box>
                  </Stack>

                  <Box
                    sx={{
                      mt: 3,
                      [theme.breakpoints.down('md')]: {
                        mt: 1
                      }
                    }}
                  >
                    <Typography
                      noWrap
                      variant="body2"
                      sx={{
                        fontSize: '0.9rem',
                        fontWeight: '600',
                        color: 'lightSecondary.main',
                        width: "100%"

                      }}
                    >Recent Invites :</Typography>
                    <Stack
                      direction='row'
                      sx={{
                        alignItems: "center",
                        mt: 0.7,
                        justifyContent: "space-between"
                      }}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <ListItemAvatar
                          sx={{
                            [theme.breakpoints.down('md')]: {
                              minWidth: '36px'
                            }
                          }}
                        >
                          <Avatar
                            sx={{
                              color: 'success.main',
                              bgcolor: 'success.lighter',
                              width: '48px',
                              height: '48px',
                              [theme.breakpoints.down('md')]: {
                                width: '34px',
                                height: '34px'
                              }
                            }}
                            src={getImages('avatar-1.png')}
                          />
                        </ListItemAvatar>
                        <ListItemAvatar
                          sx={{
                            [theme.breakpoints.down('md')]: {
                              minWidth: '36px'
                            }
                          }}
                        >
                          <Avatar
                            sx={{
                              color: 'success.main',
                              bgcolor: 'success.lighter',
                              width: '48px',
                              height: '48px',
                              [theme.breakpoints.down('md')]: {
                                width: '34px',
                                height: '34px'
                              }
                            }}
                            src={getImages('2.jpg')}
                          />
                        </ListItemAvatar>
                        <ListItemAvatar
                          sx={{
                            width: "48px",
                            [theme.breakpoints.down('md')]: {
                              minWidth: '36px',
                              width: '36px'
                            }
                          }}
                        >
                          <Badge
                            overlap="circular"
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                            badgeContent={
                              <SmallAvatar
                                sx={{
                                  width: "26px",
                                  height: "26px",
                                  [theme.breakpoints.down('md')]: {
                                    width: '22px',
                                    height: '22px'
                                  }
                                }}
                                alt="Remy Sharp" src={getImages('2.jpg')} />
                            }
                          >
                            <Avatar alt="Travis Howard"
                              sx={{
                                color: 'success.main',
                                bgcolor: 'success.lighter',
                                width: '48px',
                                height: '48px',
                                [theme.breakpoints.down('md')]: {
                                  width: '34px',
                                  height: '34px'
                                }
                              }}
                              src={getImages('4.jpg')}
                            />
                          </Badge>
                        </ListItemAvatar>
                        <ListItemAvatar
                          sx={{
                            [theme.breakpoints.down('md')]: {
                              minWidth: '36px'
                            }
                          }}
                        >
                          <Avatar
                            sx={{
                              color: 'success.main',
                              bgcolor: 'success.lighter',
                              width: '48px',
                              height: '48px',
                              [theme.breakpoints.down('md')]: {
                                width: '34px',
                                height: '34px'
                              }
                            }}
                            src={getImages('avatar-1.png')}
                          />
                        </ListItemAvatar>
                        <ListItemAvatar
                          sx={{
                            width: "18px"
                          }}
                        >
                          <Badge
                            overlap="circular">

                            <Badge
                              overlap="circular"
                              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                              badgeContent={
                                <SmallAvatar
                                  sx={{
                                    width: "26px",
                                    height: "26px",
                                    ml: 1.5,
                                    [theme.breakpoints.down('md')]: {
                                      width: '22px',
                                      height: '22px'
                                    }
                                  }}
                                  alt="Remy Sharp" src={getImages('2.jpg')} />
                              }
                            />
                            <Avatar alt="Travis Howard"
                              sx={{
                                color: 'success.main',
                                bgcolor: 'success.lighter',
                                width: '48px',
                                height: '48px',
                                [theme.breakpoints.down('md')]: {
                                  width: '34px',
                                  height: '34px'
                                }
                              }}
                              src={getImages('1.jpg')}
                            />
                            <Badge
                              overlap="circular"
                              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                              badgeContent={
                                <SmallAvatar
                                  sx={{
                                    mr: 1.5
                                  }}
                                  alt="Remy Sharp" src={getImages('2.jpg')} />
                              }
                            />
                          </Badge>
                        </ListItemAvatar>
                      </Box>
                      <Box>
                        <Typography variant="subtitle1"
                          sx={{
                            fontSize: "0.95rem",
                            color: "ltheme.main",
                            fontWeight: "600"
                          }}>
                          <Badge color="secondary" badgeContent=" " variant="dot"
                            sx={{
                              '& .MuiBadge-badge': {
                                backgroundColor: "red.main"
                              }
                            }}
                          >
                            <MessageOutlinedIcon
                              sx={{
                                verticalAlign: "middle",
                                fill: "ltheme.main",
                                width: "20px",
                                height: "20px"
                              }}
                            />
                          </Badge>
                          &nbsp;
                          13
                        </Typography>
                      </Box>
                    </Stack>
                  </Box>
                </Box>
                <Box
                  sx={{
                    pt: 2,
                    px: 2,
                    backgroundColor: "rgba(219, 235, 248, 0.2)",
                    display: "flex",
                    alignItems: "start",
                    justifyContent: "space-between",
                    borderTop: "solid 1px rgba(162, 194, 241, 1)",
                    minHeight: '102px',
                    [theme.breakpoints.down('md')]: {
                      flexWrap: 'wrap',
                      px: 2
                    }
                  }}
                >
                  <Box display={"flex"}
                    sx={{
                      width: '33.33%',
                      pr: 1,
                      [theme.breakpoints.down('md')]: {
                        width: '50%',
                      }
                    }}
                  >
                    <Box>
                      <img style={{ display: "block", marginTop: '7px' }} src={getImages('headquarter_icon.png')} />
                    </Box>
                    <ListItemText
                      sx={{
                        pl: '10px',
                        width: "60%"
                      }}
                    >
                      <Typography noWrap
                        sx={{
                          fontSize: "12px",
                          fontWeight: '700',
                          color: 'secondary.main',
                          lineHeight: '15px',
                        }}
                        variant="h6">Headquarters
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          fontSize: '12px',
                          fontWeight: '500',
                          color: 'lightSecondary.main',
                        }}
                      >Tokyo, Japan</Typography>
                    </ListItemText>
                  </Box>
                  <Box display={"flex"}
                    sx={{
                      width: '33.33%',
                      pr: 1,
                      [theme.breakpoints.down('md')]: {
                        width: '50%',
                      }
                    }}
                  >
                    <Box>
                      <img style={{ display: "block", marginTop: '7px' }} src={getImages('industry_icon.png')} />
                    </Box>
                    <ListItemText
                      sx={{
                        pl: '10px',
                        width: "60%"
                      }}
                    >
                      <Typography noWrap
                        sx={{
                          fontSize: "12px",
                          fontWeight: '700',
                          color: 'secondary.main',
                          lineHeight: '15px',
                        }}
                        variant="h6">Industries
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          fontSize: '12px',
                          fontWeight: '500',
                          color: 'lightSecondary.main',
                        }}
                      >Fintech , Healthcare , Supply Chain , Citizen Service</Typography>
                    </ListItemText>
                  </Box>
                  <Box display={"flex"}
                    sx={{
                      width: '33.33%',
                      pr: 1,
                      [theme.breakpoints.down('md')]: {
                        width: '50%',
                      }
                    }}
                  >
                    <Box>
                      <img style={{ display: "block", marginTop: '7px' }} src={getImages('usecase_icon.png')} />
                    </Box>
                    <ListItemText
                      sx={{
                        pl: '10px',
                        width: "60%"
                      }}
                    >
                      <Typography noWrap
                        sx={{
                          fontSize: "12px",
                          fontWeight: '700',
                          color: 'secondary.main',
                          lineHeight: '15px',
                        }}
                        variant="h6">Use Cases
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          fontSize: '12px',
                          fontWeight: '500',
                          color: 'lightSecondary.main',
                        }}
                      >DeFi , Auto-Settlement , Wallets & Exchanges</Typography>
                    </ListItemText>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Link>
        </Grid>
      </Grid>
    </>
  )
}

export default BsiCoLabSolutionEnquiries