import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  MenuItem,
  Typography,
  Menu,
  Paper,
  ListItemButton,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import FolderIcon from "@mui/icons-material/Folder";
import StarIcon from "@mui/icons-material/Star";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import DriveFileMoveOutlinedIcon from "@mui/icons-material/DriveFileMoveOutlined";
import ColorLensOutlinedIcon from "@mui/icons-material/ColorLensOutlined";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useDispatch, useSelector } from "react-redux";
import {
  useFetchDriveFolderListQuery,
  useFetchDriveItemListQuery,
} from "../../redux/api/BsiDrive/getDriveFolderList";
import {
  resetRefetchDocument,
  setRefetchDocument,
  setRefetchFolder,
} from "../../redux/features/refetch";
import { toast, ToastContainer } from "react-toastify";
import NewFolderModal from "./NewFolderModal";
import RenameModal from "./RenameModal";
import MoveModal from "./MoveModal";
import ShareModal from "./ShareModal";
import ShareSelectedBoardModal from "./ShareSelectedBoardModal";
import ShareSolutionChatModal from "./ShareSolutionChatModal";
import Modals from "../Modals";
import DeleteFolderAlert from "./DeleteFolderAlert";
import {Link, useNavigate } from "react-router-dom";
import {
  clearFolderData,
  getDocumentStatus,
  getFolderId,
  getFolderName,
} from "../../redux/features/driveSlice";
import { hideLoader, showLoader } from "../../redux/features/loader";
import NoDataPage from "../../utils/Error/noDataFound";
import { getImages } from "../const";
import { KeyboardArrowRight } from "@mui/icons-material";



const DriveFolder = ({ showToastMessage, searchValue,refetch,setDocumentId,documentId,driveItemData }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  // getting userId from localStorage
  const userId = localStorage.getItem("userId");
  const isDrawerOpen = useSelector((state) => state.drawer.open);
  const [anchorElFolderDropdown, setAnchorElFolderDropdown] = useState(null);
  const openFolderDropdown = Boolean(anchorElFolderDropdown);

  const handleFolderDropdownClick = (event) => {
    setAnchorElFolderDropdown(event.currentTarget);
  };
  const handleFolderDropdownClose = () => {
    setAnchorElFolderDropdown(null);
  };
  // Open Other Options
  const [anchorElOtherOptions, setAnchorElOtherOptions] = useState(null);
  const openOtherOptions = Boolean(anchorElOtherOptions);
  const [folderId, setFolderDocumentId] = useState("");
  const [folderName, setFolderDocumentName] = useState("");
  const [parentId , setParentId]  = useState("");
  const [documentName, setDocumentName] = useState("");
  const handleOtherOptionsClick = (
    event,
    documentId,
    documentName,
    documentStatus,
    parentId,
  ) => {
    setAnchorElOtherOptions(event.currentTarget);
    setFolderDocumentId(documentId);
    setFolderDocumentName(documentName);
    setParentId(parentId);
    dispatch(getFolderName(documentName));
    dispatch(getFolderId(documentId));
    dispatch(getDocumentStatus(documentStatus));
  };
  const handleOtherOptionsClose = () => {
    setAnchorElOtherOptions(null);
  };

  // Open New Folder
  const [openNewFolder, setOpenNewFolder] = useState(false);
  const handleNewFolder = () => {
    setOpenNewFolder(true);
  };
  const closeNewFolder = () => {
    setOpenNewFolder(false);
  };

  // Open Rename
  const [openRename, setOpenRename] = useState(false);
  const handleRename = () => {
    setOpenRename(true);
  };
  const closeRename = () => {
    setOpenRename(false);
  };
  // Open Move
  const [openMove, setOpenMove] = useState(false);

  const handleMove = () => {
    setOpenMove(true);
  };
  const closeMove = () => {
    setOpenMove(false);
  };
  // Open Share
  const [openShare, setOpenShare] = useState(false);
  const handleShare = () => {
    setOpenShare(true);
  };
  const closeShare = () => {
    setOpenShare(false);
  };
  // Share Selected Borad
  const [openSelectedBoard, setOpenSelectedBoard] = useState(false);
  const selectedBoardOpen = () => {
    setOpenSelectedBoard(true);
    setOpenShare(false);
  };
  const closeSelectedBoard = () => {
    setOpenSelectedBoard(false);
  };
  // Share Solution Chat
  const [openShareChat, setOpenShareChat] = useState(false);
  const shareChatOpen = () => {
    setOpenShareChat(true);
    setOpenSelectedBoard(false);
  };
  const closeShareChat = () => {
    setOpenShareChat(false);
  };
  // Delete folder alert
  // Delete list1Alert Modal
  const [openDeleteFolderAlert, setOpenDeleteFolderAlert] = useState(false);
  const deleteFolderAlert = () => {
    setOpenDeleteFolderAlert(true);
  };
  const goBackFromAlertHandle = () => {
    setOpenDeleteFolderAlert(false);
  };
  const [searchParams, setSearchParams] = useState("");
  const [history, setHistory] = useState([
    { id:documentId , name: documentName },
  ]);

  const refetchDocument = useSelector((state) => state.refetch.refetchDocument);
  useEffect(() => {
    if (refetchDocument) {
      refetch().finally(() => {
        dispatch(resetRefetchDocument());
      });
    }
  }, [refetchDocument, refetch, dispatch]);

  const navigate = useNavigate();
  const handleFolderOpen = (documentId, documentName) => {
    setDocumentId(documentId)
    setDocumentName(documentName)
    setHistory((prev) => [...prev, { id: documentId, name: documentName }]);
  };

  useEffect(() => {
    // Check if the current route is not '/bsi-sub-drive'
    if (location.pathname !== "/bsi-sub-drive") {
      dispatch(clearFolderData());
    }
  }, [location.pathname, dispatch]);


  const handlePreviousClick = (item, index) => {
    setHistory((prev) => prev.slice(0, index + 1));
    setDocumentId(item.id)
    refetch();
  };


 
  return (
    <>
      <Box
        sx={{
          mt: 4,
        }}
      >
        <Typography
          variant="h6"
          sx={{
            display: "flex",
            alignItems: "center",
            color: "secondary.main",
            fontSize: "1.1rem",
            fontWeight: "700",
            mb: 1,
          }}
        >
          {history.length > 0 && (
               <Link
               to="/bsi-drive"
               style={{
                 textDecoration: "none",
                 color: theme.palette.secondary.main, // Use the theme color
               }}
               className="ud-link"
               onClick={(e) => {
                 handlePreviousClick({ id: "", name: "All Folders" }, 0);
                 localStorage.removeItem("parentId");
               }}
             >
               All Folders
             </Link>
            )}

            {history?.map((item, pos) => {
              return (pos >= 1 ? <>
                  <KeyboardArrowRight />
                  <Link
                    to="/bsi-drive"
                    style={{
                      textDecoration: "none",
                      color: theme.palette.secondary.main,
                    }}
                    className="ud-link"
                    onClick={(e) => handlePreviousClick(item, pos)}
                  >
                    {item.name}
                  </Link>{" "}
                  </> : <></>
              )
            })}

          {/* <div
                        style={{
                            position: 'relative'
                        }}
                    >
                        <Button
                            id="basic-button1"
                            aria-controls={openFolderDropdown ? 'basic-menu1' : undefined}
                            aria-haspopup="true"
                            aria-expanded={openFolderDropdown ? 'true' : undefined}
                            onClick={handleFolderDropdownClick}
                            sx={{
                                p: '10px 0',
                                minWidth: '10px',
                                width: '100%',
                                verticalAlign: '-5px',
                                display: 'flex',
                                justifyContent: 'space-between',
                                color: 'secondary.main'
                            }}
                        >
                            <MoreVertOutlinedIcon
                                sx={{
                                    fontSize: "1.2rem",
                                }}
                            />
                        </Button>
                        <Menu
                            id="basic-menu1"
                            anchorEl={anchorElFolderDropdown}
                            open={openFolderDropdown}
                            onClose={handleFolderDropdownClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button1',
                            }}
                            sx={{
                                '& .MuiMenu-paper': {
                                    borderRadius: "4px",
                                    border: "none",
                                    minWidth: "210px",
                                    p: 0.5,
                                }
                            }}
                        >
                            <Paper
                                sx={{
                                    width: 210,
                                    maxWidth: '100%',
                                    background: "#fff",
                                    borderRadius: "10px",
                                    borderBottom: "none",
                                    boxShadow: "none",
                                    p: 0.5
                                }}>
                                <MenuItem onClick={handleFolderDropdownClose}
                                    sx={{
                                        fontSize: "0.9rem",
                                        fontWeight: "600",
                                        lineHeight: "20px",
                                        color: "lightSecondary.main",
                                        py: 1,
                                        px: 1.4,
                                        '&:hover': {
                                            backgroundColor: 'extraLTheme.main'
                                        },
                                        '&.active': {
                                            backgroundColor: 'primary.main',
                                            color: "#fff",
                                            borderRadius: "10px"
                                        }
                                    }}
                                >All</MenuItem>
                                <MenuItem onClick={handleFolderDropdownClose}
                                    sx={{
                                        fontSize: "0.9rem",
                                        fontWeight: "600",
                                        lineHeight: "20px",
                                        color: "lightSecondary.main",
                                        py: 1,
                                        px: 1.4,
                                        '&:hover': {
                                            backgroundColor: 'extraLTheme.main'
                                        },
                                        '&.active': {
                                            backgroundColor: 'primary.main',
                                            color: "#fff",
                                            borderRadius: "10px"
                                        }
                                    }}
                                >Starred</MenuItem>
                                <MenuItem onClick={handleFolderDropdownClose}
                                    sx={{
                                        fontSize: "0.9rem",
                                        fontWeight: "600",
                                        lineHeight: "20px",
                                        color: "lightSecondary.main",
                                        py: 1,
                                        px: 1.4,
                                        '&:hover': {
                                            backgroundColor: 'extraLTheme.main'
                                        },
                                        '&.active': {
                                            backgroundColor: 'primary.main',
                                            color: "#fff",
                                            borderRadius: "10px"
                                        }
                                    }}
                                >Archived</MenuItem>
                                <MenuItem onClick={handleFolderDropdownClose}
                                    sx={{
                                        fontSize: "0.9rem",
                                        fontWeight: "600",
                                        lineHeight: "20px",
                                        color: "lightSecondary.main",
                                        py: 1,
                                        px: 1.4,
                                        '&:hover': {
                                            backgroundColor: 'extraLTheme.main'
                                        },
                                        '&.active': {
                                            backgroundColor: 'primary.main',
                                            color: "#fff",
                                            borderRadius: "10px"
                                        }
                                    }}
                                >Recently deleted</MenuItem>
                            </Paper>
                        </Menu>
                    </div> */}
        </Typography>
        <Box
          sx={{
            flexWrap: "wrap",
            gap: 1,
            display: "inline-flex",
            width: "100%"
          }}
        >
          <ListItemButton
            onClick={handleNewFolder}
            sx={{
              border: "solid 1px #A2C2F1",
              minWidth: "240px",
              maxWidth: "240px",
              background: "#ECF6FF",
              padding: "14px 20px 14px 20px",
              borderRadius: "10px",
              display: "inline-block",
              width: "auto",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              color: "lightSecondary.main",
              fontWeight: "600",
              fontSize: "1rem",
              flexGrow: "inherit",
              "@media (max-width:1600px) and (min-width:1200px)": {
                minWidth: "240px",
                maxWidth: "100%",
                width: "calc(20% - 15px)",
                padding: "14px 13px",
              },
              [theme.breakpoints.down("md")]: {
                minWidth: "auto",
                maxWidth: "100%",
                width: "calc(50% - 10px)",
              },
              [theme.breakpoints.down("sm")]: {
                width: "100%",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <AddOutlinedIcon
                sx={{
                  mr: 1.2,
                  verticalAlign: "0px",
                }}
              />
              <Typography
                variant="h6"
                noWrap
                sx={{
                  maxWidth: isDrawerOpen ? "150px" : "150px",
                  "@media (max-width:1400px) and (min-width:1200px)": {
                    maxWidth: isDrawerOpen ? "140px" : "140px",
                  },
                  color: "lightSecondary.main",
                  fontWeight: "600",
                  fontSize: "1rem",
                }}
              >
                Create New Folder
              </Typography>
            </Box>
          </ListItemButton>
          {driveItemData && driveItemData?.data?.folders?.length > 0 ? (
            driveItemData.data.folders
              .filter((folder) => folder.isDocument === true) // Filter out items that are not documents
              .map((folder, index) => (
                <ListItemButton
                  key={folder._id + folder.name}
                  index={index}
                  sx={{
                    border: "solid 1px #A2C2F1",
                    minWidth: "240px",
                    maxWidth: "240px",
                    background: "#ECF6FF",
                    padding: "14px 20px 14px 20px",
                    borderRadius: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    color: "lightSecondary.main",
                    fontWeight: "600",
                    fontSize: "1rem",
                    flexGrow: "inherit",
                    "@media (max-width:1600px) and (min-width:1200px)": {
                      minWidth: "240px",
                      maxWidth: "100%",
                      width: "calc(20% - 15px)",
                      padding: "14px 13px",
                    },
                    [theme.breakpoints.down("md")]: {
                      minWidth: "auto",
                      maxWidth: "100%",
                      width: "calc(50% - 10px)",
                    },
                    [theme.breakpoints.down("sm")]: {
                      width: "100%",
                    },
                  }} 
                  onClick={() => handleFolderOpen(folder._id, folder.name)}
                >
                  <Box
                    noWrap
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                      height:'100%',
                      zIndex: 0,
                    }}
                    
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <FolderIcon sx={{ mr: 1.2, verticalAlign: "0px" }} />
                      <Typography
                        variant="h6"
                        noWrap
                        sx={{
                          maxWidth: isDrawerOpen ? "120px" : "150px",
                          "@media (max-width:1400px) and (min-width:1200px)": {
                            maxWidth: isDrawerOpen ? "120px" : "120px",
                          },
                          "@media (max-width:1200px) and (min-width: 900px)": {
                            maxWidth: isDrawerOpen ? "80px" : "100px",
                          },
                          color: "lightSecondary.main",
                          fontWeight: "600",
                          fontSize: "1rem",
                        }}
                      >
                       <Tooltip title={folder.name}> {folder.name}</Tooltip>
                      </Typography>
                    </Box>
                  </Box>
                  <Button
                    id="basic-menu1"
                    aria-controls={openOtherOptions ? "basic-menu1" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openOtherOptions ? "true" : undefined}
                    onClick={(event) => {
                      event.stopPropagation();  // Prevents handleFolderOpen from being triggered
                      handleOtherOptionsClick(
                        event,
                        folder._id,
                        folder.name,
                        folder.isDocument,
                        folder.parent_id,
                      );
                    }}
                    sx={{
                      p: "1.3rem 1rem",
                      minWidth: "10px",
                      position: "absolute",
                      right: 0,
                      verticalAlign: "-5px",
                      display: "flex",
                      justifyContent: "space-between",
                      color: "secondary.main",
                      zIndex: 1,
                      borderRadius: "0 10px 10px 0",
                    }}
                  >
                    <MoreVertOutlinedIcon
                      sx={{ verticalAlign: "0px", fontSize: "1.1rem" }}
                    />
                  </Button>
                  <div style={{ position: "relative" }}>
                    <Menu
                      id={`${folder._id}${folder.name}`}
                      key={`${folder._id}${folder.name}`}
                      anchorEl={anchorElOtherOptions}
                      open={openOtherOptions}
                      onClose={(event) => {
                        event.stopPropagation();  // Prevents handleFolderOpen from being triggered
                        handleOtherOptionsClose(event);
                      }}
                      MenuListProps={{
                        "aria-labelledby": "basic-button1",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      sx={{
                        top: "2px",
                        left: "60px !important",
                        "& .MuiMenu-paper": {
                          borderRadius: "4px",
                          border: "none",
                          minWidth: 260,
                          p: 0.5,
                        },
                      }}
                    >
                      <Paper
                        sx={{
                          width: 260,
                          maxWidth: "100%",
                          background: "#fff",
                          borderRadius: "10px",
                          borderBottom: "none",
                          boxShadow: "none",
                          p: 0.5,
                        }}
                      >
                        <MenuItem
                           onClick={(event) => {
                            event.stopPropagation();  // Prevents handleFolderOpen from being triggered
                            handleOtherOptionsClose();
                            handleShare();
                          }}
                          sx={{
                            fontSize: "0.9rem",
                            fontWeight: "600",
                            lineHeight: "20px",
                            color: "lightSecondary.main",
                            py: 1,
                            px: 1.4,
                            "&:hover": {
                              backgroundColor: "extraLTheme.main",
                            },
                            "&.active": {
                              backgroundColor: "primary.main",
                              color: "#fff",
                              borderRadius: "10px",
                            },
                          }}
                        >
                          <PersonAddAltOutlinedIcon sx={{ mr: 1 }} /> Share
                        </MenuItem>
                        <MenuItem
                          onClick={(event) => {
                            event.stopPropagation(); 
                            handleOtherOptionsClose();
                            handleMove();
                          }}
                          sx={{
                            fontSize: "0.9rem",
                            fontWeight: "600",
                            lineHeight: "20px",
                            color: "lightSecondary.main",
                            py: 1,
                            px: 1.4,
                            "&:hover": {
                              backgroundColor: "extraLTheme.main",
                            },
                            "&.active": {
                              backgroundColor: "primary.main",
                              color: "#fff",
                              borderRadius: "10px",
                            },
                          }}
                        >
                          <DriveFileMoveOutlinedIcon sx={{ mr: 1 }} /> Move to
                        </MenuItem>
                        <MenuItem
                          onClick={(event) => {
                            event.stopPropagation(); 
                            handleRename();
                            handleOtherOptionsClose();
                          }}
                          sx={{
                            fontSize: "0.9rem",
                            fontWeight: "600",
                            lineHeight: "20px",
                            color: "lightSecondary.main",
                            py: 1,
                            px: 1.4,
                            "&:hover": {
                              backgroundColor: "extraLTheme.main",
                            },
                            "&.active": {
                              backgroundColor: "primary.main",
                              color: "#fff",
                              borderRadius: "10px",
                            },
                          }}
                        >
                          <BorderColorOutlinedIcon sx={{ mr: 1 }} /> Rename
                        </MenuItem>
                        <MenuItem
                          onClick={(event) => {
                            event.stopPropagation(); 
                            handleOtherOptionsClose();
                            deleteFolderAlert();
                          }}
                          sx={{
                            fontSize: "0.9rem",
                            fontWeight: "600",
                            lineHeight: "20px",
                            color: "lightSecondary.main",
                            py: 1,
                            px: 1.4,
                            "&:hover": {
                              backgroundColor: "extraLTheme.main",
                            },
                            "&.active": {
                              backgroundColor: "primary.main",
                              color: "#fff",
                              borderRadius: "10px",
                            },
                          }}
                        >
                          <DeleteOutlineOutlinedIcon sx={{ mr: 1 }} /> Delete
                        </MenuItem>
                      </Paper>
                    </Menu>
                  </div>
                </ListItemButton>
              ))
          ) : (
            // <Box
            //   sx={{
            //     display: "flex",
            //     justifyContent: "center", // Center horizontally
            //     alignItems: "center", // Center vertically
            //     height: "100%", // Take full available height
            //     width: "100%", // Take full available width
            //   }}
            // >
            //   <img
            //     src={getImages("noDataImage.gif")}
            //     alt="No Data Found"
            //     style={{
            //       maxWidth: "100%",
            //       maxHeight: "calc(100vh - 110px)",
            //       mixBlendMode: "multiply",
            //     }}
            //   />
            // </Box>
            <>
            </>
          )}
        </Box>
      </Box>
      {/* New Folder Modal */}
      <Modals
        open={openNewFolder}
        modalWidth={480}
        handleClose={closeNewFolder}
      >
        <NewFolderModal
          handleClose={closeNewFolder}
          documentId={documentId}
          showToastMessage={showToastMessage}
        />
      </Modals>
      <Modals open={openRename} modalWidth={480} handleClose={closeRename}>
        <RenameModal
          handleClose={closeRename}
          documentId={folderId}
          documentName={folderName}
          showToastMessage={showToastMessage}
        />
      </Modals>
      <Modals open={openMove} modalWidth={500} handleClose={closeMove}>
        <MoveModal
          handleClose={closeMove}
          documentId={folderId}
          documentName={folderName}
          showToastMessage={showToastMessage}
          parentId={parentId}
        />
      </Modals>
      <Modals open={openShare} handleClose={closeShare} modalWidth={480}>
        <ShareModal
          handleClose={closeShare}
          selectedBoardOpen={selectedBoardOpen}
          showToastMessage={showToastMessage}
          folderName={folderName}
        />
      </Modals>
      <Modals
        open={openSelectedBoard}
        handleClose={closeSelectedBoard}
        modalWidth={480}
      >
        <ShareSelectedBoardModal
          handleClose={closeSelectedBoard}
          shareChatOpen={shareChatOpen}
          showToastMessage={showToastMessage}
        />
      </Modals>
      <Modals
        open={openShareChat}
        handleClose={closeShareChat}
        modalWidth={480}
      >
        <ShareSolutionChatModal
          showToastMessage={showToastMessage}
          handleClose={closeShareChat}
        />
      </Modals>
      <Modals
        open={openDeleteFolderAlert}
        handleClose={goBackFromAlertHandle}
        modalWidth={500}
      >
        <DeleteFolderAlert
          handleClose={goBackFromAlertHandle}
          documentId={folderId}
          goBackFromAlertHandle={goBackFromAlertHandle}
          showToastMessage={showToastMessage}
        />
      </Modals>
    </>
  );
};

export default DriveFolder;
