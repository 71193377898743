import React, { useState } from 'react'
import {
    Box,
    Typography,
    Button,
    IconButton,
    Stack,
    ListItemAvatar,
    ListItemText,
    Avatar,
} from '@mui/material'
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import { getImages } from '../const';
import EmailIcon from '@mui/icons-material/Email';
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useTheme } from '@emotion/react';
const ReviewInviteModal = () => {
    const theme = useTheme();
    return (
        <>
            <Box
                sx={{
                    mb: 1
                }}
            >
                <Typography variant='h5'
                    sx={{
                        fontSize: "1.3rem",
                        color: "secondary.main",
                        fontWeight: "700"
                    }}>
                    AsianWeb Technologies
                    <IconButton
                        sx={{
                            p: 0,
                            ml: 1
                        }}>
                        <OpenInNewOutlinedIcon sx={{ color: 'lightSecondary.main', fontSize: '16px' }} />
                    </IconButton>
                </Typography>
            </Box>
            <Stack
                direction="row"
                sx={{
                    padding: '10px 0',
                    borderRadius: '10px',
                    background: '#fff',
                    alignItems: 'center',
                }}
            >
                <ListItemAvatar
                    sx={{
                        width: "112px",
                    }}
                >
                    <Avatar
                        sx={{
                            color: 'success.main',
                            bgcolor: 'success.lighter',
                            width: '112px',
                            height: '112px'
                        }}
                        src={getImages('1.jpg')}
                    >

                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    sx={{
                        pl: '20px',
                        width: "80%"
                    }}>
                    <Typography noWrap
                        sx={{
                            fontSize: "1.2rem",
                            fontWeight: '700',
                            color: 'secondary.main',
                            lineHeight: '22px',
                            mb: 1,
                        }}
                        variant="h6">Nats White
                        <span style={{
                            color: "#A2C2F1",
                            fontSize: '0.9rem',
                            fontWeight: '500',
                            paddingLeft: '5px'
                        }}>
                            (COO)
                        </span>
                    </Typography>
                    <Typography
                        noWrap
                        variant="body2"
                        sx={{
                            fontSize: '1rem',
                            fontWeight: '600',
                            color: 'lightSecondary.main',
                            mb: 0.5,
                        }}
                    >
                        <EmailIcon
                            sx={{
                                verticalAlign: 'middle',
                                fontSize: '16px'
                            }}
                        /> nats.white@asianweb.in
                    </Typography>
                    <Typography
                        noWrap
                        variant="body2"
                        sx={{
                            fontSize: '1rem',
                            fontWeight: '600',
                            color: 'lightSecondary.main',
                            mb: 0.5,
                        }}
                    >
                        <PhoneEnabledIcon
                            sx={{
                                verticalAlign: 'middle',
                                fontSize: '16px'
                            }}
                        /> +1 (484) 4731859
                    </Typography>
                    <Typography
                        noWrap
                        variant="body2"
                        sx={{
                            fontSize: '1rem',
                            fontWeight: '600',
                            color: 'lightSecondary.main',
                        }}
                    >
                        <LocationOnIcon
                            sx={{
                                verticalAlign: 'middle',
                                fontSize: '16px'
                            }}
                        /> California, US
                    </Typography>
                </ListItemText>
            </Stack>
            <Box
                sx={{
                    px: 3,
                    mt: 1
                }}
            >
                <Box
                    sx={{
                        backgroundColor: 'extraLTheme.main',
                        borderRadius: '0px 10px 10px 10px',
                        p: "10px 15px",
                        width: '100%',
                        position: 'relative',
                        display: 'inline-block',
                        textAlign: 'left',
                        '&:before': {
                            content: '""',
                            position: 'absolute',
                            left: '-16px',
                            top: '0',
                            borderRight: 'solid 16px #ECF6FF',
                            borderBottom: 'solid 16px transparent'
                        },
                        [theme.breakpoints.down('md')]: {
                            width: 'calc(100% - 70px)',
                        }
                    }}
                >
                    <Typography variant='body2'
                        sx={{
                            fontSize: '0.95rem',
                            color: 'lightSecondary.main',
                            fontWeight: '500'
                        }}
                    >Hello Team, I came across to your blockchain solution and Keen to know more about it. Actually I am working in fintech domain where we are planning to implement such solution.</Typography>
                    <Typography variant='body2'
                        sx={{
                            fontSize: '12px',
                            color: 'ltheme.main',
                            fontWeight: '500',
                            textAlign: 'right'
                        }}
                    > 21 Feb, 2023 | 4:30 PM</Typography>
                </Box>
            </Box>
            <Box
                sx={{
                    mt: 3,
                    display: 'flex',
                    gap: 2,
                    paddingLeft: '70px',
                    paddingRight: '70px',
                    mb: 3
                }}
            >
                <Button variant="outlined"
                    sx={{
                        textTransform: 'inherit',
                        p: "10px 25px",
                        width: '50%',
                        borderWidth: '2px',
                        fontWeight: '600',
                        '&:hover': {
                            borderWidth: '2px',
                        }
                    }}
                >
                    Reject
                </Button>
                <Button variant="contained"
                    sx={{
                        textTransform: 'inherit',
                        p: "12px 25px",
                        width: '50%',
                        boxShadow: 'none',
                        fontWeight: '600',
                    }}>
                    Accept
                </Button>
            </Box>
        </>
    )
}

export default ReviewInviteModal