import React, { useEffect, useState } from 'react'
import {
    Box,
    Typography,
    Button,
    ListItemButton,
    List,
    ListItemAvatar,
    Stack,
} from '@mui/material'
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import FolderIcon from '@mui/icons-material/Folder';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';

import { useMoveDocumentMutation } from '../../redux/api/BsiDrive/moveFolder';
import { useDispatch, useSelector } from 'react-redux';
import { useFetchDriveItemListQuery } from '../../redux/api/BsiDrive/getDriveFolderList';
import { setRefetchDocument, setRefetchSubFolder } from '../../redux/features/refetch';
import { hideLoader, showLoader } from '../../redux/features/loader';

const MoveModal = ({ documentId , documentName, handleClose, showToastMessage ,parentId }) => {
    const userId = localStorage.getItem("userId");
    const dispatch = useDispatch();
    const [btnActive, setBtnActive] = useState(false);
    const [selectedFolderId, setSelectedFolderId] = useState(null);
    const [isMoving, setIsMoving] = useState(false);
    const { data: driveItemData, error: isDriveFolderError, isLoading: isDriveFolderLoading, refetch } = useFetchDriveItemListQuery({
    userId: userId,
    parentId:parentId,
    searchParams:"",
    sortOption:"",
    });
    const [moveDriveFolder, { isLoading, isError, error }] = useMoveDocumentMutation();
    const handleFolderClick = (folderId) => {
        setSelectedFolderId(folderId);
        setBtnActive(true);
    };


    const handleMoveItem = async () => {
        setIsMoving(true);
        try {
           let id = documentId
          const response = await moveDriveFolder({
            movedFolderId: id,
            selectedFolderId: selectedFolderId,
            userId: userId
          }).unwrap();
          const successMessage = response?.message || 'Item moved';
          showToastMessage(successMessage, 'success');
          handleClose();
            dispatch(setRefetchDocument(true));
            dispatch(setRefetchDocument(true));
            dispatch(setRefetchSubFolder(true));
        } catch (error) {
          const errorMessage = error?.message || 'Failed to move item';
          showToastMessage(errorMessage, 'error');
        }
      };
  
      useEffect(() => {
        if (isLoading) {
          dispatch(showLoader());
        } else {
          dispatch(hideLoader());
        }
      }, [isLoading, dispatch]);    

    return (
        <Box sx={{width:'100%'}}>
            <Box sx={{ mb: 3,  }}>
                <Typography variant='h5' 
                 sx={{
                    fontSize: '1.3rem',
                    color: 'secondary.main',
                    fontWeight: '700',
                    maxWidth: '100%', // Set maximum width to the width of the modal
                    whiteSpace: 'nowrap', // Prevents text from wrapping onto a new line
                    overflow: 'hidden', // Hides any overflowed text
                    textOverflow: 'ellipsis', // Adds "..." at the end of the truncated text
                  }}
                >
                    Move {documentName}
                </Typography>
            </Box>
            <Typography variant='h6'
                sx={{
                    fontSize: "0.9rem",
                    color: "secondary.main",
                    fontWeight: "700"
                }}>
                BSI Drive {`>`} Services</Typography>
            {/* <ListItemButton
                sx={{
                    fontSize: "0.95rem",
                    color: "primary.main",
                    fontWeight: "600",
                    px: 0
                }}
            >
                <CreateNewFolderIcon
                    sx={{
                        fontSize: '28px',
                        verticalAlign: 'middle',
                        mr: 1.3,
                    }}
                /> New Folder
            </ListItemButton> */}
                     <List
                component="nav"
                sx={{
                    px: 0,
                    py: 0,
                    mt: '10px',
                    '& .MuiListItemButton-root': {
                        py: 1.5,
                    },
                    maxHeight: "180px",
                    overflowY: "auto",
                    '&::-webkit-scrollbar': {
                        width: '6px',
                        borderRadius: '4px'
                    },
                    '&::-webkit-scrollbar-track': {
                        backgroundColor: 'transparent',
                        borderRadius: '4px'
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: '#9C9C9C',
                        borderRadius: '4px'
                    }
                }}
            >
                  {driveItemData && driveItemData?.data?.folders?.length > 0 ? (
            driveItemData.data.folders
              .filter((folder) => folder.isDocument === true && folder._id !== documentId) // Filter out items that are not documents
              .map((folder, index) => (
                        <ListItemButton
                            key={folder._id}
                            selected={selectedFolderId === folder._id}
                            onClick={() => handleFolderClick(folder._id)}
                            style={{
                                paddingTop: '5px',
                                paddingBottom: '5px',
                            }}
                            sx={{
                                justifyContent: "space-between",
                                alignItems: "center",
                                background: selectedFolderId === folder._id ? 'lightPrimary.main' : "#FFFFFF",
                                borderRadius: "10px",

                                '&:hover': {
                                    backgroundColor: "extraLTheme.main",
                                },
                                '&:hover .MuiSvgIcon-root': {
                                    display: 'block'
                                }
                            }}
                        >
                            <Stack
                                sx={{
                                    flexDirection: "inherit",
                                    alignItems: "center",
                                }}
                            >
                                <FolderIcon
                                    sx={{
                                        fontSize: '26px',
                                        color: 'lightSecondary.main',
                                        mr: 1.5
                                    }}
                                />
                                <Typography variant="subtitle1"
                                    noWrap
                                    sx={{
                                        fontSize: "0.95rem",
                                        color: 'lightSecondary.main',
                                        fontWeight: "600",
                                        lineHeight: "20px"
                                    }}
                                >{folder.name}</Typography>
                            </Stack>
                            <ArrowForwardIosOutlinedIcon sx={{
                                color: "lightSecondary.main",
                                width: "18px",
                                height: "18px",
                                display: 'none'
                            }} />
                        </ListItemButton>
                 ))
                ) : (
                    <>
                    </>
                )}
            </List>
            <Box
                sx={{
                    mt: 3,
                    textAlign: 'center'
                }}
            >
                <Button 
                    variant="contained"
                    sx={{
                        textTransform: 'inherit',
                        p: "12px 25px",
                        width: '50%',
                        boxShadow: 'none',
                        fontWeight: '600',
                        backgroundColor: btnActive ? 'primary.main' : 'extraLTheme.main',
                        pointerEvents: btnActive ? 'normal' : 'none',
                        '&:hover': {
                            boxShadow: 'none',
                        },
                    }}
                    onClick={handleMoveItem}
                    disabled={isMoving}
                    >
                    Move Here
                </Button>
            </Box>
        </Box>
    )
}

export default MoveModal