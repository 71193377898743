import React from 'react'
import { Box, Button, Typography } from '@mui/material'
import EastIcon from '@mui/icons-material/East';
import { getImages } from '../../const';
import { useTheme } from '@emotion/react';

const StepFooter = ({nextStep, isLastStep}) => {
    const theme = useTheme();
    return (
        <>
            <Box
                sx={{
                    textAlign: 'center'
                }}
            >
                {isLastStep ? 
                    <Button variant="contained"
                        onClick={isLastStep}
                        sx={{
                            textTransform: 'inherit',
                            p: "10px 50px",
                            boxShadow: 'none',
                            fontWeight: '600',
                            mt: 8,
                            [theme.breakpoints.down('md')]: {
                                p: "10px 35px",
                                mt: 6,
                            },
                        }}>
                        Find Solution <EastIcon sx={{ ml: 1.5 }} />
                    </Button> :
                    <Button variant="contained"
                        onClick={nextStep}
                        sx={{
                            textTransform: 'inherit',
                            p: "10px 50px",
                            boxShadow: 'none',
                            fontWeight: '600',
                            mt: 8,
                            [theme.breakpoints.down('md')]: {
                                p: "10px 35px",
                                mt: 6,
                            },
                        }}>
                        Next <EastIcon sx={{ ml: 1.5 }} />
                    </Button>
                    
                
                }
                <Typography variant='body1'
                    sx={{
                        color: 'primary.main',
                        fontWeight: '700',
                        fontSize: '1.2rem',
                        mt: 3,
                        [theme.breakpoints.down('md')]: {
                            fontSize: '1rem',
                            mt: 2,
                        },
                    }}
                >Powered by <img style={{ maxWidth: '30px', verticalAlign: '-8px' }} src={getImages('chatgtp.png')} /> GPT-4</Typography>
            </Box>
        </>
    )
}

export default StepFooter