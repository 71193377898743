import { createTheme } from '@mui/material/styles';

let variables = createTheme({
    palette: {
        primary: {
            main: '#106EED',
        },
        secondary: {
            main: '#073985',
        },
        lightSecondary: {
            main: '#2A589C',
        },
        ltheme: {
            main: '#A2C2F1',
        },
        extraLTheme: {
            main: '#ECF6FF',
        },
        red: {
            main: '#D92F25',
        },
        green: {
            main: '#45D664',
        },
        teritiary:{
            main:"blck"
        }
    },
});

const theme = createTheme(variables, {
    palette: {
        info: {
            main: variables.palette.secondary.main,
        },
    },
});

export default theme;