import React from 'react'
import {
    Box,
    IconButton,
    Typography,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { getImages } from '../const';

const ProfileDocuments = ({closeDocumentHandle}) => {
    return (
        <>
            <Box
                sx={{
                    background: "#fff",
                    minHeight: "100%",
                    position: "relative",
                    borderRadius: '10px',
                    py: 2,
                    px: 2,
                }}
            >
                <IconButton aria-label="close"
                    onClick={closeDocumentHandle}
                    sx={{
                        position: "absolute",
                        right: "10px",
                        top: "10px",
                        color: 'lightSecondary.main',
                    }}
                >
                    <CloseIcon
                        sx={{
                            fontSize: '26px'
                        }}
                    />
                </IconButton>
                <Typography variant="h6"
                    sx={{
                        color: 'secondary.main',
                        fontSize: '1.1em',
                        fontWeight: '600'
                    }}
                >Documents</Typography>

                <List
                    sx={{
                        p: 0,
                        '& .MuiListItem-root + .MuiListItem-root': {
                            borderTop: "solid 1px",
                            borderTopColor: "ltheme.main"
                        },
                        overflowY: 'auto',
                        maxHeight: 'calc(100vh - 230px)',
                        '&::-webkit-scrollbar': {
                            width: '6px',
                            borderRadius: '4px'
                        },
                        '&::-webkit-scrollbar-track': {
                            backgroundColor: 'transparent',
                            borderRadius: '4px'
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: '#9C9C9C',
                            borderRadius: '4px'
                        },
                        '@media (max-width:1600px) and (min-width:1200px)': {
                            maxHeight: '100vh',
                        },
                    }}
                >
                    <ListItem
                        sx={{
                            px: "0"
                        }}
                    >
                        <ListItemAvatar sx={{ textAlign: "center" }}>
                            <img style={{ maxWidth: "40px", maxHeight: "40px" }} src={getImages('pdf_icon.png')} />
                        </ListItemAvatar>
                        <ListItemText
                            primary={
                                <Typography noWrap
                                    sx={{
                                        fontSize: "0.9rem",
                                        fontWeight: '600',
                                        color: 'secondary.main',
                                        lineHeight: '22px'
                                    }}
                                    variant="subtitle1">Solution Proposal 
                                </Typography>}
                            secondary={
                                <Typography
                                    noWrap
                                    variant="body2"
                                    sx={{
                                        fontSize: '12px',
                                        fontWeight: '500',
                                        color: 'ltheme.main',
                                        width: "100%"

                                    }}
                                >Pdf • 3.8 mb</Typography>}
                        />
                        <Box>
                            <IconButton aria-label="delete">
                                <FileDownloadOutlinedIcon
                                    sx={{
                                        color: "lightSecondary.main"
                                    }}
                                />
                            </IconButton>
                        </Box>
                    </ListItem>
                    <ListItem
                        sx={{
                            px: "0"
                        }}
                    >
                        <ListItemAvatar sx={{ textAlign: "center" }}>
                            <img style={{ maxWidth: "40px", maxHeight: "40px" }} src={getImages('doc_icon.png')} />
                        </ListItemAvatar>
                        <ListItemText
                            primary={
                                <Typography noWrap
                                    sx={{
                                        fontSize: "0.9rem",
                                        fontWeight: '600',
                                        color: 'secondary.main',
                                        lineHeight: '22px'
                                    }}
                                    variant="subtitle1">Service description
                                </Typography>}
                            secondary={
                                <Typography
                                    noWrap
                                    variant="body2"
                                    sx={{
                                        fontSize: '12px',
                                        fontWeight: '500',
                                        color: 'ltheme.main',
                                        width: "100%"

                                    }}
                                >Pdf • 3.8 mb</Typography>}
                        />
                        <Box>
                            <IconButton aria-label="download">
                                <FileDownloadOutlinedIcon
                                    sx={{
                                        color: "lightSecondary.main"
                                    }}
                                />
                            </IconButton>
                        </Box>
                    </ListItem>
                    <ListItem
                        sx={{
                            px: "0"
                        }}
                    >
                        <ListItemAvatar sx={{ textAlign: "center" }}>
                            <img style={{ maxWidth: "40px", maxHeight: "40px" }} src={getImages('doc_icon.png')} />
                        </ListItemAvatar>
                        <ListItemText
                            primary={
                                <Typography noWrap
                                    sx={{
                                        fontSize: "0.9rem",
                                        fontWeight: '600',
                                        color: 'secondary.main',
                                        lineHeight: '22px'
                                    }}
                                    variant="subtitle1">Important notes
                                </Typography>}
                            secondary={
                                <Typography
                                    noWrap
                                    variant="body2"
                                    sx={{
                                        fontSize: '12px',
                                        fontWeight: '500',
                                        color: 'ltheme.main',
                                        width: "100%"

                                    }}
                                >Pdf • 3.8 mb</Typography>}
                        />
                        <Box>
                            <IconButton aria-label="delete">
                                <FileDownloadOutlinedIcon
                                    sx={{
                                        color: "lightSecondary.main"
                                    }}
                                />
                            </IconButton>
                        </Box>
                    </ListItem>
                    <ListItem
                        sx={{
                            px: "0"
                        }}
                    >
                        <ListItemAvatar sx={{ textAlign: "center" }}>
                            <img style={{ maxWidth: "40px", maxHeight: "40px" }} src={getImages('pdf_icon.png')} />
                        </ListItemAvatar>
                        <ListItemText
                            primary={
                                <Typography noWrap
                                    sx={{
                                        fontSize: "0.9rem",
                                        fontWeight: '600',
                                        color: 'secondary.main',
                                        lineHeight: '22px'
                                    }}
                                    variant="subtitle1">Company policy
                                </Typography>}
                            secondary={
                                <Typography
                                    noWrap
                                    variant="body2"
                                    sx={{
                                        fontSize: '12px',
                                        fontWeight: '500',
                                        color: 'ltheme.main',
                                        width: "100%"

                                    }}
                                >Pdf • 3.8 mb</Typography>}
                        />
                        <Box>
                            <IconButton aria-label="download">
                                <FileDownloadOutlinedIcon
                                    sx={{
                                        color: "lightSecondary.main"
                                    }}
                                />
                            </IconButton>
                        </Box>
                    </ListItem>
                    <ListItem
                        sx={{
                            px: "0"
                        }}
                    >
                        <ListItemAvatar sx={{ textAlign: "center" }}>
                            <img style={{ maxWidth: "40px", maxHeight: "40px" }} src={getImages('pdf_icon.png')} />
                        </ListItemAvatar>
                        <ListItemText
                            primary={
                                <Typography noWrap
                                    sx={{
                                        fontSize: "0.9rem",
                                        fontWeight: '600',
                                        color: 'secondary.main',
                                        lineHeight: '22px'
                                    }}
                                    variant="subtitle1">Document list
                                </Typography>}
                            secondary={
                                <Typography
                                    noWrap
                                    variant="body2"
                                    sx={{
                                        fontSize: '12px',
                                        fontWeight: '500',
                                        color: 'ltheme.main',
                                        width: "100%"

                                    }}
                                >Pdf • 3.8 mb</Typography>}
                        />
                        <Box>
                            <IconButton aria-label="delete">
                                <FileDownloadOutlinedIcon
                                    sx={{
                                        color: "lightSecondary.main"
                                    }}
                                />
                            </IconButton>
                        </Box>
                    </ListItem>
                    <ListItem
                        sx={{
                            px: "0"
                        }}
                    >
                        <ListItemAvatar sx={{ textAlign: "center" }}>
                            <img style={{ maxWidth: "40px", maxHeight: "40px" }} src={getImages('doc_icon.png')} />
                        </ListItemAvatar>
                        <ListItemText
                            primary={
                                <Typography noWrap
                                    sx={{
                                        fontSize: "0.9rem",
                                        fontWeight: '600',
                                        color: 'secondary.main',
                                        lineHeight: '22px'
                                    }}
                                    variant="subtitle1">Required information
                                </Typography>}
                            secondary={
                                <Typography
                                    noWrap
                                    variant="body2"
                                    sx={{
                                        fontSize: '12px',
                                        fontWeight: '500',
                                        color: 'ltheme.main',
                                        width: "100%"

                                    }}
                                >Pdf • 3.8 mb</Typography>}
                        />
                        <Box>
                            <IconButton aria-label="download">
                                <FileDownloadOutlinedIcon
                                    sx={{
                                        color: "lightSecondary.main"
                                    }}
                                />
                            </IconButton>
                        </Box>
                    </ListItem>
                    <ListItem
                        sx={{
                            px: "0"
                        }}
                    >
                        <ListItemAvatar sx={{ textAlign: "center" }}>
                            <img style={{ maxWidth: "40px", maxHeight: "40px" }} src={getImages('pdf_icon.png')} />
                        </ListItemAvatar>
                        <ListItemText
                            primary={
                                <Typography noWrap
                                    sx={{
                                        fontSize: "0.9rem",
                                        fontWeight: '600',
                                        color: 'secondary.main',
                                        lineHeight: '22px'
                                    }}
                                    variant="subtitle1">Solutions
                                </Typography>}
                            secondary={
                                <Typography
                                    noWrap
                                    variant="body2"
                                    sx={{
                                        fontSize: '12px',
                                        fontWeight: '500',
                                        color: 'ltheme.main',
                                        width: "100%"

                                    }}
                                >Pdf • 3.8 mb</Typography>}
                        />
                        <Box>
                            <IconButton aria-label="delete">
                                <FileDownloadOutlinedIcon
                                    sx={{
                                        color: "lightSecondary.main"
                                    }}
                                />
                            </IconButton>
                        </Box>
                    </ListItem>
                    <ListItem
                        sx={{
                            px: "0"
                        }}
                    >
                        <ListItemAvatar sx={{ textAlign: "center" }}>
                            <img style={{ maxWidth: "40px", maxHeight: "40px" }} src={getImages('pdf_icon.png')} />
                        </ListItemAvatar>
                        <ListItemText
                            primary={
                                <Typography noWrap
                                    sx={{
                                        fontSize: "0.9rem",
                                        fontWeight: '600',
                                        color: 'secondary.main',
                                        lineHeight: '22px'
                                    }}
                                    variant="subtitle1">Solutions
                                </Typography>}
                            secondary={
                                <Typography
                                    noWrap
                                    variant="body2"
                                    sx={{
                                        fontSize: '12px',
                                        fontWeight: '500',
                                        color: 'ltheme.main',
                                        width: "100%"

                                    }}
                                >Pdf • 3.8 mb</Typography>}
                        />
                        <Box>
                            <IconButton aria-label="download">
                                <FileDownloadOutlinedIcon
                                    sx={{
                                        color: "lightSecondary.main"
                                    }}
                                />
                            </IconButton>
                        </Box>
                    </ListItem>
                </List>
            </Box>
        </>
    )
}

export default ProfileDocuments