import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = process.env.REACT_APP_base_URL;

const RenameDocument = createApi({
    reducerPath: 'renameDocument',
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers) => {
            // Add any headers you need here
            return headers;
        },
    }),
    endpoints: (builder) => ({
        UpdatedDocumentName: builder.mutation({
            query: ({documentId , updatedName , userId}) => ({
                url: `drive/document-rename/${documentId}`, // Replace with your API endpoint URL
                method: 'POST',
                body: { 
                   newName: updatedName,
                   owner_id:userId,
            },
            }),
            transformResponse: (response) => {
                return response;
            },
        }),
    }),
});

export const { useUpdatedDocumentNameMutation } = RenameDocument;
export default RenameDocument;
