import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = process.env.REACT_APP_base_URL;

const deleteMeeting = createApi({
    reducerPath: 'deleteMeeting',
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers) => {
            // Add any headers you need here
            return headers;
        },
    }),
    endpoints: (builder) => ({
        deleteMeeting: builder.mutation({
            query: ({ currentMeetingId , userId}) => ({
                url: `meetings/${currentMeetingId}?owner_id=${userId}`,
                method: 'DELETE',
            }),
            transformResponse: (response)=>{
                return response;
            }
        }),
    }),
});

export const { useDeleteMeetingMutation } = deleteMeeting;
export default deleteMeeting;

