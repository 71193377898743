import { fetchBaseQuery, createApi } from '@reduxjs/toolkit/query/react';

const baseUrl = process.env.REACT_APP_base_URL;

export const inviteTeamMember = createApi({
  reducerPath: 'inviteTeamMember',
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers) => {
      return headers;
    },
  }),
  endpoints: (builder) => ({
    sendMemberInvitation: builder.mutation({
      query: (body) => ({
        url: 'users-collaborator/send-member-invitation-for-business-user',
        method: 'POST',
        body: {
          email: body.email,
          first_name: body.first_name,
          last_name: body.last_name,
          id: body.id,
          requirement_id: body.requirement_id,
          solution_id: body.solution_id,
        },
      }),
      transformResponse: (response) => {
        console.log(response);
        return response;
        
      },
      onError: (error) => {
        console.error('Error sending member invitation:', error);
      },
    }),
  }),
});

export const { useSendMemberInvitationMutation } = inviteTeamMember;
export default inviteTeamMember;
