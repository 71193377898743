import React from 'react'
import Modal from '@mui/material/Modal';
import { CloseOutlined } from '@ant-design/icons'
import { Box, IconButton } from '@mui/material'
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    borderRadius: "20px",
    p: 4,
};
const Modals = ({ children, open, handleClose, modalWidth }) => {
    const theme = useTheme();
    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        ...style,
                        width: modalWidth + "px",
                        [theme.breakpoints.down('sm')]: {
                            width: 'calc(100% - 30px)',
                        }
                    }}>
                    {handleClose &&
                        <IconButton aria-label="close"
                            onClick={handleClose}
                            sx={{
                                position: "absolute",
                                right: "10px",
                                top: "5px"
                            }}
                        >
                            <CloseOutlined
                                style={{ color: "#2A589C" }} />
                        </IconButton>
                    }
                    {children}
                </Box>
            </Modal>
        </>
    )
}
// Define PropTypes
Modals.propTypes = {
    children: PropTypes.node.isRequired, // children should be a React node and is required
    open: PropTypes.bool.isRequired, // open should be a boolean and is required
    handleClose: PropTypes.func.isRequired, // handleClose should be a function and is required
    modalWidth: PropTypes.number.isRequired, // modalWidth should be a number and is required
};
export default Modals