import { Box, Button, Grid, List, ListItem, TextField, Typography } from '@mui/material'
import React from 'react'
import { getImages } from '../const'
import EmailIcon from '@mui/icons-material/Email';
import { Link } from 'react-router-dom';
import { useTheme } from '@emotion/react';

const Footer = () => {
    const theme = useTheme();
    return (
        <>
            <Box
                sx={{
                    background: '#100345',
                    borderRadius: '0'
                }}
            >

                <Box
                    sx={{
                        maxWidth: "1320px",
                        margin: "0 auto",
                        [theme.breakpoints.down('xl')]: {
                            padding: '0 20px'
                        }
                    }}
                >
                    <Box sx={{
                        padding: '60px 0 40px',
                        [theme.breakpoints.down('md')]: {
                            padding: '30px 0'
                        }
                    }}>
                        <Grid container rowSpacing={0} columnSpacing={1}>
                            <Grid item md={3} xs={12} 
                                sx={{
                                    [theme.breakpoints.down('md')]: {
                                        textAlign: 'center'
                                    }
                                }}
                            >
                                <Box>
                                    <img src={getImages('bluesky.png')} alt='logo' />
                                </Box>
                                <Typography variant='body2'
                                    sx={{
                                        fontSize: '0.9em',
                                        lineHeight: '1.8em',
                                        color: '#dbebf8',
                                    }}
                                >
                                    Start Your Blockchain Solutions <br/>Discovery With BlueSky INTEL
                                </Typography>
                                <Typography variant='body2'
                                    sx={{
                                        mt: 3,
                                        [theme.breakpoints.down('md')]: {
                                            mt: 1.8
                                        }
                                    }}
                                >
                                    <a href="mailto:support@blueskyintel.com"
                                        style={{
                                            textDecoration: 'none',
                                            color: '#a2c2f1',
                                            display: 'inline-block',
                                        }}
                                    >
                                        <EmailIcon
                                            sx={{
                                                color: '#fff',
                                                verticalAlign: 'middle',
                                                mr: 1
                                            }}
                                        /> support@blueskyintel.com
                                    </a>
                                </Typography>
                            </Grid>
                            <Grid item md={3} xs={6} 
                                sx={{
                                    [theme.breakpoints.down('md')]: {
                                        mt: 4
                                    }
                                }}
                            >
                                <Typography variant='h6'
                                    sx={{
                                        fontWeight: '600',
                                        fontSize: '1.1em',
                                        lineHeight: '1.4em',
                                        color: '#f2f2f2',
                                        [theme.breakpoints.down('sm')]: {
                                            fontSize: '1em',
                                        }
                                    }}>
                                    Solution By Industries
                                </Typography>
                                <List
                                    sx={{
                                        mt: 1.5,
                                        [theme.breakpoints.down('sm')]: {
                                            mt: 0.5
                                        }
                                    }}
                                >
                                    <ListItem
                                        sx={{
                                                fontWeight: '500',
                                                fontSize: '0.9em',
                                                padding: '7px 0',
                                                [theme.breakpoints.down('sm')]: {
                                                    fontSize: '0.8em',
                                                }
                                        }}
                                    >
                                        <Link to="/"
                                            style={{
                                                display: 'inline-block',
                                                textDecoration: 'none',
                                                color: '#a2c2f1'
                                            }}
                                        >Fintech</Link>
                                    </ListItem>
                                    <ListItem
                                        sx={{
                                                fontWeight: '500',
                                                fontSize: '0.9em',
                                                padding: '7px 0',
                                                [theme.breakpoints.down('sm')]: {
                                                    fontSize: '0.8em',
                                                }
                                        }}
                                    >
                                        <Link to="/"
                                            style={{
                                                display: 'inline-block',
                                                textDecoration: 'none',
                                                color: '#a2c2f1'
                                            }}
                                        >Healthcare</Link>
                                    </ListItem>
                                    <ListItem
                                        sx={{
                                                fontWeight: '500',
                                                fontSize: '0.9em',
                                                padding: '7px 0',
                                                [theme.breakpoints.down('sm')]: {
                                                    fontSize: '0.8em',
                                                }
                                        }}
                                    >
                                        <Link to="/"
                                            style={{
                                                display: 'inline-block',
                                                textDecoration: 'none',
                                                color: '#a2c2f1'
                                            }}
                                        >Food Safety </Link>
                                    </ListItem>
                                    <ListItem
                                        sx={{
                                                fontWeight: '500',
                                                fontSize: '0.9em',
                                                padding: '7px 0',
                                                [theme.breakpoints.down('sm')]: {
                                                    fontSize: '0.8em',
                                                }
                                        }}
                                    >
                                        <Link to="/"
                                            style={{
                                                display: 'inline-block',
                                                textDecoration: 'none',
                                                color: '#a2c2f1'
                                            }}
                                        >Insurance</Link>
                                    </ListItem>
                                    <ListItem
                                        sx={{
                                                fontWeight: '500',
                                                fontSize: '0.9em',
                                                padding: '7px 0',
                                                [theme.breakpoints.down('sm')]: {
                                                    fontSize: '0.8em',
                                                }
                                        }}
                                    >
                                        <Link to="/"
                                            style={{
                                                display: 'inline-block',
                                                textDecoration: 'none',
                                                color: '#a2c2f1'
                                            }}
                                        >Supply Chain</Link>
                                    </ListItem>
                                    <ListItem
                                        sx={{
                                                fontWeight: '500',
                                                fontSize: '0.9em',
                                                padding: '7px 0',
                                                [theme.breakpoints.down('sm')]: {
                                                    fontSize: '0.8em',
                                                }
                                        }}
                                    >
                                        <Link to="/"
                                            style={{
                                                display: 'inline-block',
                                                textDecoration: 'none',
                                                color: '#a2c2f1'
                                            }}
                                        >Citizen Services</Link>
                                    </ListItem>
                                </List>
                            </Grid>
                            <Grid item md={3} xs={6} 
                                sx={{
                                    [theme.breakpoints.down('md')]: {
                                        mt: 4
                                    }
                                }}
                            >
                                <Typography variant='h6'
                                    sx={{
                                        fontWeight: '600',
                                        fontSize: '1.1em',
                                        lineHeight: '1.4em',
                                        color: '#f2f2f2',
                                        [theme.breakpoints.down('sm')]: {
                                            fontSize: '1em',
                                        }
                                    }}>
                                    Resources
                                </Typography>
                                <List
                                    sx={{
                                        mt: 1.5,
                                        [theme.breakpoints.down('sm')]: {
                                            mt: 0.5
                                        }
                                    }}
                                >
                                    <ListItem
                                        sx={{
                                                fontWeight: '500',
                                                fontSize: '0.9em',
                                                padding: '7px 0',
                                                [theme.breakpoints.down('sm')]: {
                                                    fontSize: '0.8em',
                                                }
                                        }}
                                    >
                                        <Link to="/"
                                            style={{
                                                display: 'inline-block',
                                                textDecoration: 'none',
                                                color: '#a2c2f1'
                                            }}
                                        >About Us</Link>
                                    </ListItem>
                                    <ListItem
                                        sx={{
                                                fontWeight: '500',
                                                fontSize: '0.9em',
                                                padding: '7px 0',
                                                [theme.breakpoints.down('sm')]: {
                                                    fontSize: '0.8em',
                                                }
                                        }}
                                    >
                                        <Link to="/"
                                            style={{
                                                display: 'inline-block',
                                                textDecoration: 'none',
                                                color: '#a2c2f1'
                                            }}
                                        >Blockchain Landscape</Link>
                                    </ListItem>
                                    <ListItem
                                        sx={{
                                                fontWeight: '500',
                                                fontSize: '0.9em',
                                                padding: '7px 0',
                                                [theme.breakpoints.down('sm')]: {
                                                    fontSize: '0.8em',
                                                }
                                        }}
                                    >
                                        <Link to="/"
                                            style={{
                                                display: 'inline-block',
                                                textDecoration: 'none',
                                                color: '#a2c2f1'
                                            }}
                                        >FAQs</Link>
                                    </ListItem>
                                </List>
                            </Grid>
                            <Grid item md={3} xs={12} 
                                sx={{
                                    [theme.breakpoints.down('md')]: {
                                        mt: 3
                                    }
                                }}
                            >
                                <Typography variant='h6'
                                    sx={{
                                        fontWeight: '600',
                                        fontSize: '1.1em',
                                        lineHeight: '1.4em',
                                        color: '#f2f2f2',
                                        [theme.breakpoints.down('sm')]: {
                                            fontSize: '1em',
                                        }
                                    }}>
                                    Subscribe
                                </Typography>
                                <Typography variant='body2'
                                    sx={{
                                        fontWeight: '400',
                                        fontSize: '0.9em',
                                        lineHeight: '1.2em',
                                        color: '#fff',
                                        mt: 3,
                                        [theme.breakpoints.down('sm')]: {
                                            mt: 0.5,
                                            fontSize: '0.8em',
                                        }
                                    }}>
                                    Subscribe to BlueSky INTEL Newsletter
                                </Typography>
                                <TextField
                                    hiddenLabel
                                    id="filled-hidden-label-normal"
                                    variant="filled"
                                    placeholder='Enter your email...'
                                    sx={{
                                        width: '100%',
                                        borderRadius: '4px',
                                        backgroundColor: 'extraLTheme.main',
                                        border: 'none',
                                        mt: 1.2,
                                        '& .MuiInputBase-input': {
                                            fontSize: '0.9rem',
                                            color: 'lightSecondary.main',
                                            fontWeight: '500',
                                            pt: 1.5,
                                            pb: 1.5,
                                            backgroundColor: 'extraLTheme.main',
                                            borderRadius: '4px',
                                            textAlign: 'center'
                                        },
                                        '&:hover .MuiInputBase-root:hover:before': {
                                            border: 'none'
                                        },
                                        '& .MuiInputBase-root:hover:before': {
                                            border: 'none'
                                        },
                                        '&:hover': {
                                            borderColor: 'extraLTheme.main'
                                        },
                                        '& .MuiInputBase-root:before': {
                                            border: 'none'
                                        },
                                        '& .MuiInputBase-root:after': {
                                            border: 'none'
                                        }
                                    }}
                                />
                                <Button variant="contained"
                                    sx={{
                                        textTransform: 'inherit',
                                        p: "10px 25px",
                                        width: '100%',
                                        boxShadow: 'none',
                                        fontWeight: '600',
                                        mt: 1.5,
                                    }}>
                                    Subscribe
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={{
                        color: '#a2c2f1',
                        fontSize: '0.8em',
                        fontWeight: '500',
                        padding: '20px 0',
                        borderTop: 'solid 1px rgb(255 255 255 / 20%)',
                        marginTop: '0',
                        textAlign: 'center',
                    }}>
                        &copy; 2022 <a href="javascript:void(0);" style={{ textDecoration: 'none', color: '#fff' }}>BlueSkyINTEL</a>. All rights reserved.
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default Footer