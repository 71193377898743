import React from 'react'
import { IconButton, Stack, Box, Typography, Button } from '@mui/material'
import MainCard from '../../MainCard';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@emotion/react';
import EastIcon from '@mui/icons-material/East';
import { getImages } from '../../const';
const Success = ({ nextStep, currentStep, prevStep }) => {
    const theme = useTheme();
    return (
        <>
            <Box
                sx={{
                    background: '#dbebf8'
                }}
            >
                <Box
                    sx={{
                        py: 20,
                        maxWidth: '1366px',
                        margin: '0 auto',
                        [theme.breakpoints.down('xl')]: {
                            py: 10,
                            px: '20px'
                        },
                    }}
                >
                    <MainCard content={false}
                        sx={{
                            backgroundColor: "#FFFFFF",
                            border: "none",
                            padding: "0",
                            boxShadow: "none",
                            borderRadius: "0"
                        }}
                    >
                        <Stack
                            direction='row'
                            sx={{
                                py: 1.5,
                                px: 2,
                                borderBottom: 'solid 1px #DBEBF8',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}
                        >
                            <Typography variant='body2'
                                sx={{
                                    color: 'lightSecondary.main',
                                    fontSize: '1.1rem',
                                    fontWeight: '600'
                                }}
                            >
                                &nbsp;
                            </Typography>
                            <Typography variant='body2'
                                sx={{
                                    color: 'primary.main',
                                    fontSize: '1.3rem',
                                    fontWeight: '700',
                                    [theme.breakpoints.down('sm')]: {
                                        fontSize: '1rem',
                                    }
                                }}
                            >
                                Wizard form successfully completed
                            </Typography>
                            <IconButton>
                                <CloseIcon
                                    sx={{
                                        color: 'lightSecondary.main',
                                        fontSize: '30px',
                                    }}
                                />
                            </IconButton>
                        </Stack>
                        <Box
                            sx={{
                                p: 3,
                                pb: 5
                            }}
                        >
                            <Box
                                sx={{
                                    maxWidth: '950px',
                                    margin: '0 auto'
                                }}
                            >
                                <Box
                                    sx={{
                                        textAlign: 'center',
                                        maxWidth: '150px',
                                        margin: '0 auto',
                                        '& img': {
                                            [theme.breakpoints.down('md')]: {
                                                maxWidth: '100px'
                                            },
                                        }
                                    }}
                                >
                                    <img
                                        src={getImages('success-icon.png')} alt='' />
                                </Box>
                                <Typography variant='body2'
                                    sx={{
                                        color: 'lightSecondary.main',
                                        fontSize: '1.6rem',
                                        fontWeight: '600',
                                        textAlign: 'center',
                                        mt: 4,
                                        [theme.breakpoints.down('lg')]: {
                                            fontSize: '1.3rem',
                                        },
                                        [theme.breakpoints.down('sm')]: {
                                            fontSize: '1.1rem',
                                            mt: 2,
                                        }
                                    }}
                                >
                                    Thank you for providing us with the necessary information.  We will send the solution details to your registered email address, blueskyintel
                                </Typography>

                                <Box
                                    sx={{
                                        textAlign: 'center'
                                    }}
                                >
                                    <Button variant="contained"
                                        sx={{
                                            textTransform: 'inherit',
                                            p: "10px 50px",
                                            boxShadow: 'none',
                                            fontWeight: '600',
                                            mt: 7,
                                            mb: 6,
                                            [theme.breakpoints.down('sm')]: {
                                                mt: 4,
                                                mb: 3,
                                                p: "10px 30px",
                                            }
                                        }}>
                                        Discover Solutions <EastIcon sx={{ ml: 1.5 }} />
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </MainCard>
                </Box>
            </Box>
        </>
    )
}

export default Success