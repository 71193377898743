import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = process.env.REACT_APP_base_URL;

const UploadDocument = createApi({
  reducerPath: 'uploadDocument',
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers) => {
      // Add any headers you need here
      return headers;
    },
  }),
  endpoints: (builder) => ({
    uploadDocument: builder.mutation({
      query: (formData) => {
        return {
          url: 'drive/document-upload', // Replace with your API endpoint URL
          method: 'POST',
          body: formData,
        };
      },
      transformResponse: (response) => {
        return response;
      },
    }),
  }),
});

export const { useUploadDocumentMutation } = UploadDocument;
export default UploadDocument;
