import { createSlice } from "@reduxjs/toolkit";

const boardSlice = createSlice({
    name :'board',
    initialState: {
        boardId: null,
        boardName: null,
    },
    reducers:{
        getBoardId: (state , action)=> {
          state.boardId =  action.payload
        },
        getBoardName: (state , action)=> {
          state.boardName =  action.payload
        },
        clearBoardData: (state) => {
            state.boardId = null;
            state.boardName = null;
          },
        
    },
});

export const {getBoardId , getBoardName , clearBoardData} = boardSlice.actions;
export default boardSlice.reducer;