// src/features/folderSlice.js
import { createSlice } from '@reduxjs/toolkit';

export const refetchSlice = createSlice({
  name: 'refetch',
  initialState: {
    refetchSubFolder: false,
    refetchDocument: false,
    refetchKanbanBoard: false,
    refetchSolutionProviderKanbanBoard: false,
    refetchRequirementBoard: false,
    refetchMeetingList: false,
    refetchQuickDocsList: false,
    refetchRecentSolutionList: false,
  },
  reducers: {
    setRefetchSubFolder: state => {
      state.refetchSubFolder = true;
    },
    setRefetchDocument: state => {
      state.refetchDocument = true;
    },
    setRefetchRequirementBoard: state =>{
      state.refetchRequirementBoard = true
    },
    setRefetchKanbanBoard: state =>{
      state.refetchKanbanBoard = true
    },
    setRefetchSolutionProviderKanbanBoard: state =>{
      state.refetchSolutionProviderKanbanBoard = true
    },
    setRefetchMeetingList: state =>{
      state.refetchMeetingList = true
    },
    setRefetchQuickDocsList: state =>{
      state.refetchQuickDocsList = true
    },
    setRefetchRecentSolutionList: state =>{
      state.refetchRecentSolutionList = true
    },
    resetRefetchDocument: state => {
      state.refetchDocument = false;
    },
    resetRefetchSubFolder: state => {
      state.refetchSubFolder = false;
    },
    resetRefetchKanbanBoard: state => {
      state.refetchKanbanBoard = false;
    },
    resetRefetchSolutionProviderKanbanBoard: state => {
      state.refetchSolutionProviderKanbanBoard = false;
    },
    resetRefetchRequirementBoard: state => {
      state.refetchRequirementBoard = false;
    },
    resetRefetchMeetingList: state => {
      state.refetchMeetingList = false;
    },
    resetRefetchQuickDocsList: state => {
      state.refetchQuickDocsList = false;
    },
    resetRefetchRecentSolutionList: state => {
      state.refetchRecentSolutionList = false;
    },
    

  },
});

export const {setRefetchSolutionProviderKanbanBoard, resetRefetchSolutionProviderKanbanBoard,setRefetchRecentSolutionList,resetRefetchRecentSolutionList,setRefetchQuickDocsList,resetRefetchQuickDocsList, setRefetchSubFolder,setRefetchMeetingList,resetRefetchMeetingList, setRefetchDocument,setRefetchRequirementBoard, setRefetchKanbanBoard, resetRefetchDocument,resetRefetchRequirementBoard, resetRefetchSubFolder, resetRefetchKanbanBoard } = refetchSlice.actions;

export default refetchSlice.reducer;
