import React, { useState } from 'react'
import { Box, Button, Divider, List, ListItem, Menu, MenuItem, Paper, Stack, Typography } from '@mui/material'
import { getImages } from '../const'
import { useTheme } from '@emotion/react';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { Link, useLocation } from 'react-router-dom';
import PersonIcon from '@mui/icons-material/Person';
import EastIcon from '@mui/icons-material/East';

const Header = () => {
    const path = useLocation().pathname;

    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState(null);
    const openDw = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <>
            <Box
                sx={{
                    position: "absolute",
                    left: "0",
                    top: "0",
                    width: "100%",
                    zIndex: "2"
                }}
            >
                <Box
                    sx={{
                        maxWidth: "1320px",
                        margin: "0 auto",
                        [theme.breakpoints.down('xl')]: {
                            padding: '0 20px'
                        }
                    }}
                >
                    {/* Header Top */}
                    <Stack
                        direction="row"
                        sx={{
                            borderBottom: "1px solid #0e3979",
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}>
                        <Box
                            sx={{
                                fontWeight: "600",
                                fontSize: "1em",
                                lineHeight: "22px",
                                color: "#ffffff",
                                padding: "14px 0",
                                display: "block",
                                [theme.breakpoints.down('md')]: {
                                    fontSize: "0.9em",
                                }
                            }}
                        >
                            Contact us at <a href="mailto:support@bluskyintel.com" style={{ textDecoration: "underline", color: '#fff' }}>support@bluskyintel.com</a>
                        </Box>
                        <div>
                            <Button
                                id="basic-button"
                                aria-controls={openDw ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={openDw ? 'true' : undefined}
                                onClick={handleClick}
                                sx={{
                                    pr: 0,
                                    [theme.breakpoints.down('sm')]: {
                                        display: 'block'
                                    },
                                    '& .MuiStack-root': {
                                        [theme.breakpoints.down('sm')]: {
                                            display: 'block',
                                            textAlign: 'right'
                                        },
                                    }
                                }}
                            >
                                <Stack direction="row" spacing={2} alignItems="center"
                                    sx={{
                                        p: 0.5,
                                    }}>
                                    <PersonIcon sx={{ color: '#fff' }} />
                                    <Typography variant="subtitle1" sx={{ textAlign: "left" }}>
                                        <Typography variant="body2"
                                            sx={{
                                                color: "#fff",
                                                fontSize: "1rem",
                                                fontWeight: "600",
                                                lineHeight: "normal",
                                                textTransform: "capitalize",
                                                [theme.breakpoints.down('md')]: {
                                                    fontSize: '0.8rem',
                                                },
                                                [theme.breakpoints.down('sm')]: {
                                                    display: 'none'
                                                }
                                            }}
                                        >Account</Typography>
                                    </Typography>
                                </Stack>
                            </Button>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={openDw}
                                onClose={handleClose}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                                sx={{
                                    '& .MuiMenu-paper': {
                                        borderRadius: "10px",
                                        border: "solid 1px #106EED",
                                        minWidth: "280px",
                                        p: 1,
                                    }
                                }}
                            >
                                <Paper
                                    sx={{
                                        width: 320,
                                        maxWidth: '100%',
                                        background: "#fff",
                                        borderRadius: "10px",
                                        borderBottom: "none",
                                        boxShadow: "none",
                                        p: 2
                                    }}>

                                    <Typography
                                        sx={{
                                            fontSize: "0.8rem",
                                            fontWeight: "600",
                                            color: 'ltheme.main',
                                            pl: 1.5,
                                            pb: 0.8,
                                        }} variant="body2">
                                        Switch Profile
                                    </Typography>
                                    <MenuItem className='active' onClick={handleClose}
                                        sx={{
                                            fontSize: "0.9rem",
                                            fontWeight: "600",
                                            lineHeight: "20px",
                                            color: "lightSecondary.main",
                                            py: 1.3,
                                            px: 1.4,
                                            '&:hover': {
                                                backgroundColor: 'extraLTheme.main'
                                            },
                                            '&.active': {
                                                backgroundColor: 'primary.main',
                                                color: "#fff",
                                                borderRadius: "10px"
                                            }
                                        }}
                                    >Business Owner</MenuItem>
                                    <MenuItem onClick={handleClose}
                                        sx={{
                                            fontSize: "0.9rem",
                                            fontWeight: "600",
                                            lineHeight: "20px",
                                            color: "lightSecondary.main",
                                            py: 1.3,
                                            px: 1.4,
                                            '&:hover': {
                                                backgroundColor: 'extraLTheme.main'
                                            },
                                            '&.active': {
                                                backgroundColor: 'primary.main',
                                                color: "#fff",
                                                borderRadius: "10px"
                                            }
                                        }}
                                    >Solution Provider</MenuItem>
                                    <MenuItem onClick={handleClose}
                                        sx={{
                                            fontSize: "0.9rem",
                                            fontWeight: "600",
                                            lineHeight: "20px",
                                            color: "lightSecondary.main",
                                            py: 1.3,
                                            px: 1.4,
                                            '&:hover': {
                                                backgroundColor: 'extraLTheme.main'
                                            },
                                            '&.active': {
                                                backgroundColor: 'primary.main',
                                                color: "#fff",
                                                borderRadius: "10px"
                                            }
                                        }}
                                    >Investor</MenuItem>
                                    <Divider
                                        sx={{
                                            borderColor: 'ltheme.main',
                                        }}
                                    />
                                    <MenuItem onClick={handleClose}
                                        sx={{
                                            fontSize: "0.9rem",
                                            fontWeight: "600",
                                            lineHeight: "20px",
                                            color: "lightSecondary.main",
                                            py: 1.3,
                                            px: 1.4,
                                            '&:hover': {
                                                backgroundColor: 'extraLTheme.main'
                                            },
                                            '&.active': {
                                                backgroundColor: 'primary.main',
                                                color: "#fff",
                                                borderRadius: "10px"
                                            }
                                        }}
                                    >
                                        <Link to="/manage-team-members"
                                            style={{
                                                color: '#2A589C',
                                                textDecoration: "none",
                                                '&.active': {
                                                    color: "#fff"
                                                }
                                            }}
                                        >Manage Team Members</Link>
                                    </MenuItem>
                                    <Divider
                                        sx={{
                                            borderColor: 'ltheme.main',
                                        }}
                                    />
                                    <MenuItem onClick={handleClose}
                                        sx={{
                                            justifyContent: "space-between",
                                            pt: 1.3,
                                            px: 1.4,
                                            '&:hover': {
                                                backgroundColor: 'extraLTheme.main'
                                            },
                                        }}
                                    >
                                        <Typography variant="body2"
                                            sx={{
                                                fontSize: "0.9rem",
                                                fontWeight: "600",
                                                lineHeight: "20px",
                                                color: "red.main"
                                            }}
                                        >Log Out</Typography>
                                        <LogoutOutlinedIcon
                                            sx={{
                                                color: 'red.main'
                                            }}
                                        />
                                    </MenuItem>
                                </Paper>
                            </Menu>
                        </div>
                    </Stack>
                    {/* Header Bottom */}
                    <Stack
                        direction="row"
                        sx={{
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}>

                        <Box
                            sx={{
                                py: 2
                            }}
                        >
                            <Link to="/"><img src={getImages('bluesky.png')} alt="logo" /></Link>
                        </Box>
                        <Box>
                            <List
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    [theme.breakpoints.down('lg')]: {
                                        display: 'none'
                                    },
                                }}
                            >
                                <ListItem>
                                    <Link to="/"
                                        style={{
                                            color: '#F2F2F2',
                                            textDecoration: 'none',
                                            fontSize: '1rem'
                                        }}
                                    >Home</Link>
                                </ListItem>
                                <ListItem>
                                    <Link to="/solutions"
                                        style={{
                                            color: '#F2F2F2',
                                            textDecoration: 'none',
                                            fontSize: '1rem'
                                        }}
                                    >Solutions</Link>
                                </ListItem>
                                <ListItem>
                                    <Link to="/landscape"
                                        style={{
                                            color: '#F2F2F2',
                                            textDecoration: 'none',
                                            fontSize: '1rem'
                                        }}
                                    >Landscape</Link>
                                </ListItem>
                                <ListItem>
                                    <Link to="/insights"
                                        style={{
                                            color: '#F2F2F2',
                                            textDecoration: 'none',
                                            fontSize: '1rem'
                                        }}
                                    >Insights</Link>
                                </ListItem>
                            </List>
                        </Box>
                    </Stack>
                </Box>
            </Box>
            <Box
                sx={{
                    backgroundImage: `url(${getImages('form-banner-img.jpg')})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center bottom',
                    backgroundSize: 'cover',
                    padding: "0",
                    position: "relative",
                    height: path === '/solution-listing' ? "300px" : "140px",
                    overflow: 'hidden'
                }}
            >
                {path === '/solution-listing' ?
                    <Typography variant='h5'
                        sx={{
                            fontSize: '2.7rem',
                            color: '#ADE3FD',
                            fontWeight: '700',
                            textAlign: 'center',
                            px: 1,
                            pt: '190px',
                            [theme.breakpoints.down('lg')]: {
                                fontSize: '2.3rem',
                            },
                            [theme.breakpoints.down('md')]: {
                                fontSize: '2rem',
                            },
                            [theme.breakpoints.down('sm')]: {
                                fontSize: '1.8rem',
                            },
                        }}
                    >Recommended AI solutions for Eye Care</Typography> : ""
                }
            </Box>
        </>
    )
}

export default Header