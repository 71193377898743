import React from 'react';
import {
    Avatar,
    Badge,
    Box,
    Button,
    List,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    Stack,
    Typography
} from '@mui/material';
import MainCard from '../../commonComponents/MainCard';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import { getImages } from '../../commonComponents/const';
import { styled } from '@mui/material/styles';
import { useTheme } from '@emotion/react';
import {  useNavigate } from 'react-router-dom';
import { setChatSelected, setOpenChat, toggleChatData } from '../../redux/features/chatSlice';
import { useDispatch, useSelector} from 'react-redux';
const RecentChats = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const chatData = useSelector((state) => state.chat.chatData);
    const SmallAvatar = styled(Avatar)(({ theme }) => ({
        width: 24,
        height: 24,
        border: `2px solid ${theme.palette.background.paper}`,
    }));
    const navigate = useNavigate()
    const handleNavigation = (path) => {
        navigate(path);
        if (path === "/inbox") {
            dispatch(setChatSelected(true));
            dispatch(setOpenChat(true));
            if (!chatData) {
                dispatch(toggleChatData());
            }
        }
    };
    const handleChatBox = (path)=>{
        navigate(path)
        if (path === "/inbox") {
            dispatch(setChatSelected(true));
            dispatch(setOpenChat(true));
            if (chatData) {
                dispatch(toggleChatData());
            }
        }
    }

    return (
        <>
            <MainCard content={false}
                sx={{
                    backgroundColor: "#ECF6FF",
                    border: "none",
                    padding: "15px 25px",
                    minHeight: '450px'
                }}
            >
                <Box sx={{ p: 0, pb: 0 }}>
                    <Stack spacing={2} sx={{ display: "flex", flexDirection: "inherit", justifyContent: "space-between", alignItems: "center", width: "100%", marginBottom: "10px" }}>
                        <Typography variant="h6" color="textSecondary"
                            sx={{
                                fontSize: "1.2rem", fontWeight: "600",
                                color: "secondary.main",
                                marginBottom: "1rem"
                            }}
                        >
                            Recent Chats
                        </Typography>
                        <Button onClick={()=> handleNavigation('/inbox')} variant="text" style={{ marginTop: "0" }} sx={{ fontSize: "12px", fontWeight: "600", textTransform: "inherit" }}>
                            View all <ArrowForwardIosOutlinedIcon sx={{ marginLeft: "5px", fill: "primary.main", width: "12px", height: "12px" }} />
                        </Button>
                    </Stack>
                    <List
                        component="nav"
                        sx={{
                            px: 0,
                            py: 0,
                            '& .MuiListItemButton-root': {
                                py: 1.5,
                            }
                        }}
                    >
                        <ListItemButton sx={{ px: "0" }} onClick={()=> handleChatBox('/inbox')}>
                            <ListItemAvatar
                                sx={{
                                    width: "56px"
                                }}
                            >
                                <Avatar
                                    sx={{
                                        color: 'success.main',
                                        bgcolor: 'success.lighter',
                                        width: '50px',
                                        height: '50px'
                                    }}
                                    src={getImages('avatar-1.png')}
                                >

                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                sx={{
                                    pl: '10px',
                                    width: "60%"
                                }}
                                primary={
                                    <Typography noWrap
                                        sx={{
                                            fontSize: "1rem",
                                            fontWeight: '600',
                                            color: 'secondary.main',
                                            lineHeight: '22px',
                                            maxWidth: '220px',
                                            [theme.breakpoints.down('sm')]: {
                                                maxWidth: '110px'
                                            }
                                        }}
                                        variant="h6">John Doe
                                    </Typography>}
                                secondary={<Typography
                                    noWrap
                                    variant="body2"
                                    sx={{
                                        fontSize: '12px',
                                        fontWeight: '500',
                                        color: 'primary.main',
                                        maxWidth: '220px',
                                        [theme.breakpoints.down('sm')]: {
                                            maxWidth: '110px'
                                        }
                                    }}
                                    
                                >What’s the best solution for my problem? Can you send me a listed document please.</Typography>}
                            />
                            <ListItemText
                                sx={{
                                    width: "calc(40% - 56px)"
                                }}
                            >
                                <Stack alignItems="flex-end">
                                    <Typography
                                        variant="body2" noWrap
                                        sx={{
                                            color: "ltheme.main",
                                            fontSize: '12px',
                                            fontWeight: '500',
                                            mb: '5px'
                                        }}
                                    >
                                        11:54 AM
                                    </Typography>
                                    <Box component="span"
                                        sx={{
                                            bgcolor: 'primary.main',
                                            width: 24, height: 24,
                                            borderRadius: '50%',
                                            fontSize: '12px',
                                            color: '#fff',
                                            textAlign: 'center',
                                            lineHeight: '24px'
                                        }}>10</Box>
                                </Stack>
                            </ListItemText>
                        </ListItemButton>
                        <ListItemButton sx={{ px: "0" }}>
                            <ListItemAvatar
                                sx={{
                                    width: "56px"
                                }}
                            >
                                <Avatar
                                    sx={{
                                        color: 'success.main',
                                        bgcolor: 'success.lighter',
                                        width: '50px',
                                        height: '50px'
                                    }}
                                    src={getImages('2.jpg')}
                                >

                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                sx={{
                                    pl: '10px',
                                    width: "60%"
                                }}
                                primary={
                                    <Typography noWrap
                                        sx={{
                                            fontSize: "1rem",
                                            fontWeight: '600',
                                            color: 'secondary.main',
                                            lineHeight: '22px',
                                            maxWidth: '220px',
                                            [theme.breakpoints.down('sm')]: {
                                                maxWidth: '110px'
                                            }
                                        }}
                                        variant="h6">Messiah Rozo
                                    </Typography>}
                                secondary={<Typography
                                    noWrap
                                    variant="body2"
                                    sx={{
                                        fontSize: '12px',
                                        fontWeight: '500',
                                        color: 'ltheme.main',
                                        maxWidth: '220px',
                                        [theme.breakpoints.down('sm')]: {
                                            maxWidth: '110px'
                                        }
                                    }}
                                >Hello David</Typography>}
                            />
                            <ListItemText
                                sx={{
                                    width: "calc(40% - 56px)"
                                }}
                            >
                                <Stack alignItems="flex-end">
                                    <Typography
                                        variant="body2" noWrap
                                        sx={{
                                            color: "ltheme.main",
                                            fontSize: '12px',
                                            fontWeight: '500',
                                            mb: '5px'
                                        }}
                                    >
                                        Just now
                                    </Typography>
                                    <Box component="span"
                                    >&nbsp;</Box>
                                </Stack>
                            </ListItemText>
                        </ListItemButton>
                        <ListItemButton sx={{ px: "0" }}onClick={()=> handleChatBox('/inbox')}>
                            <ListItemAvatar
                                sx={{
                                    width: "56px"
                                }}
                            >
                                <Badge
                                    overlap="circular">

                                    <Badge
                                        overlap="circular"
                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                                        badgeContent={
                                            <SmallAvatar
                                                sx={{
                                                    width: "30px",
                                                    height: "30px",
                                                    ml: 1.5
                                                }}
                                                alt="Remy Sharp" src={getImages('2.jpg')} />
                                        }
                                    />
                                    <Avatar alt="Travis Howard"
                                        sx={{
                                            color: 'success.main',
                                            bgcolor: 'success.lighter',
                                            width: '50px',
                                            height: '50px'
                                        }}
                                        src={getImages('1.jpg')}
                                    />
                                    <Badge
                                        overlap="circular"
                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                        badgeContent={
                                            <SmallAvatar
                                                sx={{
                                                    mr: 1.5
                                                }}
                                                alt="Remy Sharp" src={getImages('2.jpg')} />
                                        }
                                    />
                                </Badge>
                            </ListItemAvatar>
                            <ListItemText
                                sx={{
                                    pl: '10px',
                                    width: "60%"
                                }}
                                primary={
                                    <Typography noWrap
                                        sx={{
                                            fontSize: "1rem",
                                            fontWeight: '600',
                                            color: 'secondary.main',
                                            lineHeight: '22px',
                                            maxWidth: '220px',
                                            [theme.breakpoints.down('sm')]: {
                                                maxWidth: '110px'
                                            }
                                        }}
                                        variant="h6">Solution discussion
                                    </Typography>}
                                secondary={<Typography
                                    noWrap
                                    variant="body2"
                                    sx={{
                                        fontSize: '12px',
                                        fontWeight: '500',
                                        color: 'primary.main',
                                        maxWidth: '220px',
                                        [theme.breakpoints.down('sm')]: {
                                            maxWidth: '110px'
                                        }
                                    }}
                                >Vish: Yes I’m available.</Typography>}
                            />
                            <ListItemText
                                sx={{
                                    width: "calc(40% - 56px)"
                                }}
                            >
                                <Stack alignItems="flex-end">
                                    <Typography
                                        variant="body2" noWrap
                                        sx={{
                                            color: "ltheme.main",
                                            fontSize: '12px',
                                            fontWeight: '500',
                                            mb: '5px'
                                        }}
                                    >
                                        09:41 AM
                                    </Typography>
                                    <Box component="span"
                                        sx={{
                                            bgcolor: 'primary.main',
                                            width: 24, height: 24,
                                            borderRadius: '50%',
                                            fontSize: '12px',
                                            color: '#fff',
                                            textAlign: 'center',
                                            lineHeight: '24px'
                                        }}>10</Box>
                                </Stack>
                            </ListItemText>
                        </ListItemButton>
                        <ListItemButton sx={{ px: "0" }}>
                            <ListItemAvatar
                                sx={{
                                    width: "56px"
                                }}
                            >
                                <Avatar
                                    sx={{
                                        color: 'success.main',
                                        bgcolor: 'success.lighter',
                                        width: '50px',
                                        height: '50px'
                                    }}
                                    src={getImages('avatar-1.png')}
                                >

                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                sx={{
                                    pl: '10px',
                                    width: "60%"
                                }}
                                primary={
                                    <Typography noWrap
                                        sx={{
                                            fontSize: "1rem",
                                            fontWeight: '600',
                                            color: 'secondary.main',
                                            lineHeight: '22px',
                                            maxWidth: '220px',
                                            [theme.breakpoints.down('sm')]: {
                                                maxWidth: '110px'
                                            }
                                        }}
                                        variant="h6">Emily Blunt
                                    </Typography>}
                                secondary={<Typography
                                    noWrap
                                    variant="body2"
                                    sx={{
                                        fontSize: '12px',
                                        fontWeight: '500',
                                        color: 'ltheme.main',
                                        maxWidth: '220px',
                                        [theme.breakpoints.down('sm')]: {
                                            maxWidth: '110px'
                                        }
                                    }}
                                >I’ll confirm this with my team and get back to you asap!</Typography>}
                            />
                            <ListItemText
                                sx={{
                                    width: "calc(40% - 56px)"
                                }}
                            >
                                <Stack alignItems="flex-end">
                                    <Typography
                                        variant="body2" noWrap
                                        sx={{
                                            color: "ltheme.main",
                                            fontSize: '12px',
                                            fontWeight: '500',
                                            mb: '5px'
                                        }}
                                    >
                                        24 Jan
                                    </Typography>
                                    <Box component="span"
                                    >&nbsp;</Box>
                                </Stack>
                            </ListItemText>
                        </ListItemButton>
                        <ListItemButton sx={{ px: "0" }}>
                            <ListItemAvatar
                                sx={{
                                    width: "56px"
                                }}
                            >
                                <Badge
                                    overlap="circular"
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    badgeContent={
                                        <SmallAvatar
                                            sx={{
                                                width: "30px",
                                                height: "30px"
                                            }}
                                            alt="Remy Sharp" src={getImages('2.jpg')} />
                                    }
                                >
                                    <Avatar alt="Travis Howard"
                                        sx={{
                                            color: 'success.main',
                                            bgcolor: 'success.lighter',
                                            width: '50px',
                                            height: '50px'
                                        }}
                                        src={getImages('4.jpg')}
                                    />
                                </Badge>
                            </ListItemAvatar>
                            <ListItemText
                                sx={{
                                    pl: '10px',
                                    width: "60%"
                                }}
                                primary={
                                    <Typography noWrap
                                        sx={{
                                            fontSize: "1rem",
                                            fontWeight: '600',
                                            color: 'secondary.main',
                                            lineHeight: '22px',
                                            maxWidth: '220px',
                                            [theme.breakpoints.down('sm')]: {
                                                maxWidth: '110px'
                                            }
                                        }}
                                        variant="h6">Tyson, Lauren
                                    </Typography>}
                                secondary={<Typography
                                    noWrap
                                    variant="body2"
                                    sx={{
                                        fontSize: '12px',
                                        fontWeight: '500',
                                        color: 'ltheme.main',
                                        maxWidth: '220px',
                                        [theme.breakpoints.down('sm')]: {
                                            maxWidth: '110px'
                                        }
                                    }}
                                >Lauren : When can we schedule a call?</Typography>}
                            />
                            <ListItemText
                                sx={{
                                    width: "calc(40% - 56px)"
                                }}
                            >
                                <Stack alignItems="flex-end">
                                    <Stack alignItems="flex-end">
                                        <Typography
                                            variant="body2" noWrap
                                            sx={{
                                                color: "ltheme.main",
                                                fontSize: '12px',
                                                fontWeight: '500',
                                                mb: '5px'
                                            }}
                                        >
                                            Yesterday
                                        </Typography>
                                        <Box component="span"
                                        >&nbsp;</Box>
                                    </Stack>
                                </Stack>
                            </ListItemText>
                        </ListItemButton>
                    </List>
                </Box>
            </MainCard>
        </>
    )
}

export default RecentChats