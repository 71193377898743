import { fetchBaseQuery, createApi } from '@reduxjs/toolkit/query/react';


const baseUrl = process.env.REACT_APP_base_URL;
const logoBaseUrl = process.env.REACT_APP_S3_BUCKET_URL;

export const viewRequirement  = createApi({
    reducerPath: 'viewRequirement',
    baseQuery: fetchBaseQuery({
      baseUrl: baseUrl,
      prepareHeaders: (headers) => {
        // Add any headers you need here
        return headers;
      },
    }),
    endpoints: (builder) => ({
      fetchViewRequirement: builder.query({
        query: ({userId , boardId}) => ({
          url: `requirement_boards/view-requirement/${userId}?board_id=${boardId}`,
          method: 'GET',
        }),
        transformResponse: (response) => {
          if(response.data.length>0){
            return response;
          }else{
            return [];
          }
        },
        
        refetchOnWindowFocus: true,
        // retry: RetryOnError,
        onError: (error) => {
          console.error('Fetch Document details error:', error);
          // Handle error cases as per your application's requirements
        },
      })
    })
})


export const { useFetchViewRequirementQuery } = viewRequirement;
export default viewRequirement;
