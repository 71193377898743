import { createApi , fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = process.env.REACT_APP_base_URL;
const logoBaseUrl = 'https://bsdawebfiledata.s3.ca-central-1.amazonaws.com/solution/';
export const InboxRequirementBoardList = createApi({
    reducerPath: 'inboxRequirementBoardList',
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders:(header)=>{
            return header;
        }
    }),
    endpoints:(builder)=>({
        fetchInboxRequirementBoardDetails: builder.query({
        query:({user_id, searchParam})=>({
            url:`inbox/boards/${user_id}?search_board_name=${searchParam}`,
            method: 'GET',
        }),
        retry: (failureCount, error,) => {
            // Retry up to 3 times if the request fails due to network issues or server errors
            return failureCount <= 3 && (error.status === 503 || error.message.includes('Network Error'));
          },
        transformResponse: (response) => {
             // Extract relevant data from the response
            const { statusCode, status, message, data } = response;

            // Return the extracted data
            return {
                statusCode,
                status,
                message,
                boards: data
            };
        },
        
        refetchOnWindowFocus: true,
        // retry: RetryOnError,
        onError: (error) => {
          console.error('Fetch user details error:', error);
          // Handle error cases as per your application's requirements
        },
        }),

    })
})

export const{useFetchInboxRequirementBoardDetailsQuery} =  InboxRequirementBoardList;
export default  InboxRequirementBoardList;