import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl =process.env.REACT_APP_base_URL;

const updateUserProfile = createApi({
    reducerPath: 'updateUserProfile',
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers) => {
            // Add any headers you need here
            return headers;
        },
    }),
    endpoints: (builder) => ({
        updateUserProfile: builder.mutation({
            query: ({  userId , formData}) => {
                return{
                url:`user/${userId}`,
                method: 'PUT',
                body: formData,
              }
            },
            transformResponse: (response) => {
                return response;
            },
        }),
    }),
});

export const { useUpdateUserProfileMutation } = updateUserProfile;
export default updateUserProfile;
