import React from 'react'
import {
    Avatar,
    Box,
    IconButton,
    List,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    Typography,
    TextField,
    styled,
    InputBase,
    Button,
    Chip,
    Stack
} from '@mui/material'
import { useTheme } from '@emotion/react';
import SearchIcon from '@mui/icons-material/Search';
import { getImages } from '../const';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';

const CreateNewGroupChat = ({ handleClose, manageTeam, goBackHandle }) => {
    const theme = useTheme();
    const Search = styled('div')(({ theme }) => ({
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: '#ECF6FF',
        '&:hover': {
            backgroundColor: '#ECF6FF',
        },
        marginRight: '0',
        marginLeft: 0,
        marginBottom: 5,
        width: '100%'
    }));
    const SearchIconWrapper = styled('div')(({ theme }) => ({
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        right: '0',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }));

    const StyledInputBase = styled(InputBase)(({ theme }) => ({
        color: '#106EED',
        width: '100%',
        '& .MuiInputBase-input': {
            padding: theme.spacing(1.7, 0, 1.7, 1.7),
            paddingRight: `calc(1em + ${theme.spacing(4)})`,
            transition: theme.transitions.create('width'),
            width: '100%',
            fontSize: '12px',
            fontWeight: '500'
        },
    }));
    const handleDelete = () => {
        console.info('You clicked the delete icon.');
    };
    return (
        <>
            <Box
                sx={{
                    mb: 3
                }}
            >
                <Typography variant='h5'
                    sx={{
                        fontSize: "1.1rem",
                        color: "secondary.main",
                        fontWeight: "700"
                    }}>
                    Create New Group
                </Typography>
            </Box>
            <Box
                sx={{
                    mb: 3
                }}
            >
                <Typography variant='h5'
                    sx={{
                        fontSize: "12px",
                        color: "ltheme.main",
                        fontWeight: "500",
                        mb: 0.5
                    }}>
                    Group Name
                </Typography>
                <TextField
                    hiddenLabel
                    id="filled-hidden-label-normal"
                    variant="filled"
                    sx={{
                        width: '100%',
                        borderRadius: '4px',
                        backgroundColor: '#ECF6FF',
                        border: 'solid 1px #ECF6FF',
                        '& .MuiInputBase-root': {
                            backgroundColor: '#ECF6FF',
                        },
                        '& .MuiInputBase-input': {
                            fontSize: '0.9rem',
                            color: 'lightSecondary.main',
                            fontWeight: '500',
                            pt: 1.5,
                            pb: 1.5
                        },
                        '&:hover': {
                            borderColor: '#ECF6FF',
                        },
                        '& .MuiInputBase-root:hover:before': {
                            border: 'none'
                        },
                        '& .MuiInputBase-root:before': {
                            border: 'none'
                        },
                        '& .MuiInputBase-root:after': {
                            border: 'none'
                        }
                    }}
                />
            </Box>
            <Box
                sx={{
                    mb: 1
                }}
            >
                <Typography
                    variant="body2"
                    sx={{
                        fontSize: '0.9rem',
                        fontWeight: '600',
                        color: 'secondary.main',
                        mb: 1,

                    }}
                >Add Participants</Typography>
                <Search
                    sx={{
                        backgroundColor: 'extraLTheme.main',
                        mb: 1
                    }}
                >
                    <SearchIconWrapper>
                        <SearchIcon sx={{ color: "lightSecondary.main" }} />
                    </SearchIconWrapper>
                    <StyledInputBase
                        placeholder={manageTeam ? "Search people to add" : "Search person to start a new chat with"}
                        inputProps={{ 'aria-label': 'search' }}
                    />
                </Search>
                <Stack direction="row" spacing={1}
                    sx={{
                        overflowX: "auto",
                        '&::-webkit-scrollbar': {
                            height: '4px',
                            borderRadius: '4px'
                        },
                        '&::-webkit-scrollbar-track': {
                            backgroundColor: 'transparent',
                            borderRadius: '4px'
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: '#9C9C9C',
                            borderRadius: '4px'
                        }
                    }}
                >
                    <Chip
                        avatar={<Avatar alt="Allie Grater" sx={{ width: '24px', height: '24px' }} src={getImages('1.jpg')} />}
                        label="Allie Grater"
                        // onClick={handleClick}
                        deleteIcon={<CloseIcon />}
                        onDelete={handleDelete}
                        sx={{
                            backgroundColor: '#ECF6FF',
                            borderRadius: '4px',
                            color: 'lightSecondary.main',
                            fontSize: '12px',
                            fontWeight: '600',
                            '& .MuiChip-deleteIcon': {
                                fontSize: '14px',
                                color: 'lightSecondary.main'
                            }
                        }}
                    />
                    <Chip
                        avatar={<Avatar alt="Bridget Theriveaquai" sx={{ width: '24px', height: '24px' }} src={getImages('2.jpg')} />}
                        label="Bridget Theriveaquai"
                        // onClick={handleClick}
                        deleteIcon={<CloseIcon />}
                        onDelete={handleDelete}
                        sx={{
                            backgroundColor: '#ECF6FF',
                            borderRadius: '4px',
                            color: 'lightSecondary.main',
                            fontSize: '12px',
                            fontWeight: '600',
                            '& .MuiChip-deleteIcon': {
                                fontSize: '14px',
                                color: 'lightSecondary.main'
                            }
                        }}
                    />
                    <Chip
                        avatar={<Avatar alt="Harriet Upp" sx={{ width: '24px', height: '24px' }} src={getImages('3.jpg')} />}
                        label="Harriet Upp"
                        // onClick={handleClick}
                        deleteIcon={<CloseIcon />}
                        onDelete={handleDelete}
                        sx={{
                            backgroundColor: '#ECF6FF',
                            borderRadius: '4px',
                            color: 'lightSecondary.main',
                            fontSize: '12px',
                            fontWeight: '600',
                            '& .MuiChip-deleteIcon': {
                                fontSize: '14px',
                                color: 'lightSecondary.main'
                            }
                        }}
                    />
                </Stack>
            </Box>
            <Box>
                <Stack direction="row" spacing={1}
                    sx={{
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}
                >
                    <Typography
                        variant="body2"
                        sx={{
                            fontSize: '0.9rem',
                            fontWeight: '500',
                            color: 'ltheme.main',
                            mb: 1,

                        }}
                    >People in this board</Typography>
                    <Button variant="text" style={{ marginTop: "0" }} sx={{ fontSize: "12px", fontWeight: "600", textTransform: "inherit" }}>
                        Manage 
                        <ArrowForwardIosOutlinedIcon 
                        sx={{ 
                                marginLeft: "5px", 
                                color: "primary.main", 
                                fontSize: '12px',
                                marginTop: '3px'
                            }} 
                        />
                    </Button>
                </Stack>
                <List
                    component="nav"
                    sx={{
                        px: 0,
                        py: 0,
                        mb: 3,
                        '& .MuiListItemButton-root': {
                            py: 0.5,
                        },
                        overflowY: 'auto',
                        maxHeight: '290px',
                        '&::-webkit-scrollbar': {
                            width: '6px',
                            borderRadius: '4px'
                        },
                        '&::-webkit-scrollbar-track': {
                            backgroundColor: 'transparent',
                            borderRadius: '4px'
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: '#9C9C9C',
                            borderRadius: '4px'
                        }
                    }}
                >
                    <ListItemButton sx={{ px: "0", py: "5px" }}>
                        <ListItemAvatar
                            sx={{
                                width: "56px"
                            }}
                        >
                            <Avatar
                                sx={{
                                    color: 'success.main',
                                    bgcolor: 'success.lighter',
                                    width: '50px',
                                    height: '50px'
                                }}
                                src={getImages('1.jpg')}
                            >

                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            sx={{
                                pl: '10px',
                                width: "calc(100% - 56px)"
                            }}
                            primary={
                                <Typography noWrap
                                    sx={{
                                        fontSize: "1rem",
                                        fontWeight: '600',
                                        color: 'secondary.main',
                                        lineHeight: '22px',
                                        maxWidth: '220px',
                                        [theme.breakpoints.down('sm')]: {
                                            maxWidth: '110px'
                                        }
                                    }}
                                    variant="h6">Elizabeth Bush
                                </Typography>}
                            secondary={<Typography
                                noWrap
                                variant="body2"
                                sx={{
                                    fontSize: '12px',
                                    fontWeight: '500',
                                    color: 'ltheme.main',
                                }}
                            >Tech support</Typography>}
                        />
                    </ListItemButton>
                    <ListItemButton sx={{ px: "0", py: "5px" }}>
                        <ListItemAvatar
                            sx={{
                                width: "56px"
                            }}
                        >
                            <Avatar
                                sx={{
                                    color: 'success.main',
                                    bgcolor: 'success.lighter',
                                    width: '50px',
                                    height: '50px'
                                }}
                                src={getImages('2.jpg')}
                            >

                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            sx={{
                                pl: '10px',
                                width: "calc(100% - 56px)"
                            }}
                            primary={
                                <Typography noWrap
                                    sx={{
                                        fontSize: "1rem",
                                        fontWeight: '600',
                                        color: 'secondary.main',
                                        lineHeight: '22px',
                                        maxWidth: '220px',
                                        [theme.breakpoints.down('sm')]: {
                                            maxWidth: '110px'
                                        }
                                    }}
                                    variant="h6">Ray Sin
                                </Typography>}
                            secondary={<Typography
                                noWrap
                                variant="body2"
                                sx={{
                                    fontSize: '12px',
                                    fontWeight: '500',
                                    color: 'ltheme.main',
                                }}
                            >Chief executive officer</Typography>}
                        />
                    </ListItemButton>
                    <ListItemButton sx={{ px: "0", py: "5px" }}>
                        <ListItemAvatar
                            sx={{
                                width: "56px"
                            }}
                        >
                            <Avatar
                                sx={{
                                    color: 'success.main',
                                    bgcolor: 'success.lighter',
                                    width: '50px',
                                    height: '50px'
                                }}
                                src={getImages('3.jpg')}
                            >

                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            sx={{
                                pl: '10px',
                                width: "calc(100% - 56px)"
                            }}
                            primary={
                                <Typography noWrap
                                    sx={{
                                        fontSize: "1rem",
                                        fontWeight: '600',
                                        color: 'secondary.main',
                                        lineHeight: '22px',
                                        maxWidth: '220px',
                                        [theme.breakpoints.down('sm')]: {
                                            maxWidth: '110px'
                                        }
                                    }}
                                    variant="h6">Katherine Erd
                                </Typography>}
                            secondary={<Typography
                                noWrap
                                variant="body2"
                                sx={{
                                    fontSize: '12px',
                                    fontWeight: '500',
                                    color: 'ltheme.main',
                                }}
                            >Marketing manager</Typography>}
                        />
                    </ListItemButton>
                    <ListItemButton sx={{ px: "0", py: "5px" }}>
                        <ListItemAvatar
                            sx={{
                                width: "56px"
                            }}
                        >
                            <Avatar
                                sx={{
                                    color: 'success.main',
                                    bgcolor: 'success.lighter',
                                    width: '50px',
                                    height: '50px'
                                }}
                                src={getImages('4.jpg')}
                            >

                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            sx={{
                                pl: '10px',
                                width: "calc(100% - 56px)"
                            }}
                            primary={
                                <Typography noWrap
                                    sx={{
                                        fontSize: "1rem",
                                        fontWeight: '600',
                                        color: 'secondary.main',
                                        lineHeight: '22px',
                                        maxWidth: '220px',
                                        [theme.breakpoints.down('sm')]: {
                                            maxWidth: '110px'
                                        }
                                    }}
                                    variant="h6">Paddy O’Furniture
                                </Typography>}
                            secondary={<Typography
                                noWrap
                                variant="body2"
                                sx={{
                                    fontSize: '12px',
                                    fontWeight: '500',
                                    color: 'ltheme.main',
                                }}
                            >Creative director</Typography>}
                        />
                    </ListItemButton>
                    <ListItemButton sx={{ px: "0", py: "5px" }}>
                        <ListItemAvatar
                            sx={{
                                width: "56px"
                            }}
                        >
                            <Avatar
                                sx={{
                                    color: 'success.main',
                                    bgcolor: 'success.lighter',
                                    width: '50px',
                                    height: '50px'
                                }}
                                src={getImages('1.jpg')}
                            >

                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            sx={{
                                pl: '10px',
                                width: "calc(100% - 56px)"
                            }}
                            primary={
                                <Typography noWrap
                                    sx={{
                                        fontSize: "1rem",
                                        fontWeight: '600',
                                        color: 'secondary.main',
                                        lineHeight: '22px',
                                        maxWidth: '220px',
                                        [theme.breakpoints.down('sm')]: {
                                            maxWidth: '110px'
                                        }
                                    }}
                                    variant="h6">Joe Arias
                                </Typography>}
                            secondary={<Typography
                                noWrap
                                variant="body2"
                                sx={{
                                    fontSize: '12px',
                                    fontWeight: '500',
                                    color: 'ltheme.main',
                                }}
                            >UI/UX designer</Typography>}
                        />
                    </ListItemButton>
                    <ListItemButton sx={{ px: "0", py: "5px" }}>
                        <ListItemAvatar
                            sx={{
                                width: "56px"
                            }}
                        >
                            <Avatar
                                sx={{
                                    color: 'success.main',
                                    bgcolor: 'success.lighter',
                                    width: '50px',
                                    height: '50px'
                                }}
                                src={getImages('1.jpg')}
                            >

                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            sx={{
                                pl: '10px',
                                width: "calc(100% - 56px)"
                            }}
                            primary={
                                <Typography noWrap
                                    sx={{
                                        fontSize: "1rem",
                                        fontWeight: '600',
                                        color: 'secondary.main',
                                        lineHeight: '22px',
                                        maxWidth: '220px',
                                        [theme.breakpoints.down('sm')]: {
                                            maxWidth: '110px'
                                        }
                                    }}
                                    variant="h6">Elizabeth Bush
                                </Typography>}
                            secondary={<Typography
                                noWrap
                                variant="body2"
                                sx={{
                                    fontSize: '12px',
                                    fontWeight: '500',
                                    color: 'ltheme.main',
                                }}
                            >Tech support</Typography>}
                        />
                    </ListItemButton>
                    <ListItemButton sx={{ px: "0", py: "5px" }}>
                        <ListItemAvatar
                            sx={{
                                width: "56px"
                            }}
                        >
                            <Avatar
                                sx={{
                                    color: 'success.main',
                                    bgcolor: 'success.lighter',
                                    width: '50px',
                                    height: '50px'
                                }}
                                src={getImages('2.jpg')}
                            >

                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            sx={{
                                pl: '10px',
                                width: "calc(100% - 56px)"
                            }}
                            primary={
                                <Typography noWrap
                                    sx={{
                                        fontSize: "1rem",
                                        fontWeight: '600',
                                        color: 'secondary.main',
                                        lineHeight: '22px',
                                        maxWidth: '220px',
                                        [theme.breakpoints.down('sm')]: {
                                            maxWidth: '110px'
                                        }
                                    }}
                                    variant="h6">Ray Sin
                                </Typography>}
                            secondary={<Typography
                                noWrap
                                variant="body2"
                                sx={{
                                    fontSize: '12px',
                                    fontWeight: '500',
                                    color: 'ltheme.main',
                                }}
                            >Chief executive officer</Typography>}
                        />
                    </ListItemButton>
                    <ListItemButton sx={{ px: "0", py: "5px" }}>
                        <ListItemAvatar
                            sx={{
                                width: "56px"
                            }}
                        >
                            <Avatar
                                sx={{
                                    color: 'success.main',
                                    bgcolor: 'success.lighter',
                                    width: '50px',
                                    height: '50px'
                                }}
                                src={getImages('3.jpg')}
                            >

                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            sx={{
                                pl: '10px',
                                width: "calc(100% - 56px)"
                            }}
                            primary={
                                <Typography noWrap
                                    sx={{
                                        fontSize: "1rem",
                                        fontWeight: '600',
                                        color: 'secondary.main',
                                        lineHeight: '22px',
                                        maxWidth: '220px',
                                        [theme.breakpoints.down('sm')]: {
                                            maxWidth: '110px'
                                        }
                                    }}
                                    variant="h6">Katherine Erd
                                </Typography>}
                            secondary={<Typography
                                noWrap
                                variant="body2"
                                sx={{
                                    fontSize: '12px',
                                    fontWeight: '500',
                                    color: 'ltheme.main',
                                }}
                            >Marketing manager</Typography>}
                        />
                    </ListItemButton>
                    <ListItemButton sx={{ px: "0", py: "5px" }}>
                        <ListItemAvatar
                            sx={{
                                width: "56px"
                            }}
                        >
                            <Avatar
                                sx={{
                                    color: 'success.main',
                                    bgcolor: 'success.lighter',
                                    width: '50px',
                                    height: '50px'
                                }}
                                src={getImages('4.jpg')}
                            >

                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            sx={{
                                pl: '10px',
                                width: "calc(100% - 56px)"
                            }}
                            primary={
                                <Typography noWrap
                                    sx={{
                                        fontSize: "1rem",
                                        fontWeight: '600',
                                        color: 'secondary.main',
                                        lineHeight: '22px',
                                        maxWidth: '220px',
                                        [theme.breakpoints.down('sm')]: {
                                            maxWidth: '110px'
                                        }
                                    }}
                                    variant="h6">Paddy O’Furniture
                                </Typography>}
                            secondary={<Typography
                                noWrap
                                variant="body2"
                                sx={{
                                    fontSize: '12px',
                                    fontWeight: '500',
                                    color: 'ltheme.main',
                                }}
                            >Creative director</Typography>}
                        />
                    </ListItemButton>
                    <ListItemButton sx={{ px: "0", py: "5px" }}>
                        <ListItemAvatar
                            sx={{
                                width: "56px"
                            }}
                        >
                            <Avatar
                                sx={{
                                    color: 'success.main',
                                    bgcolor: 'success.lighter',
                                    width: '50px',
                                    height: '50px'
                                }}
                                src={getImages('1.jpg')}
                            >

                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            sx={{
                                pl: '10px',
                                width: "calc(100% - 56px)"
                            }}
                            primary={
                                <Typography noWrap
                                    sx={{
                                        fontSize: "1rem",
                                        fontWeight: '600',
                                        color: 'secondary.main',
                                        lineHeight: '22px',
                                        maxWidth: '220px',
                                        [theme.breakpoints.down('sm')]: {
                                            maxWidth: '110px'
                                        }
                                    }}
                                    variant="h6">Joe Arias
                                </Typography>}
                            secondary={<Typography
                                noWrap
                                variant="body2"
                                sx={{
                                    fontSize: '12px',
                                    fontWeight: '500',
                                    color: 'ltheme.main',
                                }}
                            >UI/UX designer</Typography>}
                        />
                    </ListItemButton>
                </List>
                <Box
                    sx={{
                        mt: 3,
                        display: 'flex',
                        gap: 2
                    }}
                >
                    {manageTeam ? 
                        <Button variant="outlined"
                            sx={{
                                textTransform: 'inherit',
                                p: "10px 25px",
                                width: '50%',
                                borderWidth: '2px',
                                fontWeight: '600',
                                '&:hover': {
                                    borderWidth: '2px',
                                }
                            }}
                            onClick={goBackHandle}
                        >
                            Go Back
                        </Button> :
                        <Button variant="outlined"
                            sx={{
                                textTransform: 'inherit',
                                p: "10px 25px",
                                width: '50%',
                                borderWidth: '2px',
                                fontWeight: '600',
                                '&:hover': {
                                    borderWidth: '2px',
                                }
                            }}
                            onClick={handleClose}
                        >
                            Cancel
                        </Button>
                    }
                    <Button variant="contained"
                        sx={{
                            textTransform: 'inherit',
                            p: "12px 25px",
                            width: '50%',
                            boxShadow: 'none',
                            fontWeight: '600',
                        }}>
                        Create Group
                    </Button>
                </Box>
            </Box>
        </>
    )
}

export default CreateNewGroupChat