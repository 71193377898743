import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseUrl = process.env.REACT_APP_base_URL

export const getCityList = createApi({
    reducerPath: 'getCityList',
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers) => {
            return headers;
        },
    }),
    endpoints: (builder) => ({
        fetchCityList: builder.query({
            query: () => ({
                url: `city`,
                method: 'GET',
            }),
            transformErrorResponse: (response) => {
                return response.data ? response.data : [];
            },
            refetchOnWindowFocus: true,
            onError: (error) => {
                console.error('Fetch user details error:', error);

            },
        })
    })
})

export const {useFetchCityListQuery} = getCityList;
