import React, { useEffect, useState } from 'react'
import {
    Avatar,
    Box,
    List,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    Typography,
    styled,
    InputBase,
    Button,
    IconButton
} from '@mui/material'
import { useTheme } from '@emotion/react';
import SearchIcon from '@mui/icons-material/Search';
import { getImages } from '../const';
import { useDispatch, useSelector } from 'react-redux';
import { useFetchUserListQuery } from '../../redux/api/Inbox/getUserList';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { setRefetchDocument } from '../../redux/features/refetch';
import { hideLoader, showLoader } from '../../redux/features/loader';
import { useDriveDocumentShareMutation } from '../../redux/api/BsiDrive/driveDocumentShare';
import CancelIcon from '@mui/icons-material/Cancel';

const ShareSolutionChatModal = ({ handleClose , showToastMessage}) => {
    const theme = useTheme();
    const folderName = useSelector((state)=> state.drive.folderName);
    const documentName = useSelector((state)=>state.drive.fileName);
    const Search = styled('div')(({ theme }) => ({
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: '#ECF6FF',
        '&:hover': {
            backgroundColor: '#ECF6FF',
        },
        marginRight: '0',
        marginLeft: 0,
        marginBottom: 5,
        width: '100%',
        display: 'flex', 
        alignItems: 'center',
    }));
    const SearchIconWrapper = styled('div')(({ theme }) => ({
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        right: '0',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }));

    const StyledInputBase = styled(InputBase)(({ theme }) => ({
        color: '#106EED',
        width: '100%',
        '& .MuiInputBase-input': {
            padding: theme.spacing(1.7, 0, 1.7, 1.7),
            paddingRight: `calc(1em + ${theme.spacing(4)})`,
            transition: theme.transitions.create('width'),
            width: '100%',
            fontSize: '12px',
            fontWeight: '500'
        },
    }));


    const userId = localStorage.getItem("userId");
    const dispatch = useDispatch()
    const selectedSolutionId =localStorage.getItem("selectedSolutionId");
    const solutionName = useSelector((state)=>state.drive.solutionName);
    const selectedBoardId = localStorage.getItem("selectedBoardId");
    const selectedFolderId = useSelector((state)=>state.drive.folderId);
    const selectedDocumentId = useSelector((state)=>state.drive.fileId);
    const documentStatus = useSelector((state)=>state.drive.documentStatus);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [permissions, setPermissions] = useState({});


      // Function to handle selecting a user
  const handleUserSelect = (user) => {
    setSelectedUsers((prevSelectedUsers) => {
      // Check if the user is already selected
      const alreadySelected = prevSelectedUsers.some(selected => selected.userId === user._id);
      if (alreadySelected) {
        // If already selected, remove the user from the list
        return prevSelectedUsers.filter(selected => selected.userId !== user._id);
      } else {
        // If not selected, add the user to the list with default permissions
        return [...prevSelectedUsers, { userId: user._id, permissions: ['read'] }];
      }
    });
  };

  const [searchParams , setSearchParams] = useState("");
  const [searchValue , setSearchValue] = useState("");
    const {data: userList, error: userDetailError, isLoading , refetch: refetchUserList,} = useFetchUserListQuery({
        userId: userId,
        selectedBoardId: selectedBoardId,
        selectedSolutionId:selectedSolutionId,
        searchParams: searchValue,
      });

      const [driveDocumentShare, { isLoading:isDocumentShare, isError, error }] = useDriveDocumentShareMutation();

      const documentId = selectedFolderId || selectedDocumentId

        // Function to handle sharing the document
     const handleShare = async () => {
      try {
      await driveDocumentShare({
        documentId: documentId,
        userId: userId,
        selectedBoardId: selectedBoardId,
        selectedSolutionId: selectedSolutionId,
        documentStatus: documentStatus,
        selectedUsers: selectedUsers,
      }).unwrap();
      const successMessage = driveDocumentShare?.message || 'Item shared';
      showToastMessage(successMessage, 'success');
      dispatch(setRefetchDocument(true));
      handleClose();
    }catch (error) {
        const errorMessage = driveDocumentShare?.message || 'Failed to share item';
        showToastMessage(errorMessage, 'error');
      }
    };

    const handleInputChange = (event) => {
      setSearchParams(event.target.value);
    };

    const handleUserSearch = async (event) => {
      if (event.key === 'Enter') {
        const inputValue = event.target.value.trim();
        if (!inputValue) {
            showToastMessage('Please enter a search term', 'error');
            return;
        }

        setSearchValue(inputValue);
        dispatch(showLoader())
  
      try {
        // Await refetch and handle search results
        await refetchUserList();
        if (userDetailError) {
            const errorMessage = userList?.message || 'Failed to load search result';
            showToastMessage(errorMessage, 'error');
        }
      } catch (userDetailError) {
        // Handle errors if needed
        showToastMessage('An error occurred during search', 'error');
      } finally {
        // Hide loader after refetch is complete
        dispatch(hideLoader());
      }
    }
    };
    const handleClearSearch = () => {
      setSearchParams("");
      setSearchValue(""); // Reset search value to empty
      refetchUserList(); // Refetch all data
    };

    useEffect(() => {
        if (isDocumentShare) {
          dispatch(showLoader());
        } else {
          dispatch(hideLoader());
        }
      }, [isDocumentShare, dispatch]);


    return (
        <>
            <Box
                sx={{
                    mb: 3
                }}
            >
                <Typography variant='h5'
                     sx={{
                      fontSize: '1.3rem',
                      color: 'secondary.main',
                      fontWeight: '700',
                      maxWidth: '100%', // Set maximum width to the width of the modal
                      whiteSpace: 'nowrap', // Prevents text from wrapping onto a new line
                      overflow: 'hidden', // Hides any overflowed text
                      textOverflow: 'ellipsis', // Adds "..." at the end of the truncated text
                    }}
                    >
                    Share “{folderName || documentName}”
                </Typography>
            </Box>
            <Search>
                    <StyledInputBase
                        placeholder="Search Users"
                        inputProps={{ 'aria-label': 'search' }}
                        value={searchParams}
                        onChange={handleInputChange}
                        onKeyDown={handleUserSearch}
                        autoFocus
                    />
                        {searchParams ? (
                            <IconButton onClick={handleClearSearch} sx={{ position: 'absolute', right: 0 }}>
                            <CancelIcon sx={{ color: "lightSecondary.main" }} />
                            </IconButton>
                        ) : (
                            <SearchIconWrapper>
                            <SearchIcon sx={{ color: "lightSecondary.main" }} />
                            </SearchIconWrapper>
                        )}
                </Search>
            <Typography variant="h6" color="textSecondary"
                sx={{
                    fontSize: "1.1rem",
                    fontWeight: "700",
                    color: "secondary.main",
                    marginBottom: "0rem"
                }}
            >
                Select Board {`>`} Solution {`>`} Chat
            </Typography>
            {userList?.data?.map((user) => {
          const isSelected = selectedUsers.some(selected => selected.userId === user._id);
          return (
          <List key={user._id} component="nav">
            <ListItemButton
              selected={isSelected}
              onClick={() => handleUserSelect(user)}
              sx={{
                py: 2.5,
                px: 1.5,
                borderRadius: "5px",
                '&:hover': {
                  backgroundColor: 'extraLTheme.main'
                }
              }}
            >
              <ListItemAvatar sx={{ width: "56px" }}>
                <Avatar
                  sx={{
                    color: 'success.main',
                    bgcolor: 'success.lighter',
                    width: '50px',
                    height: '50px'
                  }}
                  src={getImages('1.jpg')}
                />
              </ListItemAvatar>
              <ListItemText
                sx={{ pl: '10px', width: "calc(100% - 56px)" }}
                primary={
                  <Typography noWrap sx={{
                    fontSize: "1rem",
                    fontWeight: '600',
                    color: 'secondary.main',
                    lineHeight: '22px',
                    maxWidth: '220px',
                    [theme.breakpoints.down('sm')]: {
                      maxWidth: '110px'
                    }
                  }} variant="h6">
                    {user.first_name} {user.last_name}
                  </Typography>}
                secondary={<Typography
                  noWrap
                  variant="body2"
                  sx={{ fontSize: '12px', fontWeight: '500', color: 'ltheme.main' }}
                >
                  Tech support
                </Typography>}
              />
              {isSelected && <CheckCircleIcon color="success" />}
            </ListItemButton>
          </List>
        );
      })}
            <Box
                sx={{
                    mt: 3,
                    display: 'flex',
                    gap: 2
                }}
            >
                <Button variant="outlined"
                    sx={{
                        textTransform: 'inherit',
                        p: "10px 25px",
                        width: '50%',
                        borderWidth: '2px',
                        fontWeight: '600',
                        '&:hover': {
                            borderWidth: '2px',
                        }
                    }}
                    onClick={handleClose}
                >
                    Cancel
                </Button>
                <Button variant="contained"
                    sx={{
                        textTransform: 'inherit',
                        p: "12px 25px",
                        width: '50%',
                        boxShadow: 'none',
                        fontWeight: '600',
                    }}
                    onClick={handleShare}
                    disabled={isLoading}
                    >
                    Share
                </Button>
            </Box>
        </>
    )
}

export default ShareSolutionChatModal