import React, { useState } from 'react'
import {
    Box,
    Typography,
    TextField,
    Button,
    IconButton,
    Stack,
    ListItemAvatar,
    FormControl,
    Input,
    InputAdornment,
} from '@mui/material'
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import { getImages } from '../const';
import { useTheme } from '@emotion/react';
import UploadRoundedIcon from '@mui/icons-material/UploadRounded';
import { useNavigate } from 'react-router-dom';
const steps = [
    'Invite Accepted',
    'Commited',
    'Confirm Payment',
    'Invested',
];
const InterestInvestmentModal = ({ handleClose, investTypeName }) => {
    const theme = useTheme();
    const navigate = useNavigate()

    const handleNavigation = (path) => {
        navigate(path);
    };
    return (
        <>
            <Box
                sx={{
                    mb: 1
                }}
            >

                <ListItemAvatar
                    sx={{
                        minWidth: '108px'
                    }}
                >
                    <img src={getImages('image1.png')} alt='' />
                </ListItemAvatar>
                <Stack direction="row"
                    sx={{
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mb: 3
                    }}
                >
                    <Typography variant='h5'
                        sx={{
                            fontSize: "1.3rem",
                            color: "secondary.main",
                            fontWeight: "700"
                        }}>
                        TRUSTDOCK Digital ID <Box component="span" sx={{fontSize: '0.9rem', fontWeight: '600'}}>(Insurance)</Box>
                    </Typography>
                    <IconButton
                        sx={{
                            p: 0,
                            ml: 1
                        }}>
                        <OpenInNewOutlinedIcon sx={{ color: 'lightSecondary.main' }} />
                    </IconButton>
                </Stack>
            </Box>
            <Stack
                direction="row"
                sx={{
                    justifyContent: 'space-between',
                    mb: 3,
                    flexWrap: 'wrap',
                }}
            >
                <Box
                    sx={{
                        pr:2,
                        mb: 2
                    }}
                >
                    <Typography variant='h5'
                        sx={{
                            fontSize: "12px",
                            color: "ltheme.main",
                            fontWeight: "500",
                            mb: 0.5
                        }}>
                        Company Revenue
                    </Typography>
                    <Typography variant='h5'
                        sx={{
                            fontSize: "1rem",
                            color: "lightSecondary.main",
                            fontWeight: "600",
                        }}>
                        $500001 and Above USD
                    </Typography>
                </Box>
                <Box
                    sx={{
                        pr:2,
                        mb: 2
                    }}
                >
                    <Typography variant='h5'
                        sx={{
                            fontSize: "12px",
                            color: "ltheme.main",
                            fontWeight: "500",
                            mb: 0.5
                        }}>
                        Investment Stage
                    </Typography>
                    <Typography variant='h5'
                        sx={{
                            fontSize: "1rem",
                            color: "lightSecondary.main",
                            fontWeight: "600",
                        }}>
                        Pre-seed
                    </Typography>
                </Box>
                <Box>
                    <Typography variant='h6'
                        sx={{
                            fontSize: "12px",
                            color: "ltheme.main",
                            fontWeight: "500",
                            mb: 0.5
                        }}>
                        Total Funding Amount
                    </Typography>
                    <Typography variant='h5'
                        sx={{
                            fontSize: "1rem",
                            color: "lightSecondary.main",
                            fontWeight: "600",
                        }}>
                        $1000000 USD
                    </Typography>
                </Box>
            </Stack>
            <Box
                sx={{
                    mb: 2
                }}
            >
                <Typography variant='h5'
                    sx={{
                        fontSize: "12px",
                        color: "ltheme.main",
                        fontWeight: "500",
                        mb: 0.5
                    }}>
                    Amount invested($)
                </Typography>
                <TextField
                    hiddenLabel
                    id="filled-hidden-label-normal"
                    variant="filled"
                    placeholder=''
                    value="150000"
                    sx={{
                        width: '100%',
                        borderRadius: '4px',
                        backgroundColor: 'extraLTheme.main',
                        border: 'solid 1px #DBEBF8',
                        '& .MuiInputBase-root': {
                            backgroundColor: 'extraLTheme.main',
                        },
                        '& .MuiInputBase-input': {
                            fontSize: '0.9rem',
                            color: 'lightSecondary.main',
                            fontWeight: '500',
                            pt: 1.5,
                            pb: 1.5
                        },
                        '& .MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error):before': {
                            border: 'none'
                        },
                        '& .MuiInputBase-root:hover:before': {
                            border: 'none'
                        },
                        '&:hover': {
                            borderColor: '#DBEBF8'
                        },
                        '& .MuiInputBase-root:before': {
                            border: 'none'
                        },
                        '& .MuiInputBase-root:after': {
                            border: 'none'
                        }
                    }}
                />
            </Box>
            <Box
                sx={{
                    mb: 2
                }}
            >
                <Typography variant='h5'
                    sx={{
                        fontSize: "12px",
                        color: "ltheme.main",
                        fontWeight: "500",
                        mb: 0.5
                    }}>
                    Proof of transaction
                </Typography>
                <FormControl variant="filled"
                    sx={{
                        width: '100%',
                        borderRadius: '4px',
                        backgroundColor: 'extraLTheme.main',
                        border: 'solid 1px #DBEBF8',
                        '& .MuiInputBase-root': {
                            backgroundColor: 'extraLTheme.main',
                        },
                        '& .MuiInputBase-input': {
                            fontSize: '0.9rem',
                            color: 'lightSecondary.main',
                            fontWeight: '500',
                            pt: 1.5,
                            pb: 1.5
                        },
                        '& .MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error):before': {
                            border: 'none'
                        },
                        '& .MuiInputBase-root:hover:before': {
                            border: 'none'
                        },
                        '&:hover': {
                            borderColor: '#DBEBF8'
                        },
                        '& .MuiInputBase-root:before': {
                            border: 'none'
                        },
                        '& .MuiInputBase-root:after': {
                            border: 'none'
                        }
                    }}
                >
                    <Input
                        sx={{
                            width: '100%',
                            borderRadius: '4px',
                            backgroundColor: 'extraLTheme.main',
                            border: 'solid 1px #DBEBF8',
                            '& .MuiInputBase-input': {
                                fontSize: '0.9rem',
                                color: 'lightSecondary.main',
                                fontWeight: '500',
                                p: 1.5
                            },
                        }}
                        value='Transaction.pdf'
                        type='text'
                        placeholder=''
                        endAdornment={
                            <InputAdornment position="end"
                                sx={{
                                    pr: 1
                                }}
                            >
                                <IconButton
                                    sx={{
                                        p: 0.5
                                    }}
                                >
                                    <UploadRoundedIcon
                                        sx={{ color: 'lightSecondary.main' }}
                                    />
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>
            </Box>
            <Box
                sx={{
                    textAlign: 'center',
                    mb: 1,
                    mt: 4
                }}
            >
                <Button variant="contained"
                    sx={{
                        textTransform: 'inherit',
                        p: "10px 25px",
                        width: '50%',
                        borderWidth: '2px',
                        fontWeight: '600',
                        borderColor: 'primary.main',
                        boxShadow: 'none',
                        '&:hover': {
                            borderWidth: '2px',
                        }
                    }}
                    onClick={()=>{handleClose() , handleNavigation("/inbox") }}
                >
                    Go To Inbox
                </Button>
            </Box>
        </>
    )
}

export default InterestInvestmentModal