import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = process.env.REACT_APP_base_URL;

const deleteDriveFolder = createApi({
    reducerPath: 'deleteDriveFolder',
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers) => {
            // Add any headers you need here
            return headers;
        },
    }),
    endpoints: (builder) => ({
        deleteDriveFolder : builder.mutation({
            query: ({id , userId}) => ({
                url: `drive/folder/${id}?owner_id=${userId}`,
                method: 'DELETE',
            }),
            transformResponse: (response) =>{ 
                return response;
            }
        }),
    }),
});

export const { useDeleteDriveFolderMutation } = deleteDriveFolder;
export default deleteDriveFolder;

