import React from 'react'
import {
    Avatar,
    Box,
    IconButton,
    List,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    Typography,
    TextField,
    styled,
    InputBase,
    Button,
    Stack
} from '@mui/material'
import { useTheme } from '@emotion/react';
import SearchIcon from '@mui/icons-material/Search';
import { getImages } from '../const';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import { Link } from 'react-router-dom';

const InitiateNewDirectMessage = ({ handleClose}) => {
    const theme = useTheme();
    const Search = styled('div')(({ theme }) => ({
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: '#ECF6FF',
        '&:hover': {
            backgroundColor: '#ECF6FF',
        },
        marginRight: '0',
        marginLeft: 0,
        marginBottom: 5,
        width: '100%'
    }));
    const SearchIconWrapper = styled('div')(({ theme }) => ({
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        right: '0',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }));

    const StyledInputBase = styled(InputBase)(({ theme }) => ({
        color: '#106EED',
        width: '100%',
        '& .MuiInputBase-input': {
            padding: theme.spacing(1.7, 0, 1.7, 1.7),
            paddingRight: `calc(1em + ${theme.spacing(4)})`,
            transition: theme.transitions.create('width'),
            width: '100%',
            fontSize: '12px',
            fontWeight: '500'
        },
    }));
    return (
        <>


            <Box
                sx={{
                    mb: 3
                }}
            >
                <Typography variant='h5'
                    sx={{
                        fontSize: "1.1rem",
                        color: "secondary.main",
                        fontWeight: "700"
                    }}>
                    Initiate New Direct Message
                </Typography>
            </Box>
            <Search
                sx={{
                    backgroundColor: 'extraLTheme.main',
                    mb: 2
                }}
            >
                <SearchIconWrapper>
                    <SearchIcon sx={{ color: "lightSecondary.main" }} />
                </SearchIconWrapper>
                <StyledInputBase
                    placeholder="Search solution"
                    inputProps={{ 'aria-label': 'search' }}
                />
            </Search>
            <Typography
                variant="body2"
                sx={{
                    fontSize: '0.9rem',
                    fontWeight: '500',
                    color: 'ltheme.main',
                    mb: 0.5,

                }}
            >Select solution</Typography>

            <List
                component="nav"
                sx={{
                    px: 0,
                    py: 0,
                    mb: 3,
                    '& .MuiListItemButton-root': {
                        py: 0.5,
                    },
                    overflowY: 'auto',
                    maxHeight: '280px',
                    '&::-webkit-scrollbar': {
                        width: '6px',
                        borderRadius: '4px'
                    },
                    '&::-webkit-scrollbar-track': {
                        backgroundColor: 'transparent',
                        borderRadius: '4px'
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: '#9C9C9C',
                        borderRadius: '4px'
                    }
                }}
            >
                <ListItemButton
                    sx={{
                        minHeight: "50px",
                        justifyContent: "space-between",
                        alignItems: "center",
                        background: "#FFFFFF",
                        borderRadius: "5px",
                        marginTop: "5px",
                        '&:hover': {
                            backgroundColor: 'extraLTheme.main'
                        }
                    }}
                >
                    <Stack
                        sx={{
                            flexDirection: "inherit",
                            alignItems: "center",
                            width: "100%"
                        }}
                    >
                        <ListItemAvatar
                            sx={{
                                display: "inline-block",
                                marginRight: "7px"
                            }}
                        >
                            <img
                                style={{ maxWidth: "54px", display: "block" }} src={getImages('image_172.png')} />
                        </ListItemAvatar>
                        <Typography variant="subtitle1"
                            noWrap
                            sx={{
                                fontSize: "1.1rem",
                                color: "lightSecondary.main",
                                fontWeight: "600",
                                lineHeight: "20px",
                                maxWidth: '260px'
                            }}
                        >TRUSTDOCK Digital ID</Typography>
                    </Stack>
                </ListItemButton>
                <ListItemButton
                    sx={{
                        minHeight: "50px",
                        justifyContent: "space-between",
                        alignItems: "center",
                        background: "#FFFFFF",
                        borderRadius: "5px",
                        marginTop: "5px",
                        '&:hover': {
                            backgroundColor: 'extraLTheme.main'
                        }
                    }}
                >
                    <Stack
                        sx={{
                            flexDirection: "inherit",
                            alignItems: "center",
                            width: "100%"
                        }}
                    >
                        <ListItemAvatar
                            sx={{
                                display: "inline-block",
                                marginRight: "7px"
                            }}
                        >
                            <img
                                style={{ maxWidth: "54px", display: "block" }} src={getImages('image_154.png')} />
                        </ListItemAvatar>
                        <Typography variant="subtitle1"
                            noWrap
                            sx={{
                                fontSize: "1.1rem",
                                color: "lightSecondary.main",
                                fontWeight: "600",
                                lineHeight: "20px",
                                maxWidth: '260px'
                            }}
                        >KYC as a Service</Typography>
                    </Stack>
                </ListItemButton>
                <ListItemButton
                    sx={{
                        minHeight: "50px",
                        justifyContent: "space-between",
                        alignItems: "center",
                        background: "#FFFFFF",
                        borderRadius: "5px",
                        marginTop: "5px",
                        '&:hover': {
                            backgroundColor: 'extraLTheme.main'
                        }
                    }}
                >
                    <Stack
                        sx={{
                            flexDirection: "inherit",
                            alignItems: "center",
                            width: "100%"
                        }}
                    >
                        <ListItemAvatar
                            sx={{
                                display: "inline-block",
                                marginRight: "7px"
                            }}
                        >
                            <img
                                style={{ maxWidth: "54px", display: "block" }} src={getImages('image_155.png')} />
                        </ListItemAvatar>
                        <Typography variant="subtitle1"
                            noWrap
                            sx={{
                                fontSize: "1.1rem",
                                color: "lightSecondary.main",
                                fontWeight: "600",
                                lineHeight: "20px",
                                maxWidth: '260px'
                            }}
                        >Blockpass Identity for a Connected World</Typography>
                    </Stack>
                </ListItemButton>
                <ListItemButton
                    sx={{
                        minHeight: "50px",
                        justifyContent: "space-between",
                        alignItems: "center",
                        background: "#FFFFFF",
                        borderRadius: "5px",
                        marginTop: "5px",
                        '&:hover': {
                            backgroundColor: 'extraLTheme.main'
                        }
                    }}
                >
                    <Stack
                        sx={{
                            flexDirection: "inherit",
                            alignItems: "center",
                            width: "100%"
                        }}
                    >
                        <ListItemAvatar
                            sx={{
                                display: "inline-block",
                                marginRight: "7px"
                            }}
                        >
                            <img
                                style={{ maxWidth: "54px", display: "block" }} src={getImages('image_53.png')} />
                        </ListItemAvatar>
                        <Typography variant="subtitle1"
                            noWrap
                            sx={{
                                fontSize: "1.1rem",
                                color: "lightSecondary.main",
                                fontWeight: "600",
                                lineHeight: "20px",
                                maxWidth: '260px'
                            }}
                        >Advanced Solutions in Digital Healthcare</Typography>
                    </Stack>
                </ListItemButton>
                <ListItemButton
                    sx={{
                        minHeight: "50px",
                        justifyContent: "space-between",
                        alignItems: "center",
                        background: "#FFFFFF",
                        borderRadius: "5px",
                        marginTop: "5px",
                        '&:hover': {
                            backgroundColor: 'extraLTheme.main'
                        }
                    }}
                >
                    <Stack
                        sx={{
                            flexDirection: "inherit",
                            alignItems: "center",
                            width: "100%"
                        }}
                    >
                        <ListItemAvatar
                            sx={{
                                display: "inline-block",
                                marginRight: "7px"
                            }}
                        >
                            <img
                                style={{ maxWidth: "54px", display: "block" }} src={getImages('image_153.png')} />
                        </ListItemAvatar>
                        <Typography variant="subtitle1"
                            noWrap
                            sx={{
                                fontSize: "1.1rem",
                                color: "lightSecondary.main",
                                fontWeight: "600",
                                lineHeight: "20px",
                                maxWidth: '260px'
                            }}
                        >Claim Settlement Platform</Typography>
                    </Stack>
                </ListItemButton>
                <ListItemButton
                    sx={{
                        minHeight: "50px",
                        justifyContent: "space-between",
                        alignItems: "center",
                        background: "#FFFFFF",
                        borderRadius: "5px",
                        marginTop: "5px",
                        '&:hover': {
                            backgroundColor: 'extraLTheme.main'
                        }
                    }}
                >
                    <Stack
                        sx={{
                            flexDirection: "inherit",
                            alignItems: "center",
                            width: "100%"
                        }}
                    >
                        <ListItemAvatar
                            sx={{
                                display: "inline-block",
                                marginRight: "7px"
                            }}
                        >
                            <img
                                style={{ maxWidth: "54px", display: "block" }} src={getImages('image_172.png')} />
                        </ListItemAvatar>
                        <Typography variant="subtitle1"
                            noWrap
                            sx={{
                                fontSize: "1.1rem",
                                color: "lightSecondary.main",
                                fontWeight: "600",
                                lineHeight: "20px",
                                maxWidth: '260px'
                            }}
                        >TRUSTDOCK Digital ID</Typography>
                    </Stack>
                </ListItemButton>
                <ListItemButton
                    sx={{
                        minHeight: "50px",
                        justifyContent: "space-between",
                        alignItems: "center",
                        background: "#FFFFFF",
                        borderRadius: "5px",
                        marginTop: "5px",
                        '&:hover': {
                            backgroundColor: 'extraLTheme.main'
                        }
                    }}
                >
                    <Stack
                        sx={{
                            flexDirection: "inherit",
                            alignItems: "center",
                            width: "100%"
                        }}
                    >
                        <ListItemAvatar
                            sx={{
                                display: "inline-block",
                                marginRight: "7px"
                            }}
                        >
                            <img
                                style={{ maxWidth: "54px", display: "block" }} src={getImages('image_154.png')} />
                        </ListItemAvatar>
                        <Typography variant="subtitle1"
                            noWrap
                            sx={{
                                fontSize: "1.1rem",
                                color: "lightSecondary.main",
                                fontWeight: "600",
                                lineHeight: "20px",
                                maxWidth: '260px'
                            }}
                        >KYC as a Service</Typography>
                    </Stack>
                </ListItemButton>
                <ListItemButton
                    sx={{
                        minHeight: "50px",
                        justifyContent: "space-between",
                        alignItems: "center",
                        background: "#FFFFFF",
                        borderRadius: "5px",
                        marginTop: "5px",
                        '&:hover': {
                            backgroundColor: 'extraLTheme.main'
                        }
                    }}
                >
                    <Stack
                        sx={{
                            flexDirection: "inherit",
                            alignItems: "center",
                            width: "100%"
                        }}
                    >
                        <ListItemAvatar
                            sx={{
                                display: "inline-block",
                                marginRight: "7px"
                            }}
                        >
                            <img
                                style={{ maxWidth: "54px", display: "block" }} src={getImages('image_155.png')} />
                        </ListItemAvatar>
                        <Typography variant="subtitle1"
                            noWrap
                            sx={{
                                fontSize: "1.1rem",
                                color: "lightSecondary.main",
                                fontWeight: "600",
                                lineHeight: "20px",
                                maxWidth: '260px'
                            }}
                        >Blockpass Identity for a Connected World</Typography>
                    </Stack>
                </ListItemButton>
                <ListItemButton
                    sx={{
                        minHeight: "50px",
                        justifyContent: "space-between",
                        alignItems: "center",
                        background: "#FFFFFF",
                        borderRadius: "5px",
                        marginTop: "5px",
                        '&:hover': {
                            backgroundColor: 'extraLTheme.main'
                        }
                    }}
                >
                    <Stack
                        sx={{
                            flexDirection: "inherit",
                            alignItems: "center",
                            width: "100%"
                        }}
                    >
                        <ListItemAvatar
                            sx={{
                                display: "inline-block",
                                marginRight: "7px"
                            }}
                        >
                            <img
                                style={{ maxWidth: "54px", display: "block" }} src={getImages('image_53.png')} />
                        </ListItemAvatar>
                        <Typography variant="subtitle1"
                            noWrap
                            sx={{
                                fontSize: "1.1rem",
                                color: "lightSecondary.main",
                                fontWeight: "600",
                                lineHeight: "20px",
                                maxWidth: '260px'
                            }}
                        >Advanced Solutions in Digital Healthcare</Typography>
                    </Stack>
                </ListItemButton>
                <ListItemButton
                    sx={{
                        minHeight: "50px",
                        justifyContent: "space-between",
                        alignItems: "center",
                        background: "#FFFFFF",
                        borderRadius: "5px",
                        marginTop: "5px",
                        '&:hover': {
                            backgroundColor: 'extraLTheme.main'
                        }
                    }}
                >
                    <Stack
                        sx={{
                            flexDirection: "inherit",
                            alignItems: "center",
                            width: "100%"
                        }}
                    >
                        <ListItemAvatar
                            sx={{
                                display: "inline-block",
                                marginRight: "7px"
                            }}
                        >
                            <img
                                style={{ maxWidth: "54px", display: "block" }} src={getImages('image_153.png')} />
                        </ListItemAvatar>
                        <Typography variant="subtitle1"
                            noWrap
                            sx={{
                                fontSize: "1.1rem",
                                color: "lightSecondary.main",
                                fontWeight: "600",
                                lineHeight: "20px",
                                maxWidth: '260px'
                            }}
                        >Claim Settlement Platform</Typography>
                    </Stack>
                </ListItemButton>
            </List>
            <Box
                sx={{
                    mt: 3,
                    display: 'flex',
                    gap: 2
                }}
            >
                <Button variant="outlined"
                    sx={{
                        textTransform: 'inherit',
                        p: "10px 25px",
                        width: '50%',
                        borderWidth: '2px',
                        fontWeight: '600',
                        '&:hover': {
                            borderWidth: '2px',
                        }
                    }}
                    onClick={handleClose}
                >
                    Cancel
                </Button>
                <Link to="/inbox"
                    style={{
                        width: '50%',
                        display: 'inline-block'
                    }}
                >
                    <Button variant="contained"
                        sx={{
                            textTransform: 'inherit',
                            p: "12px 25px",
                            width: '100%',
                            boxShadow: 'none',
                            fontWeight: '600',
                        }}>
                        Initiate DM
                    </Button>
                </Link>
            </Box>
        </>
    )
}

export default InitiateNewDirectMessage