import React from 'react'
import { IconButton, Stack, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useTheme } from '@emotion/react';

const StepHeader = ({ currentStep, prevStep }) => {
    const theme = useTheme();
    return (
        <>
            <Stack
                direction='row'
                sx={{
                    py: 1.5,
                    px: 2,
                    borderBottom: 'solid 1px #DBEBF8',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    [theme.breakpoints.down('md')]: {
                        flexWrap: 'wrap'
                    },
                }}
            >
                <Typography variant='body2'
                    sx={{
                        color: 'lightSecondary.main',
                        fontSize: '1.1rem',
                        fontWeight: '600',
                        order: '1',
                        [theme.breakpoints.down('md')]: {
                            order: '1',
                        },
                    }}
                >
                    <IconButton onClick={prevStep}
                        sx={{
                            mr: 0.5,
                            verticalAlign: '-6px',
                        }}
                    >
                        <KeyboardBackspaceIcon
                            sx={{
                                color: 'lightSecondary.main',
                            }}
                        />
                    </IconButton>
                    {currentStep} of 13
                </Typography>
                <IconButton
                    sx={{
                        order: '3',
                        [theme.breakpoints.down('md')]: {
                            order: '2',
                        },
                    }}
                >
                    <CloseIcon
                        sx={{
                            color: 'lightSecondary.main',
                            fontSize: '30px',
                            [theme.breakpoints.down('md')]: {
                                fontSize: '24px'
                            },
                        }}
                    />
                </IconButton>
                <Typography variant='body2'
                    sx={{
                        color: 'primary.main',
                        fontSize: '1rem',
                        fontWeight: '600',
                        order: '2',
                        [theme.breakpoints.down('md')]: {
                            order: '3',
                            width: '100%',
                            textAlign: 'center',
                            pt: 1
                        },
                    }}
                >
                    AI-Powered Requirement Creation Wizard for Businesses
                </Typography>
            </Stack>
        </>
    )
}

export default StepHeader