// components which we used  as title in header

import InboxRequirementBoardDropdown from "./RequirementBoardDropdown";

// set title according to pathname
const TitleMap = {
    '/dashboard': 'Dashboard',
    // '/bsi-co-lab': <Dropdown/>,
    '/bsi-co-lab': 'BSI Co-lab',
    '/bsi-co-lab-requirements':'BSI Co-lab',
    '/bsi-co-lab/kanban': 'BSI Co-lab',
    '/invite-solution-provider': 'BSI Co-lab',
    '/manage-users': 'Manage Users',
    '/edit-team-members': 'Manage Team Members',
    '/inbox': <InboxRequirementBoardDropdown/>,
    '/bsi-drive': 'BSI Drive',
    '/bsi-sub-drive': 'BSI Drive',
    '/meetings': 'Meetings',
    '/profile': 'Profile',
    '/profile-business': 'Profile',
    '/my-solutions': 'My Solutions',
    '/edit-solution-details': 'My Solutions',
    '/solution-investment-summary': 'My Solutions',
    '/solution-enquiries': 'BSI Co-lab ',
    '/kanban-solution-enquiries':'BSI Co-lab',
    '/investment-enquiries': 'BSI Co-lab ',
    '/kanban-investment-enquiries':'BSI Co-lab ',
    '/portfolio': 'Portfolio',

 };

 export default TitleMap;