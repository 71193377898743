import React from 'react'
import {
    Box,
    Grid,
    ListItemText,
    Typography,
    styled,
    InputBase,
    ListItemButton,
    ListItemAvatar,
    Avatar,
    Badge,
    List,
    Stack,
    Button,
    IconButton,
    TextField
} from '@mui/material';
import { useTheme } from '@emotion/react';
import { UserAddOutlined } from '@ant-design/icons';
import AddCommentOutlinedIcon from '@mui/icons-material/AddCommentOutlined';
import SearchIcon from '@mui/icons-material/Search';
import { getImages } from '../const';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useDispatch, useSelector } from 'react-redux';

import { 
    setChatSelected,
    setOpenGroupChat,
    toggleOpenInviteToBSIColab,
    toggleOpenNewChat,
    setOpenChat
 } from '../../redux/features/chatSlice';
import { toggleDrawer } from '../../redux/features/drawerSlice';

const SolutionChatsList = () => {
    
    const theme = useTheme();
    const dispatch = useDispatch();
    const {open} = useSelector((state)=> state.drawer)
    const Search = styled('div')(({ theme }) => ({
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: '#DBEBF8',
        '&:hover': {
            backgroundColor: '#DBEBF8',
        },
        marginRight: '0',
        marginLeft: 0,
        marginBottom: 5,
        width: '100%',
        [theme.breakpoints.down('lg')]: {
            backgroundColor: '#ECF6FF',
        }
    }));
    const SearchIconWrapper = styled('div')(({ theme }) => ({
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        right: '0',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }));

    const StyledInputBase = styled(InputBase)(({ theme }) => ({
        color: '#106EED',
        width: '100%',
        '& .MuiInputBase-input': {
            padding: theme.spacing(1.7, 0, 1.7, 1.7),
            paddingRight: `calc(1em + ${theme.spacing(4)})`,
            transition: theme.transitions.create('width'),
            width: '100%',
            fontSize: '12px',
            fontWeight: '500'
        },
    }));
    const SmallAvatar = styled(Avatar)(({ theme }) => ({
        width: 24,
        height: 24,
        border: `2px solid ${theme.palette.background.paper}`,
    }));
    const handleChatSelected = () => {
        dispatch(setChatSelected(true));
        dispatch(setOpenGroupChat(false));
        dispatch(setOpenChat(true));
    };

    const handleGroupChat = () => {
        dispatch(setOpenGroupChat(true));
        dispatch(setChatSelected(false));
        dispatch(setOpenChat(true));
    };

    const handleInviteToBSIColab = () => {
        dispatch(toggleOpenInviteToBSIColab());
    };

    const handleNewChat = () => {
        dispatch(toggleOpenNewChat());
    };

    const handleCloseSolutionChatList = () => {
        dispatch(setOpenChat(false));
    };




    return (
        <>
            <Box
                sx={{
                    pl: 3,
                    pt: 1,
                    pr: 1
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        pr: 3,
                        [theme.breakpoints.down('md')]: {
                            flexWrap: 'wrap'
                        }
                    }}
                >
                    <Typography variant="h6"
                        color="secondary.main"
                        sx={{
                            fontSize: "1.2rem",
                            fontWeight: "600",
                            '@media (max-width:1600px) and (min-width: 1300px)': {
                                fontSize: '0.9rem'
                            },
                            [theme.breakpoints.down('xl')]: {
                                fontSize: '1rem'
                            }
                        }}
                    >
                        <KeyboardBackspaceIcon
                            onClick={handleCloseSolutionChatList}
                            sx={{
                                verticalAlign: '-6px',
                                mr: 0.5,
                                [theme.breakpoints.up('lg')]: {
                                    display: 'none',
                                }
                            }}
                        />
                        Solution Chats
                    </Typography>
                    <Button variant="none"
                        sx={{
                            textTransform: 'inherit',
                            p: "10px 0 10px 10px",
                            color: 'primary.main',
                            fontWeight: '600',
                            '&:hover' : {
                                background: 'transparent'
                            },
                            '@media (max-width:1600px) and (min-width: 1300px)': {
                                fontSize: '0.9rem'
                            },
                            [theme.breakpoints.down('xl')]: {
                                fontSize: '0.8rem'
                            }
                        }}
                        onClick={handleInviteToBSIColab}
                    >
                        <UserAddOutlined
                            style={{
                                width: "20px",
                                height: "20px",
                                marginRight: "1px",
                                position: 'relative',
                                top: '4px'
                            }} />
                        Invite to BSI Co-lab
                    </Button>
                </Box>
                <Box
                    sx={{
                        mb: 1.5,
                        pr: 2,
                        display: "flex"
                    }}
                >
                    <Search
                        sx={{
                            backgroundColor: '#DBEBF8',
                            mb: 0
                        }}
                    >
                        <SearchIconWrapper>
                            <SearchIcon sx={{ color: "lightSecondary.main" }} />
                        </SearchIconWrapper>
                        <StyledInputBase
                            placeholder="Search coversations"
                            inputProps={{ 'aria-label': 'search' }}
                        />
                    </Search>
                    <Button variant="contained"
                        sx={{
                            textTransform: 'inherit',
                            p: "10px 11px",
                            backgroundColor: '#DBEBF8',
                            minWidth: 'inherit',
                            boxShadow: 'none',
                            ml: 1,
                            '&:hover .MuiSvgIcon-root': {
                                color: '#fff'
                            }
                        }}
                        onClick={handleNewChat}
                    >
                        <AddCommentOutlinedIcon
                            sx={{
                                color: "secondary.main"
                            }}
                        />
                    </Button>
                </Box>
                <List
                    component="nav"
                    sx={{
                        px: 0,
                        py: 0,
                        pr: 1.2,
                        '& .MuiListItemButton-root': {
                            py: 1.5,
                        },
                        overflowY: 'auto',
                        maxHeight: 'calc(100vh - 214px)',
                        '&::-webkit-scrollbar': {
                            width: '6px',
                            borderRadius: '4px'
                        },
                        '&::-webkit-scrollbar-track': {
                            backgroundColor: 'transparent',
                            borderRadius: '4px'
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: '#9C9C9C',
                            borderRadius: '4px'
                        },
                        [theme.breakpoints.down('lg')]: { 
                            maxHeight: 'calc(100vh - 170px)',
                        }
                    }}
                >
                    <ListItemButton onClick={handleChatSelected} 
                    sx={{ 
                        px: "0",
                        '&:hover':{
                            background: 'transparent'
                        } }}>
                        <ListItemAvatar
                            sx={{
                                width: "56px"
                            }}
                        >
                            <Badge
                                color="secondary"
                                overlap="circular"
                                badgeContent=" "
                                variant="dot"
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                sx={{
                                    '& .MuiBadge-badge': {
                                        backgroundColor: 'green.main',
                                        minWidth: '14px',
                                        height: '14px',
                                        borderRadius: '50%',
                                        border: 'solid 2px #ECF6FF'
                                    }
                                }}
                            >
                                <Avatar
                                    sx={{
                                        color: 'success.main',
                                        bgcolor: 'success.lighter',
                                        width: '50px',
                                        height: '50px'
                                    }}
                                    src={getImages('avatar-1.png')}
                                >

                                </Avatar>
                            </Badge>
                        </ListItemAvatar>
                        <ListItemText
                            sx={{
                                pl: '10px',
                                width: "60%"
                            }}
                            primary={
                                <Typography noWrap
                                    sx={{
                                        fontSize: "1rem",
                                        fontWeight: '600',
                                        color: 'secondary.main',
                                        lineHeight: '22px',
                                        maxWidth: '220px',
                                        [theme.breakpoints.down('sm')]: {
                                            maxWidth: '110px'
                                        }
                                    }}
                                    variant="h6">John Doe
                                </Typography>}
                            secondary={<Typography
                                noWrap
                                variant="body2"
                                sx={{
                                    fontSize: '12px',
                                    fontWeight: '500',
                                    color: 'primary.main',
                                    maxWidth: '220px',
                                    [theme.breakpoints.down('sm')]: {
                                        maxWidth: '110px'
                                    }
                                }}
                            >What’s the best solution for my problem? Can you send me a listed document please.</Typography>}
                        />
                        <ListItemText
                            sx={{
                                width: "calc(40% - 56px)"
                            }}
                        >
                            <Stack alignItems="flex-end">
                                <Typography
                                    variant="body2" noWrap
                                    sx={{
                                        color: "ltheme.main",
                                        fontSize: '12px',
                                        fontWeight: '500',
                                        mb: '5px'
                                    }}
                                >
                                    11:54 AM
                                </Typography>
                                <Box component="span"
                                    sx={{
                                        bgcolor: 'primary.main',
                                        width: 24, height: 24,
                                        borderRadius: '50%',
                                        fontSize: '12px',
                                        color: '#fff',
                                        textAlign: 'center',
                                        lineHeight: '24px'
                                    }}>10</Box>
                            </Stack>
                        </ListItemText>
                    </ListItemButton>
                    <ListItemButton onClick={handleChatSelected} 
                    sx={{ 
                        px: "0",
                        '&:hover':{
                            background: 'transparent'
                        } }}>
                        <ListItemAvatar
                            sx={{
                                width: "56px"
                            }}
                        >
                            <Badge
                                color="secondary"
                                overlap="circular"
                                badgeContent=" "
                                variant="dot"
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                sx={{
                                    '& .MuiBadge-badge': {
                                        backgroundColor: 'green.main',
                                        minWidth: '14px',
                                        height: '14px',
                                        borderRadius: '50%',
                                        border: 'solid 2px #ECF6FF'
                                    }
                                }}
                            >
                                <Avatar
                                    sx={{
                                        color: 'success.main',
                                        bgcolor: 'success.lighter',
                                        width: '50px',
                                        height: '50px'
                                    }}
                                    src={getImages('2.jpg')}
                                >
                                </Avatar>
                            </Badge>
                        </ListItemAvatar>
                        <ListItemText
                            sx={{
                                pl: '10px',
                                width: "60%"
                            }}
                            primary={
                                <Typography noWrap
                                    sx={{
                                        fontSize: "1rem",
                                        fontWeight: '600',
                                        color: 'secondary.main',
                                        lineHeight: '22px',
                                        maxWidth: '220px',
                                        [theme.breakpoints.down('sm')]: {
                                            maxWidth: '110px'
                                        }
                                    }}
                                    variant="h6">Messiah Rozo
                                </Typography>}
                            secondary={<Typography
                                noWrap
                                variant="body2"
                                sx={{
                                    fontSize: '12px',
                                    fontWeight: '500',
                                    color: 'ltheme.main',
                                    maxWidth: '220px',
                                    [theme.breakpoints.down('sm')]: {
                                        maxWidth: '110px'
                                    }
                                }}
                            >Hello David</Typography>}
                        />
                        <ListItemText
                            sx={{
                                width: "calc(40% - 56px)"
                            }}
                        >
                            <Stack alignItems="flex-end">
                                <Typography
                                    variant="body2" noWrap
                                    sx={{
                                        color: "ltheme.main",
                                        fontSize: '12px',
                                        fontWeight: '500',
                                        mb: '5px'
                                    }}
                                >
                                    Just now
                                </Typography>
                                <Box component="span"
                                >&nbsp;</Box>
                            </Stack>
                        </ListItemText>
                    </ListItemButton>
                    <ListItemButton onClick={handleGroupChat} 
                    sx={{ 
                        px: "0",
                        '&:hover':{
                            background: 'transparent'
                        } }}>
                        <ListItemAvatar
                            sx={{
                                width: "56px"
                            }}
                        >
                            <Badge
                                overlap="circular">

                                <Badge
                                    overlap="circular"
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                                    badgeContent={
                                        <SmallAvatar
                                            sx={{
                                                width: "30px",
                                                height: "30px",
                                                ml: 1.5
                                            }}
                                            alt="Remy Sharp" src={getImages('2.jpg')} />
                                    }
                                />
                                <Avatar alt="Travis Howard"
                                    sx={{
                                        color: 'success.main',
                                        bgcolor: 'success.lighter',
                                        width: '50px',
                                        height: '50px'
                                    }}
                                    src={getImages('1.jpg')}
                                />
                                <Badge
                                    overlap="circular"
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    badgeContent={
                                        <SmallAvatar
                                            sx={{
                                                mr: 1.5
                                            }}
                                            alt="Remy Sharp" src={getImages('2.jpg')} />
                                    }
                                />
                            </Badge>
                        </ListItemAvatar>
                        <ListItemText
                            sx={{
                                pl: '10px',
                                width: "60%"
                            }}
                            primary={
                                <Typography noWrap
                                    sx={{
                                        fontSize: "1rem",
                                        fontWeight: '600',
                                        color: 'secondary.main',
                                        lineHeight: '22px',
                                        maxWidth: '220px',
                                        [theme.breakpoints.down('sm')]: {
                                            maxWidth: '110px'
                                        }
                                    }}
                                    variant="h6">Solution discussion
                                </Typography>}
                            secondary={<Typography
                                noWrap
                                variant="body2"
                                sx={{
                                    fontSize: '12px',
                                    fontWeight: '500',
                                    color: 'primary.main',
                                    maxWidth: '220px',
                                    [theme.breakpoints.down('sm')]: {
                                        maxWidth: '110px'
                                    }
                                }}
                            >Vish: Yes I’m available.</Typography>}
                        />
                        <ListItemText
                            sx={{
                                width: "calc(40% - 56px)"
                            }}
                        >
                            <Stack alignItems="flex-end">
                                <Typography
                                    variant="body2" noWrap
                                    sx={{
                                        color: "ltheme.main",
                                        fontSize: '12px',
                                        fontWeight: '500',
                                        mb: '5px'
                                    }}
                                >
                                    09:41 AM
                                </Typography>
                                <Box component="span"
                                    sx={{
                                        bgcolor: 'primary.main',
                                        width: 24, height: 24,
                                        borderRadius: '50%',
                                        fontSize: '12px',
                                        color: '#fff',
                                        textAlign: 'center',
                                        lineHeight: '24px'
                                    }}>10</Box>
                            </Stack>
                        </ListItemText>
                    </ListItemButton>
                    <ListItemButton onClick={handleChatSelected} 
                    sx={{ 
                        px: "0",
                        '&:hover':{
                            background: 'transparent'
                        } }}>
                        <ListItemAvatar
                            sx={{
                                width: "56px"
                            }}
                        >
                            <Badge
                                color="secondary"
                                overlap="circular"
                                badgeContent=" "
                                variant="dot"
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                sx={{
                                    '& .MuiBadge-badge': {
                                        backgroundColor: 'green.main',
                                        minWidth: '14px',
                                        height: '14px',
                                        borderRadius: '50%',
                                        border: 'solid 2px #ECF6FF'
                                    }
                                }}
                            >
                                <Avatar
                                    sx={{
                                        color: 'success.main',
                                        bgcolor: 'success.lighter',
                                        width: '50px',
                                        height: '50px'
                                    }}
                                    src={getImages('avatar-1.png')}
                                >

                                </Avatar>
                            </Badge>
                        </ListItemAvatar>
                        <ListItemText
                            sx={{
                                pl: '10px',
                                width: "60%"
                            }}
                            primary={
                                <Typography noWrap
                                    sx={{
                                        fontSize: "1rem",
                                        fontWeight: '600',
                                        color: 'secondary.main',
                                        lineHeight: '22px',
                                        maxWidth: '220px',
                                        [theme.breakpoints.down('sm')]: {
                                            maxWidth: '110px'
                                        }
                                    }}
                                    variant="h6">Emily Blunt
                                </Typography>}
                            secondary={<Typography
                                noWrap
                                variant="body2"
                                sx={{
                                    fontSize: '12px',
                                    fontWeight: '500',
                                    color: 'ltheme.main',
                                    maxWidth: '220px',
                                    [theme.breakpoints.down('sm')]: {
                                        maxWidth: '110px'
                                    }
                                }}
                            >I’ll confirm this with my team and get back to you asap!</Typography>}
                        />
                        <ListItemText
                            sx={{
                                width: "calc(40% - 56px)"
                            }}
                        >
                            <Stack alignItems="flex-end">
                                <Typography
                                    variant="body2" noWrap
                                    sx={{
                                        color: "ltheme.main",
                                        fontSize: '12px',
                                        fontWeight: '500',
                                        mb: '5px'
                                    }}
                                >
                                    24 Jan
                                </Typography>
                                <Box component="span"
                                >&nbsp;</Box>
                            </Stack>
                        </ListItemText>
                    </ListItemButton>
                    <ListItemButton onClick={handleChatSelected} 
                    sx={{ 
                        px: "0",
                        '&:hover':{
                            background: 'transparent'
                        } }}>
                        <ListItemAvatar
                            sx={{
                                width: "56px"
                            }}
                        >
                            <Badge
                                overlap="circular"
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                badgeContent={
                                    <SmallAvatar
                                        sx={{
                                            width: "30px",
                                            height: "30px"
                                        }}
                                        alt="Remy Sharp" src={getImages('2.jpg')} />
                                }
                            >
                                <Avatar alt="Travis Howard"
                                    sx={{
                                        color: 'success.main',
                                        bgcolor: 'success.lighter',
                                        width: '50px',
                                        height: '50px'
                                    }}
                                    src={getImages('4.jpg')}
                                />
                            </Badge>
                        </ListItemAvatar>
                        <ListItemText
                            sx={{
                                pl: '10px',
                                width: "60%"
                            }}
                            primary={
                                <Typography noWrap
                                    sx={{
                                        fontSize: "1rem",
                                        fontWeight: '600',
                                        color: 'secondary.main',
                                        lineHeight: '22px',
                                        maxWidth: '220px',
                                        [theme.breakpoints.down('sm')]: {
                                            maxWidth: '110px'
                                        }
                                    }}
                                    variant="h6">Tyson, Lauren
                                </Typography>}
                            secondary={<Typography
                                noWrap
                                variant="body2"
                                sx={{
                                    fontSize: '12px',
                                    fontWeight: '500',
                                    color: 'ltheme.main',
                                    maxWidth: '220px',
                                    [theme.breakpoints.down('sm')]: {
                                        maxWidth: '110px'
                                    }
                                }}
                            >Lauren : When can we schedule a call?</Typography>}
                        />
                        <ListItemText
                            sx={{
                                width: "calc(40% - 56px)"
                            }}
                        >
                            <Stack alignItems="flex-end">
                                <Stack alignItems="flex-end">
                                    <Typography
                                        variant="body2" noWrap
                                        sx={{
                                            color: "ltheme.main",
                                            fontSize: '12px',
                                            fontWeight: '500',
                                            mb: '5px'
                                        }}
                                    >
                                        Yesterday
                                    </Typography>
                                    <Box component="span"
                                    >&nbsp;</Box>
                                </Stack>
                            </Stack>
                        </ListItemText>
                    </ListItemButton>
                    <ListItemButton onClick={handleChatSelected} 
                    sx={{ 
                        px: "0",
                        '&:hover':{
                            background: 'transparent'
                        } }}>
                        <ListItemAvatar
                            sx={{
                                width: "56px"
                            }}
                        >
                            <Avatar
                                sx={{
                                    color: 'success.main',
                                    bgcolor: 'success.lighter',
                                    width: '50px',
                                    height: '50px'
                                }}
                                src={getImages('avatar-1.png')}
                            >

                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            sx={{
                                pl: '10px',
                                width: "60%"
                            }}
                            primary={
                                <Typography noWrap
                                    sx={{
                                        fontSize: "1rem",
                                        fontWeight: '600',
                                        color: 'secondary.main',
                                        lineHeight: '22px',
                                        maxWidth: '220px',
                                        [theme.breakpoints.down('sm')]: {
                                            maxWidth: '110px'
                                        }
                                    }}
                                    variant="h6">John Doe
                                </Typography>}
                            secondary={<Typography
                                noWrap
                                variant="body2"
                                sx={{
                                    fontSize: '12px',
                                    fontWeight: '500',
                                    color: 'primary.main',
                                    maxWidth: '220px',
                                    [theme.breakpoints.down('sm')]: {
                                        maxWidth: '110px'
                                    }
                                }}
                            >What’s the best solution for my problem? Can you send me a listed document please.</Typography>}
                        />
                        <ListItemText
                            sx={{
                                width: "calc(40% - 56px)"
                            }}
                        >
                            <Stack alignItems="flex-end">
                                <Typography
                                    variant="body2" noWrap
                                    sx={{
                                        color: "ltheme.main",
                                        fontSize: '12px',
                                        fontWeight: '500',
                                        mb: '5px'
                                    }}
                                >
                                    11:54 AM
                                </Typography>
                                <Box component="span"
                                    sx={{
                                        bgcolor: 'primary.main',
                                        width: 24, height: 24,
                                        borderRadius: '50%',
                                        fontSize: '12px',
                                        color: '#fff',
                                        textAlign: 'center',
                                        lineHeight: '24px'
                                    }}>10</Box>
                            </Stack>
                        </ListItemText>
                    </ListItemButton>
                    <ListItemButton onClick={handleChatSelected} 
                    sx={{ 
                        px: "0",
                        '&:hover':{
                            background: 'transparent'
                        } }}>
                        <ListItemAvatar
                            sx={{
                                width: "56px"
                            }}
                        >
                            <Avatar
                                sx={{
                                    color: 'success.main',
                                    bgcolor: 'success.lighter',
                                    width: '50px',
                                    height: '50px'
                                }}
                                src={getImages('2.jpg')}
                            >

                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            sx={{
                                pl: '10px',
                                width: "60%"
                            }}
                            primary={
                                <Typography noWrap
                                    sx={{
                                        fontSize: "1rem",
                                        fontWeight: '600',
                                        color: 'secondary.main',
                                        lineHeight: '22px',
                                        maxWidth: '220px',
                                        [theme.breakpoints.down('sm')]: {
                                            maxWidth: '110px'
                                        }
                                    }}
                                    variant="h6">Messiah Rozo
                                </Typography>}
                            secondary={<Typography
                                noWrap
                                variant="body2"
                                sx={{
                                    fontSize: '12px',
                                    fontWeight: '500',
                                    color: 'ltheme.main',
                                    maxWidth: '220px',
                                    [theme.breakpoints.down('sm')]: {
                                        maxWidth: '110px'
                                    }
                                }}
                            >Hello David</Typography>}
                        />
                        <ListItemText
                            sx={{
                                width: "calc(40% - 56px)"
                            }}
                        >
                            <Stack alignItems="flex-end">
                                <Typography
                                    variant="body2" noWrap
                                    sx={{
                                        color: "ltheme.main",
                                        fontSize: '12px',
                                        fontWeight: '500',
                                        mb: '5px'
                                    }}
                                >
                                    Just now
                                </Typography>
                                <Box component="span"
                                >&nbsp;</Box>
                            </Stack>
                        </ListItemText>
                    </ListItemButton>
                    <ListItemButton onClick={handleChatSelected} 
                    sx={{ 
                        px: "0",
                        '&:hover':{
                            background: 'transparent'
                        } }}>
                        <ListItemAvatar
                            sx={{
                                width: "56px"
                            }}
                        >
                            <Badge
                                overlap="circular">

                                <Badge
                                    overlap="circular"
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                                    badgeContent={
                                        <SmallAvatar
                                            sx={{
                                                width: "30px",
                                                height: "30px",
                                                ml: 1.5
                                            }}
                                            alt="Remy Sharp" src={getImages('2.jpg')} />
                                    }
                                />
                                <Avatar alt="Travis Howard"
                                    sx={{
                                        color: 'success.main',
                                        bgcolor: 'success.lighter',
                                        width: '50px',
                                        height: '50px'
                                    }}
                                    src={getImages('1.jpg')}
                                />
                                <Badge
                                    overlap="circular"
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    badgeContent={
                                        <SmallAvatar
                                            sx={{
                                                mr: 1.5
                                            }}
                                            alt="Remy Sharp" src={getImages('2.jpg')} />
                                    }
                                />
                            </Badge>
                        </ListItemAvatar>
                        <ListItemText
                            sx={{
                                pl: '10px',
                                width: "60%"
                            }}
                            primary={
                                <Typography noWrap
                                    sx={{
                                        fontSize: "1rem",
                                        fontWeight: '600',
                                        color: 'secondary.main',
                                        lineHeight: '22px',
                                        maxWidth: '220px',
                                        [theme.breakpoints.down('sm')]: {
                                            maxWidth: '110px'
                                        }
                                    }}
                                    variant="h6">Solution discussion
                                </Typography>}
                            secondary={<Typography
                                noWrap
                                variant="body2"
                                sx={{
                                    fontSize: '12px',
                                    fontWeight: '500',
                                    color: 'primary.main',
                                    maxWidth: '220px',
                                    [theme.breakpoints.down('sm')]: {
                                        maxWidth: '110px'
                                    }
                                }}
                            >Vish: Yes I’m available.</Typography>}
                        />
                        <ListItemText
                            sx={{
                                width: "calc(40% - 56px)"
                            }}
                        >
                            <Stack alignItems="flex-end">
                                <Typography
                                    variant="body2" noWrap
                                    sx={{
                                        color: "ltheme.main",
                                        fontSize: '12px',
                                        fontWeight: '500',
                                        mb: '5px'
                                    }}
                                >
                                    09:41 AM
                                </Typography>
                                <Box component="span"
                                    sx={{
                                        bgcolor: 'primary.main',
                                        width: 24, height: 24,
                                        borderRadius: '50%',
                                        fontSize: '12px',
                                        color: '#fff',
                                        textAlign: 'center',
                                        lineHeight: '24px'
                                    }}>10</Box>
                            </Stack>
                        </ListItemText>
                    </ListItemButton>
                    <ListItemButton onClick={handleChatSelected} 
                    sx={{ 
                        px: "0",
                        '&:hover':{
                            background: 'transparent'
                        } }}>
                        <ListItemAvatar
                            sx={{
                                width: "56px"
                            }}
                        >
                            <Avatar
                                sx={{
                                    color: 'success.main',
                                    bgcolor: 'success.lighter',
                                    width: '50px',
                                    height: '50px'
                                }}
                                src={getImages('avatar-1.png')}
                            >

                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            sx={{
                                pl: '10px',
                                width: "60%"
                            }}
                            primary={
                                <Typography noWrap
                                    sx={{
                                        fontSize: "1rem",
                                        fontWeight: '600',
                                        color: 'secondary.main',
                                        lineHeight: '22px',
                                        maxWidth: '220px',
                                        [theme.breakpoints.down('sm')]: {
                                            maxWidth: '110px'
                                        }
                                    }}
                                    variant="h6">Emily Blunt
                                </Typography>}
                            secondary={<Typography
                                noWrap
                                variant="body2"
                                sx={{
                                    fontSize: '12px',
                                    fontWeight: '500',
                                    color: 'ltheme.main',
                                    maxWidth: '220px',
                                    [theme.breakpoints.down('sm')]: {
                                        maxWidth: '110px'
                                    }
                                }}
                            >I’ll confirm this with my team and get back to you asap!</Typography>}
                        />
                        <ListItemText
                            sx={{
                                width: "calc(40% - 56px)"
                            }}
                        >
                            <Stack alignItems="flex-end">
                                <Typography
                                    variant="body2" noWrap
                                    sx={{
                                        color: "ltheme.main",
                                        fontSize: '12px',
                                        fontWeight: '500',
                                        mb: '5px'
                                    }}
                                >
                                    24 Jan
                                </Typography>
                                <Box component="span"
                                >&nbsp;</Box>
                            </Stack>
                        </ListItemText>
                    </ListItemButton>
                    <ListItemButton onClick={handleChatSelected} 
                    sx={{ 
                        px: "0",
                        '&:hover':{
                            background: 'transparent'
                        } }}>
                        <ListItemAvatar
                            sx={{
                                width: "56px"
                            }}
                        >
                            <Badge
                                overlap="circular"
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                badgeContent={
                                    <SmallAvatar
                                        sx={{
                                            width: "30px",
                                            height: "30px"
                                        }}
                                        alt="Remy Sharp" src={getImages('2.jpg')} />
                                }
                            >
                                <Avatar alt="Travis Howard"
                                    sx={{
                                        color: 'success.main',
                                        bgcolor: 'success.lighter',
                                        width: '50px',
                                        height: '50px'
                                    }}
                                    src={getImages('4.jpg')}
                                />
                            </Badge>
                        </ListItemAvatar>
                        <ListItemText
                            sx={{
                                pl: '10px',
                                width: "60%"
                            }}
                            primary={
                                <Typography noWrap
                                    sx={{
                                        fontSize: "1rem",
                                        fontWeight: '600',
                                        color: 'secondary.main',
                                        lineHeight: '22px',
                                        maxWidth: '220px',
                                        [theme.breakpoints.down('sm')]: {
                                            maxWidth: '110px'
                                        }
                                    }}
                                    variant="h6">Tyson, Lauren
                                </Typography>}
                            secondary={<Typography
                                noWrap
                                variant="body2"
                                sx={{
                                    fontSize: '12px',
                                    fontWeight: '500',
                                    color: 'ltheme.main',
                                    maxWidth: '220px',
                                    [theme.breakpoints.down('sm')]: {
                                        maxWidth: '110px'
                                    }
                                }}
                            >Lauren : When can we schedule a call?</Typography>}
                        />
                        <ListItemText
                            sx={{
                                width: "calc(40% - 56px)"
                            }}
                        >
                            <Stack alignItems="flex-end">
                                <Stack alignItems="flex-end">
                                    <Typography
                                        variant="body2" noWrap
                                        sx={{
                                            color: "ltheme.main",
                                            fontSize: '12px',
                                            fontWeight: '500',
                                            mb: '5px'
                                        }}
                                    >
                                        Yesterday
                                    </Typography>
                                    <Box component="span"
                                    >&nbsp;</Box>
                                </Stack>
                            </Stack>
                        </ListItemText>
                    </ListItemButton>
                    <ListItemButton onClick={handleChatSelected} 
                    sx={{ 
                        px: "0",
                        '&:hover':{
                            background: 'transparent'
                        } }}>
                        <ListItemAvatar
                            sx={{
                                width: "56px"
                            }}
                        >
                            <Avatar
                                sx={{
                                    color: 'success.main',
                                    bgcolor: 'success.lighter',
                                    width: '50px',
                                    height: '50px'
                                }}
                                src={getImages('avatar-1.png')}
                            >

                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            sx={{
                                pl: '10px',
                                width: "60%"
                            }}
                            primary={
                                <Typography noWrap
                                    sx={{
                                        fontSize: "1rem",
                                        fontWeight: '600',
                                        color: 'secondary.main',
                                        lineHeight: '22px',
                                        maxWidth: '220px',
                                        [theme.breakpoints.down('sm')]: {
                                            maxWidth: '110px'
                                        }
                                    }}
                                    variant="h6">John Doe
                                </Typography>}
                            secondary={<Typography
                                noWrap
                                variant="body2"
                                sx={{
                                    fontSize: '12px',
                                    fontWeight: '500',
                                    color: 'primary.main',
                                    maxWidth: '220px',
                                    [theme.breakpoints.down('sm')]: {
                                        maxWidth: '110px'
                                    }
                                }}
                            >What’s the best solution for my problem? Can you send me a listed document please.</Typography>}
                        />
                        <ListItemText
                            sx={{
                                width: "calc(40% - 56px)"
                            }}
                        >
                            <Stack alignItems="flex-end">
                                <Typography
                                    variant="body2" noWrap
                                    sx={{
                                        color: "ltheme.main",
                                        fontSize: '12px',
                                        fontWeight: '500',
                                        mb: '5px'
                                    }}
                                >
                                    11:54 AM
                                </Typography>
                                <Box component="span"
                                    sx={{
                                        bgcolor: 'primary.main',
                                        width: 24, height: 24,
                                        borderRadius: '50%',
                                        fontSize: '12px',
                                        color: '#fff',
                                        textAlign: 'center',
                                        lineHeight: '24px'
                                    }}>10</Box>
                            </Stack>
                        </ListItemText>
                    </ListItemButton>
                    <ListItemButton onClick={handleChatSelected} 
                    sx={{ 
                        px: "0",
                        '&:hover':{
                            background: 'transparent'
                        } }}>
                        <ListItemAvatar
                            sx={{
                                width: "56px"
                            }}
                        >
                            <Avatar
                                sx={{
                                    color: 'success.main',
                                    bgcolor: 'success.lighter',
                                    width: '50px',
                                    height: '50px'
                                }}
                                src={getImages('2.jpg')}
                            >

                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            sx={{
                                pl: '10px',
                                width: "60%"
                            }}
                            primary={
                                <Typography noWrap
                                    sx={{
                                        fontSize: "1rem",
                                        fontWeight: '600',
                                        color: 'secondary.main',
                                        lineHeight: '22px',
                                        maxWidth: '220px',
                                        [theme.breakpoints.down('sm')]: {
                                            maxWidth: '110px'
                                        }
                                    }}
                                    variant="h6">Messiah Rozo
                                </Typography>}
                            secondary={<Typography
                                noWrap
                                variant="body2"
                                sx={{
                                    fontSize: '12px',
                                    fontWeight: '500',
                                    color: 'ltheme.main',
                                    maxWidth: '220px',
                                    [theme.breakpoints.down('sm')]: {
                                        maxWidth: '110px'
                                    }
                                }}
                            >Hello David</Typography>}
                        />
                        <ListItemText
                            sx={{
                                width: "calc(40% - 56px)"
                            }}
                        >
                            <Stack alignItems="flex-end">
                                <Typography
                                    variant="body2" noWrap
                                    sx={{
                                        color: "ltheme.main",
                                        fontSize: '12px',
                                        fontWeight: '500',
                                        mb: '5px'
                                    }}
                                >
                                    Just now
                                </Typography>
                                <Box component="span"
                                >&nbsp;</Box>
                            </Stack>
                        </ListItemText>
                    </ListItemButton>
                    <ListItemButton onClick={handleChatSelected} 
                    sx={{ 
                        px: "0",
                        '&:hover':{
                            background: 'transparent'
                        } }}>
                        <ListItemAvatar
                            sx={{
                                width: "56px"
                            }}
                        >
                            <Badge
                                overlap="circular">

                                <Badge
                                    overlap="circular"
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                                    badgeContent={
                                        <SmallAvatar
                                            sx={{
                                                width: "30px",
                                                height: "30px",
                                                ml: 1.5
                                            }}
                                            alt="Remy Sharp" src={getImages('2.jpg')} />
                                    }
                                />
                                <Avatar alt="Travis Howard"
                                    sx={{
                                        color: 'success.main',
                                        bgcolor: 'success.lighter',
                                        width: '50px',
                                        height: '50px'
                                    }}
                                    src={getImages('1.jpg')}
                                />
                                <Badge
                                    overlap="circular"
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    badgeContent={
                                        <SmallAvatar
                                            sx={{
                                                mr: 1.5
                                            }}
                                            alt="Remy Sharp" src={getImages('2.jpg')} />
                                    }
                                />
                            </Badge>
                        </ListItemAvatar>
                        <ListItemText
                            sx={{
                                pl: '10px',
                                width: "60%"
                            }}
                            primary={
                                <Typography noWrap
                                    sx={{
                                        fontSize: "1rem",
                                        fontWeight: '600',
                                        color: 'secondary.main',
                                        lineHeight: '22px',
                                        maxWidth: '220px',
                                        [theme.breakpoints.down('sm')]: {
                                            maxWidth: '110px'
                                        }
                                    }}
                                    variant="h6">Solution discussion
                                </Typography>}
                            secondary={<Typography
                                noWrap
                                variant="body2"
                                sx={{
                                    fontSize: '12px',
                                    fontWeight: '500',
                                    color: 'primary.main',
                                    maxWidth: '220px',
                                    [theme.breakpoints.down('sm')]: {
                                        maxWidth: '110px'
                                    }
                                }}
                            >Vish: Yes I’m available.</Typography>}
                        />
                        <ListItemText
                            sx={{
                                width: "calc(40% - 56px)"
                            }}
                        >
                            <Stack alignItems="flex-end">
                                <Typography
                                    variant="body2" noWrap
                                    sx={{
                                        color: "ltheme.main",
                                        fontSize: '12px',
                                        fontWeight: '500',
                                        mb: '5px'
                                    }}
                                >
                                    09:41 AM
                                </Typography>
                                <Box component="span"
                                    sx={{
                                        bgcolor: 'primary.main',
                                        width: 24, height: 24,
                                        borderRadius: '50%',
                                        fontSize: '12px',
                                        color: '#fff',
                                        textAlign: 'center',
                                        lineHeight: '24px'
                                    }}>10</Box>
                            </Stack>
                        </ListItemText>
                    </ListItemButton>
                    <ListItemButton onClick={handleChatSelected} 
                    sx={{ 
                        px: "0",
                        '&:hover':{
                            background: 'transparent'
                        } }}>
                        <ListItemAvatar
                            sx={{
                                width: "56px"
                            }}
                        >
                            <Avatar
                                sx={{
                                    color: 'success.main',
                                    bgcolor: 'success.lighter',
                                    width: '50px',
                                    height: '50px'
                                }}
                                src={getImages('avatar-1.png')}
                            >

                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            sx={{
                                pl: '10px',
                                width: "60%"
                            }}
                            primary={
                                <Typography noWrap
                                    sx={{
                                        fontSize: "1rem",
                                        fontWeight: '600',
                                        color: 'secondary.main',
                                        lineHeight: '22px',
                                        maxWidth: '220px',
                                        [theme.breakpoints.down('sm')]: {
                                            maxWidth: '110px'
                                        }
                                    }}
                                    variant="h6">Emily Blunt
                                </Typography>}
                            secondary={<Typography
                                noWrap
                                variant="body2"
                                sx={{
                                    fontSize: '12px',
                                    fontWeight: '500',
                                    color: 'ltheme.main',
                                    maxWidth: '220px',
                                    [theme.breakpoints.down('sm')]: {
                                        maxWidth: '110px'
                                    }
                                }}
                            >I’ll confirm this with my team and get back to you asap!</Typography>}
                        />
                        <ListItemText
                            sx={{
                                width: "calc(40% - 56px)"
                            }}
                        >
                            <Stack alignItems="flex-end">
                                <Typography
                                    variant="body2" noWrap
                                    sx={{
                                        color: "ltheme.main",
                                        fontSize: '12px',
                                        fontWeight: '500',
                                        mb: '5px'
                                    }}
                                >
                                    24 Jan
                                </Typography>
                                <Box component="span"
                                >&nbsp;</Box>
                            </Stack>
                        </ListItemText>
                    </ListItemButton>
                    <ListItemButton onClick={handleChatSelected} 
                    sx={{ 
                        px: "0",
                        '&:hover':{
                            background: 'transparent'
                        } }}>
                        <ListItemAvatar
                            sx={{
                                width: "56px"
                            }}
                        >
                            <Badge
                                overlap="circular"
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                badgeContent={
                                    <SmallAvatar
                                        sx={{
                                            width: "30px",
                                            height: "30px"
                                        }}
                                        alt="Remy Sharp" src={getImages('2.jpg')} />
                                }
                            >
                                <Avatar alt="Travis Howard"
                                    sx={{
                                        color: 'success.main',
                                        bgcolor: 'success.lighter',
                                        width: '50px',
                                        height: '50px'
                                    }}
                                    src={getImages('4.jpg')}
                                />
                            </Badge>
                        </ListItemAvatar>
                        <ListItemText
                            sx={{
                                pl: '10px',
                                width: "60%"
                            }}
                            primary={
                                <Typography noWrap
                                    sx={{
                                        fontSize: "1rem",
                                        fontWeight: '600',
                                        color: 'secondary.main',
                                        lineHeight: '22px',
                                        maxWidth: '220px',
                                        [theme.breakpoints.down('sm')]: {
                                            maxWidth: '110px'
                                        }
                                    }}
                                    variant="h6">Tyson, Lauren
                                </Typography>}
                            secondary={<Typography
                                noWrap
                                variant="body2"
                                sx={{
                                    fontSize: '12px',
                                    fontWeight: '500',
                                    color: 'ltheme.main',
                                    maxWidth: '220px',
                                    [theme.breakpoints.down('sm')]: {
                                        maxWidth: '110px'
                                    }
                                }}
                            >Lauren : When can we schedule a call?</Typography>}
                        />
                        <ListItemText
                            sx={{
                                width: "calc(40% - 56px)"
                            }}
                        >
                            <Stack alignItems="flex-end">
                                <Stack alignItems="flex-end">
                                    <Typography
                                        variant="body2" noWrap
                                        sx={{
                                            color: "ltheme.main",
                                            fontSize: '12px',
                                            fontWeight: '500',
                                            mb: '5px'
                                        }}
                                    >
                                        Yesterday
                                    </Typography>
                                    <Box component="span"
                                    >&nbsp;</Box>
                                </Stack>
                            </Stack>
                        </ListItemText>
                    </ListItemButton>
                </List>
            </Box>
        </>
    )
}

export default SolutionChatsList