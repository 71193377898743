import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Cookies from 'js-cookie';
// import { verifyToken, RetryOnError } from '../../utils/hooks/VerifyToken';

const baseUrl = process.env.REACT_APP_base_URL;
const logoBaseUrl = process.env.REACT_APP_S3_BUCKET_URL;
export const getUser = createApi({
  reducerPath: 'users',
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers) => {
      const token = Cookies.get('jwt');
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => (
    {
    fetchUserDetails: builder.query({
      query: (id) => ({
        url: `user/${id}`,
        method: 'GET',
      }),
      transformResponse: (response) => {
        const savedData = response.data;
        return {
          focusIndustry: savedData.focus_industry || [],
          id: savedData._id,
          salutation: savedData.salutation,
          firstName: savedData.first_name,
          status: savedData.status,
          lastName: savedData.last_name,
          email: savedData.email,
          countryId: savedData.country_id._id,
          countryName: savedData.country_id?.name,
          phoneCode: savedData.phone_code,
          countryShortCode: savedData.country_short_code,
          phoneNumber: savedData.phone_number,
          isVerified: savedData.isVerified,
          updatedAt: savedData.updated_at,
          createdAt: savedData.created_at,
          userType: savedData.user_type,
          logo: savedData.avatar? savedData.avatar : '',
        };
      },
      refetchOnWindowFocus: true,
      // retry: RetryOnError,
      onError: (error) => {
        console.error('Fetch user details error:', error);
        // Handle error cases as per your application's requirements
      },
    }),
  }),
});

export const { useFetchUserDetailsQuery } = getUser;
