import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    chatData: true,
    openChat: false,
    openChatInfo: false,
    openSolutionList: false,
    openProfileMedia: false,
    openProfileDocument: false,
    chatSelected: false,
    openGroupChat: false,
    openInviteToBSIColab: false,
    openNewChat: false,
    threadChat: false,
    createGroup: false,
};

const chatSlice = createSlice({
    name:'chat',
    initialState,
    reducers: {
        toggleChatData(state) {
            state.chatData = !state.chatData;
        },
        setOpenChat(state, action) {
            state.openChat = action.payload;
        },
        toggleOpenChatInfo(state) {
            state.openChatInfo = !state.openChatInfo;
        },
        toggleOpenSolutionList(state) {
            state.openSolutionList = !state.openSolutionList;
        },
        toggleOpenProfileMedia(state) {
            state.openProfileMedia = !state.openProfileMedia;
        },
        toggleOpenProfileDocument(state) {
            state.openProfileDocument = !state.openProfileDocument;
        },
        setChatSelected(state, action) {
            state.chatSelected = action.payload;
        },
        setOpenGroupChat(state, action) {
            state.openGroupChat = action.payload;
        },
        toggleOpenInviteToBSIColab(state) {
            state.openInviteToBSIColab = !state.openInviteToBSIColab;
        },
        toggleOpenNewChat(state) {
            state.openNewChat = !state.openNewChat;
        },
        toggleThreadChat(state) {
            state.threadChat = !state.threadChat;
        },
        toggleCreateGroup(state) {
            state.createGroup = !state.createGroup;
        },
    },
});

export const {
    toggleChatData,
    setOpenChat,
    toggleOpenChatInfo,
    toggleOpenSolutionList,
    toggleOpenProfileMedia,
    toggleOpenProfileDocument,
    setChatSelected,
    setOpenGroupChat,
    toggleOpenInviteToBSIColab,
    toggleOpenNewChat,
    toggleThreadChat,
    toggleCreateGroup,
} = chatSlice.actions;

export default chatSlice.reducer;