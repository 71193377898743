import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = process.env.REACT_APP_base_URL;

const deleteRequirementBoardApi = createApi({
    reducerPath: 'deleteRequirementBoardApi',
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers) => {
            // Add any headers you need here
            return headers;
        },
    }),
    endpoints: (builder) => ({
        deleteRequirementBoard: builder.mutation({
            query: ({ boardId }) => ({
                url: `/requirement_boards/${boardId}`,
                method: 'DELETE',
            }),
            transformResponse: (response)=>{
                return response;
            }
        }),
    }),
});

export const { useDeleteRequirementBoardMutation } = deleteRequirementBoardApi;
export default deleteRequirementBoardApi;

