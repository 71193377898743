import React from 'react'
import {
    Box,
    IconButton,
    Typography,
    ImageList,
    ImageListItem,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { toggleOpenChatInfo, toggleOpenProfileMedia } from '../../redux/features/chatSlice';
import { useDispatch } from 'react-redux';

const ProfileMediaInfo = ({ itemData }) => {

    const dispatch = useDispatch();
    const closeMediaHandle = () => {
        dispatch(toggleOpenProfileMedia());
        dispatch(toggleOpenChatInfo());
    };

    return (
        <>

            <Box
                sx={{
                    background: "#fff",
                    minHeight: "100%",
                    position: "relative",
                    borderRadius: '10px',
                    py: 2,
                    px: 2,
                }}
            >
                <IconButton aria-label="close"
                    onClick={closeMediaHandle}
                    sx={{
                        position: "absolute",
                        right: "10px",
                        top: "10px",
                        color: 'lightSecondary.main',
                    }}
                >
                    <CloseIcon
                        sx={{
                            fontSize: '26px'
                        }}
                    />
                </IconButton>
                <Typography variant="h6"
                    sx={{
                        color: 'secondary.main',
                        fontSize: '1.1em',
                        fontWeight: '600'
                    }}
                >Media</Typography>

                <Box
                    sx={{
                        mt: 2,
                        overflowY: 'auto',
                        maxHeight: 'calc(100vh - 230px)',
                        '&::-webkit-scrollbar': {
                            width: '6px',
                            borderRadius: '4px'
                        },
                        '&::-webkit-scrollbar-track': {
                            backgroundColor: 'transparent',
                            borderRadius: '4px'
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: '#9C9C9C',
                            borderRadius: '4px'
                        },
                        '@media (max-width:1600px) and (min-width:1200px)': {
                            maxHeight: '100vh',
                        },
                    }}
                >
                    <ImageList
                        sx={{
                            width: "100%",
                            m: 0,
                        }}
                        style={{gap: '10px',}}
                        cols={3}>
                        {itemData?.map((item, index) => (
                            <ImageListItem
                                key={item.img}
                                sx={{
                                    position: "relative",
                                    borderRadius: "10px",
                                    overflow: "hidden"
                                }}
                            >
                                <img
                                    // srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                    src={`${item.img}`}
                                    alt=""
                                    loading="lazy"
                                />
                            </ImageListItem>
                        ))}
                    </ImageList>
                </Box>
            </Box>
        </>
    )
}

export default ProfileMediaInfo