import React from 'react'
import { Box, FormControl, MenuItem, Select, Typography, OutlinedInput, RadioGroup, Radio, InputLabel, ListItemText } from '@mui/material'
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import MainCard from '../../MainCard';
import StepHeader from './StepHeader';
import StepFooter from './StepFooter';
import { useTheme } from '@emotion/react';

const techs = [
    'AI',
    'Blockchain',
    'Both',
];
const Step1 = ({ nextStep, currentStep, prevStep }) => {
    const theme = useTheme();
    const [tech, setTech] = React.useState([]);
    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setTech(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    return (
        <>
            <Box
                sx={{
                    background: '#dbebf8'
                }}
            >
                <Box
                    sx={{
                        py: 20,
                        maxWidth: '1366px',
                        margin: '0 auto',
                       [theme.breakpoints.down('xl')]: {
                            py: 10,
                            px: '20px'
                        },
                    }}
                >
                    <MainCard content={false}
                        sx={{
                            backgroundColor: "#FFFFFF",
                            border: "none",
                            padding: "0",
                            boxShadow: "none",
                            borderRadius: "0"
                        }}
                    >
                        <StepHeader
                            currentStep={currentStep}
                            prevStep={prevStep}
                        />
                        <Box
                            sx={{
                                p: 3,
                                pb: 5
                            }}
                        >
                            <Box
                                sx={{
                                    maxWidth: '850px',
                                    margin: '0 auto'
                                }}
                            >

                                <Typography variant='h4'
                                    sx={{
                                        fontSize: '1.8rem',
                                        color: 'secondary.main',
                                        fontWeight: '700',
                                        textAlign: 'center',
                                        mb: 7,
                                        position: 'relative',
                                        '&:before': {
                                            content: '""',
                                            position: 'absolute',
                                            left: '50%',
                                            bottom: '-22px',
                                            height: '3px',
                                            borderRadius: '3px',
                                            width: '85px',
                                            transform: 'translate(-50%, 0)',
                                            backgroundColor: 'secondary.main'
                                        },
                                        [theme.breakpoints.down('md')]: {
                                            fontSize: '1.4rem',
                                            mb: 5,
                                            '&:before': {
                                                bottom: '-15px',
                                            }
                                        },
                                        [theme.breakpoints.down('sm')]: {
                                            fontSize: '1.2rem',
                                        },
                                    }}
                                >Enhance decision-making with AI-recommended solutions.</Typography>
                                <Typography variant='h6'
                                    sx={{
                                        fontSize: '1.1rem',
                                        color: 'secondary.main',
                                        fontWeight: '600',
                                        mb: 2,
                                        [theme.breakpoints.down('md')]: {
                                            fontSize: '1rem',
                                            mb: 1
                                        },
                                    }}
                                >Please select the technology of your choice?</Typography>
                                <FormControl fullWidth
                                    sx={{
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            border: 'none'
                                        }
                                    }}>
                                    {/* <InputLabel id="demo-multiple-checkbox-label">Tag</InputLabel> */}
                                    <Select
                                        labelId="demo-multiple-checkbox-label"
                                        id="demo-multiple-checkbox"
                                        value={tech}
                                        onChange={handleChange}
                                        defaultValue="Select Tech"
                                        input={<OutlinedInput label="Tag" />}
                                        renderValue={(selected) => selected.join(', ')}
                                        IconComponent={KeyboardArrowDownOutlinedIcon}
                                        sx={{
                                            background: "#ECF6FF",
                                            '& .MuiSelect-select': {
                                                minHeight: "20px",
                                                padding: "11px 14px",
                                                color: "lightSecondary.main",
                                                fontWeight: "600",
                                                fontSize: "0.9rem"
                                            },
                                            '& .MuiSelect-icon': {
                                                color: 'lightSecondary.main'
                                            },
                                        }}
                                    >
                                        <MenuItem disabled value="Select Tech">
                                            Select Tech
                                        </MenuItem>
                                        {techs.map((techItem) => (
                                            <MenuItem key={techItem} value={techItem}
                                                sx={{
                                                    '& .MuiTypography-root': {
                                                        color: 'secondary.main',
                                                        fontSize: '14px',
                                                        fontWeight: '600'
                                                    }
                                                }}
                                            >
                                                <Radio checked={tech.indexOf(techItem) > -1}
                                                    sx={{
                                                        py: 0.5,
                                                        color: 'secondary.main',
                                                        '& .MuiSvgIcon-root': {
                                                            color: 'secondary.main',
                                                        }
                                                    }}
                                                />
                                                <ListItemText primary={techItem}
                                                    sx={{
                                                        color: 'secondary.main',
                                                        fontSize: '14px',
                                                        fontWeight: '600'
                                                    }}
                                                />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <StepFooter nextStep={nextStep} />
                            </Box>
                        </Box>
                    </MainCard>
                </Box>
            </Box>
        </>
    )
}

export default Step1