import React, { useState } from 'react'
import {
    Box,
    Button,
    Grid,
    Typography,
} from '@mui/material';
import { useTheme } from '@emotion/react';
import { getImages } from '../../commonComponents/const';
import AllSolutionsList from '../../commonComponents/Inbox/AllSolutionsList';
import SolutionChatsList from '../../commonComponents/Inbox/SolutionChatsList';
import ChatContainer from '../../commonComponents/Inbox/ChatContainer';
import ChatProfileInfo from '../../commonComponents/Inbox/ChatProfileInfo';
import GroupChatContainer from '../../commonComponents/Inbox/GroupChatContainer';
import Modals from '../../commonComponents/Modals';
import InvitetoBSIColab from '../../commonComponents/Inbox/InvitetoBSIColab';
import NewChat from '../../commonComponents/Inbox/NewChat';
import CreateNewGroupChat from '../../commonComponents/Inbox/CreateNewGroupChat';
import ThreadChatContainer from '../../commonComponents/Inbox/ThreadChatContainer';
import ProfileMediaInfo from '../../commonComponents/Inbox/ProfileMediaInfo';
import ProfileDocuments from '../../commonComponents/Inbox/ProfileDocuments';
import { useDispatch, useSelector } from 'react-redux';
import {
    setOpenChat,
    toggleOpenChatInfo,
    toggleOpenSolutionList,
    toggleOpenProfileDocument,
    setChatSelected,
    setOpenGroupChat,
    toggleOpenInviteToBSIColab,
    toggleOpenNewChat,
    toggleThreadChat,
    toggleCreateGroup,
}from '../../redux/features/chatSlice';

const itemData = [
    {
        img: `${getImages('media1.jpg')}`,
    },
    {
        img: `${getImages('media2.jpg')}`,
    },
    {
        img: `${getImages('media1.jpg')}`,
    },
    {
        img: `${getImages('media2.jpg')}`,
    },
    {
        img: `${getImages('media1.jpg')}`,
    },
    {
        img: `${getImages('media1.jpg')}`,
    },
    {
        img: `${getImages('media2.jpg')}`,
    },
    {
        img: `${getImages('media1.jpg')}`,
    },
    {
        img: `${getImages('media2.jpg')}`,
    },
    {
        img: `${getImages('media1.jpg')}`,
    },
]
const Inbox = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const {
        chatData,
        openChat,
        openChatInfo,
        openSolutionList,
        openProfileMedia,
        openProfileDocument,
        chatSelected,
        openGroupChat,
        openInviteToBSIColab,
        openNewChat,
        threadChat,
        createGroup,
    } = useSelector((state) => state.chat);

    // Profile chat Info

    const handleSolutionList = () => {
        dispatch(toggleOpenSolutionList());
        if(openChatInfo){
            dispatch(toggleOpenChatInfo())
        }
    };

    const closeDocumentHandle = () => {
        dispatch(toggleOpenProfileDocument());
        dispatch(toggleOpenChatInfo());
    };


    // Group Chat and single Chat

    const groupChatHandle = () => {
        dispatch(setOpenGroupChat(true));
        dispatch(setChatSelected(false));
        dispatch(toggleOpenChatInfo());
        dispatch(toggleThreadChat());
        dispatch(toggleOpenSolutionList());
    };

    const chatSelectedHandle = () => {
        dispatch(setChatSelected(true));
        dispatch(setOpenGroupChat(false));
        dispatch(toggleOpenChatInfo());
        dispatch(toggleOpenSolutionList());
    };

    const closeSolutionChatList = () => {
        dispatch(setOpenChat(false));
    };

    // Invite to BSI Co-lab
    const inviteToBSIColabHandle = () => {
        dispatch(toggleOpenInviteToBSIColab());
    };

    const closeInviteToBSIColab = () => {
        dispatch(toggleOpenInviteToBSIColab());
    };

    // New Chat
    const newChatHandle = () => {
        dispatch(toggleOpenNewChat());
    };

    const closeNewChat = () => {
        dispatch(toggleOpenNewChat());
    };
    // Create Group
    const createGroupHandle = () => {
        dispatch(toggleCreateGroup());
        dispatch(toggleOpenNewChat());
        dispatch(toggleThreadChat());
    };

    const closeCreateGroup = () => {
        dispatch(toggleCreateGroup());
    };

    // Thread Chat
    const threadChatHandle = () => {
        dispatch(toggleThreadChat());
        dispatch(toggleOpenNewChat());
        dispatch(setOpenGroupChat(false));
    };

    const closeThreadChat = () => {
        dispatch(toggleThreadChat());
        dispatch(setOpenGroupChat(true));
    };

    return (
        <>
            <Box
                sx={{
                    [theme.breakpoints.down('lg')]: {
                        overflow: 'hidden',
                        position: 'relative',
                    }
                }}>
                <Grid container
                    sx={{
                        minHeight: "calc(100vh - 72px)",
                        position: 'relative',
                        overflow: 'hidden',
                        [theme.breakpoints.down('lg')]: {
                            minHeight: "calc(100vh - 97px)",
                        }
                    }}
                >
                    <Grid item xs={12} md={12} lg={2.4} xl={2.7}
                        sx={{
                            backgroundColor: "#DBEBF8",
                            pt: 2,
                            pb: 0,
                            pr: 0.7,
                            pl: 2.2,
                            position: openSolutionList ? 'absolute' : 'absolute',
                            zIndex: "2",
                            boxShadow: "5px 10px 10px rgb(0 0 0 / 10%)",
                            minHeight: "100%",
                            left: openSolutionList ? '-21%' : '0%',
                            transition: 'all 0.3s ease-in-out 0s',
                            minHeight: openSolutionList ? '100%' : 'inherit',
                            '@media (max-width:1535px) and (min-width:1200px)': {
                                left: openSolutionList ? '-19%' : '0%',
                            },
                            '@media (max-width:1200px)': {
                                left: openSolutionList ? '0%' : '0%',
                                width: '100%'
                            },
                        }}
                    >
                    <AllSolutionsList/>
                     </Grid>
                     {/* No Chat Section */}
                     {chatData ?
                        <Grid item xs={12} md={6} lg={openSolutionList ? 12 : 12} xl={openSolutionList ? 12 : 12}
                            sx={{
                                pl: openSolutionList ? "2" : "22.5%",
                                '@media (max-width:1535px) and (min-width:1200px)': {
                                    pl: openSolutionList ? "2" : "20%",
                                },
                                [theme.breakpoints.down('lg')]: {
                                    position: 'absolute',
                                    right: '-150%',
                                    top: '0',
                                    zIndex: '10',
                                    background: '#dbebf8'
                                }
                            }}
                        >
                            <Box sx={{
                                position: "relative",
                                width: '100%',
                                minHeight: '100%'
                            }}>
                                <Box
                                    sx={{
                                        position: "absolute",
                                        left: "50%",
                                        top: "50%",
                                        transform: "translate(-50%, -50%)"
                                    }}
                                >
                                    <img src={getImages('no_inbox.png')} />
                                    <Typography variant='body2'
                                        sx={{
                                            color: 'ltheme.main',
                                            fontSize: '1.3rem',
                                            fontWeight: '500',
                                            textAlign: 'center',
                                            transform: 'rotate(-5deg) skew(10deg)',
                                            pl: 12
                                        }}
                                    >
                                        No Solutions or chat selected
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid> :

                        <Grid item xs={12} md={12} lg={openSolutionList ? 12 : 12} xl={openSolutionList ? 12 : 12}
                            sx={{
                                minHeight: "100%",
                                p: 2,
                                pl: 0,
                                transition: 'all 0.3s ease-in-out 0s',
                                position: "relative",
                                right: '0',
                                pl: openSolutionList ? "2" : "22.5%",
                                '@media (max-width:1535px) and (min-width:1200px)': {
                                    pl: openSolutionList ? "2" : "20%",
                                },
                                [theme.breakpoints.down('lg')]: {
                                    p: 0
                                },
                            }}
                        >
                            <Grid container
                                sx={{
                                    minHeight: "100%",
                                    position: 'relative'
                                }}
                            >
                                <Button type='button'
                                    onClick={handleSolutionList}
                                    sx={{
                                        position: 'absolute',
                                        left: openSolutionList ? '-10px' : '-15px',
                                        top: '7px',
                                        padding: '0',
                                        minWidth: 'inherit',
                                        zIndex: '11',
                                        transform: openSolutionList ? 'rotate(0deg)' : 'rotate(180deg)',
                                        transition: 'all 0.3s ease-in-out 0s',
                                        '&:hover': {
                                            background: 'transparent'
                                        },
                                        '@media (max-width:1535px) and (min-width:1281px)': {
                                            left: openSolutionList ? '-15px' : '-12px',
                                        },
                                        '@media (max-width:1281px)': {
                                            display: 'none'
                                        },
                                    }}
                                >
                                    <img src={getImages('collaps-icon.png')} />
                                </Button>
                                <Grid item xs={12} md={12} lg={3.6} xl={4}

                                    sx={{

                                        '@media (max-width:1280px) and (min-width:1200px)': {
                                            maxWidth: openSolutionList ? '30%' : '40%',
                                            flexBasis: openSolutionList ? '30%' : '40%'
                                        },
                                        [theme.breakpoints.down('lg')]: {
                                            position: 'absolute',
                                            right: openChat ? '0' : '-150%',
                                            top: '0',
                                            zIndex: '10',
                                            background: '#dbebf8',
                                            width: '100%',
                                            height: '100%'
                                        }
                                    }}
                                >
                                    <SolutionChatsList/>
                                </Grid>
                                <Grid item xs={12} md={12}
                                    lg={openChatInfo || openProfileMedia || openProfileDocument ? 5.4 : 8.4}
                                    xl={openChatInfo || openProfileMedia || openProfileDocument ? 5 : 8}
                                    sx={{
                                        minHeight: "100%",
                                        '@media (max-width:1280px) and (min-width:1200px)': {
                                            maxWidth: 
                                            openSolutionList? openChatInfo || openProfileMedia || openProfileDocument ? '40%' : '70%' : '60%' , 
                                            
                                            flexBasis: 
                                            openSolutionList? openChatInfo || openProfileMedia || openProfileDocument ? '40%' : '70%' : '60%' 
                                        },
                                        [theme.breakpoints.down('lg')]: {
                                            position: 'absolute',
                                            right: chatSelected || openGroupChat || threadChat ? '0' : '-150%',
                                            top: '0',
                                            zIndex: '10',
                                            background: '#dbebf8',
                                            width: '100%',
                                            height: '100%'
                                        }
                                    }}
                                >
                                    <Box
                                        sx={{
                                            background: "#fff",
                                            minHeight: "100%",
                                            position: "relative",
                                            borderRadius: '10px',
                                            p: 1
                                        }}>
                                        {threadChat ?
                                            <ThreadChatContainer
                                                closeThreadChat={closeThreadChat}
                                            /> :
                                            chatSelected || openGroupChat ?
                                                openGroupChat ? 
                                                    <GroupChatContainer
                                                        threadChatHandle={threadChatHandle}
                                                    /> :
                                                    <ChatContainer
                                                    />
                                                :
                                                <Box
                                                    sx={{
                                                        position: "absolute",
                                                        left: "50%",
                                                        top: "50%",
                                                        transform: "translate(-50%, -50%)",
                                                        textAlign: "center"
                                                    }}
                                                >
                                                    <img
                                                        src={getImages('chat-icon.jpg')} alt='chat'
                                                    />
                                                    <Typography variant='h6'
                                                        sx={{
                                                            fontSize: "1.4rem",
                                                            fontWeight: "700",
                                                            color: "ltheme.main",
                                                            mt: 1
                                                        }}
                                                    >No chat selected</Typography>
                                                    <Typography variant='body2'
                                                        sx={{
                                                            fontSize: "1.1rem",
                                                            fontWeight: "500",
                                                            color: "ltheme.main"
                                                        }}
                                                    >Please select a chat from the list to see the conversation.</Typography>
                                                </Box>
                                        }
                                    </Box>
                                </Grid>
                                {openChatInfo &&
                                    <Grid item xs={12} md={12} lg={3} xl={3}
                                        sx={{
                                            pl: 1.5,
                                            minHeight: "100%",
                                            '@media (max-width:1280px)': {
                                                position: 'absolute',
                                                right: openChatInfo ? '0%' : '-150%',
                                                top: '0',
                                                zIndex: '10',
                                                background: '#dbebf8',
                                                width: '100%',
                                                pl: 0,
                                                minHeight: 'calc(100% - 82px)',
                                                transition: 'all 0.5s ease-in-out 0s',
                                            },
                                            '@media (max-width:1280px) and (min-width:1200px)': {
                                                boxShadow: '0 0 10px rgb(0 0 0 / 20%)'
                                            },
                                            '@media (max-width:1280px) and (min-width:1200px)': {
                                                maxWidth: '30%',
                                                flexBasis: '30%'
                                            },
                                        }}
                                    >
                                        <ChatProfileInfo
                                            itemData={itemData}
                                        />
                                    </Grid>
                                }
                                {openProfileMedia &&
                                    <Grid item xs={12} md={12} lg={3} xl={3}
                                        sx={{
                                            pl: 1.5,
                                            minHeight: "100%",
                                            '@media (max-width:1600px)': {
                                                position: 'absolute',
                                                right: openProfileMedia ? '0' : '-150%',
                                                top: '0',
                                                zIndex: '10',
                                                background: '#dbebf8',
                                                width: '100%',
                                            },
                                            '@media (max-width:1280px) and (min-width:1200px)': {
                                                maxWidth: '30%',
                                                flexBasis: '30%'
                                            },
                                        }}
                                    >
                                        <ProfileMediaInfo
                                            itemData={itemData}
                                        />
                                    </Grid>
                                }
                                {openProfileDocument &&
                                    <Grid item xs={12} md={12} lg={3} xl={3}
                                        sx={{
                                            pl: 1.5,
                                            minHeight: "100%",
                                            '@media (max-width:1600px)': {
                                                position: 'absolute',
                                                right: openProfileDocument ? '0' : '-150%',
                                                top: '0',
                                                zIndex: '10',
                                                background: '#dbebf8',
                                                width: '100%',
                                            },
                                            '@media (max-width:1280px) and (min-width:1200px)': {
                                                maxWidth: '30%',
                                                flexBasis: '30%'
                                            },
                                        }}
                                    >
                                        <ProfileDocuments
                                            closeDocumentHandle={closeDocumentHandle}
                                            itemData={itemData}
                                        />
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    }
                </Grid>
            </Box>
            <Modals open={openInviteToBSIColab} handleClose={closeInviteToBSIColab} modalWidth={500}>
                <InvitetoBSIColab handleClose={closeInviteToBSIColab} />
            </Modals>
            <Modals open={openNewChat} handleClose={closeNewChat} modalWidth={500}>
                <NewChat
                    handleClose={closeNewChat}
                    createGroupHandle={createGroupHandle}
                />
            </Modals>
            <Modals open={createGroup} handleClose={closeCreateGroup} modalWidth={500}>
                <CreateNewGroupChat
                    handleClose={closeCreateGroup}
                />
            </Modals>
        </>
    )
}

export default Inbox