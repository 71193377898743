import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Cookies from 'js-cookie';
// import { verifyToken, RetryOnError } from '../../utils/hooks/VerifyToken';

const baseUrl = process.env.REACT_APP_base_URL;
export const getBusinessProfile = createApi({
  reducerPath: 'businessUserProfile',
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers) => {
      return headers;
    },
  }),
  endpoints: (builder) => (
    {
    fetchBusinessUserDetails: builder.query({
      query: (userId) => ({
        url: `user/profile/${userId}`,
        method: 'GET',
      }),
      transformResponse: (response) => {
        return response;
      },
      refetchOnWindowFocus: true,
      // retry: RetryOnError,
      onError: (error) => {
        console.error('Fetch user details error:', error);
        // Handle error cases as per your application's requirements
      },
    }),
  }),
});

export const { useFetchBusinessUserDetailsQuery } = getBusinessProfile;
export default getBusinessProfile;
