import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl =process.env.REACT_APP_base_URL;

const createBusinessUserProfileApi = createApi({
    reducerPath: 'createBusinessUserProfileApi',
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers) => {
            // Add any headers you need here
            return headers;
        },
    }),
    endpoints: (builder) => ({
        createBusinessUserProfile: builder.mutation({
            query: ({ userId , userType , businessName , businessUrl , phoneCode , phoneNumber , role , countryId , techType , industryType , useCaseType , companySize , companyRevenue , linkedinUrl}) => ({
                url:'user/business/create-business-user-profile-first-form',
                method: 'POST',
                body: { 
                    user_id:userId, 
                    user_type: userType,
                    business_name: businessName, 
                    business_url: businessUrl,
                    phone_code: phoneCode,
                    phone_number: phoneNumber,
                    company_role: role,
                    country_id: countryId, 
                    tech_type: techType,
                    industry_type: industryType,
                    usecase_type: useCaseType,
                    company_size:companySize,
                    company_revenue_level: companyRevenue,
                    linkedin_url: linkedinUrl,
             },
            }),
            transformResponse: (response) => {
                return response;
             
            },
        }),
    }),
});

export const { useCreateBusinessUserProfileMutation } = createBusinessUserProfileApi;
export default createBusinessUserProfileApi;
