import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Cookies from 'js-cookie';
// import { verifyToken, RetryOnError } from '../../utils/hooks/VerifyToken';

const baseUrl = process.env.REACT_APP_base_URL;
export const getMasterData = createApi({
  reducerPath: 'master',
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers) => {
      const token = Cookies.get('jwt');
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => (
    {
    fetchMasterDetails: builder.query({
      query: () => ({
        url: `master/master-options`,
        method: 'GET',
      }),
      transformResponse: (response) => {
        // Extract relevant data from the response
       const { statusCode, status, message, data } = response;

       // Return the extracted data
       return {
           statusCode,
           status,
           message,
           masterData: data
       };
   },
      refetchOnWindowFocus: true,
      // retry: RetryOnError,
      onError: (error) => {
        console.error('Fetch master  details error:', error);
        // Handle error cases as per your application's requirements
      },
    }),
  }),
});

export const { useFetchMasterDetailsQuery } = getMasterData;
