import React, { useState } from 'react'
import {
    Box,
    Grid,
    ListItemText,
    Typography,
    ListItemAvatar,
    Avatar,
    Button,
    IconButton,
    Stack,
    ListItem,
    List
} from '@mui/material';
import { useTheme } from '@emotion/react';
import { getImages } from '../../commonComponents/const';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { toggleOpenChatInfo, toggleOpenProfileDocument, toggleOpenProfileMedia } from '../../redux/features/chatSlice';
import { useDispatch } from 'react-redux';

const ChatProfileInfo = ({itemData}) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const closeChatInfoHandle = () => {
        dispatch(toggleOpenChatInfo());
    };
    const mediaHandle = () => {
        dispatch(toggleOpenProfileMedia());
        dispatch(toggleOpenChatInfo());
    };
    const documentHandle = () => {
        dispatch(toggleOpenProfileDocument());
        dispatch(toggleOpenChatInfo());
    };

    return (
        <>
            <Box
                sx={{
                    background: "#fff",
                    minHeight: "100%",
                    position: "relative",
                    borderRadius: '10px',
                    py: 4,
                    px: 2,
                    overflowY: 'auto',
                    maxHeight: 'calc(100vh - 230px)',
                    '&::-webkit-scrollbar': {
                        width: '6px',
                        borderRadius: '4px'
                    },
                    '&::-webkit-scrollbar-track': {
                        backgroundColor: 'transparent',
                        borderRadius: '4px'
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: '#9C9C9C',
                        borderRadius: '4px'
                    },
                    '@media (max-width:1600px) and (min-width:1200px)': {
                        maxHeight: 'calc(100vh - 82px)',
                    },
                    [theme.breakpoints.down('lg')]: {
                        maxHeight: 'calc(100vh - 74px)',
                        px: 2
                    }
                }}
            >
                <IconButton aria-label="close"
                    onClick={closeChatInfoHandle}
                    sx={{
                        position: "absolute",
                        right: "10px",
                        top: "10px",
                        color: 'lightSecondary.main',
                    }}
                >
                    <CloseIcon
                        sx={{
                            fontSize: '26px'
                        }}
                    />
                </IconButton>
                <ListItemAvatar
                    sx={{
                        width: "200px",
                        margin: "0 auto",
                        [theme.breakpoints.down('xl')]: {
                            width: '150px',
                        }
                    }}
                >
                    <Avatar
                        sx={{
                            color: 'success.main',
                            bgcolor: 'success.lighter',
                            width: '200px',
                            height: '200px',
                            [theme.breakpoints.down('xl')]: {
                                width: '150px',
                                height: '150px',
                            }
                        }}
                        src={getImages('avatar-1.png')}
                    >

                    </Avatar>
                </ListItemAvatar>
                <Typography variant='h4'
                    sx={{
                        color: 'secondary.main',
                        fontSize: "1.8rem",
                        fontWeight: "700",
                        textAlign: 'center',
                        mt: 2,
                        mb: 3,
                        [theme.breakpoints.down('xl')]: {
                            fontSize: '1.4rem'
                        }
                    }}
                >
                    Messiah Rozo
                </Typography>
                <Box
                    sx={{
                        mb: 2
                    }}
                >
                    <Typography variant='h4'
                        sx={{
                            color: 'ltheme.main',
                            fontSize: "0.9rem",
                            fontWeight: "600",
                        }}
                    >
                        Email
                    </Typography>
                    <Typography variant='h5'
                        sx={{
                            color: 'lightSecondary.main',
                            fontSize: "0.9rem",
                            fontWeight: "600",
                        }}
                    >
                        cody0703@chainofthings.com
                        <ContentCopyIcon
                            sx={{
                                fontSize: '14px',
                                verticalAlign: "-2px",
                                cursor: "pointer",
                                ml: 1
                            }}
                        />
                    </Typography>
                </Box>
                <Box
                    sx={{
                        mb: 2
                    }}
                >
                    <Typography variant='h4'
                        sx={{
                            color: 'ltheme.main',
                            fontSize: "0.9rem",
                            fontWeight: "600",
                        }}
                    >
                        Phone
                    </Typography>
                    <Typography variant='h5'
                        sx={{
                            color: 'lightSecondary.main',
                            fontSize: "0.9rem",
                            fontWeight: "600",
                        }}
                    >
                        (0281) 212-51234
                        <ContentCopyIcon
                            sx={{
                                fontSize: '14px',
                                verticalAlign: "-2px",
                                cursor: "pointer",
                                ml: 1
                            }}
                        />
                    </Typography>
                </Box>
                {/* Media */}
                <Box
                    sx={{
                        mb: 3
                    }}
                >
                    <Stack
                        sx={{
                            display: "flex",
                            flexDirection: "inherit",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%",
                            marginBottom: "10px"
                        }}
                    >
                        <Typography variant="h6" color="textSecondary"
                            sx={{
                                fontSize: "1.1rem",
                                fontWeight: "600",
                                color: "secondary.main"
                            }}
                        >
                            Media
                        </Typography>
                        <Button variant="text"
                            onClick={mediaHandle}
                            style={{ marginTop: "0" }}
                            sx={{
                                fontSize: "0.9rem",
                                fontWeight: "600",
                                textTransform: "inherit",
                                p: 0
                            }}
                        >
                            Show more
                        </Button>
                    </Stack>
                    <Box>
                        <ImageList
                            sx={{
                                width: "100%",
                                gap: 10,
                                m: 0
                            }}
                            style={{gap: '7px',}}
                            cols={3}>
                            {itemData?.slice(0, 3).map((item, index) => (
                                <ImageListItem
                                    key={item.img}
                                    sx={{
                                        position: "relative",
                                        borderRadius: "10px",
                                        overflow: "hidden"
                                    }}
                                >
                                    <img
                                        // srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                        src={`${item.img}`}
                                        alt=""
                                        loading="lazy"
                                    />
                                    {index === 2 ?
                                        <Box
                                            sx={{
                                                position: "absolute",
                                                left: 0,
                                                right: 0,
                                                bottom: 0,
                                                top: 0,
                                                zIndex: 1,
                                                background: 'rgb(0 0 0 / 30%)'
                                            }}
                                        >
                                            <Typography variant='h6'
                                                sx={{
                                                    fontSize: "1rem",
                                                    fontWeight: "700",
                                                    top: "50%",
                                                    position: "absolute",
                                                    transform: "translate(0, -50%)",
                                                    left: "0",
                                                    width: "100%",
                                                    textAlign: "center",
                                                    color: "#fff"
                                                }}
                                            >+{itemData.length - 3}</Typography>
                                        </Box> : ""
                                    }
                                </ImageListItem>
                            ))}
                        </ImageList>
                    </Box>
                </Box>
                {/* Documents */}
                <Box
                    sx={{
                        mb: 2
                    }}
                >
                    <Stack
                        sx={{
                            display: "flex",
                            flexDirection: "inherit",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%",
                            marginBottom: "5px"
                        }}
                    >
                        <Typography variant="h6" color="textSecondary"
                            sx={{
                                fontSize: "1.1rem",
                                fontWeight: "600",
                                color: "secondary.main"
                            }}
                        >
                            Documents
                        </Typography>
                        <Button variant="text"
                            onClick={documentHandle}
                            style={{ marginTop: "0" }}
                            sx={{
                                fontSize: "0.9rem",
                                fontWeight: "600",
                                textTransform: "inherit",
                                p: 0
                            }}
                        >
                            Show more
                        </Button>
                    </Stack>
                    <List
                        sx={{
                            p: 0,
                            '& .MuiListItem-root + .MuiListItem-root': {
                                borderTop: "solid 1px",
                                borderTopColor: "ltheme.main"
                            }
                        }}
                    >
                        <ListItem
                            sx={{
                                px: "0"
                            }}
                        >
                            <ListItemAvatar sx={{ textAlign: "center" }}>
                                <img style={{ maxWidth: "40px", maxHeight: "40px" }} src={getImages('pdf_icon.png')} />
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <Typography noWrap
                                        sx={{
                                            fontSize: "0.9rem",
                                            fontWeight: '600',
                                            color: 'secondary.main',
                                            lineHeight: '22px'
                                        }}
                                        variant="subtitle1">Solutions
                                    </Typography>}
                                secondary={
                                    <Typography
                                        noWrap
                                        variant="body2"
                                        sx={{
                                            fontSize: '12px',
                                            fontWeight: '500',
                                            color: 'ltheme.main',
                                            width: "100%"

                                        }}
                                    >Pdf • 3.8 mb</Typography>}
                            />
                            <Box>
                                <IconButton aria-label="delete">
                                    <FileDownloadOutlinedIcon
                                        sx={{
                                            color: "lightSecondary.main"
                                        }}
                                    />
                                </IconButton>
                            </Box>
                        </ListItem>
                        <ListItem
                            sx={{
                                px: "0"
                            }}
                        >
                            <ListItemAvatar sx={{ textAlign: "center" }}>
                                <img style={{ maxWidth: "40px", maxHeight: "40px" }} src={getImages('pdf_icon.png')} />
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <Typography noWrap
                                        sx={{
                                            fontSize: "0.9rem",
                                            fontWeight: '600',
                                            color: 'secondary.main',
                                            lineHeight: '22px'
                                        }}
                                        variant="subtitle1">Solutions
                                    </Typography>}
                                secondary={
                                    <Typography
                                        noWrap
                                        variant="body2"
                                        sx={{
                                            fontSize: '12px',
                                            fontWeight: '500',
                                            color: 'ltheme.main',
                                            width: "100%"

                                        }}
                                    >Pdf • 3.8 mb</Typography>}
                            />
                            <Box>
                                <IconButton aria-label="download">
                                    <FileDownloadOutlinedIcon
                                        sx={{
                                            color: "lightSecondary.main"
                                        }}
                                    />
                                </IconButton>
                            </Box>
                        </ListItem>
                    </List>
                </Box>
            </Box>
        </>
    )
}

export default ChatProfileInfo