import { combineReducers } from 'redux';
// Import feature reducers
import chatReducer from '../features/chatSlice';
import drawerReducer from '../features/drawerSlice';
import userReducer from '../features/userSlice';
// Import API reducers
import { apiReducers } from './apiMiddleware';
import kanbanReducer from '../features/kanbanSlice';
import refetchReducer from '../features/refetch';
import solutionsReducer from '../features/inboxSolutions';
import driveReducer from '../features/driveSlice';
import loadingReducer from '../features/loadingSLice';
import loaderReducer from '../features/loader';
import boardReducer from '../features/boardSlice';
import inboxReducer from '../features/inboxSlice';

const rootReducer = combineReducers({
  ...apiReducers, // Spread API reducers
  drawer: drawerReducer,
  chat: chatReducer,
  user: userReducer,
  kanban: kanbanReducer,
  refetch: refetchReducer,
  solutions: solutionsReducer,
  drive: driveReducer,
  loading: loadingReducer,
  loader: loaderReducer,
  board: boardReducer,
  inbox: inboxReducer,
});

export default rootReducer; 
