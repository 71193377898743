import { fetchBaseQuery, createApi } from '@reduxjs/toolkit/query/react';


const baseUrl = process.env.REACT_APP_base_URL;

export const teamMemberList  = createApi({
    reducerPath: 'teamMemberList',
    baseQuery: fetchBaseQuery({
      baseUrl: baseUrl,
      prepareHeaders: (headers) => {
        return headers;
      },
    }),
    endpoints: (builder) => ({
      fetchTeamMemberList: builder.query({
        query: ({userId , searchParams}) => ({
          url: `users-collaborator/team-members/${userId}?search=${searchParams}`,
          method: 'GET',
        }),
        transformResponse: (response) => {

          if(response.data.length > 0){
            return response.data.map(savedData => ({
              first_name: savedData?.invite_to?.first_name || "",
              last_name: savedData?.invite_to?.last_name|| "",
              email: savedData?.invite_to?.email|| "",
              isAdmin: savedData?.isAdmin || false
            }));
          }else{
            return  response = [];
          }
     
        },
        refetchOnWindowFocus: true,
        onError: (error) => {
          console.error('Fetch user details error:', error);
        },
      })
    })
})


export const { useFetchTeamMemberListQuery } = teamMemberList;
export default teamMemberList;