import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  Typography,
  Box,
  List,
  ListItemAvatar,
  ListItemButton,
  Stack,
  Avatar,
  Badge,
  ListItemText,
  ListItem,
  AvatarGroup,
  InputBase,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Chip,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import { getImages } from "../../commonComponents/const";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import MainCard from "../../commonComponents/MainCard";
import { styled } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setChatSelected,
  setOpenChat,
  toggleChatData,
} from "../../redux/features/chatSlice";
import Modals from "../../commonComponents/Modals";
import ScheduleMeetingModal from "./ScheduleNewMeetingsModal";
import { toast } from "react-toastify";
import { useFetchUpcomingMeetingListQuery } from "../../redux/api/Meetings/upComingMeetingList";
import { hideLoader, showLoader } from "../../redux/features/loader";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  resetRefetchMeetingList,
  resetRefetchQuickDocsList,
  resetRefetchRecentSolutionList,
} from "../../redux/features/refetch";
import { useFetchRecentSolutionListQuery } from "../../redux/api/Meetings/recentSolutions";
import { useFetchMeetingUserListQuery } from "../../redux/api/Meetings/getMeetingUserList";
import ReRescheduleMeetingModal from "./RescheduleMeetingModal";
import DeleteMeetingAlert from "./deleteMeetingModal";
import { useFetchRecentMeetingsListQuery } from "../../redux/api/Meetings/recentInvitation";
import { useAcceptMeetingInvitationMutation } from "../../redux/api/Meetings/AcceptMeeting";
import { useRejectMeetingInvitationMutation } from "../../redux/api/Meetings/RejectMeeting";

const ITEM_HEIGHT = 48;
const options = ["None", "Atria", "Callisto", "Dione", "Ganymede"];
const Meetings = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const userId = localStorage.getItem("userId");
  const chatData = useSelector((state) => state.chat.chatData);

  const showToastMessage = (message, type) => {
    if (type === "success") {
      toast.success(message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (type === "error") {
      toast.error(message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (type === "info") {
      toast.warning(message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
  const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 24,
    height: 24,
    border: `2px solid ${theme.palette.background.paper}`,
  }));
  const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "#DBEBF8",
    "&:hover": {
      backgroundColor: "#DBEBF8",
    },
    marginRight: "0",
    marginLeft: 0,
    marginBottom: 5,
    width: "100%",
    display: "flex", // Ensure the children are aligned properly
    alignItems: "center", // Center the icons and input vertically
  }));

  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    right: "0",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "#106EED",
    width: "100%",
    "& .MuiInputBase-input": {
      padding: theme.spacing(1.7, 0, 1.7, 1.7),
      paddingRight: `calc(1em + ${theme.spacing(4)})`, // Add padding for icons
      transition: theme.transitions.create("width"),
      width: "100%",
      fontSize: "12px",
      fontWeight: "500",
    },
  }));
  // Schedule Meeting modal
  const [openMeetingModal, setMeetingModal] = useState(false);
  const [openReScheduleMeetingModal, setReScheduleMeetingModal] =
    useState(false);
  const [currentMeetingId, setCurrentMeetingId] = useState("");
  const handleMeetingModal = () => {
    setMeetingModal(true);
  };
  const handleReScheduleMeetingModal = (meetingId) => {
    setReScheduleMeetingModal(true);
    setCurrentMeetingId(meetingId);
  };
  const closeMeetingModal = () => {
    setMeetingModal(false);
  };
  const CloseReScheduleMeetingModal = () => {
    setReScheduleMeetingModal(false);
  };
  // Delete list1Alert Modal
  const [openDeleteMeetingAlert, setOpenDeleteMeetingAlert] = useState(false);
  const deleteMeetingAlertHandle = (meetingId) => {
    setOpenDeleteMeetingAlert(true);
    setReScheduleMeetingModal(false);
    setCurrentMeetingId(meetingId);
  };
  const closeDeleteMeetingAlert = () => {
    setOpenDeleteMeetingAlert(false);
  };
  const goBackFromAlertHandle = () => {
    setOpenDeleteMeetingAlert(false);
    setReScheduleMeetingModal(true);
  };

  // Dropdown Menu
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
    if (path === "/inbox") {
      dispatch(setChatSelected(true));
      dispatch(setOpenChat(true));
      if (!chatData) {
        dispatch(toggleChatData());
      }
    }
  };

  const handleChatBox = (path) => {
    navigate(path);
    if (path === "/inbox") {
      dispatch(setChatSelected(true));
      dispatch(setOpenChat(true));
      if (chatData) {
        dispatch(toggleChatData());
      }
    }
  };

  //   fetch recent Solution
  // const {
  //   data: recentSolutionList,
  //   isLoading: recentSolutionListLoading,
  //   error: recentSolutionListError,
  //   refetch: recentSolutionListList,
  // } = useFetchRecentSolutionListQuery({
  //   userId: userId,
  // });
  // const refetchRecentSolutionList = useSelector(
  //   (state) => state.refetch.refetchRecentSolutionList
  // );
  // useEffect(() => {
  //   if (refetchRecentSolutionList) {
  //     recentSolutionListLoading().finally(() => {
  //       dispatch(resetRefetchRecentSolutionList());
  //     });
  //   }
  // }, [refetchRecentSolutionList, recentSolutionListLoading, dispatch]);
  // set search params
  // const {
  //   data: DocumentList,
  //   isLoading: DocumentLoading,
  //   error: DocumentError,
  //   refetch: refetchDocument,
  // } = useFetchQuickDocumentListQuery({
  //   userId: userId,
  //   searchParam: searchValue,
  // });
  // const refetchQuickDocumentList = useSelector(
  //   (state) => state.refetch.refetchQuickDocsList
  // );
  // useEffect(() => {
  //   if (refetchQuickDocumentList) {
  //     refetchDocument().finally(() => {
  //       dispatch(resetRefetchQuickDocsList());
  //     });
  //   }
  // }, [refetchUpComingMeetingList, refetchDocument, dispatch]);

  const [searchParams, setSearchParams] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const {
    data: upcomingMeetingList,
    isLoading: upcomingMeetingLoading,
    error: upcomingMeetingError,
    refetch: refetchUpComingList,
  } = useFetchUpcomingMeetingListQuery({
    userId: userId,
    searchParam: searchValue,
  });
  const {
    data: recentInvitationsList,
    isLoading: recentInvitationsLoading,
    error: recentInvitationsError,
    refetch: refetchRecentInvitationsList,
  } = useFetchRecentMeetingsListQuery({
    userId: userId,
    searchParam: "",
  });

  const refetchUpComingMeetingList = useSelector(
    (state) => state.refetch.refetchMeetingList
  );
  useEffect(() => {
    if (refetchUpComingMeetingList) {
      refetchUpComingList().finally(() => {
        dispatch(resetRefetchMeetingList());
      });
    }
  }, [refetchUpComingMeetingList, refetchUpComingList, dispatch]);

  const handleClearSearch = () => {
    setSearchParams("");
    setSearchValue("");
    refetchUpComingList();
  };

  const handleInputChange = (event) => {
    setSearchParams(event.target.value);
  };

  const handleMeetingSearch = async (event) => {
    if (event.key === "Enter") {
      const inputValue = event.target.value.trim();
      if (!inputValue) {
        setSearchValue("");
        refetchUpComingList();
        return;
      }
      setSearchValue(inputValue);
      dispatch(showLoader());
      try {
        await refetchUpComingList();
        if (upcomingMeetingError) {
          const errorMessage = upcomingMeetingList?.message || "No Data Found";
          showToastMessage(errorMessage, "error");
          return;
        }
      } catch (error) {
        showToastMessage("An error occurred during search", "error");
      } finally {
        // Hide loader after refetch is complete
        dispatch(hideLoader());
      }
    }
  };
  const [
    acceptMeeting,
    { isLoading: isAcceptMeetingLoading, isError: isAcceptMeetingError },
  ] = useAcceptMeetingInvitationMutation();
  const [
    rejectMeeting,
    { isLoading: isRejectMeetingLoading, isError: isRejectMeetingError },
  ] = useRejectMeetingInvitationMutation();
  const handleAccept = async (meetingId) => {
    try {
      const response = await acceptMeeting({
        userId,
        meetingId: meetingId,
      }).unwrap();

      if (response.statusCode === 200) {
        showToastMessage("Invitation accepted", "success");
        refetchRecentInvitationsList();
        refetchUpComingList();
        dispatch(showLoader());
      } else {
        showToastMessage("Failed to accept invitation", "error");
      }
      handleClose();
    } catch (error) {
      showToastMessage("Failed to accept invitation", "error");
    } finally {
      // Hide the loader when the API call is finished, regardless of the outcome
      dispatch(hideLoader());
    }
  };
  const handleReject = async (meetingId) => {
    try {
      const response = await rejectMeeting({
        userId,
        meetingId: meetingId,
      }).unwrap();

      if (response.statusCode === 200) {
        showToastMessage("Invitation rejected", "success");
        refetchUpComingList();
        refetchRecentInvitationsList();
        dispatch(showLoader());
      } else {
        showToastMessage("Failed to reject invitation", "error");
      }
      handleClose();
    } catch (error) {
      showToastMessage("Failed to reject invitation", "error");
    } finally {
      // Hide the loader when the API call is finished, regardless of the outcome
      dispatch(hideLoader());
    }
  };
  const getDayOfWeek = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-US", { weekday: "short" }).format(date);
  };

  const getDayOfMonth = (dateString) => {
    const date = new Date(dateString);
    return date.getDate();
  };

  // const getMonthName = (dateString) => {
  //   const date = new Date(dateString);
  //   return new Intl.DateTimeFormat("en-US", { month: "short" }).format(date); // Example: "Jan"
  // };

  // const getYear = (dateString) => {
  //   const date = new Date(dateString);
  //   return date.getFullYear(); // Example: 2023
  // };
  const formatDate = (dateString) => {
    const date = new Date(dateString);

    // Format the date in the desired format: "Wed Sep 4, 2024"
    return new Intl.DateTimeFormat("en-US", {
      weekday: "short", // "Wed"
      month: "short", // "Sep"
      day: "numeric", // "4"
      year: "numeric", // "2024"
    }).format(date);
  };

  const getMonthName = (dateString) => {
    const date = new Date(dateString);
    const currentMonth = new Date().getMonth(); // Get current month (0-11)
    const dateMonth = date.getMonth(); // Get the month of the given date (0-11)

    if (dateMonth === currentMonth) {
      return "This Month";
    } else {
      return new Intl.DateTimeFormat("en-US", { month: "long" }).format(date);
    }
  };

  useEffect(() => {
    if (upcomingMeetingLoading) {
      dispatch(showLoader());
    } else {
      dispatch(hideLoader());
    }
  }, [upcomingMeetingLoading, dispatch]);

  return (
    <>
      <Grid
        container
        mt={0.5}
        rowSpacing={0.1}
        columnSpacing={3}
        sx={{
          mb: 2,
        }}
      >
        <Grid
          item
          xs={12}
          md={6}
          lg={7}
          sx={{
            [theme.breakpoints.down("md")]: {
              mb: 2,
            },
          }}
        >
          <MainCard
            content={false}
            sx={{
              backgroundColor: "#ECF6FF",
              border: "none",
              minHeight: "925px",
              padding: "15px 25px 30px",
              "@media (max-width:1600px) and (min-width:1200px)": {
                padding: "15px 25px 15px",
              },
              [theme.breakpoints.down("md")]: {
                padding: "12px",
              },
            }}
          >
            <Typography
              variant="h6"
              color="textSecondary"
              sx={{
                fontSize: "1.2rem",
                fontWeight: "600",
                color: "secondary.main",
                mb: 1,
              }}
            >
              Upcoming Meetings
            </Typography>
            <Search>
              <StyledInputBase
                placeholder="Search"
                inputProps={{ "aria-label": "search" }}
                value={searchParams}
                onChange={handleInputChange}
                onKeyDown={handleMeetingSearch}
                autoFocus
              />
              {searchParams ? (
                <IconButton
                  sx={{ position: "absolute", right: 0 }}
                  onClick={handleClearSearch}
                >
                  <CancelIcon sx={{ color: "lightSecondary.main" }} />
                </IconButton>
              ) : (
                <SearchIconWrapper>
                  <SearchIcon sx={{ color: "lightSecondary.main" }} />
                </SearchIconWrapper>
              )}
            </Search>
            <List
              component="nav"
              sx={{
                px: 0,
                py: 0,
              }}
            >
              <Grid
                container
                mt={0.5}
                rowSpacing={1.9}
                columnSpacing={1}
                style={{ cursor: "pointer" }}
              >
                {upcomingMeetingList?.data?.length > 0 ? (
                  upcomingMeetingList?.data?.map((monthData) => (
                    <Grid item xs={12} md={12} lg={12} key={monthData?._id}>
                      <Grid item xs={12} md={12} lg={12}>
                        <Typography
                          variant="h6"
                          color="textSecondary"
                          sx={{
                            fontSize: "1rem",
                            fontWeight: "600",
                            color: "secondary.main",
                            padding: "6px",
                          }}
                        >
                          {getMonthName(monthData.month)}
                        </Typography>
                      </Grid>
                      {monthData?.meetings?.map((meetingList) => (
                        <Grid
                          container
                          onClick={() =>
                            handleReScheduleMeetingModal(meetingList._id)
                          }
                        >
                          {/* <Grid item xs={12} md={2} lg={2}>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: "10px",
                                height: "100%",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "600",
                                  color: "ltheme.main",
                                  textAlign: "center",
                                  textTransform: "uppercase",
                                  lineHeight: "1",
                                }}
                                variant="subtitle1"
                              >
                                
                              </Typography> */}
                          {/* <Typography
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "600",
                                  color: "ltheme.main",
                                  textAlign: "center",
                                  textTransform: "uppercase",
                                  lineHeight: "1",
                                }}
                                variant="subtitle1"
                              >
                                {getDayOfMonth(meetingList.date)}
                              </Typography> */}
                          {/* </Box> */}
                          {/* </Grid> */}
                          <Grid item xs={12} md={12} lg={12}>
                            <ListItem
                              sx={{
                                minHeight: "40px",
                                alignItems: "center",
                                py: "5px",
                                background: "#FFFFFF",
                                borderRadius: "10px",
                                marginTop: "8px",
                              }}
                            >
                              <ListItemAvatar
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  marginRight: "7px",
                                }}
                              >
                                <Avatar
                                  sx={{
                                    bgcolor: "lightSecondary.main",
                                    px: 2.8,
                                    fontSize: "1rem",
                                    fontWeight: "700",
                                    width: "45px",
                                    height: "45px",
                                    borderRadius: "10px",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    lineHeight: "1.2",
                                    [theme.breakpoints.down("md")]: {
                                      width: "30px",
                                      height: "30px",
                                      fontSize: "0.6rem",
                                    },
                                  }}
                                  variant="rounded"
                                >
                                  {/* {meetingList.title
                                  .split(" ")
                                  .slice(0, 3)
                                  .map((word) => word.charAt(0).toUpperCase())
                                  .join("")} */}
                                  <span>{getDayOfWeek(meetingList.date)}</span>
                                  <span>{getDayOfMonth(meetingList.date)}</span>
                                </Avatar>
                              </ListItemAvatar>

                              <Box sx={{ width: "100%" }}>
                                <ListItemText
                                  primary={
                                    <Typography
                                      noWrap
                                      sx={{
                                        fontSize: "0.9rem",
                                        fontWeight: "600",
                                        color: "secondary.main",
                                        lineHeight: "20px",
                                      }}
                                      variant="h6"
                                    >
                                      {meetingList.title}
                                    </Typography>
                                  }
                                  secondary={
                                    <Box sx={{ width: "100%" }}>
                                      <Typography
                                        noWrap
                                        variant="body2"
                                        sx={{
                                          fontSize: "12px",
                                          fontWeight: "500",
                                          color: "ltheme.main",
                                          width: "100%",
                                        }}
                                      >
                                        {meetingList.start_time} -{" "}
                                        {meetingList.end_time}
                                      </Typography>
                                      <Typography
                                        noWrap
                                        variant="body2"
                                        sx={{
                                          fontSize: "12px",
                                          fontWeight: "500",
                                          color: "ltheme.main",
                                          maxWidth:'80%',
                                          width: "100%",
                                        }}
                                      >
                                        <Tooltip
                                          title={meetingList.description}
                                        >
                                          {meetingList.description}
                                        </Tooltip>
                                      </Typography>
                                    </Box>
                                  }
                                />
                              </Box>
                            </ListItem>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  ))
                ) : (
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      color="textSecondary"
                      sx={{
                        fontSize: "1.2rem",
                        fontWeight: "600",
                        color: "secondary.main",
                        textAlign: "center",
                        mt: 2,
                        py: "25%",
                      }}
                    >
                      You have no meetings scheduled!!
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </List>
          </MainCard>
        </Grid>
        <Grid item xs={12} md={6} lg={5}>
          <MainCard
            content={false}
            sx={{
              backgroundColor: "#ECF6FF",
              border: "none",
              padding: "15px 25px 30px",
              minHeight: "380px",
              mb: 2,
              "@media (max-width:1600px) and (min-width:1200px)": {
                padding: "15px 25px 30px",
              },
              [theme.breakpoints.down("md")]: {
                padding: "12px",
              },
            }}
          >
            <Button
              variant="contained"
              sx={{
                width: "100%",
                textTransform: "inherit",
                padding: "10px",
                boxShadow: "none",
                "&:hover": {
                  boxShadow: "none",
                },
                [theme.breakpoints.down("md")]: {},
              }}
              onClick={handleMeetingModal}
            >
              <VideocamOutlinedIcon
                sx={{ width: "20px", height: "20px", marginRight: "8px" }}
              />
              Schedule New Meeting
            </Button>
            <Typography
              variant="h6"
              color="textSecondary"
              sx={{
                fontSize: "1.2rem",
                fontWeight: "600",
                color: "secondary.main",
                marginBottom: "0.3rem",
                marginTop: "0.4rem",
              }}
            >
              Calendar
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateCalendar
                sx={{
                  width: "100%",
                  minHeight: "350",
                  "& .MuiDayCalendar-weekDayLabel": {
                    fontSize: "0.9rem",
                    width: "50px",
                    height: "45px",
                    color: "ltheme.main",
                    fontWeight: "600",
                  },
                  "& .MuiPickersDay-root": {
                    fontSize: "0.9rem",
                    width: "50px",
                    height: "45px",
                    color: "secondary.main",
                    fontWeight: "600",
                  },
                  "& .MuiPickersDay-today": {
                    background: "#106EED",
                    border: "none",
                    color: "#fff",
                    width:'50px',
                    height:'45px'
                  },
                }}
              />
            </LocalizationProvider>
          </MainCard>
          {/* Recent Invitations */}
          <MainCard
            content={false}
            sx={{
              backgroundColor: "#ECF6FF",
              border: "none",
              padding: "15px 10px",
              minHeight: "448px",
              [theme.breakpoints.down("md")]: {
                padding: "12px 10px",
              },
            }}
          >
            <Box sx={{ p: 0, pb: 0 }}>
              <Stack
                spacing={2}
                sx={{
                  display: "flex",
                  flexDirection: "inherit",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "17px",
                  width: "100%",
                  // marginBottom: "10px",
                }}
              >
                <Typography
                  variant="h6"
                  color="textSecondary"
                  sx={{
                    fontSize: "1.2rem",
                    fontWeight: "600",
                    color: "secondary.main",
                    // marginBottom: "1rem",
                  }}
                >
                  Recent Invitations
                </Typography>
                {/* <Button
                  onClick={() => handleNavigation("")}
                  variant="text"
                  style={{ marginTop: "0" }}
                  sx={{
                    fontSize: "12px",
                    fontWeight: "600",
                    textTransform: "inherit",
                  }}
                >
                  View all
                  <ArrowForwardIosOutlinedIcon
                    sx={{
                      marginLeft: "5px",
                      fill: "primary.main",
                      width: "12px",
                      height: "12px",
                    }}
                  />
                </Button> */}
              </Stack>

              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                sx={{
                  [theme.breakpoints.down("md")]: {
                    mb: 2,
                  },
                }}
              >
                <List
                  component="nav"
                  sx={
                    {
                      // px: "10%",
                      // py: "25%",
                    }
                  }
                >
                  {/* <ListItem sx={{ px: "0" }}>
                  
                  <ListItemAvatar
                    sx={{
                      textAlign: "center",
                      "& img": {
                        maxWidth: "35px",
                        maxHeight: "45px",
                        borderRadius: "10px",
                        [theme.breakpoints.down("md")]: {
                          maxWidth: "30px",
                          maxHeight: "30px",
                        },
                      },
                    }}
                  >
                    <Avatar
                      sx={{
                        bgcolor: "lightSecondary.main",
                        px: 2.8,
                        fontSize: "0.8rem",
                        fontWeight: "700",
                        width: "45px",
                        height: "45px",
                        borderRadius: "10px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        lineHeight: "1.2",
                        [theme.breakpoints.down("md")]: {
                          width: "30px",
                          height: "30px",
                          fontSize: "0.6rem",
                        },
                      }}
                      variant="rounded"
                    >
                     
                      <span>21 Sep</span>
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Box sx={{ display: "flex",  gap: "10px" }}>
                      <Typography
                        noWrap
                        sx={{
                          fontSize: "1rem",
                          fontWeight: "600",
                          color: "secondary.main",
                          lineHeight: "22px",
                        }}
                        variant="subtitle1"
                      >
                        Meeting Title
                      </Typography>
                      
                      </Box>
                    }
                    secondary={
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                        {" "}
                        
                        {["Aman", "John"].map(
                          (
                            name,
                            index 
                          ) => (
                            <Chip
                              key={index} 
                              label={name}
                              sx={{
                                fontSize: "12px",
                                fontWeight: "500",
                                backgroundColor: "#DBEBF8",
                                color: "secondary.main",
                                maxWidth: "80px",
                                width:'100%',
                                height: "26px",
                              }}
                            />
                          )
                        )}
                      </Box>
                    }
                  />
                  <Box sx={{
                    display:"flex",
                    flexDirection:"column",
                    
                  }}>
                      <Button
                  onClick={() => handleNavigation("")}
                  variant="text"
                  sx={{
                    fontSize: "12px",
                    fontWeight: "600",
                    mb:'3px',
                    textTransform: "inherit",
                    p:"0px 0px"
                  }}
                >
                  view more
                  <ArrowForwardIosOutlinedIcon
                    sx={{
                      marginLeft: "5px",
                      fill: "primary.main",
                      width: "8px",
                      height: "8px",
                    }}
                  />
                </Button>
                    <Box sx={{ display: "flex",  gap:  "5px", mb:'5px',}}>
                      <Chip 
                      label="Yes" 
                      sx={{
                        fontSize: "12px",
                        fontWeight: "500",
                        backgroundColor: "lightSecondary.main",
                        color: "#fff",
                        maxWidth: "60px",
                        maxWidth: "80px",
                                width:'100%',
                                height: "26px",
                      }}
                      onClick={handleClick} 
                      />
                      <Chip 
                      label="No" 
                      sx={{
                        fontSize: "12px",
                        fontWeight: "500",
                        backgroundColor: "lightSecondary.main",
                        color: "#fff",
                        maxWidth: "80px",
                                width:'100%',
                                height: "26px",
                      }}
                      onClick={handleClick} 
                      />
                      </Box>
                     
                  </Box>
                 
                </ListItem> */}
                </List>
                <Grid item xs={12} md={12} lg={12}>
                  {recentInvitationsList?.data &&
                    recentInvitationsList?.data?.length > 0 &&
                    recentInvitationsList?.data?.map((recentMeeting, index) => (
                      <ListItem
                        key={recentMeeting._id} // Add a unique key for each item
                        sx={{
                          width: "100%",
                          minHeight: "100%",
                          maxHeight: "100%",
                          alignItems: "center",
                          paddingLeft: "3px",
                          paddingRight: "3px",
                          background: "#FFFFFF",
                          borderRadius: "10px",
                          display: "flex",
                          gap: "10px",
                          marginTop: "8px",
                        }}
                      >
                        <Box
                          sx={{
                            // bgcolor:'#DCDCDC',
                            width: "15%",
                            // maxWidth: "35px",
                            maxHeight: "100%",
                            height: "120px",
                            borderRadius: "10px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <ListItemAvatar
                            sx={{
                              textAlign: "center",
                              "& img": {
                                maxWidth: "35px",
                                maxHeight: "100px",
                                borderRadius: "10px",
                                // paddingLeft:'13px',
                                // paddingRight:'13px',
                                [theme.breakpoints.down("md")]: {
                                  maxWidth: "30px",
                                  maxHeight: "30px",
                                },
                              },
                            }}
                          >
                            <Avatar
                              sx={{
                                bgcolor: "lightSecondary.main",
                                px: 2.8,
                                fontSize: "0.8rem",
                                fontWeight: "700",
                                width: "45px",
                                minHeight: "45px",
                                maxHeight: "100px",
                                borderRadius: "10px",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                lineHeight: "1.2",
                                [theme.breakpoints.down("md")]: {
                                  width: "30px",
                                  height: "30px",
                                  fontSize: "0.6rem",
                                },
                              }}
                              variant="rounded"
                            >
                              {/* <span>21 Sep</span> */}
                              <span>{getDayOfWeek(recentMeeting.date)}</span>
                              <span>{getDayOfMonth(recentMeeting.date)}</span>
                            </Avatar>
                          </ListItemAvatar>
                        </Box>
                        {/* meeting details */}
                        <Box
                          sx={{
                            width: "85%",
                          }}
                        >
                          <ListItemText
                            primary={
                              <Typography
                                noWrap
                                sx={{
                                  fontSize: "0.9rem",
                                  fontWeight: "600",
                                  color: "secondary.main",
                                  lineHeight: "20px",
                                }}
                                variant="h6"
                              >
                                {recentMeeting.title}
                              </Typography>
                            }
                            secondary={
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "0.2rem",
                                }}
                              >
                                {/* Dynamic description */}
                                <Box
                                  sx={{
                                    width: "100%",
                                    mt: 0.5,
                                    display: "flex",
                                    gap: "10px",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      width: "25%",
                                    }}
                                  >
                                    <Typography
                                      noWrap
                                      variant="body2"
                                      sx={{
                                        fontSize: "12px",
                                        fontWeight: "500",
                                        color: "ltheme.main",
                                        width: "100%",
                                      }}
                                    >
                                      Description
                                    </Typography>
                                  </Box>
                                  <Box
                                    sx={{
                                      width: "75%",
                                    }}
                                  >
                                    <Typography
                                      variant="body2"
                                      sx={{
                                        fontSize: "0.8rem",
                                        fontWeight: "600",
                                        color: "secondary.main",
                                        width: "100%",
                                        // maxWidth: "160px",
                                      }}
                                    >
                                      {recentMeeting.description}
                                    </Typography>
                                  </Box>
                                </Box>
                                {/* When */}
                                <Box
                                  sx={{
                                    width: "100%",
                                    mt: 0.5,
                                    display: "flex",
                                    gap: "10px",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      width: "25%",
                                    }}
                                  >
                                    <Typography
                                      variant="body2"
                                      sx={{
                                        fontSize: "12px",
                                        fontWeight: "500",
                                        color: "ltheme.main",
                                        width: "100%",
                                      }}
                                    >
                                      When
                                    </Typography>
                                  </Box>
                                  <Box
                                    sx={{
                                      width: "75%",
                                      display: "flex",
                                      gap: "5px",
                                      marginTop:"3px"
                                    }}
                                  >
                                    <Typography
                                      variant="body2"
                                      sx={{
                                        fontSize: "10px",
                                        fontWeight: "600",
                                        color: "secondary.main",
                                      }}
                                    >
                                      {formatDate(recentMeeting.date)}
                                    </Typography>
                                    <Box sx={{ display: "flex", gap: "5px" }}>
                                      <Typography
                                        variant="body2"
                                        sx={{
                                          fontSize: "10px",
                                          fontWeight: "600",
                                          color: "secondary.main",
                                        }}
                                      >
                                        {recentMeeting.start_time} {" - "}
                                      </Typography>
                                      <Typography
                                        variant="body2"
                                        sx={{
                                          fontSize: "10px",
                                          fontWeight: "600",
                                          color: "secondary.main",
                                        }}
                                      >
                                        {recentMeeting.end_time}
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Box>
                                {/* Who */}
                                <Box
                                  sx={{
                                    width: "100%",
                                    mt: 0.5,
                                    display: "flex",
                                    gap: "10px",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      width: "25%",
                                    }}
                                  >
                                    <Typography
                                      variant="body2"
                                      sx={{
                                        fontSize: "12px",
                                        fontWeight: "500",
                                        color: "ltheme.main",
                                        width: "100%",
                                      }}
                                    >
                                      Who
                                    </Typography>
                                  </Box>
                                  <Box
                                    sx={{
                                      width: "75%",
                                      display: "flex",
                                      gap: "5px",
                                      flexWrap: "wrap",
                                      flexDirection: "row",
                                    }}
                                  >
                                    <Typography
                                      variant="body2"
                                      sx={{
                                        fontSize: "0.8rem",
                                        fontWeight: "600",
                                        color: "secondary.main",
                                      }}
                                    >
                                      {recentMeeting.owner_id.first_name},
                                    </Typography>
                                    {recentMeeting.participants_user_id.map(
                                      (participant, id) => (
                                        <Typography
                                          variant="body2"
                                          sx={{
                                            fontSize: "0.8rem",
                                            fontWeight: "600",
                                            color: "secondary.main",
                                          }}
                                        >
                                          {participant.first_name},
                                        </Typography>
                                      )
                                    )}
                                  </Box>
                                </Box>
                                {/* Accept or Reject */}
                                <Box
                                  sx={{
                                    display: "flex",
                                    gap: "4px",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      gap: "10px",
                                      marginTop: "5px",
                                    }}
                                  >
                                    <Button
                                      variant="contained"
                                      sx={{
                                        textTransform: "inherit",
                                        fontSize: "12px",
                                        fontWeight: "600",
                                        boxShadow: "none",
                                        "&:hover": {
                                          boxShadow: "none",
                                        },
                                        // bgcolor: "lightSecondary.main",
                                        color: "#fff",
                                        textTransform: "inherit",
                                        minWidth: "50px",
                                        p: "0px 0px",
                                      }}
                                      onClick={() =>
                                        handleAccept(recentMeeting._id)
                                      }
                                    >
                                      Yes
                                    </Button>
                                    <Button
                                      variant="contained"
                                      sx={{
                                        fontSize: "12px",
                                        fontWeight: "600",
                                        boxShadow: "none",
                                        "&:hover": {
                                          boxShadow: "none",
                                          bgcolor: "#C0C0C0",
                                        },
                                        bgcolor: "#DCDCDC",
                                        color: "black",
                                        textTransform: "inherit",
                                        minWidth: "50px",
                                        p: "0px 0px",
                                      }}
                                      onClick={() =>
                                        handleReject(recentMeeting._id)
                                      }
                                    >
                                      No
                                    </Button>
                                  </Box>
                                </Box>
                              </Box>
                            }
                          />
                        </Box>
                      </ListItem>
                    ))}
                </Grid>
              </Grid>
              {/* </List> */}
            </Box>
          </MainCard>
        </Grid>
      </Grid>
      <Modals
        open={openMeetingModal}
        handleClose={closeMeetingModal}
        modalWidth={500}
      >
        <ScheduleMeetingModal
          handleClose={closeMeetingModal}
          showToastMessage={showToastMessage}
        />
      </Modals>
      <Modals
        open={openReScheduleMeetingModal}
        handleClose={CloseReScheduleMeetingModal}
        modalWidth={500}
      >
        <ReRescheduleMeetingModal
          handleClose={CloseReScheduleMeetingModal}
          currentMeetingId={currentMeetingId}
          showToastMessage={showToastMessage}
          deleteMeetingAlertHandle={deleteMeetingAlertHandle}
        />
      </Modals>
      <Modals
        open={openDeleteMeetingAlert}
        handleClose={closeDeleteMeetingAlert}
        modalWidth={450}
      >
        <DeleteMeetingAlert
          handleClose={closeDeleteMeetingAlert}
          currentMeetingId={currentMeetingId}
          goBackFromAlertHandle={goBackFromAlertHandle}
          refetchUpComingList={refetchUpComingList}
          showToastMessage={showToastMessage}
        />
      </Modals>
    </>
  );
};

export default Meetings;
