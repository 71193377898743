import React, { useState } from 'react';
import ProfileStep1 from '../../commonComponents/Profile/ProfileStep1';
import UpdateBusinessProfile from '../../commonComponents/Profile/businessProfile'; // Make sure this is the correct import
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

const BusinessProfile = () => {
  const user = useSelector((state) => state.user.data);
  const userType = localStorage.getItem("userType");

  const showToastMessage = (message, type) => {
    if (type === "success") {
      toast.success(message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (type === "error") {
      toast.error(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const [currentStep, setCurrentStep] = useState(1);

  const nextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const prevStep = () => {
    if (currentStep > 1) {
      setCurrentStep((prevStep) => prevStep - 1);
    }
  };

  const isLastStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  switch (currentStep) {
    case 1:
      return userType? (
        <UpdateBusinessProfile nextStep={nextStep} showToastMessage={showToastMessage} />
      ) : (
        // <ProfileStep1 nextStep={nextStep} showToastMessage={showToastMessage} />
        <></>
      );

    // Uncomment and implement other cases as needed
    // case 2:
    //   return (
    //     <ProfileStep2
    //       prevStep={prevStep}
    //       isLastStep={isLastStep}
    //       showToastMessage={showToastMessage}
    //     />
    //   );

    default:
      return <div>Success</div>;
  }
};

export default BusinessProfile;
