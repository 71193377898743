import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = process.env.REACT_APP_base_URL;

const MoveDocument = createApi({
    reducerPath: 'moveDocument',
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers) => {
            // Add any headers you need here
            return headers;
        },
    }),
    endpoints: (builder) => ({
        MoveDocument: builder.mutation({
            query: ({movedFolderId , selectedFolderId , userId}) => ({
                url: `drive/document-move/${movedFolderId}`, // Replace with your API endpoint URL
                method: 'POST',
                body: { 
                   newParentId: selectedFolderId,
                   owner_id:userId,
            },
            }),
            transformResponse: (response) => {
                return response;
            },
        }),
    }),
});

export const { useMoveDocumentMutation } = MoveDocument;
export default MoveDocument;
