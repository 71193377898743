import { fetchBaseQuery, createApi } from '@reduxjs/toolkit/query/react';


const baseUrl = process.env.REACT_APP_base_URL;
const logoBaseUrl = 'https://bsdawebfiledata.s3.ca-central-1.amazonaws.com/solution/';

export const recentSolutionList  = createApi({
    reducerPath: 'recentSolutionList',
    baseQuery: fetchBaseQuery({
      baseUrl: baseUrl,
      prepareHeaders: (headers) => {
        // Add any headers you need here
        return headers;
      },
    }),
    endpoints: (builder) => ({
      fetchRecentSolutionList: builder.query({
        query: ({userId }) => ({
          url: `meetings/recent-solutions/${userId}`,
          method: 'GET',
        }),
        transformResponse: (response) => {
          // Check if response.data is defined and is an array before accessing its length
          if (response?.data && Array.isArray(response.data) && response.data.length > 0) {
            return response;
          }
          // Handle cases where response.data is not defined or is an empty array
          return { data: [] };
        },
        
        refetchOnWindowFocus: true,
        // retry: RetryOnError,
        onError: (error) => {
          console.error('Fetch UpComingMeeting Details error:', error);
          // Handle error cases as per your application's requirements
        },
      })
    })
})


export const { useFetchRecentSolutionListQuery } = recentSolutionList;
export default recentSolutionList;
