import { createSlice } from '@reduxjs/toolkit';

const InboxSolutionSlice = createSlice({
  name: 'solutions',
  initialState: {
  solutionList:[]
  },
  reducers: {
    getSolutionList:(state, action)=>{
      state.solutionList = action.payload;
    },

  },
});

export const {getSolutionList } = InboxSolutionSlice.actions;
export default InboxSolutionSlice.reducer;
