import React from 'react'
import { Box, FormControl, Typography, RadioGroup, Radio, FormControlLabel } from '@mui/material'
import MainCard from '../../MainCard';
import StepHeader from './StepHeader';
import StepFooter from './StepFooter';
import { useTheme } from '@emotion/react';
const optionsList = [
    'Yes',
    'No',
    'Not Sure',
];
const Step8 = ({ nextStep, currentStep, prevStep }) => {
    const theme = useTheme();
    return (
        <>
            <Box
                sx={{
                    background: '#dbebf8'
                }}
            >
                <Box
                    sx={{
                        py: 20,
                        maxWidth: '1366px',
                        margin: '0 auto',
                       [theme.breakpoints.down('xl')]: {
                            py: 10,
                            px: '20px'
                        },
                    }}
                >
                    <MainCard content={false}
                        sx={{
                            backgroundColor: "#FFFFFF",
                            border: "none",
                            padding: "0",
                            boxShadow: "none",
                            borderRadius: "0"
                        }}
                    >
                        <StepHeader
                            currentStep={currentStep}
                            prevStep={prevStep}
                        />
                        <Box
                            sx={{
                                p: 3,
                                pb: 5
                            }}
                        >
                            <Box
                                sx={{
                                    maxWidth: '700px',
                                    margin: '0 auto'
                                }}
                            >

                                <Typography variant='h4'
                                    sx={{
                                        fontSize: '1.8rem',
                                        color: 'secondary.main',
                                        fontWeight: '700',
                                        textAlign: 'center',
                                        mb: 7,
                                        position: 'relative',
                                        '&:before': {
                                            content: '""',
                                            position: 'absolute',
                                            left: '50%',
                                            bottom: '-22px',
                                            height: '3px',
                                            borderRadius: '3px',
                                            width: '85px',
                                            transform: 'translate(-50%, 0)',
                                            backgroundColor: 'secondary.main'
                                        },
                                        [theme.breakpoints.down('md')]: {
                                            fontSize: '1.4rem',
                                            mb: 5,
                                            '&:before': {
                                                bottom: '-15px',
                                            }
                                        },
                                        [theme.breakpoints.down('sm')]: {
                                            fontSize: '1.2rem',
                                        },
                                    }}
                                >Are there any specific regulations or compliance standards you need to adhere to?</Typography>
                                <FormControl>
                                    <RadioGroup
                                        aria-labelledby="demo-radio-buttons-optionsList-label"
                                        name="radio-buttons-optionsList"
                                    >
                                        {optionsList.map((item, index) => (
                                            <FormControlLabel key={index}
                                                sx={{
                                                    '& .MuiTypography-root': {
                                                        color: 'secondary.main',
                                                        fontSize: '14px',
                                                        fontWeight: '600',
                                                        padding: '6px 0',
                                                        mr: 0
                                                    }
                                                }}
                                                value={item} control={
                                                    <Radio
                                                        sx={{
                                                            py: 0,
                                                            color: 'secondary.main',
                                                            '& .MuiSvgIcon-root': {
                                                                color: 'secondary.main',
                                                            }
                                                        }}
                                                    />} label={item} />
                                        ))}
                                    </RadioGroup>
                                </FormControl>
                                <StepFooter nextStep={nextStep} />
                            </Box>
                        </Box>
                    </MainCard>
                </Box>
            </Box>
        </>
    )
}

export default Step8