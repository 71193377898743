import { fetchBaseQuery, createApi } from '@reduxjs/toolkit/query/react';


const baseUrl = process.env.REACT_APP_base_URL;
const logoBaseUrl = process.env.REACT_APP_S3_BUCKET_URL;

export const getAllSolutionList  = createApi({
    reducerPath: 'getAllSolutionList',
    baseQuery: fetchBaseQuery({
      baseUrl: baseUrl,
      prepareHeaders: (headers) => {
        // Add any headers you need here
        return headers;
      },
    }),
    endpoints: (builder) => ({
      fetchSolutionList: builder.query({
        query: ({userId , industryId , useCaseIds , limit , page , searchValue, recommended, boardId}) => ({
          url: `solution/${userId}?industry=${industryId}&usecase=${useCaseIds}&page=${page}&limit=${limit}&search=${searchValue}&recommended=${recommended}&board_id=${boardId}`,
          method: 'GET',
        }),
        transformResponse: (response) => {
          const solutions = response?.data?.solutions || [];
          const invitedSolutionsId = response?.data?.invitedSolutionList || []; 
        
          const transformedSolutions = solutions.map(savedData => ({
            solutionId: savedData?._id || "",
            userId: savedData?.created_by || "",
            solutionName: savedData?.solution_name || "",
            slug: savedData?.slug || "",
            status: savedData?.status || false,
            tagline: savedData?.tagline || "",
            focusIndustry: savedData?.focus_industry || [],
            userCase: savedData?.user_case || {},
            shortOverview: savedData?.short_overview || "",
            overview: savedData?.overview || "",
            benefit: savedData?.benefits || [],
            keyFeatures: savedData?.key_features || [],
            priceRange: savedData?.price_range || "Undisclosed",
            solutionProviderId: savedData?.solution_provider_id || "",
            trial: savedData?.trial || "No",
            logo: savedData?.logo ? `${logoBaseUrl}${savedData.logo}` : '',
            isFeatured: savedData?.is_featured || "No",
            updatedAt: savedData?.updated_at || "",
            createdAt: savedData?.created_at || "",
            headquarters: savedData?.head_quarters || "",
            useCaseIds: savedData?.use_case_ids || [],
            useCaseNames: (savedData?.use_case_ids || []).map(useCase => useCase.title || ""),
            sourceLink: savedData?.source_link || "",
            industryIds: (savedData?.industry_ids || []).map(industry => industry._id || ""),
            industryNames: (savedData?.industry_ids || []).map(industry => industry.name || ""),
            techIds: savedData?.tech_ids || [],
            tech: savedData?.tech || [],
            totalCount: response?.data?.total || 0,
          }));
        
          return {
            solutions: transformedSolutions,
            invitedSolutionsId,
          };
        },
        
        refetchOnWindowFocus: true,
        // retry: RetryOnError,
        onError: (error) => {
          console.error('Fetch user details error:', error);
          // Handle error cases as per your application's requirements
        },
      })
    })
})


export const { useFetchSolutionListQuery } = getAllSolutionList;
