import { createApi , fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = process.env.REACT_APP_base_URL;
const logoBaseUrl = 'https://bsdawebfiledata.s3.ca-central-1.amazonaws.com/solution/';
export const getUserList = createApi({
    reducerPath: 'getUserList',
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders:(header)=>{
            return header;
        }
    }),
    endpoints:(builder)=>({
        fetchUserList: builder.query({
        query:({userId, selectedBoardId , selectedSolutionId ,searchParams})=>({
            url:`inbox/board/users-list/${userId}?board_id=${selectedBoardId}&search=${searchParams}&solution_id=${selectedSolutionId}`,
            method: 'GET',
        }),
        retry: (failureCount, error,) => {
            // Retry up to 3 times if the request fails due to network issues or server errors
            return failureCount <= 3 && (error.status === 503 || error.message.includes('Network Error'));
          },
        transformResponse: (response) => {
          return response
        },
        
        refetchOnWindowFocus: true,
        // retry: RetryOnError,
        onError: (error) => {
          console.error('Fetch user details error:', error);
          // Handle error cases as per your application's requirements
        },
        }),

    })
})

export const{useFetchUserListQuery} =  getUserList;
export default  getUserList;