import { useEffect, useState } from 'react';
import { Paper, Box, Typography, Button, IconButton, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import shortid from 'shortid';
// import { toast } from 'react-toastify';
import { useUploadDocumentMutation } from '../../redux/api/BsiDrive/uploadDocument';
import { useDispatch, useSelector } from 'react-redux';
import DeleteIcon from '@mui/icons-material/Delete';
import { CloudUpload } from '@mui/icons-material';
import CustomLoader from '../../utils/Loader/CustomLoader';
import { setRefetchDocument, setRefetchQuickDocsList, setRefetchSubFolder } from '../../redux/features/refetch';
import { hideLoader, showLoader } from '../../redux/features/loader';

const FileUpload = ({ handleClose, showToastMessage,documentId }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const userId = localStorage.getItem("userId");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [files, setFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadDocument, { isLoading, isError, error }] = useUploadDocumentMutation();
  const parentId = documentId;

  const handleFileChange = (e) => {
    const newFiles = Array.from(e.target.files);
    setFiles(newFiles);
    setSelectedFiles(prevFiles => [
      ...prevFiles,
      ...newFiles.map(file => ({
        id: shortid.generate(),
        filename: file.name,
        filetype: file.type,
        fileimage: URL.createObjectURL(file),
        datetime: file.lastModifiedDate.toLocaleString('en-IN'),
        filesize: filesizes(file.size),
      })),
    ]);
  };


  const handleDeleteFile = (id, filename) => {
    if (window.confirm("Are you sure you want to delete this file?")) {
      setSelectedFiles(prevFiles => prevFiles.filter(file => file.id !== id));
      setFiles(prevFiles => Array.from(prevFiles).filter(file => file.name !== filename));
    }
  };

  const filesizes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };

  const handleSubmit = async (e) => {
    e.preventDefault();3

    if (files.length === 0) {
        showToastMessage('Please select a file to upload', 'error');
        
    }else{
    const formData = new FormData();
    files.forEach(file => formData.append('files', file));
    formData.append('owner_id', userId);
    formData.append('isDocument', false);
    formData.append('parent_id', parentId || 'null'); 
    setIsUploading(true);
    try {
        const result = await uploadDocument(formData).unwrap();
        const successMessage = result?.message || 'File uploaded!';
        dispatch(setRefetchSubFolder(true));
        dispatch(setRefetchDocument(true));
        dispatch(setRefetchQuickDocsList(true));
        handleClose();
        showToastMessage(successMessage, 'success');

    } catch (uploadError) {
        const errorMessage = uploadError?.message || 'Upload failed!';
        showToastMessage(errorMessage, 'error');
    } finally {
      setIsUploading(false); // Re-enable the upload button after upload is complete
    }
}
  };

  useEffect(() => {
    if (isLoading) {
      dispatch(showLoader());
    } else {
      dispatch(hideLoader());
    }
  }, [isLoading, dispatch]);


  return (
    <Box sx={{ backgroundColor: theme.palette.background.paper,  }}>
      <Typography variant="h6" sx={{ mb: 2 }}>New File Upload</Typography>
      <form onSubmit={handleSubmit}>
        <Box sx={{
            mb: 2 ,
            maxHeight: "calc(60vh - 70px)", 
            overflowY: "auto",
            '&::-webkit-scrollbar': {
                width: '6px',
                borderRadius: '4px'
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: 'transparent',
                borderRadius: '4px'
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#9C9C9C',
                borderRadius: '4px'
              }
            }}>
          {selectedFiles.map(({ id, filename, fileimage, filesize, datetime }) => (
            <Box key={id} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <Box
                sx={{
                  width: 50,
                  height: 50,
                  mr: 2,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: theme.palette.grey[200],
                }}
              >
                {filename.match(/\.(jpg|jpeg|png|gif|svg)$/i) ? (
                  <img src={fileimage} alt={filename} style={{ maxWidth: '100%', maxHeight: '100%' }} />
                ) : (
                  <Typography variant="body2">File</Typography>
                )}
              </Box>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="body2" noWrap>{filename}</Typography>
                <Typography variant="caption" color="textSecondary">
                  Size: {filesize} | Modified: {datetime}
                </Typography>
              </Box>
              <Tooltip title="Delete">
                <IconButton color="primary" onClick={() => handleDeleteFile(id, filename)}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Box>
          ))}
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center',  }}>
          
            <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                <input
                    type="file"
                    id="fileupload"
                    name="document"
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                    multiple
                />
                <label htmlFor="fileupload">
                    <Button
                        variant="contained"
                        component="span"
                        startIcon={<CloudUpload />}
                    >
                        Choose Files
                    </Button>
                </label>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              {
                isUploading === false?
                <Button
                    type="submit"
                    variant="contained"
                    sx={{ mr: 2 }}
                >
                    Upload
                </Button>
                :
                <Button
                    variant="contained"
                    sx={{ mr: 2 }}
                >
                    Uploading...
                </Button>
              }
                
                <Button
                    type="button"
                    variant="outlined"
                    onClick={handleClose}
                >
                    Cancel
                </Button>
            </Box>
        </Box>
      </form>
    </Box>
  );
};

export default FileUpload;
