import React, { useState } from 'react'
import {
    Box,
    Typography,
    Button,
    IconButton,
    FormControl,
    Input,
    InputAdornment,
} from '@mui/material'
import { useTheme } from '@emotion/react';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import UploadRoundedIcon from '@mui/icons-material/UploadRounded';
const PaymentConfirmModal = ({ handleClose, goBackCp }) => {
    const theme = useTheme();
    return (
        <>
            <Box
                sx={{
                    mb: 4
                }}
            >
                <Typography variant='h5'
                    sx={{
                        fontSize: "1.3rem",
                        color: "secondary.main",
                        fontWeight: "700",
                        mb: 0.5
                    }}>Confirm Payment</Typography>
                <Typography variant='h5'
                    sx={{
                        fontSize: "12px",
                        color: "ltheme.main",
                        fontWeight: "500",
                        mb: 0.5
                    }}>Please attach a proof of transaction</Typography>
            </Box>
            <Box
                sx={{
                    mb: 2
                }}
            >
                <Typography variant='h5'
                    sx={{
                        fontSize: "12px",
                        color: "ltheme.main",
                        fontWeight: "500",
                        mb: 0.5
                    }}>
                    Upload a proof of transaction
                </Typography>
                <FormControl variant="filled"
                    sx={{
                        width: '100%',
                        borderRadius: '4px',
                        backgroundColor: 'extraLTheme.main',
                        border: 'solid 1px #DBEBF8',
                        '& .MuiInputBase-root': {
                            backgroundColor: 'extraLTheme.main',
                        },
                        '& .MuiInputBase-input': {
                            fontSize: '0.9rem',
                            color: 'lightSecondary.main',
                            fontWeight: '500',
                            pt: 1.5,
                            pb: 1.5
                        },
                        '& .MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error):before': {
                            border: 'none'
                        },
                        '& .MuiInputBase-root:hover:before': {
                            border: 'none'
                        },
                        '&:hover': {
                            borderColor: '#DBEBF8'
                        },
                        '& .MuiInputBase-root:before': {
                            border: 'none'
                        },
                        '& .MuiInputBase-root:after': {
                            border: 'none'
                        }
                    }}
                >
                    <Input
                        sx={{
                            width: '100%',
                            borderRadius: '4px',
                            backgroundColor: 'extraLTheme.main',
                            border: 'solid 1px #DBEBF8',
                            '& .MuiInputBase-input': {
                                fontSize: '0.9rem',
                                color: 'lightSecondary.main',
                                fontWeight: '500',
                                p: 1.5
                            },
                        }}
                        value='120000'
                        type='text'
                        placeholder=''
                        endAdornment={
                            <InputAdornment position="end"
                                sx={{
                                    pr: 1
                                }}
                            >
                                <IconButton
                                    sx={{
                                        p: 0.5
                                    }}
                                >
                                    <UploadRoundedIcon
                                        sx={{ color: 'lightSecondary.main' }}
                                    />
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>
            </Box>
            <Box
                sx={{
                    textAlign: 'center',
                    mb: 1,
                    mt: 5
                }}
            >
                <Button variant="outlined"
                    sx={{
                        textTransform: 'inherit',
                        p: "10px 25px",
                        width: '240px',
                        textAlign: 'center',
                        borderWidth: '2px',
                        fontWeight: '600',
                        borderColor: 'primary.main',
                        boxShadow: 'none',
                        mr: 2,
                        '&:hover': {
                            borderWidth: '2px',
                        }
                    }}
                    onClick={goBackCp}
                >
                    <KeyboardArrowLeftOutlinedIcon
                        sx={{
                            fontSize: '24px',
                            mr: 1
                        }}
                    /> Go Back
                </Button>
                <Button variant="contained"
                    sx={{
                        textTransform: 'inherit',
                        p: "12px 25px",
                        width: '240px',
                        textAlign: 'center',
                        borderWidth: '2px',
                        fontWeight: '600',
                        borderColor: 'primary.main',
                        boxShadow: 'none',
                        '&:hover': {
                            borderWidth: '2px',
                            boxShadow: 'none',
                        }
                    }}
                >
                    Proceed
                </Button>
            </Box>
        </>
    )
}

export default PaymentConfirmModal