import React from 'react'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import {
    Avatar,
    Box,
    Button,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    MenuItem,
    Stack,
    Typography,
    Card,
    CardContent,
    IconButton,
    Select,
    FormControl,
} from '@mui/material';
import { useTheme } from '@emotion/react';
import { styled } from '@mui/material/styles';
import { getImages } from '../../commonComponents/const';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import { DeleteOutlineOutlined } from '@mui/icons-material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import { Link } from 'react-router-dom';
const EditTeamMembers = () => {
    const theme = useTheme();
    // Select 
    const [designation, setDesignation] = React.useState('');
    const handleChange = (event) => {
        setDesignation(event.target.value);
    };
    // Search
    const Search = styled('div')(({ theme }) => ({
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: '#ECF6FF',
        '&:hover': {
            backgroundColor: '#ECF6FF',
        },
        marginRight: '0',
        marginLeft: 0,
        marginBottom: 5,
        width: '100%'
    }));
    const SearchIconWrapper = styled('div')(({ theme }) => ({
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        right: '0',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }));

    const StyledInputBase = styled(InputBase)(({ theme }) => ({
        color: '#106EED',
        width: '100%',
        '& .MuiInputBase-input': {
            padding: theme.spacing(1.7, 0, 1.7, 1.7),
            paddingRight: `calc(1em + ${theme.spacing(4)})`,
            transition: theme.transitions.create('width'),
            width: '100%',
            fontSize: '12px',
            fontWeight: '500'
        },
    }));
    return (
        <>
            <Box>
                <Typography
                    sx={{
                        fontSize: "1.4rem",
                        fontWeight: "700",
                        color: 'secondary.main',
                        [theme.breakpoints.down('md')]: {
                            fontSize: '1.1rem',
                        },
                    }} variant="h4"
                    noWrap component="div">
                    <Link to="/manage-team-members"
                        style={{
                            color: '#2A589C',
                            textDecoration: "none"
                        }}
                    >
                        <KeyboardBackspaceIcon
                            style={{ verticalAlign: "-4px" }}
                        /> Edit Details
                    </Link>
                </Typography>
            </Box>
            <Box
                sx={{
                    background: "#ECF6FF",
                    mt: 2,
                    borderRadius: "10px",
                    p: 2,
                    mb: 5
                }}
            >
                <Card
                    sx={{
                        boxShadow: 'none',
                        mb: 1
                    }}
                >
                    <CardContent
                        style={{
                            paddingBottom: "10px"
                        }}
                    >
                        <Grid container rowSpacing={0} columnSpacing={2}
                            sx={{
                                alignItems: 'flex-end'
                            }}
                        >
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <Box display={"flex"} sx={{ px: "0", alignItems: "center" }}>
                                    <ListItemAvatar
                                        sx={{
                                            width: "75px"
                                        }}
                                    >
                                        <Avatar
                                            sx={{
                                                color: 'success.main',
                                                bgcolor: 'success.lighter',
                                                width: '75px',
                                                height: '75px'
                                            }}
                                            src={getImages('3.jpg')}
                                        >

                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        sx={{
                                            pl: '10px',
                                            width: "60%"
                                        }}
                                    >
                                        <Typography noWrap
                                            sx={{
                                                fontSize: "1.1rem",
                                                fontWeight: '600',
                                                color: 'secondary.main',
                                                lineHeight: '24px',
                                                maxWidth: '220px',
                                                [theme.breakpoints.down('sm')]: {
                                                    maxWidth: '110px'
                                                }
                                            }}
                                            variant="h6">Elizabeth Bush
                                        </Typography>
                                        <Typography
                                            noWrap
                                            variant="body2"
                                            sx={{
                                                fontSize: '0.9rem',
                                                fontWeight: '500',
                                                color: 'primary.main',
                                                maxWidth: '220px',
                                                [theme.breakpoints.down('sm')]: {
                                                    maxWidth: '110px'
                                                }
                                            }}
                                        >elizabeth.bush@gmail.com</Typography>
                                    </ListItemText>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={7} md={5} lg={5}
                                sx={{
                                    borderLeft: '1px solid #A2C2F1',
                                    borderRight: '1px solid #A2C2F1',
                                    [theme.breakpoints.down('sm')]: {
                                        borderLeft: "none",
                                        borderRight: "none",
                                        mt: 2
                                    },
                                }}
                            >
                                <Box
                                    sx={{
                                        px: "0",
                                        maxWidth: '420px',
                                        margin: '0 auto',
                                        [theme.breakpoints.down('xl')]: {
                                            maxWidth: "100%",
                                            paddingRight: "15px"
                                        },
                                        [theme.breakpoints.down('md')]: {
                                            paddingRight: "0"
                                        }
                                    }}
                                >
                                    <Box
                                        sx={{
                                            fontSize: "12px",
                                            fontWeight: '500',
                                            color: 'ltheme.main',
                                            lineHeight: '24px',
                                        }}
                                        variant="body2">
                                        Designation
                                    </Box>
                                    <FormControl fullWidth
                                        sx={{
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                border: 'none'
                                            }
                                        }}
                                    >
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={designation}
                                            onChange={handleChange}
                                            displayEmpty
                                            IconComponent={KeyboardArrowDownOutlinedIcon}
                                            sx={{
                                                background: "#ECF6FF",
                                                '& .MuiSelect-select': {
                                                    minHeight: "20px",
                                                    padding: "11px 14px",
                                                    color: "lightSecondary.main",
                                                    fontWeight: "600",
                                                    fontSize: "0.9rem"
                                                },
                                                '& .MuiSelect-icon': {
                                                    color: 'lightSecondary.main'
                                                },
                                            }}
                                        >
                                            <MenuItem value={10}>Tech support</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={5} md={3} lg={3}
                                sx={{
                                    textAlign: 'right',
                                    pr: 2,
                                    [theme.breakpoints.down('md')]: {
                                        pr: 0,
                                        mt: 2
                                    }
                                }}
                            >

                                <Button variant="outlined"
                                    sx={{
                                        textTransform: 'inherit',
                                        p: "10px 25px",
                                        borderColor: 'red.main',
                                        color: 'red.main',
                                        fontSize: '0.9rem',
                                        fontWeight: '600',
                                        [theme.breakpoints.down('md')]: {
                                            width: "100%",
                                            p: "10px 10px",
                                        },
                                        '&:hover': {
                                            borderColor: 'red.main',
                                            backgroundColor: 'transparent'
                                        }
                                    }}
                                >
                                    <DeleteOutlineOutlined
                                        style={{
                                            width: "20px",
                                            height: "20px",
                                            marginRight: "5px",
                                            marginTop: "0"
                                        }} />
                                    Remove Member
                                </Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                <Card
                    sx={{
                        boxShadow: 'none',
                        mb: 1
                    }}
                >
                    <CardContent
                        style={{
                            paddingBottom: "10px"
                        }}
                    >
                        <Grid container rowSpacing={0} columnSpacing={0}
                        >
                            <Grid item xs={12}>
                                <Typography
                                    sx={{
                                        fontSize: "1.1rem",
                                        fontWeight: '600',
                                        color: 'lightSecondary.main',
                                        lineHeight: '24px',
                                        mb: 2.5
                                    }}
                                    variant="h6">Manage Access
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}
                                sx={{
                                    borderRight: '1px solid #A2C2F1',
                                    pr: 5,
                                    [theme.breakpoints.down('sm')]: {
                                        borderRight: "none",
                                        pr: 0
                                    }
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: "0.9rem",
                                        fontWeight: '600',
                                        color: 'lightSecondary.main',
                                        lineHeight: '20px',
                                        mb: 0.5
                                    }}
                                    variant="h6">Edit access
                                </Typography>
                                <Box sx={{ mb: 2 }}>
                                    <Search>
                                        <SearchIconWrapper>
                                            <SearchIcon sx={{ color: "lightSecondary.main" }} />
                                        </SearchIconWrapper>
                                        <StyledInputBase
                                            placeholder="Search solution"
                                            inputProps={{ 'aria-label': 'search' }}
                                        />
                                    </Search>
                                </Box>

                                <List
                                    component="nav"
                                    sx={{
                                        px: 0,
                                        py: 0,
                                        '& .MuiListItemButton-root': {
                                            py: 1.5,
                                        },
                                        overflowY: 'auto',
                                        maxHeight: '355px',
                                        '&::-webkit-scrollbar': {
                                            width: '6px',
                                            borderRadius: '4px'
                                        },
                                        '&::-webkit-scrollbar-track': {
                                            backgroundColor: 'transparent',
                                            borderRadius: '4px'
                                        },
                                        '&::-webkit-scrollbar-thumb': {
                                            backgroundColor: '#9C9C9C',
                                            borderRadius: '4px'
                                        }
                                    }}
                                >
                                    <ListItem
                                        sx={{
                                            minHeight: "65px",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            backgroundColor: "extraLTheme.main",
                                            borderRadius: "10px",
                                            marginTop: "5px"
                                        }}
                                    >
                                        <Stack
                                            sx={{
                                                flexDirection: "inherit",
                                                alignItems: "center",
                                                width: "60%"
                                            }}
                                        >
                                            <ListItemAvatar
                                                sx={{
                                                    display: "inline-block",
                                                    marginRight: "7px"
                                                }}
                                            >
                                                <img src={getImages('image_172.png')} />
                                            </ListItemAvatar>
                                            <Typography variant="subtitle1"
                                                sx={{
                                                    fontSize: "0.95rem",
                                                    color: "lightSecondary.main",
                                                    fontWeight: "600",
                                                    lineHeight: "20px"
                                                }}
                                            >TRUSTDOCK Digital ID</Typography>
                                        </Stack>
                                        <Typography variant="subtitle1" sx={{
                                            fontSize: "0.95rem",
                                            color: "primary.main",
                                            fontWeight: "600",
                                            width: "10%",
                                            textAlign: "center"
                                        }}>IB1</Typography>
                                        <FormControl fullWidth
                                            sx={{
                                                maxWidth: '100px',
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    border: 'none'
                                                }
                                            }}
                                        >
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={designation}
                                                onChange={handleChange}
                                                displayEmpty
                                                IconComponent={KeyboardArrowDownOutlinedIcon}
                                                sx={{
                                                    background: "#fff",
                                                    borderRadius: "40px",
                                                    '& .MuiSelect-select': {
                                                        minHeight: "18px",
                                                        padding: "5px 6px 5px 18px",
                                                        color: "lightSecondary.main",
                                                        fontWeight: "600",
                                                        fontSize: "0.8rem"
                                                    },
                                                    '& .MuiSelect-icon': {
                                                        color: 'lightSecondary.main'
                                                    },
                                                }}
                                            >
                                                <MenuItem value={10}>View</MenuItem>
                                                <MenuItem value={12}>Edit</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <IconButton aria-label="message">
                                            <RemoveCircleOutlineOutlinedIcon sx={{
                                                color: "red.main",
                                                width: "18px",
                                                height: "18px"
                                            }} />
                                        </IconButton>
                                    </ListItem>
                                    <ListItem sx={{
                                        minHeight: "65px",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        backgroundColor: "extraLTheme.main",
                                        borderRadius: "10px",
                                        marginTop: "5px"
                                    }}>
                                        <Stack sx={{
                                            flexDirection: "inherit",
                                            alignItems: "center",
                                            width: "60%"
                                        }}>
                                            <ListItemAvatar sx={{
                                                display: "inline-block",
                                                marginRight: "7px"
                                            }}>
                                                <img src={getImages('image_154.png')} />
                                            </ListItemAvatar>
                                            <Typography variant="subtitle1" sx={{
                                                fontSize: "0.95rem",
                                                color: "lightSecondary.main",
                                                fontWeight: "600",
                                                lineHeight: "20px"
                                            }}>CIAM - Customer Identity & Access Management</Typography>
                                        </Stack>
                                        <Typography variant="subtitle1" sx={{
                                            fontSize: "0.95rem",
                                            color: "primary.main",
                                            fontWeight: "600",
                                            width: "10%",
                                            textAlign: "center"
                                        }}>LR</Typography>
                                        <FormControl fullWidth
                                            sx={{
                                                maxWidth: '100px',
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    border: 'none'
                                                }
                                            }}
                                        >
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={designation}
                                                onChange={handleChange}
                                                displayEmpty
                                                IconComponent={KeyboardArrowDownOutlinedIcon}
                                                sx={{
                                                    background: "#fff",
                                                    borderRadius: "40px",
                                                    '& .MuiSelect-select': {
                                                        minHeight: "18px",
                                                        padding: "5px 6px 5px 18px",
                                                        color: "lightSecondary.main",
                                                        fontWeight: "600",
                                                        fontSize: "0.8rem"
                                                    },
                                                    '& .MuiSelect-icon': {
                                                        color: 'lightSecondary.main'
                                                    },
                                                }}
                                            >
                                                <MenuItem value={10}>View</MenuItem>
                                                <MenuItem value={12}>Edit</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <IconButton aria-label="message">
                                            <RemoveCircleOutlineOutlinedIcon sx={{
                                                color: "red.main",
                                                width: "18px",
                                                height: "18px"
                                            }} />
                                        </IconButton>
                                    </ListItem>
                                    <ListItem sx={{
                                        minHeight: "65px",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        backgroundColor: "extraLTheme.main",
                                        borderRadius: "10px",
                                        marginTop: "5px"
                                    }}>
                                        <Stack sx={{
                                            flexDirection: "inherit",
                                            alignItems: "center",
                                            width: "60%"
                                        }}>
                                            <ListItemAvatar sx={{
                                                display: "inline-block",
                                                marginRight: "7px"
                                            }}>
                                                <img src={getImages('image_53.png')} />
                                            </ListItemAvatar>
                                            <Typography variant="subtitle1" sx={{
                                                fontSize: "0.95rem",
                                                color: "lightSecondary.main",
                                                fontWeight: "600",
                                                lineHeight: "20px"
                                            }}>Advanced Solutions in Digital Healthcare</Typography>
                                        </Stack>
                                        <Typography variant="subtitle1" sx={{
                                            fontSize: "0.95rem",
                                            color: "primary.main",
                                            fontWeight: "600",
                                            width: "10%",
                                            textAlign: "center"
                                        }}>DHR</Typography>
                                        <FormControl fullWidth
                                            sx={{
                                                maxWidth: '100px',
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    border: 'none'
                                                }
                                            }}
                                        >
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={designation}
                                                onChange={handleChange}
                                                displayEmpty
                                                IconComponent={KeyboardArrowDownOutlinedIcon}
                                                sx={{
                                                    background: "#fff",
                                                    borderRadius: "40px",
                                                    '& .MuiSelect-select': {
                                                        minHeight: "18px",
                                                        padding: "5px 6px 5px 18px",
                                                        color: "lightSecondary.main",
                                                        fontWeight: "600",
                                                        fontSize: "0.8rem"
                                                    },
                                                    '& .MuiSelect-icon': {
                                                        color: 'lightSecondary.main'
                                                    },
                                                }}
                                            >
                                                <MenuItem value={10}>View</MenuItem>
                                                <MenuItem value={12}>Edit</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <IconButton aria-label="message">
                                            <RemoveCircleOutlineOutlinedIcon sx={{
                                                color: "red.main",
                                                width: "18px",
                                                height: "18px"
                                            }} />
                                        </IconButton>
                                    </ListItem>
                                    <ListItem sx={{
                                        minHeight: "65px",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        backgroundColor: "extraLTheme.main",
                                        borderRadius: "10px",
                                        marginTop: "5px"
                                    }}>
                                        <Stack sx={{
                                            flexDirection: "inherit",
                                            alignItems: "center",
                                            width: "60%"
                                        }}>
                                            <ListItemAvatar sx={{
                                                display: "inline-block",
                                                marginRight: "7px"
                                            }}>
                                                <img src={getImages('image_155.png')} />
                                            </ListItemAvatar>
                                            <Typography variant="subtitle1" sx={{
                                                fontSize: "0.95rem",
                                                color: "lightSecondary.main",
                                                fontWeight: "600",
                                                lineHeight: "20px"
                                            }}>Blockpass Identity for a Connected World</Typography>
                                        </Stack>
                                        <Typography variant="subtitle1" sx={{
                                            fontSize: "0.95rem",
                                            color: "primary.main",
                                            fontWeight: "600",
                                            width: "10%",
                                            textAlign: "center"
                                        }}>IB1</Typography>
                                        <FormControl fullWidth
                                            sx={{
                                                maxWidth: '100px',
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    border: 'none'
                                                }
                                            }}
                                        >
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={designation}
                                                onChange={handleChange}
                                                displayEmpty
                                                IconComponent={KeyboardArrowDownOutlinedIcon}
                                                sx={{
                                                    background: "#fff",
                                                    borderRadius: "40px",
                                                    '& .MuiSelect-select': {
                                                        minHeight: "18px",
                                                        padding: "5px 6px 5px 18px",
                                                        color: "lightSecondary.main",
                                                        fontWeight: "600",
                                                        fontSize: "0.8rem"
                                                    },
                                                    '& .MuiSelect-icon': {
                                                        color: 'lightSecondary.main'
                                                    },
                                                }}
                                            >
                                                <MenuItem value={10}>View</MenuItem>
                                                <MenuItem value={12}>Edit</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <IconButton aria-label="message">
                                            <RemoveCircleOutlineOutlinedIcon sx={{
                                                color: "red.main",
                                                width: "18px",
                                                height: "18px"
                                            }} />
                                        </IconButton>
                                    </ListItem>
                                    <ListItem
                                        sx={{
                                            minHeight: "65px",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            backgroundColor: "extraLTheme.main",
                                            borderRadius: "10px",
                                            marginTop: "5px"
                                        }}>
                                        <Stack sx={{
                                            flexDirection: "inherit",
                                            alignItems: "center",
                                            width: "60%"
                                        }}>
                                            <ListItemAvatar
                                                sx={{
                                                    display: "inline-block",
                                                    marginRight: "7px"
                                                }}
                                            >
                                                <img src={getImages('image_153.png')} />
                                            </ListItemAvatar>
                                            <Typography variant="subtitle1"
                                                sx={{
                                                    fontSize: "0.95rem",
                                                    color: "lightSecondary.main",
                                                    fontWeight: "600",
                                                    lineHeight: "20px"
                                                }}
                                            >Polysign</Typography>
                                        </Stack>
                                        <Typography variant="subtitle1"
                                            sx={{
                                                fontSize: "0.95rem",
                                                color: "primary.main",
                                                fontWeight: "600",
                                                width: "10%",
                                                textAlign: "center"
                                            }}
                                        >SCE</Typography>
                                        <FormControl fullWidth
                                            sx={{
                                                maxWidth: '100px',
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    border: 'none'
                                                }
                                            }}
                                        >
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={designation}
                                                onChange={handleChange}
                                                displayEmpty
                                                IconComponent={KeyboardArrowDownOutlinedIcon}
                                                sx={{
                                                    background: "#fff",
                                                    borderRadius: "40px",
                                                    '& .MuiSelect-select': {
                                                        minHeight: "18px",
                                                        padding: "5px 6px 5px 18px",
                                                        color: "lightSecondary.main",
                                                        fontWeight: "600",
                                                        fontSize: "0.8rem"
                                                    },
                                                    '& .MuiSelect-icon': {
                                                        color: 'lightSecondary.main'
                                                    },
                                                }}
                                            >
                                                <MenuItem value={10}>View</MenuItem>
                                                <MenuItem value={12}>Edit</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <IconButton aria-label="message">
                                            <RemoveCircleOutlineOutlinedIcon sx={{
                                                color: "red.main",
                                                width: "18px",
                                                height: "18px"
                                            }} />
                                        </IconButton>
                                    </ListItem>
                                </List>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}
                                sx={{
                                    pl: 5,
                                    [theme.breakpoints.down('sm')]: {
                                        borderLeft: "none",
                                        pl: 0,
                                        pt: 4
                                    }
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: "0.9rem",
                                        fontWeight: '600',
                                        color: 'lightSecondary.main',
                                        lineHeight: '20px',
                                        mb: 0.5
                                    }}
                                    variant="h6">Give new access
                                </Typography>
                                <Box sx={{ mb: 2 }}>
                                    <Search>
                                        <SearchIconWrapper>
                                            <SearchIcon sx={{ color: "lightSecondary.main" }} />
                                        </SearchIconWrapper>
                                        <StyledInputBase
                                            placeholder="Search solution"
                                            inputProps={{ 'aria-label': 'search' }}
                                        />
                                    </Search>
                                </Box>
                                <List
                                    component="nav"
                                    sx={{
                                        px: 0,
                                        py: 0,
                                        '& .MuiListItem-root': {
                                            py: 1.5,
                                        },
                                        overflowY: 'auto',
                                        maxHeight: '355px',
                                        '&::-webkit-scrollbar': {
                                            width: '6px',
                                            borderRadius: '4px'
                                        },
                                        '&::-webkit-scrollbar-track': {
                                            backgroundColor: 'transparent',
                                            borderRadius: '4px'
                                        },
                                        '&::-webkit-scrollbar-thumb': {
                                            backgroundColor: '#9C9C9C',
                                            borderRadius: '4px'
                                        }
                                    }}
                                >
                                    <ListItem
                                        sx={{
                                            minHeight: "65px",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            backgroundColor: "extraLTheme.main",
                                            borderRadius: "10px",
                                            marginTop: "5px"
                                        }}
                                    >
                                        <Stack
                                            sx={{
                                                flexDirection: "inherit",
                                                alignItems: "center",
                                                width: "60%"
                                            }}
                                        >
                                            <ListItemAvatar
                                                sx={{
                                                    display: "inline-block",
                                                    marginRight: "7px"
                                                }}
                                            >
                                                <img src={getImages('image_172.png')} />
                                            </ListItemAvatar>
                                            <Typography variant="subtitle1"
                                                sx={{
                                                    fontSize: "0.95rem",
                                                    color: "lightSecondary.main",
                                                    fontWeight: "600",
                                                    lineHeight: "20px"
                                                }}
                                            >TRUSTDOCK Digital ID</Typography>
                                        </Stack>
                                        <Typography variant="subtitle1" sx={{
                                            fontSize: "0.95rem",
                                            color: "primary.main",
                                            fontWeight: "600",
                                            width: "10%",
                                            textAlign: "center"
                                        }}>IB1</Typography>
                                        <FormControl fullWidth
                                            sx={{
                                                maxWidth: '100px',
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    border: 'none'
                                                }
                                            }}
                                        >
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={designation}
                                                onChange={handleChange}
                                                displayEmpty
                                                IconComponent={KeyboardArrowDownOutlinedIcon}
                                                sx={{
                                                    background: "#fff",
                                                    borderRadius: "40px",
                                                    '& .MuiSelect-select': {
                                                        minHeight: "18px",
                                                        padding: "5px 6px 5px 18px",
                                                        color: "lightSecondary.main",
                                                        fontWeight: "600",
                                                        fontSize: "0.8rem"
                                                    },
                                                    '& .MuiSelect-icon': {
                                                        color: 'lightSecondary.main'
                                                    },
                                                }}
                                            >
                                                <MenuItem value={10}>View</MenuItem>
                                                <MenuItem value={12}>Edit</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <IconButton aria-label="message">
                                            <AddCircleOutlineIcon sx={{
                                                color: "green.main",
                                                width: "18px",
                                                height: "18px"
                                            }} />
                                        </IconButton>
                                    </ListItem>
                                    <ListItem
                                        sx={{
                                            minHeight: "65px",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            backgroundColor: "extraLTheme.main",
                                            borderRadius: "10px",
                                            marginTop: "5px"
                                        }}
                                    >
                                        <Stack
                                            sx={{
                                                flexDirection: "inherit",
                                                alignItems: "center",
                                                width: "60%"
                                            }}
                                        >
                                            <ListItemAvatar
                                                sx={{
                                                    display: "inline-block",
                                                    marginRight: "7px"
                                                }}
                                            >
                                                <img src={getImages('image_172.png')} />
                                            </ListItemAvatar>
                                            <Typography variant="subtitle1"
                                                sx={{
                                                    fontSize: "0.95rem",
                                                    color: "lightSecondary.main",
                                                    fontWeight: "600",
                                                    lineHeight: "20px"
                                                }}
                                            >TRUSTDOCK Digital ID</Typography>
                                        </Stack>
                                        <Typography variant="subtitle1" sx={{
                                            fontSize: "0.95rem",
                                            color: "primary.main",
                                            fontWeight: "600",
                                            width: "10%",
                                            textAlign: "center"
                                        }}>IB1</Typography>
                                        <FormControl fullWidth
                                            sx={{
                                                maxWidth: '100px',
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    border: 'none'
                                                }
                                            }}
                                        >
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={designation}
                                                onChange={handleChange}
                                                displayEmpty
                                                IconComponent={KeyboardArrowDownOutlinedIcon}
                                                sx={{
                                                    background: "#fff",
                                                    borderRadius: "40px",
                                                    '& .MuiSelect-select': {
                                                        minHeight: "18px",
                                                        padding: "5px 6px 5px 18px",
                                                        color: "lightSecondary.main",
                                                        fontWeight: "600",
                                                        fontSize: "0.8rem"
                                                    },
                                                    '& .MuiSelect-icon': {
                                                        color: 'lightSecondary.main'
                                                    },
                                                }}
                                            >
                                                <MenuItem value={10}>View</MenuItem>
                                                <MenuItem value={12}>Edit</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <IconButton aria-label="message">
                                            <AddCircleOutlineIcon sx={{
                                                color: "green.main",
                                                width: "18px",
                                                height: "18px"
                                            }} />
                                        </IconButton>
                                    </ListItem>
                                    <ListItem sx={{
                                        minHeight: "65px",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        backgroundColor: "extraLTheme.main",
                                        borderRadius: "10px",
                                        marginTop: "5px"
                                    }}>
                                        <Stack sx={{
                                            flexDirection: "inherit",
                                            alignItems: "center",
                                            width: "60%"
                                        }}>
                                            <ListItemAvatar sx={{
                                                display: "inline-block",
                                                marginRight: "7px"
                                            }}>
                                                <img src={getImages('image_154.png')} />
                                            </ListItemAvatar>
                                            <Typography variant="subtitle1" sx={{
                                                fontSize: "0.95rem",
                                                color: "lightSecondary.main",
                                                fontWeight: "600",
                                                lineHeight: "20px"
                                            }}>CIAM - Customer Identity & Access Management</Typography>
                                        </Stack>
                                        <Typography variant="subtitle1" sx={{
                                            fontSize: "0.95rem",
                                            color: "primary.main",
                                            fontWeight: "600",
                                            width: "10%",
                                            textAlign: "center"
                                        }}>LR</Typography>
                                        <FormControl fullWidth
                                            sx={{
                                                maxWidth: '100px',
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    border: 'none'
                                                }
                                            }}
                                        >
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={designation}
                                                onChange={handleChange}
                                                displayEmpty
                                                IconComponent={KeyboardArrowDownOutlinedIcon}
                                                sx={{
                                                    background: "#fff",
                                                    borderRadius: "40px",
                                                    '& .MuiSelect-select': {
                                                        minHeight: "18px",
                                                        padding: "5px 6px 5px 18px",
                                                        color: "lightSecondary.main",
                                                        fontWeight: "600",
                                                        fontSize: "0.8rem"
                                                    },
                                                    '& .MuiSelect-icon': {
                                                        color: 'lightSecondary.main'
                                                    },
                                                }}
                                            >
                                                <MenuItem value={10}>View</MenuItem>
                                                <MenuItem value={12}>Edit</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <IconButton aria-label="message">
                                            <AddCircleOutlineIcon sx={{
                                                color: "green.main",
                                                width: "18px",
                                                height: "18px"
                                            }} />
                                        </IconButton>
                                    </ListItem>
                                    <ListItem sx={{
                                        minHeight: "65px",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        backgroundColor: "extraLTheme.main",
                                        borderRadius: "10px",
                                        marginTop: "5px"
                                    }}>
                                        <Stack sx={{
                                            flexDirection: "inherit",
                                            alignItems: "center",
                                            width: "60%"
                                        }}>
                                            <ListItemAvatar sx={{
                                                display: "inline-block",
                                                marginRight: "7px"
                                            }}>
                                                <img src={getImages('image_53.png')} />
                                            </ListItemAvatar>
                                            <Typography variant="subtitle1" sx={{
                                                fontSize: "0.95rem",
                                                color: "lightSecondary.main",
                                                fontWeight: "600",
                                                lineHeight: "20px"
                                            }}>Advanced Solutions in Digital Healthcare</Typography>
                                        </Stack>
                                        <Typography variant="subtitle1" sx={{
                                            fontSize: "0.95rem",
                                            color: "primary.main",
                                            fontWeight: "600",
                                            width: "10%",
                                            textAlign: "center"
                                        }}>DHR</Typography>
                                        <FormControl fullWidth
                                            sx={{
                                                maxWidth: '100px',
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    border: 'none'
                                                }
                                            }}
                                        >
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={designation}
                                                onChange={handleChange}
                                                displayEmpty
                                                IconComponent={KeyboardArrowDownOutlinedIcon}
                                                sx={{
                                                    background: "#fff",
                                                    borderRadius: "40px",
                                                    '& .MuiSelect-select': {
                                                        minHeight: "18px",
                                                        padding: "5px 6px 5px 18px",
                                                        color: "lightSecondary.main",
                                                        fontWeight: "600",
                                                        fontSize: "0.8rem"
                                                    },
                                                    '& .MuiSelect-icon': {
                                                        color: 'lightSecondary.main'
                                                    },
                                                }}
                                            >
                                                <MenuItem value={10}>View</MenuItem>
                                                <MenuItem value={12}>Edit</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <IconButton aria-label="message">
                                            <AddCircleOutlineIcon sx={{
                                                color: "green.main",
                                                width: "18px",
                                                height: "18px"
                                            }} />
                                        </IconButton>
                                    </ListItem>
                                    <ListItem sx={{
                                        minHeight: "65px",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        backgroundColor: "extraLTheme.main",
                                        borderRadius: "10px",
                                        marginTop: "5px"
                                    }}>
                                        <Stack sx={{
                                            flexDirection: "inherit",
                                            alignItems: "center",
                                            width: "60%"
                                        }}>
                                            <ListItemAvatar sx={{
                                                display: "inline-block",
                                                marginRight: "7px"
                                            }}>
                                                <img src={getImages('image_155.png')} />
                                            </ListItemAvatar>
                                            <Typography variant="subtitle1" sx={{
                                                fontSize: "0.95rem",
                                                color: "lightSecondary.main",
                                                fontWeight: "600",
                                                lineHeight: "20px"
                                            }}>Blockpass Identity for a Connected World</Typography>
                                        </Stack>
                                        <Typography variant="subtitle1" sx={{
                                            fontSize: "0.95rem",
                                            color: "primary.main",
                                            fontWeight: "600",
                                            width: "10%",
                                            textAlign: "center"
                                        }}>IB1</Typography>
                                        <FormControl fullWidth
                                            sx={{
                                                maxWidth: '100px',
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    border: 'none'
                                                }
                                            }}
                                        >
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={designation}
                                                onChange={handleChange}
                                                displayEmpty
                                                IconComponent={KeyboardArrowDownOutlinedIcon}
                                                sx={{
                                                    background: "#fff",
                                                    borderRadius: "40px",
                                                    '& .MuiSelect-select': {
                                                        minHeight: "18px",
                                                        padding: "5px 6px 5px 18px",
                                                        color: "lightSecondary.main",
                                                        fontWeight: "600",
                                                        fontSize: "0.8rem"
                                                    },
                                                    '& .MuiSelect-icon': {
                                                        color: 'lightSecondary.main'
                                                    },
                                                }}
                                            >
                                                <MenuItem value={10}>View</MenuItem>
                                                <MenuItem value={12}>Edit</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <IconButton aria-label="message">
                                            <AddCircleOutlineIcon sx={{
                                                color: "green.main",
                                                width: "18px",
                                                height: "18px"
                                            }} />
                                        </IconButton>
                                    </ListItem>
                                    <ListItem
                                        sx={{
                                            minHeight: "65px",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            backgroundColor: "extraLTheme.main",
                                            borderRadius: "10px",
                                            marginTop: "5px"
                                        }}>
                                        <Stack sx={{
                                            flexDirection: "inherit",
                                            alignItems: "center",
                                            width: "60%"
                                        }}>
                                            <ListItemAvatar
                                                sx={{
                                                    display: "inline-block",
                                                    marginRight: "7px"
                                                }}
                                            >
                                                <img src={getImages('image_153.png')} />
                                            </ListItemAvatar>
                                            <Typography variant="subtitle1"
                                                sx={{
                                                    fontSize: "0.95rem",
                                                    color: "lightSecondary.main",
                                                    fontWeight: "600",
                                                    lineHeight: "20px"
                                                }}
                                            >Polysign</Typography>
                                        </Stack>
                                        <Typography variant="subtitle1"
                                            sx={{
                                                fontSize: "0.95rem",
                                                color: "primary.main",
                                                fontWeight: "600",
                                                width: "10%",
                                                textAlign: "center"
                                            }}
                                        >SCE</Typography>
                                        <FormControl fullWidth
                                            sx={{
                                                maxWidth: '100px',
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    border: 'none'
                                                }
                                            }}
                                        >
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={designation}
                                                onChange={handleChange}
                                                displayEmpty
                                                IconComponent={KeyboardArrowDownOutlinedIcon}
                                                sx={{
                                                    background: "#fff",
                                                    borderRadius: "40px",
                                                    '& .MuiSelect-select': {
                                                        minHeight: "18px",
                                                        padding: "5px 6px 5px 18px",
                                                        color: "lightSecondary.main",
                                                        fontWeight: "600",
                                                        fontSize: "0.8rem"
                                                    },
                                                    '& .MuiSelect-icon': {
                                                        color: 'lightSecondary.main'
                                                    },
                                                }}
                                            >
                                                <MenuItem value={10}>View</MenuItem>
                                                <MenuItem value={12}>Edit</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <IconButton aria-label="message">
                                            <AddCircleOutlineIcon sx={{
                                                color: "green.main",
                                                width: "18px",
                                                height: "18px"
                                            }} />
                                        </IconButton>
                                    </ListItem>
                                </List>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>

            </Box>
        </>
    )
}

export default EditTeamMembers