import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = process.env.REACT_APP_base_URL;

const InviteSolutionProvider = createApi({
    reducerPath: 'InviteSolutionProvider',
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers) => {
            // Add any headers you need here
            return headers;
        },
    }),
    endpoints: (builder) => ({
        inviteSolutionProvider: builder.mutation({
            query: ({boardId , userId , solutionId , solutionName , solutionProviderId}) => ({
                url: `requirement_boards/invite-solution`, // Replace with your API endpoint URL
                method: 'POST',
                body: { 
                    userId: userId,
                    boardId: boardId,
                    solutionId: solutionId,
                    solutionName: solutionName,
                    solutionProviderId: solutionProviderId,
            },
            }),
            transformResponse: (response) => {
                if(response.status != false){
                return response;
              }else{
                return  response = [];
               }
            },
        }),
    }),
});

export const { useInviteSolutionProviderMutation } = InviteSolutionProvider;
export default InviteSolutionProvider
