import React, { useState } from 'react';
import {
    Avatar,
    Box,
    Button,
    Grid,
    ListItem,
    ListItemAvatar,
    ListItemText,
    MenuItem,
    Stack,
    Typography,
} from '@mui/material';
import MainCard from '../../commonComponents/MainCard';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import { getImages } from '../../commonComponents/const';
import { styled } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
// Dropdown Menu
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useTheme } from '@emotion/react';
import { Link, useNavigate, useLocation} from 'react-router-dom';
const options = [
    'None',
    'Atria',
    'Callisto',
    'Dione',
    'Ganymede',
];

const ITEM_HEIGHT = 48;
const ImportantDocuments = () => {
    const theme = useTheme();
    const Search = styled('div')(({ theme }) => ({
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: '#DBEBF8',
        '&:hover': {
            backgroundColor: '#DBEBF8',
        },
        marginRight: '0',
        marginLeft: 0,
        marginBottom: 5,
        width: '100%'
    }));
     const navigate = useNavigate()
     const handleViewAllClick = () => {
        navigate('/bsi-drive');
    };

    const SearchIconWrapper = styled('div')(({ theme }) => ({
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        right: '0',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }));

    const StyledInputBase = styled(InputBase)(({ theme }) => ({
        color: '#106EED',
        width: '100%',
        '& .MuiInputBase-input': {
            padding: theme.spacing(1.7, 0, 1.7, 1.7),
            paddingRight: `calc(1em + ${theme.spacing(4)})`,
            transition: theme.transitions.create('width'),
            width: '100%',
            fontSize: '12px',
            fontWeight: '500'
        },
    }));
    // Dropdown Menu
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <>
            <MainCard content={false} 
            sx={{ 
                backgroundColor: "#ECF6FF", 
                border: "none", 
                padding: "15px 25px 7px", 
                minHeight: '450px',
                [theme.breakpoints.down("md")] : {
                    padding: "15px",
                }

            }}>
                <Box sx={{ p: 0, pb: 0 }}>
                    <Stack spacing={2} sx={{ display: "flex", flexDirection: "inherit", justifyContent: "space-between", alignItems: "center", width: "100%", marginBottom: "10px" }}>
                        <Typography variant="h6" color="textSecondary"
                            sx={{
                                fontSize: "1.2rem", fontWeight: "600",
                                color: "secondary.main",
                                marginBottom: "1rem"
                            }}
                        >
                            Important Documents
                        </Typography>
                        <Button onClick={handleViewAllClick} variant="text" style={{ marginTop: "0" }} sx={{ fontSize: "12px", fontWeight: "600", textTransform: "inherit" }}>
                            View all <ArrowForwardIosOutlinedIcon sx={{ marginLeft: "5px", fill: "primary.main", width: "12px", height: "12px" }} />
                        </Button>
                    </Stack>
                    <Search>
                        <SearchIconWrapper>
                            <SearchIcon sx={{ color: "lightSecondary.main" }} />
                        </SearchIconWrapper>
                        <StyledInputBase
                            placeholder="Search a document"
                            inputProps={{ 'aria-label': 'search' }}
                        />
                    </Search>

                    <Grid container mt={0.5} rowSpacing={0.1} columnSpacing={1}>
                        <Grid item xs={12} md={6} lg={6}>
                            <ListItem sx={{ px: "0" }}>
                                <ListItemAvatar sx={{ textAlign: "center" }}>
                                    <img style={{ maxWidth: "40px", maxHeight: "40px" }} src={getImages('pdf_icon.png')} />
                                </ListItemAvatar>
                                <ListItemText
                                    primary={
                                        <Typography noWrap
                                            sx={{
                                                fontSize: "0.9rem",
                                                fontWeight: '600',
                                                color: 'secondary.main',
                                                lineHeight: '22px'
                                            }}
                                            variant="subtitle1">Solutions
                                        </Typography>}
                                    secondary={
                                        <Typography
                                            noWrap
                                            variant="body2"
                                            sx={{
                                                fontSize: '12px',
                                                fontWeight: '500',
                                                color: 'ltheme.main',
                                                width: "100%"

                                            }}
                                        >Pdf • 3.8 mb</Typography>}
                                />
                                <div>
                                    <IconButton
                                        aria-label="more"
                                        id="long-button"
                                        aria-controls={open ? 'long-menu' : undefined}
                                        aria-expanded={open ? 'true' : undefined}
                                        aria-haspopup="true"
                                        onClick={handleClick}
                                    >
                                        <MoreVertIcon sx={{ width: "20px", height: "20px" }} />
                                    </IconButton>
                                    <Menu
                                        id="long-menu"
                                        MenuListProps={{
                                            'aria-labelledby': 'long-button',
                                        }}
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleClose}
                                        PaperProps={{
                                            style: {
                                                maxHeight: ITEM_HEIGHT * 4.5,
                                                width: '20ch',
                                            },
                                        }}
                                    >
                                        {options.map((option) => (
                                            <MenuItem key={option} selected={option === 'Pyxis'} onClick={handleClose}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </div>
                            </ListItem>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <ListItem sx={{ px: "0" }}>
                                <ListItemAvatar sx={{ textAlign: "center" }}>
                                    <img style={{ maxWidth: "40px", maxHeight: "40px" }} src={getImages('doc_icon.png')} />
                                </ListItemAvatar>
                                <ListItemText
                                    primary={
                                        <Typography noWrap
                                            sx={{
                                                fontSize: "0.9rem",
                                                fontWeight: '600',
                                                color: 'secondary.main',
                                                lineHeight: '22px'
                                            }}
                                            variant="subtitle1">Service description
                                        </Typography>}
                                    secondary={
                                        <Typography
                                            noWrap
                                            variant="body2"
                                            sx={{
                                                fontSize: '12px',
                                                fontWeight: '500',
                                                color: 'ltheme.main',
                                                width: "100%"

                                            }}
                                        >Doc • 21.5 mb</Typography>}
                                />
                                <div>
                                    <IconButton
                                        aria-label="more"
                                        id="long-button"
                                        aria-controls={open ? 'long-menu' : undefined}
                                        aria-expanded={open ? 'true' : undefined}
                                        aria-haspopup="true"
                                        onClick={handleClick}
                                    >
                                        <MoreVertIcon sx={{ width: "20px", height: "20px" }} />
                                    </IconButton>
                                    <Menu
                                        id="long-menu"
                                        MenuListProps={{
                                            'aria-labelledby': 'long-button',
                                        }}
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleClose}
                                        PaperProps={{
                                            style: {
                                                maxHeight: ITEM_HEIGHT * 4.5,
                                                width: '20ch',
                                            },
                                        }}
                                    >
                                        {options.map((option) => (
                                            <MenuItem key={option} selected={option === 'Pyxis'} onClick={handleClose}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </div>
                            </ListItem>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <ListItem sx={{ px: "0" }}>
                                <ListItemAvatar sx={{ textAlign: "center" }}>
                                    <img style={{ maxWidth: "40px", maxHeight: "40px" }} src={getImages('jpeg.png')} />
                                </ListItemAvatar>
                                <ListItemText
                                    primary={
                                        <Typography noWrap
                                            sx={{
                                                fontSize: "0.9rem",
                                                fontWeight: '600',
                                                color: 'secondary.main',
                                                lineHeight: '22px'
                                            }}
                                            variant="subtitle1">Finance state
                                        </Typography>}
                                    secondary={
                                        <Typography
                                            noWrap
                                            variant="body2"
                                            sx={{
                                                fontSize: '12px',
                                                fontWeight: '500',
                                                color: 'ltheme.main',
                                                width: "100%"

                                            }}
                                        >Jpeg • 3.8 mb</Typography>}
                                />
                                <div>
                                    <IconButton
                                        aria-label="more"
                                        id="long-button"
                                        aria-controls={open ? 'long-menu' : undefined}
                                        aria-expanded={open ? 'true' : undefined}
                                        aria-haspopup="true"
                                        onClick={handleClick}
                                    >
                                        <MoreVertIcon sx={{ width: "20px", height: "20px" }} />
                                    </IconButton>
                                    <Menu
                                        id="long-menu"
                                        MenuListProps={{
                                            'aria-labelledby': 'long-button',
                                        }}
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleClose}
                                        PaperProps={{
                                            style: {
                                                maxHeight: ITEM_HEIGHT * 4.5,
                                                width: '20ch',
                                            },
                                        }}
                                    >
                                        {options.map((option) => (
                                            <MenuItem key={option} selected={option === 'Pyxis'} onClick={handleClose}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </div>
                            </ListItem>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <ListItem sx={{ px: "0" }}>
                                <ListItemAvatar sx={{ textAlign: "center" }}>
                                    <img style={{ maxWidth: "40px", maxHeight: "40px" }} src={getImages('video_icon.png')} />
                                </ListItemAvatar>
                                <ListItemText
                                    primary={
                                        <Typography noWrap
                                            sx={{
                                                fontSize: "0.9rem",
                                                fontWeight: '600',
                                                color: 'secondary.main',
                                                lineHeight: '22px'
                                            }}
                                            variant="subtitle1">Solution details
                                        </Typography>}
                                    secondary={
                                        <Typography
                                            noWrap
                                            variant="body2"
                                            sx={{
                                                fontSize: '12px',
                                                fontWeight: '500',
                                                color: 'ltheme.main',
                                                width: "100%"

                                            }}
                                        >Mp4 • 21.5 mb</Typography>}
                                />
                                <div>
                                    <IconButton
                                        aria-label="more"
                                        id="long-button"
                                        aria-controls={open ? 'long-menu' : undefined}
                                        aria-expanded={open ? 'true' : undefined}
                                        aria-haspopup="true"
                                        onClick={handleClick}
                                    >
                                        <MoreVertIcon sx={{ width: "20px", height: "20px" }} />
                                    </IconButton>
                                    <Menu
                                        id="long-menu"
                                        MenuListProps={{
                                            'aria-labelledby': 'long-button',
                                        }}
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleClose}
                                        PaperProps={{
                                            style: {
                                                maxHeight: ITEM_HEIGHT * 4.5,
                                                width: '20ch',
                                            },
                                        }}
                                    >
                                        {options.map((option) => (
                                            <MenuItem key={option} selected={option === 'Pyxis'} onClick={handleClose}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </div>
                            </ListItem>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <ListItem sx={{ px: "0" }}>
                                <ListItemAvatar sx={{ textAlign: "center" }}>
                                    <img style={{ maxWidth: "40px", maxHeight: "40px" }} src={getImages('pdf_icon.png')} />
                                </ListItemAvatar>
                                <ListItemText
                                    primary={
                                        <Typography noWrap
                                            sx={{
                                                fontSize: "0.9rem",
                                                fontWeight: '600',
                                                color: 'secondary.main',
                                                lineHeight: '22px'
                                            }}
                                            variant="subtitle1">Steps
                                        </Typography>}
                                    secondary={
                                        <Typography
                                            noWrap
                                            variant="body2"
                                            sx={{
                                                fontSize: '12px',
                                                fontWeight: '500',
                                                color: 'ltheme.main',
                                                width: "100%"

                                            }}
                                        >Mp4 • 21.5 mb</Typography>}
                                />
                                <div>
                                    <IconButton
                                        aria-label="more"
                                        id="long-button"
                                        aria-controls={open ? 'long-menu' : undefined}
                                        aria-expanded={open ? 'true' : undefined}
                                        aria-haspopup="true"
                                        onClick={handleClick}
                                    >
                                        <MoreVertIcon sx={{ width: "20px", height: "20px" }} />
                                    </IconButton>
                                    <Menu
                                        id="long-menu"
                                        MenuListProps={{
                                            'aria-labelledby': 'long-button',
                                        }}
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleClose}
                                        PaperProps={{
                                            style: {
                                                maxHeight: ITEM_HEIGHT * 4.5,
                                                width: '20ch',
                                            },
                                        }}
                                    >
                                        {options.map((option) => (
                                            <MenuItem key={option} selected={option === 'Pyxis'} onClick={handleClose}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </div>
                            </ListItem>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <ListItem sx={{ px: "0" }}>
                                <ListItemAvatar sx={{ textAlign: "center" }}>
                                    <img style={{ maxWidth: "40px", maxHeight: "40px" }} src={getImages('pdf_icon.png')} />
                                </ListItemAvatar>
                                <ListItemText
                                    primary={
                                        <Typography noWrap
                                            sx={{
                                                fontSize: "0.9rem",
                                                fontWeight: '600',
                                                color: 'secondary.main',
                                                lineHeight: '22px'
                                            }}
                                            variant="subtitle1">BSI Terms
                                        </Typography>}
                                    secondary={
                                        <Typography
                                            noWrap
                                            variant="body2"
                                            sx={{
                                                fontSize: '12px',
                                                fontWeight: '500',
                                                color: 'ltheme.main',
                                                width: "100%"

                                            }}
                                        >Mp4 • 21.5 mb</Typography>}
                                />
                                <div>
                                    <IconButton
                                        aria-label="more"
                                        id="long-button"
                                        aria-controls={open ? 'long-menu' : undefined}
                                        aria-expanded={open ? 'true' : undefined}
                                        aria-haspopup="true"
                                        onClick={handleClick}
                                    >
                                        <MoreVertIcon sx={{ width: "20px", height: "20px" }} />
                                    </IconButton>
                                    <Menu
                                        id="long-menu"
                                        MenuListProps={{
                                            'aria-labelledby': 'long-button',
                                        }}
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleClose}
                                        PaperProps={{
                                            style: {
                                                maxHeight: ITEM_HEIGHT * 4.5,
                                                width: '20ch',
                                            },
                                        }}
                                    >
                                        {options.map((option) => (
                                            <MenuItem key={option} selected={option === 'Pyxis'} onClick={handleClose}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </div>
                            </ListItem>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <ListItem sx={{ px: "0" }}>
                                <ListItemAvatar sx={{ textAlign: "center" }}>
                                    <img style={{ maxWidth: "40px", maxHeight: "40px" }} src={getImages('doc_icon.png')} />
                                </ListItemAvatar>
                                <ListItemText
                                    primary={
                                        <Typography noWrap
                                            sx={{
                                                fontSize: "0.9rem",
                                                fontWeight: '600',
                                                color: 'secondary.main',
                                                lineHeight: '22px'
                                            }}
                                            variant="subtitle1">Network
                                        </Typography>}
                                    secondary={
                                        <Typography
                                            noWrap
                                            variant="body2"
                                            sx={{
                                                fontSize: '12px',
                                                fontWeight: '500',
                                                color: 'ltheme.main',
                                                width: "100%"

                                            }}
                                        >Doc • 21.5 mb</Typography>}
                                />
                                <div>
                                    <IconButton
                                        aria-label="more"
                                        id="long-button"
                                        aria-controls={open ? 'long-menu' : undefined}
                                        aria-expanded={open ? 'true' : undefined}
                                        aria-haspopup="true"
                                        onClick={handleClick}
                                    >
                                        <MoreVertIcon sx={{ width: "20px", height: "20px" }} />
                                    </IconButton>
                                    <Menu
                                        id="long-menu"
                                        MenuListProps={{
                                            'aria-labelledby': 'long-button',
                                        }}
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleClose}
                                        PaperProps={{
                                            style: {
                                                maxHeight: ITEM_HEIGHT * 4.5,
                                                width: '20ch',
                                            },
                                        }}
                                    >
                                        {options.map((option) => (
                                            <MenuItem key={option} selected={option === 'Pyxis'} onClick={handleClose}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </div>
                            </ListItem>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <ListItem sx={{ px: "0" }}>
                                <ListItemAvatar sx={{ textAlign: "center" }}>
                                    <img style={{ maxWidth: "40px", maxHeight: "40px" }} src={getImages("Vector.png")} />
                                </ListItemAvatar>
                                <ListItemText
                                    primary={
                                        <Typography noWrap
                                            sx={{
                                                fontSize: "0.9rem",
                                                fontWeight: '600',
                                                color: 'secondary.main',
                                                lineHeight: '22px'
                                            }}
                                            variant="subtitle1">Pitch call 2023-03-21
                                        </Typography>}
                                    secondary={
                                        <Typography
                                            noWrap
                                            variant="body2"
                                            sx={{
                                                fontSize: '12px',
                                                fontWeight: '500',
                                                color: 'ltheme.main',
                                                width: "100%"

                                            }}
                                        >Aac • 21.5 mb</Typography>}
                                />
                                <div>
                                    <IconButton
                                        aria-label="more"
                                        id="long-button"
                                        aria-controls={open ? 'long-menu' : undefined}
                                        aria-expanded={open ? 'true' : undefined}
                                        aria-haspopup="true"
                                        onClick={handleClick}
                                    >
                                        <MoreVertIcon sx={{ width: "20px", height: "20px" }} />
                                    </IconButton>
                                    <Menu
                                        id="long-menu"
                                        MenuListProps={{
                                            'aria-labelledby': 'long-button',
                                        }}
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleClose}
                                        PaperProps={{
                                            style: {
                                                maxHeight: ITEM_HEIGHT * 4.5,
                                                width: '20ch',
                                            },
                                        }}
                                    >
                                        {options.map((option) => (
                                            <MenuItem key={option} selected={option === 'Pyxis'} onClick={handleClose}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </div>
                            </ListItem>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <ListItem sx={{ px: "0" }}>
                                <ListItemAvatar sx={{ textAlign: "center" }}>
                                    <img style={{ maxWidth: "40px", maxHeight: "40px" }} src={getImages('jpeg.png')} />
                                </ListItemAvatar>
                                <ListItemText
                                    primary={
                                        <Typography noWrap
                                            sx={{
                                                fontSize: "0.9rem",
                                                fontWeight: '600',
                                                color: 'secondary.main',
                                                lineHeight: '22px'
                                            }}
                                            variant="subtitle1">Downloaded vector-art
                                        </Typography>}
                                    secondary={
                                        <Typography
                                            noWrap
                                            variant="body2"
                                            sx={{
                                                fontSize: '12px',
                                                fontWeight: '500',
                                                color: 'ltheme.main',
                                                width: "100%"

                                            }}
                                        >Png • 21.5 mb</Typography>}
                                />
                                <div>
                                    <IconButton
                                        aria-label="more"
                                        id="long-button"
                                        aria-controls={open ? 'long-menu' : undefined}
                                        aria-expanded={open ? 'true' : undefined}
                                        aria-haspopup="true"
                                        onClick={handleClick}
                                    >
                                        <MoreVertIcon sx={{ width: "20px", height: "20px" }} />
                                    </IconButton>
                                    <Menu
                                        id="long-menu"
                                        MenuListProps={{
                                            'aria-labelledby': 'long-button',
                                        }}
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleClose}
                                        PaperProps={{
                                            style: {
                                                maxHeight: ITEM_HEIGHT * 4.5,
                                                width: '20ch',
                                            },
                                        }}
                                    >
                                        {options.map((option) => (
                                            <MenuItem key={option} selected={option === 'Pyxis'} onClick={handleClose}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </div>
                            </ListItem>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <ListItem sx={{ px: "0" }}>
                                <ListItemAvatar sx={{ textAlign: "center" }}>
                                    <img style={{ maxWidth: "40px", maxHeight: "40px" }} src={getImages('doc_icon.png')} />
                                </ListItemAvatar>
                                <ListItemText
                                    primary={
                                        <Typography noWrap
                                            sx={{
                                                fontSize: "0.9rem",
                                                fontWeight: '600',
                                                color: 'secondary.main',
                                                lineHeight: '22px'
                                            }}
                                            variant="subtitle1">Project proposal
                                        </Typography>}
                                    secondary={
                                        <Typography
                                            noWrap
                                            variant="body2"
                                            sx={{
                                                fontSize: '12px',
                                                fontWeight: '500',
                                                color: 'ltheme.main',
                                                width: "100%"

                                            }}
                                        >Doc • 21.5 mb</Typography>}
                                />
                                <div>
                                    <IconButton
                                        aria-label="more"
                                        id="long-button"
                                        aria-controls={open ? 'long-menu' : undefined}
                                        aria-expanded={open ? 'true' : undefined}
                                        aria-haspopup="true"
                                        onClick={handleClick}
                                    >
                                        <MoreVertIcon sx={{ width: "20px", height: "20px" }} />
                                    </IconButton>
                                    <Menu
                                        id="long-menu"
                                        MenuListProps={{
                                            'aria-labelledby': 'long-button',
                                        }}
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleClose}
                                        PaperProps={{
                                            style: {
                                                maxHeight: ITEM_HEIGHT * 4.5,
                                                width: '20ch',
                                            },
                                        }}
                                    >
                                        {options.map((option) => (
                                            <MenuItem key={option} selected={option === 'Pyxis'} onClick={handleClose}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </div>
                            </ListItem>
                        </Grid>
                    </Grid>
                    {/* </List> */}
                </Box>
            </MainCard>
        </>
    )
}

export default ImportantDocuments