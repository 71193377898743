import React from 'react';
import {
    Box,
    Button,
    List,
    ListItemAvatar,
    ListItemButton,
    Stack,
    Typography
} from '@mui/material';
import MainCard from '../../commonComponents/MainCard';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import { getImages } from '../../commonComponents/const';
import { useTheme } from '@emotion/react';
import { Link, useNavigate } from 'react-router-dom';

const RecentEnquiries = () => {
    const theme = useTheme();
    const navigate = useNavigate()
    const handleViewAllClick = () => {
       navigate('/solution-enquiries');
   };
   const handleOnClick = ()=>{
    navigate('/kanban-investment-enquiries')
   }

    return (
        <>
            <MainCard content={false}
                sx={{
                    backgroundColor: "#ECF6FF",
                    border: "none",
                    padding: "15px 25px",
                    minHeight: '420px',
                    [theme.breakpoints.down("md")]: {
                        padding: "15px",
                    },
                }}>
                <Box sx={{ p: 0, pb: 0 }}>
                    <Stack spacing={2} sx={{ display: "flex", flexDirection: "inherit", justifyContent: "space-between", alignItems: "center", width: "100%", marginBottom: "10px" }}>
                        <Typography variant="h6" color="textSecondary"
                            sx={{
                                fontSize: "1.2rem",
                                fontWeight: "600",
                                color: "secondary.main",
                                marginBottom: "1rem"
                            }}
                        >
                            Recent Responses
                        </Typography>
                        <Button onClick={handleViewAllClick} variant="text" style={{ marginTop: "0" }} sx={{ fontSize: "12px", fontWeight: "600", textTransform: "inherit" }}>
                            View all <ArrowForwardIosOutlinedIcon sx={{ marginLeft: "5px", fill: "primary.main", width: "12px", height: "12px" }} />
                        </Button>
                    </Stack>
                    <List
                        component="nav"
                        sx={{
                            px: 0,
                            py: 0,
                            '& .MuiListItemButton-root': {
                                py: 1.5,
                                // '& .MuiAvatar-root': avatarSX,
                                // '& .MuiListItemSecondaryAction-root': { ...actionSX, position: 'relative' }
                            }
                        }}
                    >
                        <ListItemButton
                         onClick={handleOnClick}
                            sx={{
                                minHeight: "65px",
                                justifyContent: "space-between",
                                alignItems: "center",
                                background: "#FFFFFF",
                                borderRadius: "10px",
                                marginTop: "5px"
                            }}
                        >
                            <Stack
                                sx={{
                                    flexDirection: "inherit",
                                    alignItems: "center",
                                    width: "60%"
                                }}
                            >
                                <ListItemAvatar
                                    sx={{
                                        display: "inline-block",
                                        marginRight: "7px"
                                    }}
                                >
                                    <img src={getImages('image_172.png')} />
                                </ListItemAvatar>
                                <Typography variant="subtitle1"
                                    sx={{
                                        fontSize: "0.95rem",
                                        color: "secondary.main",
                                        fontWeight: "600",
                                        lineHeight: "20px"
                                    }}
                                >TRUSTDOCK Digital ID</Typography>
                            </Stack>
                            <Typography variant="subtitle1" sx={{
                                fontSize: "0.95rem",
                                color: "primary.main",
                                fontWeight: "600",
                                width: "10%",
                                textAlign: "center"
                            }}>IB1</Typography>
                            <Typography variant="subtitle1"
                                sx={{
                                    fontSize: "0.95rem",
                                    color: "ltheme.main",
                                    fontWeight: "600"
                                }}>
                                <MessageOutlinedIcon sx={{
                                    verticalAlign: "middle",
                                    fill: "ltheme.main",
                                    width: "20px",
                                    height: "20px"
                                }} /> 13</Typography>
                            <ArrowForwardIosOutlinedIcon sx={{
                                fill: "primary.main",
                                width: "18px",
                                height: "18px"
                            }} />
                        </ListItemButton>
                        <ListItemButton sx={{
                            minHeight: "65px",
                            justifyContent: "space-between",
                            alignItems: "center",
                            background: "#FFFFFF",
                            borderRadius: "10px",
                            marginTop: "5px"
                        }}>
                            <Stack sx={{
                                flexDirection: "inherit",
                                alignItems: "center",
                                width: "60%"
                            }}>
                                <ListItemAvatar sx={{
                                    display: "inline-block",
                                    marginRight: "7px"
                                }}>
                                    <img src={getImages('image_154.png')} />
                                </ListItemAvatar>
                                <Typography variant="subtitle1" sx={{
                                    fontSize: "0.95rem",
                                    color: "secondary.main",
                                    fontWeight: "600",
                                    lineHeight: "20px",
                                    maxHeight: '40px',
                                    overflow: 'hidden'
                                }}>CIAM - Customer Identity & Access Management</Typography>
                            </Stack>
                            <Typography variant="subtitle1" sx={{
                                fontSize: "0.95rem",
                                color: "primary.main",
                                fontWeight: "600",
                                width: "10%",
                                textAlign: "center"
                            }}>LR</Typography>
                            <Typography variant="subtitle1"
                                sx={{
                                    fontSize: "0.95rem",
                                    color: "ltheme.main",
                                    fontWeight: "600"
                                }}>
                                <MessageOutlinedIcon sx={{
                                    verticalAlign: "middle",
                                    fill: "ltheme.main",
                                    width: "20px",
                                    height: "20px"
                                }} /> 27</Typography>
                            <ArrowForwardIosOutlinedIcon sx={{
                                fill: "primary.main",
                                width: "18px",
                                height: "18px"
                            }} />
                        </ListItemButton>
                        <ListItemButton sx={{
                            minHeight: "65px",
                            justifyContent: "space-between",
                            alignItems: "center",
                            background: "#FFFFFF",
                            borderRadius: "10px",
                            marginTop: "5px"
                        }}>
                            <Stack sx={{
                                flexDirection: "inherit",
                                alignItems: "center",
                                width: "60%"
                            }}>
                                <ListItemAvatar sx={{
                                    display: "inline-block",
                                    marginRight: "7px"
                                }}>
                                    <img src={getImages('image_53.png')} />
                                </ListItemAvatar>
                                <Typography variant="subtitle1" sx={{
                                    fontSize: "0.95rem",
                                    color: "secondary.main",
                                    fontWeight: "600",
                                    lineHeight: "20px",
                                    maxHeight: '40px',
                                    overflow: 'hidden'
                                }}>Advanced Solutions in Digital Healthcare</Typography>
                            </Stack>
                            <Typography variant="subtitle1" sx={{
                                fontSize: "0.95rem",
                                color: "primary.main",
                                fontWeight: "600",
                                width: "10%",
                                textAlign: "center"
                            }}>DHR</Typography>
                            <Typography variant="subtitle1"
                                sx={{
                                    fontSize: "0.95rem",
                                    color: "ltheme.main",
                                    fontWeight: "600"
                                }}>
                                <MessageOutlinedIcon sx={{
                                    verticalAlign: "middle",
                                    fill: "ltheme.main",
                                    width: "20px",
                                    height: "20px"
                                }} /> 69</Typography>
                            <ArrowForwardIosOutlinedIcon sx={{
                                fill: "primary.main",
                                width: "18px",
                                height: "18px"
                            }} />
                        </ListItemButton>
                        <ListItemButton sx={{
                            minHeight: "65px",
                            justifyContent: "space-between",
                            alignItems: "center",
                            background: "#FFFFFF",
                            borderRadius: "10px",
                            marginTop: "5px"
                        }}>
                            <Stack sx={{
                                flexDirection: "inherit",
                                alignItems: "center",
                                width: "60%"
                            }}>
                                <ListItemAvatar sx={{
                                    display: "inline-block",
                                    marginRight: "7px"
                                }}>
                                    <img src={getImages('image_155.png')} />
                                </ListItemAvatar>
                                <Typography variant="subtitle1" sx={{
                                    fontSize: "0.95rem",
                                    color: "secondary.main",
                                    fontWeight: "600",
                                    lineHeight: "20px",
                                    maxHeight: '40px',
                                    overflow: 'hidden'
                                }}>Blockpass Identity for a Connected World</Typography>
                            </Stack>
                            <Typography variant="subtitle1" sx={{
                                fontSize: "0.95rem",
                                color: "primary.main",
                                fontWeight: "600",
                                width: "10%",
                                textAlign: "center"
                            }}>IB1</Typography>
                            <Typography variant="subtitle1"
                                sx={{
                                    fontSize: "0.95rem",
                                    color: "ltheme.main",
                                    fontWeight: "600"
                                }}>
                                <MessageOutlinedIcon sx={{
                                    verticalAlign: "middle",
                                    fill: "ltheme.main",
                                    width: "20px",
                                    height: "20px"
                                }} /> 04</Typography>
                            <ArrowForwardIosOutlinedIcon sx={{
                                fill: "primary.main",
                                width: "18px",
                                height: "18px"
                            }} />
                        </ListItemButton>
                        <ListItemButton sx={{
                            minHeight: "65px",
                            justifyContent: "space-between",
                            alignItems: "center",
                            background: "#FFFFFF",
                            borderRadius: "10px",
                            marginTop: "5px"
                        }}>
                            <Stack sx={{
                                flexDirection: "inherit",
                                alignItems: "center",
                                width: "60%"
                            }}>
                                <ListItemAvatar sx={{
                                    display: "inline-block",
                                    marginRight: "7px"
                                }}>
                                    <img src={getImages('image_153.png')} />
                                </ListItemAvatar>
                                <Typography variant="subtitle1" sx={{
                                    fontSize: "0.95rem",
                                    color: "secondary.main",
                                    fontWeight: "600",
                                    lineHeight: "20px",
                                    maxHeight: '40px',
                                    overflow: 'hidden'
                                }}>Polysign</Typography>
                            </Stack>
                            <Typography variant="subtitle1" sx={{
                                fontSize: "0.95rem",
                                color: "primary.main",
                                fontWeight: "600",
                                width: "10%",
                                textAlign: "center"
                            }}>SCE</Typography>
                            <Typography variant="subtitle1"
                                sx={{
                                    fontSize: "0.95rem",
                                    color: "ltheme.main",
                                    fontWeight: "600"
                                }}>
                                <MessageOutlinedIcon sx={{
                                    verticalAlign: "middle",
                                    fill: "ltheme.main",
                                    width: "20px",
                                    height: "20px"
                                }} /> 69</Typography>
                            <ArrowForwardIosOutlinedIcon sx={{
                                fill: "primary.main",
                                width: "18px",
                                height: "18px"
                            }} />
                        </ListItemButton>
                    </List>
                </Box>
            </MainCard>
        </>
    )
}

export default RecentEnquiries