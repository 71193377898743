import React, { useState } from 'react';
import {
    Box,
    Button,
    MenuItem,
    Stack,
    Typography,
    Menu,
    styled,
    InputBase,
    Radio,
    FormControlLabel,
    Select,
    FormControl,
    Grid,
    Card,
    CardContent,
    ListItemText,
    List,
    ListItemAvatar,
    Avatar,
    ListItem,
    Pagination,
    PaginationItem,
    ListItemButton,
} from '@mui/material';
import { useTheme } from '@emotion/react';
import { Link } from 'react-router-dom';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SearchIcon from '@mui/icons-material/Search';
import { ArrowDropUpSharp, KeyboardArrowDownOutlined } from '@mui/icons-material';
import NorthOutlinedIcon from '@mui/icons-material/NorthOutlined';
import SouthOutlinedIcon from '@mui/icons-material/SouthOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardDoubleArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowLeftOutlined';
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';
import { getImages } from '../../commonComponents/const';
import MainCard from '../../commonComponents/MainCard';
import Modals from '../../commonComponents/Modals';
import InvestmentsProcessModal from '../../commonComponents/MySolutions/InvestmentsProcessModal';
import InterestInvestmentModal from '../../commonComponents/Portfolio/InterestInvestmentModal';
import InterestCommitmentModal from '../../commonComponents/Portfolio/InterestCommitmentModal';
import PaymentConfirmModal from '../../commonComponents/Portfolio/PaymentConfirmModal';

const Portfolio = () => {
    const theme = useTheme();
    const Search = styled('div')(({ theme }) => ({
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: '#DBEBF8',
        '&:hover': {
            backgroundColor: '#DBEBF8',
        },
        marginLeft: 0,
        marginBottom: 0,
        width: '300px',
        [theme.breakpoints.down('md')]: {
            width: 'calc(100% - 180px)',
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    }));
    const SearchIconWrapper = styled('div')(({ theme }) => ({
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        right: '0',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }));

    const StyledInputBase = styled(InputBase)(({ theme }) => ({
        color: '#106EED',
        width: '100%',
        '& .MuiInputBase-input': {
            padding: theme.spacing(1.6, 0, 1.9, 1.5),
            paddingRight: `calc(1em + ${theme.spacing(4)})`,
            transition: theme.transitions.create('width'),
            width: '100%',
            fontSize: '12px',
            fontWeight: '500',
            '@media (max-width:1600px)': {
                paddingTop: "11px",
                paddingBottom: "11px",
            },
        },
    }));
    // Sort and Filter
    const [openSort, setOpenSort] = useState(false);
    const [selectedSortOption, setSelectedSortOption] = useState('');

    const [openFilter, setOpenFilter] = useState(false);
    const [anchorElSort, setAnchorElSort] = useState(null);
    const [anchorElFilter, setAnchorElFilter] = useState(null);
    const handleClickSort = (event) => {
        setAnchorElSort(event.currentTarget);
        setOpenSort(!openSort);
    };
    const handleCloseSort = (event) => {
        setAnchorElSort(null);
        setOpenSort(false);
        setSelectedSortOption(event.target.value);
    };
    const handleClickFilter = (event) => {
        setAnchorElFilter(event.currentTarget);
        setOpenFilter(!openFilter);
    };
    const handleCloseFilter = () => {
        setAnchorElFilter(null);
        setOpenFilter(false);
    };
    // Select 
    const [designation, setDesignation] = useState('');
    const handleChange = (event) => {
        setDesignation(event.target.value);
    };
    // Pagination
    const [page, setPage] = useState(1);
    const handlePaginationChange = (event, value) => {
        setPage(value);
    };
    // Invested Modal
    const [openInvested, setOpenInvested] = useState(false);
    const handleInvested = () => {
        setOpenInvested(true)
    }
    const closeInvested = () => {
        setOpenInvested(false)
    }
    // Commitment Modal
    const [openCommitment, setOpenCommitment] = useState(false);
    const handleCommitment = () => {
        setOpenCommitment(true)
    }
    const closeCommitment = () => {
        setOpenCommitment(false)
    }
    // Confirm Payment Modal
    const [openConfirmPayment, setOpenConfirmPayment] = useState(false);
    const handleConfirmPayment = () => {
        setOpenConfirmPayment(true)
        setOpenCommitment(false)
    }
    const closeConfirmPayment = () => {
        setOpenConfirmPayment(false)
    }
    const goBackCp = () => {
        setOpenCommitment(true)
        setOpenConfirmPayment(false)
    }
    return (
        <>
            <Box>
                <Typography
                    underline="hover"
                    key="2"
                    color="inherit"
                    sx={{
                        color: '#2A589C',
                        textDecoration: "none",
                        fontSize: "1.4rem",
                        fontWeight: "700",
                        color: '#073985',
                        [theme.breakpoints.down('md')]: {
                            fontSize: '1.1rem',
                        },
                    }}
                >Investment Summary</Typography>
                <Grid container mt={1} columnSpacing={2}>
                    <Grid item xs={12} md={6} lg={3}>
                        <Card
                            sx={{
                                boxShadow: 'none',
                                mb: 2
                            }}
                        >
                            <CardContent
                                style={{
                                    paddingTop: "25px"
                                }}
                            >
                                <Typography
                                    variant="body2"
                                    sx={{
                                        fontSize: '1.1rem',
                                        fontWeight: '500',
                                        color: 'ltheme.main',
                                        [theme.breakpoints.down('lg')]: {
                                            fontSize: '1rem',
                                        },
                                    }}
                                >
                                    Portfolio Value
                                    <InfoOutlinedIcon
                                        sx={{
                                            verticalAlign: 'middle',
                                            ml: 0.5,
                                            fontSize: '17px'
                                        }}
                                    /></Typography>
                                <Typography
                                    variant="h6"
                                    sx={{
                                        fontSize: '2.4rem',
                                        fontWeight: '700',
                                        lineHeight: '2.7rem',
                                        color: 'lightSecondary.main',
                                        mb: 0.5,
                                        mt: 0.5,
                                        [theme.breakpoints.down('xl')]: {
                                            fontSize: '2.1rem',
                                        },
                                        [theme.breakpoints.down('md')]: {
                                            fontSize: '1.7rem',
                                            lineHeight: '2rem'
                                        },
                                    }}
                                >$513.04k</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <Card
                            sx={{
                                boxShadow: 'none',
                                mb: 2
                            }}
                        >
                            <CardContent
                                style={{
                                    paddingTop: "25px"
                                }}
                            >
                                <Typography
                                    variant="body2"
                                    sx={{
                                        fontSize: '1.1rem',
                                        fontWeight: '500',
                                        color: 'ltheme.main',
                                        [theme.breakpoints.down('lg')]: {
                                            fontSize: '1rem',
                                        },
                                    }}
                                >
                                    Total Investments
                                    <InfoOutlinedIcon
                                        sx={{
                                            verticalAlign: 'middle',
                                            ml: 0.5,
                                            fontSize: '17px'
                                        }}
                                    /></Typography>
                                <Typography
                                    variant="h6"
                                    sx={{
                                        fontSize: '2.4rem',
                                        fontWeight: '700',
                                        lineHeight: '2.7rem',
                                        color: 'lightSecondary.main',
                                        mb: 0.5,
                                        mt: 0.5,
                                        [theme.breakpoints.down('xl')]: {
                                            fontSize: '2.1rem',
                                        },
                                        [theme.breakpoints.down('md')]: {
                                            fontSize: '1.7rem',
                                            lineHeight: '2rem'
                                        },
                                    }}
                                >$490k
                                    <Box component="span"
                                        sx={{
                                            fontSize: '1rem',
                                            lineHeight: '1rem',
                                            color: '#106EED',
                                            marginLeft: '10px',
                                            fontWeight: '600',
                                            '@media (max-width:1450px) and (min-width: 1300px)': {
                                                fontSize: '0.5rem'
                                            },
                                            [theme.breakpoints.down('xl')]: {
                                                fontSize: '0.85rem',
                                            },
                                        }}
                                    >in 17 Solutions</Box></Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <Card
                            sx={{
                                boxShadow: 'none',
                                mb: 2
                            }}
                        >
                            <CardContent
                                style={{
                                    paddingTop: "25px"
                                }}
                            >
                                <Typography
                                    variant="body2"
                                    sx={{
                                        fontSize: '1.1rem',
                                        fontWeight: '500',
                                        color: 'ltheme.main',
                                        [theme.breakpoints.down('lg')]: {
                                            fontSize: '1rem',
                                        },
                                    }}
                                >
                                    Total Commitment
                                    <InfoOutlinedIcon
                                        sx={{
                                            verticalAlign: 'middle',
                                            ml: 0.5,
                                            fontSize: '17px'
                                        }}
                                    /></Typography>
                                <Typography
                                    variant="h6"
                                    sx={{
                                        fontSize: '2.4rem',
                                        fontWeight: '700',
                                        lineHeight: '2.7rem',
                                        color: 'lightSecondary.main',
                                        mb: 0.5,
                                        mt: 0.5,
                                        [theme.breakpoints.down('xl')]: {
                                            fontSize: '2.1rem',
                                        },
                                        [theme.breakpoints.down('md')]: {
                                            fontSize: '1.7rem',
                                            lineHeight: '2rem'
                                        },
                                    }}
                                >$200k
                                    <Box component="span"
                                        sx={{
                                            fontSize: '1rem',
                                            lineHeight: '1rem',
                                            color: '#106EED',
                                            marginLeft: '10px',
                                            fontWeight: '600',
                                            '@media (max-width:1450px) and (min-width: 1300px)': {
                                                fontSize: '0.5rem'
                                            },
                                            [theme.breakpoints.down('xl')]: {
                                                fontSize: '0.85rem',
                                            },
                                        }}
                                    >in 4 Solutions</Box></Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <Card
                            sx={{
                                boxShadow: 'none',
                                mb: 2
                            }}
                        >
                            <CardContent
                                style={{
                                    paddingTop: "25px"
                                }}
                            >
                                <Typography
                                    variant="body2"
                                    sx={{
                                        fontSize: '1.1rem',
                                        fontWeight: '500',
                                        color: 'ltheme.main',
                                        [theme.breakpoints.down('lg')]: {
                                            fontSize: '1rem',
                                        },
                                    }}
                                >
                                    Overall Return
                                    <InfoOutlinedIcon
                                        sx={{
                                            verticalAlign: 'middle',
                                            ml: 0.5,
                                            fontSize: '17px'
                                        }}
                                    /></Typography>
                                <Typography
                                    variant="h6"
                                    sx={{
                                        fontSize: '2.4rem',
                                        fontWeight: '700',
                                        lineHeight: '2.7rem',
                                        color: 'lightSecondary.main',
                                        mb: 0.5,
                                        mt: 0.5,
                                        [theme.breakpoints.down('xl')]: {
                                            fontSize: '2.1rem',
                                        },
                                        [theme.breakpoints.down('md')]: {
                                            fontSize: '1.7rem',
                                            lineHeight: '2rem'
                                        },
                                    }}
                                >$23.04k
                                    <span
                                        style={{
                                            fontSize: '1rem',
                                            color: '#7CDDA3',
                                            lineHeight: '1rem',
                                            fontWeight: '600'
                                        }}
                                    >
                                        <ArrowDropUpSharp
                                            sx={{
                                                verticalAlign: 'middle'
                                            }}
                                        /> 2.4%
                                    </span>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Box>

            <Grid container mt={1} mb={3} columnSpacing={2}>
                <Grid item xs={12} md={6} lg={6} sx={{ mb: 2 }}>
                    <MainCard content={false}
                        sx={{
                            backgroundColor: "#ECF6FF",
                            border: "none",
                            padding: "20px",
                            boxShadow: "none",
                            borderRadius: "10px"
                        }}
                    >

                        <Typography variant='h6'
                            sx={{
                                color: 'secondary.main',
                                fontSize: "1.1rem",
                                fontWeight: "700",
                                mb: 1
                            }}
                        >
                            Investments
                        </Typography>
                        <Stack
                            direction="row"
                            sx={{
                                justifyItems: 'center',
                                justifyContent: 'space-between',
                                mt: 0,
                                [theme.breakpoints.down('md')]: {
                                    flexWrap: 'wrap'
                                }
                            }}
                        >
                            <Search
                                sx={{
                                    width: 'calc(100% - 150px)',
                                    backgroundColor: '#DBEBF8',
                                    mb: 2.5,
                                    mr: 2,
                                    [theme.breakpoints.down('md')]: {
                                        width: 'calc(100% - 220px)',
                                    },
                                    [theme.breakpoints.down('sm')]: {
                                        mr: 0,
                                        width: '100%',
                                    }
                                }}
                            >
                                <SearchIconWrapper>
                                    <SearchIcon sx={{ color: "lightSecondary.main" }} />
                                </SearchIconWrapper>
                                <StyledInputBase
                                    id="searchBox"
                                    placeholder="Search investment"
                                    inputProps={{ 'aria-label': 'search' }}
                                />
                            </Search>
                            <Box
                                sx={{
                                    mr: 2,
                                    mb: 2.5,
                                    [theme.breakpoints.down('sm')]: {
                                        width: "calc(50% - 0.5rem)",
                                        mr: '0.5rem',
                                    }
                                }}
                            >
                                <Button
                                    id="basic-button"
                                    aria-controls={openSort ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={openSort ? 'true' : undefined}
                                    onClick={handleClickSort}
                                    sx={{
                                        background: "#fff",
                                        fontSize: "0.9rem",
                                        color: "lightSecondary.main",
                                        textTransform: "inherit",
                                        fontWeight: "600",
                                        p: "10px 20px",
                                        '&:hover': {
                                            background: "#fff",
                                        },
                                        [theme.breakpoints.down('md')]: {
                                            width: "100%"
                                        }
                                    }}
                                >
                                    Sort
                                    <KeyboardArrowDownOutlined
                                        sx={{

                                        }}
                                    />
                                </Button>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorElSort}
                                    open={openSort}
                                    onClose={handleCloseSort}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                    sx={{
                                        '& .MuiMenu-paper': {
                                            borderRadius: "10px",
                                            border: "solid 1px #106EED"
                                        }
                                    }}
                                >
                                    <MenuItem
                                        sx={{
                                            '& .MuiFormControlLabel-label': {
                                                fontSize: "0.9rem",
                                                color: "#2A589C",
                                                fontWeight: "600",
                                            }
                                        }}
                                        onClick={handleCloseSort}>
                                        <FormControlLabel
                                            value="CreatedUp" control={<Radio sx={{ paddingY: 0, color: "#2A589C" }} checked={selectedSortOption === 'CreatedUp'} />} label="Created" />
                                        <NorthOutlinedIcon sx={{
                                            fontSize: "1.1rem",
                                            color: "#2A589C"
                                        }} />
                                    </MenuItem>
                                    <MenuItem
                                        sx={{
                                            '& .MuiFormControlLabel-label': {
                                                fontSize: "0.9rem",
                                                color: "#2A589C",
                                                fontWeight: "600",
                                            }
                                        }}
                                        onClick={handleCloseSort}>
                                        <FormControlLabel
                                            value="CreatedDown" control={<Radio sx={{ paddingY: 0, color: "#2A589C" }} checked={selectedSortOption === 'CreatedDown'} />} label="Created" />
                                        <SouthOutlinedIcon sx={{
                                            fontSize: "1.1rem",
                                            color: "#2A589C"
                                        }} />
                                    </MenuItem>
                                    <MenuItem
                                        sx={{
                                            '& .MuiFormControlLabel-label': {
                                                fontSize: "0.9rem",
                                                color: "#2A589C",
                                                fontWeight: "600",
                                            }
                                        }}
                                        onClick={handleCloseSort}>
                                        <FormControlLabel
                                            value="name-a-z" control={<Radio sx={{ paddingY: 0, color: "#2A589C" }} checked={selectedSortOption === 'name-a-z'} />} label="Name A-Z" />
                                    </MenuItem>
                                    <MenuItem
                                        sx={{
                                            '& .MuiFormControlLabel-label': {
                                                fontSize: "0.9rem",
                                                color: "#2A589C",
                                                fontWeight: "600",
                                            }
                                        }}
                                        onClick={handleCloseSort}>
                                        <FormControlLabel
                                            value="name-z-a" control={<Radio sx={{ paddingY: 0, color: "#2A589C" }} checked={selectedSortOption === 'name-z-a'} />} label="Name Z-A" />
                                    </MenuItem>
                                </Menu>
                            </Box>
                            <Box
                                sx={{
                                    [theme.breakpoints.down('sm')]: {
                                        width: "50%"
                                    }
                                }}
                            >
                                <Button
                                    id="basic-button"
                                    aria-controls={openFilter ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={openFilter ? 'true' : undefined}
                                    onClick={handleClickFilter}
                                    sx={{
                                        background: "#fff",
                                        fontSize: "0.9rem",
                                        color: "lightSecondary.main",
                                        textTransform: "inherit",
                                        fontWeight: "600",
                                        p: "10px 20px",
                                        '&:hover': {
                                            background: "#fff",
                                        },
                                        [theme.breakpoints.down('sm')]: {
                                            width: "100%"
                                        }
                                    }}
                                >
                                    Filter
                                    <KeyboardArrowDownOutlined
                                    />
                                </Button>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorElFilter}
                                    open={openFilter}
                                    onClose={handleCloseFilter}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                    sx={{
                                        '& .MuiMenu-paper': {
                                            borderRadius: "10px",
                                            border: "solid 1px #106EED",
                                            minWidth: "280px",
                                            px: 2.3,
                                            py: 1
                                        }
                                    }}
                                >
                                    <Box sx={{
                                        mb: 1.5
                                    }}>
                                        <Typography
                                            sx={{
                                                fontSize: "12px",
                                                fontWeight: '500',
                                                color: 'ltheme.main',
                                                lineHeight: '24px',
                                            }}
                                            variant="body2">
                                            Select Industry
                                        </Typography>
                                        <FormControl fullWidth
                                            sx={{
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    border: 'none'
                                                }
                                            }}
                                        >
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={designation}
                                                onChange={handleChange}
                                                displayEmpty
                                                IconComponent={KeyboardArrowDownOutlinedIcon}
                                                sx={{
                                                    background: "#ECF6FF",
                                                    '& .MuiSelect-select': {
                                                        minHeight: "20px",
                                                        padding: "11px 14px",
                                                        color: "lightSecondary.main",
                                                        fontWeight: "600",
                                                        fontSize: "0.9rem"
                                                    },
                                                    '& .MuiSelect-icon': {
                                                        color: 'lightSecondary.main'
                                                    },
                                                }}
                                            >
                                                <MenuItem>Select Industry</MenuItem>
                                                <MenuItem value={2}>Insurance</MenuItem>
                                                <MenuItem value={3}>Citizen service</MenuItem>
                                                <MenuItem value={4}>Healthcare</MenuItem>
                                                <MenuItem value={5}>Supply chain</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                    <Box>
                                        <Typography
                                            sx={{
                                                fontSize: "12px",
                                                fontWeight: '500',
                                                color: 'ltheme.main',
                                                lineHeight: '24px',
                                            }}
                                            variant="body2">
                                            Select Type
                                        </Typography>
                                        <FormControl fullWidth
                                            sx={{
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    border: 'none'
                                                }
                                            }}
                                        >
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={designation}
                                                onChange={handleChange}
                                                displayEmpty
                                                IconComponent={KeyboardArrowDownOutlinedIcon}
                                                sx={{
                                                    background: "#ECF6FF",
                                                    '& .MuiSelect-select': {
                                                        minHeight: "20px",
                                                        padding: "11px 14px",
                                                        color: "lightSecondary.main",
                                                        fontWeight: "600",
                                                        fontSize: "0.9rem"
                                                    },
                                                    '& .MuiSelect-icon': {
                                                        color: 'lightSecondary.main'
                                                    },
                                                }}
                                            >
                                                <MenuItem>Select Type</MenuItem>
                                                <MenuItem value={12}>All</MenuItem>
                                                <MenuItem value={13}>Starred</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                    <Box sx={{
                                        display: 'flex',
                                        gap: 2,
                                        mt: 2
                                    }}>
                                        <Box sx={{
                                            width: '50%'
                                        }}>
                                            <Button variant="outlined"
                                                sx={{
                                                    width: "100%",
                                                    textTransform: 'inherit',
                                                    p: "7px 15px"
                                                }}
                                            >
                                                Clear
                                            </Button>
                                        </Box>
                                        <Box sx={{
                                            width: '50%'
                                        }}>
                                            <Button variant="contained"
                                                sx={{
                                                    width: "100%",
                                                    textTransform: 'inherit',
                                                    p: "7px 15px",
                                                    boxShadow: 'none'
                                                }}>Apply
                                            </Button>
                                        </Box>
                                    </Box>
                                </Menu>
                            </Box>
                        </Stack>
                        <List
                            sx={{
                                p: "0",
                                '& .MuiListItem-root + .MuiListItem-root': {
                                    mt: 1.3
                                }
                            }}
                        >
                            <ListItem
                                sx={{
                                    p: 0,
                                }}
                            >
                                <ListItemButton
                                    onClick={handleInvested}
                                    sx={{
                                        padding: '10px 24px',
                                        borderRadius: '10px',
                                        background: '#fff',
                                        [theme.breakpoints.down('sm')]: {
                                            pl:'10px'
                                        }
                                    }}
                                >
                                    <ListItemAvatar
                                        sx={{
                                            minWidth: '108px',
                                            '& img': {
                                                maxWidth: '108px'
                                            },
                                            [theme.breakpoints.down('lg')]: {
                                                minWidth: '80px',
                                                '& img': {
                                                    maxWidth: '80px'
                                                },
                                            },
                                            [theme.breakpoints.down('md')]: {
                                                minWidth: '60px',
                                                '& img': {
                                                    maxWidth: '60px'
                                                },
                                            }
                                        }}
                                    >
                                        <img src={getImages('image1.png')} alt='' />
                                    </ListItemAvatar>
                                    <ListItemText
                                        sx={{
                                            pl: '20px',
                                            width: "60%",
                                            [theme.breakpoints.down('sm')]: {
                                                pl:'10px'
                                            }
                                        }}>
                                        <Typography noWrap
                                            sx={{
                                                fontSize: "1.2rem",
                                                fontWeight: '700',
                                                color: 'secondary.main',
                                                lineHeight: '22px',
                                                maxWidth: '350px',
                                                [theme.breakpoints.down('sm')]: {
                                                    maxWidth: '110px',
                                                    fontSize: '0.8rem'
                                                }
                                            }}
                                            variant="h6">TRUSTDOCK Digital ID
                                        </Typography>
                                        <Typography
                                            noWrap
                                            variant="body2"
                                            sx={{
                                                fontSize: '1rem',
                                                fontWeight: '600',
                                                color: 'lightSecondary.main',
                                                maxWidth: '220px',
                                                mb: 0.5,
                                                [theme.breakpoints.down('sm')]: {
                                                    maxWidth: '110px',
                                                    fontSize: '0.8rem'
                                                }
                                            }}
                                        >Industry: Insurance</Typography>
                                        <Typography
                                            noWrap
                                            variant="body2"
                                            sx={{
                                                color: "ltheme.main",
                                                fontSize: '0.9rem',
                                                fontWeight: '500',
                                                maxWidth: '220px',
                                                [theme.breakpoints.down('sm')]: {
                                                    maxWidth: '110px',
                                                    fontSize: '0.8rem'
                                                }
                                            }}
                                        >12 Feb 2023</Typography>
                                    </ListItemText>
                                    <ListItemText
                                        sx={{
                                            width: "calc(40% - 56px)"
                                        }}
                                    >
                                        <Stack alignItems="flex-end">
                                            <Typography
                                                variant="body2" noWrap
                                                sx={{
                                                    color: "ltheme.main",
                                                    fontSize: '0.9rem',
                                                    fontWeight: '500',
                                                    maxWidth: '220px',
                                                    [theme.breakpoints.down('sm')]: {
                                                        maxWidth: '110px'
                                                    }
                                                }}
                                            >
                                                Invested
                                            </Typography>
                                            <Box component="span"
                                                sx={{
                                                    fontSize: "1.4rem",
                                                    fontWeight: '700',
                                                    color: 'lightSecondary.main',
                                                    lineHeight: '1.6rem',
                                                }}>
                                                $50k
                                            </Box>
                                        </Stack>
                                    </ListItemText>
                                </ListItemButton>
                            </ListItem>
                            <ListItem
                                sx={{
                                    p: 0,
                                }}
                            >
                                <ListItemButton
                                    onClick={handleInvested}
                                    sx={{
                                        padding: '10px 24px',
                                        borderRadius: '10px',
                                        background: '#fff',
                                        [theme.breakpoints.down('sm')]: {
                                            pl:'10px'
                                        }
                                    }}
                                >
                                    <ListItemAvatar
                                        sx={{
                                            minWidth: '108px',
                                            '& img': {
                                                maxWidth: '108px'
                                            },
                                            [theme.breakpoints.down('lg')]: {
                                                minWidth: '80px',
                                                '& img': {
                                                    maxWidth: '80px'
                                                },
                                            },
                                            [theme.breakpoints.down('md')]: {
                                                minWidth: '60px',
                                                '& img': {
                                                    maxWidth: '60px'
                                                },
                                            }
                                        }}
                                    >
                                        <img src={getImages('image2.png')} alt='' />
                                    </ListItemAvatar>
                                    <ListItemText
                                        sx={{
                                            pl: '20px',
                                            width: "60%",
                                            [theme.breakpoints.down('sm')]: {
                                                pl:'10px'
                                            }
                                        }}>
                                        <Typography noWrap
                                            sx={{
                                                fontSize: "1.2rem",
                                                fontWeight: '700',
                                                color: 'secondary.main',
                                                lineHeight: '22px',
                                                [theme.breakpoints.down('xl')]: {
                                                    maxWidth: '320px'
                                                },
                                                [theme.breakpoints.down('lg')]: {
                                                    maxWidth: '220px'
                                                },
                                                [theme.breakpoints.down('md')]: {
                                                    maxWidth: '160px',
                                                    fontSize: '1rem'
                                                },
                                                [theme.breakpoints.down('sm')]: {
                                                    maxWidth: '100px',
                                                    fontSize: '1rem'
                                                },
                                            }}
                                            variant="h6">The Avaneer Ecosystem
                                        </Typography>
                                        <Typography
                                            noWrap
                                            variant="body2"
                                            sx={{
                                                fontSize: '1rem',
                                                fontWeight: '600',
                                                color: 'lightSecondary.main',
                                                maxWidth: '220px',
                                                mb: 0.5,
                                                [theme.breakpoints.down('sm')]: {
                                                    maxWidth: '110px',
                                                    fontSize: '0.8rem'
                                                }
                                            }}
                                        >Industry: Citizen service</Typography>
                                        <Typography
                                            noWrap
                                            variant="body2"
                                            sx={{
                                                color: "ltheme.main",
                                                fontSize: '0.9rem',
                                                fontWeight: '500',
                                                maxWidth: '220px',
                                                [theme.breakpoints.down('sm')]: {
                                                    maxWidth: '110px',
                                                    fontSize: '0.8rem'
                                                }
                                            }}
                                        >12 Feb 2023</Typography>
                                    </ListItemText>
                                    <ListItemText
                                        sx={{
                                            width: "calc(40% - 56px)"
                                        }}
                                    >
                                        <Stack alignItems="flex-end">
                                            <Typography
                                                variant="body2" noWrap
                                                sx={{
                                                    color: "ltheme.main",
                                                    fontSize: '0.9rem',
                                                    fontWeight: '500',
                                                    maxWidth: '220px',
                                                    [theme.breakpoints.down('sm')]: {
                                                        maxWidth: '110px'
                                                    }
                                                }}
                                            >
                                                Invested
                                            </Typography>
                                            <Box component="span"
                                                sx={{
                                                    fontSize: "1.4rem",
                                                    fontWeight: '700',
                                                    color: 'lightSecondary.main',
                                                    lineHeight: '1.6rem',
                                                }}>
                                                $20k
                                            </Box>
                                        </Stack>
                                    </ListItemText>
                                </ListItemButton>
                            </ListItem>
                            <ListItem
                                sx={{
                                    p: 0,
                                }}
                            >
                                <ListItemButton
                                    onClick={handleInvested}
                                    sx={{
                                        padding: '10px 24px',
                                        borderRadius: '10px',
                                        background: '#fff',
                                        [theme.breakpoints.down('sm')]: {
                                            pl:'10px'
                                        }
                                    }}
                                >
                                    <ListItemAvatar
                                        sx={{
                                            minWidth: '108px',
                                            '& img': {
                                                maxWidth: '108px'
                                            },
                                            [theme.breakpoints.down('lg')]: {
                                                minWidth: '80px',
                                                '& img': {
                                                    maxWidth: '80px'
                                                },
                                            },
                                            [theme.breakpoints.down('md')]: {
                                                minWidth: '60px',
                                                '& img': {
                                                    maxWidth: '60px'
                                                },
                                            }
                                        }}
                                    >
                                        <img src={getImages('image3.png')} alt='' />
                                    </ListItemAvatar>
                                    <ListItemText
                                        sx={{
                                            pl: '20px',
                                            width: "60%",
                                            [theme.breakpoints.down('sm')]: {
                                                pl:'10px'
                                            }
                                        }}>
                                        <Typography noWrap
                                            sx={{
                                                fontSize: "1.2rem",
                                                fontWeight: '700',
                                                color: 'secondary.main',
                                                lineHeight: '22px',
                                                [theme.breakpoints.down('xl')]: {
                                                    maxWidth: '320px'
                                                },
                                                [theme.breakpoints.down('lg')]: {
                                                    maxWidth: '220px'
                                                },
                                                [theme.breakpoints.down('md')]: {
                                                    maxWidth: '160px',
                                                    fontSize: '1rem'
                                                },
                                                [theme.breakpoints.down('sm')]: {
                                                    maxWidth: '100px',
                                                    fontSize: '1rem'
                                                },
                                            }}
                                            variant="h6">Blockpass Identity for a Connected World
                                        </Typography>
                                        <Typography
                                            noWrap
                                            variant="body2"
                                            sx={{
                                                fontSize: '1rem',
                                                fontWeight: '600',
                                                color: 'lightSecondary.main',
                                                maxWidth: '220px',
                                                mb: 0.5,
                                                [theme.breakpoints.down('sm')]: {
                                                    maxWidth: '110px',
                                                    fontSize: '0.8rem'
                                                }
                                            }}
                                        >Industry: Citizen service</Typography>
                                        <Typography
                                            noWrap
                                            variant="body2"
                                            sx={{
                                                color: "ltheme.main",
                                                fontSize: '0.9rem',
                                                fontWeight: '500',
                                                maxWidth: '220px',
                                                [theme.breakpoints.down('sm')]: {
                                                    maxWidth: '110px',
                                                    fontSize: '0.8rem'
                                                }
                                            }}
                                        >18 Feb 2023</Typography>
                                    </ListItemText>
                                    <ListItemText
                                        sx={{
                                            width: "calc(40% - 56px)"
                                        }}
                                    >
                                        <Stack alignItems="flex-end">
                                            <Typography
                                                variant="body2" noWrap
                                                sx={{
                                                    color: "ltheme.main",
                                                    fontSize: '0.9rem',
                                                    fontWeight: '500',
                                                    maxWidth: '220px',
                                                    [theme.breakpoints.down('sm')]: {
                                                        maxWidth: '110px'
                                                    }
                                                }}
                                            >
                                                Invested
                                            </Typography>
                                            <Box component="span"
                                                sx={{
                                                    fontSize: "1.4rem",
                                                    fontWeight: '700',
                                                    color: 'lightSecondary.main',
                                                    lineHeight: '1.6rem',
                                                }}>
                                                $150k
                                            </Box>
                                        </Stack>
                                    </ListItemText>
                                </ListItemButton>
                            </ListItem>
                            <ListItem
                                sx={{
                                    p: 0,
                                }}
                            >
                                <ListItemButton
                                    onClick={handleInvested}
                                    sx={{
                                        padding: '10px 24px',
                                        borderRadius: '10px',
                                        background: '#fff',
                                        [theme.breakpoints.down('sm')]: {
                                            pl:'10px'
                                        }
                                    }}
                                >
                                    <ListItemAvatar
                                        sx={{
                                            minWidth: '108px',
                                            '& img': {
                                                maxWidth: '108px'
                                            },
                                            [theme.breakpoints.down('lg')]: {
                                                minWidth: '80px',
                                                '& img': {
                                                    maxWidth: '80px'
                                                },
                                            },
                                            [theme.breakpoints.down('md')]: {
                                                minWidth: '60px',
                                                '& img': {
                                                    maxWidth: '60px'
                                                },
                                            }
                                        }}
                                    >
                                        <img src={getImages('image4.png')} alt='' />
                                    </ListItemAvatar>
                                    <ListItemText
                                        sx={{
                                            pl: '20px',
                                            width: "60%",
                                            [theme.breakpoints.down('sm')]: {
                                                pl:'10px'
                                            }
                                        }}>
                                        <Typography noWrap
                                            sx={{
                                                fontSize: "1.2rem",
                                                fontWeight: '700',
                                                color: 'secondary.main',
                                                lineHeight: '22px',
                                                [theme.breakpoints.down('xl')]: {
                                                    maxWidth: '320px'
                                                },
                                                [theme.breakpoints.down('lg')]: {
                                                    maxWidth: '220px'
                                                },
                                                [theme.breakpoints.down('md')]: {
                                                    maxWidth: '160px',
                                                    fontSize: '1rem'
                                                },
                                                [theme.breakpoints.down('sm')]: {
                                                    maxWidth: '100px',
                                                    fontSize: '1rem'
                                                },
                                            }}
                                            variant="h6">Advanced Solutions in Digital Healthcare
                                        </Typography>
                                        <Typography
                                            noWrap
                                            variant="body2"
                                            sx={{
                                                fontSize: '1rem',
                                                fontWeight: '600',
                                                color: 'lightSecondary.main',
                                                maxWidth: '220px',
                                                mb: 0.5,
                                                [theme.breakpoints.down('sm')]: {
                                                    maxWidth: '110px',
                                                    fontSize: '0.8rem'
                                                }
                                            }}
                                        >Industry: Citizen service</Typography>
                                        <Typography
                                            noWrap
                                            variant="body2"
                                            sx={{
                                                color: "ltheme.main",
                                                fontSize: '0.9rem',
                                                fontWeight: '500',
                                                maxWidth: '220px',
                                                [theme.breakpoints.down('sm')]: {
                                                    maxWidth: '110px',
                                                    fontSize: '0.8rem'
                                                }
                                            }}
                                        >23 Feb 2023</Typography>
                                    </ListItemText>
                                    <ListItemText
                                        sx={{
                                            width: "calc(40% - 56px)"
                                        }}
                                    >
                                        <Stack alignItems="flex-end">
                                            <Typography
                                                variant="body2" noWrap
                                                sx={{
                                                    color: "ltheme.main",
                                                    fontSize: '0.9rem',
                                                    fontWeight: '500',
                                                    maxWidth: '220px',
                                                    [theme.breakpoints.down('sm')]: {
                                                        maxWidth: '110px'
                                                    }
                                                }}
                                            >
                                                Invested
                                            </Typography>
                                            <Box component="span"
                                                sx={{
                                                    fontSize: "1.4rem",
                                                    fontWeight: '700',
                                                    color: 'lightSecondary.main',
                                                    lineHeight: '1.6rem',
                                                }}>
                                                $80k
                                            </Box>
                                        </Stack>
                                    </ListItemText>
                                </ListItemButton>
                            </ListItem>
                            <ListItem
                                sx={{
                                    p: 0,
                                }}
                            >
                                <ListItemButton
                                    onClick={handleInvested}
                                    sx={{
                                        padding: '10px 24px',
                                        borderRadius: '10px',
                                        background: '#fff',
                                        [theme.breakpoints.down('sm')]: {
                                            padding:'10px 14px'
                                        }
                                    }}
                                >
                                    <ListItemAvatar
                                        sx={{
                                            minWidth: '108px',
                                            '& img': {
                                                maxWidth: '108px'
                                            },
                                            [theme.breakpoints.down('lg')]: {
                                                minWidth: '80px',
                                                '& img': {
                                                    maxWidth: '80px'
                                                },
                                            },
                                            [theme.breakpoints.down('md')]: {
                                                minWidth: '60px',
                                                '& img': {
                                                    maxWidth: '60px'
                                                },
                                            }
                                        }}
                                    >
                                        <img src={getImages('image5.png')} alt='' />
                                    </ListItemAvatar>
                                    <ListItemText
                                        sx={{
                                            pl: '20px',
                                            width: "60%",
                                            [theme.breakpoints.down('sm')]: {
                                                pl:'10px'
                                            }
                                        }}>
                                        <Typography noWrap
                                            sx={{
                                                fontSize: "1.2rem",
                                                fontWeight: '700',
                                                color: 'secondary.main',
                                                lineHeight: '22px',
                                                [theme.breakpoints.down('xl')]: {
                                                    maxWidth: '320px'
                                                },
                                                [theme.breakpoints.down('lg')]: {
                                                    maxWidth: '220px'
                                                },
                                                [theme.breakpoints.down('md')]: {
                                                    maxWidth: '160px',
                                                    fontSize: '1rem'
                                                },
                                                [theme.breakpoints.down('sm')]: {
                                                    maxWidth: '100px',
                                                    fontSize: '1rem'
                                                },
                                            }}
                                            variant="h6">Claim Settlement Platform
                                        </Typography>
                                        <Typography
                                            noWrap
                                            variant="body2"
                                            sx={{
                                                fontSize: '1rem',
                                                fontWeight: '600',
                                                color: 'lightSecondary.main',
                                                maxWidth: '220px',
                                                mb: 0.5,
                                                [theme.breakpoints.down('sm')]: {
                                                    maxWidth: '110px',
                                                    fontSize: '0.8rem'
                                                }
                                            }}
                                        >Industry: Citizen service</Typography>
                                        <Typography
                                            noWrap
                                            variant="body2"
                                            sx={{
                                                color: "ltheme.main",
                                                fontSize: '0.9rem',
                                                fontWeight: '500',
                                                maxWidth: '220px',
                                                [theme.breakpoints.down('sm')]: {
                                                    maxWidth: '110px',
                                                    fontSize: '0.8rem'
                                                }
                                            }}
                                        >27 Feb 2023</Typography>
                                    </ListItemText>
                                    <ListItemText
                                        sx={{
                                            width: "calc(40% - 56px)"
                                        }}
                                    >
                                        <Stack alignItems="flex-end">
                                            <Typography
                                                variant="body2" noWrap
                                                sx={{
                                                    color: "ltheme.main",
                                                    fontSize: '0.9rem',
                                                    fontWeight: '500',
                                                    maxWidth: '220px',
                                                    [theme.breakpoints.down('sm')]: {
                                                        maxWidth: '110px'
                                                    }
                                                }}
                                            >
                                                Invested
                                            </Typography>
                                            <Box component="span"
                                                sx={{
                                                    fontSize: "1.4rem",
                                                    fontWeight: '700',
                                                    color: 'lightSecondary.main',
                                                    lineHeight: '1.6rem',
                                                }}>
                                                $500k
                                            </Box>
                                        </Stack>
                                    </ListItemText>
                                </ListItemButton>
                            </ListItem>
                        </List>
                        <Stack
                            direction="row"
                            sx={{
                                justifyContent: 'center',
                                my: 2.5
                            }}
                        >
                            <Pagination count={3} page={page} onChange={handlePaginationChange}
                                sx={{
                                    '& .MuiButtonBase-root': {
                                        color: 'secondary.main',
                                        fontSize: '1.2rem',
                                        fontWeight: '700',
                                        minWidth: '45px',
                                        height: '45px',
                                        '&.Mui-selected': {
                                            backgroundColor: 'secondary.main',
                                            color: '#fff',
                                            borderRadius: '6px'
                                        },
                                        '@media (max-width:1600px)': {
                                            fontSize: '1rem',
                                            minWidth: '35px',
                                            height: '35px',

                                        },
                                        [theme.breakpoints.down('lg')]: {
                                            fontSize: '1rem',
                                            minWidth: '30px',
                                            height: '30px',
                                        }
                                    }
                                }}
                                renderItem={(item) => (
                                    <PaginationItem
                                        slots={{ previous: KeyboardDoubleArrowLeftOutlinedIcon, next: KeyboardDoubleArrowRightOutlinedIcon }}
                                        {...item}
                                    />
                                )}

                            />
                        </Stack>
                    </MainCard>
                </Grid>
                <Grid item xs={12} md={6} lg={6} sx={{ mb: 2 }}>
                    <MainCard content={false}
                        sx={{
                            backgroundColor: "#ECF6FF",
                            border: "none",
                            padding: "20px",
                            boxShadow: "none",
                            borderRadius: "10px"
                        }}
                    >

                        <Typography variant='h6'
                            sx={{
                                color: 'secondary.main',
                                fontSize: "1.1rem",
                                fontWeight: "700",
                                mb: 1
                            }}
                        >
                            Commitments
                        </Typography>
                        <Stack
                            direction="row"
                            sx={{
                                justifyItems: 'center',
                                justifyContent: 'space-between',
                                mt: 0,
                                [theme.breakpoints.down('md')]: {
                                    flexWrap: 'wrap'
                                }
                            }}
                        >
                            <Search
                                sx={{
                                    width: 'calc(100% - 150px)',
                                    backgroundColor: '#DBEBF8',
                                    mb: 2.5,
                                    mr: 2,
                                    [theme.breakpoints.down('md')]: {
                                        width: 'calc(100% - 220px)',
                                    },
                                    [theme.breakpoints.down('sm')]: {
                                        mr: 0,
                                        width: '100%',
                                    }
                                }}
                            >
                                <SearchIconWrapper>
                                    <SearchIcon sx={{ color: "lightSecondary.main" }} />
                                </SearchIconWrapper>
                                <StyledInputBase
                                    id="searchBox"
                                    placeholder="Search commitements"
                                    inputProps={{ 'aria-label': 'search' }}
                                />
                            </Search>
                            <Box
                                sx={{
                                    mr: 2,
                                    mb: 2.5,
                                    [theme.breakpoints.down('sm')]: {
                                        width: "calc(50% - 0.5rem)",
                                        mr: '0.5rem',
                                    }
                                }}
                            >
                                <Button
                                    id="basic-button"
                                    aria-controls={openSort ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={openSort ? 'true' : undefined}
                                    onClick={handleClickSort}
                                    sx={{
                                        background: "#fff",
                                        fontSize: "0.9rem",
                                        color: "lightSecondary.main",
                                        textTransform: "inherit",
                                        fontWeight: "600",
                                        p: "10px 20px",
                                        '&:hover': {
                                            background: "#fff",
                                        },
                                        [theme.breakpoints.down('md')]: {
                                            width: "100%"
                                        }
                                    }}
                                >
                                    Sort
                                    <KeyboardArrowDownOutlined
                                        sx={{

                                        }}
                                    />
                                </Button>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorElSort}
                                    open={openSort}
                                    onClose={handleCloseSort}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                    sx={{
                                        '& .MuiMenu-paper': {
                                            borderRadius: "10px",
                                            border: "solid 1px #106EED"
                                        }
                                    }}
                                >
                                    <MenuItem
                                        sx={{
                                            '& .MuiFormControlLabel-label': {
                                                fontSize: "0.9rem",
                                                color: "#2A589C",
                                                fontWeight: "600",
                                            }
                                        }}
                                        onClick={handleCloseSort}>
                                        <FormControlLabel
                                            value="CreatedUp" control={<Radio sx={{ paddingY: 0, color: "#2A589C" }} checked={selectedSortOption === 'CreatedUp'} />} label="Created" />
                                        <NorthOutlinedIcon sx={{
                                            fontSize: "1.1rem",
                                            color: "#2A589C"
                                        }} />
                                    </MenuItem>
                                    <MenuItem
                                        sx={{
                                            '& .MuiFormControlLabel-label': {
                                                fontSize: "0.9rem",
                                                color: "#2A589C",
                                                fontWeight: "600",
                                            }
                                        }}
                                        onClick={handleCloseSort}>
                                        <FormControlLabel
                                            value="CreatedDown" control={<Radio sx={{ paddingY: 0, color: "#2A589C" }} checked={selectedSortOption === 'CreatedDown'} />} label="Created" />
                                        <SouthOutlinedIcon sx={{
                                            fontSize: "1.1rem",
                                            color: "#2A589C"
                                        }} />
                                    </MenuItem>
                                    <MenuItem
                                        sx={{
                                            '& .MuiFormControlLabel-label': {
                                                fontSize: "0.9rem",
                                                color: "#2A589C",
                                                fontWeight: "600",
                                            }
                                        }}
                                        onClick={handleCloseSort}>
                                        <FormControlLabel
                                            value="name-a-z" control={<Radio sx={{ paddingY: 0, color: "#2A589C" }} checked={selectedSortOption === 'name-a-z'} />} label="Name A-Z" />
                                    </MenuItem>
                                    <MenuItem
                                        sx={{
                                            '& .MuiFormControlLabel-label': {
                                                fontSize: "0.9rem",
                                                color: "#2A589C",
                                                fontWeight: "600",
                                            }
                                        }}
                                        onClick={handleCloseSort}>
                                        <FormControlLabel
                                            value="name-z-a" control={<Radio sx={{ paddingY: 0, color: "#2A589C" }} checked={selectedSortOption === 'name-z-a'} />} label="Name Z-A" />
                                    </MenuItem>
                                </Menu>
                            </Box>
                            <Box
                                sx={{
                                    [theme.breakpoints.down('sm')]: {
                                        width: "50%"
                                    }
                                }}
                            >
                                <Button
                                    id="basic-button"
                                    aria-controls={openFilter ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={openFilter ? 'true' : undefined}
                                    onClick={handleClickFilter}
                                    sx={{
                                        background: "#fff",
                                        fontSize: "0.9rem",
                                        color: "lightSecondary.main",
                                        textTransform: "inherit",
                                        fontWeight: "600",
                                        p: "10px 20px",
                                        '&:hover': {
                                            background: "#fff",
                                        },
                                        [theme.breakpoints.down('sm')]: {
                                            width: "100%"
                                        }
                                    }}
                                >
                                    Filter
                                    <KeyboardArrowDownOutlined
                                    />
                                </Button>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorElFilter}
                                    open={openFilter}
                                    onClose={handleCloseFilter}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                    sx={{
                                        '& .MuiMenu-paper': {
                                            borderRadius: "10px",
                                            border: "solid 1px #106EED",
                                            minWidth: "280px",
                                            px: 2.3,
                                            py: 1
                                        }
                                    }}
                                >
                                    <Box sx={{
                                        mb: 1.5
                                    }}>
                                        <Typography
                                            sx={{
                                                fontSize: "12px",
                                                fontWeight: '500',
                                                color: 'ltheme.main',
                                                lineHeight: '24px',
                                            }}
                                            variant="body2">
                                            Select Industry
                                        </Typography>
                                        <FormControl fullWidth
                                            sx={{
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    border: 'none'
                                                }
                                            }}
                                        >
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={designation}
                                                onChange={handleChange}
                                                displayEmpty
                                                IconComponent={KeyboardArrowDownOutlinedIcon}
                                                sx={{
                                                    background: "#ECF6FF",
                                                    '& .MuiSelect-select': {
                                                        minHeight: "20px",
                                                        padding: "11px 14px",
                                                        color: "lightSecondary.main",
                                                        fontWeight: "600",
                                                        fontSize: "0.9rem"
                                                    },
                                                    '& .MuiSelect-icon': {
                                                        color: 'lightSecondary.main'
                                                    },
                                                }}
                                            >
                                                <MenuItem>Select Industry</MenuItem>
                                                <MenuItem value={2}>Insurance</MenuItem>
                                                <MenuItem value={3}>Citizen service</MenuItem>
                                                <MenuItem value={4}>Healthcare</MenuItem>
                                                <MenuItem value={5}>Supply chain</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                    <Box>
                                        <Typography
                                            sx={{
                                                fontSize: "12px",
                                                fontWeight: '500',
                                                color: 'ltheme.main',
                                                lineHeight: '24px',
                                            }}
                                            variant="body2">
                                            Select Type
                                        </Typography>
                                        <FormControl fullWidth
                                            sx={{
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    border: 'none'
                                                }
                                            }}
                                        >
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={designation}
                                                onChange={handleChange}
                                                displayEmpty
                                                IconComponent={KeyboardArrowDownOutlinedIcon}
                                                sx={{
                                                    background: "#ECF6FF",
                                                    '& .MuiSelect-select': {
                                                        minHeight: "20px",
                                                        padding: "11px 14px",
                                                        color: "lightSecondary.main",
                                                        fontWeight: "600",
                                                        fontSize: "0.9rem"
                                                    },
                                                    '& .MuiSelect-icon': {
                                                        color: 'lightSecondary.main'
                                                    },
                                                }}
                                            >
                                                <MenuItem>Select Type</MenuItem>
                                                <MenuItem value={12}>All</MenuItem>
                                                <MenuItem value={13}>Starred</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                    <Box sx={{
                                        display: 'flex',
                                        gap: 2,
                                        mt: 2
                                    }}>
                                        <Box sx={{
                                            width: '50%'
                                        }}>
                                            <Button variant="outlined"
                                                sx={{
                                                    width: "100%",
                                                    textTransform: 'inherit',
                                                    p: "7px 15px"
                                                }}
                                            >
                                                Clear
                                            </Button>
                                        </Box>
                                        <Box sx={{
                                            width: '50%'
                                        }}>
                                            <Button variant="contained"
                                                sx={{
                                                    width: "100%",
                                                    textTransform: 'inherit',
                                                    p: "7px 15px",
                                                    boxShadow: 'none'
                                                }}>Apply
                                            </Button>
                                        </Box>
                                    </Box>
                                </Menu>
                            </Box>
                        </Stack>
                        <List
                            sx={{
                                p: "0",
                                '& .MuiListItem-root + .MuiListItem-root': {
                                    mt: 1.3
                                }
                            }}
                        >
                            <ListItem
                                sx={{
                                    p: 0,
                                }}
                            >
                                <ListItemButton
                                    onClick={handleCommitment}
                                    sx={{
                                        padding: '10px 24px',
                                        borderRadius: '10px',
                                        background: '#fff',
                                        [theme.breakpoints.down('sm')]: {
                                            pl:'10px'
                                        }
                                    }}
                                >
                                    <ListItemAvatar
                                        sx={{
                                            minWidth: '108px',
                                            '& img': {
                                                maxWidth: '108px'
                                            },
                                            [theme.breakpoints.down('lg')]: {
                                                minWidth: '80px',
                                                '& img': {
                                                    maxWidth: '80px'
                                                },
                                            },
                                            [theme.breakpoints.down('md')]: {
                                                minWidth: '60px',
                                                '& img': {
                                                    maxWidth: '60px'
                                                },
                                            }
                                        }}
                                    >
                                        <img src={getImages('image6.png')} alt='' />
                                    </ListItemAvatar>
                                    <ListItemText
                                        sx={{
                                            pl: '20px',
                                            width: "60%",
                                            [theme.breakpoints.down('sm')]: {
                                                pl:'10px'
                                            }
                                        }}>
                                        <Typography noWrap
                                            sx={{
                                                fontSize: "1.2rem",
                                                fontWeight: '700',
                                                color: 'secondary.main',
                                                lineHeight: '22px',
                                                [theme.breakpoints.down('xl')]: {
                                                    maxWidth: '320px'
                                                },
                                                [theme.breakpoints.down('lg')]: {
                                                    maxWidth: '220px'
                                                },
                                                [theme.breakpoints.down('md')]: {
                                                    maxWidth: '160px',
                                                    fontSize: '1rem'
                                                },
                                                [theme.breakpoints.down('sm')]: {
                                                    maxWidth: '100px',
                                                    fontSize: '1rem'
                                                },
                                            }}
                                            variant="h6">Aalpha information systems
                                        </Typography>
                                        <Typography
                                            noWrap
                                            variant="body2"
                                            sx={{
                                                fontSize: '1rem',
                                                fontWeight: '600',
                                                color: 'lightSecondary.main',
                                                maxWidth: '220px',
                                                mb: 0.5,
                                                [theme.breakpoints.down('sm')]: {
                                                    maxWidth: '110px',
                                                    fontSize: '0.8rem'
                                                }
                                            }}
                                        >Industry: Citizen service</Typography>
                                        <Typography
                                            noWrap
                                            variant="body2"
                                            sx={{
                                                color: "ltheme.main",
                                                fontSize: '0.9rem',
                                                fontWeight: '500',
                                                maxWidth: '220px',
                                                [theme.breakpoints.down('sm')]: {
                                                    maxWidth: '110px',
                                                    fontSize: '0.8rem'
                                                }
                                            }}
                                        >12 Feb 2023</Typography>
                                    </ListItemText>
                                    <ListItemText
                                        sx={{
                                            width: "calc(40% - 56px)"
                                        }}
                                    >
                                        <Stack alignItems="flex-end">
                                            <Typography
                                                variant="body2" noWrap
                                                sx={{
                                                    color: "ltheme.main",
                                                    fontSize: '0.9rem',
                                                    fontWeight: '500',
                                                    maxWidth: '220px',
                                                    [theme.breakpoints.down('sm')]: {
                                                        maxWidth: '110px'
                                                    }
                                                }}
                                            >
                                                Committed
                                            </Typography>
                                            <Box component="span"
                                                sx={{
                                                    fontSize: "1.4rem",
                                                    fontWeight: '700',
                                                    color: 'lightSecondary.main',
                                                    lineHeight: '1.6rem',
                                                }}>
                                                $150k
                                            </Box>
                                        </Stack>
                                    </ListItemText>
                                </ListItemButton>
                            </ListItem>
                            <ListItem
                                sx={{
                                    p: 0,
                                }}
                            >
                                <ListItemButton
                                    onClick={handleCommitment}
                                    sx={{
                                        padding: '10px 24px',
                                        borderRadius: '10px',
                                        background: '#fff',
                                        [theme.breakpoints.down('sm')]: {
                                            pl:'10px'
                                        }
                                    }}
                                >
                                    <ListItemAvatar
                                        sx={{
                                            minWidth: '108px',
                                            '& img': {
                                                maxWidth: '108px'
                                            },
                                            [theme.breakpoints.down('lg')]: {
                                                minWidth: '80px',
                                                '& img': {
                                                    maxWidth: '80px'
                                                },
                                            },
                                            [theme.breakpoints.down('md')]: {
                                                minWidth: '60px',
                                                '& img': {
                                                    maxWidth: '60px'
                                                },
                                            }
                                        }}
                                    >
                                        <img src={getImages('image7.png')} alt='' />
                                    </ListItemAvatar>
                                    <ListItemText
                                        sx={{
                                            pl: '20px',
                                            width: "60%",
                                            [theme.breakpoints.down('sm')]: {
                                                pl:'10px'
                                            }
                                        }}>
                                        <Typography noWrap
                                            sx={{
                                                fontSize: "1.2rem",
                                                fontWeight: '700',
                                                color: 'secondary.main',
                                                lineHeight: '22px',
                                                [theme.breakpoints.down('xl')]: {
                                                    maxWidth: '320px'
                                                },
                                                [theme.breakpoints.down('lg')]: {
                                                    maxWidth: '220px'
                                                },
                                                [theme.breakpoints.down('md')]: {
                                                    maxWidth: '160px',
                                                    fontSize: '1rem'
                                                },
                                                [theme.breakpoints.down('sm')]: {
                                                    maxWidth: '100px',
                                                    fontSize: '1rem'
                                                },
                                            }}
                                            variant="h6">Bitfi
                                        </Typography>
                                        <Typography
                                            noWrap
                                            variant="body2"
                                            sx={{
                                                fontSize: '1rem',
                                                fontWeight: '600',
                                                color: 'lightSecondary.main',
                                                maxWidth: '220px',
                                                mb: 0.5,
                                                [theme.breakpoints.down('sm')]: {
                                                    maxWidth: '110px',
                                                    fontSize: '0.8rem'
                                                }
                                            }}
                                        >Industry: Citizen service</Typography>
                                        <Typography
                                            noWrap
                                            variant="body2"
                                            sx={{
                                                color: "ltheme.main",
                                                fontSize: '0.9rem',
                                                fontWeight: '500',
                                                maxWidth: '220px',
                                                [theme.breakpoints.down('sm')]: {
                                                    maxWidth: '110px',
                                                    fontSize: '0.8rem'
                                                }
                                            }}
                                        >12 Feb 2023</Typography>
                                    </ListItemText>
                                    <ListItemText
                                        sx={{
                                            width: "calc(40% - 56px)"
                                        }}
                                    >
                                        <Stack alignItems="flex-end">
                                            <Typography
                                                variant="body2" noWrap
                                                sx={{
                                                    color: "ltheme.main",
                                                    fontSize: '0.9rem',
                                                    fontWeight: '500',
                                                    maxWidth: '220px',
                                                    [theme.breakpoints.down('sm')]: {
                                                        maxWidth: '110px'
                                                    }
                                                }}
                                            >
                                                Committed
                                            </Typography>
                                            <Box component="span"
                                                sx={{
                                                    fontSize: "1.4rem",
                                                    fontWeight: '700',
                                                    color: 'lightSecondary.main',
                                                    lineHeight: '1.6rem',
                                                }}>
                                                $20k
                                            </Box>
                                        </Stack>
                                    </ListItemText>
                                </ListItemButton>
                            </ListItem>
                            <ListItem
                                sx={{
                                    p: 0,
                                }}
                            >
                                <ListItemButton
                                    onClick={handleCommitment}
                                    sx={{
                                        padding: '10px 24px',
                                        borderRadius: '10px',
                                        background: '#fff',
                                        [theme.breakpoints.down('sm')]: {
                                            pl:'10px'
                                        }
                                    }}
                                >
                                    <ListItemAvatar
                                        sx={{
                                            minWidth: '108px',
                                            '& img': {
                                                maxWidth: '108px'
                                            },
                                            [theme.breakpoints.down('lg')]: {
                                                minWidth: '80px',
                                                '& img': {
                                                    maxWidth: '80px'
                                                },
                                            },
                                            [theme.breakpoints.down('md')]: {
                                                minWidth: '60px',
                                                '& img': {
                                                    maxWidth: '60px'
                                                },
                                            }
                                        }}
                                    >
                                        <img src={getImages('image8.png')} alt='' />
                                    </ListItemAvatar>
                                    <ListItemText
                                        sx={{
                                            pl: '20px',
                                            width: "60%",
                                            [theme.breakpoints.down('sm')]: {
                                                pl:'10px'
                                            }
                                        }}>
                                        <Typography noWrap
                                            sx={{
                                                fontSize: "1.2rem",
                                                fontWeight: '700',
                                                color: 'secondary.main',
                                                lineHeight: '22px',
                                                [theme.breakpoints.down('xl')]: {
                                                    maxWidth: '320px'
                                                },
                                                [theme.breakpoints.down('lg')]: {
                                                    maxWidth: '220px'
                                                },
                                                [theme.breakpoints.down('md')]: {
                                                    maxWidth: '160px',
                                                    fontSize: '1rem'
                                                },
                                                [theme.breakpoints.down('sm')]: {
                                                    maxWidth: '100px',
                                                    fontSize: '1rem'
                                                },
                                            }}
                                            variant="h6">Dual Carbon Management Platform
                                        </Typography>
                                        <Typography
                                            noWrap
                                            variant="body2"
                                            sx={{
                                                fontSize: '1rem',
                                                fontWeight: '600',
                                                color: 'lightSecondary.main',
                                                maxWidth: '220px',
                                                mb: 0.5,
                                                [theme.breakpoints.down('sm')]: {
                                                    maxWidth: '110px',
                                                    fontSize: '0.8rem'
                                                }
                                            }}
                                        >Industry: Citizen service</Typography>
                                        <Typography
                                            noWrap
                                            variant="body2"
                                            sx={{
                                                color: "ltheme.main",
                                                fontSize: '0.9rem',
                                                fontWeight: '500',
                                                maxWidth: '220px',
                                                [theme.breakpoints.down('sm')]: {
                                                    maxWidth: '110px',
                                                    fontSize: '0.8rem'
                                                }
                                            }}
                                        >18 Feb 2023</Typography>
                                    </ListItemText>
                                    <ListItemText
                                        sx={{
                                            width: "calc(40% - 56px)"
                                        }}
                                    >
                                        <Stack alignItems="flex-end">
                                            <Typography
                                                variant="body2" noWrap
                                                sx={{
                                                    color: "ltheme.main",
                                                    fontSize: '0.9rem',
                                                    fontWeight: '500',
                                                    maxWidth: '220px',
                                                    [theme.breakpoints.down('sm')]: {
                                                        maxWidth: '110px'
                                                    }
                                                }}
                                            >
                                                Committed
                                            </Typography>
                                            <Box component="span"
                                                sx={{
                                                    fontSize: "1.4rem",
                                                    fontWeight: '700',
                                                    color: 'lightSecondary.main',
                                                    lineHeight: '1.6rem',
                                                }}>
                                                $150k
                                            </Box>
                                        </Stack>
                                    </ListItemText>
                                </ListItemButton>
                            </ListItem>
                            <ListItem
                                sx={{
                                    p: 0,
                                }}
                            >
                                <ListItemButton
                                    onClick={handleCommitment}
                                    sx={{
                                        padding: '10px 24px',
                                        borderRadius: '10px',
                                        background: '#fff',
                                        [theme.breakpoints.down('sm')]: {
                                            pl:'10px'
                                        }
                                    }}
                                >
                                    <ListItemAvatar
                                        sx={{
                                            minWidth: '108px',
                                            '& img': {
                                                maxWidth: '108px'
                                            },
                                            [theme.breakpoints.down('lg')]: {
                                                minWidth: '80px',
                                                '& img': {
                                                    maxWidth: '80px'
                                                },
                                            },
                                            [theme.breakpoints.down('md')]: {
                                                minWidth: '60px',
                                                '& img': {
                                                    maxWidth: '60px'
                                                },
                                            }
                                        }}
                                    >
                                        <img src={getImages('image9.png')} alt='' />
                                    </ListItemAvatar>
                                    <ListItemText
                                        sx={{
                                            pl: '20px',
                                            width: "60%",
                                            [theme.breakpoints.down('sm')]: {
                                                pl:'10px'
                                            }
                                        }}>
                                        <Typography noWrap
                                            sx={{
                                                fontSize: "1.2rem",
                                                fontWeight: '700',
                                                color: 'secondary.main',
                                                lineHeight: '22px',
                                                [theme.breakpoints.down('xl')]: {
                                                    maxWidth: '320px'
                                                },
                                                [theme.breakpoints.down('lg')]: {
                                                    maxWidth: '220px'
                                                },
                                                [theme.breakpoints.down('md')]: {
                                                    maxWidth: '160px',
                                                    fontSize: '1rem'
                                                },
                                                [theme.breakpoints.down('sm')]: {
                                                    maxWidth: '100px',
                                                    fontSize: '1rem'
                                                },
                                            }}
                                            variant="h6">Blockchain For Government
                                        </Typography>
                                        <Typography
                                            noWrap
                                            variant="body2"
                                            sx={{
                                                fontSize: '1rem',
                                                fontWeight: '600',
                                                color: 'lightSecondary.main',
                                                maxWidth: '220px',
                                                mb: 0.5,
                                                [theme.breakpoints.down('sm')]: {
                                                    maxWidth: '110px',
                                                    fontSize: '0.8rem'
                                                }
                                            }}
                                        >Industry: Citizen service</Typography>
                                        <Typography
                                            noWrap
                                            variant="body2"
                                            sx={{
                                                color: "ltheme.main",
                                                fontSize: '0.9rem',
                                                fontWeight: '500',
                                                maxWidth: '220px',
                                                [theme.breakpoints.down('sm')]: {
                                                    maxWidth: '110px',
                                                    fontSize: '0.8rem'
                                                }
                                            }}
                                        >23 Feb 2023</Typography>
                                    </ListItemText>
                                    <ListItemText
                                        sx={{
                                            width: "calc(40% - 56px)"
                                        }}
                                    >
                                        <Stack alignItems="flex-end">
                                            <Typography
                                                variant="body2" noWrap
                                                sx={{
                                                    color: "ltheme.main",
                                                    fontSize: '0.9rem',
                                                    fontWeight: '500',
                                                    maxWidth: '220px',
                                                    [theme.breakpoints.down('sm')]: {
                                                        maxWidth: '110px'
                                                    }
                                                }}
                                            >
                                                Committed
                                            </Typography>
                                            <Box component="span"
                                                sx={{
                                                    fontSize: "1.4rem",
                                                    fontWeight: '700',
                                                    color: 'lightSecondary.main',
                                                    lineHeight: '1.6rem',
                                                }}>
                                                $80k
                                            </Box>
                                        </Stack>
                                    </ListItemText>
                                </ListItemButton>
                            </ListItem>
                            <ListItem
                                sx={{
                                    p: 0,
                                }}
                            >
                                <ListItemButton
                                    onClick={handleCommitment}
                                    sx={{
                                        padding: '10px 24px',
                                        borderRadius: '10px',
                                        background: '#fff',
                                        [theme.breakpoints.down('sm')]: {
                                            pl:'10px'
                                        }
                                    }}
                                >
                                    <ListItemAvatar
                                        sx={{
                                            minWidth: '108px',
                                            '& img': {
                                                maxWidth: '108px'
                                            },
                                            [theme.breakpoints.down('lg')]: {
                                                minWidth: '80px',
                                                '& img': {
                                                    maxWidth: '80px'
                                                },
                                            },
                                            [theme.breakpoints.down('md')]: {
                                                minWidth: '60px',
                                                '& img': {
                                                    maxWidth: '60px'
                                                },
                                            }
                                        }}
                                    >
                                        <img src={getImages('image1.png')} alt='' />
                                    </ListItemAvatar>
                                    <ListItemText
                                        sx={{
                                            pl: '20px',
                                            width: "60%",
                                            [theme.breakpoints.down('sm')]: {
                                                pl:'10px'
                                            }
                                        }}>
                                        <Typography noWrap
                                            sx={{
                                                fontSize: "1.2rem",
                                                fontWeight: '700',
                                                color: 'secondary.main',
                                                lineHeight: '22px',
                                                [theme.breakpoints.down('xl')]: {
                                                    maxWidth: '320px'
                                                },
                                                [theme.breakpoints.down('lg')]: {
                                                    maxWidth: '220px'
                                                },
                                                [theme.breakpoints.down('md')]: {
                                                    maxWidth: '160px',
                                                    fontSize: '1rem'
                                                },
                                                [theme.breakpoints.down('sm')]: {
                                                    maxWidth: '100px',
                                                    fontSize: '1rem'
                                                },
                                            }}
                                            variant="h6">KYC as a Service
                                        </Typography>
                                        <Typography
                                            noWrap
                                            variant="body2"
                                            sx={{
                                                fontSize: '1rem',
                                                fontWeight: '600',
                                                color: 'lightSecondary.main',
                                                maxWidth: '220px',
                                                mb: 0.5,
                                                [theme.breakpoints.down('sm')]: {
                                                    maxWidth: '110px',
                                                    fontSize: '0.8rem'
                                                }
                                            }}
                                        >Industry: Citizen service</Typography>
                                        <Typography
                                            noWrap
                                            variant="body2"
                                            sx={{
                                                color: "ltheme.main",
                                                fontSize: '0.9rem',
                                                fontWeight: '500',
                                                maxWidth: '220px',
                                                [theme.breakpoints.down('sm')]: {
                                                    maxWidth: '110px',
                                                    fontSize: '0.8rem'
                                                }
                                            }}
                                        >27 Feb 2023</Typography>
                                    </ListItemText>
                                    <ListItemText
                                        sx={{
                                            width: "calc(40% - 56px)"
                                        }}
                                    >
                                        <Stack alignItems="flex-end">
                                            <Typography
                                                variant="body2" noWrap
                                                sx={{
                                                    color: "ltheme.main",
                                                    fontSize: '0.9rem',
                                                    fontWeight: '500',
                                                    maxWidth: '220px',
                                                    [theme.breakpoints.down('sm')]: {
                                                        maxWidth: '110px'
                                                    }
                                                }}
                                            >
                                                Committed
                                            </Typography>
                                            <Box component="span"
                                                sx={{
                                                    fontSize: "1.4rem",
                                                    fontWeight: '700',
                                                    color: 'lightSecondary.main',
                                                    lineHeight: '1.6rem',
                                                }}>
                                                $500k
                                            </Box>
                                        </Stack>
                                    </ListItemText>
                                </ListItemButton>
                            </ListItem>
                        </List>
                        <Stack
                            direction="row"
                            sx={{
                                justifyContent: 'center',
                                my: 2.5
                            }}
                        >
                            <Pagination count={3} page={page} onChange={handlePaginationChange}
                                sx={{
                                    '& .MuiButtonBase-root': {
                                        color: 'secondary.main',
                                        fontSize: '1.2rem',
                                        fontWeight: '700',
                                        minWidth: '45px',
                                        height: '45px',
                                        '&.Mui-selected': {
                                            backgroundColor: 'secondary.main',
                                            color: '#fff',
                                            borderRadius: '6px'
                                        },
                                        '@media (max-width:1600px)': {
                                            fontSize: '1rem',
                                            minWidth: '35px',
                                            height: '35px',

                                        },
                                        [theme.breakpoints.down('lg')]: {
                                            fontSize: '1rem',
                                            minWidth: '30px',
                                            height: '30px',
                                        }
                                    }
                                }}
                                renderItem={(item) => (
                                    <PaginationItem
                                        slots={{ previous: KeyboardDoubleArrowLeftOutlinedIcon, next: KeyboardDoubleArrowRightOutlinedIcon }}
                                        {...item}
                                    />
                                )}

                            />
                        </Stack>
                    </MainCard>
                </Grid>
            </Grid>
            {/* Modals */}
            <Modals open={openInvested} modalWidth={650} handleClose={closeInvested} >
                <InterestInvestmentModal handleClose={closeInvested} />
            </Modals>
            <Modals open={openCommitment} modalWidth={650} handleClose={closeCommitment} >
                <InterestCommitmentModal handleClose={closeCommitment} handleConfirmPayment={handleConfirmPayment} />
            </Modals>
            <Modals open={openConfirmPayment} modalWidth={650} handleClose={closeConfirmPayment} >
                <PaymentConfirmModal handleClose={closeConfirmPayment} goBackCp={goBackCp} />
            </Modals>
        </>
    )
}

export default Portfolio