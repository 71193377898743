import React, { useRef, useState } from "react";
import {
    Avatar,
    AvatarGroup,
    Badge,
    Box,
    Button,
    Grid,
    IconButton,
    List,
    ListItemButton,
    Stack,
    styled,
    InputBase,
    Typography,
    ListItemText,
    Divider,
    ListItemAvatar,
} from "@mui/material";
import { useTheme } from '@emotion/react';
import MainCard from "../../commonComponents/MainCard";
import { getImages } from "../../commonComponents/const";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Link, useNavigate } from "react-router-dom";
import Chip from '@mui/material/Chip';
import SearchIcon from '@mui/icons-material/Search';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import Modals from "../../commonComponents/Modals";
import DeleteBoardAlert from "../../commonComponents/Kanban/DeleteBoardAlert";
import StarOutlinedIcon from '@mui/icons-material/StarOutlined';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import ReviewInviteModal from "../../commonComponents/Kanban/ReviewInviteModal";
import InvestmentStatusModal from "../../commonComponents/Kanban/InvestmentStatusModal";
import { useDispatch } from "react-redux";
import BoardSetting from "../../commonComponents/Kanban/BoardSetting";

const KanbanInvestmentEnquiries = () => {
    const theme = useTheme();
    // Drag and Drop logic
    const dragItem = useRef();
    const dragOverItem = useRef();

    const [Invited, setInvited] = useState([
        {
            id: 1,
            imgSrc: "kanban1.png",
            orgName: "AsianWeb Technologies",
            name: "Del Phineum",
            designation: "COO",
            title: "Hello Team, I came across to your blockchain solution and Keen to know more about i...",
            date: "05 Feb 2023",
            msg: '08'
        },
        {
            id: 2,
            imgSrc: "kanban4.png",
            orgName: "Bitcoin",
            name: "David Jordan",
            designation: "CEO",
            title: "Decentralised Identity",
            date: "01 Feb 2023",
            msg: '03'
        },
        {
            id: 2,
            imgSrc: "kanban4.png",
            orgName: "Four Screens",
            name: "Tbone Jod",
            designation: "CTO",
            title: "Decentralised Identity",
            date: "01 Feb 2023",
            msg: '03'
        }
    ]);

    const [Accepted, setAccepted] = useState([
        {
            id: 1,
            imgSrc: "kanban7.png",
            orgName: "WeDigTech",
            name: "Percy Kewshun",
            designation: "CTO",
            title: "Blockpass Identity for a Connected World",
            date: "01 Feb 2023",
            msg: '12'
        },
        {
            id: 2,
            imgSrc: "kanban7.png",
            orgName: "Verse Techno",
            name: "Kydae Daniels",
            designation: "CTO",
            title: "Blockpass Identity for a Connected World",
            date: "01 Feb 2023",
            msg: '12'
        }
    ]);
    const [Rejected, setRejected] = useState([
        {
            id: 1,
            imgSrc: "kanban2.png",
            orgName: "Sonilen Technologies",
            name: "Greg Arias",
            designation: "PM",
            title: "Benovymed Healthcare",
            date: "05 Feb 2023",
            msg: '25'
        },
        {
            id: 2,
            imgSrc: "kanban6.png",
            orgName: "AlgoTrading Experts",
            name: "Jhon Kallis",
            designation: "COO",
            title: "CIAM – Customer Identity & Access Management",
            date: "05 Feb 2023",
            msg: '87'
        }
    ]);
    const [Selected, setSelected] = useState([
        {
            id: 1,
            imgSrc: "kanban3.png",
            orgName: "F(x) Data Labs",
            name: "Dave Allippa",
            designation: "VP",
            title: "Claim Settlement Platform",
            date: "05 Feb 2023",
            msg: '36'
        }
    ]);
    const dragStart = (e, listId) => {
        dragItem.current = { id: e.target.id, listId };
    };

    const dragEnter = (e, listId) => {
        dragOverItem.current = { id: e.currentTarget.id, listId };
    };

    const getItemContent = (listId, index) => {
        switch (listId) {
            case "Invited":
                return Invited[index];
            case "Accepted":
                return Accepted[index];
            case "Rejected":
                return Rejected[index];
            case "Selected":
                return Selected[index];
            default:
                return null;
        }
    };

    const getItemsArray = (listId) => {
        switch (listId) {
            case "Invited":
                return Invited;
            case "Accepted":
                return Accepted;
            case "Rejected":
                return Rejected;
            case "Selected":
                return Selected;
            default:
                return [];
        }
    };

    const setItemsArray = (listId, items) => {
        switch (listId) {
            case "Invited":
                setInvited(items);
                break;
            case "Accepted":
                setAccepted(items);
                break;
            case "Rejected":
                setRejected(items);
                break;
            case "Selected":
                setSelected(items);
                break;
            default:
                break;
        }
    };



    const drop = () => {
        if (dragItem.current !== null && dragOverItem.current !== null) {
            const dragItemIndex = parseInt(dragItem.current.id);
            const dragOverItemIndex = parseInt(dragOverItem.current.id);
            const dragItemContent = getItemContent(
                dragItem.current.listId,
                dragItemIndex
            );

            if (dragItem.current.listId === dragOverItem.current.listId) {
                // Dropping within the same list
                const copyDragListItems = [...getItemsArray(dragItem.current.listId)];
                copyDragListItems.splice(dragItemIndex, 1);
                copyDragListItems.splice(dragOverItemIndex, 0, dragItemContent);
                setItemsArray(dragItem.current.listId, copyDragListItems);
            } else {
                // Dropping onto a different list
                const copyDragListItems = [...getItemsArray(dragItem.current.listId)];
                const copyDragOverListItems = [
                    ...getItemsArray(dragOverItem.current.listId),
                ];
                copyDragListItems.splice(dragItemIndex, 1);
                copyDragOverListItems.splice(dragOverItemIndex, 0, dragItemContent);
                setItemsArray(dragItem.current.listId, copyDragListItems);
                setItemsArray(dragOverItem.current.listId, copyDragOverListItems);
            }
        }
    };
    const Search = styled('div')(({ theme }) => ({
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: '#ECF6FF',
        '&:hover': {
            backgroundColor: '#ECF6FF',
        },
        marginRight: '0',
        marginLeft: 0,
        marginBottom: 0,
        width: '100%'
    }));
    const SearchIconWrapper = styled('div')(({ theme }) => ({
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        right: '0',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }));

    const StyledInputBase = styled(InputBase)(({ theme }) => ({
        color: '#106EED',
        width: '100%',
        '& .MuiInputBase-input': {
            padding: theme.spacing(1.7, 0, 1.7, 1.7),
            paddingRight: `calc(1em + ${theme.spacing(4)})`,
            transition: theme.transitions.create('width'),
            width: '100%',
            fontSize: '12px',
            fontWeight: '500'
        },
    }));
    // Board Settings Modal
    const [openBoardSettings, setOpenBoardSettings] = useState(false);
    const boardSettingsHandle = () => {
        setOpenBoardSettings(true)
    }
    const closeBoardSettings = () => {
        setOpenBoardSettings(false)
    }
    // Delete Board Alert Modal
    const [openDeleteBoardAlert, setOpenDeleteBoardAlert] = useState(false);
    const deleteBoardAlertHandle = () => {
        setOpenDeleteBoardAlert(true)
        setOpenBoardSettings(false)
    }
    const closeDeleteBoardAlert = () => {
        setOpenDeleteBoardAlert(false)
    }
    const goBackFromAlertHandle = () => {
        setOpenDeleteBoardAlert(false)
        setOpenBoardSettings(true)
    }
    // Review Invite Modal
    const [openReviewInvite, setOpenReviewInvite] = useState(false);
    const reviewInviteHandle = () => {
        setOpenReviewInvite(true)
    }
    const closeReviewInvite = () => {
        setOpenReviewInvite(false)
    }
    // Review Invite Modal
    const [openInvestmentStatus, setOpenInvestmentStatus] = useState(false);
    const investmentStatusHandle = () => {
        setOpenInvestmentStatus(true)
    }
    const closeInvestmentStatus = () => {
        setOpenInvestmentStatus(false)
    }
    const navigate = useNavigate()

    const handleNavigation = (path) => {
        navigate(path);
    };

    return (
        <>
            <Stack
                direction="row"
                sx={{
                    mb: 2,
                    justifyContent: 'space-between'
                }}
            >
                <Typography
                    sx={{
                        fontSize: "1.4rem",
                        fontWeight: "700",
                        color: 'secondary.main',
                        [theme.breakpoints.down('md')]: {
                            fontSize: '1.1rem',
                        },
                    }} variant="h4"
                    noWrap component="div">
                    <Link to="/investment-enquiries"
                        style={{
                            color: '#2A589C',
                            textDecoration: "none"
                        }}
                    >
                        <KeyboardBackspaceIcon
                            style={{ verticalAlign: "-8px", fontSize: '32px', marginRight: '7px' }}
                        />
                    </Link>
                    TRUSTDOCK Digital ID
                    <Chip label="Insurance"
                        sx={{
                            backgroundColor: 'primary.main',
                            color: '#fff',
                            ml: 3,
                            fontWeight: '600'
                        }}
                    />
                </Typography>
                <Stack
                    direction="row"
                    sx={{
                        minWidth: '23%'
                    }}
                >
                    <Search>
                        <SearchIconWrapper>
                            <SearchIcon sx={{ color: "lightSecondary.main" }} />
                        </SearchIconWrapper>
                        <StyledInputBase
                            placeholder="Search this board"
                            inputProps={{ 'aria-label': 'search' }}
                        />
                    </Search>
                    <IconButton onClick={boardSettingsHandle}>
                        <SettingsOutlinedIcon sx={{ color: 'lightSecondary.main' }} />
                    </IconButton>
                </Stack>
            </Stack>
            <Box
                style={{
                    [theme.breakpoints.down('lg')]: {
                        overflowX: 'auto',
                        '&::-webkit-scrollbar': {
                            height: '6px',
                            borderRadius: '4px'
                        },
                        '&::-webkit-scrollbar-track': {
                            backgroundColor: 'transparent',
                            borderRadius: '4px'
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: '#9C9C9C',
                            borderRadius: '4px'
                        }
                    }
                }}
            >
                <Box
                    style={{ width: '100%', }}
                    sx={{
                        display: "flex",
                        gap: 1.5,
                    }}
                >
                    {/* Invited */}
                    <Box
                        sx={{
                            width: '25%',
                            minWidth: '280px',
                        }}
                    >
                        <Box
                            sx={{
                                marginBottom: "10px",
                                backgroundColor: '#FFB185',
                                textAlign: 'center',
                                borderRadius: '10px',
                                padding: '11px'
                            }}
                        >
                            <Typography
                                variant="h6"
                                color="textSecondary"
                                sx={{
                                    fontSize: "0.9rem",
                                    fontWeight: "600",
                                    color: "secondary.main",
                                }}
                            >
                                Invites
                            </Typography>
                        </Box>
                        <MainCard
                            content={false}
                            sx={{
                                backgroundColor: "#ECF6FF",
                                border: "none",
                                padding: '5px 10px 10px',
                                height: 'calc(100vh - 220px)',
                                overflowY: 'auto',
                                '&::-webkit-scrollbar': {
                                    width: '6px',
                                    borderRadius: '4px'
                                },
                                '&::-webkit-scrollbar-track': {
                                    backgroundColor: 'transparent',
                                    borderRadius: '4px'
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    backgroundColor: '#9C9C9C',
                                    borderRadius: '4px'
                                }
                            }}
                        >
                            <Box sx={{ p: 0, pb: 0 }}>

                                <List
                                    component="nav"
                                    sx={{
                                        px: 0,
                                        py: 0,
                                        "& .MuiListItemButton-root": {
                                            py: 1.5,
                                        },
                                    }}
                                >
                                    {Invited.length === 0 && (
                                        <ListItemButton
                                            sx={{
                                                minHeight: "65px",
                                                background: "#fff",
                                                borderRadius: "10px",
                                                marginTop: "5px",
                                            }}
                                            onDragEnter={(e) => dragEnter(e, "Invited")}
                                            onDragEnd={drop}
                                        >
                                            Drag items here
                                        </ListItemButton>
                                    )}
                                    {Invited?.map((list1, index) => {
                                        return (
                                            <>
                                                <ListItemButton
                                                    key={index}
                                                    id={index}
                                                    sx={{
                                                        minHeight: "65px",
                                                        display: "block",
                                                        alignItems: "center",
                                                        background: "#FFFFFF",
                                                        borderRadius: "10px",
                                                        marginTop: "7px",
                                                        py: 3,
                                                        px: 2.5
                                                    }}
                                                    onDragStart={(e) => dragStart(e, "Invited")}
                                                    onDragEnter={(e) => dragEnter(e, "Invited")}
                                                    onDragEnd={drop}
                                                    draggable
                                                >
                                                    <Stack
                                                        direction="row"
                                                        sx={{
                                                            alignItems: 'center',
                                                            justifyContent: 'space-between'
                                                        }}
                                                    >
                                                        <Typography
                                                            noWrap
                                                            sx={{
                                                                fontSize: "1rem",
                                                                fontWeight: '600',
                                                                color: 'secondary.main',
                                                                lineHeight: '22px',
                                                                mb: 0,
                                                                pr: 2
                                                            }}
                                                            variant="h6">{list1.orgName}
                                                        </Typography>
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                alignSelf: 'start'
                                                            }}
                                                        >
                                                          
                                                        </Box>
                                                    </Stack>
                                                    <Divider
                                                        sx={{
                                                            borderColor: '#DBEBF8',
                                                            my: 1
                                                        }}
                                                    />
                                                    <Stack
                                                        direction="row"
                                                        sx={{
                                                            px: "0",
                                                            alignItems: 'center',
                                                            justifyContent: 'space-between',
                                                            mb: 1
                                                        }}
                                                    >
                                                        <Stack
                                                            direction="row"
                                                            sx={{
                                                                px: "0",
                                                                alignItems: 'center'
                                                            }}
                                                        >
                                                            <ListItemAvatar
                                                                sx={{
                                                                    minWidth: '32px',
                                                                }}
                                                            >
                                                                <Avatar
                                                                    sx={{
                                                                        color: 'success.main',
                                                                        bgcolor: 'success.lighter',
                                                                        width: '32px',
                                                                        height: '32px'
                                                                    }}
                                                                    src={getImages('avatar-1.png')}
                                                                >

                                                                </Avatar>
                                                            </ListItemAvatar>
                                                            <ListItemText
                                                                sx={{
                                                                    pl: '10px',
                                                                    width: "80%",
                                                                    m: 0
                                                                }}
                                                                primary={
                                                                    <Typography noWrap
                                                                        sx={{
                                                                            fontSize: "1rem",
                                                                            fontWeight: '600',
                                                                            color: 'secondary.main',
                                                                            lineHeight: '22px',
                                                                            maxWidth: '220px',
                                                                            [theme.breakpoints.down('sm')]: {
                                                                                maxWidth: '110px'
                                                                            }
                                                                        }}
                                                                        variant="h6">{list1.name}
                                                                    </Typography>}
                                                                secondary={<Typography
                                                                    noWrap
                                                                    variant="body2"
                                                                    sx={{
                                                                        fontSize: '12px',
                                                                        fontWeight: '500',
                                                                        color: 'ltheme.main',
                                                                        maxWidth: '220px',
                                                                        [theme.breakpoints.down('sm')]: {
                                                                            maxWidth: '110px'
                                                                        }
                                                                    }}
                                                                >{list1.designation}</Typography>}
                                                            />
                                                        </Stack>
                                                        
                                                    </Stack>
                                                    <Box
                                                        sx={{
                                                            mb: 2
                                                        }}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                fontSize: "0.9rem",
                                                                fontWeight: '600',
                                                                color: 'lightSecondary.main',
                                                                lineHeight: '22px',
                                                                mb: 0.3,
                                                                minHeight: '40px'
                                                            }}
                                                            variant="h6">{list1.title}
                                                        </Typography>
                                                        <Typography
                                                            noWrap
                                                            variant="body2"
                                                            sx={{
                                                                fontSize: '12px',
                                                                fontWeight: '500',
                                                                color: 'ltheme.main',
                                                            }}
                                                        >
                                                            {list1.date}
                                                        </Typography>
                                                    </Box>
                                                    <Button variant="contained"
                                                        onClick={reviewInviteHandle}
                                                        sx={{
                                                            width: "100%",
                                                            textTransform: 'inherit',
                                                            p: "10px",
                                                            boxShadow: 'none',
                                                            '&:hover': {
                                                                boxShadow: 'none'
                                                            }
                                                        }}
                                                    >
                                                        Review Invite
                                                        <ArrowForwardOutlinedIcon
                                                            sx={{
                                                                fontSize: '20px',
                                                                ml: "8px"
                                                            }} />
                                                    </Button>
                                                </ListItemButton>
                                            </>
                                        );
                                    })}
                                </List>
                            </Box>
                        </MainCard>
                    </Box>
                    {/* Accepted */}
                    <Box
                        sx={{
                            width: '25%',
                            minWidth: '280px',
                        }}
                    >
                        <Box
                            sx={{
                                marginBottom: "10px",
                                backgroundColor: '#75DCD6',
                                textAlign: 'center',
                                borderRadius: '10px',
                                padding: '11px'
                            }}
                        >
                            <Typography
                                variant="h6"
                                color="textSecondary"
                                sx={{
                                    fontSize: "0.9rem",
                                    fontWeight: "600",
                                    color: "secondary.main",
                                }}
                            >
                                Accepted
                            </Typography>
                        </Box>
                        <MainCard
                            content={false}
                            sx={{
                                backgroundColor: "#ECF6FF",
                                border: "none",
                                padding: '5px 10px 10px',
                                height: 'calc(100vh - 220px)',
                                overflowY: 'auto',
                                '&::-webkit-scrollbar': {
                                    width: '6px',
                                    borderRadius: '4px'
                                },
                                '&::-webkit-scrollbar-track': {
                                    backgroundColor: 'transparent',
                                    borderRadius: '4px'
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    backgroundColor: '#9C9C9C',
                                    borderRadius: '4px'
                                }
                            }}
                        >
                            <Box sx={{ p: 0, pb: 0 }}>
                                <List
                                    component="nav"
                                    sx={{
                                        px: 0,
                                        py: 0,
                                        "& .MuiListItemButton-root": {
                                            py: 1.5,
                                        },
                                    }}
                                >
                                    {Accepted.length === 0 && (
                                        <ListItemButton
                                            sx={{
                                                minHeight: "65px",
                                                background: "#fff",
                                                borderRadius: "10px",
                                                marginTop: "5px",
                                            }}
                                            onDragEnter={(e) => dragEnter(e, "Accepted")}
                                            onDragEnd={drop}
                                        >
                                            Drag items here
                                        </ListItemButton>
                                    )}
                                    {Accepted?.map((list2, index) => {
                                        return (
                                            <>
                                                <ListItemButton
                                                    onClick={investmentStatusHandle}
                                                    key={index}
                                                    id={index}
                                                    sx={{
                                                        minHeight: "65px",
                                                        display: "block",
                                                        alignItems: "center",
                                                        background: "#FFFFFF",
                                                        borderRadius: "10px",
                                                        marginTop: "7px",
                                                        py: 3,
                                                        px: 2.5
                                                    }}
                                                    onDragStart={(e) => dragStart(e, "Accepted")}
                                                    onDragEnter={(e) => dragEnter(e, "Accepted")}
                                                    onDragEnd={drop}
                                                    draggable
                                                >
                                                    <Stack
                                                        direction="row"
                                                        sx={{
                                                            alignItems: 'center',
                                                            justifyContent: 'space-between'
                                                        }}
                                                    >
                                                        <Typography
                                                            noWrap
                                                            sx={{
                                                                fontSize: "1rem",
                                                                fontWeight: '600',
                                                                color: 'secondary.main',
                                                                lineHeight: '22px',
                                                                mb: 0,
                                                                pr: 2
                                                            }}
                                                            variant="h6">{list2.orgName}
                                                        </Typography>
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                alignSelf: 'start'
                                                            }}
                                                        >
                                                            <IconButton
                                                                sx={{
                                                                    p: 0.5,
                                                                }}>
                                                                <OpenInNewOutlinedIcon sx={{ color: 'lightSecondary.main' }} />
                                                            </IconButton>
                                                            <IconButton
                                                                sx={{
                                                                    p: 0.5,
                                                                }}>
                                                                <StarOutlinedIcon sx={{ color: 'lightSecondary.main' }} />
                                                            </IconButton>
                                                        </Box>
                                                    </Stack>
                                                    <Divider
                                                        sx={{
                                                            borderColor: '#DBEBF8',
                                                            my: 1
                                                        }}
                                                    />
                                                    <Stack
                                                        direction="row"
                                                        sx={{
                                                            px: "0",
                                                            alignItems: 'center',
                                                            justifyContent: 'space-between',
                                                            mb: 1
                                                        }}
                                                    >
                                                        <Stack
                                                            direction="row"
                                                            sx={{
                                                                px: "0",
                                                                alignItems: 'center'
                                                            }}
                                                        >
                                                            <ListItemAvatar
                                                                sx={{
                                                                    minWidth: '32px',
                                                                }}
                                                            >
                                                                <Avatar
                                                                    sx={{
                                                                        color: 'success.main',
                                                                        bgcolor: 'success.lighter',
                                                                        width: '32px',
                                                                        height: '32px'
                                                                    }}
                                                                    src={getImages('avatar-1.png')}
                                                                >

                                                                </Avatar>
                                                            </ListItemAvatar>
                                                            <ListItemText
                                                                sx={{
                                                                    pl: '10px',
                                                                    width: "80%",
                                                                    m: 0
                                                                }}
                                                                primary={
                                                                    <Typography noWrap
                                                                        sx={{
                                                                            fontSize: "1rem",
                                                                            fontWeight: '600',
                                                                            color: 'secondary.main',
                                                                            lineHeight: '22px',
                                                                            maxWidth: '220px',
                                                                            [theme.breakpoints.down('sm')]: {
                                                                                maxWidth: '110px'
                                                                            }
                                                                        }}
                                                                        variant="h6">{list2.name}
                                                                    </Typography>}
                                                                secondary={<Typography
                                                                    noWrap
                                                                    variant="body2"
                                                                    sx={{
                                                                        fontSize: '12px',
                                                                        fontWeight: '500',
                                                                        color: 'ltheme.main',
                                                                        maxWidth: '220px',
                                                                        [theme.breakpoints.down('sm')]: {
                                                                            maxWidth: '110px'
                                                                        }
                                                                    }}
                                                                >{list2.designation}</Typography>}
                                                            />
                                                        </Stack>
                                                        <Box>
                                                            <Typography variant="subtitle1"
                                                                sx={{
                                                                    fontSize: "0.95rem",
                                                                    color: "ltheme.main",
                                                                    fontWeight: "600"
                                                                }}>
                                                                <Badge color="secondary" badgeContent=" " variant="dot"
                                                                    sx={{
                                                                        '& .MuiBadge-badge': {
                                                                            backgroundColor: "red.main"
                                                                        }
                                                                    }}
                                                                >
                                                                    <MessageOutlinedIcon
                                                                        sx={{
                                                                            verticalAlign: "middle",
                                                                            fill: "ltheme.main",
                                                                            width: "20px",
                                                                            height: "20px"
                                                                        }}
                                                                    />
                                                                </Badge>
                                                                &nbsp;
                                                                {list2.msg}
                                                            </Typography>
                                                        </Box>
                                                    </Stack>
                                                    <Box
                                                        sx={{
                                                            mb: 2
                                                        }}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                fontSize: "0.9rem",
                                                                fontWeight: '600',
                                                                color: 'lightSecondary.main',
                                                                lineHeight: '22px',
                                                                mb: 0.3,
                                                                minHeight: '40px'
                                                            }}
                                                            variant="h6">{list2.title}
                                                        </Typography>
                                                        <Typography
                                                            noWrap
                                                            variant="body2"
                                                            sx={{
                                                                fontSize: '12px',
                                                                fontWeight: '500',
                                                                color: 'ltheme.main',
                                                            }}
                                                        >
                                                            {list2.date}
                                                        </Typography>
                                                    </Box>
                                                </ListItemButton>
                                            </>
                                        );
                                    })}
                                </List>
                            </Box>
                        </MainCard>
                    </Box>
                    {/* Rejected */}
                    <Box
                        sx={{
                            width: '25%',
                            minWidth: '280px',
                        }}
                    >
                        <Box
                            sx={{
                                marginBottom: "10px",
                                backgroundColor: '#FFB2B2',
                                textAlign: 'center',
                                borderRadius: '10px',
                                padding: '11px'
                            }}
                        >
                            <Typography
                                variant="h6"
                                color="textSecondary"
                                sx={{
                                    fontSize: "0.9rem",
                                    fontWeight: "600",
                                    color: "secondary.main",
                                }}
                            >
                                Rejected
                            </Typography>
                        </Box>
                        <MainCard
                            content={false}
                            sx={{
                                backgroundColor: "#ECF6FF",
                                border: "none",
                                padding: '5px 10px 10px',
                                height: 'calc(100vh - 220px)',
                                overflowY: 'auto',
                                '&::-webkit-scrollbar': {
                                    width: '6px',
                                    borderRadius: '4px'
                                },
                                '&::-webkit-scrollbar-track': {
                                    backgroundColor: 'transparent',
                                    borderRadius: '4px'
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    backgroundColor: '#9C9C9C',
                                    borderRadius: '4px'
                                }
                            }}
                        >
                            <Box sx={{ p: 0, pb: 0 }}>
                                <List
                                    component="nav"
                                    sx={{
                                        px: 0,
                                        py: 0,
                                        "& .MuiListItemButton-root": {
                                            py: 1.5,
                                        },
                                    }}
                                >
                                    {Rejected.length === 0 && (
                                        <ListItemButton
                                            sx={{
                                                minHeight: "65px",
                                                background: "#fff",
                                                borderRadius: "10px",
                                                marginTop: "5px",
                                            }}
                                            onDragEnter={(e) => dragEnter(e, "Rejected")}
                                            onDragEnd={drop}
                                        >
                                            Drag items here
                                        </ListItemButton>
                                    )}

                                    {Rejected?.map((list3, index) => {
                                        return (
                                            <>
                                                <ListItemButton
                                                    key={index}
                                                    id={index}
                                                    sx={{
                                                        minHeight: "65px",
                                                        display: "block",
                                                        alignItems: "center",
                                                        background: "#FFFFFF",
                                                        borderRadius: "10px",
                                                        marginTop: "7px",
                                                        py: 3,
                                                        px: 2.5
                                                    }}
                                                    onDragStart={(e) => dragStart(e, "Rejected")}
                                                    onDragEnter={(e) => dragEnter(e, "Rejected")}
                                                    onDragEnd={drop}
                                                    draggable
                                                >
                                                    <Stack
                                                        direction="row"
                                                        sx={{
                                                            alignItems: 'center',
                                                            justifyContent: 'space-between'
                                                        }}
                                                    >
                                                        <Typography
                                                            noWrap
                                                            sx={{
                                                                fontSize: "1rem",
                                                                fontWeight: '600',
                                                                color: 'secondary.main',
                                                                lineHeight: '22px',
                                                                mb: 0,
                                                                pr: 2
                                                            }}
                                                            variant="h6">{list3.orgName}
                                                        </Typography>
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                alignSelf: 'start'
                                                            }}
                                                        >
                                                            <IconButton
                                                                sx={{
                                                                    p: 0.5,
                                                                }}>
                                                                <OpenInNewOutlinedIcon sx={{ color: 'lightSecondary.main' }} />
                                                            </IconButton>
                                                            <IconButton
                                                                sx={{
                                                                    p: 0.5,
                                                                }}>
                                                                <StarOutlinedIcon sx={{ color: 'lightSecondary.main' }} />
                                                            </IconButton>
                                                        </Box>
                                                    </Stack>
                                                    <Divider
                                                        sx={{
                                                            borderColor: '#DBEBF8',
                                                            my: 1
                                                        }}
                                                    />
                                                    <Stack
                                                        direction="row"
                                                        sx={{
                                                            px: "0",
                                                            alignItems: 'center',
                                                            justifyContent: 'space-between',
                                                            mb: 1
                                                        }}
                                                    >
                                                        <Stack
                                                            direction="row"
                                                            sx={{
                                                                px: "0",
                                                                alignItems: 'center'
                                                            }}
                                                        >
                                                            <ListItemAvatar
                                                                sx={{
                                                                    minWidth: '32px',
                                                                }}
                                                            >
                                                                <Avatar
                                                                    sx={{
                                                                        color: 'success.main',
                                                                        bgcolor: 'success.lighter',
                                                                        width: '32px',
                                                                        height: '32px'
                                                                    }}
                                                                    src={getImages('avatar-1.png')}
                                                                >

                                                                </Avatar>
                                                            </ListItemAvatar>
                                                            <ListItemText
                                                                sx={{
                                                                    pl: '10px',
                                                                    width: "80%",
                                                                    m: 0
                                                                }}
                                                                primary={
                                                                    <Typography noWrap
                                                                        sx={{
                                                                            fontSize: "1rem",
                                                                            fontWeight: '600',
                                                                            color: 'secondary.main',
                                                                            lineHeight: '22px',
                                                                            maxWidth: '220px',
                                                                            [theme.breakpoints.down('sm')]: {
                                                                                maxWidth: '110px'
                                                                            }
                                                                        }}
                                                                        variant="h6">{list3.name}
                                                                    </Typography>}
                                                                secondary={<Typography
                                                                    noWrap
                                                                    variant="body2"
                                                                    sx={{
                                                                        fontSize: '12px',
                                                                        fontWeight: '500',
                                                                        color: 'ltheme.main',
                                                                        maxWidth: '220px',
                                                                        [theme.breakpoints.down('sm')]: {
                                                                            maxWidth: '110px'
                                                                        }
                                                                    }}
                                                                >{list3.designation}</Typography>}
                                                            />
                                                        </Stack>
                                                        <Box>
                                                            <Typography variant="subtitle1"
                                                                sx={{
                                                                    fontSize: "0.95rem",
                                                                    color: "ltheme.main",
                                                                    fontWeight: "600"
                                                                }}>
                                                                <Badge color="secondary" badgeContent=" " variant="dot"
                                                                    sx={{
                                                                        '& .MuiBadge-badge': {
                                                                            backgroundColor: "red.main"
                                                                        }
                                                                    }}
                                                                >
                                                                    <MessageOutlinedIcon
                                                                        sx={{
                                                                            verticalAlign: "middle",
                                                                            fill: "ltheme.main",
                                                                            width: "20px",
                                                                            height: "20px"
                                                                        }}
                                                                    />
                                                                </Badge>
                                                                &nbsp;
                                                                {list3.msg}
                                                            </Typography>
                                                        </Box>
                                                    </Stack>
                                                    <Box
                                                        sx={{
                                                            mb: 2
                                                        }}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                fontSize: "0.9rem",
                                                                fontWeight: '600',
                                                                color: 'lightSecondary.main',
                                                                lineHeight: '22px',
                                                                mb: 0.3,
                                                                minHeight: '40px'
                                                            }}
                                                            variant="h6">{list3.title}
                                                        </Typography>
                                                        <Typography
                                                            noWrap
                                                            variant="body2"
                                                            sx={{
                                                                fontSize: '12px',
                                                                fontWeight: '500',
                                                                color: 'ltheme.main',
                                                            }}
                                                        >
                                                            {list3.date}
                                                        </Typography>
                                                    </Box>
                                                </ListItemButton>
                                            </>
                                        );
                                    })}
                                </List>
                            </Box>
                        </MainCard>
                    </Box>
                    {/* Selected */}
                    <Box
                        sx={{
                            width: '25%',
                            minWidth: '280px',
                        }}
                    >
                        <Box
                            sx={{
                                marginBottom: "10px",
                                backgroundColor: '#7CDDA3',
                                textAlign: 'center',
                                borderRadius: '10px',
                                padding: '11px'
                            }}
                        >
                            <Typography
                                variant="h6"
                                color="textSecondary"
                                sx={{
                                    fontSize: "0.9rem",
                                    fontWeight: "600",
                                    color: "secondary.main",
                                }}
                            >
                                Selected
                            </Typography>
                        </Box>
                        <MainCard
                            content={false}
                            sx={{
                                backgroundColor: "#ECF6FF",
                                border: "none",
                                padding: '5px 10px 10px',
                                height: 'calc(100vh - 220px)',
                                overflowY: 'auto',
                                '&::-webkit-scrollbar': {
                                    width: '6px',
                                    borderRadius: '4px'
                                },
                                '&::-webkit-scrollbar-track': {
                                    backgroundColor: 'transparent',
                                    borderRadius: '4px'
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    backgroundColor: '#9C9C9C',
                                    borderRadius: '4px'
                                }
                            }}
                        >
                            <Box sx={{ p: 0, pb: 0 }}>
                                <List
                                    component="nav"
                                    sx={{
                                        px: 0,
                                        py: 0,
                                        "& .MuiListItemButton-root": {
                                            py: 1.5,
                                        },
                                    }}
                                >
                                    {Selected.length === 0 && (
                                        <ListItemButton
                                            sx={{
                                                minHeight: "65px",
                                                background: "#fff",
                                                borderRadius: "10px",
                                                marginTop: "5px",
                                            }}
                                            onDragEnter={(e) => dragEnter(e, "Selected")}
                                            onDragEnd={drop}
                                        >
                                            Drag items here
                                        </ListItemButton>
                                    )}

                                    {Selected?.map((list4, index) => {
                                        return (
                                            <>
                                                <ListItemButton
                                                    key={index}
                                                    id={index}
                                                    sx={{
                                                        minHeight: "65px",
                                                        display: "block",
                                                        alignItems: "center",
                                                        background: "#FFFFFF",
                                                        borderRadius: "10px",
                                                        marginTop: "7px",
                                                        py: 3,
                                                        px: 2.5
                                                    }}
                                                    onDragStart={(e) => dragStart(e, "Selected")}
                                                    onDragEnter={(e) => dragEnter(e, "Selected")}
                                                    onDragEnd={drop}
                                                    draggable
                                                >
                                                    <Stack
                                                        direction="row"
                                                        sx={{
                                                            alignItems: 'center',
                                                            justifyContent: 'space-between'
                                                        }}
                                                    >
                                                        <Typography
                                                            noWrap
                                                            sx={{
                                                                fontSize: "1rem",
                                                                fontWeight: '600',
                                                                color: 'secondary.main',
                                                                lineHeight: '22px',
                                                                mb: 0,
                                                                pr: 2
                                                            }}
                                                            variant="h6">{list4.orgName}
                                                        </Typography>
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                alignSelf: 'start'
                                                            }}
                                                        >
                                                            <IconButton
                                                                sx={{
                                                                    p: 0.5,
                                                                }}>
                                                                <OpenInNewOutlinedIcon sx={{ color: 'lightSecondary.main' }} />
                                                            </IconButton>
                                                            <IconButton
                                                                sx={{
                                                                    p: 0.5,
                                                                }}>
                                                                <StarOutlinedIcon sx={{ color: 'lightSecondary.main' }} />
                                                            </IconButton>
                                                        </Box>
                                                    </Stack>
                                                    <Divider
                                                        sx={{
                                                            borderColor: '#DBEBF8',
                                                            my: 1
                                                        }}
                                                    />
                                                    <Stack
                                                        direction="row"
                                                        sx={{
                                                            px: "0",
                                                            alignItems: 'center',
                                                            justifyContent: 'space-between',
                                                            mb: 1
                                                        }}
                                                    >
                                                        <Stack
                                                            direction="row"
                                                            sx={{
                                                                px: "0",
                                                                alignItems: 'center'
                                                            }}
                                                        >
                                                            <ListItemAvatar
                                                                sx={{
                                                                    minWidth: '32px',
                                                                }}
                                                            >
                                                                <Avatar
                                                                    sx={{
                                                                        color: 'success.main',
                                                                        bgcolor: 'success.lighter',
                                                                        width: '32px',
                                                                        height: '32px'
                                                                    }}
                                                                    src={getImages('avatar-1.png')}
                                                                >

                                                                </Avatar>
                                                            </ListItemAvatar>
                                                            <ListItemText
                                                                sx={{
                                                                    pl: '10px',
                                                                    width: "80%",
                                                                    m: 0
                                                                }}
                                                                primary={
                                                                    <Typography noWrap
                                                                        sx={{
                                                                            fontSize: "1rem",
                                                                            fontWeight: '600',
                                                                            color: 'secondary.main',
                                                                            lineHeight: '22px',
                                                                            maxWidth: '220px',
                                                                            [theme.breakpoints.down('sm')]: {
                                                                                maxWidth: '110px'
                                                                            }
                                                                        }}
                                                                        variant="h6">{list4.name}
                                                                    </Typography>}
                                                                secondary={<Typography
                                                                    noWrap
                                                                    variant="body2"
                                                                    sx={{
                                                                        fontSize: '12px',
                                                                        fontWeight: '500',
                                                                        color: 'ltheme.main',
                                                                        maxWidth: '220px',
                                                                        [theme.breakpoints.down('sm')]: {
                                                                            maxWidth: '110px'
                                                                        }
                                                                    }}
                                                                >{list4.designation}</Typography>}
                                                            />
                                                        </Stack>
                                                        <Box>
                                                            <Typography variant="subtitle1"
                                                                sx={{
                                                                    fontSize: "0.95rem",
                                                                    color: "ltheme.main",
                                                                    fontWeight: "600"
                                                                }}>
                                                                <Badge color="secondary" badgeContent=" " variant="dot"
                                                                    sx={{
                                                                        '& .MuiBadge-badge': {
                                                                            backgroundColor: "red.main"
                                                                        }
                                                                    }}
                                                                >
                                                                    <MessageOutlinedIcon
                                                                        sx={{
                                                                            verticalAlign: "middle",
                                                                            fill: "ltheme.main",
                                                                            width: "20px",
                                                                            height: "20px"
                                                                        }}
                                                                    />
                                                                </Badge>
                                                                &nbsp;
                                                                {list4.msg}
                                                            </Typography>
                                                        </Box>
                                                    </Stack>
                                                    <Box
                                                        sx={{
                                                            mb: 2
                                                        }}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                fontSize: "0.9rem",
                                                                fontWeight: '600',
                                                                color: 'lightSecondary.main',
                                                                lineHeight: '22px',
                                                                mb: 0.3,
                                                                minHeight: '40px'
                                                            }}
                                                            variant="h6">{list4.title}
                                                        </Typography>
                                                        <Typography
                                                            noWrap
                                                            variant="body2"
                                                            sx={{
                                                                fontSize: '12px',
                                                                fontWeight: '500',
                                                                color: 'ltheme.main',
                                                            }}
                                                        >
                                                            {list4.date}
                                                        </Typography>
                                                    </Box>
                                                </ListItemButton>
                                            </>
                                        );
                                    })}
                                </List>
                            </Box>
                        </MainCard>
                    </Box>
                   
                </Box>
            </Box>
            <Modals open={openBoardSettings} handleClose={closeBoardSettings} modalWidth={500}>
                <BoardSetting handleClose={closeBoardSettings} deleteBoardAlertHandle={deleteBoardAlertHandle} />
            </Modals>
            <Modals open={openDeleteBoardAlert} handleClose={closeDeleteBoardAlert} modalWidth={500}>
                <DeleteBoardAlert handleClose={closeDeleteBoardAlert} goBackFromAlertHandle={goBackFromAlertHandle} />
            </Modals>
            <Modals open={openReviewInvite} handleClose={closeReviewInvite} modalWidth={600}>
                <ReviewInviteModal handleClose={closeReviewInvite} />
            </Modals>
            <Modals open={openInvestmentStatus} handleClose={closeInvestmentStatus} modalWidth={600}>
                <InvestmentStatusModal handleClose={closeInvestmentStatus}/>
            </Modals>
        </>
    );
};

export default KanbanInvestmentEnquiries;
