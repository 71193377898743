import React, { useEffect, useState } from 'react';
import { Box, Typography, TextField, Button } from '@mui/material';
import { useUpdatedDocumentNameMutation } from '../../redux/api/BsiDrive/renameDocument';
import { useDispatch, useSelector } from 'react-redux';
import { setRefetchDocument, setRefetchSubFolder } from '../../redux/features/refetch';
import { hideLoader, showLoader } from '../../redux/features/loader';

const RenameModal = ({ handleClose,   documentId, documentName,  showToastMessage }) => {
    const userId = localStorage.getItem("userId")
    const fileNameParts = documentName.split('.');
    const originalExtension = fileNameParts.length > 1 ? fileNameParts.pop() : ''; // Extract the original extension
    const [updatedName, setUpdatedName] = useState(); // Set initial name without extension
    const [isFolderRename, setIsFolderRename] = useState(false);
    const dispatch = useDispatch();
    const handleDocumentName = (event) => {
        setUpdatedName(event.target.value.replace(/\./g, ''));
    };

    const [updateDocumentName, { isLoading:isDocumentRename, isError, error }] = useUpdatedDocumentNameMutation();
    const renameDocument = async () => {
        if (!updatedName.trim()) {
            showToastMessage('Name cannot be empty', 'error');
            return;
        }
        setIsFolderRename(true);
        try {
            let id =documentId;
            const newFileName = originalExtension ? `${updatedName}.${originalExtension}` : updatedName;
            const result = await updateDocumentName({
                documentId: id,
                updatedName: newFileName,
                userId:userId,
            }).unwrap();

            const successMessage = result?.message || 'File renamed'
            showToastMessage(successMessage, 'success');
            handleClose();
            dispatch(setRefetchDocument(true));
            dispatch(setRefetchSubFolder(true));
        } catch (error) {
            const errorMessage = error?.message || 'Failed to rename';
            showToastMessage(errorMessage, 'error');
        }
    };


    useEffect(() => {
        if (isDocumentRename) {
          dispatch(showLoader());
        } else {
          dispatch(hideLoader());
        }
      }, [isDocumentRename, dispatch]);

    return (
        <>
            <Box sx={{ mb: 3 }}>
                <Typography
                    variant="h5"
                    sx={{
                        fontSize: '1.3rem',
                        color: 'secondary.main',
                        fontWeight: '700',
                        maxWidth: '100%', // Set maximum width to the width of the modal
                        whiteSpace: 'nowrap', // Prevents text from wrapping onto a new line
                        overflow: 'hidden', // Hides any overflowed text
                        textOverflow: 'ellipsis', // Adds "..." at the end of the truncated text
                      }}
                >
                    {documentName}
                </Typography>
            </Box>
            <TextField
                hiddenLabel
                id="filled-hidden-label-normal"
                variant="filled"
                placeholder="Type new name"
                onChange={handleDocumentName}
                sx={{
                    width: '100%',
                    borderRadius: '4px',
                    backgroundColor: 'extraLTheme.main',
                    border: 'solid 1px #DBEBF8',
                    '& .MuiInputBase-input': {
                        fontSize: '0.9rem',
                        color: 'lightSecondary.main',
                        fontWeight: '500',
                        pt: 1.5,
                        pb: 1.5,
                    },
                    '& .MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error):before': {
                        border: 'none',
                    },
                    '& .MuiInputBase-root:hover:before': {
                        border: 'none',
                    },
                    '&:hover': {
                        borderColor: '#DBEBF8',
                    },
                    '& .MuiInputBase-root:before': {
                        border: 'none',
                    },
                    '& .MuiInputBase-root:after': {
                        border: 'none',
                    },
                }}
            />
            <Box sx={{ mt: 3, display: 'flex', gap: 2 }}>
                <Button
                    variant="outlined"
                    sx={{
                        textTransform: 'inherit',
                        p: '10px 25px',
                        width: '50%',
                        borderWidth: '2px',
                        fontWeight: '600',
                        '&:hover': {
                            borderWidth: '2px',
                        },
                    }}
                    onClick={handleClose}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    sx={{
                        textTransform: 'inherit',
                        p: '12px 25px',
                        width: '50%',
                        boxShadow: 'none',
                        fontWeight: '600',
                        '&:hover': {
                            boxShadow: 'none',
                        },
                        "&.Mui-disabled": {
                        backgroundColor: "primary.main", // Change this to the desired color
                        color: "white", // Maintain text color
                        opacity: 1, // Keep the button fully opaque
                        boxShadow: "none", // No shadow for the disabled state
                        },
                    }}
                    onClick={renameDocument}
                    disabled={isFolderRename}
                >
                    Rename
                </Button>
            </Box>
        </>
    );
};

export default RenameModal;
