import React from 'react'
import {
    Avatar,
    Box,
    IconButton,
    List,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    Typography,
    TextField,
    styled,
    InputBase,
    Button
} from '@mui/material'
import { useTheme } from '@emotion/react';
import SearchIcon from '@mui/icons-material/Search';
import { getImages } from '../const';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';

const NewChat = ({ handleClose, createGroupHandle }) => {
    const theme = useTheme();
    const Search = styled('div')(({ theme }) => ({
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: '#ECF6FF',
        '&:hover': {
            backgroundColor: '#ECF6FF',
        },
        marginRight: '0',
        marginLeft: 0,
        marginBottom: 5,
        width: '100%'
    }));
    const SearchIconWrapper = styled('div')(({ theme }) => ({
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        right: '0',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }));

    const StyledInputBase = styled(InputBase)(({ theme }) => ({
        color: '#106EED',
        width: '100%',
        '& .MuiInputBase-input': {
            padding: theme.spacing(1.7, 0, 1.7, 1.7),
            paddingRight: `calc(1em + ${theme.spacing(4)})`,
            transition: theme.transitions.create('width'),
            width: '100%',
            fontSize: '12px',
            fontWeight: '500'
        },
    }));
    return (
        <>


            <Box
                sx={{
                    mb: 3
                }}
            >
                <Typography variant='h5'
                    sx={{
                        fontSize: "1.1rem",
                        color: "secondary.main",
                        fontWeight: "700"
                    }}>
                    New Chat
                </Typography>
            </Box>
            <Search
                sx={{
                    backgroundColor: 'extraLTheme.main',
                    mb: 2
                }}
            >
                <SearchIconWrapper>
                    <SearchIcon sx={{ color: "lightSecondary.main" }} />
                </SearchIconWrapper>
                <StyledInputBase
                    placeholder="Search person to start a new chat with"
                    inputProps={{ 'aria-label': 'search' }}
                />
            </Search>
            <Box
                sx={{
                    borderBottom: 'solid 1px #DBEBF8',
                    pb: 1.5,
                    mb: 2
                }}
            >
                <Button variant="text"
                    sx={{
                        textTransform: 'inherit',
                        p: "0",
                        borderWidth: '2px',
                        fontWeight: '600',
                        '&:hover': {
                            borderWidth: '2px',
                            backgroundColor: 'transparent'
                        }
                    }}
                    onClick={createGroupHandle}
                >
                    <IconButton aria-label="Group add"
                        sx={{
                            backgroundColor: '#DBEBF8',
                            color: 'primary.main',
                            mr: 1.5
                        }}
                    >
                        <GroupAddOutlinedIcon />
                    </IconButton>
                    Create New Group
                </Button>
            </Box>
            <Typography
                variant="body2"
                sx={{
                    fontSize: '0.9rem',
                    fontWeight: '500',
                    color: 'ltheme.main',
                    mb: 1,

                }}
            >People in this board</Typography>
            <List
                component="nav"
                sx={{
                    px: 0,
                    py: 0,
                    mb: 3,
                    '& .MuiListItemButton-root': {
                        py: 0.5,
                    },
                    overflowY: 'auto',
                    maxHeight: '364px',
                    '&::-webkit-scrollbar': {
                        width: '6px',
                        borderRadius: '4px'
                    },
                    '&::-webkit-scrollbar-track': {
                        backgroundColor: 'transparent',
                        borderRadius: '4px'
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: '#9C9C9C',
                        borderRadius: '4px'
                    }
                }}
            >
                <ListItemButton sx={{ px: "0", py:"5px" }}>
                    <ListItemAvatar
                        sx={{
                            width: "56px"
                        }}
                    >
                        <Avatar
                            sx={{
                                color: 'success.main',
                                bgcolor: 'success.lighter',
                                width: '50px',
                                height: '50px'
                            }}
                            src={getImages('1.jpg')}
                        >

                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        sx={{
                            pl: '10px',
                            width: "calc(100% - 56px)"
                        }}
                        primary={
                            <Typography noWrap
                                sx={{
                                    fontSize: "1rem",
                                    fontWeight: '600',
                                    color: 'secondary.main',
                                    lineHeight: '22px',
                                    maxWidth: '220px',
                                    [theme.breakpoints.down('sm')]: {
                                        maxWidth: '110px'
                                    }
                                }}
                                variant="h6">Elizabeth Bush
                            </Typography>}
                        secondary={<Typography
                            noWrap
                            variant="body2"
                            sx={{
                                fontSize: '12px',
                                fontWeight: '500',
                                color: 'ltheme.main',
                            }}
                        >Tech support</Typography>}
                    />
                </ListItemButton>
                <ListItemButton sx={{ px: "0", py:"5px" }}>
                    <ListItemAvatar
                        sx={{
                            width: "56px"
                        }}
                    >
                        <Avatar
                            sx={{
                                color: 'success.main',
                                bgcolor: 'success.lighter',
                                width: '50px',
                                height: '50px'
                            }}
                            src={getImages('2.jpg')}
                        >

                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        sx={{
                            pl: '10px',
                            width: "calc(100% - 56px)"
                        }}
                        primary={
                            <Typography noWrap
                                sx={{
                                    fontSize: "1rem",
                                    fontWeight: '600',
                                    color: 'secondary.main',
                                    lineHeight: '22px',
                                    maxWidth: '220px',
                                    [theme.breakpoints.down('sm')]: {
                                        maxWidth: '110px'
                                    }
                                }}
                                variant="h6">Ray Sin
                            </Typography>}
                        secondary={<Typography
                            noWrap
                            variant="body2"
                            sx={{
                                fontSize: '12px',
                                fontWeight: '500',
                                color: 'ltheme.main',
                            }}
                        >Chief executive officer</Typography>}
                    />
                </ListItemButton>
                <ListItemButton sx={{ px: "0", py:"5px" }}>
                    <ListItemAvatar
                        sx={{
                            width: "56px"
                        }}
                    >
                        <Avatar
                            sx={{
                                color: 'success.main',
                                bgcolor: 'success.lighter',
                                width: '50px',
                                height: '50px'
                            }}
                            src={getImages('3.jpg')}
                        >

                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        sx={{
                            pl: '10px',
                            width: "calc(100% - 56px)"
                        }}
                        primary={
                            <Typography noWrap
                                sx={{
                                    fontSize: "1rem",
                                    fontWeight: '600',
                                    color: 'secondary.main',
                                    lineHeight: '22px',
                                    maxWidth: '220px',
                                    [theme.breakpoints.down('sm')]: {
                                        maxWidth: '110px'
                                    }
                                }}
                                variant="h6">Katherine Erd
                            </Typography>}
                        secondary={<Typography
                            noWrap
                            variant="body2"
                            sx={{
                                fontSize: '12px',
                                fontWeight: '500',
                                color: 'ltheme.main',
                            }}
                        >Marketing manager</Typography>}
                    />
                </ListItemButton>
                <ListItemButton sx={{ px: "0", py:"5px" }}>
                    <ListItemAvatar
                        sx={{
                            width: "56px"
                        }}
                    >
                        <Avatar
                            sx={{
                                color: 'success.main',
                                bgcolor: 'success.lighter',
                                width: '50px',
                                height: '50px'
                            }}
                            src={getImages('4.jpg')}
                        >

                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        sx={{
                            pl: '10px',
                            width: "calc(100% - 56px)"
                        }}
                        primary={
                            <Typography noWrap
                                sx={{
                                    fontSize: "1rem",
                                    fontWeight: '600',
                                    color: 'secondary.main',
                                    lineHeight: '22px',
                                    maxWidth: '220px',
                                    [theme.breakpoints.down('sm')]: {
                                        maxWidth: '110px'
                                    }
                                }}
                                variant="h6">Paddy O’Furniture
                            </Typography>}
                        secondary={<Typography
                            noWrap
                            variant="body2"
                            sx={{
                                fontSize: '12px',
                                fontWeight: '500',
                                color: 'ltheme.main',
                            }}
                        >Creative director</Typography>}
                    />
                </ListItemButton>
                <ListItemButton sx={{ px: "0", py:"5px" }}>
                    <ListItemAvatar
                        sx={{
                            width: "56px"
                        }}
                    >
                        <Avatar
                            sx={{
                                color: 'success.main',
                                bgcolor: 'success.lighter',
                                width: '50px',
                                height: '50px'
                            }}
                            src={getImages('1.jpg')}
                        >

                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        sx={{
                            pl: '10px',
                            width: "calc(100% - 56px)"
                        }}
                        primary={
                            <Typography noWrap
                                sx={{
                                    fontSize: "1rem",
                                    fontWeight: '600',
                                    color: 'secondary.main',
                                    lineHeight: '22px',
                                    maxWidth: '220px',
                                    [theme.breakpoints.down('sm')]: {
                                        maxWidth: '110px'
                                    }
                                }}
                                variant="h6">Joe Arias
                            </Typography>}
                        secondary={<Typography
                            noWrap
                            variant="body2"
                            sx={{
                                fontSize: '12px',
                                fontWeight: '500',
                                color: 'ltheme.main',
                            }}
                        >UI/UX designer</Typography>}
                    />
                </ListItemButton>
                <ListItemButton sx={{ px: "0", py:"5px" }}>
                    <ListItemAvatar
                        sx={{
                            width: "56px"
                        }}
                    >
                        <Avatar
                            sx={{
                                color: 'success.main',
                                bgcolor: 'success.lighter',
                                width: '50px',
                                height: '50px'
                            }}
                            src={getImages('1.jpg')}
                        >

                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        sx={{
                            pl: '10px',
                            width: "calc(100% - 56px)"
                        }}
                        primary={
                            <Typography noWrap
                                sx={{
                                    fontSize: "1rem",
                                    fontWeight: '600',
                                    color: 'secondary.main',
                                    lineHeight: '22px',
                                    maxWidth: '220px',
                                    [theme.breakpoints.down('sm')]: {
                                        maxWidth: '110px'
                                    }
                                }}
                                variant="h6">Elizabeth Bush
                            </Typography>}
                        secondary={<Typography
                            noWrap
                            variant="body2"
                            sx={{
                                fontSize: '12px',
                                fontWeight: '500',
                                color: 'ltheme.main',
                            }}
                        >Tech support</Typography>}
                    />
                </ListItemButton>
                <ListItemButton sx={{ px: "0", py:"5px" }}>
                    <ListItemAvatar
                        sx={{
                            width: "56px"
                        }}
                    >
                        <Avatar
                            sx={{
                                color: 'success.main',
                                bgcolor: 'success.lighter',
                                width: '50px',
                                height: '50px'
                            }}
                            src={getImages('2.jpg')}
                        >

                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        sx={{
                            pl: '10px',
                            width: "calc(100% - 56px)"
                        }}
                        primary={
                            <Typography noWrap
                                sx={{
                                    fontSize: "1rem",
                                    fontWeight: '600',
                                    color: 'secondary.main',
                                    lineHeight: '22px',
                                    maxWidth: '220px',
                                    [theme.breakpoints.down('sm')]: {
                                        maxWidth: '110px'
                                    }
                                }}
                                variant="h6">Ray Sin
                            </Typography>}
                        secondary={<Typography
                            noWrap
                            variant="body2"
                            sx={{
                                fontSize: '12px',
                                fontWeight: '500',
                                color: 'ltheme.main',
                            }}
                        >Chief executive officer</Typography>}
                    />
                </ListItemButton>
                <ListItemButton sx={{ px: "0", py:"5px" }}>
                    <ListItemAvatar
                        sx={{
                            width: "56px"
                        }}
                    >
                        <Avatar
                            sx={{
                                color: 'success.main',
                                bgcolor: 'success.lighter',
                                width: '50px',
                                height: '50px'
                            }}
                            src={getImages('3.jpg')}
                        >

                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        sx={{
                            pl: '10px',
                            width: "calc(100% - 56px)"
                        }}
                        primary={
                            <Typography noWrap
                                sx={{
                                    fontSize: "1rem",
                                    fontWeight: '600',
                                    color: 'secondary.main',
                                    lineHeight: '22px',
                                    maxWidth: '220px',
                                    [theme.breakpoints.down('sm')]: {
                                        maxWidth: '110px'
                                    }
                                }}
                                variant="h6">Katherine Erd
                            </Typography>}
                        secondary={<Typography
                            noWrap
                            variant="body2"
                            sx={{
                                fontSize: '12px',
                                fontWeight: '500',
                                color: 'ltheme.main',
                            }}
                        >Marketing manager</Typography>}
                    />
                </ListItemButton>
                <ListItemButton sx={{ px: "0", py:"5px" }}>
                    <ListItemAvatar
                        sx={{
                            width: "56px"
                        }}
                    >
                        <Avatar
                            sx={{
                                color: 'success.main',
                                bgcolor: 'success.lighter',
                                width: '50px',
                                height: '50px'
                            }}
                            src={getImages('4.jpg')}
                        >

                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        sx={{
                            pl: '10px',
                            width: "calc(100% - 56px)"
                        }}
                        primary={
                            <Typography noWrap
                                sx={{
                                    fontSize: "1rem",
                                    fontWeight: '600',
                                    color: 'secondary.main',
                                    lineHeight: '22px',
                                    maxWidth: '220px',
                                    [theme.breakpoints.down('sm')]: {
                                        maxWidth: '110px'
                                    }
                                }}
                                variant="h6">Paddy O’Furniture
                            </Typography>}
                        secondary={<Typography
                            noWrap
                            variant="body2"
                            sx={{
                                fontSize: '12px',
                                fontWeight: '500',
                                color: 'ltheme.main',
                            }}
                        >Creative director</Typography>}
                    />
                </ListItemButton>
                <ListItemButton sx={{ px: "0", py:"5px" }}>
                    <ListItemAvatar
                        sx={{
                            width: "56px"
                        }}
                    >
                        <Avatar
                            sx={{
                                color: 'success.main',
                                bgcolor: 'success.lighter',
                                width: '50px',
                                height: '50px'
                            }}
                            src={getImages('1.jpg')}
                        >

                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        sx={{
                            pl: '10px',
                            width: "calc(100% - 56px)"
                        }}
                        primary={
                            <Typography noWrap
                                sx={{
                                    fontSize: "1rem",
                                    fontWeight: '600',
                                    color: 'secondary.main',
                                    lineHeight: '22px',
                                    maxWidth: '220px',
                                    [theme.breakpoints.down('sm')]: {
                                        maxWidth: '110px'
                                    }
                                }}
                                variant="h6">Joe Arias
                            </Typography>}
                        secondary={<Typography
                            noWrap
                            variant="body2"
                            sx={{
                                fontSize: '12px',
                                fontWeight: '500',
                                color: 'ltheme.main',
                            }}
                        >UI/UX designer</Typography>}
                    />
                </ListItemButton>
            </List>
            <Button variant="outlined"
                sx={{
                    textTransform: 'inherit',
                    p: "7px 25px",
                    width: '100%',
                    borderWidth: '2px',
                    fontWeight: '600',
                    borderColor: '#106EED',
                    '&:hover': {
                        borderWidth: '2px',
                    }
                }}
            >
                Manage Team Members
            </Button>
        </>
    )
}

export default NewChat