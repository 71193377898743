import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './appRoutes';
import AuthenticationRoutes from './authenticationRoutes';
// import Cookies from 'js-cookie';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    // var routes = (Cookies.get('jwt') !== "" && Cookies.get('jwt') !== undefined)?MainRoutes: AuthenticationRoutes; 
    var routes = MainRoutes
    return useRoutes([routes])
}
 