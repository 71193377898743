import React, { useRef, useState } from 'react';
import {
    Box,
    Button,
    MenuItem,
    Stack,
    Typography,
    Menu,
    styled,
    InputBase,
    Radio,
    FormControlLabel,
    Select,
    FormControl,
    IconButton,
} from '@mui/material';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { useTheme } from '@emotion/react';
import SearchIcon from '@mui/icons-material/Search';
import CancelIcon from '@mui/icons-material/Cancel';
import { KeyboardArrowDownOutlined } from '@mui/icons-material';
import NorthOutlinedIcon from '@mui/icons-material/NorthOutlined';
import SouthOutlinedIcon from '@mui/icons-material/SouthOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import DriveFolder from '../../commonComponents/BsiDrive/DriveFolder';
import DriveFiles from '../../commonComponents/BsiDrive/DriveFiles';
import NewFolderModal from '../../commonComponents/BsiDrive/NewFolderModal';
import Modals from '../../commonComponents/Modals';
import RenameModal from '../../commonComponents/BsiDrive/RenameModal';
import MoveModal from '../../commonComponents/BsiDrive/MoveModal';
import ShareModal from '../../commonComponents/BsiDrive/ShareModal';
import ShareSelectedBoardModal from '../../commonComponents/BsiDrive/ShareSelectedBoardModal';
import ShareSolutionChatModal from '../../commonComponents/BsiDrive/ShareSolutionChatModal';
import { toast} from 'react-toastify';
import SubDriveFolder from './SubDriveFolder';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Link, useLocation } from 'react-router-dom';
import { useFetchDriveSubFolderListQuery } from '../../redux/api/BsiDrive/getDriveSubFolderList';
import { useDispatch, useSelector } from 'react-redux';
import { hideLoader, showLoader } from '../../redux/features/loader';
import FileUpload from './fileUpload';


const BsiSubDrive = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const userId = localStorage.getItem("userId")
    const Search = styled("div")(({ theme }) => ({
        position: "relative",
        borderRadius: theme.shape.borderRadius,
        backgroundColor: "#ECF6FF",
        "&:hover": {
          backgroundColor: "#ECF6FF",
        },
        marginLeft: 0,
        marginBottom: 0,
        width: "300px",
        display: 'flex', 
        alignItems: 'center',
        [theme.breakpoints.down("md")]: {
          width: "calc(100% - 180px)",
        },
        [theme.breakpoints.down("sm")]: {
          width: "100%",
        },
        
      }));
      const SearchIconWrapper = styled("div")(({ theme }) => ({
        padding: theme.spacing(0, 2),
        height: "100%",
        position: "absolute",
        right: "0",
        pointerEvents: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }));
    
      const StyledInputBase = styled(InputBase)(({ theme }) => ({
        color: "#106EED",
        width: "100%",
        "& .MuiInputBase-input": {
          padding: theme.spacing(1.9, 0, 1.9, 1.7),
          paddingRight: `calc(1em + ${theme.spacing(4)})`,
          transition: theme.transitions.create("width"),
          width: "100%",
          fontSize: "12px",
          fontWeight: "500",
        },
      }));

      const showToastMessage = (message, type) => {
        if (type === "success") {
          toast.success(message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else if (type === "error") {
          toast.error(message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      };

    // Sort and Filter
    const [openSort, setOpenSort] = useState(false);
    const [selectedSortOption, setSelectedSortOption] = useState('');

    const [openFilter, setOpenFilter] = useState(false);
    const [anchorElSort, setAnchorElSort] = useState(null);
    const [anchorElFilter, setAnchorElFilter] = useState(null);

    const handleClickSort = (event) => {
        setAnchorElSort(event.currentTarget);
        setOpenSort(!openSort);
    };
    const handleCloseSort = (event) => {
        setAnchorElSort(null);
        setOpenSort(false);
        setSelectedSortOption(event.target.value);
    };
    const handleClickFilter = (event) => {
        setAnchorElFilter(event.currentTarget);
        setOpenFilter(!openFilter);
    };
    const handleCloseFilter = () => {
        setAnchorElFilter(null);
        setOpenFilter(false);
    };
    //   upload modal
    const [openUploadModal , setOpenUploadModal] = useState(false);
    const handleOpenUploadModal = ()=>{
        setOpenUploadModal(true)
    };
    const handleCloseUploadModal = ()=>{
        setOpenUploadModal(false)
    };

    // Select 
    const [designation, setDesignation] = useState('');
    const handleChange = (event) => {
        setDesignation(event.target.value);
    };

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const parentFolderId = queryParams.get('documentId');
    const parentFolderName = queryParams.get('documentName');

    const [searchParams , setSearchParams] = useState("");
    const [searchValue , setSearchValue] = useState("");
    const handleInputChange = (event) => {
        setSearchParams(event.target.value);
      };
    const {
        data: subFolderList,
        error: isDriveSubFolderError,
        isLoading: isDriveSubFolderLoading,
        refetch:refetchSubFolder,
      } = useFetchDriveSubFolderListQuery({ userId, parentFolderId });
    
  const handleDocumentSearch = async (event) => {
    if (event.key === 'Enter') {
        const inputValue = event.target.value.trim();
        if (!inputValue) {
            setSearchValue(''); // Reset the searchValue state
            await refetchSubFolder();
            return;
        }

        setSearchValue(inputValue);
        dispatch(showLoader());
        try {
             // Start loading
            await refetchSubFolder(); 
            if (isDriveSubFolderError) {
                const errorMessage = subFolderList?.message || 'Failed to load search result';
                showToastMessage(errorMessage, 'error');
            }
        } catch (error) {
            showToastMessage('An error occurred during search', 'error');
        }finally {
            // Hide loader after refetch is complete
            dispatch(hideLoader());
          }
    }
 };
        const handleClearSearch = () => {
            setSearchParams("");
            setSearchValue(""); // Reset search value to empty
            refetchSubFolder(); // Refetch all data
        };
  // Function to trigger the handleBack method in SubDriveFolder
      const subDriveFolderRef = useRef(null);
      
        const triggerHandleBack = () => {
            if (subDriveFolderRef.current) {
            subDriveFolderRef.current.handleBack();
            }
        };

    return (
        <>
            <Stack
                direction="row"
                sx={{
                    justifyItems: 'center',
                    justifyContent: 'space-between',
                    [theme.breakpoints.down('md')]: {
                        flexWrap: 'wrap'
                    }
                }}
            >

                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap:"10px",
                        [theme.breakpoints.down('md')]: {
                            width: '100%'
                        },
                        [theme.breakpoints.down('sm')]: {
                            flexWrap: 'wrap'
                        }
                    }}
                >
                    <Link
                     style={{
                        color: '#2A589C',
                        textDecoration: "none",
                        fontSize: "1.4rem",
                        fontWeight: "700",
                        color: '#073985',
                        display: "flex",
                        alignItems: "center",
                        [theme.breakpoints.down('md')]: {
                            fontSize: '1.1rem',
                        },
                    }}
                    
                    >
                     <KeyboardBackspaceIcon
                    sx={{
                        fontSize: "2.1rem",
                    }}
                    style={{ verticalAlign: "middle", marginRight: "0.3rem" }}
                    onClick={triggerHandleBack}
                   />
                   </Link>
                    <Search
                        sx={{
                            backgroundColor: 'extraLTheme.main',
                            mr: 2,
                            [theme.breakpoints.down('sm')]: {
                                mr: 0
                            }
                        }}
                    >
                        <StyledInputBase
                            id="searchBox"
                            placeholder="Search file or folder"
                            inputProps={{ 'aria-label': 'search' }}
                            value={searchParams}
                            onChange={handleInputChange}
                            onKeyDown={handleDocumentSearch}
                            autoFocus
                        />
                          {searchParams ? (
                            <IconButton onClick={handleClearSearch} sx={{ position: 'absolute', right: 0 }}>
                            <CancelIcon sx={{ color: "lightSecondary.main" }} />
                            </IconButton>
                        ) : (
                            <SearchIconWrapper>
                            <SearchIcon sx={{ color: "lightSecondary.main" }} />
                            </SearchIconWrapper>
                        )}
                    </Search>
                    <Button variant="contained"
                        sx={{
                            textTransform: 'inherit',
                            p: "11px",
                            boxShadow: 'none',
                            px: 5,
                            '&:hover': {
                                boxShadow: 'none'
                            },
                            [theme.breakpoints.down('md')]: {
                                px: 2.5,
                            },
                            [theme.breakpoints.down('sm')]: {
                                width: '100%',
                                mt: 1
                            }
                        }}
                        onClick={handleOpenUploadModal}
                    >
                        <FileUploadOutlinedIcon
                            sx={{
                                fontSize: '20px',
                                marginRight: "8px"
                            }}
                        /> Upload Files
                    </Button>
                </Box>
                <Stack
                    direction="row"
                    sx={{
                        [theme.breakpoints.down('md')]: {
                            mt: 2,
                            ml: 'auto'
                        },
                        [theme.breakpoints.down('sm')]: {
                            mt: 1.2,
                            ml: '0',
                            width: '100%'
                        }
                    }}
                >
                </Stack>

            </Stack>

            {/* folder and file component */}
            <SubDriveFolder
              searchValue={searchValue}
              ref={subDriveFolderRef} 
              showToastMessage={showToastMessage}
              parentFolderId={parentFolderId}
              parentFolderName={parentFolderName}
            />
            <Modals open={openUploadModal} handleClose={handleCloseUploadModal} modalWidth={500}>
                <FileUpload handleClose={handleCloseUploadModal} handleCloseUploadModal={handleCloseUploadModal} parentFolderId={parentFolderId} showToastMessage={showToastMessage}  />
            </Modals>

        </>
    )
}

export default BsiSubDrive;