import React from 'react'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import {
    Avatar,
    Box,
    Button,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    MenuItem,
    Stack,
    Typography,
    Card,
    CardContent,
    IconButton,
    Select,
    FormControl,
    TextField,
    Input,
    InputAdornment,
    OutlinedInput,
    Chip,
    FilledInput,
} from '@mui/material';
import { useTheme } from '@emotion/react';
import { styled } from '@mui/material/styles';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import { Link } from 'react-router-dom';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import { CloseOutlined } from '@mui/icons-material';
import { getImages } from '../const';
const indNames = [
    'Fintech',
    'Citizen Service',
];
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
function getStyles(indNames, IndustryName, theme) {
    return {
        fontWeight:
            IndustryName.indexOf(indNames) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}
const EditSolutionDetails = () => {
    const theme = useTheme();
    const [IndustryName, setIndustryName] = React.useState([]);

    const handleChangeIndustry = (event) => {
        const {
            target: { value },
        } = event;
        setIndustryName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setIndustryName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    return (
        <>
            <Box>
                <Typography
                    sx={{
                        fontSize: "1.4rem",
                        fontWeight: "700",
                        color: 'secondary.main',
                        [theme.breakpoints.down('md')]: {
                            fontSize: '1.1rem',
                        },
                    }} variant="h4"
                    noWrap component="div">
                    <Link to="/my-solutions"
                        style={{
                            color: '#2A589C',
                            textDecoration: "none"
                        }}
                    >
                        <KeyboardBackspaceIcon
                            sx={{
                                verticalAlign: "-12px",
                                fontSize: '40px',
                                [theme.breakpoints.down('md')]: {
                                    fontSize: '30px',
                                    verticalAlign: "-10px",
                                },
                            }}
                        /> Edit Solution Details
                    </Link>
                </Typography>
            </Box>


            <Grid container mt={1} columnSpacing={2}>
                <Grid item xs={12} md={11} lg={11}>
                    <Card
                        sx={{
                            boxShadow: 'none',
                            borderRadius: "10px",
                            mb: 3
                        }}
                    >
                        <CardContent
                            sx={{
                                p: 4,
                                [theme.breakpoints.down('md')]: {
                                    p: 2,
                                },
                            }}
                            style={{
                                paddingBottom: "0"
                            }}
                        >

                            <Box
                                component="form"
                                sx={{
                                    mt: 0,
                                    mb: 0,
                                    '& .MuiTextField-root': { m: 1, width: '25ch' },
                                }}
                                noValidate
                                autoComplete="off"
                            >
                                <Grid container rowSpacing={2} columnSpacing={5} sx={{
                                    mb: 2
                                }}>
                                    <Grid item xs={12} md={6} lg={6}
                                    >
                                        <Grid container rowSpacing={0} columnSpacing={2} sx={{
                                            mb: 0
                                        }}>
                                            <Grid item xs={3} md={3} lg={3} xl={2}>
                                                <Box
                                                    sx={{
                                                        backgroundColor: 'extraLTheme.main',
                                                        textAlign: 'center',
                                                        height: '68px',
                                                        pt: 2
                                                    }}
                                                >
                                                    <img src={getImages('image_172.png')} alt='logo'/>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={9} md={9} lg={9} xl={10}>
                                                <Typography
                                                    noWrap
                                                    variant="body2"
                                                    sx={{
                                                        fontSize: '12px',
                                                        fontWeight: '500',
                                                        color: 'ltheme.main',
                                                        width: "100%",
                                                        mb: 0.5
                                                    }}
                                                >Solution Logo</Typography>
                                                <FormControl variant="filled"
                                                    sx={{
                                                        mb: 0,
                                                        width: '100%',
                                                        '& .MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error):before': {
                                                            border: 'none',
                                                            background: 'transparent'
                                                        },
                                                        '& .MuiInputBase-root:before': {
                                                            border: 'none'
                                                        },
                                                        '& .MuiInputBase-root:after': {
                                                            border: 'none'
                                                        },
                                                    }}
                                                >
                                                    <Input
                                                        sx={{
                                                            width: '100%',
                                                            borderRadius: '4px',
                                                            backgroundColor: 'extraLTheme.main',
                                                            border: 'solid 1px #DBEBF8',
                                                            '& .MuiInputBase-input': {
                                                                fontSize: '0.9rem',
                                                                color: 'lightSecondary.main',
                                                                fontWeight: '500',
                                                                p: 1.5
                                                            },
                                                        }}
                                                        id="standard-adornment-password"
                                                        type='text'
                                                        placeholder='Enter Old Password'
                                                        value='Trustdock.png'
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    sx={{
                                                                        mr: 1
                                                                    }}
                                                                >
                                                                    <BorderColorOutlinedIcon sx={{ color: 'lightSecondary.main' }} />
                                                                </IconButton>
                                                            </InputAdornment>
                                                        }
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={6}
                                    >
                                        <Typography
                                            noWrap
                                            variant="body2"
                                            sx={{
                                                fontSize: '12px',
                                                fontWeight: '500',
                                                color: 'ltheme.main',
                                                width: "100%",
                                                mb: 0.5
                                            }}
                                        >Solution Name</Typography>
                                        <TextField
                                            hiddenLabel
                                            id="filled-hidden-label-normal"
                                            variant="filled"
                                            value='TRUSTDOCK Digital ID'
                                            style={{
                                                margin: 0,
                                                width: '100%'
                                            }}
                                            sx={{
                                                width: '100%',
                                                borderRadius: '4px',
                                                backgroundColor: 'extraLTheme.main',
                                                border: 'solid 1px #DBEBF8',
                                                m: 0,
                                                '& .MuiInputBase-root': {
                                                    backgroundColor: 'extraLTheme.main',
                                                },
                                                '& .MuiInputBase-input': {
                                                    fontSize: '0.9rem',
                                                    color: 'lightSecondary.main',
                                                    fontWeight: '500',
                                                    pt: 1.5,
                                                    pb: 1.5
                                                },
                                                '& .MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error):before': {
                                                    border: 'none'
                                                },
                                                '& .MuiInputBase-root:hover:before': {
                                                    border: 'none'
                                                },
                                                '&:hover': {
                                                    borderColor: '#DBEBF8'
                                                },
                                                '& .MuiInputBase-root:before': {
                                                    border: 'none'
                                                },
                                                '& .MuiInputBase-root:after': {
                                                    border: 'none'
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12}>
                                        <Typography
                                            noWrap
                                            variant="body2"
                                            sx={{
                                                fontSize: '12px',
                                                fontWeight: '500',
                                                color: 'ltheme.main',
                                                width: "100%",
                                                mb: 0.5
                                            }}
                                        >Solution Overview</Typography>
                                        <textarea rows="4"
                                            value='As an extension of our services, we offer TRUSTDOCK ID. TRUSTDOCK ID is a secure virtual identity wallet where users can store their TRUSTDOCK-verified information for future use by other companies us.'
                                            style={{
                                                background: '#ECF6FF',
                                                padding: '12px',
                                                border: 'solid 1px #DBEBF8',
                                                outline: 'none',
                                                width: '100%',
                                                boxSizing: 'border-box',
                                                resize: 'none',
                                                borderRadius: '4px',
                                                color: '#2A589C',
                                                fontWeight: "600",
                                                fontSize: "0.95rem",
                                            }}
                                            className='textarea-placeholder'
                                        >
                                        </textarea>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={6}>
                                        <Typography
                                            noWrap
                                            variant="body2"
                                            sx={{
                                                fontSize: '12px',
                                                fontWeight: '500',
                                                color: 'ltheme.main',
                                                width: "100%",
                                                mb: 0.5
                                            }}
                                        >Website URL</Typography>
                                        <TextField
                                            hiddenLabel
                                            id="filled-hidden-label-normal"
                                            variant="filled"
                                            value='https://www.trustdock.net/backup/products/'
                                            style={{
                                                margin: 0,
                                                width: '100%'
                                            }}
                                            sx={{
                                                width: '100%',
                                                borderRadius: '4px',
                                                backgroundColor: 'extraLTheme.main',
                                                border: 'solid 1px #DBEBF8',
                                                m: 0,
                                                '& .MuiInputBase-root': {
                                                    backgroundColor: 'extraLTheme.main',
                                                },
                                                '& .MuiInputBase-input': {
                                                    fontSize: '0.9rem',
                                                    color: 'lightSecondary.main',
                                                    fontWeight: '500',
                                                    pt: 1.5,
                                                    pb: 1.5
                                                },
                                                '& .MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error):before': {
                                                    border: 'none'
                                                },
                                                '& .MuiInputBase-root:hover:before': {
                                                    border: 'none'
                                                },
                                                '&:hover': {
                                                    borderColor: '#DBEBF8'
                                                },
                                                '& .MuiInputBase-root:before': {
                                                    border: 'none'
                                                },
                                                '& .MuiInputBase-root:after': {
                                                    border: 'none'
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={6}>
                                        <Typography
                                            noWrap
                                            variant="body2"
                                            sx={{
                                                fontSize: '12px',
                                                fontWeight: '500',
                                                color: 'ltheme.main',
                                                width: "100%",
                                                mb: 0.5
                                            }}
                                        >Headquarters</Typography>
                                        <TextField
                                            hiddenLabel
                                            id="filled-hidden-label-normal"
                                            variant="filled"
                                            value='Tokyo, Japan'
                                            style={{
                                                margin: 0,
                                                width: '100%'
                                            }}
                                            sx={{
                                                width: '100%',
                                                borderRadius: '4px',
                                                backgroundColor: 'extraLTheme.main',
                                                border: 'solid 1px #DBEBF8',
                                                m: 0,
                                                '& .MuiInputBase-root': {
                                                    backgroundColor: 'extraLTheme.main',
                                                },
                                                '& .MuiInputBase-input': {
                                                    fontSize: '0.9rem',
                                                    color: 'lightSecondary.main',
                                                    fontWeight: '500',
                                                    pt: 1.5,
                                                    pb: 1.5
                                                },
                                                '& .MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error):before': {
                                                    border: 'none'
                                                },
                                                '& .MuiInputBase-root:hover:before': {
                                                    border: 'none'
                                                },
                                                '&:hover': {
                                                    borderColor: '#DBEBF8'
                                                },
                                                '& .MuiInputBase-root:before': {
                                                    border: 'none'
                                                },
                                                '& .MuiInputBase-root:after': {
                                                    border: 'none'
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={6}>
                                        <Typography
                                            noWrap
                                            variant="body2"
                                            sx={{
                                                fontSize: '12px',
                                                fontWeight: '500',
                                                color: 'ltheme.main',
                                                width: "100%",
                                                mb: 0.5
                                            }}
                                        >Industry</Typography>
                                        <div>
                                            <FormControl variant="filled"
                                                sx={{
                                                    m: 0,
                                                    width: '100%',
                                                    borderRadius: '4px',
                                                    '& .MuiSelect-select': {
                                                        minHeight: "20px",
                                                        padding: "11px 14px",
                                                        color: "lightSecondary.main",
                                                        fontWeight: "600",
                                                        fontSize: "0.9rem",
                                                        border: 'solid 1px #DBEBF8',
                                                        background: '#ECF6FF',
                                                        borderRadius: '4px',
                                                    },
                                                    '& .MuiSelect-icon': {
                                                        color: 'lightSecondary.main'
                                                    },
                                                    '& .MuiInputBase-root': {
                                                        '&:before': {
                                                            display: 'none'
                                                        },
                                                        '&:after': {
                                                            display: 'none'
                                                        }
                                                    },
                                                    '& .MuiChip-root': {
                                                        fontSize: '12px',
                                                        height: '24px',
                                                        color: 'lightSecondary.main',
                                                        '& .MuiSvgIcon-root': {
                                                            fontSize: '15px',
                                                            color: 'lightSecondary.main',
                                                        }
                                                    }
                                                }}
                                            >
                                                <Select
                                                    multiple
                                                    value={IndustryName}
                                                    onChange={handleChangeIndustry}
                                                    IconComponent={KeyboardArrowDownOutlinedIcon}
                                                    input={<FilledInput id="select-multiple-chip" />}
                                                    renderValue={(selected) => (
                                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                            {selected.map((value) => (
                                                                <Chip
                                                                    key={value}
                                                                    label={value}
                                                                    onDelete={() => onRemove(value)}
                                                                    deleteIcon={<CloseOutlined />}
                                                                />
                                                            ))}
                                                        </Box>
                                                    )}
                                                    MenuProps={MenuProps}
                                                >
                                                    {indNames.map((name) => (
                                                        <MenuItem
                                                            key={name}
                                                            value={name}
                                                            style={getStyles(name, IndustryName, theme)}
                                                        >
                                                            {name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={6}>
                                        <Typography
                                            noWrap
                                            variant="body2"
                                            sx={{
                                                fontSize: '12px',
                                                fontWeight: '500',
                                                color: 'ltheme.main',
                                                width: "100%",
                                                mb: 0.5
                                            }}
                                        >Use Cases</Typography>
                                        <div>
                                            <FormControl variant="filled"
                                                sx={{
                                                    m: 0,
                                                    width: '100%',
                                                    borderRadius: '4px',
                                                    '& .MuiSelect-select': {
                                                        minHeight: "20px",
                                                        padding: "11px 14px",
                                                        color: "lightSecondary.main",
                                                        fontWeight: "600",
                                                        fontSize: "0.9rem",
                                                        border: 'solid 1px #DBEBF8',
                                                        background: '#ECF6FF',
                                                        borderRadius: '4px',
                                                    },
                                                    '& .MuiSelect-icon': {
                                                        color: 'lightSecondary.main'
                                                    },
                                                    '& .MuiInputBase-root': {
                                                        '&:before': {
                                                            display: 'none'
                                                        },
                                                        '&:after': {
                                                            display: 'none'
                                                        }
                                                    },
                                                    '& .MuiChip-root': {
                                                        fontSize: '12px',
                                                        height: '24px',
                                                        color: 'lightSecondary.main',
                                                        '& .MuiSvgIcon-root': {
                                                            fontSize: '15px',
                                                            color: 'lightSecondary.main',
                                                        }
                                                    }
                                                }}
                                            >
                                                <Select
                                                    multiple
                                                    value={IndustryName}
                                                    onChange={handleChange}
                                                    IconComponent={KeyboardArrowDownOutlinedIcon}
                                                    input={<FilledInput id="select-multiple-chip" />}
                                                    renderValue={(selected) => (
                                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                            {selected.map((value) => (
                                                                <Chip
                                                                    key={value}
                                                                    label={value}
                                                                    onDelete={() => onRemove(value)}
                                                                    deleteIcon={<CloseOutlined />}
                                                                />
                                                            ))}
                                                        </Box>
                                                    )}
                                                    MenuProps={MenuProps}
                                                >
                                                    {indNames.map((name) => (
                                                        <MenuItem
                                                            key={name}
                                                            value={name}
                                                            style={getStyles(name, IndustryName, theme)}
                                                        >
                                                            {name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12}
                                        sx={{
                                            textAlign: 'center',
                                            mt: 4,
                                            mb: 4,
                                        }}
                                    >
                                        <Button variant="contained"
                                            sx={{
                                                textTransform: 'inherit',
                                                p: "10px 20px",
                                                boxShadow: 'none',
                                                fontWeight: '600',
                                                width: '200px',
                                            }}>
                                            Save
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    )
}

export default EditSolutionDetails