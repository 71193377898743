import React, { useState} from 'react'
import { useTheme } from '@emotion/react';
import {
    Box,
    Typography,
    TextField,
    Button,
    ListItemButton,
    List,
    Stack,
    ListItemAvatar,
    styled,
    Avatar,
    ListItemText,
    IconButton,
} from '@mui/material';
import { KeyboardArrowRightOutlined } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import { useFetchInboxRequirementBoardDetailsQuery } from '../../redux/api/Inbox/getInboxRequirementBoard';
import { useDispatch, useSelector } from 'react-redux';
import { getBoardId, getBoardName } from '../../redux/features/driveSlice';
import CancelIcon from '@mui/icons-material/Cancel';
import { hideLoader, showLoader } from '../../redux/features/loader';
const ShareModal = ({selectedBoardOpen , showToastMessage,folderName}) => {
    const theme = useTheme();
    const userId = localStorage.getItem('userId'); 

    const DocumentName = useSelector((state)=>state.drive.fileName);
    const dispatch = useDispatch();
    const Search = styled("div")(({ theme }) => ({
        position: "relative",
        borderRadius: theme.shape.borderRadius,
        backgroundColor: "#ECF6FF",
        "&:hover": {
          backgroundColor: "#ECF6FF",
        },
        marginRight: "0",
        marginLeft: 0,
        marginBottom: 5,
        width: "100%",
        display: 'flex', 
        alignItems: 'center',
      }));
      const SearchIconWrapper = styled("div")(({ theme }) => ({
        padding: theme.spacing(0, 2),
        height: "100%",
        position: "absolute",
        right: "0",
        pointerEvents: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }));
    
      const StyledInputBase = styled(InputBase)(({ theme }) => ({
        color: "#106EED",
        width: "100%",
        "& .MuiInputBase-input": {
          padding: theme.spacing(1.7, 0, 1.7, 1.7),
          paddingRight: `calc(1em + ${theme.spacing(4)})`,
          transition: theme.transitions.create("width"),
          width: "100%",
          fontSize: "12px",
          fontWeight: "500",
        },
      }));
   // search logic
   const [searchParams , setSearchParams] = useState("");
   const [searchValue , setSearchValue] = useState("");
    // handleSearching logic
    const handleInputChange = (event) => {
        setSearchParams(event.target.value);
      };

    const {data:boardList , isLoading:boardListLoading , error:boardListError , refetch:refetchBoard,} = useFetchInboxRequirementBoardDetailsQuery(
        {
          user_id:userId,
          searchParam:searchValue,
        })
        const selectedBoardId = (boardId , boardName)=>{
        localStorage.setItem("selectedBoardId" , boardId);
        localStorage.setItem("selectedBoardName" , boardName); 
        }
        const handleBoardSearch = async (event) => {
            if (event.key === 'Enter') {
              const inputValue = event.target.value.trim();
              if (!inputValue) {
                  showToastMessage('Please enter a search term', 'error');
                  return;
              }
              setSearchValue(inputValue);
              dispatch(showLoader())
            try {
              // Await refetch and handle search results
              await refetchBoard();
              if (boardListError) {
                  const errorMessage = boardList?.message || 'Failed to load search result';
                  showToastMessage(errorMessage, 'error');
              }
            } catch (boardListError) {
              showToastMessage('An error occurred during search', 'error');
            } finally {
              // Hide loader after refetch is complete
              dispatch(hideLoader());
            }
          }
          };
          const handleClearSearch = () => {
            setSearchParams("");
            setSearchValue(""); // Reset search value to empty
            refetchBoard(); // Refetch all data
          };
    return (
        <>
            <Box
                sx={{
                    mb: 3
                }}
            >
                <Typography variant='h5'
                     sx={{
                        fontSize: '1.3rem',
                        color: 'secondary.main',
                        fontWeight: '700',
                        maxWidth: '100%', // Set maximum width to the width of the modal
                        whiteSpace: 'nowrap', // Prevents text from wrapping onto a new line
                        overflow: 'hidden', // Hides any overflowed text
                        textOverflow: 'ellipsis', // Adds "..." at the end of the truncated text
                      }}
                    >
                    Share “{folderName || DocumentName}”
                </Typography>
            </Box>
            <Box sx={{ mb: 1.5 }}>
                <Search>
                    <StyledInputBase
                        placeholder="Search board"
                        inputProps={{ 'aria-label': 'search' }}
                        value={searchParams}
                        onChange={handleInputChange}
                        onKeyDown={handleBoardSearch}
                        autoFocus
                    />
                        {searchParams ? (
                            <IconButton onClick={handleClearSearch} sx={{ position: 'absolute', right: 0 }}>
                            <CancelIcon sx={{ color: "lightSecondary.main" }} />
                            </IconButton>
                        ) : (
                            <SearchIconWrapper>
                            <SearchIcon sx={{ color: "lightSecondary.main" }} />
                            </SearchIconWrapper>
                        )}
                </Search>
            </Box>
            <Typography variant="h6" color="textSecondary"
                sx={{
                    fontSize: "1.1rem",
                    fontWeight: "700",
                    color: "secondary.main",
                    marginBottom: "0rem"
                }}
            >
                Select Board
            </Typography>
            <List
                component="nav"
                sx={{
                    px: 0,
                    py: 0,
                    maxHeight: "300px",
                    overflowY: 'auto',
                    '&::-webkit-scrollbar': {
                      width: '6px',
                      borderRadius: '4px'
                    },
                    '&::-webkit-scrollbar-track': {
                      backgroundColor: 'transparent',
                      borderRadius: '4px'
                    },
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: '#9C9C9C',
                      borderRadius: '4px'
                    },
                    '& .MuiListItemButton-root': {
                        py: 1,
                    }
                }}
            >
            {boardList?.boards?.map((board, index) => (
              index === index && (
                <ListItemButton
                    key={board._id}
                    onClick={()=>{selectedBoardOpen() , selectedBoardId(board._id , board.board_name)}}
                    sx={{
                        borderRadius: "4px",
                        '&:hover': {
                            backgroundColor: "#ECF6FF"
                        },
                    }}
                >
                    <ListItemAvatar
                        sx={{
                            width: "56px"
                        }}
                    >
                        <Avatar
                            sx={{
                                bgcolor: "secondary.main",
                                px: 2.8,
                                fontSize: "12px",
                                fontWeight: "600"
                            }} variant="rounded">
                            {board.board_title}
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        sx={{
                            pl: '10px',
                            width: "60%"
                        }}>
                        <Typography noWrap
                            sx={{
                                fontSize: "1.1rem",
                                fontWeight: '700',
                                color: 'secondary.main',
                                lineHeight: '22px',
                                maxWidth: '220px',
                                [theme.breakpoints.down('sm')]: {
                                    maxWidth: '110px'
                                }
                            }}
                            variant="h6">
                              {board.board_name}
                        </Typography>
                    </ListItemText>
                    <IconButton aria-label="arrow"
                        sx={{
                            p: 0,
                            '&:hover': {
                                backgroundColor: 'transparent'
                            },
                        }}
                    >
                        <KeyboardArrowRightOutlined sx={{ color: "#2A589C" }} />
                    </IconButton>
                </ListItemButton>
                )
            ))}
            </List>
        </>
    )
}

export default ShareModal