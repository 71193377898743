import React, { useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import theme from './styles/muiTheme';
import { Outlet, useLocation } from "react-router-dom";
import {store} from './redux/store/store';
import { Provider, useDispatch ,useSelector} from 'react-redux';
// import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Layout from './commonComponents/layout';
import './assets/css/style.css'
import FrontLayout from './commonComponents/Frontend/FrontLayout';
import TitleMap from './commonComponents/titleMap';
import ThemeRoutes from './routes';
import Cookies from 'js-cookie';

function App() {
  const path = useLocation().pathname;
  const location = useLocation();
  // const queryClient = new QueryClient()
  const initialTitle = TitleMap[location.pathname] 
  const [title, setTitle] = useState('');
  // logic to set the title according to pathname/routes

  useEffect(() => {
    // Update the title whenever the pathname changes
    const newTitle = TitleMap[location.pathname] 
    setTitle(newTitle);
  }, [location.pathname]);

  // const dispatch = useDispatch();
  // const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  // useEffect(() => {
  //   const name = Cookies.get('name');
  //   const token = Cookies.get('token');
    
  //   if (name && token) {
  //     dispatch(setUser({ name, token }));
  //   }
  // }, [dispatch]);


  return (
  
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <ThemeRoutes />
        {location.pathname === '/ask-for-technology' || location.pathname === '/solution-listing' ? (
          <FrontLayout>
            <Outlet />
          </FrontLayout>
        ) : (
          <Layout title={title}>
            <Outlet title={title}/>
          </Layout>
        )}
       </ThemeProvider>
      <ToastContainer/>
      {/* <ReactQueryDevtools initialIsOpen={false} position='bottom-left' /> */}
    </Provider>
  );
}

export default App;
