import React, { useState } from 'react'
import {
    Box,
    Button,
    ListItemText,
    Typography,
    Card,
    CardContent,
    IconButton,
    Container,
    Pagination,
    Stack,
    PaginationItem
} from '@mui/material';
import CompareIcon from '@mui/icons-material/Compare';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import MainCard from '../../../commonComponents/MainCard';
import { getImages } from '../../../commonComponents/const';
import { useTheme } from '@emotion/react';
import EastIcon from '@mui/icons-material/East';
import KeyboardDoubleArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowLeftOutlined';
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';
const SolutionListing = () => {
    const theme = useTheme();
    const [page, setPage] = useState(1);
    const handleChange = (event, value) => {
        setPage(value);
    };
    return (
        <>
            <Box
                sx={{
                    padding: '40px 0',
                    backgroundColor: '#DBEBF8',
                    [theme.breakpoints.down('md')]: {
                        padding: '30px 0', 
                    }
                }}
            >
                <Container maxWidth="lg"
                    sx={{
                        padding: '0 20px'
                    }}
                >
                    <MainCard content={false}
                        sx={{
                            backgroundColor: "#ECF6FF",
                            border: "none",
                            padding: "15px",
                            '& .MuiPaper-root + .MuiPaper-root': {
                                mt: 3
                            }
                        }}
                    >
                        {/* Card */}
                        <Card
                            sx={{
                                boxShadow: 'none',
                                borderRadius: "10px"
                            }}
                        >
                            <CardContent
                                sx={{
                                    p: 0
                                }}
                                style={{
                                    paddingBottom: "0"
                                }}
                            >
                                <Box
                                    sx={{
                                        px: 2,
                                        pt: 4,
                                        pb: 6,
                                        [theme.breakpoints.down('md')]: {
                                            pt: 3,
                                            pb: 3,
                                        }
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between"
                                        }}
                                    >
                                        <Box>
                                            <img src={getImages('solution_icon1.jpg')} />
                                        </Box>
                                        <Box
                                            sx={{
                                                [theme.breakpoints.down('md')]: {
                                                    display: 'none'
                                                }
                                            }}
                                        >
                                            <Button variant="outlined"
                                                sx={{
                                                    textTransform: 'inherit',
                                                    p: "8px 25px",
                                                    mr: 2,
                                                    boxShadow: 'none',
                                                    borderWidth: "2px",
                                                    fontWeight: "600",
                                                    '&:hover': {
                                                        borderWidth: "2px",
                                                    }
                                                }}>
                                                Website
                                            </Button>
                                            <Button variant="contained"
                                                sx={{
                                                    textTransform: 'inherit',
                                                    p: "10px 25px",
                                                    boxShadow: 'none',
                                                    fontWeight: "600"
                                                }}
                                            >
                                                Contact Seller
                                            </Button>
                                        </Box>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            mt: 2,
                                            [theme.breakpoints.down('md')]: {
                                                display: 'block',
                                                mt: 1
                                            }
                                        }}
                                    >
                                        <Box>
                                            <Typography variant="h6" color="textSecondary"
                                                sx={{
                                                    fontSize: "1.2rem",
                                                    fontWeight: "700",
                                                    color: "secondary.main",
                                                    mb: 0.5
                                                }}
                                            >
                                                Medical Eye
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary"
                                                sx={{
                                                    fontSize: "0.9rem",
                                                    fontWeight: "500",
                                                    color: "secondary.main",
                                                }}
                                            >
                                                Medical Eye Co., Ltd. is a comprehensive medical solutions provider that aims to create a sustainable future of medical care.
                                            </Typography>
                                        </Box>
                                        <Box
                                            sx={{
                                                minWidth: '98px',
                                                [theme.breakpoints.down('md')]: {
                                                    mt: 1
                                                }
                                            }}
                                        >
                                            <IconButton aria-label="compare" size="medium"
                                                sx={{
                                                    backgroundColor: "extraLTheme.main",
                                                    borderRadius: "4px",
                                                    ml: 1,
                                                    color: "ltheme.main"
                                                }}
                                            >
                                                <CompareIcon fontSize="inherit" />
                                            </IconButton>
                                            <IconButton aria-label="bookmark" size="medium"
                                                sx={{
                                                    backgroundColor: "extraLTheme.main",
                                                    borderRadius: "4px",
                                                    ml: 1,
                                                    color: "ltheme.main"
                                                }}
                                            >
                                                <BookmarkIcon fontSize="inherit" />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                    <Box
                                        sx={{
                                            [theme.breakpoints.up('md')]: {
                                                display: 'none'
                                            },
                                            [theme.breakpoints.down('md')]: {
                                                mt: 2
                                            }
                                        }}
                                    >
                                        <Button variant="outlined"
                                            sx={{
                                                textTransform: 'inherit',
                                                p: "8px 25px",
                                                mr: 2,
                                                boxShadow: 'none',
                                                borderWidth: "2px",
                                                fontWeight: "600",
                                                '&:hover': {
                                                    borderWidth: "2px",
                                                },
                                                [theme.breakpoints.down('md')]: {
                                                    p: "8px 15px",
                                                    mr: 1.5,
                                                }
                                            }}>
                                            Website
                                        </Button>
                                        <Button variant="contained"
                                            sx={{
                                                textTransform: 'inherit',
                                                p: "10px 25px",
                                                boxShadow: 'none',
                                                fontWeight: "600",
                                                [theme.breakpoints.down('md')]: {
                                                    p: "10px 15px",
                                                    mr: 2,
                                                }
                                            }}
                                        >
                                            Contact Seller
                                        </Button>
                                    </Box>
                                </Box>
                                <Box
                                    sx={{
                                        py: 2,
                                        px: 5,
                                        backgroundColor: "#DBEBF833",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        borderTop: "solid 1px rgb(16 110 237 / 30%)",
                                        [theme.breakpoints.down('md')]: {
                                            flexWrap: 'wrap',
                                            px: 2
                                        }
                                    }}
                                >
                                    <Box display={"flex"}
                                        sx={{
                                            px: "0",
                                            alignItems: "center"
                                        }}
                                    >
                                        <Box>
                                            <img style={{ display: "block" }} src={getImages('headquarters.png')} />
                                        </Box>
                                        <ListItemText
                                            sx={{
                                                pl: '10px',
                                                width: "60%"
                                            }}
                                        >
                                            <Typography noWrap
                                                sx={{
                                                    fontSize: "12px",
                                                    fontWeight: '700',
                                                    color: 'secondary.main',
                                                    lineHeight: '15px',
                                                }}
                                                variant="h6">Headquarters
                                            </Typography>
                                            <Typography
                                                noWrap
                                                variant="body2"
                                                sx={{
                                                    fontSize: '12px',
                                                    fontWeight: '500',
                                                    color: 'lightSecondary.main',
                                                }}
                                            >London, UK</Typography>
                                        </ListItemText>
                                    </Box>
                                    <Box display={"flex"}
                                        sx={{
                                            px: "0",
                                            alignItems: "center"
                                        }}
                                    >
                                        <Box>
                                            <img style={{ display: "block" }} src={getImages('industries.png')} />
                                        </Box>
                                        <ListItemText
                                            sx={{
                                                pl: '10px',
                                                width: "60%"
                                            }}
                                        >
                                            <Typography noWrap
                                                sx={{
                                                    fontSize: "12px",
                                                    fontWeight: '700',
                                                    color: 'secondary.main',
                                                    lineHeight: '15px',
                                                }}
                                                variant="h6">Industries
                                            </Typography>
                                            <Typography
                                                noWrap
                                                variant="body2"
                                                sx={{
                                                    fontSize: '12px',
                                                    fontWeight: '500',
                                                    color: 'lightSecondary.main',
                                                }}
                                            >Finance, Retial</Typography>
                                        </ListItemText>
                                    </Box>
                                    <Box display={"flex"}
                                        sx={{
                                            px: "0",
                                            alignItems: "center"
                                        }}
                                    >
                                        <Box>
                                            <img style={{ display: "block" }} src={getImages('use-cases.png')} />
                                        </Box>
                                        <ListItemText
                                            sx={{
                                                pl: '10px',
                                                width: "60%"
                                            }}
                                        >
                                            <Typography noWrap
                                                sx={{
                                                    fontSize: "12px",
                                                    fontWeight: '700',
                                                    color: 'secondary.main',
                                                    lineHeight: '15px',
                                                }}
                                                variant="h6">Use Cases
                                            </Typography>
                                            <Typography
                                                noWrap
                                                variant="body2"
                                                sx={{
                                                    fontSize: '12px',
                                                    fontWeight: '500',
                                                    color: 'lightSecondary.main',
                                                }}
                                            >Smart Contract, Fruad Dection FinTech</Typography>
                                        </ListItemText>
                                    </Box>
                                </Box>
                            </CardContent>
                        </Card>

                        {/* Card */}
                        <Card
                            sx={{
                                boxShadow: 'none',
                                borderRadius: "10px"
                            }}
                        >
                            <CardContent
                                sx={{
                                    p: 0
                                }}
                                style={{
                                    paddingBottom: "0"
                                }}
                            >
                                <Box
                                    sx={{
                                        px: 2,
                                        pt: 4,
                                        pb: 6,
                                        [theme.breakpoints.down('md')]: {
                                            pt: 3,
                                            pb: 3,
                                        }
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between"
                                        }}
                                    >
                                        <Box>
                                            <img src={getImages('solution_icon1.jpg')} />
                                        </Box>
                                        <Box
                                            sx={{
                                                [theme.breakpoints.down('md')]: {
                                                    display: 'none'
                                                }
                                            }}
                                        >
                                            <Button variant="outlined"
                                                sx={{
                                                    textTransform: 'inherit',
                                                    p: "8px 25px",
                                                    mr: 2,
                                                    boxShadow: 'none',
                                                    borderWidth: "2px",
                                                    fontWeight: "600",
                                                    '&:hover': {
                                                        borderWidth: "2px",
                                                    }
                                                }}>
                                                Website
                                            </Button>
                                            <Button variant="contained"
                                                sx={{
                                                    textTransform: 'inherit',
                                                    p: "10px 25px",
                                                    boxShadow: 'none',
                                                    fontWeight: "600"
                                                }}
                                            >
                                                Contact Seller
                                            </Button>
                                        </Box>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            mt: 2,
                                            [theme.breakpoints.down('md')]: {
                                                display: 'block',
                                                mt: 1
                                            }
                                        }}
                                    >
                                        <Box>
                                            <Typography variant="h6" color="textSecondary"
                                                sx={{
                                                    fontSize: "1.2rem",
                                                    fontWeight: "700",
                                                    color: "secondary.main",
                                                    mb: 0.5
                                                }}
                                            >
                                                NovaSight
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary"
                                                sx={{
                                                    fontSize: "0.9rem",
                                                    fontWeight: "500",
                                                    color: "secondary.main",
                                                }}
                                            >
                                                NovaSight is specializing in vision care solutions based on AI and eye-tracking technology
                                            </Typography>
                                        </Box>
                                        <Box
                                            sx={{
                                                minWidth: '98px',
                                                [theme.breakpoints.down('md')]: {
                                                    mt: 1
                                                }
                                            }}
                                        >
                                            <IconButton aria-label="compare" size="medium"
                                                sx={{
                                                    backgroundColor: "extraLTheme.main",
                                                    borderRadius: "4px",
                                                    ml: 1,
                                                    color: "ltheme.main"
                                                }}
                                            >
                                                <CompareIcon fontSize="inherit" />
                                            </IconButton>
                                            <IconButton aria-label="bookmark" size="medium"
                                                sx={{
                                                    backgroundColor: "primary.main",
                                                    borderRadius: "4px",
                                                    ml: 1,
                                                    color: "#fff"
                                                }}
                                            >
                                                <BookmarkIcon fontSize="inherit" />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                    <Box
                                        sx={{
                                            [theme.breakpoints.up('md')]: {
                                                display: 'none'
                                            },
                                            [theme.breakpoints.down('md')]: {
                                                mt: 2
                                            }
                                        }}
                                    >
                                        <Button variant="outlined"
                                            sx={{
                                                textTransform: 'inherit',
                                                p: "8px 25px",
                                                mr: 2,
                                                boxShadow: 'none',
                                                borderWidth: "2px",
                                                fontWeight: "600",
                                                '&:hover': {
                                                    borderWidth: "2px",
                                                },
                                                [theme.breakpoints.down('md')]: {
                                                    p: "8px 15px",
                                                    mr: 1.5,
                                                }
                                            }}>
                                            Website
                                        </Button>
                                        <Button variant="contained"
                                            sx={{
                                                textTransform: 'inherit',
                                                p: "10px 25px",
                                                boxShadow: 'none',
                                                fontWeight: "600",
                                                [theme.breakpoints.down('md')]: {
                                                    p: "10px 15px",
                                                    mr: 2,
                                                }
                                            }}
                                        >
                                            Contact Seller
                                        </Button>
                                    </Box>
                                </Box>
                                <Box
                                    sx={{
                                        py: 2,
                                        px: 5,
                                        backgroundColor: "#DBEBF833",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        borderTop: "solid 1px rgb(16 110 237 / 30%)",
                                        [theme.breakpoints.down('md')]: {
                                            flexWrap: 'wrap',
                                            px: 2
                                        }
                                    }}
                                >
                                    <Box display={"flex"}
                                        sx={{
                                            px: "0",
                                            alignItems: "center"
                                        }}
                                    >
                                        <Box>
                                            <img style={{ display: "block" }} src={getImages('headquarters.png')} />
                                        </Box>
                                        <ListItemText
                                            sx={{
                                                pl: '10px',
                                                width: "60%"
                                            }}
                                        >
                                            <Typography noWrap
                                                sx={{
                                                    fontSize: "12px",
                                                    fontWeight: '700',
                                                    color: 'secondary.main',
                                                    lineHeight: '15px',
                                                }}
                                                variant="h6">Headquarters
                                            </Typography>
                                            <Typography
                                                noWrap
                                                variant="body2"
                                                sx={{
                                                    fontSize: '12px',
                                                    fontWeight: '500',
                                                    color: 'lightSecondary.main',
                                                }}
                                            >London, UK</Typography>
                                        </ListItemText>
                                    </Box>
                                    <Box display={"flex"}
                                        sx={{
                                            px: "0",
                                            alignItems: "center"
                                        }}
                                    >
                                        <Box>
                                            <img style={{ display: "block" }} src={getImages('industries.png')} />
                                        </Box>
                                        <ListItemText
                                            sx={{
                                                pl: '10px',
                                                width: "60%"
                                            }}
                                        >
                                            <Typography noWrap
                                                sx={{
                                                    fontSize: "12px",
                                                    fontWeight: '700',
                                                    color: 'secondary.main',
                                                    lineHeight: '15px',
                                                }}
                                                variant="h6">Industries
                                            </Typography>
                                            <Typography
                                                noWrap
                                                variant="body2"
                                                sx={{
                                                    fontSize: '12px',
                                                    fontWeight: '500',
                                                    color: 'lightSecondary.main',
                                                }}
                                            >Finance, Retial</Typography>
                                        </ListItemText>
                                    </Box>
                                    <Box display={"flex"}
                                        sx={{
                                            px: "0",
                                            alignItems: "center"
                                        }}
                                    >
                                        <Box>
                                            <img style={{ display: "block" }} src={getImages('use-cases.png')} />
                                        </Box>
                                        <ListItemText
                                            sx={{
                                                pl: '10px',
                                                width: "60%"
                                            }}
                                        >
                                            <Typography noWrap
                                                sx={{
                                                    fontSize: "12px",
                                                    fontWeight: '700',
                                                    color: 'secondary.main',
                                                    lineHeight: '15px',
                                                }}
                                                variant="h6">Use Cases
                                            </Typography>
                                            <Typography
                                                noWrap
                                                variant="body2"
                                                sx={{
                                                    fontSize: '12px',
                                                    fontWeight: '500',
                                                    color: 'lightSecondary.main',
                                                }}
                                            >Smart Contract, Fruad Dection FinTech</Typography>
                                        </ListItemText>
                                    </Box>
                                </Box>
                            </CardContent>
                        </Card>

                        {/* Card */}
                        <Card
                            sx={{
                                boxShadow: 'none',
                                borderRadius: "10px"
                            }}
                        >
                            <CardContent
                                sx={{
                                    p: 0
                                }}
                                style={{
                                    paddingBottom: "0"
                                }}
                            >
                                <Box
                                    sx={{
                                        px: 2,
                                        pt: 4,
                                        pb: 6,
                                        [theme.breakpoints.down('md')]: {
                                            pt: 3,
                                            pb: 3,
                                        }
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between"
                                        }}
                                    >
                                        <Box>
                                            <img src={getImages('solution_icon1.jpg')} />
                                        </Box>
                                        <Box
                                            sx={{
                                                [theme.breakpoints.down('md')]: {
                                                    display: 'none'
                                                }
                                            }}
                                        >
                                            <Button variant="outlined"
                                                sx={{
                                                    textTransform: 'inherit',
                                                    p: "8px 25px",
                                                    mr: 2,
                                                    boxShadow: 'none',
                                                    borderWidth: "2px",
                                                    fontWeight: "600",
                                                    '&:hover': {
                                                        borderWidth: "2px",
                                                    }
                                                }}>
                                                Website
                                            </Button>
                                            <Button variant="contained"
                                                sx={{
                                                    textTransform: 'inherit',
                                                    p: "10px 25px",
                                                    boxShadow: 'none',
                                                    fontWeight: "600"
                                                }}
                                            >
                                                Contact Seller
                                            </Button>
                                        </Box>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            mt: 2,
                                            [theme.breakpoints.down('md')]: {
                                                display: 'block',
                                                mt: 1
                                            }
                                        }}
                                    >
                                        <Box>
                                            <Typography variant="h6" color="textSecondary"
                                                sx={{
                                                    fontSize: "1.2rem",
                                                    fontWeight: "700",
                                                    color: "secondary.main",
                                                    mb: 0.5
                                                }}
                                            >
                                                Pr3vent
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary"
                                                sx={{
                                                    fontSize: "0.9rem",
                                                    fontWeight: "500",
                                                    color: "secondary.main",
                                                }}
                                            >
                                                Pr3vent is an eye care diagnostic AI startup.
                                            </Typography>
                                        </Box>
                                        <Box
                                            sx={{
                                                minWidth: '98px',
                                                [theme.breakpoints.down('md')]: {
                                                    mt: 1
                                                }
                                            }}
                                        >
                                            <IconButton aria-label="compare" size="medium"
                                                sx={{
                                                    backgroundColor: "primary.main",
                                                    borderRadius: "4px",
                                                    ml: 1,
                                                    color: "#fff",
                                                    [theme.breakpoints.down('md')]: {
                                                        ml: 0
                                                    }
                                                }}
                                            >
                                                <CompareIcon fontSize="inherit" />
                                            </IconButton>
                                            <IconButton aria-label="bookmark" size="medium"
                                                sx={{
                                                    backgroundColor: "extraLTheme.main",
                                                    borderRadius: "4px",
                                                    ml: 1,
                                                    color: "ltheme.main"
                                                }}
                                            >
                                                <BookmarkIcon fontSize="inherit" />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                    <Box
                                        sx={{
                                            [theme.breakpoints.up('md')]: {
                                                display: 'none'
                                            },
                                            [theme.breakpoints.down('md')]: {
                                                mt: 2
                                            }
                                        }}
                                    >
                                        <Button variant="outlined"
                                            sx={{
                                                textTransform: 'inherit',
                                                p: "8px 25px",
                                                mr: 2,
                                                boxShadow: 'none',
                                                borderWidth: "2px",
                                                fontWeight: "600",
                                                '&:hover': {
                                                    borderWidth: "2px",
                                                },
                                                [theme.breakpoints.down('md')]: {
                                                    p: "8px 15px",
                                                    mr: 1.5,
                                                }
                                            }}>
                                            Website
                                        </Button>
                                        <Button variant="contained"
                                            sx={{
                                                textTransform: 'inherit',
                                                p: "10px 25px",
                                                boxShadow: 'none',
                                                fontWeight: "600",
                                                [theme.breakpoints.down('md')]: {
                                                    p: "10px 15px",
                                                    mr: 2,
                                                }
                                            }}
                                        >
                                            Contact Seller
                                        </Button>
                                    </Box>
                                </Box>
                                <Box
                                    sx={{
                                        py: 2,
                                        px: 5,
                                        backgroundColor: "#DBEBF833",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        borderTop: "solid 1px rgb(16 110 237 / 30%)",
                                        [theme.breakpoints.down('md')]: {
                                            flexWrap: 'wrap',
                                            px: 2
                                        }
                                    }}
                                >
                                    <Box display={"flex"}
                                        sx={{
                                            px: "0",
                                            alignItems: "center"
                                        }}
                                    >
                                        <Box>
                                            <img style={{ display: "block" }} src={getImages('headquarters.png')} />
                                        </Box>
                                        <ListItemText
                                            sx={{
                                                pl: '10px',
                                                width: "60%"
                                            }}
                                        >
                                            <Typography noWrap
                                                sx={{
                                                    fontSize: "12px",
                                                    fontWeight: '700',
                                                    color: 'secondary.main',
                                                    lineHeight: '15px',
                                                }}
                                                variant="h6">Headquarters
                                            </Typography>
                                            <Typography
                                                noWrap
                                                variant="body2"
                                                sx={{
                                                    fontSize: '12px',
                                                    fontWeight: '500',
                                                    color: 'lightSecondary.main',
                                                }}
                                            >London, UK</Typography>
                                        </ListItemText>
                                    </Box>
                                    <Box display={"flex"}
                                        sx={{
                                            px: "0",
                                            alignItems: "center"
                                        }}
                                    >
                                        <Box>
                                            <img style={{ display: "block" }} src={getImages('industries.png')} />
                                        </Box>
                                        <ListItemText
                                            sx={{
                                                pl: '10px',
                                                width: "60%"
                                            }}
                                        >
                                            <Typography noWrap
                                                sx={{
                                                    fontSize: "12px",
                                                    fontWeight: '700',
                                                    color: 'secondary.main',
                                                    lineHeight: '15px',
                                                }}
                                                variant="h6">Industries
                                            </Typography>
                                            <Typography
                                                noWrap
                                                variant="body2"
                                                sx={{
                                                    fontSize: '12px',
                                                    fontWeight: '500',
                                                    color: 'lightSecondary.main',
                                                }}
                                            >Finance, Retial</Typography>
                                        </ListItemText>
                                    </Box>
                                    <Box display={"flex"}
                                        sx={{
                                            px: "0",
                                            alignItems: "center"
                                        }}
                                    >
                                        <Box>
                                            <img style={{ display: "block" }} src={getImages('use-cases.png')} />
                                        </Box>
                                        <ListItemText
                                            sx={{
                                                pl: '10px',
                                                width: "60%"
                                            }}
                                        >
                                            <Typography noWrap
                                                sx={{
                                                    fontSize: "12px",
                                                    fontWeight: '700',
                                                    color: 'secondary.main',
                                                    lineHeight: '15px',
                                                }}
                                                variant="h6">Use Cases
                                            </Typography>
                                            <Typography
                                                noWrap
                                                variant="body2"
                                                sx={{
                                                    fontSize: '12px',
                                                    fontWeight: '500',
                                                    color: 'lightSecondary.main',
                                                }}
                                            >Smart Contract, Fruad Dection FinTech</Typography>
                                        </ListItemText>
                                    </Box>
                                </Box>
                            </CardContent>
                        </Card>

                        {/* Card */}
                        <Card
                            sx={{
                                boxShadow: 'none',
                                borderRadius: "10px"
                            }}
                        >
                            <CardContent
                                sx={{
                                    p: 0
                                }}
                                style={{
                                    paddingBottom: "0"
                                }}
                            >
                                <Box
                                    sx={{
                                        px: 2,
                                        pt: 4,
                                        pb: 6,
                                        [theme.breakpoints.down('md')]: {
                                            pt: 3,
                                            pb: 3,
                                        }
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between"
                                        }}
                                    >
                                        <Box>
                                            <img src={getImages('solution_icon1.jpg')} />
                                        </Box>
                                        <Box
                                            sx={{
                                                [theme.breakpoints.down('md')]: {
                                                    display: 'none'
                                                }
                                            }}
                                        >
                                            <Button variant="outlined"
                                                sx={{
                                                    textTransform: 'inherit',
                                                    p: "8px 25px",
                                                    mr: 2,
                                                    boxShadow: 'none',
                                                    borderWidth: "2px",
                                                    fontWeight: "600",
                                                    '&:hover': {
                                                        borderWidth: "2px",
                                                    }
                                                }}>
                                                Website
                                            </Button>
                                            <Button variant="contained"
                                                sx={{
                                                    textTransform: 'inherit',
                                                    p: "10px 25px",
                                                    boxShadow: 'none',
                                                    fontWeight: "600"
                                                }}
                                            >
                                                Contact Seller
                                            </Button>
                                        </Box>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            mt: 2,
                                            [theme.breakpoints.down('md')]: {
                                                display: 'block',
                                                mt: 1
                                            }
                                        }}
                                    >
                                        <Box>
                                            <Typography variant="h6" color="textSecondary"
                                                sx={{
                                                    fontSize: "1.2rem",
                                                    fontWeight: "700",
                                                    color: "secondary.main",
                                                    mb: 0.5
                                                }}
                                            >
                                                Custom Surgical
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary"
                                                sx={{
                                                    fontSize: "0.9rem",
                                                    fontWeight: "500",
                                                    color: "secondary.main",
                                                }}
                                            >
                                                Custom Surgical is transforming eyecare through its AI-powered imaging-data management and digitization tools.
                                            </Typography>
                                        </Box>
                                        <Box
                                            sx={{
                                                minWidth: '98px',
                                                [theme.breakpoints.down('md')]: {
                                                    mt: 1
                                                }
                                            }}
                                        >
                                            <IconButton aria-label="compare" size="medium"
                                                sx={{
                                                    backgroundColor: "primary.main",
                                                    borderRadius: "4px",
                                                    ml: 1,
                                                    color: "#fff",
                                                    [theme.breakpoints.down('md')]: {
                                                        ml: 0
                                                    }
                                                }}
                                            >
                                                <CompareIcon fontSize="inherit" />
                                            </IconButton>
                                            <IconButton aria-label="bookmark" size="medium"
                                                sx={{
                                                    backgroundColor: "extraLTheme.main",
                                                    borderRadius: "4px",
                                                    ml: 1,
                                                    color: "ltheme.main"
                                                }}
                                            >
                                                <BookmarkIcon fontSize="inherit" />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                    <Box
                                        sx={{
                                            [theme.breakpoints.up('md')]: {
                                                display: 'none'
                                            },
                                            [theme.breakpoints.down('md')]: {
                                                mt: 2
                                            }
                                        }}
                                    >
                                        <Button variant="outlined"
                                            sx={{
                                                textTransform: 'inherit',
                                                p: "8px 25px",
                                                mr: 2,
                                                boxShadow: 'none',
                                                borderWidth: "2px",
                                                fontWeight: "600",
                                                '&:hover': {
                                                    borderWidth: "2px",
                                                },
                                                [theme.breakpoints.down('md')]: {
                                                    p: "8px 15px",
                                                    mr: 1.5,
                                                }
                                            }}>
                                            Website
                                        </Button>
                                        <Button variant="contained"
                                            sx={{
                                                textTransform: 'inherit',
                                                p: "10px 25px",
                                                boxShadow: 'none',
                                                fontWeight: "600",
                                                [theme.breakpoints.down('md')]: {
                                                    p: "10px 15px",
                                                    mr: 2,
                                                }
                                            }}
                                        >
                                            Contact Seller
                                        </Button>
                                    </Box>
                                </Box>
                                <Box
                                    sx={{
                                        py: 2,
                                        px: 5,
                                        backgroundColor: "#DBEBF833",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        borderTop: "solid 1px rgb(16 110 237 / 30%)",
                                        [theme.breakpoints.down('md')]: {
                                            flexWrap: 'wrap',
                                            px: 2
                                        }
                                    }}
                                >
                                    <Box display={"flex"}
                                        sx={{
                                            px: "0",
                                            alignItems: "center"
                                        }}
                                    >
                                        <Box>
                                            <img style={{ display: "block" }} src={getImages('headquarters.png')} />
                                        </Box>
                                        <ListItemText
                                            sx={{
                                                pl: '10px',
                                                width: "60%"
                                            }}
                                        >
                                            <Typography noWrap
                                                sx={{
                                                    fontSize: "12px",
                                                    fontWeight: '700',
                                                    color: 'secondary.main',
                                                    lineHeight: '15px',
                                                }}
                                                variant="h6">Headquarters
                                            </Typography>
                                            <Typography
                                                noWrap
                                                variant="body2"
                                                sx={{
                                                    fontSize: '12px',
                                                    fontWeight: '500',
                                                    color: 'lightSecondary.main',
                                                }}
                                            >London, UK</Typography>
                                        </ListItemText>
                                    </Box>
                                    <Box display={"flex"}
                                        sx={{
                                            px: "0",
                                            alignItems: "center"
                                        }}
                                    >
                                        <Box>
                                            <img style={{ display: "block" }} src={getImages('industries.png')} />
                                        </Box>
                                        <ListItemText
                                            sx={{
                                                pl: '10px',
                                                width: "60%"
                                            }}
                                        >
                                            <Typography noWrap
                                                sx={{
                                                    fontSize: "12px",
                                                    fontWeight: '700',
                                                    color: 'secondary.main',
                                                    lineHeight: '15px',
                                                }}
                                                variant="h6">Industries
                                            </Typography>
                                            <Typography
                                                noWrap
                                                variant="body2"
                                                sx={{
                                                    fontSize: '12px',
                                                    fontWeight: '500',
                                                    color: 'lightSecondary.main',
                                                }}
                                            >Finance, Retial</Typography>
                                        </ListItemText>
                                    </Box>
                                    <Box display={"flex"}
                                        sx={{
                                            px: "0",
                                            alignItems: "center"
                                        }}
                                    >
                                        <Box>
                                            <img style={{ display: "block" }} src={getImages('use-cases.png')} />
                                        </Box>
                                        <ListItemText
                                            sx={{
                                                pl: '10px',
                                                width: "60%"
                                            }}
                                        >
                                            <Typography noWrap
                                                sx={{
                                                    fontSize: "12px",
                                                    fontWeight: '700',
                                                    color: 'secondary.main',
                                                    lineHeight: '15px',
                                                }}
                                                variant="h6">Use Cases
                                            </Typography>
                                            <Typography
                                                noWrap
                                                variant="body2"
                                                sx={{
                                                    fontSize: '12px',
                                                    fontWeight: '500',
                                                    color: 'lightSecondary.main',
                                                }}
                                            >Smart Contract, Fruad Dection FinTech</Typography>
                                        </ListItemText>
                                    </Box>
                                </Box>
                            </CardContent>
                        </Card>

                        {/* Card */}
                        <Card
                            sx={{
                                boxShadow: 'none',
                                borderRadius: "10px"
                            }}
                        >
                            <CardContent
                                sx={{
                                    p: 0
                                }}
                                style={{
                                    paddingBottom: "0"
                                }}
                            >
                                <Box
                                    sx={{
                                        px: 2,
                                        pt: 4,
                                        pb: 6,
                                        [theme.breakpoints.down('md')]: {
                                            pt: 3,
                                            pb: 3,
                                        }
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between"
                                        }}
                                    >
                                        <Box>
                                            <img src={getImages('solution_icon1.jpg')} />
                                        </Box>
                                        <Box
                                            sx={{
                                                [theme.breakpoints.down('md')]: {
                                                    display: 'none'
                                                }
                                            }}
                                        >
                                            <Button variant="outlined"
                                                sx={{
                                                    textTransform: 'inherit',
                                                    p: "8px 25px",
                                                    mr: 2,
                                                    boxShadow: 'none',
                                                    borderWidth: "2px",
                                                    fontWeight: "600",
                                                    '&:hover': {
                                                        borderWidth: "2px",
                                                    }
                                                }}>
                                                Website
                                            </Button>
                                            <Button variant="contained"
                                                sx={{
                                                    textTransform: 'inherit',
                                                    p: "10px 25px",
                                                    boxShadow: 'none',
                                                    fontWeight: "600"
                                                }}
                                            >
                                                Contact Seller
                                            </Button>
                                        </Box>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            mt: 2,
                                            [theme.breakpoints.down('md')]: {
                                                display: 'block',
                                                mt: 1
                                            }
                                        }}
                                    >
                                        <Box>
                                            <Typography variant="h6" color="textSecondary"
                                                sx={{
                                                    fontSize: "1.2rem",
                                                    fontWeight: "700",
                                                    color: "secondary.main",
                                                    mb: 0.5
                                                }}
                                            >
                                                Eyeneed
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary"
                                                sx={{
                                                    fontSize: "0.9rem",
                                                    fontWeight: "500",
                                                    color: "secondary.main",
                                                }}
                                            >
                                                Eyeneed is an AI-based application software developed by ophthalmologists for eye care professionals.
                                            </Typography>
                                        </Box>
                                        <Box
                                            sx={{
                                                minWidth: '98px',
                                                [theme.breakpoints.down('md')]: {
                                                    mt: 1
                                                }
                                            }}
                                        >
                                            <IconButton aria-label="compare" size="medium"
                                                sx={{
                                                    backgroundColor: "primary.main",
                                                    borderRadius: "4px",
                                                    ml: 1,
                                                    color: "#fff",
                                                    [theme.breakpoints.down('md')]: {
                                                        ml: 0
                                                    }
                                                }}
                                            >
                                                <CompareIcon fontSize="inherit" />
                                            </IconButton>
                                            <IconButton aria-label="bookmark" size="medium"
                                                sx={{
                                                    backgroundColor: "extraLTheme.main",
                                                    borderRadius: "4px",
                                                    ml: 1,
                                                    color: "ltheme.main"
                                                }}
                                            >
                                                <BookmarkIcon fontSize="inherit" />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                    <Box
                                        sx={{
                                            [theme.breakpoints.up('md')]: {
                                                display: 'none'
                                            },
                                            [theme.breakpoints.down('md')]: {
                                                mt: 2
                                            }
                                        }}
                                    >
                                        <Button variant="outlined"
                                            sx={{
                                                textTransform: 'inherit',
                                                p: "8px 25px",
                                                mr: 2,
                                                boxShadow: 'none',
                                                borderWidth: "2px",
                                                fontWeight: "600",
                                                '&:hover': {
                                                    borderWidth: "2px",
                                                },
                                                [theme.breakpoints.down('md')]: {
                                                    p: "8px 15px",
                                                    mr: 1.5,
                                                }
                                            }}>
                                            Website
                                        </Button>
                                        <Button variant="contained"
                                            sx={{
                                                textTransform: 'inherit',
                                                p: "10px 25px",
                                                boxShadow: 'none',
                                                fontWeight: "600",
                                                [theme.breakpoints.down('md')]: {
                                                    p: "10px 15px",
                                                    mr: 2,
                                                }
                                            }}
                                        >
                                            Contact Seller
                                        </Button>
                                    </Box>
                                </Box>
                                <Box
                                    sx={{
                                        py: 2,
                                        px: 5,
                                        backgroundColor: "#DBEBF833",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        borderTop: "solid 1px rgb(16 110 237 / 30%)",
                                        [theme.breakpoints.down('md')]: {
                                            flexWrap: 'wrap',
                                            px: 2
                                        }
                                    }}
                                >
                                    <Box display={"flex"}
                                        sx={{
                                            px: "0",
                                            alignItems: "center"
                                        }}
                                    >
                                        <Box>
                                            <img style={{ display: "block" }} src={getImages('headquarters.png')} />
                                        </Box>
                                        <ListItemText
                                            sx={{
                                                pl: '10px',
                                                width: "60%"
                                            }}
                                        >
                                            <Typography noWrap
                                                sx={{
                                                    fontSize: "12px",
                                                    fontWeight: '700',
                                                    color: 'secondary.main',
                                                    lineHeight: '15px',
                                                }}
                                                variant="h6">Headquarters
                                            </Typography>
                                            <Typography
                                                noWrap
                                                variant="body2"
                                                sx={{
                                                    fontSize: '12px',
                                                    fontWeight: '500',
                                                    color: 'lightSecondary.main',
                                                }}
                                            >London, UK</Typography>
                                        </ListItemText>
                                    </Box>
                                    <Box display={"flex"}
                                        sx={{
                                            px: "0",
                                            alignItems: "center"
                                        }}
                                    >
                                        <Box>
                                            <img style={{ display: "block" }} src={getImages('industries.png')} />
                                        </Box>
                                        <ListItemText
                                            sx={{
                                                pl: '10px',
                                                width: "60%"
                                            }}
                                        >
                                            <Typography noWrap
                                                sx={{
                                                    fontSize: "12px",
                                                    fontWeight: '700',
                                                    color: 'secondary.main',
                                                    lineHeight: '15px',
                                                }}
                                                variant="h6">Industries
                                            </Typography>
                                            <Typography
                                                noWrap
                                                variant="body2"
                                                sx={{
                                                    fontSize: '12px',
                                                    fontWeight: '500',
                                                    color: 'lightSecondary.main',
                                                }}
                                            >Finance, Retial</Typography>
                                        </ListItemText>
                                    </Box>
                                    <Box display={"flex"}
                                        sx={{
                                            px: "0",
                                            alignItems: "center"
                                        }}
                                    >
                                        <Box>
                                            <img style={{ display: "block" }} src={getImages('use-cases.png')} />
                                        </Box>
                                        <ListItemText
                                            sx={{
                                                pl: '10px',
                                                width: "60%"
                                            }}
                                        >
                                            <Typography noWrap
                                                sx={{
                                                    fontSize: "12px",
                                                    fontWeight: '700',
                                                    color: 'secondary.main',
                                                    lineHeight: '15px',
                                                }}
                                                variant="h6">Use Cases
                                            </Typography>
                                            <Typography
                                                noWrap
                                                variant="body2"
                                                sx={{
                                                    fontSize: '12px',
                                                    fontWeight: '500',
                                                    color: 'lightSecondary.main',
                                                }}
                                            >Smart Contract, Fruad Dection FinTech</Typography>
                                        </ListItemText>
                                    </Box>
                                </Box>
                            </CardContent>
                        </Card>

                        <Stack
                            direction="row"
                            sx={{
                                justifyContent: 'center',
                                my: 2.5
                            }}
                        >
                            <Pagination count={10} page={page} onChange={handleChange}
                                sx={{
                                    '& .MuiButtonBase-root': {
                                        color: 'secondary.main',
                                        fontSize: '1.2rem',
                                        fontWeight: '700',
                                        minWidth: '45px',
                                        height: '45px',
                                        '&.Mui-selected': {
                                            backgroundColor: 'secondary.main',
                                            color: '#fff',
                                            borderRadius: '6px'
                                        },
                                        [theme.breakpoints.down('md')]: {
                                            fontSize: '1rem',
                                            minWidth: '30px',
                                            height: '30px',
                                        }
                                    }
                                }}
                                renderItem={(item) => (
                                    <PaginationItem
                                        slots={{ previous: KeyboardDoubleArrowLeftOutlinedIcon, next: KeyboardDoubleArrowRightOutlinedIcon }}
                                        {...item}
                                    />
                                )}

                            />
                        </Stack>
                    </MainCard>
                    <Box
                        sx={{
                            mt: 12,
                            mb: 2,
                            [theme.breakpoints.down('md')]: {
                                mt: 5,
                                mb: 2,
                            }
                        }}
                    >

                        <Card
                            sx={{
                                boxShadow: 'none',
                                borderRadius: "10px",
                            }}
                        >
                            <CardContent
                                sx={{
                                    px: 4,
                                    py: 6,
                                    '&:last-child': {
                                        pb: 6
                                    }
                                }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: 'center',
                                        [theme.breakpoints.down('md')]: {
                                            display: 'block',
                                        }
                                    }}
                                >
                                    <Box>
                                        <Typography variant="h6" color="textSecondary"
                                            sx={{
                                                fontSize: "1.8rem",
                                                fontWeight: "800",
                                                color: "secondary.main",
                                                mb: 1
                                            }}
                                        >
                                            Didn’t find a solution?
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary"
                                            sx={{
                                                fontSize: "1.2rem",
                                                fontWeight: "600",
                                                color: "primary.main",
                                            }}
                                        >
                                            Let us recommend a Blockchain Solution for you!
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            minWidth: '98px',
                                            [theme.breakpoints.down('md')]: {
                                                mt: 1
                                            }
                                        }}
                                    >
                                        <Button variant="contained"
                                            sx={{
                                                textTransform: 'inherit',
                                                p: "10px 30px",
                                                boxShadow: 'none',
                                                fontWeight: '600',
                                                [theme.breakpoints.down('md')]: {
                                                    p: "10px 20px",
                                                },
                                            }}>
                                            Get In Touch <EastIcon sx={{ ml: 1.5 }} />
                                        </Button>
                                    </Box>
                                </Box>
                            </CardContent>
                        </Card>
                    </Box>
                </Container>
            </Box>
        </>
    )
}

export default SolutionListing