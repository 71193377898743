// drawerSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  open: true,
};

const drawerSlice = createSlice({
  name: 'drawer',
  initialState,
  reducers: {
    toggleDrawer: (state) => {
      state.open = !state.open;
    },
    openDrawer: (state) => {
      state.open = true;
    },
    closeDrawer: (state) => {
      state.open = false;
    },
    handleOverlayBg: (state) => {
      state.open = false;
    },
  },
});

export const { toggleDrawer, openDrawer, closeDrawer, handleOverlayBg } = drawerSlice.actions;
export default drawerSlice.reducer;
