import React, { useEffect, useState } from "react";
import {
  Box,
  List,
  ListItemAvatar,
  ListItemButton,
  Typography,
  styled,
  InputBase,
  Stack,
  IconButton,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CancelIcon from "@mui/icons-material/Cancel";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import { getImages } from "../const";
import { useFetchInboxSelectedSolutionListQuery } from "../../redux/api/Inbox/getInboxSelectedSolutionList";
import { useFetchRequirementBoardDetailsQuery } from "../../redux/api/Requirement Boards/getRequirementBoardDetail";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  getSolutionId,
  getSolutionName,
} from "../../redux/features/driveSlice";
import { hideLoader, showLoader } from "../../redux/features/loader";

const ShareSelectedBoardModal = ({ shareChatOpen, showToastMessage }) => {
  const userId = localStorage.getItem("userId");
  const logoBaseUrl = process.env.REACT_APP_S3_BUCKET_URL;
  const selectedBoardId = localStorage.getItem("selectedBoardId");
  const boardName = useSelector((state) => state.drive.boardName);
  const folderName = useSelector((state) => state.drive.folderName);
  const DocumentName = useSelector((state) => state.drive.fileName);
  const dispatch = useDispatch();
  const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "#ECF6FF",
    "&:hover": {
      backgroundColor: "#ECF6FF",
    },
    marginRight: "0",
    marginLeft: 0,
    marginBottom: 5,
    width: "100%",
    display: "flex",
    alignItems: "center",
  }));
  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    right: "0",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "#106EED",
    width: "100%",
    "& .MuiInputBase-input": {
      padding: theme.spacing(1.7, 0, 1.7, 1.7),
      paddingRight: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create("width"),
      width: "100%",
      fontSize: "12px",
      fontWeight: "500",
    },
  }));
  // const status = "Selected"

  // searching Logic
  const [searchParams, setSearchParams] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const {
    data: solutionList,
    error: solutionListError,
    isLoading: boardDetailLoading,
    refetch,
  } = useFetchInboxSelectedSolutionListQuery({
    userId: userId,
    selectedBoardId: selectedBoardId,
    searchParams: searchValue,
  });

  const handleInputChange = (event) => {
    setSearchParams(event.target.value);
  };

  const handleSolutionSearch = async (event) => {
    if (event.key === "Enter") {
      const inputValue = event.target.value.trim();
      if (!inputValue) {
        showToastMessage("Please enter a search term", "error");
        return;
      }

      setSearchValue(inputValue);
      dispatch(showLoader());

      try {
        // Await refetch and handle search results
        await refetch();
        if (solutionListError) {
          const errorMessage =
            solutionList?.message || "Failed to load search result";
          showToastMessage(errorMessage, "error");
        }
      } catch (solutionListError) {
        // Handle errors if needed
        showToastMessage("An error occurred during search", "error");
      } finally {
        // Hide loader after refetch is complete
        dispatch(hideLoader());
      }
    }
  };
  const handleClearSearch = () => {
    setSearchParams("");
    setSearchValue(""); // Reset search value to empty
    refetch(); // Refetch all data
  };

  const selectedSolutionId = (solutionId, solutionName) => {
    localStorage.setItem("selectedSolutionId", solutionId);
    dispatch(getSolutionName(solutionName));
  };
  //   Loader
  useEffect(() => {
    if (boardDetailLoading) {
      dispatch(showLoader());
    } else {
      dispatch(hideLoader());
    }
  }, [boardDetailLoading, dispatch]);

  return (
    <>
      <Box
        sx={{
          mb: 3,
        }}
      >
        <Typography
          variant="h5"
          sx={{
            fontSize: '1.3rem',
            color: 'secondary.main',
            fontWeight: '700',
            maxWidth: '100%', // Set maximum width to the width of the modal
            whiteSpace: 'nowrap', // Prevents text from wrapping onto a new line
            overflow: 'hidden', // Hides any overflowed text
            textOverflow: 'ellipsis', // Adds "..." at the end of the truncated text
          }}
        >
          Share “{folderName || DocumentName}”
        </Typography>
      </Box>
      <Search>
        <StyledInputBase
          placeholder="Search Solutions"
          inputProps={{ "aria-label": "search" }}
          value={searchParams}
          onChange={handleInputChange}
          onKeyDown={handleSolutionSearch}
          autoFocus
        />
        {searchParams ? (
          <IconButton
            onClick={handleClearSearch}
            sx={{ position: "absolute", right: 0 }}
          >
            <CancelIcon sx={{ color: "lightSecondary.main" }} />
          </IconButton>
        ) : (
          <SearchIconWrapper>
            <SearchIcon sx={{ color: "lightSecondary.main" }} />
          </SearchIconWrapper>
        )}
      </Search>
      <Typography
        variant="h6"
        color="textSecondary"
        sx={{
          fontSize: "1.1rem",
          fontWeight: "700",
          color: "secondary.main",
          marginBottom: "0rem",
        }}
      >
        Select Board {`>`} Solution
      </Typography>

      <List
        component="nav"
        sx={{
          px: 0,
          py: 0,
          mb: 3,
          "& .MuiListItemButton-root": {
            py: 0.5,
          },
          overflowY: "auto",
          maxHeight: "280px",
          "&::-webkit-scrollbar": {
            width: "6px",
            borderRadius: "4px",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "transparent",
            borderRadius: "4px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#9C9C9C",
            borderRadius: "4px",
          },
        }}
      >
        {!solutionList?.solutions?.solutions ||
        solutionList?.solutions?.solutions.length === 0 ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="body1" 
              sx={{
                  color:"black",
                  fontWeight: 500,  
                  marginTop:'20px'
                }}
            >
              No solutions is available 
            </Typography>
          </Box>
        ) : (
          solutionList?.solutions?.solutions.map((solution, index) => (
            <ListItemButton
              key={solution.solution_id._id}
              onClick={() => {
                shareChatOpen();
                selectedSolutionId(
                  solution.solution_id._id,
                  solution.solution_id.solution_name
                );
              }}
              sx={{
                minHeight: "50px",
                justifyContent: "space-between",
                alignItems: "center",
                background: "#FFFFFF",
                borderRadius: "5px",
                marginTop: "5px",
                "&:hover": {
                  backgroundColor: "extraLTheme.main",
                },
              }}
            >
              <Stack
                sx={{
                  flexDirection: "inherit",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <ListItemAvatar
                  sx={{
                    display: "inline-block",
                    marginRight: "7px",
                  }}
                >
                  <img
                    style={{ maxWidth: "54px", display: "block" }}
                    src={`${logoBaseUrl}${solution.solution_id.logo}`}
                    alt={`${solution.solution_id.solution_name} logo`}
                  />
                </ListItemAvatar>
                <Typography
                  variant="subtitle1"
                  noWrap
                  sx={{
                    fontSize: "1.1rem",
                    color: "lightSecondary.main",
                    fontWeight: "600",
                    lineHeight: "20px",
                    maxWidth: "260px",
                  }}
                >
                  {solution.solution_id.solution_name}
                </Typography>
              </Stack>
              <ArrowForwardIosOutlinedIcon
                sx={{
                  color: "lightSecondary.main",
                  fontSize: "15px",
                }}
              />
            </ListItemButton>
          ))
        )}
      </List>
    </>
  );
};

export default ShareSelectedBoardModal;
