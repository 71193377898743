import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl =process.env.REACT_APP_base_URL;

const acceptMeeting = createApi({
    reducerPath: 'acceptMeeting',
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers) => {
            // Add any headers you need here
            return headers;
        },
    }),
    endpoints: (builder) => ({
        acceptMeetingInvitation: builder.mutation({
            query: ({  userId , meetingId }) => ({
                url: `meetings/accept`,
                method: 'POST',
                body: {
                    user_id: userId,
                    meeting_id: meetingId,
                }
            }),
            transformResponse: (response) => {
            return response
            },
        }),
    }),
});

export const { useAcceptMeetingInvitationMutation } = acceptMeeting;
export default acceptMeeting;
