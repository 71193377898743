import { createSlice } from "@reduxjs/toolkit";

const driveSlice = createSlice({
    name :'drive',
    initialState: {
        folderId: null,
        folderName: null,
        fileId: null,
        fileName: null,
        boardId: null,
        boardName: null,
        solutionName: null,
        documentStatus: null,
    },
    reducers:{
        getFolderId: (state , action)=> {
          state.folderId =  action.payload
        },
        getFolderName: (state , action)=> {
          state.folderName =  action.payload
        },
        getFileId: (state , action)=> {
          state.fileId =  action.payload
        },
        getFileName: (state , action)=> {
          state.fileName =  action.payload
        },
        getBoardId: (state , action)=> {
          state.boardId =  action.payload
        },
        getBoardName: (state , action)=> {
          state.boardName =  action.payload
        },
        getSolutionId: (state , action)=> {
          state.solutionId =  action.payload
        },
        getSolutionName: (state , action)=> {
          state.solutionName =  action.payload
        },
        getDocumentStatus: (state, action)=>{
          state.documentStatus = action.payload
        },

        clearFolderData: (state) => {
            state.folderId = null;
            state.folderName = null;
          },
        
    },
});

export const {getFolderId ,getFileId,getFileName,  getFolderName , clearFolderData , getBoardId ,getBoardName, getSolutionId ,getSolutionName, getDocumentStatus  } = driveSlice.actions;
export default driveSlice.reducer;