import { fetchBaseQuery, createApi } from '@reduxjs/toolkit/query/react';


const baseUrl = process.env.REACT_APP_base_URL;
const logoBaseUrl = 'https://bsdawebfiledata.s3.ca-central-1.amazonaws.com/solution/';

export const recentInvitations  = createApi({
    reducerPath: 'recentInvitations',
    baseQuery: fetchBaseQuery({
      baseUrl: baseUrl,
      prepareHeaders: (headers) => {
        // Add any headers you need here
        return headers;
      },
    }),
    endpoints: (builder) => ({
      fetchRecentMeetingsList: builder.query({
        query: ({userId , searchParam}) => ({
          url: `meetings/recent-invitation/${userId}`,
          method: 'GET',
        }),
        transformResponse: (response) => {
          if(response?.data?.length>0){
            return response;
          }else{
            return {data: []}
          }
        },
        
        refetchOnWindowFocus: true,
        // retry: RetryOnError,
        onError: (error) => {
          console.error('Fetch recent invitations error:', error);
          // Handle error cases as per your application's requirements
        },
      })
    })
})


export const { useFetchRecentMeetingsListQuery } = recentInvitations;
export default recentInvitations;
