import React from 'react'
import {
    Box,
    ListItemText,
    Typography,
    ListItemAvatar,
    Avatar,
    Badge,
    Button,
    IconButton,
    TextField,
    styled,
    Stack,
    AvatarGroup,
} from '@mui/material';
import { useTheme } from '@emotion/react';
import { getImages } from '../../commonComponents/const';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import MicIcon from '@mui/icons-material/Mic';
import SendIcon from '@mui/icons-material/Send';
import { UserAddOutlined } from '@ant-design/icons';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import AddReactionOutlinedIcon from '@mui/icons-material/AddReactionOutlined';
import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import ReplyOutlinedIcon from '@mui/icons-material/ReplyOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { CloseOutlined } from '@mui/icons-material';
import { setChatSelected, setOpenGroupChat, toggleOpenChatInfo, toggleOpenNewChat, toggleOpenSolutionList, toggleThreadChat } from '../../redux/features/chatSlice';
import { useDispatch, useSelector } from 'react-redux';

const GroupChatContainer = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const solutionList = useSelector((state)=>state.chat.openSolutionList)
    const SmallAvatar = styled(Avatar)(({ theme }) => ({
        width: 24,
        height: 24,
        border: `2px solid ${theme.palette.background.paper}`,
    }));
    const chatInfoHandle = () => {
        dispatch(toggleOpenChatInfo());
        if(!solutionList){
         dispatch(toggleOpenSolutionList());
        }
    };
    const threadChatHandle = () => {
        dispatch(toggleThreadChat());
    };
    const closeChatContainer = () => {
        dispatch(setChatSelected(false));
        dispatch(setOpenGroupChat(false));
    };

    return (
        <>
            <Box>
                {/* Chat header */}
                <Box
                    sx={{
                        backgroundColor: 'extraLTheme.main',
                        borderRadius: '10px 10px 0 0',
                        p: 2,
                        display: 'flex'
                    }}
                >
                    <Badge
                        overlap="circular">
                        <Badge
                            overlap="circular"
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                            badgeContent={
                                <SmallAvatar
                                    sx={{
                                        width: "30px",
                                        height: "30px",
                                        ml: 1.5
                                    }}
                                    alt="Remy Sharp" src={getImages('2.jpg')} />
                            }
                        />
                        <Avatar alt="Travis Howard"
                            sx={{
                                color: 'success.main',
                                bgcolor: 'success.lighter',
                                width: '50px',
                                height: '50px'
                            }}
                            src={getImages('1.jpg')}
                        />
                        <Badge
                            overlap="circular"
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                            badgeContent={
                                <SmallAvatar
                                    sx={{
                                        mr: 1.5
                                    }}
                                    alt="Remy Sharp" src={getImages('2.jpg')} />
                            }
                        />
                    </Badge>
                    <ListItemText
                        sx={{
                            pl: '10px',
                            width: "60%"
                        }}
                        primary={
                            <Typography noWrap
                                sx={{
                                    fontSize: "1rem",
                                    fontWeight: '600',
                                    color: 'secondary.main',
                                    lineHeight: '22px',
                                    maxWidth: '220px',
                                    [theme.breakpoints.down('sm')]: {
                                        maxWidth: '110px'
                                    }
                                }}
                                variant="h6">Solution discussion
                            </Typography>}
                        secondary={<Typography
                            noWrap
                            variant="body2"
                            sx={{
                                fontSize: '12px',
                                fontWeight: '500',
                                color: 'primary.main',
                                pl: 0
                            }}
                        >
                            Ivan Itchinos, Theresa Green, Vish Strophobia & You
                        </Typography>}
                    />
                    <ListItemText
                        sx={{
                            width: "calc(40% - 56px)",
                            textAlign: 'right',
                            [theme.breakpoints.down('md')]: {
                                whiteSpace: 'nowrap',
                                minWidth: "120px",
                            }
                        }}
                    >
                        <IconButton
                            sx={{
                                position: 'relative',
                                [theme.breakpoints.down('lg')]: {
                                    px : 0.5
                                }
                            }}
                        >
                            <UserAddOutlined
                                style={{
                                    width: "22px",
                                    height: "22px",
                                    color: "#2A589C"
                                }}
                            />
                        </IconButton>
                        <IconButton
                            sx={{
                                position: 'relative',
                                [theme.breakpoints.down('lg')]: {
                                    px : 0.7
                                }
                            }}
                        >
                            <CalendarMonthIcon
                                sx={{
                                    color: 'lightSecondary.main',
                                    fontSize: '24px',
                                }}
                            />
                            <LocalPhoneIcon
                                sx={{
                                    color: 'lightSecondary.main',
                                    fontSize: '17px',
                                    position: 'absolute',
                                    left: '4px',
                                    bottom: '2px'
                                }}
                            />
                        </IconButton>
                        <IconButton
                            onClick={chatInfoHandle}
                            sx={{
                                [theme.breakpoints.down('lg')]: {
                                    px : 0.5
                                }
                            }}
                        >
                            <InfoOutlinedIcon
                                sx={{
                                    color: 'lightSecondary.main',
                                    fontSize: '24px',
                                }}
                            />
                        </IconButton>
                        <IconButton
                            onClick={closeChatContainer}
                            sx={{
                                [theme.breakpoints.up('lg')]: {
                                    display: 'none',
                                },
                                [theme.breakpoints.down('lg')]: {
                                    px : 0.2
                                }
                            }}
                        >
                            <CloseOutlined
                                sx={{
                                    color: 'lightSecondary.main',
                                    fontSize: '24px',
                                }}
                            />
                        </IconButton>
                    </ListItemText>
                </Box>
                {/* Chat Body */}
                <Box
                    sx={{
                        position: 'relative',
                        overflowY: 'auto',
                        pr: 1,
                        maxHeight: 'calc(100vh - 265px)',
                        minHeight: 'calc(100vh - 267px)',
                        '&::-webkit-scrollbar': {
                            width: '6px',
                            borderRadius: '4px'
                        },
                        '&::-webkit-scrollbar-track': {
                            backgroundColor: 'transparent',
                            borderRadius: '4px'
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: '#9C9C9C',
                            borderRadius: '4px'
                        },
                        [theme.breakpoints.down('lg')]: {
                            maxHeight: 'calc(100vh - 224px)',
                            minHeight: 'calc(100vh - 226px)',
                        },
                        [theme.breakpoints.down('md')]: {
                            maxHeight: 'calc(100vh - 251px)',
                            minHeight: 'calc(100vh - 253px)',
                        }
                    }}
                >
                    {/* Sender message */}
                    {/* <Box
                        sx={{
                            textAlign: 'right',
                            pr: 2,
                            py: 2
                        }}>
                        <Box
                            sx={{
                                backgroundColor: 'lightSecondary.main',
                                borderRadius: '10px 0px 10px 10px',
                                p: "10px 15px",
                                width: '60%',
                                position: 'relative',
                                display: 'inline-block',
                                textAlign: 'left',
                                '&:before': {
                                    content: '""',
                                    position: 'absolute',
                                    right: '-16px',
                                    top: '0',
                                    borderLeft: 'solid 16px #2A589C',
                                    borderBottom: 'solid 16px transparent'
                                }
                            }}
                        >
                            <Typography variant='body2'
                                sx={{
                                    fontSize: '0.9rem',
                                    color: '#fff',
                                    fontWeight: '500'
                                }}
                            >Hello Team, I came across to your blockchain solution and Keen to know more about it. Actually I am working in fintech domain where we are planning to implement such solution.</Typography>
                            <Typography variant='body2'
                                sx={{
                                    fontSize: '12px',
                                    color: 'ltheme.main',
                                    fontWeight: '500',
                                    textAlign: 'right'
                                }}
                            >11:48 AM</Typography>
                        </Box>
                    </Box> */}
                    {/* Reciver message */}
                    <Box
                        sx={{
                            textAlign: 'left',
                            pl: 2,
                            py: 2,
                            display: 'flex'
                        }}>
                        <ListItemAvatar
                            sx={{
                                width: "42px"
                            }}
                        >
                            <Avatar
                                sx={{
                                    color: 'success.main',
                                    bgcolor: 'success.lighter',
                                    width: '40px',
                                    height: '40px'
                                }}
                                src={getImages('1.jpg')}
                            >
                            </Avatar>
                        </ListItemAvatar>
                        <Box
                            sx={{
                                backgroundColor: 'extraLTheme.main',
                                borderRadius: '0px 10px 10px 10px',
                                p: "10px 15px",
                                maxWidth: '60%',
                                position: 'relative',
                                display: 'inline-block',
                                textAlign: 'left',
                                '&:before': {
                                    content: '""',
                                    position: 'absolute',
                                    left: '-16px',
                                    top: '0',
                                    borderRight: 'solid 16px #ECF6FF',
                                    borderBottom: 'solid 16px transparent'
                                }
                            }}
                        >
                            <Typography variant='body2'
                                sx={{
                                    fontSize: '0.9rem',
                                    color: '#419C2A',
                                    fontWeight: '600'
                                }}>
                                Theresa Green
                            </Typography>
                            <Box
                                sx={{
                                    mt: 0.5
                                }}
                            >
                                <img
                                    style={{ maxWidth: '120px', borderRadius: '8px' }}
                                    src={getImages('media2.jpg')}
                                />
                            </Box>
                            <Typography variant='body2'
                                sx={{
                                    fontSize: '12px',
                                    color: 'ltheme.main',
                                    fontWeight: '500',
                                    textAlign: 'right'
                                }}
                            >11:48 AM</Typography>
                            <Box
                                sx={{
                                    backgroundColor: '#ECF6FF',
                                    border: 'solid 1px #A2C2F1',
                                    borderRadius: '10px',
                                    padding: '4px 5px 0 7px',
                                    width: '36px',
                                    height: '36px',
                                    position: 'absolute',
                                    left: '10px',
                                    bottom: '-15px',
                                }}
                            >👍🏻</Box>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            textAlign: 'left',
                            pl: 2,
                            py: 2,
                            display: 'flex'
                        }}>
                        <ListItemAvatar
                            sx={{
                                width: "42px"
                            }}
                        >
                            <Avatar
                                sx={{
                                    color: 'success.main',
                                    bgcolor: 'success.lighter',
                                    width: '40px',
                                    height: '40px'
                                }}
                                src={getImages('1.jpg')}
                            >
                            </Avatar>
                        </ListItemAvatar>
                        <Box
                            sx={{
                                backgroundColor: 'extraLTheme.main',
                                borderRadius: '0px 10px 10px 10px',
                                p: "10px 15px",
                                width: '60%',
                                position: 'relative',
                                display: 'inline-block',
                                textAlign: 'left',
                                '&:before': {
                                    content: '""',
                                    position: 'absolute',
                                    left: '-16px',
                                    top: '0',
                                    borderRight: 'solid 16px #ECF6FF',
                                    borderBottom: 'solid 16px transparent'
                                },
                                [theme.breakpoints.down('md')]: {
                                    width: 'calc(100% - 70px)',
                                }
                            }}
                        >
                            <Typography variant='body2'
                                sx={{
                                    fontSize: '0.9rem',
                                    color: '#C3A400',
                                    fontWeight: '600'
                                }}>
                                Ivan Itchinos
                            </Typography>
                            <Stack
                                direction="row"
                                sx={{
                                    my: 0.5,
                                    backgroundColor: '#DBEBF8',
                                    p: 2,
                                    borderRadius: '10px',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Stack
                                    direction="row"
                                    sx={{
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <ListItemAvatar
                                        sx={{
                                            textAlign: "center",
                                            backgroundColor: "#fff",
                                            minWidth: "50px",
                                            borderRadius: "10px",
                                            height: '50px',
                                            lineHeight: '50px',
                                            position: 'relative'
                                        }}
                                    >
                                        <img
                                            style={{
                                                maxWidth: "40px",
                                                maxHeight: "40px",
                                                position: 'absolute',
                                                left: '50%',
                                                top: '50%',
                                                transform: 'translate(-50%, -50%)',
                                            }}
                                            src={getImages('pdf_icon.png')} />
                                    </ListItemAvatar>
                                    <Stack
                                        sx={{
                                            pl: 2
                                        }}
                                    >
                                        <Typography noWrap
                                            sx={{
                                                fontSize: "0.9rem",
                                                fontWeight: '600',
                                                color: 'secondary.main',
                                                lineHeight: '22px',
                                                mb: 0.5,
                                            }}
                                            variant="subtitle1">Service description
                                        </Typography>
                                        <Typography
                                            noWrap
                                            variant="body2"
                                            sx={{
                                                fontSize: '0.8rem',
                                                fontWeight: '500',
                                                color: 'ltheme.main',
                                                width: "100%"

                                            }}
                                        >Pdf • 3.8 mb</Typography>
                                    </Stack>
                                </Stack>
                                <IconButton>
                                    <FileDownloadOutlinedIcon
                                        sx={{
                                            color: 'lightSecondary.main'
                                        }}
                                    />
                                </IconButton>
                            </Stack>
                            <Typography variant='body2'
                                sx={{
                                    fontSize: '0.9rem',
                                    color: 'lightSecondary.main',
                                    fontWeight: '500'
                                }}
                            >Here are the services we provide, have a look.</Typography>
                            <Typography variant='body2'
                                sx={{
                                    fontSize: '12px',
                                    color: 'ltheme.main',
                                    fontWeight: '500',
                                    textAlign: 'right'
                                }}
                            >11:48 AM</Typography>
                        </Box>
                    </Box>
                    {/* Sender message */}
                    <Box
                        sx={{
                            textAlign: 'right',
                            pr: 2,
                            py: 2
                        }}>
                        <Box
                            sx={{
                                backgroundColor: 'lightSecondary.main',
                                borderRadius: '10px 0px 10px 10px',
                                p: "10px 15px",
                                width: '60%',
                                position: 'relative',
                                display: 'inline-block',
                                textAlign: 'left',
                                '&:before': {
                                    content: '""',
                                    position: 'absolute',
                                    right: '-16px',
                                    top: '0',
                                    borderLeft: 'solid 16px #2A589C',
                                    borderBottom: 'solid 16px transparent'
                                },
                                [theme.breakpoints.down('md')]: {
                                    width: 'calc(100% - 70px)',
                                }
                            }}
                        >
                            <Typography variant='body2'
                                sx={{
                                    fontSize: '0.9rem',
                                    color: '#fff',
                                    fontWeight: '500'
                                }}
                            >Hello Team, This looks good to me. Let’s get on a call to discuss more details on it.</Typography>
                            <Typography variant='body2'
                                sx={{
                                    fontSize: '12px',
                                    color: 'ltheme.main',
                                    fontWeight: '500',
                                    textAlign: 'right'
                                }}
                            >11:48 AM</Typography>
                        </Box>
                    </Box>
                    {/* Reciver message */}
                    <Box
                        sx={{
                            textAlign: 'left',
                            pl: 2,
                            py: 2,
                            display: 'flex'
                        }}>
                        <ListItemAvatar
                            sx={{
                                width: "42px"
                            }}
                        >
                            <Avatar
                                sx={{
                                    color: 'success.main',
                                    bgcolor: 'success.lighter',
                                    width: '40px',
                                    height: '40px'
                                }}
                                src={getImages('1.jpg')}
                            >
                            </Avatar>
                        </ListItemAvatar>
                        <Box
                            sx={{
                                backgroundColor: 'extraLTheme.main',
                                borderRadius: '0px 10px 10px 10px',
                                p: "10px 15px",
                                width: '60%',
                                position: 'relative',
                                display: 'inline-block',
                                textAlign: 'left',
                                '&:before': {
                                    content: '""',
                                    position: 'absolute',
                                    left: '-16px',
                                    top: '0',
                                    borderRight: 'solid 16px #ECF6FF',
                                    borderBottom: 'solid 16px transparent'
                                },
                                [theme.breakpoints.down('md')]: {
                                    width: 'calc(100% - 70px)',
                                }
                            }}
                        >
                            <Typography variant='body2'
                                sx={{
                                    fontSize: '0.9rem',
                                    color: '#419C2A',
                                    fontWeight: '600'
                                }}>
                                Theresa Green
                            </Typography>
                            <Typography variant='body2'
                                sx={{
                                    fontSize: '0.9rem',
                                    color: 'lightSecondary.main',
                                    fontWeight: '500',
                                    mt: 0.5
                                }}
                            >Hey Vish, Should I schedule a meeting tomorrow?</Typography>
                            <Button
                                onClick={threadChatHandle}
                                direction="row"
                                sx={{
                                    alignItems: 'center',
                                    backgroundColor: '#DBEBF8',
                                    borderRadius: '10px',
                                    padding: '5px 10px 7px',
                                    mt: 1,
                                    textTransform: 'inherit'
                                }}
                            >
                                <TextsmsOutlinedIcon
                                    sx={{
                                        color: 'lightSecondary.main',
                                        fontSize: '20px',
                                        mt: 0.5
                                    }}
                                />
                                <AvatarGroup max={3}
                                    sx={{
                                        mx: 1,
                                        height: 20,
                                        overflow: 'visible'
                                    }}>
                                    <Avatar
                                        alt="Remy Sharp"
                                        src={getImages("1.jpg")}
                                        sx={{ width: 20, height: 20 }}
                                    />
                                    <Avatar
                                        alt="Remy Sharp"
                                        src={getImages("2.jpg")}
                                        sx={{ width: 20, height: 20 }}
                                    />
                                    <Avatar
                                        alt="Remy Sharp"
                                        src={getImages("3.jpg")}
                                        sx={{ width: 20, height: 20 }}
                                    />
                                </AvatarGroup>
                                <Typography variant='body2'
                                    sx={{
                                        fontSize: '13px',
                                        fontWeight: '500',
                                        color: 'lightSecondary.main'
                                    }}
                                >3 replies</Typography>
                                <KeyboardArrowRightIcon
                                    sx={{
                                        ml: 1,
                                        color: 'lightSecondary.main',
                                        verticalAlign: 'middle',
                                        marginTop: '1px'
                                    }}
                                />
                            </Button>
                            <Typography variant='body2'
                                sx={{
                                    fontSize: '12px',
                                    color: 'ltheme.main',
                                    fontWeight: '500',
                                    textAlign: 'right'
                                }}
                            >11:48 AM</Typography>
                            <Box
                                sx={{
                                    backgroundColor: '#ECF6FF',
                                    border: 'solid 1px #A2C2F1',
                                    borderRadius: '10px',
                                    padding: '0 7px',
                                    minWidth: '36px',
                                    minHeight: '36px',
                                    position: 'absolute',
                                    right: '-10px',
                                    top: '-15px',
                                    boxShadow: '0 5px 8px rgba(7, 57, 133, 0.1)',
                                }}
                            >
                                <IconButton
                                    sx={{
                                        p: 0.7
                                    }}
                                >
                                    <AddReactionOutlinedIcon
                                        sx={{
                                            color: 'lightSecondary.main',
                                            fontSize: '20px',
                                        }}
                                    />
                                </IconButton>
                                <IconButton
                                    sx={{
                                        p: 0.7
                                    }}
                                >
                                    <TextsmsOutlinedIcon
                                        sx={{
                                            color: 'lightSecondary.main',
                                            fontSize: '20px',
                                        }}
                                    />
                                </IconButton>
                                <IconButton
                                    sx={{
                                        p: 0.7
                                    }}
                                >
                                    <BookmarkBorderOutlinedIcon
                                        sx={{
                                            color: 'lightSecondary.main',
                                            fontSize: '20px',
                                        }}
                                    />
                                </IconButton>
                                <IconButton
                                    sx={{
                                        p: 0.7
                                    }}
                                >
                                    <ReplyOutlinedIcon
                                        sx={{
                                            color: 'lightSecondary.main',
                                            fontSize: '20px',
                                        }}
                                    />
                                </IconButton>
                            </Box>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            textAlign: 'left',
                            pl: 2,
                            py: 2,
                            display: 'flex'
                        }}>
                        <ListItemAvatar
                            sx={{
                                width: "42px"
                            }}
                        >
                            <Avatar
                                sx={{
                                    color: 'success.main',
                                    bgcolor: 'success.lighter',
                                    width: '40px',
                                    height: '40px'
                                }}
                                src={getImages('1.jpg')}
                            >
                            </Avatar>
                        </ListItemAvatar>
                        <Box
                            sx={{
                                backgroundColor: 'extraLTheme.main',
                                borderRadius: '0px 10px 10px 10px',
                                p: "10px 15px",
                                maxWidth: '60%',
                                position: 'relative',
                                display: 'inline-block',
                                textAlign: 'left',
                                '&:before': {
                                    content: '""',
                                    position: 'absolute',
                                    left: '-16px',
                                    top: '0',
                                    borderRight: 'solid 16px #ECF6FF',
                                    borderBottom: 'solid 16px transparent'
                                },
                                [theme.breakpoints.down('md')]: {
                                    maxWidth: '100%',
                                    width: 'calc(100% - 70px)',
                                }
                            }}
                        >
                            <Typography variant='body2'
                                sx={{
                                    fontSize: '0.9rem',
                                    color: '#9C2A2A',
                                    fontWeight: '600'
                                }}>
                                Vish Strophobia
                            </Typography>
                            <Typography variant='body2'
                                sx={{
                                    fontSize: '0.9rem',
                                    color: 'lightSecondary.main',
                                    fontWeight: '500',
                                    mt: 0.5
                                }}
                            >Yes I’m available.</Typography>
                            <Typography variant='body2'
                                sx={{
                                    fontSize: '12px',
                                    color: 'ltheme.main',
                                    fontWeight: '500',
                                    textAlign: 'right'
                                }}
                            >11:48 AM</Typography>

                            <Box
                                sx={{
                                    backgroundColor: '#ECF6FF',
                                    border: 'solid 1px #A2C2F1',
                                    borderRadius: '10px',
                                    padding: '0 7px',
                                    minWidth: '36px',
                                    minHeight: '36px',
                                    position: 'absolute',
                                    right: '-50px',
                                    top: '-15px',
                                    boxShadow: '0 5px 8px rgba(7, 57, 133, 0.1)',
                                    display: 'flex',
                                    alignItems: 'center',
                                    [theme.breakpoints.down('md')]: {
                                        right: '-10px',
                                    }
                                }}
                            >
                                <IconButton
                                    sx={{
                                        p: 0.7
                                    }}
                                >
                                    <img style={{ maxWidth: '130px' }} src={getImages('emoji-icons.png')} />
                                </IconButton>
                                <IconButton
                                    variant="contained"
                                    sx={{
                                        p: 0.4,
                                        backgroundColor: '#DBEBF8'
                                    }}
                                >
                                    <AddOutlinedIcon
                                        sx={{
                                            color: '#2A589C'
                                        }}
                                    />
                                </IconButton>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                {/* Chat Footer */}
                <Box
                    sx={{
                        backgroundColor: 'extraLTheme.main',
                        borderStyle: 'solid',
                        borderWidth: '1px',
                        borderColor: 'ltheme.main',
                        p: 1.5,
                        borderRadius: '0 0 10px 10px',
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >

                    <Button variant="none"
                        sx={{
                            textTransform: 'inherit',
                            p: "8px 8px",
                            minWidth: 'inherit',
                            boxShadow: 'none'
                        }}>
                        <SentimentSatisfiedAltIcon
                            sx={{
                                color: "lightSecondary.main",
                                fontSize: "20px"
                            }}
                        />
                    </Button>
                    <Button variant="none"
                        sx={{
                            textTransform: 'inherit',
                            p: "8px 8px",
                            minWidth: 'inherit',
                            boxShadow: 'none'
                        }}>
                        <AttachFileIcon
                            sx={{
                                color: "lightSecondary.main",
                                fontSize: "20px"
                            }}
                        />
                    </Button>
                    <TextField id="outlined-basic" label="" variant="outlined" placeholder='Type a message'
                        sx={{
                            width: 'calc(100% - 153px)',
                            '& .MuiOutlinedInput-notchedOutline': {
                                border: 'none'
                            },
                            '& .MuiInputBase-input': {
                                padding: '0px 10px',
                                fontSize: '0.95rem',
                                color: 'lightSecondary.main',
                                fontWeight: '600'
                            }
                        }}
                    />
                    <Button variant="none"
                        sx={{
                            textTransform: 'inherit',
                            p: "8px 8px",
                            minWidth: 'inherit',
                            boxShadow: 'none',
                            borderRadius: '0',
                            borderRight: 'solid 1px',
                            borderRightColor: 'lightSecondary.main'
                        }}>
                        <MicIcon
                            sx={{
                                color: "lightSecondary.main",
                                fontSize: "20px"
                            }}
                        />
                    </Button>
                    <Button variant="contained"
                        sx={{
                            textTransform: 'inherit',
                            p: "8px 8px",
                            backgroundColor: 'lightSecondary.main',
                            minWidth: 'inherit',
                            boxShadow: 'none',
                            ml: 1
                        }}>
                        <SendIcon
                            sx={{
                                color: "#fff",
                                fontSize: "20px"
                            }}
                        />
                    </Button>
                </Box>
            </Box>
        </>
    )
}

export default GroupChatContainer