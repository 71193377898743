import * as React from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import {
  Avatar,
  Divider,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Menu,
  Paper,
  TextField,
  Typography,
  styled,
  InputBase,
  IconButton,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import CancelIcon from "@mui/icons-material/Cancel";
import SearchIcon from "@mui/icons-material/Search";
import { useFetchInboxRequirementBoardDetailsQuery } from "../redux/api/Inbox/getInboxRequirementBoard";
import { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { useFetchInboxSelectedSolutionListQuery } from "../redux/api/Inbox/getInboxSelectedSolutionList";
import { useDispatch } from "react-redux";
import { getSolutionList } from "../redux/features/inboxSolutions";
import { getBoardId } from "../redux/features/inboxSlice";
const InboxRequirementBoardDropdown = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  // get user id from localStorage
  const userId = localStorage.getItem("userId");

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openDw = Boolean(anchorEl);
  const [searchParams, setSearchParams] = useState("");
  const [searchValue, setSearchValue] = useState("");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "#ECF6FF",
    "&:hover": {
      backgroundColor: "#ECF6FF",
    },
    marginRight: "0",
    marginLeft: 0,
    marginBottom: 5,
    width: "100%",
  }));
  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    right: "0",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "#106EED",
    width: "100%",
    "& .MuiInputBase-input": {
      padding: theme.spacing(1.9, 0, 1.9, 1.7),
      paddingRight: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create("width"),
      width: "100%",
      fontSize: "12px",
      fontWeight: "500",
    },
  }));


  const {
    data: boardList,
    isLoading: boardListLoading,
    error: boardListError,
    refetch: refetchBoard,
  } = useFetchInboxRequirementBoardDetailsQuery({
    user_id: userId,
    searchParam: searchValue,
  });

        // Initialize selectedBoardId with the first board's ID if available
        const [selectedBoardId, setSelectedBoardId] = useState(() => {
          if (boardList?.boards && boardList.boards.length > 0) {
            const firstBoardId = boardList.boards[0]._id;
            return firstBoardId;
          }
          return "";
        });
        // storing selectedBoardId in localstorage
        const handleBoardSelection = (boardId) => {
          setSelectedBoardId(boardId);
          dispatch(getBoardId(boardId));
        };
  
  const {
    data: solutions,
    isLoading: solutionsLoading,
    error: solutionError,
    refetchSolutions,
  } = useFetchInboxSelectedSolutionListQuery({
    userId: userId,
    selectedBoardId: selectedBoardId,
  });
  useEffect(() => {
    if (solutions && solutions.solutions) {
      dispatch(getSolutionList(solutions.solutions));
    }
  }, [solutions, dispatch]);

  // handleSearching logic
  const handleInputChange = (event) => {
    setSearchParams(event.target.value);
  };
  const handleSearchRequirementBoard = async (event) => {
    if (event.key === "Enter") {
      const inputValue = event.target.value.trim();
      if (!inputValue) {
        setSearchValue(""); // Reset the searchValue state
        await refetchBoard();
        return;
      }
      setSearchValue(searchParams);

      if (boardList) {
        const errorMessage = boardList?.data?.message || "noDataFound";
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
      await refetchBoard();
    }
  };
   


  // Clear search after not found data
  const handleClearSearch = () => {
    setSearchParams("");
    setSearchValue("");
    refetchBoard();
  };

  return (
    <>
      <Box display={"flex"} sx={{ px: "0" }}>
        <ListItemButton
          onClick={handleClick}
          sx={{
            [theme.breakpoints.down("md")]: {
              p: 0.5,
            },
          }}
        >
          <ListItemAvatar
            sx={{
              [theme.breakpoints.down("md")]: {
                minWidth: "36px",
              },
            }}
          >
            <Avatar
              sx={{
                bgcolor: "secondary.main",
                px: 0,
                fontSize: "1.1rem",
                fontWeight: "600",
                width: "50px",
                height: "50px",
                borderRadius: "10px",
                textAlign: "center",
                [theme.breakpoints.down("md")]: {
                  width: "36px",
                  height: "36px",
                  fontSize: "0.9rem",
                },
              }}
              variant="rounded"
            ></Avatar>
          </ListItemAvatar>
          {boardList?.boards && boardList.boards.length > 0 ? (
            boardList.boards.map(
              (board, index) =>
                index === 0 && (
                  <ListItemText
                    key={index}
                    sx={{
                      width: "60%",
                      marginTop: "5px",
                    }}
                  >
                    <Typography
                      noWrap
                      sx={{
                        fontSize: "1.6rem",
                        fontWeight: "700",
                        color: "secondary.main",
                        marginLeft: "5px",
                        [theme.breakpoints.down("md")]: {
                          fontSize: "1.2rem",
                        },
                      }}
                      variant="h6"
                    >
                      {board.board_name}
                    </Typography>
                  </ListItemText>
                )
            )
          ) : (
            <ListItemText
              sx={{
                width: "60%",
                marginTop: "5px",
              }}
            >
              <Typography
                noWrap
                sx={{
                  fontSize: "1.6rem",
                  fontWeight: "700",
                  color: "secondary.main",
                  marginLeft: "5px",
                  [theme.breakpoints.down("md")]: {
                    fontSize: "1.2rem",
                  },
                }}
                variant="h6"
              >
                No Board Available
              </Typography>
            </ListItemText>
          )}

          <KeyboardArrowDownOutlinedIcon
            sx={{
              marginTop: "4px",
              fontSize: "36px",
              cursor: "pointer",
              [theme.breakpoints.down("md")]: {
                fontSize: "26px",
              },
            }}
          />
        </ListItemButton>

        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={openDw}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          sx={{
            "& .MuiMenu-paper": {
              borderRadius: "10px",
              border: "solid 1px #106EED",
              minWidth: "340px",
              p: 0,
            },
          }}
        >
          <Paper
            sx={{
              width: 420,
              maxWidth: "100%",
              background: "#fff",
              borderRadius: "10px",
              borderBottom: "none",
              boxShadow: "none",
              p: 1,
            }}
          >
            {/* <TextField
              id="searchBox"
              name="searchBox"
              type="search"
              label="Search"
              variant="outlined"
              fullWidth
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            /> */}
            <Search
              sx={{
                backgroundColor: "extraLTheme.main",
                mb: 2,
              }}
            >
              <SearchIconWrapper>
                {searchParams ? (
                  <IconButton
                    onClick={handleClearSearch}
                    sx={{ color: "lightSecondary.main", cursor: "pointer" }}
                  >
                    <CancelIcon />
                  </IconButton>
                ) : (
                  <SearchIcon sx={{ color: "lightSecondary.main" }} />
                )}
              </SearchIconWrapper>
              <StyledInputBase
                id="searchBox"
                placeholder="Search board"
                inputProps={{ "aria-label": "search" }}
                value={searchParams}
                onChange={handleInputChange}
                onKeyDown={handleSearchRequirementBoard}
                autoFocus
              />
            </Search>
            <Box
              sx={{
                maxHeight: "300px",
                overflowY: "auto",
                "&::-webkit-scrollbar": {
                  width: "6px",
                  borderRadius: "4px",
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "transparent",
                  borderRadius: "4px",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#9C9C9C",
                  borderRadius: "4px",
                },
              }}
            >
              {boardList?.boards?.map((board, index) => (
                <MenuItem
                  key={board._id}
                  onClick={() => {
                    handleBoardSelection(board._id);
                    handleClose();
                  }}
                  sx={{
                    fontSize: "0.9rem",
                    fontWeight: "600",
                    lineHeight: "30px",
                    color: "lightS30ondary.main",
                    py: 1.3,
                    px: 1.4,
                    borderRadius: "4px",
                    "&:hover": {
                      backgroundColor: "extraLTheme.main",
                    },
                    "&.active": {
                      backgroundColor: "primary.main",
                      color: "#fff",
                      borderRadius: "4px",
                    },
                  }}
                >
                  <ListItemAvatar
                    sx={{
                      minWidth: "30px",
                      marginRight: "10px",
                    }}
                  >
                    <Avatar
                      sx={{
                        bgcolor: "lightSecondary.main",
                        p: 0,
                        fontSize: "12px",
                        fontWeight: "600",
                        minWidth: "30px",
                        width: "30px",
                        height: "30px",
                        borderRadius: "4px",
                      }}
                      variant="rounded"
                    >
                      {board.board_title}
                    </Avatar>
                  </ListItemAvatar>
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#2A589C",
                      fontSize: "1.1rem",
                      fontWeight: "700",
                    }}
                  >
                    {board.board_name}
                  </Typography>
                </MenuItem>
              ))}
            </Box>
          </Paper>
        </Menu>
      </Box>
    </>
  );
};

export default InboxRequirementBoardDropdown;
