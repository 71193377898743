import React, { useState } from 'react'
import {
    Box,
    Typography,
    TextField,
    Button,
    IconButton,
    Stack,
    ListItemAvatar,
    ListItemText,
    Avatar,
    Divider,
    Stepper,
    Step,
    StepLabel,
    FormControl,
    Input,
    InputAdornment,
} from '@mui/material'
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import { getImages } from '../const';
import { useTheme } from '@emotion/react';
import EmailIcon from '@mui/icons-material/Email';
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { useNavigate } from 'react-router-dom';
const steps = [
    'Invite Accepted',
    'Commited',
    'Confirm Payment',
    'Invested',
];
const InvestmentsProcessModal = ({ handleClose, investTypeName }) => {
    const theme = useTheme();
    const navigate = useNavigate()
    const handleNavigation = (path)=>{
        navigate(path)
    }
    return (
        <>
            <Box
                sx={{
                    mb: 1
                }}
            >
                <Typography variant='h5'
                    sx={{
                        fontSize: "1.3rem",
                        color: "secondary.main",
                        fontWeight: "700"
                    }}>
                    AsianWeb Technologies
                    <IconButton
                        sx={{
                            p: 0,
                            ml: 1
                        }}>
                        <OpenInNewOutlinedIcon sx={{ color: 'lightSecondary.main' }} />
                    </IconButton>
                </Typography>
            </Box>
            <Stack
                direction="row"
                sx={{
                    padding: '10px 0',
                    borderRadius: '10px',
                    background: '#fff',
                    alignItems: 'center',
                }}
            >
                <ListItemAvatar
                    sx={{
                        width: "112px",
                    }}
                >
                    <Avatar
                        sx={{
                            color: 'success.main',
                            bgcolor: 'success.lighter',
                            width: '112px',
                            height: '112px'
                        }}
                        src={getImages('1.jpg')}
                    >

                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    sx={{
                        pl: '20px',
                        width: "80%"
                    }}>
                    <Typography noWrap
                        sx={{
                            fontSize: "1.2rem",
                            fontWeight: '700',
                            color: 'secondary.main',
                            lineHeight: '22px',
                            mb: 1,
                        }}
                        variant="h6">Nats White
                        <span style={{
                            color: "#A2C2F1",
                            fontSize: '0.9rem',
                            fontWeight: '500',
                            paddingLeft: '5px'
                        }}>
                            (COO)
                        </span>
                    </Typography>
                    <Typography
                        noWrap
                        variant="body2"
                        sx={{
                            fontSize: '1rem',
                            fontWeight: '600',
                            color: 'lightSecondary.main',
                            mb: 0.5,
                        }}
                    >
                        <EmailIcon
                            sx={{
                                verticalAlign: 'middle',
                                fontSize: '16px'
                            }}
                        /> nats.white@asianweb.in
                    </Typography>
                    <Typography
                        noWrap
                        variant="body2"
                        sx={{
                            fontSize: '1rem',
                            fontWeight: '600',
                            color: 'lightSecondary.main',
                            mb: 0.5,
                        }}
                    >
                        <PhoneEnabledIcon
                            sx={{
                                verticalAlign: 'middle',
                                fontSize: '16px'
                            }}
                        /> +1 (484) 4731859
                    </Typography>
                    <Typography
                        noWrap
                        variant="body2"
                        sx={{
                            fontSize: '1rem',
                            fontWeight: '600',
                            color: 'lightSecondary.main',
                        }}
                    >
                        <LocationOnIcon
                            sx={{
                                verticalAlign: 'middle',
                                fontSize: '16px'
                            }}
                        /> California, US
                    </Typography>
                </ListItemText>
            </Stack>
            <Divider
                sx={{
                    backgroundColor: '#DBEBF8',
                    my: 2
                }}
            />
            <Box
                sx={{
                    mb: 3
                }}
            >
                <Typography variant='h6'
                    sx={{
                        fontSize: '1.2rem',
                        fontWeight: '500',
                        color: 'primary.main',
                        mb: 2,
                    }}
                >Investment Status</Typography>
                <Box sx={{ width: '100%' }}>
                    <Stepper activeStep={investTypeName ==='invested'? 4 : 1} alternativeLabel
                        sx={{
                            '& .MuiStepConnector-root' : {
                                left: 'calc(-50% + 10px)',
                                right: 'calc(50% + 10px)',
                                top: '9px',
                            },
                            '& .MuiStepLabel-iconContainer' : {
                                zIndex: '1',
                                position: 'relative',
                                borderWidth: '2px',
                                borderStyle: 'solid',
                                borderColor: 'secondary.main',
                                borderRadius: '50%',
                                minWidth: '20px',
                                padding: '2px',
                                '& .MuiSvgIcon-root' : {
                                    color: '#fff',
                                    fontSize: '12px',
                                    '& .MuiStepIcon-text' : {
                                        display: 'none'
                                    }
                                },
                                '&.Mui-active': {
                                    '& .MuiSvgIcon-root' : {
                                        color: 'secondary.main',
                                    },
                                },
                                '&.Mui-completed': {
                                    '& .MuiSvgIcon-root' : {
                                        color: 'secondary.main',
                                    },
                                },
                            },
                            '& .MuiStepLabel-labelContainer' : {
                                '& .MuiStepLabel-label' : {
                                    fontSize: '1rem',
                                    fontWeight: '500',
                                    color: 'lightSecondary.main',
                                    // '&.Mui-active' : {
                                    //     color: 'lightSecondary.main',
                                    // },
                                    // '&.Mui-completed' : {
                                    //     color: 'lightSecondary.main',
                                    // },
                                },
                            },
                            '& .MuiStepConnector-line' : {
                              borderColor: 'secondary.main',
                              borderTopWidth: '2px',
                            },
                        }}
                    >
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </Box>
            </Box>
            <Box
                sx={{
                    mb: 2
                }}
            >
                <Typography variant='h5'
                    sx={{
                        fontSize: "12px",
                        color: "ltheme.main",
                        fontWeight: "500",
                        mb: 0.5
                    }}>
                    Investment Interest($)
                </Typography>
                <TextField
                    hiddenLabel
                    id="filled-hidden-label-normal"
                    variant="filled"
                    placeholder=''
                    value="150000"
                    sx={{
                        width: '100%',
                        borderRadius: '4px',
                        backgroundColor: 'extraLTheme.main',
                        border: 'solid 1px #DBEBF8',
                        '& .MuiInputBase-root': {
                            backgroundColor: 'extraLTheme.main',
                        },
                        '& .MuiInputBase-input': {
                            fontSize: '0.9rem',
                            color: 'lightSecondary.main',
                            fontWeight: '500',
                            pt: 1.5,
                            pb: 1.5
                        },
                        '& .MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error):before': {
                            border: 'none'
                        },
                        '& .MuiInputBase-root:hover:before': {
                            border: 'none'
                        },
                        '&:hover': {
                            borderColor: '#DBEBF8'
                        },
                        '& .MuiInputBase-root:before': {
                            border: 'none'
                        },
                        '& .MuiInputBase-root:after': {
                            border: 'none'
                        }
                    }}
                />
            </Box>
            {investTypeName ==='invested' &&
                <Box
                    sx={{
                        mb: 2
                    }}
                >
                    <Typography variant='h5'
                        sx={{
                            fontSize: "12px",
                            color: "ltheme.main",
                            fontWeight: "500",
                            mb: 0.5
                        }}>
                        Proof of transaction
                    </Typography>
                    <FormControl variant="filled"
                        sx={{
                            width: '100%',
                            borderRadius: '4px',
                            backgroundColor: 'extraLTheme.main',
                            border: 'solid 1px #DBEBF8',
                            '& .MuiInputBase-root': {
                                backgroundColor: 'extraLTheme.main',
                            },
                            '& .MuiInputBase-input': {
                                fontSize: '0.9rem',
                                color: 'lightSecondary.main',
                                fontWeight: '500',
                                pt: 1.5,
                                pb: 1.5
                            },
                            '& .MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error):before': {
                                border: 'none'
                            },
                            '& .MuiInputBase-root:hover:before': {
                                border: 'none'
                            },
                            '&:hover': {
                                borderColor: '#DBEBF8'
                            },
                            '& .MuiInputBase-root:before': {
                                border: 'none'
                            },
                            '& .MuiInputBase-root:after': {
                                border: 'none'
                            }
                        }}
                    >
                        <Input
                            sx={{
                                width: '100%',
                                borderRadius: '4px',
                                backgroundColor: 'extraLTheme.main',
                                border: 'solid 1px #DBEBF8',
                                '& .MuiInputBase-input': {
                                    fontSize: '0.9rem',
                                    color: 'lightSecondary.main',
                                    fontWeight: '500',
                                    p: 1.5
                                },
                            }}
                            value='Transaction.pdf'
                            type='text'
                            placeholder=''
                            endAdornment={
                                <InputAdornment position="end"
                                sx={{
                                    pr: 1
                                }}
                                >
                                    <IconButton
                                        sx={{
                                            p: 0.5,
                                            mr: 1
                                        }}
                                    >
                                        <VisibilityOutlinedIcon
                                            sx={{color: 'lightSecondary.main'}}
                                        />
                                    </IconButton>
                                    <IconButton
                                        sx={{
                                            p: 0.5
                                        }}
                                    >
                                        <FileDownloadOutlinedIcon
                                            sx={{color: 'lightSecondary.main'}}
                                        />
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </Box>
            }
            <Box
                sx={{
                    textAlign:'center',
                    mb: 1,
                    mt: 4
                }}
            >
                <Button variant="outlined"
                    sx={{
                        textTransform: 'inherit',
                        p: "10px 25px",
                        width: '50%',
                        borderWidth: '2px',
                        fontWeight: '600',
                        borderColor: 'primary.main',
                        color: 'primary.main',
                        '&:hover': {
                            borderWidth: '2px',
                        }
                    }}
                    onClick={()=>handleNavigation("/inbox")}
                >
                    Go To Inbox
                </Button>
            </Box>
        </>
    )
}

export default InvestmentsProcessModal