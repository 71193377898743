import React, { useEffect, useState } from 'react'
import {
    Box,
    Typography,
    TextField,
    Button,
    Autocomplete
} from '@mui/material'
import { useTheme } from '@emotion/react';
import { DeleteOutlineOutlined } from '@mui/icons-material';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import { useLocation, useNavigate } from 'react-router-dom';
import { hideLoader, showLoader } from '../../redux/features/loader';
import { useDispatch } from 'react-redux';
import { useDeleteMeetingMutation } from '../../redux/api/Meetings/deleteMeeting';



const DeleteMeetingAlert = ({ handleClose,refetchUpComingList, goBackFromAlertHandle  , showToastMessage , currentMeetingId }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const userId = localStorage.getItem("userId");
    // const searchParams = new URLSearchParams(location.search);
    // const boardId = searchParams.get('board_id');
    const selectedBoardId = localStorage.getItem("boardId");
    const [deleteMeeting, { isLoading: deleteLoading , isError:deleteError}] = useDeleteMeetingMutation();
    const deleteUpComingMeeting = async () => {
        try {
            const response =  await deleteMeeting({ currentMeetingId ,userId}).unwrap();
            const successMessage ="Meeting deleted ";
            if(response?.status === 200){
                showToastMessage(successMessage, "success");
            }else if(response?.message === "Meeting ID not found"){
                const errorMessage =  "You do not have permission to delete this meeting";
                showToastMessage(errorMessage, "error");
            }
            refetchUpComingList(); 
            handleClose(); 
        } catch (error) {
            const errorMessage ="Failed to delete meeting";
            showToastMessage(errorMessage, "error");
        }finally {
            // Hide the loader when the API call is finished, regardless of the outcome
            dispatch(hideLoader());
          }
    };
    useEffect(() => {
        if (deleteLoading) {
          dispatch(showLoader());
        } else {
          dispatch(hideLoader());
        }
      }, [deleteLoading, dispatch]);

    return (
        <>
            <Box
                sx={{
                    mb: 4,
                    mt: 3
                }}
            >
                <Typography variant='h5'
                    sx={{
                        fontSize: "1.1rem",
                        color: "secondary.main",
                        fontWeight: "700"
                    }}>
                    Are you sure you want to delete this meeting?
                </Typography>
            </Box>
            <Box
                sx={{
                    mt: 3,
                    display: 'flex',
                    gap: 2,
                }}
            >
                <Button variant="outlined"
                    sx={{
                        textTransform: 'inherit',
                        p: "10px 25px",
                        width: '50%',
                        borderWidth: '2px',
                        fontWeight: '600',
                        [theme.breakpoints.down('md')]: {
                            width: "50%",
                            p: "10px 10px",
                        },
                        [theme.breakpoints.down('xs')]: {
                            width: "50%",
                            p: "5px 5px",
                        },
                        borderColor: 'primary.main',
                        '&:hover': {
                            borderWidth: '2px',
                        }
                    }}
                    onClick={goBackFromAlertHandle}
                >
                    <ArrowBackIosNewOutlinedIcon
                        style={{
                            fontSize: '16px',
                            marginRight: "5px",
                            marginTop: "-2px"
                        }}/>
                    Go back
                </Button>
                <Button variant="outlined"
                    sx={{
                        textTransform: 'inherit',
                        p: "10px 25px",
                        borderColor: 'red.main',
                        color: 'red.main',
                        fontSize: '0.9rem',
                        fontWeight: '600',
                        width: '50%',
                        [theme.breakpoints.down('md')]: {
                            width: "50%",
                            p: "10px 10px",
                        },
                        [theme.breakpoints.down('xs')]: {
                            width: "50%",
                            p: "5px 5px",
                        },
                        '&:hover': {
                            borderColor: 'red.main',
                            backgroundColor: 'transparent'
                        }
                    }}
                    onClick={deleteUpComingMeeting}
                >
                    <DeleteOutlineOutlined
                        style={{
                            fontSize: '20px',
                            marginRight: "5px",
                            marginTop: "-3px"
                        }} />
                    Delete 
                </Button>
            </Box>
        </>
    )
}

export default DeleteMeetingAlert;