import React, { useState } from 'react'
import Step1 from '../../commonComponents/Frontend/FrontSteps/Step1';
import Step2 from '../../commonComponents/Frontend/FrontSteps/Step2';
import Step3 from '../../commonComponents/Frontend/FrontSteps/Step3';
import Step4 from '../../commonComponents/Frontend/FrontSteps/Step4';
import Step5 from '../../commonComponents/Frontend/FrontSteps/Step5';
import Step6 from '../../commonComponents/Frontend/FrontSteps/Step6';
import Step7 from '../../commonComponents/Frontend/FrontSteps/Step7';
import Step8 from '../../commonComponents/Frontend/FrontSteps/Step8';
import Step9 from '../../commonComponents/Frontend/FrontSteps/Step9';
import Step10 from '../../commonComponents/Frontend/FrontSteps/Step10';
import Step11 from '../../commonComponents/Frontend/FrontSteps/Step11';
import Step12 from '../../commonComponents/Frontend/FrontSteps/Step12';
import Step13 from '../../commonComponents/Frontend/FrontSteps/Step13';
import Success from '../../commonComponents/Frontend/FrontSteps/Success';

const FrontSteps = () => {
    const [currentStep, setCurrentStep] = useState(4);
    const nextStep = () => {
        setCurrentStep(currentStep + 1)
    }
    const prevStep = () => {
        if (currentStep > 1) {
            setCurrentStep(currentStep - 1)
        }
    }
    const isLastStep = () => {
        setCurrentStep(currentStep + 1)
    }
    switch (currentStep) {
        case 1:
            return (
                <>
                    <Step1
                        nextStep={nextStep}
                        currentStep={currentStep}
                        prevStep={prevStep}
                    />
                </>
            );

        case 2:
            return (
                <>
                    <Step2
                        nextStep={nextStep}
                        currentStep={currentStep}
                        prevStep={prevStep}
                    />
                </>
            );

        case 3:
            return (
                <>
                    <Step3
                        nextStep={nextStep}
                        currentStep={currentStep}
                        prevStep={prevStep}
                    />
                </>
            );

        case 4:
            return (
                <>
                    <Step4
                        nextStep={nextStep}
                        currentStep={currentStep}
                        prevStep={prevStep}
                    />
                </>
            );
        case 5:
            return (
                <>
                    <Step5
                        nextStep={nextStep}
                        currentStep={currentStep}
                        prevStep={prevStep}
                    />
                </>
            );
        case 6:
            return (
                <>
                    <Step6
                        nextStep={nextStep}
                        currentStep={currentStep}
                        prevStep={prevStep}
                    />
                </>
            );
        case 7:
            return (
                <>
                    <Step7
                        nextStep={nextStep}
                        currentStep={currentStep}
                        prevStep={prevStep}
                    />
                </>
            );
        case 8:
            return (
                <>
                    <Step8
                        nextStep={nextStep}
                        currentStep={currentStep}
                        prevStep={prevStep}
                    />
                </>
            );
        case 9:
            return (
                <>
                    <Step9
                        nextStep={nextStep}
                        currentStep={currentStep}
                        prevStep={prevStep}
                    />
                </>
            );
        case 10:
            return (
                <>
                    <Step10
                        nextStep={nextStep}
                        currentStep={currentStep}
                        prevStep={prevStep}
                    />
                </>
            );
        case 11:
            return (
                <>
                    <Step11
                        nextStep={nextStep}
                        currentStep={currentStep}
                        prevStep={prevStep}
                    />
                </>
            );
        case 12:
            return (
                <>
                    <Step12
                        nextStep={nextStep}
                        currentStep={currentStep}
                        prevStep={prevStep}
                    />
                </>
            );
        case 13:
            return (
                <>
                    <Step13
                        prevStep={prevStep}
                        currentStep={currentStep}
                        isLastStep={isLastStep}
                    />
                </>
            );
        default:
            return (
                <>
                    <Success/>
                </>
            );
    }
}

export default FrontSteps