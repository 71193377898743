import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  MenuItem,
  Typography,
  Menu,
  styled,
  InputBase,
  Paper,
  IconButton,
  ListItem,
  ListItemAvatar,
  List,
  ListItemButton,
  Tooltip,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import StarIcon from "@mui/icons-material/Star";
import { getImages } from "../../commonComponents/const";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import DriveFileMoveOutlinedIcon from "@mui/icons-material/DriveFileMoveOutlined";
import ColorLensOutlinedIcon from "@mui/icons-material/ColorLensOutlined";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { useFetchDriveDocumentListQuery } from "../../redux/api/BsiDrive/getDriveDocuments";
import FileUpload from "../../commonComponents/BsiDrive/fileUpload";
import Modals from "../Modals";
import RenameModal from "./RenameModal";
import MoveModal from "./MoveModal";
import ShareModal from "./ShareModal";
import {
  resetRefetchDocument,
  setRefetchDocument,
} from "../../redux/features/refetch";
import { useDispatch, useSelector } from "react-redux";
import DeleteFolderAlert from "./DeleteFolderAlert";
import CustomLoader from "../../utils/Loader/CustomLoader";
import { useFetchDriveItemListQuery } from "../../redux/api/BsiDrive/getDriveFolderList";
import { hideLoader, showLoader } from "../../redux/features/loader";
import {
  getDocumentStatus,
  getFileId,
  getFileName,
} from "../../redux/features/driveSlice";
import ShareSelectedBoardModal from "./ShareSelectedBoardModal";
import ShareSolutionChatModal from "./ShareSolutionChatModal";

const DriveFiles = ({
  showToastMessage,
  searchValue,
  refetch,
  setDocumentId,
  documentId,
  driveItemData,
}) => {
  // function for file avatar
  // function for file avatar
  const isDrawerOpen = useSelector((state) => state.drawer.open);
  function getIconForFileType(fileType) {
    switch (fileType.toLowerCase()) {
      case "jpg":
      case "jpeg":
      case "png":
        return getImages("image-icon.webp"); // Path to your image icon
      case "gif":
        return getImages("gifIcon.png");
      case "pdf":
        return getImages("pdf_icon.png"); // Path to your PDF icon
      case "docx":
        return getImages("docxIcon.png"); // Path to your PDF icon
      case "doc":
        return getImages("doc_icon.png"); // Path to your PDF icon
      case "mp4":
        return getImages("mp4.png");
      case "avi":
        return getImages("avi.png");
      case "mov":
        return getImages("mov.png");
      case "wmv":
        return getImages("wmv.png");
      case "mp3":
        return getImages("mp3.png");
      case "wav":
        return getImages("wav.png");
      case "ogg":
        return getImages("ogg.png");
      case "xlsx":
        return getImages("xlsx.png");

      case "csv":
        return getImages("xlsx.png");

      default:
        return getImages("image-icon.webp"); // Default icon for other file types
    }
  }
  // Function to get file view based on file type
  function getFileTypeView(document) {
    const fileType = document.name.split(".").pop().toLowerCase();

    switch (fileType) {
      case "pdf":
        return (
          <embed
            src={getImages("pdf_img.png")}
            type="application/pdf"
            style={{
              width: "100%",
              height: "180px",
            }}
          />
        );
      case "mp4":
        return (
          <video
            controls
            style={{
              width: "100%",
              height: "180px",
            }}
          >
            <source src={document.viewUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        );
      default:
        return <div>Unsupported file type</div>;
    }
  }

  const theme = useTheme();
  const dispatch = useDispatch();
  // getting userId from localStorage
  const userId = localStorage.getItem("userId");
  const userType = localStorage.getItem("userType")
  const [anchorElFileDropdown, setAnchorElFileDropdown] = useState(null);
  const openFileDropdown = Boolean(anchorElFileDropdown);
  const handleFileDropdownClick = (event) => {
    setAnchorElFileDropdown(event.currentTarget);
  };
  const handleFileDropdownClose = () => {
    setAnchorElFileDropdown(null);
  };
  // Open Other Options
  const [anchorElOtherOptions, setAnchorElOtherOptions] = useState(null);
  const openOtherOptions = Boolean(anchorElOtherOptions);
  // const [documentId, setDocumentId] = useState("");
  const [folderId, setFolderDocumentId] = useState("");
  const [folderName, setFolderDocumentName] = useState("");
  const [documentName, setDocumentName] = useState("");
  const [documentStatus, setDocumentStatus] = useState();
  const [documentUrl, setDocumentUrl] = useState("");
  const [parentId, setParentId] = useState("");

  const handleOtherOptionsClick = (
    event,
    documentId,
    documentName,
    documentStatus,
    documentUrl,
    parentId
  ) => {
    setAnchorElOtherOptions(event.currentTarget);
    setFolderDocumentId(documentId);
    setFolderDocumentName(documentName);
    setDocumentStatus(documentStatus);
    setDocumentUrl(documentUrl);
    setParentId(parentId);
    dispatch(getDocumentStatus(documentStatus));
    dispatch(getFileId(documentId));
    dispatch(getFileName(documentName));
  };
  const handleDoubleTap = (documentName, documentUrl) => {
    setTimeout(() => {
      handleDownload();
    }, 100);
  };
  const handleOtherOptionsClose = () => {
    setAnchorElOtherOptions(null);
  };
  // Share Solution Chat
  const [openShareChat, setOpenShareChat] = useState(false);
  const shareChatOpen = () => {
    setOpenShareChat(true);
    setOpenSelectedBoard(false);
  };
  const closeShareChat = () => {
    setOpenShareChat(false);
  };

  // Preview logic
  //  function handlePreview(documentId, documentName , viewUrl) {
  //     const fileUrl = `${viewUrl}/${documentId}`; // Replace with your actual file URL generation logic
  //     const previewWindow = window.open('', '_blank');
  //     previewWindow.document.write(`<iframe src="${fileUrl}" width="100%" height="500"></iframe>`);
  //   }

  //   Download logic
  const handleDownload = async () => {
    try {
      // Fetch the file from the server
      const response = await fetch(documentUrl);

      // Check if the response is ok
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      // Create a blob from the response
      const blob = await response.blob();

      // Create a link element
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");

      // Set the href of the link to the blob URL
      link.href = url;

      // Choose the filename for the downloaded file
      link.download = documentName || "downloaded-file"; // Ensure documentName is a string

      // Append the link to the body
      document.body.appendChild(link);

      // Trigger the download
      link.click();

      // Clean up and remove the link from the DOM
      setTimeout(() => {
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url); // Free up memory
      }, 100); // Delay removal to ensure click event has been processed
    } catch (error) {
      console.error("Failed to download file:", error);
    }
  };

  // Open Rename
  const [openRename, setOpenRename] = useState(false);
  const handleRename = () => {
    setOpenRename(true);
  };
  const closeRename = () => {
    setOpenRename(false);
  };
  //   upload modal
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const handleOpenUploadModal = () => {
    setOpenUploadModal(true);
  };
  const handleCloseUploadModal = () => {
    setOpenUploadModal(false);
  };
  // Open Move
  const [openMove, setOpenMove] = useState(false);
  const [movedFolderId, setMovedFolderId] = useState(null);
  const handleMove = (folderId) => {
    setOpenMove(true);
    setMovedFolderId(folderId);
  };
  const closeMove = () => {
    setOpenMove(false);
  };
  // Open Share
  const [openShare, setOpenShare] = useState(false);
  const handleShare = () => {
    setOpenShare(true);
  };
  const closeShare = () => {
    setOpenShare(false);
  };
  // Delete folder alert
  // Delete list1Alert Modal
  const [openDeleteFolderAlert, setOpenDeleteFolderAlert] = useState(false);
  const deleteFileAlert = () => {
    setOpenDeleteFolderAlert(true);
  };
  const goBackFromAlertHandle = () => {
    setOpenDeleteFolderAlert(false);
  };
  // Share Selected Borad
  const [openSelectedBoard, setOpenSelectedBoard] = useState(false);
  const selectedBoardOpen = () => {
    setOpenSelectedBoard(true);
    setOpenShare(false);
  };
  const closeSelectedBoard = () => {
    setOpenSelectedBoard(false);
  };

  // const {
  //   data: driveItemData,
  //   error: isDriveFolderError,
  //   loading: isDriveFolderLoading,
  //   refetch,
  // } = useFetchDriveItemListQuery({
  //   userId: userId,
  //   parentId:documentId,
  //   searchParams: searchValue,
  // });
  const refetchDocument = useSelector((state) => state.refetch.refetchDocument);
  useEffect(() => {
    if (refetchDocument) {
      refetch().finally(() => {
        dispatch(resetRefetchDocument());
      });
    }
  }, [refetchDocument, refetch, dispatch]);

  //   Loader
  // useEffect(() => {
  //   if (isDriveFolderLoading) {
  //     dispatch(showLoader());
  //   } else {
  //     dispatch(hideLoader());
  //   }
  // }, [isDriveFolderLoading, dispatch]);

  return (
    <>
      <Box
        sx={{
          mt: 4,
          mb: 3,
        }}
      >
        <Typography
          variant="h6"
          sx={{
            display: "flex",
            alignItems: "center",
            color: "secondary.main",
            fontSize: "1.1rem",
            fontWeight: "700",
            mb: 1,
          }}
        >
          <ArrowDropDownIcon
            sx={{
              fontSize: "1.2rem",
            }}
          />
          All Files
          {/* <div
                        style={{
                            position: 'relative'
                        }}
                    >
                        <Button
                            id="basic-button1"
                            aria-controls={openFileDropdown ? 'basic-menu1' : undefined}
                            aria-haspopup="true"
                            aria-expanded={openFileDropdown ? 'true' : undefined}
                            onClick={handleFileDropdownClick}
                            sx={{
                                p: '10px 0',
                                minWidth: '10px',
                                width: '100%',
                                verticalAlign: '-5px',
                                display: 'flex',
                                justifyContent: 'space-between',
                                color: 'secondary.main'
                            }}
                        >
                            <MoreVertOutlinedIcon
                                sx={{
                                    fontSize: "1.2rem",
                                }}
                            />
                        </Button>
                        <Menu
                            id="basic-menu1"
                            anchorEl={anchorElFileDropdown}
                            open={openFileDropdown}
                            onClose={handleFileDropdownClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button1',
                            }}
                            sx={{
                                '& .MuiMenu-paper': {
                                    borderRadius: "4px",
                                    border: "none",
                                    minWidth: "210px",
                                    p: 0.5,
                                }
                            }}
                        >
                            <Paper
                                sx={{
                                    width: 210,
                                    maxWidth: '100%',
                                    background: "#fff",
                                    borderRadius: "10px",
                                    borderBottom: "none",
                                    boxShadow: "none",
                                    p: 0.5
                                }}>
                                <MenuItem onClick={handleFileDropdownClose}
                                    sx={{
                                        fontSize: "0.9rem",
                                        fontWeight: "600",
                                        lineHeight: "20px",
                                        color: "lightSecondary.main",
                                        py: 1,
                                        px: 1.4,
                                        '&:hover': {
                                            backgroundColor: 'extraLTheme.main'
                                        },
                                        '&.active': {
                                            backgroundColor: 'primary.main',
                                            color: "#fff",
                                            borderRadius: "10px"
                                        }
                                    }}
                                >All</MenuItem>
                                <MenuItem onClick={handleFileDropdownClose}
                                    sx={{
                                        fontSize: "0.9rem",
                                        fontWeight: "600",
                                        lineHeight: "20px",
                                        color: "lightSecondary.main",
                                        py: 1,
                                        px: 1.4,
                                        '&:hover': {
                                            backgroundColor: 'extraLTheme.main'
                                        },
                                        '&.active': {
                                            backgroundColor: 'primary.main',
                                            color: "#fff",
                                            borderRadius: "10px"
                                        }
                                    }}
                                >Starred</MenuItem>
                                <MenuItem onClick={handleFileDropdownClose}
                                    sx={{
                                        fontSize: "0.9rem",
                                        fontWeight: "600",
                                        lineHeight: "20px",
                                        color: "lightSecondary.main",
                                        py: 1,
                                        px: 1.4,
                                        '&:hover': {
                                            backgroundColor: 'extraLTheme.main'
                                        },
                                        '&.active': {
                                            backgroundColor: 'primary.main',
                                            color: "#fff",
                                            borderRadius: "10px"
                                        }
                                    }}
                                >Archived</MenuItem>
                                <MenuItem onClick={handleFileDropdownClose}
                                    sx={{
                                        fontSize: "0.9rem",
                                        fontWeight: "600",
                                        lineHeight: "20px",
                                        color: "lightSecondary.main",
                                        py: 1,
                                        px: 1.4,
                                        '&:hover': {
                                            backgroundColor: 'extraLTheme.main'
                                        },
                                        '&.active': {
                                            backgroundColor: 'primary.main',
                                            color: "#fff",
                                            borderRadius: "10px"
                                        }
                                    }}
                                >Recently deleted</MenuItem>
                            </Paper>
                        </Menu>
                    </div> */}
        </Typography>
        <ListItemButton
          onClick={handleOpenUploadModal}
          sx={{
            border: "solid 1px #A2C2F1",
            minWidth: "240px",
            maxWidth: "240px",
            background: "#ECF6FF",
            padding: "14px 20px 14px 20px",
            borderRadius: "10px",
            display: "inline-block",
            width: "auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            color: "lightSecondary.main",
            fontWeight: "600",
            fontSize: "1rem",
            flexGrow: "inherit",
            "@media (max-width:1600px) and (min-width:1200px)": {
              minWidth: "240px",
              maxWidth: "100%",
              width: "calc(20% - 15px)",
              padding: "14px 13px",
            },
            [theme.breakpoints.down("md")]: {
              minWidth: "auto",
              maxWidth: "100%",
              width: "calc(50% - 10px)",
            },
            [theme.breakpoints.down("sm")]: {
              width: "100%",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <FileUploadOutlinedIcon
              sx={{
                mr: 1.2,
                verticalAlign: "0px",
              }}
            />
            <Typography
              variant="h6"
              noWrap
              sx={{
                maxWidth: isDrawerOpen ? "150px" : "150px",
                "@media (max-width:1400px) and (min-width:1200px)": {
                  maxWidth: isDrawerOpen ? "140px" : "140px",
                },
                color: "lightSecondary.main",
                fontWeight: "600",
                fontSize: "1rem",
              }}
            >
              Upload Files
            </Typography>
          </Box>
        </ListItemButton>
        <List
          sx={{
            flexWrap: "wrap",
            gap: 2,
            display: "inline-flex",
            p: 0,
            width: "100%",
            marginTop: "20px",
          }}
        >
          {driveItemData && driveItemData?.data?.documents.length > 0 ? (
            driveItemData?.data?.documents
              .filter((document) => document.isDocument === false) // Filter out items that are not documents
              .map((document, index) => {
                const fileExtension = document.name
                  ? document.name.split(".").pop().toLowerCase()
                  : "";
                const isImage = ["jpg", "jpeg", "png"].includes(fileExtension);
                const isPDF = fileExtension === "pdf";
                const isGif = fileExtension === "gif";
                const isDocx = fileExtension === "docx";
                const isDoc = fileExtension === "doc";
                const isMp4 = fileExtension === "mp4";
                const isAvi = fileExtension === "avi";
                const isMov = fileExtension === "mov";
                const isWmv = fileExtension === "wmv";
                const isMp3 = fileExtension === "mp3";
                const isWav = fileExtension === "wav";
                const isOgg = fileExtension === "ogg";
                const xlsx = fileExtension === "xlsx";
                const csv = fileExtension === "csv";

                return (
                  <ListItemButton
                    onDoubleClick={() => handleDoubleTap()}
                    onClick={() => {
                      setDocumentName(document.name);
                      setDocumentUrl(document.s3Url);
                    }}
                    key={document._id}
                    sx={{
                      border: "solid 1px #A2C2F1",
                      minWidth: "60px",
                      maxWidth: "60px",
                      background: "#ECF6FF",
                      padding: "8px 5px 8px 5px",
                      borderRadius: "10px",
                      display: "inline-block",
                      // width: "auto",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      color: "lightSecondary.main",
                      fontWeight: "600",
                      fontSize: "1rem",
                      flexGrow: "inherit",
                      "@media (max-width:2560px) and (min-width:1200px)": {
                        minWidth: "150px",
                        maxWidth: "100%",
                        // width: "calc(20% - 15px)",
                        padding: "8px 5px",
                      },
                      "@media (max-width:1200px) and (min-width:900px)": {
                        minWidth: "150px",
                        maxWidth: "100%",
                        // width: "calc(20% - 15px)",
                        padding: "8px 5px",
                      },
                      [theme.breakpoints.down("md")]: {
                        minWidth: "150px",
                        maxWidth: "45%",
                        width: '100%'
                        // width: "calc(50% - 10px)",
                      },
                      [theme.breakpoints.down("sm")]: {
                        width: "100%",
                        maxWidth: '100%'
                      },
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <ListItemAvatar
                        sx={{
                          textAlign: "center",
                          minWidth: "30px",
                          mixBlendMode: "multiply",
                        }}
                      >
                        <img
                          style={{
                            maxWidth: "26px",
                            maxHeight: "26px",
                            display: "block",
                          }}
                          // src={
                          //   document.name
                          //     ? getIconForFileType(
                          //         document.name.split(".").pop()
                          //       )
                          //     : "path/to/default/icon.png"
                          // }
                          src={
                            isImage
                              ? getImages("image-icon.webp")
                              : isPDF
                              ? getImages("pdfImage.webp") // Replace with your PDF icon path
                              : isGif
                              ? getImages("image-icon.webp")
                              : isDocx
                              ? getImages("docxIcon.png")
                              : isDoc
                              ? getImages("doc_icon.png")
                              : isMp4
                              ? getImages("mp4.png")
                              : isAvi
                              ? getImages("avi.png")
                              : isMov
                              ? getImages("mov.png")
                              : isWav
                              ? getImages("wav.png")
                              : isMp3
                              ? getImages("mp3.png")
                              : // : isWav
                              // ? getImages("wav.png")
                              isOgg
                              ? getImages("ogg.png")
                              : xlsx
                              ? getImages("xlsx.png")
                              : csv
                              ? getImages("xlsx.png")
                              : getImages("image-icon.webp") // Replace with your default icon path
                          }
                          alt={document.name || "Document Image"}
                        />
                      </ListItemAvatar>
                      <Typography
                        variant="h6"
                        noWrap
                        sx={{
                          maxWidth: isDrawerOpen ? "100px" : "100px",
                          "@media (max-width:2560px) and (min-width:1200px)": {
                            maxWidth: isDrawerOpen ? "100px" : "100px",
                          },
                          "@media (max-width:1200px) and (min-width:900px)": {
                            maxWidth: isDrawerOpen ? "80px" : "80px",
                          },
                          color: "lightSecondary.main",
                          fontWeight: "600",
                          fontSize: "0.7rem",
                        }}
                      >
                        <Tooltip title={document.name}>
                          {" "}
                          {document.name}
                        </Tooltip>
                      </Typography>
                    </Box>
                    <Typography sx={{ display: "flex" }}>
                      <div style={{ position: "relative" }}>
                        <Button
                          id="basic-button1"
                          aria-controls={
                            openOtherOptions ? "basic-menu1" : undefined
                          }
                          aria-haspopup="true"
                          aria-expanded={openOtherOptions ? "true" : undefined}
                          onClick={(event) =>
                            handleOtherOptionsClick(
                              event,
                              document._id,
                              document.name,
                              document.isDocument,
                              document.s3Url,
                              document.parent_id
                            )
                          }
                          sx={{
                            p: "0 0",
                            minWidth: "10px",
                            width: "100%",
                            verticalAlign: "-5px",
                            display: "flex",
                            justifyContent: "space-between",
                            color: "secondary.main",
                          }}
                        >
                          <MoreVertOutlinedIcon
                            sx={{ verticalAlign: "0px", fontSize: "1.1rem" }}
                          />
                        </Button>
                        <Menu
                          // id="basic-menu1"
                          id={`${document._id}${document.name}`}
                          key={`${document._id}${document.name}`}
                          anchorEl={anchorElOtherOptions}
                          open={openOtherOptions}
                          onClose={handleOtherOptionsClose}
                          MenuListProps={{
                            "aria-labelledby": "basic-button1",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          sx={{
                            "& .MuiMenu-paper": {
                              borderRadius: "4px",
                              border: "none",
                              minWidth: "160px",
                              p: 0.5,
                            },
                          }}
                        >
                          <Paper
                            sx={{
                              width: 120,
                              maxWidth: "100%",
                              background: "#fff",
                              borderRadius: "10px",
                              borderBottom: "none",
                              boxShadow: "none",
                              p: 0.5,
                            }}
                          >
                            {
                              userType !== "solution_provider"?
                            <MenuItem
                              onClick={() => {
                                handleOtherOptionsClose();
                                handleShare();
                              }}
                              sx={{
                                fontSize: "0.9rem",
                                fontWeight: "600",
                                lineHeight: "20px",
                                color: "lightSecondary.main",
                                py: 1,
                                px: 1.4,
                                "&:hover": {
                                  backgroundColor: "extraLTheme.main",
                                },
                                "&.active": {
                                  backgroundColor: "primary.main",
                                  color: "#fff",
                                  borderRadius: "10px",
                                },
                              }}
                            >
                              <PersonAddAltOutlinedIcon sx={{ mr: 1 }} /> Share
                            </MenuItem>
                            :
                            <></>
                            }
                            
                            <MenuItem
                              onClick={() => {
                                handleMove();
                                handleOtherOptionsClose();
                              }}
                              sx={{
                                fontSize: "0.9rem",
                                fontWeight: "600",
                                lineHeight: "20px",
                                color: "lightSecondary.main",
                                py: 1,
                                px: 1.4,
                                "&:hover": {
                                  backgroundColor: "extraLTheme.main",
                                },
                                "&.active": {
                                  backgroundColor: "primary.main",
                                  color: "#fff",
                                  borderRadius: "10px",
                                },
                              }}
                            >
                              <DriveFileMoveOutlinedIcon sx={{ mr: 1 }} /> Move
                              to
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                handleRename();
                                handleOtherOptionsClose();
                              }}
                              sx={{
                                fontSize: "0.9rem",
                                fontWeight: "600",
                                lineHeight: "20px",
                                color: "lightSecondary.main",
                                py: 1,
                                px: 1.4,
                                "&:hover": {
                                  backgroundColor: "extraLTheme.main",
                                },
                                "&.active": {
                                  backgroundColor: "primary.main",
                                  color: "#fff",
                                  borderRadius: "10px",
                                },
                              }}
                            >
                              <BorderColorOutlinedIcon sx={{ mr: 1 }} /> Rename
                            </MenuItem>
                            <MenuItem
                              sx={{
                                fontSize: "0.9rem",
                                fontWeight: "600",
                                lineHeight: "20px",
                                color: "lightSecondary.main",
                                py: 1,
                                px: 1.4,
                                "&:hover": {
                                  backgroundColor: "extraLTheme.main",
                                },
                                "&.active": {
                                  backgroundColor: "primary.main",
                                  color: "#fff",
                                  borderRadius: "10px",
                                },
                              }}
                              onClick={() => {
                                handleOtherOptionsClose();
                                handleDownload(
                                  document._id,
                                  document.name,
                                  document.s3Url
                                );
                              }}
                            >
                              <FileDownloadOutlinedIcon sx={{ mr: 1 }} />{" "}
                              Download
                            </MenuItem>
                            <MenuItem
                              sx={{
                                fontSize: "0.9rem",
                                fontWeight: "600",
                                lineHeight: "20px",
                                color: "lightSecondary.main",
                                py: 1,
                                px: 1.4,
                                "&:hover": {
                                  backgroundColor: "extraLTheme.main",
                                },
                                "&.active": {
                                  backgroundColor: "primary.main",
                                  color: "#fff",
                                  borderRadius: "10px",
                                },
                              }}
                              onClick={() => {
                                handleOtherOptionsClose();
                                deleteFileAlert();
                              }}
                            >
                              <DeleteOutlineOutlinedIcon sx={{ mr: 1 }} />{" "}
                              Delete
                            </MenuItem>
                          </Paper>
                        </Menu>
                      </div>
                    </Typography>
                    {/* <Box
                      sx={{
                        borderTop: "solid 1px #A2C2F1",
                        background: "#ECF6FF",
                        padding: "14px 10px",
                        borderRadius: "0 0 10px 10px",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        color: "lightSecondary.main",
                        fontWeight: "600",
                        fontSize: "1rem",
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <ListItemAvatar
                          sx={{ textAlign: "center", minWidth: "30px" , mixBlendMode: "multiply"}}
                        >
                          <img
                            style={{
                              maxWidth: "26px",
                              maxHeight: "26px",
                              display: "block",
                            }}
                            src={
                              document.name
                                ? getIconForFileType(
                                    document.name.split(".").pop()
                                  )
                                : "path/to/default/icon.png"
                            }
                            alt={document.name}
                          />
                        </ListItemAvatar>
                        <Typography
                          variant="h6"
                          noWrap
                          sx={{
                            maxWidth: "120px",
                            color: "lightSecondary.main",
                            fontWeight: "600",
                            fontSize: "1rem",
                          }}
                        >
                          {document.name}
                        </Typography>
                      </Box>
                      <Typography sx={{ display: "flex" }}>
                        <div style={{ position: "relative" }}>
                          <Button
                            id="basic-button1"
                            aria-controls={
                              openOtherOptions ? "basic-menu1" : undefined
                            }
                            aria-haspopup="true"
                            aria-expanded={
                              openOtherOptions ? "true" : undefined
                            }
                            onClick={(event) =>
                              handleOtherOptionsClick(
                                event,
                                document._id,
                                document.name,
                                document.isDocument,
                                document.s3Url,
                                document.parent_id,
                              )
                            }
                            sx={{
                              p: "0 0",
                              minWidth: "10px",
                              width: "100%",
                              verticalAlign: "-5px",
                              display: "flex",
                              justifyContent: "space-between",
                              color: "secondary.main",
                            }}
                          >
                            <MoreVertOutlinedIcon
                              sx={{ verticalAlign: "0px", fontSize: "1.1rem" }}
                            />
                          </Button>
                          <Menu
                            // id="basic-menu1"
                            id={`${document._id}${document.name}`}
                            key={`${document._id}${document.name}`}
                            anchorEl={anchorElOtherOptions}
                            open={openOtherOptions}
                            onClose={handleOtherOptionsClose}
                            MenuListProps={{
                              "aria-labelledby": "basic-button1",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                            sx={{
                              "& .MuiMenu-paper": {
                                borderRadius: "4px",
                                border: "none",
                                minWidth: "260px",
                                p: 0.5,
                              },
                            }}
                          >
                            <Paper
                              sx={{
                                width: 260,
                                maxWidth: "100%",
                                background: "#fff",
                                borderRadius: "10px",
                                borderBottom: "none",
                                boxShadow: "none",
                                p: 0.5,
                              }}
                            >
                              <MenuItem
                                onClick={() => {
                                  handleOtherOptionsClose();
                                  handleShare();
                                }}
                                sx={{
                                  fontSize: "0.9rem",
                                  fontWeight: "600",
                                  lineHeight: "20px",
                                  color: "lightSecondary.main",
                                  py: 1,
                                  px: 1.4,
                                  "&:hover": {
                                    backgroundColor: "extraLTheme.main",
                                  },
                                  "&.active": {
                                    backgroundColor: "primary.main",
                                    color: "#fff",
                                    borderRadius: "10px",
                                  },
                                }}
                              >
                                <PersonAddAltOutlinedIcon sx={{ mr: 1 }} />{" "}
                                Share
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  handleMove();
                                  handleOtherOptionsClose();
                                }}
                                sx={{
                                  fontSize: "0.9rem",
                                  fontWeight: "600",
                                  lineHeight: "20px",
                                  color: "lightSecondary.main",
                                  py: 1,
                                  px: 1.4,
                                  "&:hover": {
                                    backgroundColor: "extraLTheme.main",
                                  },
                                  "&.active": {
                                    backgroundColor: "primary.main",
                                    color: "#fff",
                                    borderRadius: "10px",
                                  },
                                }}
                              >
                                <DriveFileMoveOutlinedIcon sx={{ mr: 1 }} />{" "}
                                Move to
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  handleRename();
                                  handleOtherOptionsClose();
                                }}
                                sx={{
                                  fontSize: "0.9rem",
                                  fontWeight: "600",
                                  lineHeight: "20px",
                                  color: "lightSecondary.main",
                                  py: 1,
                                  px: 1.4,
                                  "&:hover": {
                                    backgroundColor: "extraLTheme.main",
                                  },
                                  "&.active": {
                                    backgroundColor: "primary.main",
                                    color: "#fff",
                                    borderRadius: "10px",
                                  },
                                }}
                              >
                                <BorderColorOutlinedIcon sx={{ mr: 1 }} />{" "}
                                Rename
                              </MenuItem>
                              <MenuItem
                                sx={{
                                  fontSize: "0.9rem",
                                  fontWeight: "600",
                                  lineHeight: "20px",
                                  color: "lightSecondary.main",
                                  py: 1,
                                  px: 1.4,
                                  "&:hover": {
                                    backgroundColor: "extraLTheme.main",
                                  },
                                  "&.active": {
                                    backgroundColor: "primary.main",
                                    color: "#fff",
                                    borderRadius: "10px",
                                  },
                                }}
                                onClick={() => {
                                  handleOtherOptionsClose();
                                  handleDownload(
                                    document._id,
                                    document.name,
                                    document.s3Url,

                                  );
                                }}
                              >
                                <FileDownloadOutlinedIcon sx={{ mr: 1 }} />{" "}
                                Download
                              </MenuItem>
                              <MenuItem
                                sx={{
                                  fontSize: "0.9rem",
                                  fontWeight: "600",
                                  lineHeight: "20px",
                                  color: "lightSecondary.main",
                                  py: 1,
                                  px: 1.4,
                                  "&:hover": {
                                    backgroundColor: "extraLTheme.main",
                                  },
                                  "&.active": {
                                    backgroundColor: "primary.main",
                                    color: "#fff",
                                    borderRadius: "10px",
                                  },
                                }}
                                onClick={() => {
                                  handleOtherOptionsClose();
                                  deleteFileAlert();
                                }}
                              >
                                <DeleteOutlineOutlinedIcon sx={{ mr: 1 }} />{" "}
                                Delete
                              </MenuItem>
                             
                            </Paper>
                          </Menu>
                        </div>
                      </Typography>
                    </Box> */}
                  </ListItemButton>
                );
              })
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center", // Center horizontally
                alignItems: "center", // Center vertically
                height: "100%", // Take full available height
                width: "100%", // Take full available width
              }}
            >
              {/* <img
                src={getImages("noDataImage.gif")}
                alt="No Data Found"
                style={{
                  maxWidth: "100%",
                  maxHeight: "calc(100vh - 110px)",
                  mixBlendMode: "multiply",
                }}
              /> */}
            </Box>
          )}
        </List>
      </Box>
      <Modals open={openRename} modalWidth={480}>
        <RenameModal
          documentId={folderId}
          documentName={folderName}
          handleClose={closeRename}
          showToastMessage={showToastMessage}
        />
      </Modals>
      <Modals open={openMove} handleClose={closeMove} modalWidth={395}>
        <MoveModal
          handleClose={closeMove}
          documentId={folderId}
          documentName={folderName}
          showToastMessage={showToastMessage}
          parentId={parentId}
        />
      </Modals>
      <Modals open={openShare} handleClose={closeShare} modalWidth={480}>
        <ShareModal
          selectedBoardOpen={selectedBoardOpen}
          documentId={documentId}
          documentName={documentName}
          showToastMessage={showToastMessage}
        />
      </Modals>
      <Modals
        open={openSelectedBoard}
        handleClose={closeSelectedBoard}
        modalWidth={480}
      >
        <ShareSelectedBoardModal
          handleClose={closeSelectedBoard}
          shareChatOpen={shareChatOpen}
          showToastMessage={showToastMessage}
        />
      </Modals>
      <Modals
        open={openShareChat}
        handleClose={closeShareChat}
        modalWidth={480}
      >
        <ShareSolutionChatModal
          showToastMessage={showToastMessage}
          handleClose={closeShareChat}
        />
      </Modals>
      <Modals
        open={openDeleteFolderAlert}
        handleClose={goBackFromAlertHandle}
        modalWidth={500}
      >
        <DeleteFolderAlert
          handleClose={goBackFromAlertHandle}
          documentId={folderId}
          goBackFromAlertHandle={goBackFromAlertHandle}
          showToastMessage={showToastMessage}
        />
      </Modals>
      <Modals
        open={openUploadModal}
        handleClose={handleCloseUploadModal}
        modalWidth={500}
      >
        <FileUpload
          handleClose={handleCloseUploadModal}
          handleCloseUploadModal={handleCloseUploadModal}
          showToastMessage={showToastMessage}
          documentId={documentId}
        />
      </Modals>
    </>
  );
};

export default DriveFiles;
