import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = "https://www.blueskyintel.com/";
const updateUserPassword = createApi({
    reducerPath: 'updateUserPassword',
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers) => {
            // Add any headers you need here
            return headers;
        },
    }),
    endpoints: (builder) => ({
        updateUserPassword: builder.mutation({
            query: ({  userId , currentPassword , newPassword , confirmPassword }) => ({
                url:`api/user/update-password`,
                method: 'POST',
                body: { 
                    user_id: userId,
                    current_password: currentPassword,
                    new_password: newPassword, 
                    new_confirm_password: confirmPassword,
             },
            }),
            transformResponse: (response) => {
                return response;
            },
        }),
    }),
});

export const { useUpdateUserPasswordMutation } = updateUserPassword;
export default updateUserPassword;
