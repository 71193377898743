import React from 'react'
import {
    Box,
    ListItemText,
    Typography,
    ListItemAvatar,
    Avatar,
    Button,
    IconButton,
    TextField,
    Divider
} from '@mui/material';
import { useTheme } from '@emotion/react';
import { getImages } from '../../commonComponents/const';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import MicIcon from '@mui/icons-material/Mic';
import SendIcon from '@mui/icons-material/Send';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

const ThreadChatContainer = ({ closeThreadChat }) => {
    const theme = useTheme();
    return (
        <>
            <Box>
                {/* Chat header */}
                <Box
                    sx={{
                        backgroundColor: 'extraLTheme.main',
                        borderRadius: '10px 10px 0 0',
                        p: 2,
                        display: 'flex'
                    }}
                >
                    <IconButton onClick={closeThreadChat}>
                        <KeyboardBackspaceIcon
                            sx={{
                                fontSize: '30px',
                                color: 'lightSecondary.main'
                            }}
                        />
                    </IconButton>
                    <ListItemText
                        sx={{
                            pl: '10px',
                            width: "60%"
                        }}
                        primary={
                            <Typography noWrap
                                sx={{
                                    fontSize: "1rem",
                                    fontWeight: '600',
                                    color: 'secondary.main',
                                    lineHeight: '22px',
                                    maxWidth: '220px',
                                    [theme.breakpoints.down('sm')]: {
                                        maxWidth: '110px'
                                    }
                                }}
                                variant="h6">Thread
                            </Typography>}
                        secondary={<Typography
                            variant="body2"
                            sx={{
                                fontSize: '12px',
                                fontWeight: '500',
                                color: 'primary.main',
                            }}
                        >
                            Solution discussion
                        </Typography>}
                    />
                </Box>
                {/* Chat Body */}
                <Box
                    sx={{
                        position: 'relative',
                        overflowY: 'auto',
                        pr: 1,
                        maxHeight: 'calc(100vh - 265px)',
                        minHeight: 'calc(100vh - 267px)',
                        '&::-webkit-scrollbar': {
                            width: '6px',
                            borderRadius: '4px'
                        },
                        '&::-webkit-scrollbar-track': {
                            backgroundColor: 'transparent',
                            borderRadius: '4px'
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: '#9C9C9C',
                            borderRadius: '4px'
                        },
                        [theme.breakpoints.down('lg')]: {
                            maxHeight: 'calc(100vh - 224px)',
                            minHeight: 'calc(100vh - 246px)',
                        },
                        [theme.breakpoints.down('md')]: {
                            maxHeight: 'calc(100vh - 251px)',
                            minHeight: 'calc(100vh - 253px)',
                        }
                    }}
                >

                    <Box
                        sx={{
                            textAlign: 'left',
                            pl: 2,
                            py: 2,
                            display: 'flex'
                        }}>
                        <ListItemAvatar
                            sx={{
                                width: "42px"
                            }}
                        >
                            <Avatar
                                sx={{
                                    color: 'success.main',
                                    bgcolor: 'success.lighter',
                                    width: '40px',
                                    height: '40px'
                                }}
                                src={getImages('1.jpg')}
                            >
                            </Avatar>
                        </ListItemAvatar>
                        <Box
                            sx={{
                                backgroundColor: 'extraLTheme.main',
                                borderRadius: '0px 10px 10px 10px',
                                p: "10px 15px",
                                maxWidth: '60%',
                                position: 'relative',
                                display: 'inline-block',
                                textAlign: 'left',
                                '&:before': {
                                    content: '""',
                                    position: 'absolute',
                                    left: '-16px',
                                    top: '0',
                                    borderRight: 'solid 16px #ECF6FF',
                                    borderBottom: 'solid 16px transparent'
                                },
                                [theme.breakpoints.down('md')]: {
                                    maxWidth: '100%',
                                    width: 'calc(100% - 70px)',
                                }
                            }}
                        >
                            <Typography variant='body2'
                                sx={{
                                    fontSize: '0.9rem',
                                    color: '#419C2A',
                                    fontWeight: '600'
                                }}>
                                Theresa Green
                            </Typography>
                            <Typography variant='body2'
                                sx={{
                                    fontSize: '0.9rem',
                                    color: 'lightSecondary.main',
                                    fontWeight: '500'
                                }}
                            >Hey team, Should I schedule a meeting tomorrow?</Typography>
                            <Typography variant='body2'
                                sx={{
                                    fontSize: '12px',
                                    color: 'ltheme.main',
                                    fontWeight: '500',
                                    textAlign: 'right'
                                }}
                            >11:48 AM</Typography>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center'
                        }}
                        >

                        <Typography variant='body2'
                            sx={{
                                fontSize: '13px',
                                fontWeight: '500',
                                color: '#A2C2F1'
                            }}
                        >3 replies</Typography>
                        <Box
                            sx={{
                                width:'calc(100% - 100px)',
                                pl:1.5
                            }}
                        >
                            <Divider
                                sx={{
                                    borderColor: 'ltheme.main',
                                }}
                            />
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            textAlign: 'left',
                            pl: 2,
                            py: 2,
                            display: 'flex'
                        }}>
                        <ListItemAvatar
                            sx={{
                                width: "42px"
                            }}
                        >
                            <Avatar
                                sx={{
                                    color: 'success.main',
                                    bgcolor: 'success.lighter',
                                    width: '40px',
                                    height: '40px'
                                }}
                                src={getImages('1.jpg')}
                            >
                            </Avatar>
                        </ListItemAvatar>
                        <Box
                            sx={{
                                backgroundColor: 'extraLTheme.main',
                                borderRadius: '0px 10px 10px 10px',
                                p: "10px 15px",
                                maxWidth: '60%',
                                position: 'relative',
                                display: 'inline-block',
                                textAlign: 'left',
                                '&:before': {
                                    content: '""',
                                    position: 'absolute',
                                    left: '-16px',
                                    top: '0',
                                    borderRight: 'solid 16px #ECF6FF',
                                    borderBottom: 'solid 16px transparent'
                                },
                                [theme.breakpoints.down('md')]: {
                                    maxWidth: '100%',
                                    width: 'calc(100% - 70px)',
                                }
                            }}
                        >
                            <Typography variant='body2'
                                sx={{
                                    fontSize: '0.9rem',
                                    color: '#C3A400',
                                    fontWeight: '600'
                                }}>
                                Ivan Itchinos
                            </Typography>
                            <Typography variant='body2'
                                sx={{
                                    fontSize: '0.9rem',
                                    color: 'lightSecondary.main',
                                    fontWeight: '500'
                                }}
                            >What will be the time for it?</Typography>
                            <Typography variant='body2'
                                sx={{
                                    fontSize: '12px',
                                    color: 'ltheme.main',
                                    fontWeight: '500',
                                    textAlign: 'right'
                                }}
                            >11:48 AM</Typography>
                        </Box>
                    </Box>
                    {/* Sender message */}
                    <Box
                        sx={{
                            textAlign: 'right',
                            pr: 2,
                            py: 2
                        }}>
                        <Box
                            sx={{
                                backgroundColor: 'lightSecondary.main',
                                borderRadius: '10px 0px 10px 10px',
                                p: "10px 15px",
                                width: '60%',
                                position: 'relative',
                                display: 'inline-block',
                                textAlign: 'left',
                                '&:before': {
                                    content: '""',
                                    position: 'absolute',
                                    right: '-16px',
                                    top: '0',
                                    borderLeft: 'solid 16px #2A589C',
                                    borderBottom: 'solid 16px transparent'
                                },
                                [theme.breakpoints.down('md')]: {
                                    width: 'calc(100% - 70px)',
                                }
                            }}
                        >
                            <Typography variant='body2'
                                sx={{
                                    fontSize: '0.9rem',
                                    color: '#fff',
                                    fontWeight: '500'
                                }}
                            >Hey Theresa, <br />Sure let’s have it tomorrow morning?</Typography>
                            <Typography variant='body2'
                                sx={{
                                    fontSize: '12px',
                                    color: 'ltheme.main',
                                    fontWeight: '500',
                                    textAlign: 'right'
                                }}
                            >11:48 AM</Typography>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            textAlign: 'left',
                            pl: 2,
                            py: 2,
                            display: 'flex'
                        }}>
                        <ListItemAvatar
                            sx={{
                                width: "42px"
                            }}
                        >
                            <Avatar
                                sx={{
                                    color: 'success.main',
                                    bgcolor: 'success.lighter',
                                    width: '40px',
                                    height: '40px'
                                }}
                                src={getImages('1.jpg')}
                            >
                            </Avatar>
                        </ListItemAvatar>
                        <Box
                            sx={{
                                backgroundColor: 'extraLTheme.main',
                                borderRadius: '0px 10px 10px 10px',
                                p: "10px 15px",
                                maxWidth: '60%',
                                position: 'relative',
                                display: 'inline-block',
                                textAlign: 'left',
                                '&:before': {
                                    content: '""',
                                    position: 'absolute',
                                    left: '-16px',
                                    top: '0',
                                    borderRight: 'solid 16px #ECF6FF',
                                    borderBottom: 'solid 16px transparent'
                                },
                                [theme.breakpoints.down('md')]: {
                                    maxWidth: '100%',
                                    width: 'calc(100% - 70px)',
                                }
                            }}
                        >
                            <Typography variant='body2'
                                sx={{
                                    fontSize: '0.9rem',
                                    color: '#9C2A2A',
                                    fontWeight: '600'
                                }}>
                                Vish Strophobia
                            </Typography>
                            <Typography variant='body2'
                                sx={{
                                    fontSize: '0.9rem',
                                    color: 'lightSecondary.main',
                                    fontWeight: '500'
                                }}
                            >Yes I’m available.</Typography>
                            <Typography variant='body2'
                                sx={{
                                    fontSize: '12px',
                                    color: 'ltheme.main',
                                    fontWeight: '500',
                                    textAlign: 'right'
                                }}
                            >11:50 AM</Typography>
                        </Box>
                    </Box>
                </Box>
                {/* Chat Footer */}
                <Box
                    sx={{
                        backgroundColor: 'extraLTheme.main',
                        borderStyle: 'solid',
                        borderWidth: '1px',
                        borderColor: 'ltheme.main',
                        p: 1.5,
                        borderRadius: '0 0 10px 10px',
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >

                    <Button variant="none"
                        sx={{
                            textTransform: 'inherit',
                            p: "8px 8px",
                            minWidth: 'inherit',
                            boxShadow: 'none'
                        }}>
                        <SentimentSatisfiedAltIcon
                            sx={{
                                color: "lightSecondary.main",
                                fontSize: "20px"
                            }}
                        />
                    </Button>
                    <Button variant="none"
                        sx={{
                            textTransform: 'inherit',
                            p: "8px 8px",
                            minWidth: 'inherit',
                            boxShadow: 'none'
                        }}>
                        <AttachFileIcon
                            sx={{
                                color: "lightSecondary.main",
                                fontSize: "20px"
                            }}
                        />
                    </Button>
                    <TextField id="outlined-basic" label="" variant="outlined" placeholder='Type a message'
                        sx={{
                            width: 'calc(100% - 153px)',
                            '& .MuiOutlinedInput-notchedOutline': {
                                border: 'none'
                            },
                            '& .MuiInputBase-input': {
                                padding: '0px 10px',
                                fontSize: '0.95rem',
                                color: 'lightSecondary.main',
                                fontWeight: '600'
                            }
                        }}
                    />
                    <Button variant="none"
                        sx={{
                            textTransform: 'inherit',
                            p: "8px 8px",
                            minWidth: 'inherit',
                            boxShadow: 'none',
                            borderRadius: '0',
                            borderRight: 'solid 1px',
                            borderRightColor: 'lightSecondary.main'
                        }}>
                        <MicIcon
                            sx={{
                                color: "lightSecondary.main",
                                fontSize: "20px"
                            }}
                        />
                    </Button>
                    <Button variant="contained"
                        sx={{
                            textTransform: 'inherit',
                            p: "8px 8px",
                            backgroundColor: 'lightSecondary.main',
                            minWidth: 'inherit',
                            boxShadow: 'none',
                            ml: 1
                        }}>
                        <SendIcon
                            sx={{
                                color: "#fff",
                                fontSize: "20px"
                            }}
                        />
                    </Button>
                </Box>
            </Box>
        </>
    )
}

export default ThreadChatContainer