import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  Typography,
  TextField,
  Grid,
  Button,
  Stack,
  IconButton,
} from "@mui/material";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import MainCard from "../MainCard";
import { useTheme } from "@emotion/react";
import { Link } from "react-router-dom";
import { useFetchCountryListQuery } from "../../redux/api/Users/getCountryList";
import { useFetchIndustryListQuery } from "../../redux/api/Industry/getIndustry";
import { useFetchUseCaseListQuery } from "../../redux/api/usecase/getUseCase";
import { toast } from "react-toastify";
import { useFetchMasterDetailsQuery } from "../../redux/api/Users/master";
import CustomLoader from "../../utils/Loader/CustomLoader";
import { useFetchBusinessUserDetailsQuery } from "../../redux/api/Users/getuserProfile";
import { useDispatch, useSelector } from "react-redux";
import Flag from "react-world-flags";
import { hideLoader, showLoader } from "../../redux/features/loader";
import { useUpdateBusinessUserProfileMutation } from "../../redux/api/Users/updateBusinessProfile";

const updateBusinessProfile = ({ nextStep, showToastMessage }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const storedUserType = localStorage.getItem("userType");
  // If storedUserType is null or "null", set businessUserType to "business_user"
  const userId = localStorage.getItem("userId");
  // api call userProfileData
  const {
    data: userProfileData,
    isLoading: userLoading,
    isError: userError,
    refetch,
  } = useFetchBusinessUserDetailsQuery(userId);
  const { data } = userProfileData || {};
  const user = data?.user_id || {};
  // businessName
  const [businessName, setBusinessName] = useState(data?.business_name || "");
  const handleBusinessName = (event) => {
    const inputValue = event.target.value.trim();
    setBusinessName(inputValue);
  };
  // businessUrl
  const [businessUrl, setBusinessUrl] = useState(data?.business_url || "");
  const handleBusinessUrl = (event) => {
    const inputValue = event.target.value.trim();
    setBusinessUrl(inputValue);
  };

  // LinkedinUrl
  const [linkedinUrl, setLinkedinUrl] = useState(data?.linkedin_url || "");
  const handleLinkedinUrl = (event) => {
    const inputValue = event.target.value.trim();
    setLinkedinUrl(inputValue);
  };


  // countryCode
  const [countryCode, setCountryCode] = useState(
    data?.country_id?.phone_code || ""
  );
  const handleCountryCode = (event) => {
    const inputValue = event.target.value.trim();
    setCountryCode(inputValue);
  };
  // phoneNumber
  const [phoneNumber, setPhoneNumber] = useState(data?.phone_number || "");
  const handlePhoneNumber = (event) => {
    const inputValue = event.target.value.trim();
    setPhoneNumber(inputValue);
  };
  // Role
  const [role, setRole] = useState(data?.company_role || "");
  const handleRole = (event) => {
    const inputValue = event.target.value.trim();
    setRole(inputValue);
  };
  // Head Country
  const [headCountry, setHeadCountry] = useState(data?.country_id?.name || "");
  const [headCountryId, setHeadCountryId] = useState(data?.country_id?._id || "");

  const {
    data: countryData,
    isLoading: countryLoading,
    isError: countryError,
  } = useFetchCountryListQuery();

  const handleHeadCountry = (event) => {
    const selectedCountry = event.target.value.trim();
    if (selectedCountry === "") {
      setHeadCountry(data?.country_id?.name || "");
      setHeadCountryId(countryData?.country_id?._id || "");
    } else {
      setHeadCountry(selectedCountry);
      const selectedCountryObject = countryData?.data.find(
        (country) => country.name === selectedCountry
      );
      if (selectedCountryObject) {
        setHeadCountryId(selectedCountryObject._id);
      } else {
        setHeadCountryId("");
      }
    }
  };

  // Industry
  const [industry, setIndustry] = useState(data?.industry_type || "");
  const handleIndustry = (event) => {
    const inputValue = event.target.value.trim();
    setIndustry(inputValue);
  };
  // Tech
  const [tech, setTech] = useState(data?.tech_type || "");
  // const handleTech = (event) => {
  //   const inputValue = event.target.value.trim();
  //   setTech(inputValue);
  // };
  // UseCase
  const [useCase, setUseCase] = useState(data?.usecase_type || "");
  // const handleUseCase = (event) => {
  //   const inputValue = event.target.value.trim();
  //   setUseCase(inputValue);
  // };
  // Company Size
  const [companySize, setCompanySize] = useState(data?.company_size || "");

  const handleCompanySize = (event) => {
    const inputValue = event.target.value.trim();
    setCompanySize(inputValue);
  };
  // Company Revenue
  const [companyRevenue, setCompanyRevenue] = useState(
    data?.company_revenue_level || ""
  );
  const handleCompanyRevenue = (event) => {
    const inputValue = event.target.value.trim();
    setCompanyRevenue(inputValue);
  };

  const [
    updateBusinessUserProfile,
    { isLoading: profileUpdateLoading, isError: profileError },
  ] = useUpdateBusinessUserProfileMutation();

  const {
    data: IndustryData,
    isLoading: industryLoading,
    error: industryError,
  } = useFetchIndustryListQuery();
  const {
    data: UseCaseData,
    isLoading: useCaseLoading,
    error: useCaseError,
  } = useFetchUseCaseListQuery();
  const {
    data: masterData,
    isLoading: masterLoading,
    error: masterError,
  } = useFetchMasterDetailsQuery();


  const updateProfile = async () => {
    try {
      const response = await updateBusinessUserProfile({
        profileId: userProfileData?.data._id,
        userId: userId,
        userType: storedUserType,
        businessName: businessName !== ""? businessName : data?.business_name,
        businessUrl: businessUrl !== ""? businessUrl : data?.business_url,
        phoneCode: countryCode !== ""? countryCode : data?.country_id?.phone_code,
        countryId: headCountryId !==""? headCountryId :data?.country_id._id,
        phoneNumber: phoneNumber !==""? phoneNumber : data?.phone_number,
        role: role !=="" ? role : data?.company_role,
        techType: tech !== ""? tech : tech,
        industryType: industry !==""? industry : data?.industry_type,
        useCaseType: useCase !==""? useCase : data?.usecase_type,
        companySize: companySize !== ""? companySize : data?.company_size,
        companyRevenue: companyRevenue !==""? companyRevenue : data?.company_revenue_level ,
        linkedinUrl: linkedinUrl,
      }).unwrap();

      const successMessage = response?.message || "Profile updated";
      showToastMessage(successMessage, "success");
    } catch (error) {
      const errorMessage = error?.message || "Failed to update";
      showToastMessage(errorMessage, "error");
    }
  };
  useEffect(() => {
    if (data) {
      setBusinessName(data.business_name || "");
      setBusinessUrl(data?.business_url || "");
      setPhoneNumber(data?.phone_number || "");
      setPhoneNumber(data?.phone_number || "");
      setLinkedinUrl(data?.linkedin_url || "");
    }
  }, [data])


  useEffect(() => {
    if (profileUpdateLoading) {
      dispatch(showLoader());
    } else {
      dispatch(hideLoader());
    }
  }, [profileUpdateLoading, dispatch]);

  return (
    <>
      <Box
        sx={{
          // background: "#dbebf8",
        }}
      >
        {/* <Stack
          direction="row"
          sx={{
            py: 0,
            px: 0,
            borderBottom: "solid 1px #DBEBF8",
            alignItems: "center",
          }}
        >
          <Link
            to="/profile"
            style={{
              marginRight: "5px",
              display: "inline-flex",
            }}
          >
            <KeyboardBackspaceIcon
              sx={{
                color: "lightSecondary.main",
                fontSize: "40px",
                [theme.breakpoints.down("md")]: {
                  fontSize: "30px",
                },
              }}
            />
          </Link>
          <Typography
            variant="body2"
            sx={{
              color: "lightSecondary.main",
              fontSize: "1.8rem",
              fontWeight: "700",
              pr: 0.7,
              [theme.breakpoints.down("md")]: {
                fontSize: "1.3rem",
              },
            }}
          >
            Update Business Profile
          </Typography>

          <Typography variant='body2'
            sx={{
                color: '#A2C2F1',
                fontSize: '1.4rem',
                fontWeight: '600',
                [theme.breakpoints.down('md')]: {
                    fontSize: '1.1rem',
                }
            }}
          >
            (1/2)
        </Typography>
        </Stack> */}
        <Box
          sx={{
            // pt: 3,
            maxWidth: "1352px",
            margin: "0 auto",
            // mb: 3,
          }}
        >
          <MainCard
            content={false}
            sx={{
              backgroundColor: "#FFFFFF",
              border: "none",
              padding: "0",
              boxShadow: "none",
              borderRadius: "10px",
              p: 5,
              [theme.breakpoints.down("md")]: {
                p: 3,
              },
            }}
          >
            {/* <Typography
              variant="h4"
              sx={{
                fontSize: "1.8rem",
                color: "secondary.main",
                fontWeight: "700",
                textAlign: "center",
                mb: 7,
                position: "relative",
                [theme.breakpoints.down("md")]: {
                  fontSize: "1.4rem",
                  mb: 5,
                  "&:before": {
                    bottom: "-15px",
                  },
                },
                [theme.breakpoints.down("sm")]: {
                  fontSize: "1.2rem",
                },
              }}
            >
              Tell Us About Your Business
            </Typography> */}

            <Grid
              key={data?._id}
              container
              rowSpacing={2}
              columnSpacing={3}
              sx={{
                mb: 2,
              }}
            >
              {/* Business Name */}
              <Grid item xs={12} md={12} lg={12}>
                <TextField
                  hiddenLabel
                  id="filled-hidden-label-normal"
                  variant="filled"
                  value={businessName}
                  placeholder="Business Name*"
                  required
                  style={{
                    margin: 0,
                    width: "100%",
                  }}
                  sx={{
                    width: "100%",
                    borderRadius: "4px",
                    backgroundColor: "extraLTheme.main",
                    border: "solid 1px #DBEBF8",
                    m: 0,
                    "& .MuiInputBase-root": {
                      backgroundColor: "extraLTheme.main",
                    },
                    "& .MuiInputBase-input": {
                      fontSize: "0.9rem",
                      color: "lightSecondary.main",
                      fontWeight: "600",
                      pt: 1.5,
                      pb: 1.5,
                    },
                    "& .MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error):before":
                      {
                        border: "none",
                      },
                    "& .MuiInputBase-root:hover:before": {
                      border: "none",
                    },
                    "&:hover": {
                      borderColor: "#DBEBF8",
                    },
                    "& .MuiInputBase-root:before": {
                      border: "none",
                    },
                    "& .MuiInputBase-root:after": {
                      border: "none",
                    },
                  }}
                  onChange={handleBusinessName}
                />
              </Grid>

              {/* Business Url */}
              <Grid item xs={12} md={12} lg={12}>
                <TextField
                  hiddenLabel
                  id="filled-hidden-label-normal"
                  variant="filled"
                  value={businessUrl}
                  placeholder="Business Website*"
                  style={{
                    margin: 0,
                    width: "100%",
                  }}
                  sx={{
                    width: "100%",
                    borderRadius: "4px",
                    backgroundColor: "extraLTheme.main",
                    border: "solid 1px #DBEBF8",
                    m: 0,
                    "& .MuiInputBase-root": {
                      backgroundColor: "extraLTheme.main",
                    },
                    "& .MuiInputBase-input": {
                      fontSize: "0.9rem",
                      color: "lightSecondary.main",
                      fontWeight: "600",
                      pt: 1.5,
                      pb: 1.5,
                    },
                    "& .MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error):before":
                      {
                        border: "none",
                      },
                    "& .MuiInputBase-root:hover:before": {
                      border: "none",
                    },
                    "&:hover": {
                      borderColor: "#DBEBF8",
                    },
                    "& .MuiInputBase-root:before": {
                      border: "none",
                    },
                    "& .MuiInputBase-root:after": {
                      border: "none",
                    },
                  }}
                  onChange={handleBusinessUrl}
                />
              </Grid>
              {/* Country code and phoneNumber */}
              <Grid item xs={12} md={6} lg={6}>
                <Grid
                  container
                  rowSpacing={0}
                  columnSpacing={2}
                  sx={{
                    mb: 0,
                  }}
                >
                  <Grid item xs={4} md={4} lg={4}>
                    {countryData?.data && (
                      <FormControl
                        fullWidth
                        sx={{
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                        }}
                      >
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={countryCode}
                          onChange={handleCountryCode}
                          displayEmpty
                          IconComponent={KeyboardArrowDownOutlinedIcon}
                          sx={{
                            background: "#ECF6FF",
                            "& .MuiSelect-select": {
                              minHeight: "20px",
                              padding: "11px 14px",
                              color: "lightSecondary.main",
                              fontWeight: "600",
                              fontSize: "0.9rem",
                              border: "solid 1px #DBEBF8",
                            },
                            "& .MuiSelect-icon": {
                              color: "lightSecondary.main",
                            },
                          }}
                          MenuProps={{
                            PaperProps: {
                              sx: {
                                maxHeight: "200px",
                                overflowY: "auto",
                                "&::-webkit-scrollbar": {
                                  width: "6px",
                                  borderRadius: "4px",
                                },
                                "&::-webkit-scrollbar-track": {
                                  backgroundColor: "transparent",
                                  borderRadius: "4px",
                                },
                                "&::-webkit-scrollbar-thumb": {
                                  backgroundColor: "#9C9C9C",
                                  borderRadius: "4px",
                                },
                              },
                            },
                          }}
                        >
                          <MenuItem
                            value={countryCode}
                            disabled
                          >
                            {data?.country_id?.phone_code || "ph-code*"}
                          </MenuItem>
                          {countryData &&
                            countryData.data.map((country) => (
                              <MenuItem
                                key={country._id}
                                value={country.phone_code}
                              >
                                <Box
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Flag
                                    code={country.iso2}
                                    style={{
                                      width: "20px",
                                      height: "15px",
                                      marginRight: "10px",
                                    }}
                                  />
                                  {country.phone_code}
                                </Box>
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    )}
                  </Grid>
                  <Grid item xs={8} md={48} lg={8}>
                    <TextField
                      hiddenLabel
                      id="filled-hidden-label-normal"
                      variant="filled"
                      value={phoneNumber}
                      placeholder="phoneNumber"
                      style={{
                        margin: 0,
                        width: "100%",
                      }}
                      sx={{
                        width: "100%",
                        borderRadius: "4px",
                        backgroundColor: "extraLTheme.main",
                        border: "solid 1px #DBEBF8",
                        m: 0,
                        "& .MuiInputBase-root": {
                          backgroundColor: "extraLTheme.main",
                        },
                        "& .MuiInputBase-input": {
                          fontSize: "0.9rem",
                          color: "lightSecondary.main",
                          fontWeight: "600",
                          pt: 1.5,
                          pb: 1.5,
                        },
                        "& .MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error):before":
                          {
                            border: "none",
                          },
                        "& .MuiInputBase-root:hover:before": {
                          border: "none",
                        },
                        "&:hover": {
                          borderColor: "#DBEBF8",
                        },
                        "& .MuiInputBase-root:before": {
                          border: "none",
                        },
                        "& .MuiInputBase-root:after": {
                          border: "none",
                        },
                      }}
                      onChange={handlePhoneNumber}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {/* Role */}
              <Grid item xs={12} md={6} lg={6}>
                <FormControl
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  }}
                >
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={role}
                    onChange={handleRole}
                    displayEmpty
                    IconComponent={KeyboardArrowDownOutlinedIcon}
                    sx={{
                      background: "#ECF6FF",
                      "& .MuiSelect-select": {
                        minHeight: "20px",
                        padding: "11px 14px",
                        color: "lightSecondary.main",
                        fontWeight: "600",
                        fontSize: "0.9rem",
                        border: "solid 1px #DBEBF8",
                      },
                      "& .MuiSelect-icon": {
                        color: "lightSecondary.main",
                      },
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          maxHeight: "200px",
                          overflowY: "auto",
                          "&::-webkit-scrollbar": {
                            width: "6px",
                            borderRadius: "4px",
                          },
                          "&::-webkit-scrollbar-track": {
                            backgroundColor: "transparent",
                            borderRadius: "4px",
                          },
                          "&::-webkit-scrollbar-thumb": {
                            backgroundColor: "#9C9C9C",
                            borderRadius: "4px",
                          },
                        },
                      },
                    }}
                  >
                    <MenuItem value={role} disabled>
                      {data?.company_role || "Select Role*"}
                    </MenuItem>
                    {masterData?.masterData?.roles.map(
                      (role, index) =>
                        index === index && (
                          <MenuItem key={role} value={role}>
                            {role}
                          </MenuItem>
                        )
                    )}
                  </Select>
                </FormControl>
              </Grid>
              {/* Country */}
              <Grid item xs={12} md={6} lg={6}>
                <FormControl
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  }}
                >
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={headCountry}
                    onChange={handleHeadCountry}
                    displayEmpty
                    IconComponent={KeyboardArrowDownOutlinedIcon}
                    sx={{
                      background: "#ECF6FF",
                      "& .MuiSelect-select": {
                        minHeight: "20px",
                        padding: "11px 14px",
                        color: "lightSecondary.main",
                        fontWeight: "600",
                        fontSize: "0.9rem",
                        border: "solid 1px #DBEBF8",
                      },
                      "& .MuiSelect-icon": {
                        color: "lightSecondary.main",
                      },
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          maxHeight: "300px",
                          overflowY: "auto",
                          "&::-webkit-scrollbar": {
                            width: "6px",
                            borderRadius: "4px",
                          },
                          "&::-webkit-scrollbar-track": {
                            backgroundColor: "transparent",
                            borderRadius: "4px",
                          },
                          "&::-webkit-scrollbar-thumb": {
                            backgroundColor: "#9C9C9C",
                            borderRadius: "4px",
                          },
                        },
                      },
                    }}
                  >
                    <MenuItem value={headCountry} disabled>
                      {data?.country_id?.name || "Select Country*"}
                    </MenuItem>
                    {countryData &&
                      countryData.data.map((country) => (
                        <MenuItem key={country._id} value={country.name}>
                          <Box
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Flag
                              code={country.iso2}
                              style={{
                                width: "20px",
                                height: "15px",
                                marginRight: "10px",
                              }}
                            />
                            {country.name}
                          </Box>
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>

              {/* tech */}
              {/* <Grid item xs={12} md={6} lg={6}>
                <FormControl
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  }}
                >
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={tech}
                    onChange={handleTech}
                    displayEmpty
                    IconComponent={KeyboardArrowDownOutlinedIcon}
                    sx={{
                      background: "#ECF6FF",
                      "& .MuiSelect-select": {
                        minHeight: "20px",
                        padding: "11px 14px",
                        color: "lightSecondary.main",
                        fontWeight: "600",
                        fontSize: "0.9rem",
                        border: "solid 1px #DBEBF8",
                      },
                      "& .MuiSelect-icon": {
                        color: "lightSecondary.main",
                      },
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          maxHeight: "140px",
                          overflowY: "auto",
                          "&::-webkit-scrollbar": {
                            width: "6px",
                            borderRadius: "4px",
                          },
                          "&::-webkit-scrollbar-track": {
                            backgroundColor: "transparent",
                            borderRadius: "4px",
                          },
                          "&::-webkit-scrollbar-thumb": {
                            backgroundColor: "#9C9C9C",
                            borderRadius: "4px",
                          },
                        },
                      },
                    }}
                  >
                    <MenuItem value={tech} disabled>
                      {data?.tech_type ? data.tech_type : "Select Tech*"}
                    </MenuItem>
                    <MenuItem value={"AI"}>AI</MenuItem>
                    <MenuItem value={"Blockchain"}>Blockchain</MenuItem>
                  </Select>
                </FormControl>
              </Grid> */}
              {/* Industry */}
              <Grid item xs={12} md={6} lg={6}>
                <FormControl
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  }}
                >
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={industry}
                    onChange={handleIndustry}
                    displayEmpty
                    IconComponent={KeyboardArrowDownOutlinedIcon}
                    sx={{
                      background: "#ECF6FF",
                      "& .MuiSelect-select": {
                        minHeight: "20px",
                        padding: "11px 14px",
                        color: "lightSecondary.main",
                        fontWeight: "600",
                        fontSize: "0.9rem",
                        border: "solid 1px #DBEBF8",
                      },
                      "& .MuiSelect-icon": {
                        color: "lightSecondary.main",
                      },
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          maxHeight: "300px",
                          overflowY: "auto",
                          "&::-webkit-scrollbar": {
                            width: "6px",
                            borderRadius: "4px",
                          },
                          "&::-webkit-scrollbar-track": {
                            backgroundColor: "transparent",
                            borderRadius: "4px",
                          },
                          "&::-webkit-scrollbar-thumb": {
                            backgroundColor: "#9C9C9C",
                            borderRadius: "4px",
                          },
                        },
                      },
                    }}
                  >
                    <MenuItem value={industry} disabled>
                      {data?.industry_type || "Select Industry*"}
                    </MenuItem>
                    {IndustryData &&
                      IndustryData.data.map((industry) => (
                        <MenuItem key={industry._id} value={industry.name}>
                          {industry.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              {/* useCase */}
              {/* <Grid item xs={12} md={6} lg={6}>
                <FormControl
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  }}
                >
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={useCase}
                    onChange={handleUseCase}
                    displayEmpty
                    IconComponent={KeyboardArrowDownOutlinedIcon}
                    sx={{
                      background: "#ECF6FF",
                      "& .MuiSelect-select": {
                        minHeight: "20px",
                        padding: "11px 14px",
                        color: "lightSecondary.main",
                        fontWeight: "600",
                        fontSize: "0.9rem",
                        border: "solid 1px #DBEBF8",
                      },
                      "& .MuiSelect-icon": {
                        color: "lightSecondary.main",
                      },
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          maxHeight: "300px",
                          overflowY: "auto",
                          "&::-webkit-scrollbar": {
                            width: "6px",
                            borderRadius: "4px",
                          },
                          "&::-webkit-scrollbar-track": {
                            backgroundColor: "transparent",
                            borderRadius: "4px",
                          },
                          "&::-webkit-scrollbar-thumb": {
                            backgroundColor: "#9C9C9C",
                            borderRadius: "4px",
                          },
                        },
                      },
                    }}
                  >
                    <MenuItem value={useCase} disabled>
                      {data?.usecase_type || "Select Usecase*"}
                    </MenuItem>
                    {UseCaseData &&
                      UseCaseData.data.map((useCase) => (
                        <MenuItem key={useCase._id} value={useCase.title}>
                          {useCase.title}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid> */}
              {/* company size */}
              <Grid item xs={12} md={6} lg={6}>
                <FormControl
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  }}
                >
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={companySize}
                    onChange={handleCompanySize}
                    displayEmpty
                    IconComponent={KeyboardArrowDownOutlinedIcon}
                    sx={{
                      background: "#ECF6FF",
                      "& .MuiSelect-select": {
                        minHeight: "20px",
                        padding: "11px 14px",
                        color: "lightSecondary.main",
                        fontWeight: "600",
                        fontSize: "0.9rem",
                        border: "solid 1px #DBEBF8",
                      },
                      "& .MuiSelect-icon": {
                        color: "lightSecondary.main",
                      },
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          maxHeight: "300px",
                          overflowY: "auto",
                          "&::-webkit-scrollbar": {
                            width: "6px",
                            borderRadius: "4px",
                          },
                          "&::-webkit-scrollbar-track": {
                            backgroundColor: "transparent",
                            borderRadius: "4px",
                          },
                          "&::-webkit-scrollbar-thumb": {
                            backgroundColor: "#9C9C9C",
                            borderRadius: "4px",
                          },
                        },
                      },
                    }}
                  >
                    <MenuItem value={companySize} disabled>
                      {data?.company_size|| "Select Company Size*"}
                    </MenuItem>
                    {masterData?.masterData?.companySize.map(
                      (values, index) =>
                        index === index && (
                          <MenuItem key={values} value={values}>
                            {values}
                          </MenuItem>
                        )
                    )}
                  </Select>
                </FormControl>
              </Grid>
              {/* company Revenue */}
              <Grid item xs={12} md={6} lg={6}>
                <FormControl
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  }}
                >
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={companyRevenue}
                    onChange={handleCompanyRevenue}
                    displayEmpty
                    IconComponent={KeyboardArrowDownOutlinedIcon}
                    sx={{
                      background: "#ECF6FF",
                      "& .MuiSelect-select": {
                        minHeight: "20px",
                        padding: "11px 14px",
                        color: "lightSecondary.main",
                        fontWeight: "600",
                        fontSize: "0.9rem",
                        border: "solid 1px #DBEBF8",
                      },
                      "& .MuiSelect-icon": {
                        color: "lightSecondary.main",
                      },
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          maxHeight: "200px",
                          overflowY: "auto",
                          "&::-webkit-scrollbar": {
                            width: "6px",
                            borderRadius: "4px",
                          },
                          "&::-webkit-scrollbar-track": {
                            backgroundColor: "transparent",
                            borderRadius: "4px",
                          },
                          "&::-webkit-scrollbar-thumb": {
                            backgroundColor: "#9C9C9C",
                            borderRadius: "4px",
                          },
                        },
                      },
                    }}
                  >
                    <MenuItem value={companyRevenue} disabled>
                      {data?.company_revenue_level || "Select Company Revenue*"}
                    </MenuItem>
                    {masterData?.masterData?.companyRevenue.map(
                      (revenue, index) =>
                        index === index && (
                          <MenuItem key={revenue} value={revenue}>
                            {revenue}
                          </MenuItem>
                        )
                    )}
                  </Select>
                </FormControl>
              </Grid>
              {/* Linkedin url */}
              <Grid item xs={12} md={12} lg={12}>
                <TextField
                  hiddenLabel
                  id="filled-hidden-label-normal"
                  variant="filled"
                  value={linkedinUrl}
                  placeholder="Linkedin Url"
                  style={{
                    margin: 0,
                    width: "100%",
                  }}
                  sx={{
                    width: "100%",
                    borderRadius: "4px",
                    backgroundColor: "extraLTheme.main",
                    border: "solid 1px #DBEBF8",
                    m: 0,
                    "& .MuiInputBase-root": {
                      backgroundColor: "extraLTheme.main",
                    },
                    "& .MuiInputBase-input": {
                      fontSize: "0.9rem",
                      color: "lightSecondary.main",
                      fontWeight: "600",
                      pt: 1.5,
                      pb: 1.5,
                    },
                    "& .MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error):before":
                      {
                        border: "none",
                      },
                    "& .MuiInputBase-root:hover:before": {
                      border: "none",
                    },
                    "&:hover": {
                      borderColor: "#DBEBF8",
                    },
                    "& .MuiInputBase-root:before": {
                      border: "none",
                    },
                    "& .MuiInputBase-root:after": {
                      border: "none",
                    },
                  }}
                  onChange={handleLinkedinUrl}
                />
              </Grid>
            </Grid>
            <Box
              sx={{
                textAlign: "center",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  textTransform: "inherit",
                  p: "10px 20px",
                  boxShadow: "none",
                  fontWeight: "600",
                  mt: 4,
                  width: "240px",
                }}
                onClick={updateProfile}
              >
                update
              </Button>
            </Box>
          </MainCard>
        </Box>
      </Box>
    </>
  );
};

export default updateBusinessProfile;
