import React, { useEffect, useState } from 'react'
import {
    Box,
    Typography,
    TextField,
    Button,
    Autocomplete
} from '@mui/material'
import { useTheme } from '@emotion/react';
import { DeleteOutlineOutlined } from '@mui/icons-material';
import { useUpdateRequirementBoardNameMutation } from '../../redux/api/Requirement Boards/updateRequirementBoardName';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { hideLoader, showLoader } from '../../redux/features/loader';
import { setRefetchKanbanBoard, setRefetchRequirementBoard } from '../../redux/features/refetch';

const BoardSetting = ({ handleClose, deleteBoardAlertHandle ,currentBoardId, boardName, refetchBsiCoLab = () => {} , showToastMessage }) => {
    const theme = useTheme();
    const location = useLocation();
    const dispatch = useDispatch();
    const selectedBoardId = localStorage.getItem("boardId");
    const boardId = currentBoardId || selectedBoardId;
    const [newBoardName, setNewBoardName] = useState(boardName ? boardName : "");
    const [updateRequirementBoardName, { isLoading, isError }] = useUpdateRequirementBoardNameMutation();
    const handleChange = (event) => {
        setNewBoardName(event.target.value);
    };

    const updateBoardName = async () => {    
        try {
            const boardId = currentBoardId || selectedBoardId;
            const response = await updateRequirementBoardName({
                boardId: boardId,
                boardName: newBoardName,
            }).unwrap();
    
            // Assuming the API uses standard HTTP status codes in its response body
            if (response?.statusCode === 200) {
                refetchBsiCoLab();
                dispatch(setRefetchKanbanBoard(true))
                showToastMessage('Board name updated', 'success');
                handleClose();
            } else {
                // Handle specific error scenarios based on the response
                const errorMessage = response?.message || 'Failed to update board name';
                showToastMessage(errorMessage, 'error');
            }
        } catch (error) {
            // Provide a more specific error message if available
            const errorMessage = error?.message || 'Failed to update board name';
            showToastMessage(errorMessage, 'error');
        }
    };

    //   Loader
      useEffect(() => {
        if (isLoading) {
          dispatch(showLoader());
        } else {
          dispatch(hideLoader());
        }
      }, [isLoading, dispatch]);

    return (
        <>
            <Box
                sx={{
                    mb: 3
                }}
            >
                <Typography variant='h5'
                    sx={{
                        fontSize: "1.1rem",
                        color: "secondary.main",
                        fontWeight: "700"
                    }}>
                    Board Settings
                </Typography>
            </Box>
            <Box
                sx={{
                    mb: 3
                }}
            >
                <Typography variant='h5'
                    sx={{
                        fontSize: "0.9rem",
                        color: "secondary.main",
                        fontWeight: "600",
                        mb: 0.5
                    }}>
                    Board Name
                </Typography>
                <TextField
                    hiddenLabel
                    id="filled-hidden-label-normal"
                    variant="filled"
                    value={newBoardName}
                    onChange={handleChange}
                    sx={{
                        width: '100%',
                        borderRadius: '4px',
                        backgroundColor: 'extraLTheme.main',
                        border: 'none',
                        '& .MuiInputBase-input': {
                            fontSize: '0.9rem',
                            color: 'lightSecondary.main',
                            fontWeight: '500',
                            pt: 1.5,
                            pb: 1.5,
                            backgroundColor: 'extraLTheme.main',
                        },
                        '&:hover .MuiInputBase-root:hover:before': {
                            border: 'none'
                        },
                        '& .MuiInputBase-root:hover:before': {
                            border: 'none'
                        },
                        '&:hover': {
                            borderColor: 'extraLTheme.main'
                        },
                        '& .MuiInputBase-root:before': {
                            border: 'none'
                        },
                        '& .MuiInputBase-root:after': {
                            border: 'none'
                        }
                    }}
                />
            </Box>
            {/* comment incase for future use */}
            {/* <Box
                sx={{
                    mb: 3
                }}
            >
                <Typography variant='h5'
                    sx={{
                        fontSize: "0.9rem",
                        color: "secondary.main",
                        fontWeight: "600",
                        mb: 0.5
                    }}>
                    Select Industry
                </Typography>
                <Autocomplete
                    id="size-small-standard"
                    options={selectItem}
                    getOptionLabel={(option) => option.title}
                    defaultValue={selectItem[13]}
                    sx={{
                        '& .MuiInputBase-root':{
                            backgroundColor: 'extraLTheme.main',
                            p: 0,
                            borderRadius: '4px'
                        },
                        '& .MuiFilledInput-root:before': {
                            display:'none'
                        },
                        '& .MuiFilledInput-root:after': {
                            display:'none'
                        },
                        '& .MuiFilledInput-root .MuiInputBase-input' : {
                            padding: '11px 12px',
                            fontSize: '0.9rem',
                            color: 'lightSecondary.main',
                            fontWeight: '500',
                        }
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="filled"
                            placeholder="Select Industry"
                        />
                    )}
                />
            </Box> */}
            <Box
                sx={{
                    mt: 3,
                    display: 'flex',
                    gap: 2,
                    pb: 3,
                    borderBottom: "solid 1px",
                    borderBottomColor: "ltheme.main"
                }}
            >
                <Button variant="outlined"
                    sx={{
                        textTransform: 'inherit',
                        p: "10px 25px",
                        width: '50%',
                        borderWidth: '2px',
                        fontWeight: '600',
                        '&:hover': {
                            borderWidth: '2px',
                        }
                    }}
                    onClick={handleClose}
                >
                    Cancel
                </Button>
                <Button variant="contained"
                    sx={{
                        textTransform: 'inherit',
                        p: "12px 25px",
                        width: '50%',
                        boxShadow: 'none',
                        fontWeight: '600',
                    }}
                    onClick={updateBoardName}
                    >
                    Save
                </Button>
            </Box>

            <Box
                sx={{
                    textAlign: 'center',
                    mt: 3
                }}
            >
                <Button variant="outlined"
                    sx={{
                        textTransform: 'inherit',
                        p: "10px 25px",
                        borderColor: 'red.main',
                        color: 'red.main',
                        fontSize: '0.9rem',
                        fontWeight: '600',
                        [theme.breakpoints.down('md')]: {
                            width: "100%",
                            p: "10px 10px",
                        },
                        '&:hover': {
                            borderColor: 'red.main',
                            backgroundColor: 'transparent'
                        }
                    }}
                    onClick={deleteBoardAlertHandle}
                >
                    <DeleteOutlineOutlined
                        style={{
                            width: "20px",
                            height: "20px",
                            marginRight: "5px",
                            marginTop: "0"
                        }} />
                    Delete Board
                </Button>
            </Box>
        </>
    )
}

export default BoardSetting