import Search from '@mui/icons-material/Search';
import { fetchBaseQuery, createApi } from '@reduxjs/toolkit/query/react';


const baseUrl = process.env.REACT_APP_base_URL;

export const getDriveItemList  = createApi({
    reducerPath: 'getDriveItemList',
    baseQuery: fetchBaseQuery({
      baseUrl: baseUrl,
      prepareHeaders: (headers) => {
        // Add any headers you need here
        return headers;
      },
    }),
    endpoints: (builder) => ({
      fetchDriveItemList: builder.query({
        query: ({userId ,parentId, searchParams,sortOption}) => ({
          url: `drive/${userId}?search=${searchParams}&sort=${sortOption}&parentId=${parentId ? parentId : ""}`,
          method: 'GET',
        }),
        transformResponse: (response) => {
         
          if(response.status != false){
            return response;
          }else{
            return []
          }
         },
        
        refetchOnWindowFocus: true,
        // retry: RetryOnError,
        onError: (error) => {
          console.error('Fetch folder detail error:', error);
          // Handle error cases as per your application's requirements
        },
      })
    })
})


export const { useFetchDriveItemListQuery } = getDriveItemList;
export default getDriveItemList;
