import { createSlice } from '@reduxjs/toolkit';

const kanbanSlice = createSlice({
  name: 'kanban',
  initialState: {
    invited: [],
    shortlisted: [],
    rejected: [],
    selected: [],
    responded: [],
  },
  reducers: {
    setInvited: (state, action) => {
      state.invited = action.payload;
    },
    addToShortlisted: (state, action)=>{
      state.shortlisted = action.payload;
    },
    addToRejected: (state, action)=>{
      state.rejected = action.payload;
    },
    addToSelected: (state, action)=>{
      state.selected = action.payload;
    },
    addToResponded: (state, action)=>{
      state.responded = action.payload;
    },
  },
});

export const {setInvited, addToShortlisted , addToRejected, addToResponded, addToSelected } = kanbanSlice.actions;
export default kanbanSlice.reducer;
