import { fetchBaseQuery, createApi } from '@reduxjs/toolkit/query/react';


const baseUrl = process.env.REACT_APP_base_URL;
const logoBaseUrl = 'https://bsdawebfiledata.s3.ca-central-1.amazonaws.com/solution/';

export const quickAccessDocs  = createApi({
    reducerPath: 'quickAccessDocs',
    baseQuery: fetchBaseQuery({
      baseUrl: baseUrl,
      prepareHeaders: (headers) => {
        // Add any headers you need here
        return headers;
      },
    }),
    endpoints: (builder) => ({
      fetchQuickDocumentList: builder.query({
        query: ({userId , searchParam}) => ({
          url: `meetings/quick-access-doc/${userId}?search=${searchParam}`,
          method: 'GET',
        }),
        transformResponse: (response) => {
          if(response.data.length>0){
            return response;
          }
        },
        
        refetchOnWindowFocus: true,
        // retry: RetryOnError,
        onError: (error) => {
          console.error('Fetch Document details error:', error);
          // Handle error cases as per your application's requirements
        },
      })
    })
})


export const { useFetchQuickDocumentListQuery } = quickAccessDocs;
export default quickAccessDocs;
