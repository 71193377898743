import React from 'react';  // Import React from 'react'
import { useTheme } from '@emotion/react';  // Import useTheme from '@emotion/react'
import { 
    Avatar, Box, IconButton, 
    List, ListItemAvatar, 
    ListItemButton, ListItemText, 
    Typography 
} from '@mui/material'
import { styled } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import { KeyboardArrowRightOutlined } from '@mui/icons-material';

const CreateNewGroup = ({selectedBoardOpen}) => {
    const theme = useTheme();
    const Search = styled('div')(({ theme }) => ({
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: '#DBEBF8',
        '&:hover': {
            backgroundColor: '#DBEBF8',
        },
        marginRight: '0',
        marginLeft: 0,
        marginBottom: 5,
        width: '100%'
    }));
    const SearchIconWrapper = styled('div')(({ theme }) => ({
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        right: '0',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }));

    const StyledInputBase = styled(InputBase)(({ theme }) => ({
        color: '#106EED',
        width: '100%',
        '& .MuiInputBase-input': {
            padding: theme.spacing(1.7, 0, 1.7, 1.7),
            paddingRight: `calc(1em + ${theme.spacing(4)})`,
            transition: theme.transitions.create('width'),
            width: '100%',
            fontSize: '12px',
            fontWeight: '500'
        },
    }));
  return (
    <>
        <Box
            sx={{
                mb: 3
            }}
        >
            <Typography variant='h5'
                sx={{
                    fontSize: "1.1rem",
                    color: "secondary.main",
                    fontWeight: "700"
                }}>
                Create New Group
            </Typography>
        </Box>
        <Box sx={{mb: 1.5}}>
            <Search>
                <SearchIconWrapper>
                    <SearchIcon sx={{ color: "lightSecondary.main" }} />
                </SearchIconWrapper>
                <StyledInputBase
                    placeholder="Search board"
                    inputProps={{ 'aria-label': 'search' }}
                />
            </Search>
        </Box>
        <Typography variant="h6" color="textSecondary"
            sx={{
                fontSize: "1.1rem", 
                fontWeight: "700",
                color: "secondary.main",
                marginBottom: "0rem"
            }}
        >
            Select Board
        </Typography>
        <List
            component="nav"
            sx={{
                px: 0,
                py: 0,
                '& .MuiListItemButton-root': {
                    py: 1,
                }
            }}
        >
            <ListItemButton
                onClick={selectedBoardOpen}
                sx={{
                    borderRadius: "4px",
                    '&:hover': {
                        backgroundColor: "#ECF6FF"
                    },
                }}
            >
                <ListItemAvatar
                    sx={{
                        width: "56px"
                    }}
                >
                    <Avatar 
                        sx={{ 
                                bgcolor: "secondary.main",
                                px: 2.8,
                                fontSize: "12px",
                                fontWeight: "600"
                            }} variant="rounded">
                        IE1
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    sx={{
                        pl: '10px',
                        width: "60%"
                    }}>
                        <Typography noWrap
                            sx={{
                                fontSize: "1.1rem",
                                fontWeight: '700',
                                color: 'secondary.main',
                                lineHeight: '22px',
                                maxWidth: '220px',
                                [theme.breakpoints.down('sm')]: {
                                    maxWidth: '110px'
                                }
                            }}
                            variant="h6">Insurance Enquiries 1
                        </Typography>
                </ListItemText>
                <IconButton aria-label="arrow"
                    sx= {{
                        p:0,
                        '&:hover' : {
                            backgroundColor: 'transparent'
                        },
                    }}
                >
                    <KeyboardArrowRightOutlined sx={{color: "#2A589C"}} />
                </IconButton>
            </ListItemButton>
            <ListItemButton
                onClick={selectedBoardOpen}
                sx={{
                    borderRadius: "4px",
                    '&:hover': {
                        backgroundColor: "#ECF6FF"
                    },
                }}
            >
                <ListItemAvatar
                    sx={{
                        width: "56px"
                    }}
                >
                    <Avatar 
                        sx={{ 
                                bgcolor: "secondary.main",
                                px: 2.8,
                                fontSize: "12px",
                                fontWeight: "600"
                            }} variant="rounded">
                        LR
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    sx={{
                        pl: '10px',
                        width: "60%"
                    }}>
                        <Typography noWrap
                            sx={{
                                fontSize: "1.1rem",
                                fontWeight: '700',
                                color: 'secondary.main',
                                lineHeight: '22px',
                                maxWidth: '220px',
                                [theme.breakpoints.down('sm')]: {
                                    maxWidth: '110px'
                                }
                            }}
                            variant="h6">Legal records
                        </Typography>
                </ListItemText>
                <IconButton aria-label="arrow"
                    sx= {{
                        p: 0,
                        '&:hover' : {
                            backgroundColor: 'transparent'
                        },
                    }}
                >
                    <KeyboardArrowRightOutlined sx={{color: "#2A589C"}} />
                </IconButton>
            </ListItemButton>
            <ListItemButton
                onClick={selectedBoardOpen}
                sx={{
                    borderRadius: "4px",
                    '&:hover': {
                        backgroundColor: "#ECF6FF"
                    },
                }}
            >
                <ListItemAvatar
                    sx={{
                        width: "56px"
                    }}
                >
                    <Avatar 
                        sx={{ 
                                bgcolor: "secondary.main",
                                px: 2.8,
                                fontSize: "12px",
                                fontWeight: "600"
                            }} variant="rounded">
                        EHR
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    sx={{
                        pl: '10px',
                        width: "60%"
                    }}>
                        <Typography noWrap
                            sx={{
                                fontSize: "1.1rem",
                                fontWeight: '700',
                                color: 'secondary.main',
                                lineHeight: '22px',
                                maxWidth: '220px',
                                [theme.breakpoints.down('sm')]: {
                                    maxWidth: '110px'
                                }
                            }}
                            variant="h6">Electrical health records
                        </Typography>
                </ListItemText>
                <IconButton aria-label="arrow"
                    sx= {{
                        p:0,
                        '&:hover' : {
                            backgroundColor: 'transparent'
                        },
                    }}
                >
                    <KeyboardArrowRightOutlined sx={{color: "#2A589C"}} />
                </IconButton>
            </ListItemButton>
            <ListItemButton
                onClick={selectedBoardOpen}
                sx={{
                    borderRadius: "4px",
                    '&:hover': {
                        backgroundColor: "#ECF6FF"
                    },
                }}
            >
                <ListItemAvatar
                    sx={{
                        width: "56px"
                    }}
                >
                    <Avatar 
                        sx={{ 
                                bgcolor: "secondary.main",
                                px: 2.8,
                                fontSize: "12px",
                                fontWeight: "600"
                            }} variant="rounded">
                        DS
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    sx={{
                        pl: '10px',
                        width: "60%"
                    }}>
                        <Typography noWrap
                            sx={{
                                fontSize: "1.1rem",
                                fontWeight: '700',
                                color: 'secondary.main',
                                lineHeight: '22px',
                                maxWidth: '220px',
                                [theme.breakpoints.down('sm')]: {
                                    maxWidth: '110px'
                                }
                            }}
                            variant="h6">Decentralized Storage
                        </Typography>
                </ListItemText>
                <IconButton aria-label="arrow"
                    sx= {{
                        p:0,
                        '&:hover' : {
                            backgroundColor: 'transparent'
                        },
                    }}
                >
                    <KeyboardArrowRightOutlined sx={{color: "#2A589C"}} />
                </IconButton>
            </ListItemButton>
            <ListItemButton
                onClick={selectedBoardOpen}
                sx={{
                    borderRadius: "4px",
                    '&:hover': {
                        backgroundColor: "#ECF6FF"
                    },
                }}
            >
                <ListItemAvatar
                    sx={{
                        width: "56px"
                    }}
                >
                    <Avatar 
                        sx={{ 
                                bgcolor: "secondary.main",
                                px: 2.8,
                                fontSize: "12px",
                                fontWeight: "600"
                            }} variant="rounded">
                        SCE
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    sx={{
                        pl: '10px',
                        width: "60%"
                    }}>
                        <Typography noWrap
                            sx={{
                                fontSize: "1.1rem",
                                fontWeight: '700',
                                color: 'secondary.main',
                                lineHeight: '22px',
                                maxWidth: '220px',
                                [theme.breakpoints.down('sm')]: {
                                    maxWidth: '110px'
                                }
                            }}
                            variant="h6">Supply chain Enquiries
                        </Typography>
                </ListItemText>
                <IconButton aria-label="arrow"
                    sx= {{
                        p:0,
                        '&:hover' : {
                            backgroundColor: 'transparent'
                        },
                    }}
                >
                    <KeyboardArrowRightOutlined sx={{color: "#2A589C"}} />
                </IconButton>
            </ListItemButton>
        </List>
    </>
  )
}

export default CreateNewGroup