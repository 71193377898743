import React from 'react';
import {
    Avatar,
    AvatarGroup,
    Box,
    Button,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Stack,
    Typography
} from '@mui/material';
import MainCard from '../../commonComponents/MainCard';
import { useTheme } from '@emotion/react';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import { getImages } from '../../commonComponents/const';
// Date
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import Overview from '../../commonComponents/Dashboard/Overview';
import RecentEnquiries from '../../commonComponents/Dashboard/RecentEnquiries';
import RecentChats from '../../commonComponents/Dashboard/RecentChats';
import ImportantDocuments from '../../commonComponents/Dashboard/ImportantDocuments';
import { useNavigate } from 'react-router-dom';
const Dashboard = () => {
    const theme = useTheme();
    const navigate = useNavigate(); 
    const handleViewAllClick = (path)=>{
        navigate(path)
    }

    return (
        <>
            {/* <Layout> */}
            <Grid container rowSpacing={4.5} columnSpacing={2.75}>
                <Grid item xs={12} md={12} lg={8}
                    sx={{
                        '@media (max-width:1600px) and (min-width:1401px)': {
                            flexBasis: '72%',
                            maxWidth: '72%'
                        },
                        '@media (max-width:1400px) and (min-width:1200px)': {
                            flexBasis: '65%',
                            maxWidth: '65%'
                        },
                    }}
                >
                    <Grid container rowSpacing={2.75} columnSpacing={2.75}>
                        <Grid item xs={12} md={5} lg={4}
                            sx={{
                                '@media (max-width:1600px) and (min-width:1401px)': {
                                    flexBasis: '40%',
                                    maxWidth: '40%'
                                },
                                '@media (max-width:1400px) and (min-width:1200px)': {
                                    flexBasis: '45%',
                                    maxWidth: '45%'
                                },
                            }}
                        >
                            <Overview />
                        </Grid>
                        <Grid item xs={12} md={7} lg={8}
                            sx={{
                                '@media (max-width:1600px) and (min-width:1401px)': {
                                    flexBasis: '60%',
                                    maxWidth: '60%'
                                },
                                '@media (max-width:1400px) and (min-width:1200px)': {
                                    flexBasis: '55%',
                                    maxWidth: '55%'
                                },
                            }}
                        >
                            <RecentEnquiries />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}
                            sx={{
                                '@media (max-width:1600px) and (min-width:1200px)': {
                                    flexBasis: '45%',
                                    maxWidth: '45%'
                                },
                            }}
                        >
                            <RecentChats />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}
                            sx={{
                                '@media (max-width:1600px) and (min-width:1200px)': {
                                    flexBasis: '55%',
                                    maxWidth: '55%'
                                },
                            }}
                        >
                            <ImportantDocuments />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={12} lg={4}
                    sx={{
                        '@media (max-width:1600px) and (min-width:1401px)': {
                            flexBasis: '28%',
                            maxWidth: '28%'
                        },
                        '@media (max-width:1400px) and (min-width:1200px)': {
                            flexBasis: '35%',
                            maxWidth: '35%'
                        },
                    }}
                >
                    <MainCard content={false}
                        sx={{
                            backgroundColor: "#ECF6FF",
                            border: "none",
                            padding: "15px 25px",
                            [theme.breakpoints.down('md')]: {
                                padding: "15px 15px",
                            },
                        }
                        }>
                        <Box sx={{ p: 0, pb: 0 }}>
                            <Stack spacing={2}
                                sx={{
                                    flexDirection: "inherit",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    width: "100%",
                                    marginBottom: "0"
                                }}>
                                <Typography variant="h6" color="textSecondary"
                                    sx={{
                                        fontSize: "1.2rem",
                                        fontWeight: "600",
                                        color: "secondary.main",
                                        marginBottom: "1rem"
                                    }}
                                >
                                    Calendar
                                </Typography>
                            </Stack>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateCalendar sx={{
                                    width: '100%',
                                    minHeight: '350',
                                    "& .MuiDayCalendar-weekDayLabel": {
                                        fontSize: '0.9rem',
                                        width: "42px",
                                        height: "42px",
                                        color: "ltheme.main",
                                        fontWeight: "600",
                                        '@media (max-width:1600px)': {
                                            width: "35px",
                                            height: "35px",
                                        },
                                        '@media (max-width:1320px)': {
                                            width: "32px",
                                            height: "32px",
                                        },
                                        '@media (max-width:767px)': {
                                            width: "42px",
                                            height: "42px",
                                        },
                                        '@media (max-width:400px)': {
                                            width: "36px",
                                            height: "36px",
                                        },
                                    },
                                    "& .MuiPickersDay-root": {
                                        fontSize: '0.9rem',
                                        width: "42px",
                                        height: "42px",
                                        color: "secondary.main",
                                        fontWeight: "600",
                                        '@media (max-width:1600px)': {
                                            width: "35px",
                                            height: "35px",
                                        },
                                        '@media (max-width:1320px)': {
                                            width: "32px",
                                            height: "32px",
                                        },
                                        '@media (max-width:767px)': {
                                            width: "42px",
                                            height: "42px",
                                        },
                                        '@media (max-width:400px)': {
                                            width: "36px",
                                            height: "36px",
                                        },
                                    },
                                    "& .MuiPickersDay-today": {
                                        background: '#106EED',
                                        border: 'none',
                                        color: "#fff",
                                    }
                                }} />
                            </LocalizationProvider>
                            {/* Upcoming Meetings */}
                            <Stack spacing={2}
                                sx={{
                                    flexDirection: "inherit",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    width: "100%",
                                    mb: 2,
                                    mt: 2
                                }}>
                                <Typography variant="h6" color="textSecondary"
                                    sx={{
                                        fontSize: "1.2rem",
                                        fontWeight: "600",
                                        color: "secondary.main",
                                        marginBottom: "1rem"
                                    }}
                                >
                                    Upcoming Meetings
                                </Typography>
                                    
                                <Button variant="text"
                                onClick={()=>handleViewAllClick("/meetings")}
                                    sx={{
                                        fontSize: "12px",
                                        fontWeight: "600",
                                        textTransform: "inherit"
                                    }}
                                >
                                    View all <ArrowForwardIosOutlinedIcon sx={{ marginLeft: "5px", fill: "primary.main", width: "12px", height: "12px" }} />
                                </Button>

                            </Stack>

                            <Button variant="contained"
                                sx={{
                                    width: "100%",
                                    textTransform: 'inherit',
                                    padding: '10px',
                                    boxShadow: 'none',
                                    '&:hover': {
                                        boxShadow: 'none'
                                    }
                                }}
                            >
                                <VideocamOutlinedIcon sx={{ width: "20px", height: "20px", marginRight: "8px" }} /> Schedule New Meeting
                            </Button>

                            <List
                                component="nav"
                                sx={{
                                    px: 0,
                                    py: 0,
                                    '& .MuiListItemButton-root': {
                                        py: 1.5,
                                        // '& .MuiAvatar-root': avatarSX,
                                        // '& .MuiListItemSecondaryAction-root': { ...actionSX, position: 'relative' }
                                    }
                                }}
                            >
                                <Grid container mt={0.5} rowSpacing={1.9} columnSpacing={1}>
                                    <Grid item xs={12} md={2} lg={2}>
                                        <Typography
                                            sx={{
                                                fontSize: "12px",
                                                fontWeight: '600',
                                                color: 'ltheme.main',
                                                lineHeight: '20px',
                                                textAlign: 'center',
                                                textTransform: 'uppercase',
                                                marginTop: "8px"
                                            }}
                                            variant="subtitle1">WED
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: "1.5rem",
                                                fontWeight: '600',
                                                color: 'ltheme.main',
                                                lineHeight: '20px',
                                                textAlign: 'center',
                                                textTransform: 'uppercase',
                                                marginTop: "4px"
                                            }}
                                            variant="subtitle1">22
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={10} lg={10}>
                                        <ListItem sx={{ minHeight: "40px", alignItems: "center", py: "5px", background: "#FFFFFF", borderRadius: "10px", marginTop: "8px" }}>
                                            <ListItemAvatar sx={{
                                                display: "inline-block",
                                                marginRight: "7px"
                                            }}>
                                                <img style={{ display: "block" }} src={getImages('image_172.png')} />
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={
                                                    <Typography noWrap
                                                        sx={{
                                                            fontSize: "0.9rem",
                                                            fontWeight: '600',
                                                            color: 'secondary.main',
                                                            lineHeight: '20px',
                                                        }}
                                                        variant="h6">Project Discussion
                                                    </Typography>}
                                                secondary={
                                                    <Typography
                                                        noWrap
                                                        variant="body2"
                                                        sx={{
                                                            fontSize: '12px',
                                                            fontWeight: '500',
                                                            color: 'ltheme.main',
                                                            width: "100%",
                                                            mt: 0.5,

                                                        }}
                                                    >09:45 AM - 10:45 AM</Typography>}
                                            />
                                            <AvatarGroup max={3}
                                                sx={{
                                                    ml: "auto",
                                                    height: 24,
                                                    overflow: 'visible'
                                                }}>
                                                <Avatar
                                                    alt="Remy Sharp"
                                                    src={getImages("1.jpg")}
                                                    sx={{ width: 24, height: 24 }}
                                                />
                                                <Avatar
                                                    alt="Remy Sharp"
                                                    src={getImages("2.jpg")}
                                                    sx={{ width: 24, height: 24 }}
                                                />
                                                <Avatar
                                                    alt="Remy Sharp"
                                                    src={getImages("3.jpg")}
                                                    sx={{ width: 24, height: 24 }}
                                                />
                                            </AvatarGroup>
                                        </ListItem>
                                        <ListItem sx={{ minHeight: "40px", alignItems: "center", py: "5px", background: "#FFFFFF", borderRadius: "10px", marginTop: "8px" }}>
                                            <ListItemAvatar sx={{
                                                display: "inline-block",
                                                marginRight: "7px"
                                            }}>
                                                <img style={{ display: "block" }} src={getImages('image_172.png')} />
                                            </ListItemAvatar>
                                            <ListItemText

                                                primary={
                                                    <Typography noWrap
                                                        sx={{
                                                            fontSize: "0.9rem",
                                                            fontWeight: '600',
                                                            color: 'secondary.main',
                                                            lineHeight: '20px'
                                                        }}
                                                        variant="h6">Solution Enquiries
                                                    </Typography>}
                                                secondary={
                                                    <Typography
                                                        noWrap
                                                        variant="body2"
                                                        sx={{
                                                            fontSize: '12px',
                                                            fontWeight: '500',
                                                            color: 'ltheme.main',
                                                            width: "100%",
                                                            mt: 0.5

                                                        }}
                                                    >11:00 AM - 11:30 AM</Typography>}
                                            />
                                            <AvatarGroup max={3} sx={{ ml: "auto", height: 24, overflow: 'visible' }}>
                                                <Avatar
                                                    alt="Remy Sharp"
                                                    src={getImages("1.jpg")}
                                                    sx={{ width: 24, height: 24 }}
                                                />
                                                <Avatar
                                                    alt="Remy Sharp"
                                                    src={getImages("2.jpg")}
                                                    sx={{ width: 24, height: 24 }}
                                                />
                                            </AvatarGroup>
                                        </ListItem>
                                    </Grid>
                                </Grid>
                                <Grid container mt={0.5} rowSpacing={1.9} columnSpacing={1}>
                                    <Grid item xs={12} md={2} lg={2}>
                                        <Typography
                                            sx={{
                                                fontSize: "12px",
                                                fontWeight: '600',
                                                color: 'ltheme.main',
                                                lineHeight: '20px',
                                                textAlign: 'center',
                                                textTransform: 'uppercase',
                                                marginTop: "8px"
                                            }}
                                            variant="subtitle1">THU
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: "1.5rem",
                                                fontWeight: '600',
                                                color: 'ltheme.main',
                                                lineHeight: '20px',
                                                textAlign: 'center',
                                                textTransform: 'uppercase',
                                                marginTop: "4px"
                                            }}
                                            variant="subtitle1">23
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={10} lg={10}>
                                        <ListItem sx={{ minHeight: "40px", alignItems: "center", py: "5px", background: "#FFFFFF", borderRadius: "10px", marginTop: "8px" }}>
                                            <ListItemAvatar sx={{
                                                display: "inline-block",
                                                marginRight: "7px"
                                            }}>
                                                <img style={{ display: "block" }} src={getImages('image_172.png')} />
                                            </ListItemAvatar>
                                            <ListItemText

                                                primary={
                                                    <Typography noWrap
                                                        sx={{
                                                            fontSize: "0.9rem",
                                                            fontWeight: '600',
                                                            color: 'secondary.main',
                                                            lineHeight: '20px'
                                                        }}
                                                        variant="h6">Project Discussion
                                                    </Typography>}
                                                secondary={
                                                    <Typography
                                                        noWrap
                                                        variant="body2"
                                                        sx={{
                                                            fontSize: '12px',
                                                            fontWeight: '500',
                                                            color: 'ltheme.main',
                                                            width: "100%",
                                                            mt: 0.5

                                                        }}
                                                    >09:45 AM - 10:45 AM</Typography>}
                                            />
                                            <AvatarGroup max={3} sx={{ ml: "auto", height: 24, overflow: 'visible' }}>
                                                <Avatar
                                                    alt="Remy Sharp"
                                                    src={getImages("1.jpg")}
                                                    sx={{ width: 24, height: 24 }}
                                                />
                                                <Avatar
                                                    alt="Remy Sharp"
                                                    src={getImages("2.jpg")}
                                                    sx={{ width: 24, height: 24 }}
                                                />
                                            </AvatarGroup>
                                        </ListItem>
                                        <ListItem sx={{ minHeight: "40px", alignItems: "center", py: "5px", background: "#FFFFFF", borderRadius: "10px", marginTop: "8px" }}>
                                            <ListItemAvatar sx={{
                                                display: "inline-block",
                                                marginRight: "7px"
                                            }}>
                                                <img style={{ display: "block" }} src={getImages('image_172.png')} />
                                            </ListItemAvatar>
                                            <ListItemText

                                                primary={
                                                    <Typography noWrap
                                                        sx={{
                                                            fontSize: "0.9rem",
                                                            fontWeight: '600',
                                                            color: 'secondary.main',
                                                            lineHeight: '20px'
                                                        }}
                                                        variant="h6">Solution Enquiries
                                                    </Typography>}
                                                secondary={
                                                    <Typography
                                                        noWrap
                                                        variant="body2"
                                                        sx={{
                                                            fontSize: '12px',
                                                            fontWeight: '500',
                                                            color: 'ltheme.main',
                                                            width: "100%",
                                                            mt: 0.5

                                                        }}
                                                    >11:00 AM - 11:30 AM</Typography>}
                                            />
                                            <AvatarGroup max={3} sx={{ ml: "auto", height: 24, overflow: 'visible' }}>
                                                <Avatar
                                                    alt="Remy Sharp"
                                                    src={getImages("1.jpg")}
                                                    sx={{ width: 24, height: 24 }}
                                                />
                                                <Avatar
                                                    alt="Remy Sharp"
                                                    src={getImages("2.jpg")}
                                                    sx={{ width: 24, height: 24 }}
                                                />
                                                <Avatar
                                                    alt="Remy Sharp"
                                                    src={getImages("3.jpg")}
                                                    sx={{ width: 24, height: 24 }}
                                                />
                                            </AvatarGroup>
                                        </ListItem>
                                        <ListItem sx={{ minHeight: "40px", alignItems: "center", py: "5px", background: "#FFFFFF", borderRadius: "10px", marginTop: "8px" }}>
                                            <ListItemAvatar sx={{
                                                display: "inline-block",
                                                marginRight: "7px"
                                            }}>
                                                <img style={{ display: "block" }} src={getImages('image_172.png')} />
                                            </ListItemAvatar>
                                            <ListItemText

                                                primary={
                                                    <Typography noWrap
                                                        sx={{
                                                            fontSize: "0.9rem",
                                                            fontWeight: '600',
                                                            color: 'secondary.main',
                                                            lineHeight: '20px'
                                                        }}
                                                        variant="h6">Solution Enquiries
                                                    </Typography>}
                                                secondary={
                                                    <Typography
                                                        noWrap
                                                        variant="body2"
                                                        sx={{
                                                            fontSize: '12px',
                                                            fontWeight: '500',
                                                            color: 'ltheme.main',
                                                            width: "100%",
                                                            mt: 0.5

                                                        }}
                                                    >11:00 AM - 11:30 AM</Typography>}
                                            />
                                            <AvatarGroup max={3} sx={{ ml: "auto", height: 24, overflow: 'visible' }}>
                                                <Avatar
                                                    alt="Remy Sharp"
                                                    src={getImages("1.jpg")}
                                                    sx={{ width: 24, height: 24 }}
                                                />
                                                <Avatar
                                                    alt="Remy Sharp"
                                                    src={getImages("2.jpg")}
                                                    sx={{ width: 24, height: 24 }}
                                                />
                                            </AvatarGroup>
                                        </ListItem>
                                    </Grid>
                                </Grid>
                            </List>
                        </Box>
                    </MainCard>
                </Grid>
            </Grid>
        </>
    )
}

export default Dashboard;