// // store.js
import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import rootReducer from './rootReducer';
import { apiMiddlewares } from './apiMiddleware';
export const store = configureStore({
  reducer:rootReducer,
  middleware: (getDefaultMiddleware) => 
  getDefaultMiddleware().concat(...apiMiddlewares)
});

setupListeners(store.dispatch);

