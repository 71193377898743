import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
    name :'user',
    initialState: {
        data: null,
    },
    reducers:{
        getUser: (state , action)=> {
          state.data =  action.payload
        },
        clearUser: ()=> null,
    },
});

export const {getUser , clearUser} = userSlice.actions;
export default userSlice.reducer;