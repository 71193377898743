import React, { useState } from 'react';
import {
    Box,
    Button,
    Stack,
    Typography
} from '@mui/material';
import MainCard from '../../commonComponents/MainCard';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { PieChart } from '@mui/x-charts/PieChart';
import { useTheme } from '@emotion/react';
import SwapHorizOutlinedIcon from '@mui/icons-material/SwapHorizOutlined';
import { useNavigate } from 'react-router-dom';
const data = [
    { value: 15, label: '12 Investments', color: '#7CDDA3' },
    { value: 9, label: '9 Rejected', color: '#FFB2B2' },
    { value: 18, label: '18 Commitments', color: '#106EED' },
];
const data1 = [
    { value: 5, label: '15 Completed', color: '#7CDDA3' },
    { value: 10, label: '11 Rejected', color: '#FFB2B2' },
    { value: 15, label: '21 In progress', color: '#106EED' },
];

const Overview = () => {
    const theme = useTheme();
    // Dropdown Menu
    const [swapped, setSwapped] = useState(false);
    const [buttonText, setButtonText] = useState("See Investment Summary")
    const handleSwap = () => {
        setSwapped(!swapped);
    };
    const navigate = useNavigate()
    const handleOnClick = () => {
       navigate('/my-solutions');
   };
    return (
        <>
            <MainCard content={false}
                sx={{
                    backgroundColor: "#ECF6FF",
                    border: "none",
                    padding: "15px 25px",
                    boxShadow: "none",
                    borderRadius: "10px",
                    minHeight: '420px',
                }}
            >
                <Box sx={{ p: 0, pb: 0 }}>
                    <Stack spacing={2}
                        direction="row"
                        sx={{
                            justifyContent: 'space-between'
                        }}
                    >
                        <Typography variant="h6" color="textSecondary"
                            sx={{
                                fontSize: "1.2rem", fontWeight: "600",
                                color: "secondary.main",
                                marginBottom: "1rem"
                            }}
                        >
                            Overview
                        </Typography>
                        {/* For Solution Provide */}
                        {/* <div className=''></div> */}
                        <Stack
                            direction="row"
                            sx={{
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            {swapped ? (
                                <>
                                    <button className="button"
                                        style={{
                                            color: '#106EED',
                                            fontSize: '12px',
                                            fontWeight: '600',
                                            backgroundColor: 'transparent',
                                            border: 'none'
                                        }}
                                    >
                                        Solution
                                    </button>
                                    <Box
                                        sx={{
                                            pt: 0.7
                                        }}
                                    >
                                        <SwapHorizOutlinedIcon
                                            sx={{
                                                color: 'primary.main',
                                                fontSize: '15px'
                                            }}
                                        />
                                    </Box>
                                    <button className="button" onClick={handleSwap}
                                        style={{
                                            color: '#A2C2F1',
                                            fontSize: '12px',
                                            fontWeight: '600',
                                            backgroundColor: 'transparent',
                                            border: 'none',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        Investment
                                    </button>
                                </>
                            ) : (
                                <>
                                    <button className="button"
                                        style={{
                                            color: '#106EED',
                                            fontSize: '12px',
                                            fontWeight: '600',
                                            backgroundColor: 'transparent',
                                            border: 'none'
                                        }}
                                    >
                                        Investment
                                    </button>
                                    <Box
                                        sx={{
                                            pt: 0.7
                                        }}
                                    >
                                        <SwapHorizOutlinedIcon
                                            sx={{
                                                color: 'primary.main',
                                                fontSize: '15px'
                                            }}
                                        />
                                    </Box>
                                    <button className="button" onClick={handleSwap}
                                        style={{
                                            color: '#A2C2F1',
                                            fontSize: '12px',
                                            fontWeight: '600',
                                            backgroundColor: 'transparent',
                                            border: 'none',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        Solution
                                    </button>
                                </>
                            )}
                        </Stack>
                    </Stack>
                    <Box
                        sx={{
                            textAlign: "center",
                            position: 'relative',
                            width: "230px",
                            ml: "auto",
                            mr: "auto",
                            [theme.breakpoints.down('sm')]: {
                                width: "240px",
                                ml: "auto",
                                mr: "auto"
                            }
                        }}>
                        <div>
                            {swapped ?
                                <PieChart
                                    series={[{ data: data1, innerRadius: 74, legend: false }]}
                                    slotProps={{
                                        legend: { hidden: true },
                                    }}
                                    width={320}
                                    height={200}
                                    sx={{
                                        display: "inline-flex",
                                    }}
                                >
                                </PieChart>
                                :
                                <PieChart
                                    series={[{ data, innerRadius: 74, legend: false }]}
                                    slotProps={{
                                        legend: { hidden: true },
                                    }}
                                    width={320}
                                    height={200}
                                    sx={{
                                        display: "inline-flex",
                                    }}
                                >
                                </PieChart>
                            }
                        </div>
                        <Box
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '0',
                                width: '100%',
                                textAlign: 'center',
                                transform: "translate(0, -50%)"
                            }}>
                            <Typography variant="h6"
                                sx={{
                                    textAlign: "center",
                                    color: "#073985",
                                    fontWeight: "700",
                                    fontSize: "2.4rem",
                                    lineHeight: "40px"
                                }}>39</Typography>
                            <Typography variant="h6"
                                sx={{
                                    textAlign: "center",
                                    fontWeight: "600",
                                    color: "#A2C2F1",
                                    fontSize: "12px"
                                }}>Enquiries</Typography>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            py: 1
                        }}>
                        {swapped ?
                            data1?.map((entry, index) => (
                                <Box
                                    key={index}
                                    display="flex"
                                    alignItems="center"
                                    sx={{
                                        maxWidth: "190px",
                                        margin: "0 auto",
                                        py: 0.7
                                    }}
                                >
                                    <div
                                        style={{
                                            width: '40px',
                                            height: '10px',
                                            borderRadius: "10px",
                                            backgroundColor: entry.color || '#ccc',
                                            marginRight: "7px"
                                        }} />
                                    <Typography
                                        sx={{
                                            color: 'ltheme.main',
                                            fontSize: "0.9rem",
                                            fontWeight: "600"
                                        }}
                                    >{entry.label}</Typography>
                                </Box>
                            )) :
                            data?.map((entry, index) => (
                                <Box
                                    key={index}
                                    display="flex"
                                    alignItems="center"
                                    sx={{
                                        maxWidth: "190px",
                                        margin: "0 auto",
                                        py: 0.7
                                    }}
                                >
                                    <div
                                        style={{
                                            width: '40px',
                                            height: '10px',
                                            borderRadius: "10px",
                                            backgroundColor: entry.color || '#ccc',
                                            marginRight: "7px"
                                        }} />
                                    <Typography
                                        sx={{
                                            color: 'ltheme.main',
                                            fontSize: "0.9rem",
                                            fontWeight: "600"
                                        }}
                                    >{entry.label}</Typography>
                                </Box>
                            ))
                        }
                    </Box>
                    {swapped ?
                                <Button variant="contained"
                                sx={{
                                    width: "100%",
                                    textTransform: 'inherit',
                                    p: "10px",
                                    boxShadow: 'none',
                                    '&:hover': {
                                        boxShadow: 'none'
                                    }
                                }}
                                onClick={handleOnClick}
                            >
                                {/* <AddOutlinedIcon
                                    sx={{
                                        width: "20px",
                                        height: "20px",
                                        marginRight: "8px"
                                    }} /> */}
                                Manage Solutions
                            </Button>
                                :
                                <Button variant="contained"
                                sx={{
                                    width: "100%",
                                    textTransform: 'inherit',
                                    p: "10px",
                                    boxShadow: 'none',
                                    '&:hover': {
                                        boxShadow: 'none'
                                    }
                                }}
                                onClick={handleOnClick}
                            >
                                {/* <AddOutlinedIcon
                                    sx={{
                                        width: "20px",
                                        height: "20px",
                                        marginRight: "8px"
                                    }} /> */}
                                See Investment Summary
                            </Button>
                               
                            }
                </Box>
            </MainCard>
        </>
    )
}

export default Overview