import React from 'react'
import {
    Box,
    ListItemText,
    Typography,
    ListItemAvatar,
    Avatar,
    Badge,
    Button,
    IconButton,
    TextField,
    styled,
} from '@mui/material';
import { useTheme } from '@emotion/react';
import { getImages } from '../../commonComponents/const';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import MicIcon from '@mui/icons-material/Mic';
import SendIcon from '@mui/icons-material/Send';
import { CloseOutlined } from '@mui/icons-material';
import { setChatSelected, setOpenGroupChat, toggleOpenChatInfo, toggleOpenSolutionList } from '../../redux/features/chatSlice';
import { useDispatch, useSelector } from 'react-redux';

const ChatContainer = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const solutionList = useSelector((state)=> state.chat.openSolutionList)
    const SmallAvatar = styled(Avatar)(({ theme }) => ({
        width: 24,
        height: 24,
        border: `2px solid ${theme.palette.background.paper}`,
    }));
    const chatInfoHandle = () => {
        dispatch(toggleOpenChatInfo());
        if(!solutionList){
         dispatch(toggleOpenSolutionList());
        }
    };
    const closeChatContainer = () => {
        dispatch(setChatSelected(false));
        dispatch(setOpenGroupChat(false));
    };


    return (
        <>
            <Box>
                {/* Chat header */}
                <Box
                    sx={{
                        backgroundColor: 'extraLTheme.main',
                        borderRadius: '10px 10px 0 0',
                        p: 2,
                        display: 'flex'
                    }}
                >
                    <ListItemAvatar
                        sx={{
                            width: "56px"
                        }}
                    >
                        <Avatar
                            sx={{
                                color: 'success.main',
                                bgcolor: 'success.lighter',
                                width: '50px',
                                height: '50px'
                            }}
                            src={getImages('1.jpg')}
                        >
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        sx={{
                            pl: '10px',
                            width: "60%"
                        }}
                        primary={
                            <Typography noWrap
                                sx={{
                                    fontSize: "1rem",
                                    fontWeight: '600',
                                    color: 'secondary.main',
                                    lineHeight: '22px',
                                    maxWidth: '220px',
                                    [theme.breakpoints.down('sm')]: {
                                        maxWidth: '110px'
                                    }
                                }}
                                variant="h6">Messiah Rozo
                            </Typography>}
                        secondary={<Typography
                            variant="body2"
                            sx={{
                                fontSize: '12px',
                                fontWeight: '500',
                                color: 'primary.main',
                                pl: 2
                            }}
                        >
                            <Badge color="secondary" badgeContent=" " variant="dot"
                                sx={{
                                    '& .MuiBadge-badge': {
                                        backgroundColor: "green.main",
                                        right: '11px',
                                        top: '-2px'
                                    }
                                }}
                            ></Badge>
                            Active now
                        </Typography>}
                    />
                    <ListItemText
                        sx={{
                            width: "calc(40% - 56px)",
                            textAlign: 'right',
                            [theme.breakpoints.down('md')]: {
                                whiteSpace: 'nowrap',
                                minWidth: "120px",
                            }
                        }}
                    >
                        <IconButton
                            sx={{
                                position: 'relative',
                                [theme.breakpoints.down('lg')]: {
                                    px : 0.7
                                }
                            }}
                        >
                            <CalendarMonthIcon
                                sx={{
                                    color: 'lightSecondary.main',
                                    fontSize: '24px',
                                }}
                            />
                            <LocalPhoneIcon
                                sx={{
                                    color: 'lightSecondary.main',
                                    fontSize: '17px',
                                    position: 'absolute',
                                    left: '4px',
                                    bottom: '2px'
                                }}
                            />
                        </IconButton>
                        <IconButton
                            onClick={chatInfoHandle}
                            sx={{
                                [theme.breakpoints.down('lg')]: {
                                    px : 0.5
                                }
                            }}
                        >
                            <InfoOutlinedIcon
                                sx={{
                                    color: 'lightSecondary.main',
                                    fontSize: '24px',
                                }}
                            />
                        </IconButton>
                        <IconButton
                            onClick={closeChatContainer}
                            sx={{
                                [theme.breakpoints.up('lg')]: {
                                    display: 'none',
                                },
                                [theme.breakpoints.down('lg')]: {
                                    px : 0.5
                                }
                            }}
                        >
                            <CloseOutlined
                                sx={{
                                    color: 'lightSecondary.main',
                                    fontSize: '24px',
                                }}
                            />
                        </IconButton>
                    </ListItemText>
                </Box>
                {/* Chat Body */}
                <Box
                    sx={{
                        position: 'relative',
                        overflowY: 'auto',
                        pr: 1,
                        maxHeight: 'calc(100vh - 265px)',
                        minHeight: 'calc(100vh - 267px)',
                        '&::-webkit-scrollbar': {
                            width: '6px',
                            borderRadius: '4px'
                        },
                        '&::-webkit-scrollbar-track': {
                            backgroundColor: 'transparent',
                            borderRadius: '4px'
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: '#9C9C9C',
                            borderRadius: '4px'
                        },
                        [theme.breakpoints.down('lg')]: {
                            maxHeight: 'calc(100vh - 224px)',
                            minHeight: 'calc(100vh - 226px)',
                        },
                        [theme.breakpoints.down('md')]: {
                            maxHeight: 'calc(100vh - 251px)',
                            minHeight: 'calc(100vh - 253px)',
                        }
                    }}
                >
                    {/* Sender message */}
                    <Box
                        sx={{
                            textAlign: 'right',
                            pr: 2,
                            py: 2
                        }}>
                        <Box
                            sx={{
                                backgroundColor: 'lightSecondary.main',
                                borderRadius: '10px 0px 10px 10px',
                                p: "10px 15px",
                                width: '60%',
                                position: 'relative',
                                display: 'inline-block',
                                textAlign: 'left',
                                '&:before': {
                                    content: '""',
                                    position: 'absolute',
                                    right: '-16px',
                                    top: '0',
                                    borderLeft: 'solid 16px #2A589C',
                                    borderBottom: 'solid 16px transparent'
                                },
                                [theme.breakpoints.down('md')]: {
                                    width: 'calc(100% - 70px)',
                                }
                            }}
                        >
                            <Typography variant='body2'
                                sx={{
                                    fontSize: '0.9rem',
                                    color: '#fff',
                                    fontWeight: '500'
                                }}
                            >Hello Team, I came across to your blockchain solution and Keen to know more about it. Actually I am working in fintech domain where we are planning to implement such solution.</Typography>
                            <Typography variant='body2'
                                sx={{
                                    fontSize: '12px',
                                    color: 'ltheme.main',
                                    fontWeight: '500',
                                    textAlign: 'right'
                                }}
                            >11:48 AM</Typography>
                        </Box>
                    </Box>
                    {/* Reciver message */}
                    <Box
                        sx={{
                            textAlign: 'left',
                            pl: 2,
                            py: 2,
                            display: 'flex'
                        }}>
                        <ListItemAvatar
                            sx={{
                                width: "42px"
                            }}
                        >
                            <Avatar
                                sx={{
                                    color: 'success.main',
                                    bgcolor: 'success.lighter',
                                    width: '40px',
                                    height: '40px'
                                }}
                                src={getImages('1.jpg')}
                            >
                            </Avatar>
                        </ListItemAvatar>
                        <Box
                            sx={{
                                backgroundColor: 'extraLTheme.main',
                                borderRadius: '0px 10px 10px 10px',
                                p: "10px 15px",
                                width: '60%',
                                position: 'relative',
                                display: 'inline-block',
                                textAlign: 'left',
                                '&:before': {
                                    content: '""',
                                    position: 'absolute',
                                    left: '-16px',
                                    top: '0',
                                    borderRight: 'solid 16px #ECF6FF',
                                    borderBottom: 'solid 16px transparent'
                                },
                                [theme.breakpoints.down('md')]: {
                                    width: 'calc(100% - 70px)',
                                }
                            }}
                        >
                            <Typography variant='body2'
                                sx={{
                                    fontSize: '0.9rem',
                                    color: 'lightSecondary.main',
                                    fontWeight: '500'
                                }}
                            >Hello David <br /><br />
                                Thanks for connecting !<br /><br />
                                Sure will help out with your query so let me tell you about our product.<br />
                                Sofo Pay is a supply chain optimization and credit risk management solution. The mobile app Sofo Pay is designed for the needs of SMEs and decentralized logistics.</Typography>
                            <Typography variant='body2'
                                sx={{
                                    fontSize: '12px',
                                    color: 'ltheme.main',
                                    fontWeight: '500',
                                    textAlign: 'right'
                                }}
                            >11:48 AM</Typography>
                        </Box>
                    </Box>
                    {/* Sender message */}
                    <Box
                        sx={{
                            textAlign: 'right',
                            pr: 2,
                            py: 2
                        }}>
                        <Box
                            sx={{
                                backgroundColor: 'lightSecondary.main',
                                borderRadius: '10px 0px 10px 10px',
                                p: "10px 15px",
                                width: '60%',
                                position: 'relative',
                                display: 'inline-block',
                                textAlign: 'left',
                                '&:before': {
                                    content: '""',
                                    position: 'absolute',
                                    right: '-16px',
                                    top: '0',
                                    borderLeft: 'solid 16px #2A589C',
                                    borderBottom: 'solid 16px transparent'
                                },
                                [theme.breakpoints.down('md')]: {
                                    width: 'calc(100% - 70px)',
                                }
                            }}
                        >
                            <Typography variant='body2'
                                sx={{
                                    fontSize: '0.9rem',
                                    color: '#fff',
                                    fontWeight: '500'
                                }}
                            >Hello Team, I came across to your blockchain solution and Keen to know more about it. Actually I am working in fintech domain where we are planning to implement such solution.</Typography>
                            <Typography variant='body2'
                                sx={{
                                    fontSize: '12px',
                                    color: 'ltheme.main',
                                    fontWeight: '500',
                                    textAlign: 'right'
                                }}
                            >11:48 AM</Typography>
                        </Box>
                    </Box>
                    {/* Reciver message */}
                    <Box
                        sx={{
                            textAlign: 'left',
                            pl: 2,
                            py: 2,
                            display: 'flex'
                        }}>
                        <ListItemAvatar
                            sx={{
                                width: "42px"
                            }}
                        >
                            <Avatar
                                sx={{
                                    color: 'success.main',
                                    bgcolor: 'success.lighter',
                                    width: '40px',
                                    height: '40px'
                                }}
                                src={getImages('1.jpg')}
                            >
                            </Avatar>
                        </ListItemAvatar>
                        <Box
                            sx={{
                                backgroundColor: 'extraLTheme.main',
                                borderRadius: '0px 10px 10px 10px',
                                p: "10px 15px",
                                width: '60%',
                                position: 'relative',
                                display: 'inline-block',
                                textAlign: 'left',
                                '&:before': {
                                    content: '""',
                                    position: 'absolute',
                                    left: '-16px',
                                    top: '0',
                                    borderRight: 'solid 16px #ECF6FF',
                                    borderBottom: 'solid 16px transparent'
                                },
                                [theme.breakpoints.down('md')]: {
                                    width: 'calc(100% - 70px)',
                                }
                            }}
                        >
                            <Typography variant='body2'
                                sx={{
                                    fontSize: '0.9rem',
                                    color: 'lightSecondary.main',
                                    fontWeight: '500'
                                }}
                            >Hello David <br /><br />
                                Thanks for connecting !<br /><br />
                                Sure will help out with your query so let me tell you about our product.<br />
                                Sofo Pay is a supply chain optimization and credit risk management solution. The mobile app Sofo Pay is designed for the needs of SMEs and decentralized logistics.</Typography>
                            <Typography variant='body2'
                                sx={{
                                    fontSize: '12px',
                                    color: 'ltheme.main',
                                    fontWeight: '500',
                                    textAlign: 'right'
                                }}
                            >11:48 AM</Typography>
                        </Box>
                    </Box>
                </Box>
                {/* Chat Footer */}
                <Box
                    sx={{
                        backgroundColor: 'extraLTheme.main',
                        borderStyle: 'solid',
                        borderWidth: '1px',
                        borderColor: 'ltheme.main',
                        p: 1.5,
                        borderRadius: '0 0 10px 10px',
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >

                    <Button variant="none"
                        sx={{
                            textTransform: 'inherit',
                            p: "8px 8px",
                            minWidth: 'inherit',
                            boxShadow: 'none'
                        }}>
                        <SentimentSatisfiedAltIcon
                            sx={{
                                color: "lightSecondary.main",
                                fontSize: "20px"
                            }}
                        />
                    </Button>
                    <Button variant="none"
                        sx={{
                            textTransform: 'inherit',
                            p: "8px 8px",
                            minWidth: 'inherit',
                            boxShadow: 'none'
                        }}>
                        <AttachFileIcon
                            sx={{
                                color: "lightSecondary.main",
                                fontSize: "20px"
                            }}
                        />
                    </Button>
                    <TextField id="outlined-basic" label="" variant="outlined" placeholder='Type a message'
                        sx={{
                            width: 'calc(100% - 153px)',
                            '& .MuiOutlinedInput-notchedOutline': {
                                border: 'none'
                            },
                            '& .MuiInputBase-input': {
                                padding: '0px 10px',
                                fontSize: '0.95rem',
                                color: 'lightSecondary.main',
                                fontWeight: '600'
                            }
                        }}
                    />
                    <Button variant="none"
                        sx={{
                            textTransform: 'inherit',
                            p: "8px 8px",
                            minWidth: 'inherit',
                            boxShadow: 'none',
                            borderRadius: '0',
                            borderRight: 'solid 1px',
                            borderRightColor: 'lightSecondary.main'
                        }}>
                        <MicIcon
                            sx={{
                                color: "lightSecondary.main",
                                fontSize: "20px"
                            }}
                        />
                    </Button>
                    <Button variant="contained"
                        sx={{
                            textTransform: 'inherit',
                            p: "8px 8px",
                            backgroundColor: 'lightSecondary.main',
                            minWidth: 'inherit',
                            boxShadow: 'none',
                            ml: 1
                        }}>
                        <SendIcon
                            sx={{
                                color: "#fff",
                                fontSize: "20px"
                            }}
                        />
                    </Button>
                </Box>
            </Box>
        </>
    )
}

export default ChatContainer