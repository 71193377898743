import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const baseUrl = process.env.REACT_APP_base_URL;

const updateSolutionStatusApi = createApi({
    reducerPath: 'updateSolutionStatusApi',
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers) => {
            // Add any headers you need here
            return headers;
        },
    }),
    endpoints: (builder) => ({
        updateSolutionStatus: builder.mutation({
            query: ({ id, status, userId }) => ({
                url: `solution_requirements/${id}`,
                method: 'PUT',
                body: { 
                solution_status: status,
                user_id:userId, 
                },
            }),
            transformResponse: (response) => {
                const savedData = response.data;
                return {
                    id: savedData._id,
                    solutionId: savedData.solution_id,
                    boardId: savedData.board_id,
                    solutionStatus: savedData.solution_status,
                    solutionName: savedData.solution_name,
                    techName: savedData.tech_name,
                    industryName: savedData.industry_name,
                    useCaseName: savedData.usecase_name,
                    useCaseId: savedData.use_case_id,
                    dateAdded: savedData.date_added,
                    updatedAt: savedData.updated_at,
                };
            },
            // These properties should be part of the mutation, not the query
            refetchOnWindowFocus: true,
            retry: true,
            onError: (error) => {
                console.error('Fetch user details error:', error);
                // Handle error cases as per your application's requirements
            },
        }),
    }),
});

export const { useUpdateSolutionStatusMutation } = updateSolutionStatusApi;
export default updateSolutionStatusApi;
