import React, { useEffect, useState } from 'react'
import {
    Box,
    Typography,
    TextField,
    Button,
    Autocomplete
} from '@mui/material'
import { useTheme } from '@emotion/react';
import { DeleteOutlineOutlined } from '@mui/icons-material';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import { useDeleteRequirementBoardMutation } from '../../redux/api/Requirement Boards/deleteBoard';
import { useLocation, useNavigate } from 'react-router-dom';
import { hideLoader, showLoader } from '../../redux/features/loader';
import { useDispatch, useSelector } from 'react-redux';
import { setRefetchKanbanBoard, setRefetchRequirementBoard } from '../../redux/features/refetch';


const DeleteBoardAlert = ({ handleClose, currentBoardId, goBackFromAlertHandle  , showToastMessage , deleteTitle }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // const searchParams = new URLSearchParams(location.search);
    // const boardId = searchParams.get('board_id');
    const selectedBoardId = localStorage.getItem("boardId")
    const [deleteRequirementBoard, { isLoading: deleteLoading , isError:deleteError}] = useDeleteRequirementBoardMutation();
    const deleteBoard = async () => {
        try {
            const boardId = currentBoardId || selectedBoardId;
            const response =  await deleteRequirementBoard({ boardId }).unwrap();
            const successMessage ="Board deleted ";
            if(response.status === 200){
                showToastMessage(successMessage, "success");
            }
            // refetchBoardDetails(); 
            handleClose(); 
            dispatch(setRefetchRequirementBoard(true));
            dispatch(setRefetchKanbanBoard(true));
            navigate('/bsi-co-lab'); 
        } catch (error) {
            const errorMessage = error?.message || "Failed to delete folder";
            showToastMessage(errorMessage, "error");
        }
    };
    useEffect(() => {
        if (deleteLoading) {
          dispatch(showLoader());
        } else {
          dispatch(hideLoader());
        }
      }, [deleteLoading, dispatch]);

    return (
        <>
            <Box
                sx={{
                    mb: 4,
                    mt: 3
                }}
            >
                <Typography variant='h5'
                    sx={{
                        fontSize: "1.1rem",
                        color: "secondary.main",
                        fontWeight: "700"
                    }}>
                    Are you sure you want to delete this {deleteTitle}?
                </Typography>
            </Box>
            <Box
                sx={{
                    mt: 3,
                    display: 'flex',
                    gap: 2,
                }}
            >
                <Button variant="outlined"
                    sx={{
                        textTransform: 'inherit',
                        p: "10px 25px",
                        width: '50%',
                        borderWidth: '2px',
                        fontWeight: '600',
                        borderColor: 'primary.main',
                        [theme.breakpoints.down('md')]: {
                            width: "50%",
                            p: "10px 10px",
                            fontSize: '0.7rem',
                        },
                        '&:hover': {
                            borderWidth: '2px',
                        }
                    }}
                    onClick={goBackFromAlertHandle}
                >
                    <ArrowBackIosNewOutlinedIcon
                        style={{
                            fontSize: '16px',
                            marginRight: "5px",
                            marginTop: "-2px",
                            
                        }}
                        
                        />
                    Go back
                </Button>
                <Button variant="outlined"
                noWrap
                    sx={{
                        textTransform: 'inherit',
                        p: "10px 25px",
                        borderColor: 'red.main',
                        color: 'red.main',
                        width:"52%",
                        fontSize: '0.9rem',
                        fontWeight: '600',
                        [theme.breakpoints.down('md')]: {
                            width: "52%",
                            p: "10px 10px",
                            fontSize: '0.7rem',
                        },
                        '&:hover': {
                            borderColor: 'red.main',
                            backgroundColor: 'transparent'
                        }
                    }}
                    onClick={deleteBoard}
                >
                    <DeleteOutlineOutlined
                        style={{
                            fontSize: '20px',
                            marginRight: "5px",
                            marginTop: "-3px"
                        }} />
                    Delete {deleteTitle}
                </Button>
            </Box>
        </>
    )
}

export default DeleteBoardAlert