import React, { useState } from 'react';
import {
    Box,
    Button,
    MenuItem,
    Stack,
    Typography,
    Menu,
    styled,
    InputBase,
    Radio,
    FormControlLabel,
    Select,
    FormControl,
    Grid,
    Card,
    CardContent,
    Paper,
    IconButton,
    ListItemText,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined';
import { useTheme } from '@emotion/react';
import SearchIcon from '@mui/icons-material/Search';
import { KeyboardArrowDownOutlined } from '@mui/icons-material';
import NorthOutlinedIcon from '@mui/icons-material/NorthOutlined';
import SouthOutlinedIcon from '@mui/icons-material/SouthOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import AddIcon from '@mui/icons-material/Add';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import { getImages } from '../../commonComponents/const';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import StarOutlinedIcon from '@mui/icons-material/StarOutlined';
import EastOutlinedIcon from '@mui/icons-material/EastOutlined';
import { Link } from 'react-router-dom';

const MySolutions = () => {
    const theme = useTheme();
    const Search = styled('div')(({ theme }) => ({
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: '#ECF6FF',
        '&:hover': {
            backgroundColor: '#ECF6FF',
        },
        marginLeft: 0,
        marginBottom: 0,
        width: '300px',
        [theme.breakpoints.down('md')]: {
            width: 'calc(100% - 180px)',
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    }));
    const SearchIconWrapper = styled('div')(({ theme }) => ({
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        right: '0',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }));

    const StyledInputBase = styled(InputBase)(({ theme }) => ({
        color: '#106EED',
        width: '100%',
        '& .MuiInputBase-input': {
            padding: theme.spacing(1.9, 0, 1.9, 1.7),
            paddingRight: `calc(1em + ${theme.spacing(4)})`,
            transition: theme.transitions.create('width'),
            width: '100%',
            fontSize: '12px',
            fontWeight: '500',
            '@media (max-width:1600px)': {
                paddingTop: "11px",
                paddingBottom: "11px",
            },
        },
    }));
    // Sort and Filter
    const [openSort, setOpenSort] = useState(false);
    const [selectedSortOption, setSelectedSortOption] = useState('');

    const [openFilter, setOpenFilter] = useState(false);
    const [anchorElSort, setAnchorElSort] = useState(null);
    const [anchorElFilter, setAnchorElFilter] = useState(null);
    const handleClickSort = (event) => {
        setAnchorElSort(event.currentTarget);
        setOpenSort(!openSort);
    };
    const handleCloseSort = (event) => {
        setAnchorElSort(null);
        setOpenSort(false);
        setSelectedSortOption(event.target.value);
    };
    const handleClickFilter = (event) => {
        setAnchorElFilter(event.currentTarget);
        setOpenFilter(!openFilter);
    };
    const handleCloseFilter = () => {
        setAnchorElFilter(null);
        setOpenFilter(false);
    };
    // Select 
    const [designation, setDesignation] = useState('');
    const handleChange = (event) => {
        setDesignation(event.target.value);
    };

    const [anchorElFolderDropdown, setAnchorElFolderDropdown] = useState(null);
    const openFolderDropdown = Boolean(anchorElFolderDropdown);
    const handleFolderDropdownClick = (event) => {
        setAnchorElFolderDropdown(event.currentTarget);
    };
    const handleFolderDropdownClose = () => {
        setAnchorElFolderDropdown(null);
    };
    const [anchorElSolutionListOptions, setAnchorElSolutionListOptions] = useState(null);
    const openSolutionListOptions = Boolean(anchorElSolutionListOptions);
    const handleSolutionListOptionsClick = (event) => {
        setAnchorElSolutionListOptions(event.currentTarget);
    };
    const handleSolutionListOptionsClose = () => {
        setAnchorElSolutionListOptions(null);
    };
    return (
        <>
            <Typography
                underline="hover"
                key="2"
                color="inherit"
                sx={{
                    color: '#2A589C',
                    textDecoration: "none",
                    fontSize: "1.4rem",
                    fontWeight: "700",
                    color: '#073985',
                    [theme.breakpoints.down('md')]: {
                        fontSize: '1.1rem',
                    },
                }}
            >Investment Summary</Typography>

            <Grid container mt={1} columnSpacing={2}>
                <Grid item xs={12} md={6} lg={3}>
                    <Card
                        sx={{
                            boxShadow: 'none',
                            mb: 2
                        }}
                    >
                        <CardContent
                            style={{
                                paddingTop: "25px"
                            }}
                        >
                            <Typography
                                variant="body2"
                                sx={{
                                    fontSize: '1.1rem',
                                    fontWeight: '500',
                                    color: 'ltheme.main',
                                    [theme.breakpoints.down('lg')]: {
                                        fontSize: '1rem',
                                    },
                                }}
                            >
                                Raised Amount
                                <InfoOutlinedIcon
                                    sx={{
                                        verticalAlign: 'middle',
                                        ml: 0.5,
                                        fontSize: '17px'
                                    }}
                                /></Typography>
                            <Typography
                                variant="h6"
                                sx={{
                                    fontSize: '2.4rem',
                                    fontWeight: '700',
                                    lineHeight: '2.7rem',
                                    color: 'lightSecondary.main',
                                    mb: 0.5,
                                    mt: 0.5,
                                    [theme.breakpoints.down('xl')]: {
                                        fontSize: '2.1rem',
                                    },
                                    [theme.breakpoints.down('md')]: {
                                        fontSize: '1.7rem',
                                        lineHeight: '2rem'
                                    },
                                }}
                            >$1.2m</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <Card
                        sx={{
                            boxShadow: 'none',
                            mb: 2
                        }}
                    >
                        <CardContent
                            style={{
                                paddingTop: "25px"
                            }}
                        >
                            <Typography
                                variant="body2"
                                sx={{
                                    fontSize: '1.1rem',
                                    fontWeight: '500',
                                    color: 'ltheme.main',
                                    [theme.breakpoints.down('lg')]: {
                                        fontSize: '1rem',
                                    },
                                }}
                            >
                                Total Investments
                                <InfoOutlinedIcon
                                    sx={{
                                        verticalAlign: 'middle',
                                        ml: 0.5,
                                        fontSize: '17px'
                                    }}
                                /></Typography>
                            <Typography
                                variant="h6"
                                sx={{
                                    fontSize: '2.4rem',
                                    fontWeight: '700',
                                    lineHeight: '2.7rem',
                                    color: 'lightSecondary.main',
                                    mb: 0.5,
                                    mt: 0.5,
                                    [theme.breakpoints.down('xl')]: {
                                        fontSize: '2.1rem',
                                    },
                                    [theme.breakpoints.down('md')]: {
                                        fontSize: '1.7rem',
                                        lineHeight: '2rem'
                                    },
                                }}
                            >$950k
                             <Box component="span"
                                    sx={{
                                        fontSize: '1rem',
                                        lineHeight: '1rem',
                                        color: '#106EED',
                                        marginLeft: '10px',
                                        fontWeight: '600',
                                        [theme.breakpoints.down('xl')]: {
                                            fontSize: '0.85rem',
                                        },
                                    }}
                                >in 2 Solutions</Box></Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <Card
                        sx={{
                            boxShadow: 'none',
                            mb: 2
                        }}
                    >
                        <CardContent
                            style={{
                                paddingTop: "25px"
                            }}
                        >
                            <Typography
                                variant="body2"
                                sx={{
                                    fontSize: '1.1rem',
                                    fontWeight: '500',
                                    color: 'ltheme.main',
                                    [theme.breakpoints.down('lg')]: {
                                        fontSize: '1rem',
                                    },
                                }}
                            >
                                Total Commitment
                                <InfoOutlinedIcon
                                    sx={{
                                        verticalAlign: 'middle',
                                        ml: 0.5,
                                        fontSize: '17px'
                                    }}
                                /></Typography>
                            <Typography
                                variant="h6"
                                sx={{
                                    fontSize: '2.4rem',
                                    fontWeight: '700',
                                    lineHeight: '2.7rem',
                                    color: 'lightSecondary.main',
                                    mb: 0.5,
                                    mt: 0.5,
                                    [theme.breakpoints.down('xl')]: {
                                        fontSize: '2.1rem',
                                    },
                                    [theme.breakpoints.down('md')]: {
                                        fontSize: '1.7rem',
                                        lineHeight: '2rem'
                                    },
                                }}
                            >$200k
                                <Box component="span"
                                    sx={{
                                        fontSize: '1rem',
                                        lineHeight: '1rem',
                                        color: '#106EED',
                                        marginLeft: '10px',
                                        fontWeight: '600',
                                        [theme.breakpoints.down('xl')]: {
                                            fontSize: '0.85rem',
                                        },
                                    }}
                                >in 2 Solutions</Box></Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <Card
                        sx={{
                            boxShadow: 'none',
                            mb: 2
                        }}
                    >
                        <CardContent
                            style={{
                                paddingTop: "25px"
                            }}
                        >
                            <Typography
                                variant="body2"
                                sx={{
                                    fontSize: '1.1rem',
                                    fontWeight: '500',
                                    color: 'ltheme.main',
                                    [theme.breakpoints.down('lg')]: {
                                        fontSize: '1rem',
                                    },
                                }}
                            >
                                Overall Return
                                <InfoOutlinedIcon
                                    sx={{
                                        verticalAlign: 'middle',
                                        ml: 0.5,
                                        fontSize: '17px'
                                    }}
                                /></Typography>
                            <Typography
                                variant="h6"
                                sx={{
                                    fontSize: '2.4rem',
                                    fontWeight: '700',
                                    lineHeight: '2.7rem',
                                    color: 'lightSecondary.main',
                                    mb: 0.5,
                                    mt: 0.5,
                                    [theme.breakpoints.down('xl')]: {
                                        fontSize: '2.1rem',
                                    },
                                    [theme.breakpoints.down('md')]: {
                                        fontSize: '1.7rem',
                                        lineHeight: '2rem'
                                    },
                                }}
                            >$23.04k
                                <span
                                    style={{
                                        fontSize: '1rem',
                                        color: '#7CDDA3',
                                        lineHeight: '1rem',
                                        fontWeight: '600'
                                    }}
                                >
                                    <ArrowDropUpOutlinedIcon
                                        sx={{
                                            verticalAlign: 'middle'
                                        }}
                                    /> 2.4%
                                </span>
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            {/* Search and Sort section */}
            <Stack
                direction="row"
                sx={{
                    justifyItems: 'center',
                    justifyContent: 'space-between',
                    mt: 3,
                    '@media (max-width:1600px) and (min-width:1200px)': {
                        mt: 1.5,
                    },
                    [theme.breakpoints.down('md')]: {
                        flexWrap: 'wrap',
                    }
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        [theme.breakpoints.down('md')]: {
                            width: '100%'
                        },
                        [theme.breakpoints.down('sm')]: {
                            flexWrap: 'wrap'
                        }
                    }}
                >
                    <Button variant="contained"
                        sx={{
                            textTransform: 'inherit',
                            py: "11px",
                            boxShadow: 'none',
                            px: 3,
                            '&:hover': {
                                boxShadow: 'none'
                            },
                            [theme.breakpoints.down('md')]: {
                                px: 2.5,
                                width: '100%',
                            }
                        }}
                    >
                        <AddIcon
                            sx={{
                                fontSize: '20px',
                                marginRight: "8px"
                            }}
                        /> Create New Solution
                    </Button>
                </Box>
                <Stack
                    direction="row"
                    sx={{
                        [theme.breakpoints.down('md')]: {
                            mt: 2,
                            ml: 'auto'
                        },
                        [theme.breakpoints.down('md')]: {
                            mt: 1.2,
                            ml: '0',
                            width: '100%',
                            flexWrap: 'wrap'
                        }
                    }}
                >

                    <Search
                        sx={{
                            backgroundColor: 'extraLTheme.main',
                            mr: 2,
                            [theme.breakpoints.down('md')]: {
                                mr: 0,
                                width: '100%',
                                mb: 1.5
                            }
                        }}
                    >
                        <SearchIconWrapper>
                            <SearchIcon sx={{ color: "lightSecondary.main" }} />
                        </SearchIconWrapper>
                        <StyledInputBase
                            id="searchBox"
                            placeholder="Search solution"
                            inputProps={{ 'aria-label': 'search' }}
                        />
                    </Search>
                    <Box
                        sx={{
                            mr: 2,
                            [theme.breakpoints.down('md')]: {
                                width: "calc(50% - 0.5rem)",
                                mr: '0.5rem',
                            }
                        }}
                    >
                        <Button
                            id="basic-button"
                            aria-controls={openSort ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={openSort ? 'true' : undefined}
                            onClick={handleClickSort}
                            sx={{
                                background: "#fff",
                                fontSize: "0.9rem",
                                color: "lightSecondary.main",
                                textTransform: "inherit",
                                fontWeight: "600",
                                p: "10px 20px",
                                '&:hover': {
                                    background: "#fff",
                                },
                                [theme.breakpoints.down('md')]: {
                                    width: "100%"
                                }
                            }}
                        >
                            Sort
                            <KeyboardArrowDownOutlined
                                sx={{

                                }}
                            />
                        </Button>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorElSort}
                            open={openSort}
                            onClose={handleCloseSort}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                            sx={{
                                '& .MuiMenu-paper': {
                                    borderRadius: "10px",
                                    border: "solid 1px #106EED"
                                }
                            }}
                        >
                            <MenuItem
                                sx={{
                                    '& .MuiFormControlLabel-label': {
                                        fontSize: "0.9rem",
                                        color: "#2A589C",
                                        fontWeight: "600",
                                    }
                                }}
                                onClick={handleCloseSort}>
                                <FormControlLabel
                                    value="CreatedUp" control={<Radio sx={{ paddingY: 0, color: "#2A589C" }} checked={selectedSortOption === 'CreatedUp'} />} label="Created" />
                                <NorthOutlinedIcon sx={{
                                    fontSize: "1.1rem",
                                    color: "#2A589C"
                                }} />
                            </MenuItem>
                            <MenuItem
                                sx={{
                                    '& .MuiFormControlLabel-label': {
                                        fontSize: "0.9rem",
                                        color: "#2A589C",
                                        fontWeight: "600",
                                    }
                                }}
                                onClick={handleCloseSort}>
                                <FormControlLabel
                                    value="CreatedDown" control={<Radio sx={{ paddingY: 0, color: "#2A589C" }} checked={selectedSortOption === 'CreatedDown'} />} label="Created" />
                                <SouthOutlinedIcon sx={{
                                    fontSize: "1.1rem",
                                    color: "#2A589C"
                                }} />
                            </MenuItem>
                            <MenuItem
                                sx={{
                                    '& .MuiFormControlLabel-label': {
                                        fontSize: "0.9rem",
                                        color: "#2A589C",
                                        fontWeight: "600",
                                    }
                                }}
                                onClick={handleCloseSort}>
                                <FormControlLabel
                                    value="name-a-z" control={<Radio sx={{ paddingY: 0, color: "#2A589C" }} checked={selectedSortOption === 'name-a-z'} />} label="Name A-Z" />
                            </MenuItem>
                            <MenuItem
                                sx={{
                                    '& .MuiFormControlLabel-label': {
                                        fontSize: "0.9rem",
                                        color: "#2A589C",
                                        fontWeight: "600",
                                    }
                                }}
                                onClick={handleCloseSort}>
                                <FormControlLabel
                                    value="name-z-a" control={<Radio sx={{ paddingY: 0, color: "#2A589C" }} checked={selectedSortOption === 'name-z-a'} />} label="Name Z-A" />
                            </MenuItem>
                        </Menu>
                    </Box>
                    <Box
                        sx={{
                            [theme.breakpoints.down('md')]: {
                                width: "50%"
                            }
                        }}
                    >
                        <Button
                            id="basic-button"
                            aria-controls={openFilter ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={openFilter ? 'true' : undefined}
                            onClick={handleClickFilter}
                            sx={{
                                background: "#fff",
                                fontSize: "0.9rem",
                                color: "lightSecondary.main",
                                textTransform: "inherit",
                                fontWeight: "600",
                                p: "10px 20px",
                                '&:hover': {
                                    background: "#fff",
                                },
                                [theme.breakpoints.down('md')]: {
                                    width: "100%"
                                }
                            }}
                        >
                            Filter
                            <KeyboardArrowDownOutlined
                            />
                        </Button>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorElFilter}
                            open={openFilter}
                            onClose={handleCloseFilter}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                            sx={{
                                '& .MuiMenu-paper': {
                                    borderRadius: "10px",
                                    border: "solid 1px #106EED",
                                    minWidth: "280px",
                                    px: 2.3,
                                    py: 1
                                }
                            }}
                        >
                            <Box sx={{
                                mb: 1.5
                            }}>
                                <Typography
                                    sx={{
                                        fontSize: "12px",
                                        fontWeight: '500',
                                        color: 'ltheme.main',
                                        lineHeight: '24px',
                                    }}
                                    variant="body2">
                                    Select Industry
                                </Typography>
                                <FormControl fullWidth
                                    sx={{
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            border: 'none'
                                        }
                                    }}
                                >
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={designation}
                                        onChange={handleChange}
                                        displayEmpty
                                        IconComponent={KeyboardArrowDownOutlinedIcon}
                                        sx={{
                                            background: "#ECF6FF",
                                            '& .MuiSelect-select': {
                                                minHeight: "20px",
                                                padding: "11px 14px",
                                                color: "lightSecondary.main",
                                                fontWeight: "600",
                                                fontSize: "0.9rem"
                                            },
                                            '& .MuiSelect-icon': {
                                                color: 'lightSecondary.main'
                                            },
                                        }}
                                    >
                                        <MenuItem>Select Industry</MenuItem>
                                        <MenuItem value={2}>Insurance</MenuItem>
                                        <MenuItem value={3}>Citizen service</MenuItem>
                                        <MenuItem value={4}>Healthcare</MenuItem>
                                        <MenuItem value={5}>Supply chain</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box>
                                <Typography
                                    sx={{
                                        fontSize: "12px",
                                        fontWeight: '500',
                                        color: 'ltheme.main',
                                        lineHeight: '24px',
                                    }}
                                    variant="body2">
                                    Select Type
                                </Typography>
                                <FormControl fullWidth
                                    sx={{
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            border: 'none'
                                        }
                                    }}
                                >
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={designation}
                                        onChange={handleChange}
                                        displayEmpty
                                        IconComponent={KeyboardArrowDownOutlinedIcon}
                                        sx={{
                                            background: "#ECF6FF",
                                            '& .MuiSelect-select': {
                                                minHeight: "20px",
                                                padding: "11px 14px",
                                                color: "lightSecondary.main",
                                                fontWeight: "600",
                                                fontSize: "0.9rem"
                                            },
                                            '& .MuiSelect-icon': {
                                                color: 'lightSecondary.main'
                                            },
                                        }}
                                    >
                                        <MenuItem>Select Type</MenuItem>
                                        <MenuItem value={12}>All</MenuItem>
                                        <MenuItem value={13}>Starred</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                gap: 2,
                                mt: 2
                            }}>
                                <Box sx={{
                                    width: '50%'
                                }}>
                                    <Button variant="outlined"
                                        sx={{
                                            width: "100%",
                                            textTransform: 'inherit',
                                            p: "7px 15px"
                                        }}
                                    >
                                        Clear
                                    </Button>
                                </Box>
                                <Box sx={{
                                    width: '50%'
                                }}>
                                    <Button variant="contained"
                                        sx={{
                                            width: "100%",
                                            textTransform: 'inherit',
                                            p: "7px 15px",
                                            boxShadow: 'none'
                                        }}>Apply
                                    </Button>
                                </Box>
                            </Box>
                        </Menu>
                    </Box>
                </Stack>
            </Stack>

            <Box
                sx={{
                    mt: 3,
                    '@media (max-width:1600px) and (min-width:1200px)': {
                        mt: 1.5,
                    },
                }}
            >
                <Typography variant='h6'
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        color: 'secondary.main',
                        fontSize: "1.1rem",
                        fontWeight: "700",
                        mb: 1
                    }}
                >
                    All Solutions
                    <div
                        style={{
                            position: 'relative'
                        }}
                    >
                        <Button
                            id="basic-button1"
                            aria-controls={openFolderDropdown ? 'basic-menu1' : undefined}
                            aria-haspopup="true"
                            aria-expanded={openFolderDropdown ? 'true' : undefined}
                            onClick={handleFolderDropdownClick}
                            sx={{
                                p: '10px 0',
                                minWidth: '10px',
                                width: '100%',
                                verticalAlign: '-5px',
                                display: 'flex',
                                justifyContent: 'space-between',
                                color: 'secondary.main'
                            }}
                        >
                            <MoreVertOutlinedIcon
                                sx={{
                                    fontSize: "1.2rem",
                                }}
                            />
                        </Button>
                        <Menu
                            id="basic-menu1"
                            anchorEl={anchorElFolderDropdown}
                            open={openFolderDropdown}
                            onClose={handleFolderDropdownClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button1',
                            }}
                            sx={{
                                '& .MuiMenu-paper': {
                                    borderRadius: "4px",
                                    border: "none",
                                    minWidth: "210px",
                                    p: 0.5,
                                }
                            }}
                        >
                            <Paper
                                sx={{
                                    width: 210,
                                    maxWidth: '100%',
                                    background: "#fff",
                                    borderRadius: "10px",
                                    borderBottom: "none",
                                    boxShadow: "none",
                                    p: 0.5
                                }}>
                                <MenuItem onClick={handleFolderDropdownClose}
                                    sx={{
                                        fontSize: "0.9rem",
                                        fontWeight: "600",
                                        lineHeight: "20px",
                                        color: "lightSecondary.main",
                                        py: 1,
                                        px: 1.4,
                                        '&:hover': {
                                            backgroundColor: 'extraLTheme.main'
                                        },
                                        '&.active': {
                                            backgroundColor: 'primary.main',
                                            color: "#fff",
                                            borderRadius: "10px"
                                        }
                                    }}
                                >All</MenuItem>
                                <MenuItem onClick={handleFolderDropdownClose}
                                    sx={{
                                        fontSize: "0.9rem",
                                        fontWeight: "600",
                                        lineHeight: "20px",
                                        color: "lightSecondary.main",
                                        py: 1,
                                        px: 1.4,
                                        '&:hover': {
                                            backgroundColor: 'extraLTheme.main'
                                        },
                                        '&.active': {
                                            backgroundColor: 'primary.main',
                                            color: "#fff",
                                            borderRadius: "10px"
                                        }
                                    }}
                                >Starred</MenuItem>
                                <MenuItem onClick={handleFolderDropdownClose}
                                    sx={{
                                        fontSize: "0.9rem",
                                        fontWeight: "600",
                                        lineHeight: "20px",
                                        color: "lightSecondary.main",
                                        py: 1,
                                        px: 1.4,
                                        '&:hover': {
                                            backgroundColor: 'extraLTheme.main'
                                        },
                                        '&.active': {
                                            backgroundColor: 'primary.main',
                                            color: "#fff",
                                            borderRadius: "10px"
                                        }
                                    }}
                                >Archived</MenuItem>
                                <MenuItem onClick={handleFolderDropdownClose}
                                    sx={{
                                        fontSize: "0.9rem",
                                        fontWeight: "600",
                                        lineHeight: "20px",
                                        color: "lightSecondary.main",
                                        py: 1,
                                        px: 1.4,
                                        '&:hover': {
                                            backgroundColor: 'extraLTheme.main'
                                        },
                                        '&.active': {
                                            backgroundColor: 'primary.main',
                                            color: "#fff",
                                            borderRadius: "10px"
                                        }
                                    }}
                                >Recently deleted</MenuItem>
                            </Paper>
                        </Menu>
                    </div>
                </Typography>

                <Grid container mt={1} columnSpacing={2}>
                    <Grid item xs={12} md={6} lg={6} sx={{mb:2}}>
                        <Card
                            sx={{
                                boxShadow: 'none',
                                borderRadius: "10px"
                            }}
                        >
                            <CardContent
                                sx={{
                                    p: 0
                                }}
                                style={{
                                    paddingBottom: "0"
                                }}
                            >
                                <Box
                                    sx={{
                                        px: 2,
                                        pt: 2,
                                        pb: 2,
                                        [theme.breakpoints.down('md')]: {
                                            pt: 3,
                                            pb: 3,
                                        }
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "start",
                                            justifyContent: "space-between"
                                        }}
                                    >
                                        <Box>
                                            <img src={getImages('image_172.png')} />
                                        </Box>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <IconButton
                                                sx={{
                                                    p: 0.5,
                                                }}>
                                                <OpenInNewOutlinedIcon sx={{color:'lightSecondary.main'}} />
                                            </IconButton>
                                            <IconButton
                                                sx={{
                                                    p: 0.5,
                                                }}>
                                                <StarOutlinedIcon sx={{color:'lightSecondary.main'}} />
                                            </IconButton>
                                            <div
                                                style={{
                                                    position: 'relative'
                                                }}
                                            >
                                                <IconButton
                                                    id="basic-button1"
                                                    aria-controls={openSolutionListOptions ? 'basic-menu1' : undefined}
                                                    aria-haspopup="true"
                                                    aria-expanded={openSolutionListOptions ? 'true' : undefined}
                                                    onClick={handleSolutionListOptionsClick}
                                                    sx={{
                                                        p: 0.5,
                                                    }}
                                                >
                                                    <MoreVertOutlinedIcon
                                                        sx={{
                                                            fontSize: "1.2rem",
                                                            color:'lightSecondary.main'
                                                        }}
                                                    />
                                                </IconButton>
                                                <Menu
                                                    id="basic-menu1"
                                                    anchorEl={anchorElSolutionListOptions}
                                                    open={openSolutionListOptions}
                                                    onClose={handleSolutionListOptionsClose}
                                                    MenuListProps={{
                                                        'aria-labelledby': 'basic-button1',
                                                    }}
                                                    sx={{
                                                        '& .MuiMenu-paper': {
                                                            borderRadius: "4px",
                                                            border: "none",
                                                            minWidth: "210px",
                                                            p: 0.5,
                                                        }
                                                    }}
                                                >
                                                    <Paper
                                                        sx={{
                                                            width: 210,
                                                            maxWidth: '100%',
                                                            background: "#fff",
                                                            borderRadius: "10px",
                                                            borderBottom: "none",
                                                            boxShadow: "none",
                                                            p: 0.5
                                                        }}>
                                                        <MenuItem
                                                            sx={{
                                                                fontSize: "0.9rem",
                                                                fontWeight: "600",
                                                                lineHeight: "20px",
                                                                color: "lightSecondary.main",
                                                                py: 1,
                                                                px: 1.4,
                                                                '&:hover': {
                                                                    backgroundColor: 'extraLTheme.main'
                                                                },
                                                                '&.active': {
                                                                    backgroundColor: 'primary.main',
                                                                    color: "#fff",
                                                                    borderRadius: "10px"
                                                                }
                                                            }}
                                                        >
                                                            <Link to="/edit-solution-details"
                                                                style={{
                                                                    textDecoration: 'none', 
                                                                    color: 'inherit',
                                                                    display: 'block',
                                                                    width: '100%'
                                                                }}
                                                            >Edit</Link>
                                                        </MenuItem>
                                                        <MenuItem onClick={handleSolutionListOptionsClose}
                                                            sx={{
                                                                fontSize: "0.9rem",
                                                                fontWeight: "600",
                                                                lineHeight: "20px",
                                                                color: "lightSecondary.main",
                                                                py: 1,
                                                                px: 1.4,
                                                                '&:hover': {
                                                                    backgroundColor: 'extraLTheme.main'
                                                                },
                                                                '&.active': {
                                                                    backgroundColor: 'primary.main',
                                                                    color: "#fff",
                                                                    borderRadius: "10px"
                                                                }
                                                            }}
                                                        >Archive</MenuItem>
                                                        <MenuItem onClick={handleSolutionListOptionsClose}
                                                            sx={{
                                                                fontSize: "0.9rem",
                                                                fontWeight: "600",
                                                                lineHeight: "20px",
                                                                color: "lightSecondary.main",
                                                                py: 1,
                                                                px: 1.4,
                                                                '&:hover': {
                                                                    backgroundColor: 'extraLTheme.main'
                                                                },
                                                                '&.active': {
                                                                    backgroundColor: 'primary.main',
                                                                    color: "#fff",
                                                                    borderRadius: "10px"
                                                                }
                                                            }}
                                                        >Delete</MenuItem>
                                                    </Paper>
                                                </Menu>
                                            </div>
                                        </Box>
                                    </Box>
                                    <Box
                                        sx={{
                                            mt: 1,
                                            maxWidth: '80%',
                                            [theme.breakpoints.down('md')]: {
                                                mt: 1,
                                                maxWidth: '100%',
                                            }
                                        }}
                                    >
                                        <Box>
                                            <Typography variant="h6" color="textSecondary"
                                                sx={{
                                                    fontSize: "1.2rem",
                                                    fontWeight: "700",
                                                    color: "secondary.main",
                                                    mb: 0.5
                                                }}
                                            >
                                                TRUSTDOCK Digital ID
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary"
                                                sx={{
                                                    fontSize: "0.95rem",
                                                    fontWeight: "500",
                                                    color: "secondary.main",
                                                    minHeight: '41px',
                                                    maxHeight: '41px',
                                                    overflow: 'hidden'
                                                }}
                                            >
                                                As an extension of our services, we offer TRUSTDOCK ID. TRUSTDOCK ID is a secure virtual identity wallet where users can store their TRUSTDOCK-...
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box
                                    sx={{
                                        py: 2,
                                        px: 2,
                                        backgroundColor: "rgba(219, 235, 248, 0.2)",
                                        display: "flex",
                                        alignItems: "start",
                                        justifyContent: "space-between",
                                        borderTop: "solid 1px rgba(162, 194, 241, 1)",
                                        minHeight: '102px',
                                        [theme.breakpoints.down('md')]: {
                                            flexWrap: 'wrap',
                                            px: 2
                                        }
                                    }}
                                >
                                    <Box display={"flex"}
                                        sx={{
                                            px: "0",
                                            pr: 1,
                                            alignItems: "center"
                                        }}
                                    >
                                        <Box>
                                            <img style={{ display: "block" }} src={getImages('headquarter_icon.png')} />
                                        </Box>
                                        <ListItemText
                                            sx={{
                                                pl: '10px',
                                                width: "60%"
                                            }}
                                        >
                                            <Typography noWrap
                                                sx={{
                                                    fontSize: "12px",
                                                    fontWeight: '700',
                                                    color: 'secondary.main',
                                                    lineHeight: '15px',
                                                }}
                                                variant="h6">Headquarters
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                sx={{
                                                    fontSize: '12px',
                                                    fontWeight: '500',
                                                    color: 'lightSecondary.main',
                                                }}
                                            >London, UK</Typography>
                                        </ListItemText>
                                    </Box>
                                    <Box display={"flex"}
                                        sx={{
                                            px: "0",
                                            pr: 1,
                                            alignItems: "center"
                                        }}
                                    >
                                        <Box>
                                            <img style={{ display: "block" }} src={getImages('industry_icon.png')} />
                                        </Box>
                                        <ListItemText
                                            sx={{
                                                pl: '10px',
                                                width: "60%"
                                            }}
                                        >
                                            <Typography noWrap
                                                sx={{
                                                    fontSize: "12px",
                                                    fontWeight: '700',
                                                    color: 'secondary.main',
                                                    lineHeight: '15px',
                                                }}
                                                variant="h6">Industries
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                sx={{
                                                    fontSize: '12px',
                                                    fontWeight: '500',
                                                    color: 'lightSecondary.main',
                                                }}
                                            >Finance, Retial</Typography>
                                        </ListItemText>
                                    </Box>
                                    <Box display={"flex"}
                                        sx={{
                                            px: "0",
                                            alignItems: "center"
                                        }}
                                    >
                                        <Box>
                                            <img style={{ display: "block" }} src={getImages('usecase_icon.png')} />
                                        </Box>
                                        <ListItemText
                                            sx={{
                                                pl: '10px',
                                                width: "60%"
                                            }}
                                        >
                                            <Typography noWrap
                                                sx={{
                                                    fontSize: "12px",
                                                    fontWeight: '700',
                                                    color: 'secondary.main',
                                                    lineHeight: '15px',
                                                }}
                                                variant="h6">Use Cases
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                sx={{
                                                    fontSize: '12px',
                                                    fontWeight: '500',
                                                    color: 'lightSecondary.main',
                                                }}
                                            >Smart Contract, Fruad Dection FinTech</Typography>
                                        </ListItemText>
                                    </Box>
                                </Box>
                                <Box
                                    sx={{
                                        py: 2,
                                        px: 2,
                                        backgroundColor: "#fff",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        borderTop: "solid 1px rgba(162, 194, 241, 1)",
                                        [theme.breakpoints.down('md')]: {
                                            flexWrap: 'wrap',
                                            px: 2
                                        }
                                    }}
                                >
                                    <Box
                                        sx={{
                                            [theme.breakpoints.down('sm')]: {
                                                width: '40%',
                                            }
                                        }}
                                    >
                                        <Typography variant='h6'
                                            sx={{
                                                fontSize: '12px',
                                                fontWeight: '600',
                                                color: 'ltheme.main',
                                                mb: 0.3
                                            }}
                                        >Funding amount</Typography>
                                        <Typography variant='h5'
                                            sx={{
                                                fontSize: '1rem',
                                                fontWeight: '600',
                                                color: 'lightSecondary.main'
                                            }}
                                        >$10M</Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            [theme.breakpoints.down('sm')]: {
                                                width: '60%',
                                            }
                                        }}
                                    >
                                        <Typography variant='h6'
                                            sx={{
                                                fontSize: '12px',
                                                fontWeight: '600',
                                                color: 'ltheme.main',
                                                mb: 0.3
                                            }}
                                        >Investment stage</Typography>
                                        <Typography variant='h5'
                                            sx={{
                                                fontSize: '1rem',
                                                fontWeight: '600',
                                                color: 'lightSecondary.main'
                                            }}
                                        >Pre-seed</Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            [theme.breakpoints.down('sm')]: {
                                                width: '40%',
                                            }
                                        }}
                                    >
                                        <Typography variant='h6'
                                            sx={{
                                                fontSize: '12px',
                                                fontWeight: '600',
                                                color: 'ltheme.main',
                                                mb: 0.3
                                            }}
                                        >Total investment</Typography>
                                        <Typography variant='h5'
                                            sx={{
                                                fontSize: '1rem',
                                                fontWeight: '600',
                                                color: 'lightSecondary.main'
                                            }}
                                        >$950k (7)</Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            [theme.breakpoints.down('sm')]: {
                                                width: '40%',
                                            }
                                        }}
                                    >
                                        <Typography variant='h6'
                                            sx={{
                                                fontSize: '12px',
                                                fontWeight: '600',
                                                color: 'ltheme.main',
                                                mb: 0.3
                                            }}
                                        >Commitments</Typography>
                                        <Typography variant='h5'
                                            sx={{
                                                fontSize: '1rem',
                                                fontWeight: '600',
                                                color: 'lightSecondary.main'
                                            }}
                                        >$700k (6)</Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            [theme.breakpoints.down('sm')]: {
                                                width: '20%',
                                            }
                                        }}
                                    >
                                        <Link to="/solution-investment-summary">
                                            <EastOutlinedIcon
                                                sx={{
                                                    fontSize: '2.3rem',
                                                    color: 'lightSecondary.main',
                                                    display: 'block'
                                                }}
                                            />
                                        </Link>
                                    </Box>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6} sx={{mb:2}}>
                        <Card
                            sx={{
                                boxShadow: 'none',
                                borderRadius: "10px"
                            }}
                        >
                            <CardContent
                                sx={{
                                    p: 0
                                }}
                                style={{
                                    paddingBottom: "0"
                                }}
                            >
                                <Box
                                    sx={{
                                        px: 2,
                                        pt: 2,
                                        pb: 2,
                                        [theme.breakpoints.down('md')]: {
                                            pt: 3,
                                            pb: 3,
                                        }
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "start",
                                            justifyContent: "space-between"
                                        }}
                                    >
                                        <Box>
                                            <img src={getImages('image_172.png')} />
                                        </Box>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <IconButton
                                                sx={{
                                                    p: 0.5,
                                                }}>
                                                <OpenInNewOutlinedIcon sx={{color:'lightSecondary.main'}} />
                                            </IconButton>
                                            <IconButton
                                                sx={{
                                                    p: 0.5,
                                                }}>
                                                <StarOutlinedIcon sx={{color:'lightSecondary.main'}} />
                                            </IconButton>
                                            <div
                                                style={{
                                                    position: 'relative'
                                                }}
                                            >
                                                <IconButton
                                                    id="basic-button1"
                                                    aria-controls={openSolutionListOptions ? 'basic-menu1' : undefined}
                                                    aria-haspopup="true"
                                                    aria-expanded={openSolutionListOptions ? 'true' : undefined}
                                                    onClick={handleSolutionListOptionsClick}
                                                    sx={{
                                                        p: 0.5,
                                                    }}
                                                >
                                                    <MoreVertOutlinedIcon
                                                        sx={{
                                                            fontSize: "1.2rem",
                                                            color:'lightSecondary.main'
                                                        }}
                                                    />
                                                </IconButton>
                                                <Menu
                                                    id="basic-menu1"
                                                    anchorEl={anchorElSolutionListOptions}
                                                    open={openSolutionListOptions}
                                                    onClose={handleSolutionListOptionsClose}
                                                    MenuListProps={{
                                                        'aria-labelledby': 'basic-button1',
                                                    }}
                                                    sx={{
                                                        '& .MuiMenu-paper': {
                                                            borderRadius: "4px",
                                                            border: "none",
                                                            minWidth: "210px",
                                                            p: 0.5,
                                                        }
                                                    }}
                                                >
                                                    <Paper
                                                        sx={{
                                                            width: 210,
                                                            maxWidth: '100%',
                                                            background: "#fff",
                                                            borderRadius: "10px",
                                                            borderBottom: "none",
                                                            boxShadow: "none",
                                                            p: 0.5
                                                        }}>
                                                        <MenuItem
                                                            sx={{
                                                                fontSize: "0.9rem",
                                                                fontWeight: "600",
                                                                lineHeight: "20px",
                                                                color: "lightSecondary.main",
                                                                py: 1,
                                                                px: 1.4,
                                                                '&:hover': {
                                                                    backgroundColor: 'extraLTheme.main'
                                                                },
                                                                '&.active': {
                                                                    backgroundColor: 'primary.main',
                                                                    color: "#fff",
                                                                    borderRadius: "10px"
                                                                }
                                                            }}
                                                        >
                                                            <Link to="/edit-solution-details"
                                                                style={{
                                                                    textDecoration: 'none', 
                                                                    color: 'inherit',
                                                                    display: 'block',
                                                                    width: '100%'
                                                                }}
                                                            >Edit</Link>
                                                        </MenuItem>
                                                        <MenuItem onClick={handleSolutionListOptionsClose}
                                                            sx={{
                                                                fontSize: "0.9rem",
                                                                fontWeight: "600",
                                                                lineHeight: "20px",
                                                                color: "lightSecondary.main",
                                                                py: 1,
                                                                px: 1.4,
                                                                '&:hover': {
                                                                    backgroundColor: 'extraLTheme.main'
                                                                },
                                                                '&.active': {
                                                                    backgroundColor: 'primary.main',
                                                                    color: "#fff",
                                                                    borderRadius: "10px"
                                                                }
                                                            }}
                                                        >Archive</MenuItem>
                                                        <MenuItem onClick={handleSolutionListOptionsClose}
                                                            sx={{
                                                                fontSize: "0.9rem",
                                                                fontWeight: "600",
                                                                lineHeight: "20px",
                                                                color: "lightSecondary.main",
                                                                py: 1,
                                                                px: 1.4,
                                                                '&:hover': {
                                                                    backgroundColor: 'extraLTheme.main'
                                                                },
                                                                '&.active': {
                                                                    backgroundColor: 'primary.main',
                                                                    color: "#fff",
                                                                    borderRadius: "10px"
                                                                }
                                                            }}
                                                        >Delete</MenuItem>
                                                    </Paper>
                                                </Menu>
                                            </div>
                                        </Box>
                                    </Box>
                                    <Box
                                        sx={{
                                            mt: 1,
                                            maxWidth: '80%',
                                            [theme.breakpoints.down('md')]: {
                                                mt: 1,
                                                maxWidth: '100%',
                                            }
                                        }}
                                    >
                                        <Box>
                                            <Typography variant="h6" color="textSecondary"
                                                sx={{
                                                    fontSize: "1.2rem",
                                                    fontWeight: "700",
                                                    color: "secondary.main",
                                                    mb: 0.5
                                                }}
                                            >
                                                KYC as a Service
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary"
                                                sx={{
                                                    fontSize: "0.95rem",
                                                    fontWeight: "500",
                                                    color: "secondary.main",
                                                    minHeight: '41px',
                                                    maxHeight: '41px',
                                                    overflow: 'hidden'
                                                }}
                                            >
                                                TRUSTDOCK: The e-KYC and Due Diligence You Need.
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box
                                    sx={{
                                        py: 2,
                                        px: 2,
                                        backgroundColor: "rgba(219, 235, 248, 0.2)",
                                        display: "flex",
                                        alignItems: "start",
                                        justifyContent: "space-between",
                                        borderTop: "solid 1px rgba(162, 194, 241, 1)",
                                        minHeight: '102px',
                                        [theme.breakpoints.down('md')]: {
                                            flexWrap: 'wrap',
                                            px: 2
                                        }
                                    }}
                                >
                                    <Box display={"flex"}
                                        sx={{
                                            pl: "0",
                                            pr: 1,
                                            alignItems: "center"
                                        }}
                                    >
                                        <Box>
                                            <img style={{ display: "block" }} src={getImages('headquarter_icon.png')} />
                                        </Box>
                                        <ListItemText
                                            sx={{
                                                pl: '10px',
                                                width: "60%"
                                            }}
                                        >
                                            <Typography noWrap
                                                sx={{
                                                    fontSize: "12px",
                                                    fontWeight: '700',
                                                    color: 'secondary.main',
                                                    lineHeight: '15px',
                                                }}
                                                variant="h6">Headquarters
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                sx={{
                                                    fontSize: '12px',
                                                    fontWeight: '500',
                                                    color: 'lightSecondary.main',
                                                }}
                                            >London, UK</Typography>
                                        </ListItemText>
                                    </Box>
                                    <Box display={"flex"}
                                        sx={{
                                            pl: "0",
                                            pr: 1,
                                            alignItems: "center"
                                        }}
                                    >
                                        <Box>
                                            <img style={{ display: "block" }} src={getImages('industry_icon.png')} />
                                        </Box>
                                        <ListItemText
                                            sx={{
                                                pl: '10px',
                                                width: "60%"
                                            }}
                                        >
                                            <Typography noWrap
                                                sx={{
                                                    fontSize: "12px",
                                                    fontWeight: '700',
                                                    color: 'secondary.main',
                                                    lineHeight: '15px',
                                                }}
                                                variant="h6">Industries
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                sx={{
                                                    fontSize: '12px',
                                                    fontWeight: '500',
                                                    color: 'lightSecondary.main',
                                                }}
                                            >Finance, Retial</Typography>
                                        </ListItemText>
                                    </Box>
                                    <Box display={"flex"}
                                        sx={{
                                            pl: "0",
                                            alignItems: "center"
                                        }}
                                    >
                                        <Box>
                                            <img style={{ display: "block" }} src={getImages('usecase_icon.png')} />
                                        </Box>
                                        <ListItemText
                                            sx={{
                                                pl: '10px',
                                                width: "60%"
                                            }}
                                        >
                                            <Typography noWrap
                                                sx={{
                                                    fontSize: "12px",
                                                    fontWeight: '700',
                                                    color: 'secondary.main',
                                                    lineHeight: '15px',
                                                }}
                                                variant="h6">Use Cases
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                sx={{
                                                    fontSize: '12px',
                                                    fontWeight: '500',
                                                    color: 'lightSecondary.main',
                                                }}
                                            >Smart Contract, Fruad Dection FinTech</Typography>
                                        </ListItemText>
                                    </Box>
                                </Box>
                                <Box
                                    sx={{
                                        py: 2,
                                        px: 2,
                                        backgroundColor: "#fff",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        borderTop: "solid 1px rgba(162, 194, 241, 1)",
                                        [theme.breakpoints.down('md')]: {
                                            flexWrap: 'wrap',
                                            px: 2
                                        }
                                    }}
                                >
                                    <Box
                                        sx={{
                                            [theme.breakpoints.down('sm')]: {
                                                width: '40%',
                                            }
                                        }}
                                    >
                                        <Typography variant='h6'
                                            sx={{
                                                fontSize: '12px',
                                                fontWeight: '600',
                                                color: 'ltheme.main',
                                                mb: 0.3
                                            }}
                                        >Funding amount</Typography>
                                        <Typography variant='h5'
                                            sx={{
                                                fontSize: '1rem',
                                                fontWeight: '600',
                                                color: 'lightSecondary.main'
                                            }}
                                        >$10M</Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            [theme.breakpoints.down('sm')]: {
                                                width: '60%',
                                            }
                                        }}
                                    >
                                        <Typography variant='h6'
                                            sx={{
                                                fontSize: '12px',
                                                fontWeight: '600',
                                                color: 'ltheme.main',
                                                mb: 0.3
                                            }}
                                        >Investment stage</Typography>
                                        <Typography variant='h5'
                                            sx={{
                                                fontSize: '1rem',
                                                fontWeight: '600',
                                                color: 'lightSecondary.main'
                                            }}
                                        >Pre-seed</Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            [theme.breakpoints.down('sm')]: {
                                                width: '40%',
                                            }
                                        }}
                                    >
                                        <Typography variant='h6'
                                            sx={{
                                                fontSize: '12px',
                                                fontWeight: '600',
                                                color: 'ltheme.main',
                                                mb: 0.3
                                            }}
                                        >Total investment</Typography>
                                        <Typography variant='h5'
                                            sx={{
                                                fontSize: '1rem',
                                                fontWeight: '600',
                                                color: 'lightSecondary.main'
                                            }}
                                        >$950k (7)</Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            [theme.breakpoints.down('sm')]: {
                                                width: '40%',
                                            }
                                        }}
                                    >
                                        <Typography variant='h6'
                                            sx={{
                                                fontSize: '12px',
                                                fontWeight: '600',
                                                color: 'ltheme.main',
                                                mb: 0.3
                                            }}
                                        >Commitments</Typography>
                                        <Typography variant='h5'
                                            sx={{
                                                fontSize: '1rem',
                                                fontWeight: '600',
                                                color: 'lightSecondary.main'
                                            }}
                                        >$700k (6)</Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            [theme.breakpoints.down('sm')]: {
                                                width: '20%',
                                            }
                                        }}
                                    >
                                        <Link to="/solution-investment-summary">
                                            <EastOutlinedIcon
                                                sx={{
                                                    fontSize: '2.3rem',
                                                    color: 'lightSecondary.main',
                                                    display: 'block'
                                                }}
                                            />
                                        </Link>
                                    </Box>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

export default MySolutions